import { axiosModules } from "@qubit-utill/src";

export default {
  getWebExtendsFilterFieldList: (param?) => axiosModules.get(`/pfilter/webextendsfilterfields`, param),
  getWebExtendsFilterList: (param) => axiosModules.get(`/pfilter/webextendsfilters`, param),
  getWebExtendsFilter: (webExtendsFilterId) => axiosModules.get(`/pfilter/webextendsfilter/${webExtendsFilterId}`),
  createWebExtendsFilter: (data) => axiosModules.post(`/pfilter/webextendsfilter`, data),
  updateWebExtendsFilter: (data) => axiosModules.put(`/pfilter/webextendsfilter`, data),
  deleteWebExtendsFilter: (webExtendsFilterId, param) =>
    axiosModules.patch(`/pfilter/webextendsfilter/${webExtendsFilterId}/isdel`, param),
  updateIsUseWebExtends: (webExtendsFilterId, isUse, param) =>
    axiosModules.patch(`/pfilter/webextendsfilter/${webExtendsFilterId}/isuse/${isUse}`, param),
  deleteWebExtendsFilters: (data) => axiosModules.patch(`/pfilter/webextendsfilter/isdel/1`, data),
  updateWebExtendsFilters: (isUse, data) => axiosModules.patch(`/pfilter/webextendsfilter/isuse/${isUse}`, data),

  // pfilter > filters > 필터 자동완성 검색용 > 웹확장필터 목록 조회 - 자동완성용
  getWebExtendsFilterListForAutoComplete: (webExtendsFilterType?) =>
    axiosModules.get("/pfilter/webextendsfilters/simple", { limit: 9999, webExtendsFilterType }),
};
