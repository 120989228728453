import { axiosModules } from "@qubit-utill/src";

export default {
  getComplianceSettingCount: () => {
    return axiosModules.get("/pfilter/complianceconfig/count");
  },
  getComplianceTotalList: () => {
    return axiosModules.get("/pfilter/compliance/active/list");
  },
  getComplianceSettingList: () => {
    return axiosModules.get("/pfilter/complianceconfig");
  },
  updateComplianceSettingList: (param) => {
    return axiosModules.put("/pfilter/complianceconfig", param);
  },
  checkIsComplianceSetting: () => {
    return axiosModules.get("/pfilter/complianceconfig/initial/registration");
  },
  getComplianceEntityListByFilterKeys: (filterKeys) => {
    return axiosModules.get("/pfilter/complianceentity/filter/list", {
      filterKeys,
    });
  },
  getComplianceEntityCountByFilterKeys: (filterKeys) => {
    return axiosModules.get("/pfilter/complianceentity/filter/list/count", {
      filterKeys,
    });
  },
};
