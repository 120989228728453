import { axiosModules } from "@qubit-utill/src";

//TODO: api 수정, parameters, return 확인
export default {
  //pserver > 포렌식 > 서버 포렌식 목록 조회
  getForensicList: (params) => {
    return axiosModules.get("/pserver/forensic/list", params);
  },

  //pserver > 포렌식 > 서버 포렌식 상세조회
  getForensicInfo: (params) => {
    return axiosModules.get("/pserver/forensic/info", params);
  },

  //pserver > 포렌식 > 서버 포렌식 diff 조회
  getForensicDetailDiffInfo: (params) => {
    return axiosModules.get("/pserver/forensic/diff", params);
  },

  //pserver > 포렌식 > 포렌식 선택 목록 조회
  getForensicManualOptionList: (params) => {
    return axiosModules.get("/pserver/forensic/select/list", params);
  },

  //pserver > 포렌식 > 서버 포렌식 자동 옵션 수집 체크
  createForensicAutoExecuteCheck: (data) => {
    return axiosModules.post("/pserver/forensic/check", data);
  },

  //pserver > 포렌식 > 서버 포렌식 자동 옵션 초기화 체크
  createForensicAutoResetCheck: (data) => {
    return axiosModules.post("/pserver/forensic/reset/check", data);
  },

  //pserver > 포렌식 > 서버 포렌식 초기화
  createForensicAutoReset: (data) => {
    return axiosModules.post("/pserver/forensic/reset", data);
  },

  //pserver > 포렌식 > 서버 포렌식 자동 옵션 수집
  updateForensicAutoExecute: (data) => {
    return axiosModules.put("/pserver/forensic/auto", data);
  },

  //pserver > 포렌식 > 서버 포렌식 수동 옵션 수집
  updateForensicManualExecute: (data) => {
    return axiosModules.put("/pserver/forensic/manual", data);
  },
};
