import { observable, action, toJS } from "mobx";

import { ListCondition, withLoading, languageSet, Common_Utils } from "@qubit-utill/src";

import { systemDetectApis } from "@src/apis/pLogDetect";
import { complianceSettingApis } from "@src/apis/pFilter";
import { CSVINFO, UISET } from "@src/constants";

import DefenceStore from "@src/common/stores/DefenceStore";
import SystemFilterDetectionModel from "@src/pages/FilterDetection/SystemFilterDetection/models/SystemFilterDetectionModel";

export default class SystemFilterDetectStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }

  root;

  @observable
  tableList = [];

  @observable
  systemFilterDetectInfo = new SystemFilterDetectionModel(null, this.root.CodeStore);

  @observable
  ipDefenceType = "DEFENSE_IP_SYSTEM";

  @action
  clear = () => {
    this.tableList = [];
    this.systemFilterDetectInfo = new SystemFilterDetectionModel(null, this.root.CodeStore);
  };

  @withLoading("isLoading")
  @action
  getList = async () => {
    try {
      const tagServicePropertyList = this.tagServicePropertyList;
      const tagValueTypeList = this.tagValueTypeList;
      const getTagServiceDataList = this.openIpTagModal;

      const { list, totalCount, nextCursorMark } = await systemDetectApis.getSysLogDetectList(this.params);

      this.tableList = list.map((info) => {
        const _info = { ...info, tagServicePropertyList, tagValueTypeList, getTagServiceDataList };
        return new SystemFilterDetectionModel(_info, this.root.CodeStore);
      });

      this.setCursorMarkInfo(nextCursorMark);
      this.totalCount = totalCount;
    } catch (e) {
      this.tableList = [];
      this.totalCount = 0;
    }
  };

  getComplianceEntityCountByFilterKeys = async (filterIds) => {
    try {
      const { totalCount } = await complianceSettingApis.getComplianceEntityCountByFilterKeys(filterIds);

      return totalCount;
    } catch (e) {
      return 0;
    }
  };

  @withLoading("isSubLoading")
  @action
  getInfo = async (documentId) => {
    try {
      const tagServicePropertyList = this.tagServicePropertyList;
      const tagValueTypeList = this.tagValueTypeList;
      const getTagServiceDataList = this.openIpTagModal;
      const getTagServiceDataListFn = this.getTagServiceDataList;

      const { info, viewTemplate, filterInfoList, programMessageTemplate } = await systemDetectApis.getSysLogDetect(documentId);

      const _info = { ...info, tagServicePropertyList, tagValueTypeList, getTagServiceDataList, getTagServiceDataListFn };

      this.systemFilterDetectInfo = await new SystemFilterDetectionModel(
        {
          ..._info,
          viewTemplate,
          filterInfoList,
          programMessageTemplate,
        },
        this.root.CodeStore
      );

      const totalCount = await this.getComplianceEntityCountByFilterKeys(this.systemFilterDetectInfo.filterIds);
      this.systemFilterDetectInfo.setInfo("complianceEntityCount", totalCount);

      this.systemFilterDetectInfo.attackIp && (await DefenceStore.getDefenceInfo(this.systemFilterDetectInfo)); //get 방어정보
    } catch (err) {
      console.log(err);
      this.systemFilterDetectInfo = new SystemFilterDetectionModel(null, this.root.CodeStore);
    }
  };

  @action
  downloadCSV = async (isUseBulk?, no?) => {
    try {
      if (this.tableList.length > 0) {
        let csv;

        if (isUseBulk && no) {
          csv = await systemDetectApis.getSysLogDetectCsvList({
            ...toJS(this.params),
            // serverIds: toJS(this.params.serverIds),
            // typeosList: toJS(this.params.typeosList),
            // serverGroupIds: toJS(this.params.serverGroupIds),
            offset: 0,
            limit: no,
          });
        } else {
          csv = Common_Utils.makeCSV(CSVINFO.FILTERDETECT_SYS.head, this.tableList);
        }

        Common_Utils.csvDownload(
          csv,
          Common_Utils.setCsvFileName({ title: UISET.FILTERDETECT_SYS.title, category: languageSet("필터탐지"), ...this.params })
        );
      } else {
        return languageSet("에러_데이터가없습니다");
      }
    } catch ({ data }) {
      console.log(data);
    }
  };
}
