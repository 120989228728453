import { observable, computed } from "mobx";

import { Common_Utils, dateUtil, formatUtils } from "@qubit-utill/src";

import { CSVINFO } from "@src/constants";
import { NETISSUETYPE } from "@src/constants/code";
import { NetworkLogModel } from "@src/common/models";

import getTrFilters from "@src/pages/FilterDetection/SystemFilterDetection/utill/getTrFilters";

class NetworkFilterDetectionModel extends NetworkLogModel {
  constructor(param, CodeStore) {
    super(param);
    this.CodeStore = CodeStore;
    if (param) {
      this.osFamily = formatUtils.IsNullFormat(param.osFamily);
      this.withMicroSecondsLogCreateDate = param.logCreateDate + param.logCreateMicroSeconds;
    }
  }
  @computed
  get attackIp() {
    return this.logClientIp;
  }

  @computed
  get trFilters() {
    return getTrFilters(this.filters, this.CodeStore);
  }

  @computed
  get isDefenceFlag() {
    return Common_Utils.isCheckBool(this.isDefence);
  }

  @computed
  get serverIpAddr() {
    return this.hostIp || this.serverIp;
  }

  @computed
  get ticketText() {
    return this.userTicketPublish ? dateUtil.timeage(this.userTicketPublish.registerDate) : false;
  }

  @computed
  get stepText() {
    return Common_Utils.getTrFiltersText("step", this.trFilters);
  }

  @computed
  get attackText() {
    return Common_Utils.getTrFiltersText("attack", this.trFilters);
  }

  @computed
  get classficationText() {
    return Common_Utils.getTrFiltersText("classfication", this.trFilters);
  }

  @computed
  get csvData() {
    return Common_Utils.makeCsvRowData(this, CSVINFO.FILTERDETECT_NET.infoKey);
  }

  @computed
  get filterIds() {
    return this.filters ? this.filters.map(({ filterId }) => filterId) : [this.filterId];
  }

  @computed
  get logInfo() {
    return {
      issueServiceType: NETISSUETYPE,
      logCreateDate: this.logCreateDate,
      filterList: this.filterIds,
    };
  }

  defenseConfigFilterType: string = "filter";

  @observable
  CodeStore;

  @observable
  filterId: string = "";

  @observable
  documentId: string = ""; //로그 id

  @observable
  serverGroup: string = "";

  @observable
  hostName: string = "";

  @observable
  logClientIp: string = "";

  @observable
  logSeverity: string = "";

  @observable
  osFamily: string = "";

  @observable
  logCreateDate: string = "";

  @observable
  filters = [];

  @observable
  viewTemplate = [];

  @observable
  filterInfoList = [];

  @observable
  countryIsoCode: string = "";

  @observable
  isDefence: string = "0";

  @observable
  filterChannelName: string = "";

  @observable
  serverId: number = 0;

  @observable
  detectDefenseId: number = 0;

  @observable
  formatLogOrigin: string = "";

  @observable
  logOrigin: string = "";

  @observable
  logHash: string = "";

  @observable
  hostIp: string = "";

  @observable
  serverIp: string = "";

  @observable
  complianceEntityCount: number = 0;

  @observable
  logCreateMicroSeconds: string = "";

  @observable
  withMicroSecondsLogCreateDate: string = "";

  @observable
  userTicketPublish: {
    registerDate: string;
  } = {
    registerDate: "",
  };
}

export default NetworkFilterDetectionModel;
