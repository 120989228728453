import React from "react";
import { observer } from "mobx-react";

interface Props {
  title?: string;
}

const GraphTitle: React.SFC<Props> = ({ title, children }) => {
  return (
    <p className="w-700 t-center f-13 m-b-0 c-black h-25">
      {title}
      {children}
    </p>
  );
};

export default observer(GraphTitle);
