import { observable, action } from "mobx";

import { withLoading, languageSet, Common_Utils } from "@qubit-utill/src";

import { logUploadReportApis } from "@src/apis/pStats/report";
import LogUploadReportStore from "@src/pages/Statistic/LogUpLoad/stores/LogUploadReportStore";
import { LogCountByChannelModel } from "@src/pages/Statistic/LogUpLoad/models";

class LogCountByChannelStore extends LogUploadReportStore {
  constructor(param, codeStore) {
    super(param, codeStore);
    this.defaultDate = this.dateInfo.detectDate;
    this.logUploadDate = this.dateInfo.detectDate;
  }

  @observable
  defaultDate: string = "";

  @observable
  logUploadDate: string = "";

  @observable
  serverId: number = 0;

  @observable
  serverIp: string = "";

  @observable
  logCountByChannelInfo = {};

  @observable
  logCountByChannelSumList: Array<LogCountByChannelModel> = [];

  @observable
  isCallServerManage: boolean = false;

  @withLoading("isLoading")
  @action
  getLogCountList = async () => {
    const param = {
      logUploadDate: this.logUploadDate,
      serverId: this.serverId,
    };

    try {
      const info = await logUploadReportApis.getLogUploadByChannelInfo(param);
      this.logCountByChannelInfo = info;
      this.logCountByChannelSumList = info.logUploadDaySumByChannel.map(
        (channelItem) => new LogCountByChannelModel(channelItem, this.serverIp)
      );
    } catch ({ data }) {
      this.logCountByChannelInfo = {};
      this.logCountByChannelSumList = [];
      return data;
    }
  };

  @action
  initDate = () => {
    this.logUploadDate = this.defaultDate;
  };

  @action
  getLogCountByChannelCsvData = (head, serverIp) => {
    if (this.logCountByChannelSumList.length > 0) {
      const fileName = `${serverIp} ${languageSet("채널별_로그수")}`;
      const csv = Common_Utils.makeCSV(head, this.logCountByChannelSumList, "", false, true);
      Common_Utils.csvDownload(csv, fileName);
    } else {
      return languageSet("에러_데이터가없습니다");
    }
  };
}

export default LogCountByChannelStore;
