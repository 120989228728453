import { action, computed, observable, toJS } from "mobx";
import { getDaysInMonth, isFuture, endOfMonth } from "date-fns";

import { languageSet, Common_Utils, withLoading, dateUtil } from "@qubit-utill/src";

import { UISET, CSVINFO } from "@src/constants";
import { logUploadReportApis } from "@src/apis/pStats/report";

import { FilterReportStore } from "@src/pages/Report";
import UploadChartModel from "@src/pages/Statistic/LogUpLoad/models/UploadChartModel";

class LogUploadReportStore extends FilterReportStore {
  constructor(params, CodeStore) {
    super(params);
    let local = localStorage.getItem(UISET.STATISTIC_UPLOAD.localStorageName);
    const sessionStorageDetectDate = sessionStorage.getItem(Common_Utils.getCurrentPathName());
    if (sessionStorageDetectDate) {
      const detectDate = JSON.parse(sessionStorageDetectDate).detectDate;
      this.dateInfo.detectDate = detectDate;
    }
    this.unit = local ? local : this.unitOption[1];
    this.params.limit = params.limit;
    this.params.orderMap = "totalLogUploadBytes|DESC";
    if (CodeStore) {
      this.setLogUploadTypeModel(CodeStore);
    }
  }

  @action
  setLogUploadTypeModel = (CodeStore) => {
    CodeStore.codeListForSelect &&
      CodeStore.codeListForSelect.logUploadOrder &&
      CodeStore.codeListForSelect.logUploadOrder.map((data) => {
        if (data.value.codeOption === "logUploadBytesModel") {
          this.logUploadBytesModel.push(data);
        } else {
          this.logUploadCountModel.push(data);
        }
      });
  };

  @observable
  logUploadBytesModel: Array<object> = [];

  @observable
  logUploadCountModel: Array<object> = [];

  @action
  setFilterReportParams = () => {};

  graphTitle = languageSet("로그업로드_분포");
  pieChartTitle = languageSet("로그별_업로드_분포");
  csvInfoKey = "STATISTIC_UPLOAD";

  subTitles = {
    day: languageSet("시간별_로그업로드_분포"),
    week: languageSet("로그업로드_분포"),
    month: languageSet("월별_로그업로드_분포"),
  };

  @observable
  unit: string = "MB";

  @observable
  selectedIndex = -1;

  unitOption = ["KB", "MB", "GB"];

  @observable
  selectedInfo: number[] = [];

  @observable
  totalStackedData: number = 0;

  @observable
  isCsv: boolean = false;

  @observable
  searchWeeklyList: { logUploadYear: number; logUploadWeek: number }[] = [];

  @computed
  get apiParams() {
    return {
      logUploadDate: this.params.detectDate,
      logUploadWeek: this.params.detectWeek,
      logUploadMonth: this.params.detectMonth,
      logUploadYear: this.params.detectYear,
      limit: this.isCsv ? 3000 : this.params.limit,
      offset: this.isCsv ? 0 : this.params.offset,
      serverGroupIds: toJS(this.params.serverGroupList),
      serverIds: toJS(this.params.serverIds),
      orderMap: this.params.orderMap,
    };
  }

  @observable
  logUploadType = "logUploadBytesModel";

  @action
  setLogUploadType = (value) => {
    this.logUploadType = value;
    this.isLogUploadBytesModel = value === "logUploadBytesModel" ? true : false;
    this.csvInfoKey = value === "logUploadBytesModel" ? "STATISTIC_UPLOAD" : "STATISTIC_UPLOAD_COUNT";
  };

  @computed
  get getLogUploadTypeName() {
    return this.logUploadType === "logUploadBytesModel" ? languageSet("용량") : languageSet("수");
  }

  @observable
  isLogUploadBytesModel: boolean = true;

  @action
  getDayTop10ListApi = () => logUploadReportApis.getUploadList(this.apiParams, this.termsTab);
  @action
  getWeekTop10ListApi = () => logUploadReportApis.getUploadList(this.apiParams, this.termsTab);
  @action
  getMonthTop10ListApi = () => logUploadReportApis.getUploadList(this.apiParams, this.termsTab);

  @action
  setUnit = (item: string) => {
    try {
      this.mainChartData.setUnitData(item);
      this.barChartData.setUnitData(item);
      this.setPieChartData();
    } catch (error) {
    } finally {
      this.unit = item;
      localStorage.setItem(UISET.STATISTIC_UPLOAD.localStorageName, item);
    }
  };

  @action
  getDayChart = async () => {
    try {
      const apiData = await logUploadReportApis.getUploadMainChart(this.apiParams, this.termsTab);
      const dayCategory = this.getDateRange(apiData.startLogUploadDate, apiData.endLogUploadDate);
      this.mainChartData = new UploadChartModel(this.getConfig(apiData, dayCategory, "Daily", this.termsTab));
      this.barChartData = new UploadChartModel(this.getConfig(apiData, UISET.DATE_CATEGORY.HOUR, "Hour", this.termsTab));
      this.setPieChartData();
    } catch (error) {
      this.mainChartData = {};
      this.barChartData = {};
      this.pieChartData = {};
    }
  };

  @action
  getDaySubChart = async () => {
    try {
      const apiData = await logUploadReportApis.getUploadSubChart(this.apiParams, this.termsTab);
      this.barChartData = new UploadChartModel(this.getConfig(apiData, UISET.DATE_CATEGORY.HOUR, "Hour", this.termsTab));
    } catch (error) {
      this.barChartData = {};
    }
  };

  @action
  getWeekChart = async () => {
    try {
      const apiData = await logUploadReportApis.getUploadMainChart(this.apiParams, this.termsTab);
      this.searchWeeklyList = apiData.searchWeeklyList;

      const { year, month, weekNo } = dateUtil.getWeekNumberByMonth(this.dateInfo.detectDate);
      this.setSubTitle(dateUtil.setYearWeekWeekNo(year, month, weekNo));
      const weekCategory = apiData.searchWeeklyList.map((data) => data.logUploadWeek);

      this.mainChartData = new UploadChartModel(this.getConfig(apiData, weekCategory, "Weekly", this.termsTab));

      const dayCategory = this.getDateRange(this.startDate, this.endDate);
      this.barChartData = new UploadChartModel(this.getConfig(apiData, dayCategory, "Daily", this.termsTab));

      this.setPieChartData();
      //주별 category 재설정
      this.mainChartData.category = dateUtil.setWeekWithMonthForReport(this.dateInfo.detectDate);
    } catch (error) {
      this.mainChartData = {};
      this.barChartData = {};
      this.pieChartData = {};
    }
  };

  @action
  getWeekSubChart = async () => {
    try {
      const apiData = await logUploadReportApis.getUploadSubChart(this.apiParams, this.termsTab);

      const dayCategory = this.getDateRange(this.startDate, this.endDate);
      this.barChartData = new UploadChartModel(this.getConfig(apiData, dayCategory, "Daily", this.termsTab));
    } catch (error) {
      this.barChartData = {};
    }
  };

  @action
  getMonthChart = async () => {
    try {
      const apiData = await logUploadReportApis.getUploadMainChart(this.apiParams, this.termsTab);
      const daysInMonth = getDaysInMonth(new Date(this.dateInfo.detectDate));
      this.mainChartData = new UploadChartModel(
        this.getConfig(apiData, UISET.DATE_CATEGORY.MONTH.slice(0, daysInMonth), "line", this.termsTab)
      );
      const customCategory = apiData.searchMonthlyList.map(
        (data) => `${data.logUploadYear} ${languageSet("n월", data.logUploadMonth)}`
      );
      this.barChartData = new UploadChartModel(this.getConfig(apiData, customCategory, "Monthly", this.termsTab));
      this.setPieChartData();
    } catch (error) {
      this.mainChartData = {};
      this.barChartData = {};
      this.pieChartData = {};
    }
  };

  @action
  @withLoading("isSubLoading")
  getSubChartData = (date: string, stackedData: Array<number>, dataPointIndex: number) => {
    this.params = {
      ...this.params,
      offset: 0,
    };
    this.selectedIndex = dataPointIndex;
    this.setSelectedInfo(stackedData);
    if (this.termsTab === "day") {
      this.setSubTitle(date);
      this.getDay(date);
    } else if (this.termsTab === "week") {
      try {
        const searchWeek = this.searchWeeklyList[dataPointIndex];
        const _year = searchWeek.logUploadYear;
        const _week = searchWeek.logUploadWeek;
        const parseDate = dateUtil.getDateByWeekNumber(_year, this.dateInfo.detectMonth, _week, 3);
        const { year, month, weekNo } = dateUtil.getWeekNumberByMonth(parseDate);
        this.setSubTitle(dateUtil.setYearWeekWeekNo(year, month, weekNo));
        this.getWeek(new Date(parseDate));
      } catch (error) {}
    }
  };

  @action
  setSelectedInfo = (stackedData) => {
    this.selectedInfo = stackedData;
    this.totalStackedData = this.selectedInfo.reduce((acc, cur) => acc + cur, 0);

    const pieChartData = stackedData.map((data) => data);
    this.pieChartData =
      this.totalStackedData === 0 || stackedData.some((data) => data === undefined)
        ? {}
        : {
            chartData: pieChartData,
          };
    this.pieChartData.category = this.mainChartData.originData.map(({ name }) => languageSet(name));
  };

  @action
  setPieChartData = () => {
    let index = -1,
      stackData: number[] = [];
    switch (this.termsTab) {
      case "day":
        index = this.selectedIndex !== -1 ? this.selectedIndex : this.mainChartData.category.indexOf(this.dateInfo.detectDate);
        stackData = this.mainChartData.chartData.map(({ data }) => {
          return data[index];
        });
        break;
      case "week":
        index = this.selectedIndex !== -1 ? this.selectedIndex : this.mainChartData.category.indexOf(this.dateInfo.detectWeek);
        stackData = this.mainChartData.chartData.map(({ data }) => {
          return data[index];
        });
        break;
      case "month":
        stackData = this.mainChartData.chartData.map(({ data }) => {
          return data.reduce((acc, cur) => acc + cur, 0);
        });
        break;
    }
    this.selectedIndex = index;
    this.setSelectedInfo(stackData);
  };

  getGraphFileName = (middleName) => {
    const logUploadTypeName = this.isLogUploadBytesModel ? languageSet("업로드용량") : languageSet("업로드수");
    return Common_Utils.getDownloadFileName(languageSet("통계"), logUploadTypeName, middleName, this.termsText);
  };

  getConfig = (apiData, category, timeText, termsTab) => {
    const today = new Date();
    const todayString = dateUtil.getDateByForm(today);

    let lastIndex = -1;
    let getIndex = (data: any, category: string[]) => 0;
    switch (timeText) {
      case "Daily":
        lastIndex = category.indexOf(todayString);
        getIndex = (data, category) => category.indexOf(data.logUploadDate);
        break;
      case "Hour":
        lastIndex = this.dateInfo.detectDate === todayString ? today.getHours() : -1;
        getIndex = (data) => data.logUploadHour;
        break;
      case "line":
        timeText = "Daily";
        lastIndex = isFuture(endOfMonth(new Date(this.dateInfo.detectDate))) ? today.getDate() - 1 : -1;
        getIndex = (data) => Number(data.logUploadDate.substring(8, 10)) - 1;
        break;
      case "Weekly":
        getIndex = (data, _category) => _category.indexOf(data.logUploadWeek);

        break;
      case "Monthly":
        getIndex = (data, _category) => _category.indexOf(`${data.logUploadYear} ${languageSet("n월", data.logUploadMonth)}`);
        break;

      default:
        break;
    }

    return {
      system: apiData[`sysLog${timeText}SumList`],
      web: apiData[`webLog${timeText}SumList`],
      app: apiData[`applicationLog${timeText}SumList`],
      network: apiData[`networkLog${timeText}SumList`],
      yesterdaySystem: apiData[`yesterdaySysLogUpload${timeText}SumList`],
      yesterdayWeb: apiData[`yesterdayWebLogUpload${timeText}SumList`],
      yesterdayApp: apiData[`yesterdayApplicationLogUpload${timeText}SumList`],
      yesterdayNetwork: apiData[`yesterdayNetworkLogUpload${timeText}SumList`],
      unit: this.unit,
      category,
      lastIndex,
      getIndex,
      timeText,
      termsTab,
    };
  };

  @action
  goFirstPage = () => {
    this.params.offset = 0;
    this.page = 1;
  };

  @observable
  page: number = 1;

  @action
  getTypeosFamily = async (typeos) => {
    try {
      await this.root.CodeStore.getOsListByType("filter");
      const osList = this.root.CodeStore.osList.filter;

      if (osList) {
        osList.osFamily.forEach(({ typeosList, osFamily }) => {
          if (typeosList.indexOf(typeos) > -1 && osFamily) return osFamily;
        });
      }
    } catch ({ data }) {
      console.log("error", data);
    }
  };

  //override
  downloadCSV = (list, etcText = "", notUseNo = false) => {
    const head = CSVINFO[this.csvInfoKey].head;
    const csv = Common_Utils.makeCSV(head, list, etcText, notUseNo);
    Common_Utils.csvDownload(csv, this.getGraphFileName(etcText));
  };
}

export default LogUploadReportStore;
