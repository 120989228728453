import pStats from "./pStats";
import {
  ApiWeekChartType,
  ApiWeekSubChartType,
  ApiMitreTopListType,
  ApiMonthChartType,
} from "@src/pages/Report/Mitre/type/MitreApisType";

const REPORTTYPE = "mitredetect";

interface params {
  mitreDomainId?: string;
  detectDate: string;
  detectWeek?: string;
  detectYear?: string;
  serverIds?: string;
}

interface weekTopParams extends params {
  limit?: number;
}
//일별 분류 리스트
export default {
  getMitreReportTabList: (param) => pStats.getCategoryGroupDayList(REPORTTYPE, param),

  getMitreDayMainChart: (param) => pStats.getDayMainChart(REPORTTYPE, param),

  getDayTopList: (param): ApiMitreTopListType => pStats.getDayTopList(REPORTTYPE, param),

  getWeeklyMainChart: (param): ApiWeekChartType => pStats.getWeeklyMainChart(REPORTTYPE, param),

  getWeekSubChart: (param: params): ApiWeekSubChartType => pStats.getWeekSubChart(REPORTTYPE, param),

  getWeekTopListApi: (param: weekTopParams): ApiMitreTopListType => pStats.getWeekTopListApi(REPORTTYPE, param),

  getMonthMainChart: (param): ApiMonthChartType => pStats.getMonthMainChart(REPORTTYPE, param),

  getMonthTopListApi: (param: weekTopParams): ApiMitreTopListType => pStats.getMonthTopListApi(REPORTTYPE, param),
};
