import { axiosModules } from "@qubit-utill/src";

export default {
  //pserver > pserver > 서버 그룹 > 서버 그룹 등록
  createServerGroup: (params) => {
    return axiosModules.post("/pserver/servergroup", params);
  },

  //pserver > pserver > 서버 그룹 > 서버 그룹 수정
  updateServerGroup: (serverGroupId, params) => {
    return axiosModules.put(`/pserver/servergroup/${serverGroupId}`, params);
  },

  //pserver > pserver > 서버 그룹 > 서버 그룹 목록 조회(os카운트포함)
  getServerGroupList: (params) => {
    return axiosModules.get("/pserver/servergroup", params);
  },

  //pserver > pserver > 서버 그룹 > 서버그룹권한을 기준으로 서버 그룹 목록 조회
  getServerGroupByUserServerGroupList: (params) => {
    return axiosModules.get("/pserver/servergroup/byuserservergroup", params);
  },

  //pserver > pserver > 서버 그룹 > 서버 그룹 목록 조회
  getSimpleServerGroupList: (params) => {
    return axiosModules.get("/pserver/servergroups/simple", params);
  },

  //pserver > pserver > 서버 그룹 > 서버 그룹 삭제(논리삭제)
  deleteServerGroups: (params) => {
    return axiosModules.patch("/pserver/servergroups", params);
  },

  getDefaultServerGroupInfo: () => {
    return axiosModules.get("/pserver/servergroup/default");
  },

  getServerGroupInfo: (serverGroupId) => {
    return axiosModules.get(`/pserver/servergroup/${serverGroupId}`);
  },

  getServerGroupNameIsExist: (serverGroup) => {
    return axiosModules.patch("/pserver/servergroup/check", {
      serverGroup: serverGroup,
    });
  },
};
