import { axiosModules } from "@qubit-utill/src";

export default {
  //IP추출 리스트 API
  getIpExtractionList: (params) => axiosModules.get("/pstats/ipdetect/detectfiltertype/ip", params),

  //URL
  getUrlExtractionList: (params) => axiosModules.get("/pstats/webrequest/url/status", params),

  // plog > 로그인로그 > 로그인 로그 리스트 조회
  getLoginExtractionList: (params) => axiosModules.get("/plog/loginlog/list", params),

  // plog > 로그인로그 > 로그인 로그 리스트 CSV 다운로드
  getLoginExtractionCsvList: (params) => axiosModules.getCsv("/plog/loginlog/list/csv", params),
};
