import { axiosModules } from "@qubit-utill/src";

export default {
  // plogdetect > 시스템 탐지 로그 > 탐지 시스템 로그 조회
  getSysLogDetect: (documentId) => {
    return axiosModules.get(`/plogdetect/syslogdetect/${documentId}`);
  },

  //시스템 탐지 로그 > 탐지 시스템 로그 방어/복구 조회
  getSysLogDetectEdrDefense: (documentId) => {
    return axiosModules.get(`/plogdetect/syslogdetect/edrdefense/${documentId}`);
  },

  // plogdetect > 시스템 탐지 로그 > 탐지 시스템 로그 리스트 조회
  getSysLogDetectList: (params) => {
    return axiosModules.get("/plogdetect/syslogdetect/list", params);
  },

  // plogdetect > 시스템 탐지 로그 > 탐지 시스템 로그 리스트 CSV 다운로드
  getSysLogDetectCsvList: (params) => {
    return axiosModules.getCsv("/plogdetect/syslogdetect/list/csv", params);
  },
};
