import { axiosModules } from "@qubit-utill/src";

export default {
  // 보고서 > 업로드용량 > 일 정보 > 일 업로드용량 메인 챠트 정보 가져오기
  getUploadMainChart: (params, termsTab) => axiosModules.get(`/pstats/logupload/${termsTab}/mainchart`, params),

  // 보고서 > 업로드용량 > 일 정보 > 일 업로드용량 선택일자 시간대별 정보 가져오기
  getUploadSubChart: (params, termsTab) => axiosModules.get(`/pstats/logupload/${termsTab}/subchart`, params),

  // 보고서 > 업로드용량 > 일 정보 > 일 업로드용량 메인 목록 가져오기
  getUploadList: (params, termsTab) => axiosModules.get(`/pstats/logupload/${termsTab}/main`, params),

  // 보고서 > 업로드용량 > 일 정보 > 일 채널별 업로드용량 정보 가져오기
  getLogUploadByChannelInfo: (params) => axiosModules.get("/pstats/loguploadchannel/day/count", params),
};
