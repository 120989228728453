import { observable, action, computed, toJS } from "mobx";

import { ListCondition, withLoading, Common_Utils, dateUtil, languageSet } from "@qubit-utill/src";
import { ListFilterModel } from "@qubit-utill/src/model";

import { pDetectFilterApis } from "@src/apis";
import { pSearchApis } from "@src/apis";
import { serverApis } from "@src/apis/pServer";

import { CSVINFO, UISET } from "@src/constants";
import { SystemDefenceModel } from "@src/pages/Defence/DefenceIp/models";

class SystemDefenceStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }
  root;

  @observable
  isLoading: boolean = true;

  @observable
  tableList: SystemDefenceModel[] = [];

  @observable
  defenseInfo = new SystemDefenceModel(null, this.root.CodeStore);

  @observable
  defenseConfigFilterType: string = "";

  @observable
  isTotalCheck: boolean = false;

  serverList = [
    {
      name: "",
      value: "",
      code: "",
    },
  ];

  @observable
  serverWithOsList: ListFilterModel = {};

  @computed
  get checkList() {
    return this.tableList.filter((data) => data.isSelected);
  }

  @computed
  get totalCountForUI() {
    return this.isTotalCheck ? this.totalCount : this.checkList.length;
  }

  @action
  allCheck = (checked) => {
    this.tableList.forEach((data) => {
      data.setInfo("isSelected", checked);
    });
  };

  @action
  clearInfo = async () => {
    this.defenseInfo = new SystemDefenceModel(null, this.root.CodeStore);
    this.serverWithOsList.selectEntryList.forEach((info) => {
      info.isSelected = false;
    });
  };

  @withLoading("isLoading")
  @action
  getList = async () => {
    try {
      await this.getServerWithOsList();
      await this.root.CodeStore.getCodeList("defenseConfigFilterType");
      const { list, totalCount } = await pDetectFilterApis.getDetectDefenseList(this.params);
      this.tableList = list.map((data) => new SystemDefenceModel(data, this.root.CodeStore));
      this.totalCount = totalCount;
    } catch (response) {
      this.tableList = [];
      this.totalCount = 0;
      return response;
    }
  };

  @action
  updateFireWall = async (params) => {
    let data = {
      isActive: params.firewallSetting ? "1" : "0",
      serverId: params.serverId,
      detectDefenseId: params.detectDefenseId,
    };
    try {
      await pDetectFilterApis.updateDetectDefenseByIsActive(data);
    } catch ({ data }) {
      return data;
    }
  };

  @withLoading("isSubLoading")
  @action
  getInfo = async (detectDefenseId) => {
    try {
      const { info } = await pDetectFilterApis.getDetectDefenseManual(detectDefenseId);
      this.defenseInfo = new SystemDefenceModel(info, this.root.CodeStore);
    } catch (err) {}
  };

  @action
  updateDetectDefenseByIsActive = async (params, isCheckBox?) => {
    let data = {
      isActive: isCheckBox || params.isActive,
      detectDefenseIds: isCheckBox ? this.checkList.map(({ detectDefenseId }) => detectDefenseId) : [params.detectDefenseId],
    };
    try {
      await pDetectFilterApis.updateDetectDefenseByIsActive(data);
    } catch ({ data }) {
      return data;
    }
  };

  @action
  updateDetectDefense = async () => {
    const data = {
      defenseDescription: this.defenseInfo.defenseDescription,
      isActive: this.defenseInfo.isActive,
    };

    try {
      await pDetectFilterApis.updateDetectDefense(this.defenseInfo.detectDefenseId, data);
    } catch ({ data }) {
      return data;
    }
  };

  @withLoading("isLoading")
  @action
  createDetectDefense = async () => {
    const data = {
      serverIds: toJS(this.defenseInfo.serverIds),
      detectDefenseIp: this.defenseInfo.detectDefenseIp,
      detectDefenseType: this.defenseInfo.detectDefenseType,
      defenseConfigFilterType: this.defenseInfo.defenseConfigFilterType,
      defenseDescription: this.defenseInfo.defenseDescription,
      isActive: this.defenseInfo.isActive,
    };
    try {
      await pDetectFilterApis.createDetectDefenseManual(data);
    } catch ({ data }) {
      throw data;
    }
  };

  @withLoading("isSubLoading")
  @action
  getServerWithOsList = async () => {
    try {
      const { info } = await pSearchApis.getSearchMenuList("defenseipmodal");
      let selectList = await Common_Utils.constructClassList(info.selectList, ListFilterModel);
      if (selectList === null || selectList.length === 0) return;

      selectList.forEach((info: ListFilterModel) => {
        if (info.searchMenuSelectCode === "serverIds") this.serverWithOsList = info;
      });
    } catch ({ data }) {
      this.serverWithOsList = {};
    }
  };

  @action
  getServerList = async () => {
    const { list } = await serverApis.getServerList({
      offset: 0,
      limit: 99999,
      isUseSysLog: "1",
      isUseAgent: "1",
    });

    this.serverList = list.map((option, index) => ({
      uiId: index,
      name: option.serverIp,
      value: option.serverIp,
      serverId: option.serverId,
      typeos: option.typeos,
    }));
  };

  @action
  deleteCustomDefenseWaf = async (detectDefenseId) => {
    try {
      await pDetectFilterApis.deleteDetectDefenseId(detectDefenseId);
    } catch ({ data }) {
      return data;
    }
  };

  @action
  deleteDetectDefenseIds = async () => {
    const detectDefenseIds = this.checkList.reduce((acc, cur) => (acc += cur.detectDefenseId + ","), "");
    const params = {
      detectDefenseIds: detectDefenseIds.slice(0, -1),
    };

    try {
      await pDetectFilterApis.deleteDetectDefenseIds(params);
    } catch ({ data }) {
      return data;
    }
  };

  @action
  csvDownload = () => {
    if (this.tableList.length > 0) {
      const csv = Common_Utils.makeCSV(CSVINFO.DEFENSE_IP_SYSTEM.head, this.tableList);
      const { detectDate } = dateUtil.getDateInfo(new Date());
      Common_Utils.csvDownload(
        csv,
        Common_Utils.setCsvFileName({
          title: languageSet("차단IP주소"),
          category: UISET.DEFENSE_IP_SYSTEM.title,
          date: detectDate,
        })
      );
    } else {
      return languageSet("에러_데이터가없습니다");
    }
  };
}

export default SystemDefenceStore;
