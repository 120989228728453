import { observable, action, set } from "mobx";

import { ListCondition, withLoading } from "@qubit-utill/src";

import { pTicketApis } from "@src/apis";

import { TicketInputModel, TicketModel, TicketSystemModel } from "@src/pages/Group/Interlock/Ticket/models";

class TicketStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }

  root;

  @observable
  isLoading: boolean = false;

  @observable
  options = {};

  @observable
  ticketInfo = new TicketModel({});

  @observable
  originTicketInfo = new TicketModel({});

  @observable
  isEdit: boolean = false;

  @observable
  ticketPrintInfo = null;

  @observable
  issueServiceType: string = "";

  @observable
  logCreateDate: string = "";

  @observable
  filterList = [];

  inputForm;

  @action
  setTicketInfo = (userTicketPublish) => {
    this.ticketPrintInfo = userTicketPublish;
  };

  @action
  setLogInfo = (issueServiceType, logCreateDate, filterList = []) => {
    this.issueServiceType = issueServiceType;
    this.logCreateDate = logCreateDate;
    this.filterList = filterList;
  };

  @action
  toggleIsEdit = () => {
    this.isEdit = !this.isEdit;
    if (!this.isEdit) {
      this.ticketInfo = new TicketModel(this.originTicketInfo);
    }
  };

  @withLoading("isLoading")
  @action
  async getData() {
    await Promise.all([this.getTicketOption(), this.getTicketInfo()]);
  }

  @action
  getTicketOption = async () => {
    try {
      const { list } = await pTicketApis.getTicketOption();
      list.forEach((data) => {
        set(this.options, {
          [data.ticketSystemId]: {
            ticketSystemName: data.ticketSystemName,
            optionList: data.ticketSystemVersionModelList
              ? data.ticketSystemVersionModelList.map((version) => new TicketSystemModel(version))
              : [],
          },
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action
  getTicketParameterOption = async () => {
    const { ticketSystemId, version } = this.ticketInfo;
    try {
      if (ticketSystemId && version) {
        const { list } = await pTicketApis.getTicketParameterOption({
          ticketSystemId,
          version,
        });
        this.ticketInfo.ticketSystemParameterList = list.map((data) => new TicketInputModel(data));
      }
    } catch (error) {
      this.inputForm = [];
      console.log(error);
    }
  };

  @withLoading("isLoading")
  @action
  getTicketInfo = async () => {
    try {
      const { info } = await pTicketApis.getTicketInfo();
      await this.getTicketParameterOption();
      this.ticketInfo = new TicketModel(info);
      this.originTicketInfo = new TicketModel(info);
    } catch (error) {
      this.ticketInfo = new TicketModel({});
    }
  };

  @withLoading("isLoading")
  @action
  updateTicket = async () => {
    try {
      await pTicketApis.updateTicket(this.ticketInfo);
      this.isEdit = !this.isEdit;
    } catch ({ data }) {
      return data;
    }
  };

  @action
  checkValid = () => {
    let info =
      Boolean(this.ticketInfo.ticketSystemParameterList.length === 0) ||
      this.ticketInfo.ticketSystemParameterList.find(
        (data: any) => data.isRequiredFlag && data.isUserDataFlag && !data.parameterValue
      );
    return Boolean(info);
  };

  @action
  getPrintTicketInfo = async (issueServiceType, issuePrimaryKey, logCreateDate?, filterList?) => {
    this.issueServiceType = issueServiceType;
    this.logCreateDate = logCreateDate;
    this.filterList = filterList;
    try {
      const { info } = await pTicketApis.getPrintTicketInfo(issueServiceType, issuePrimaryKey);
      this.ticketPrintInfo = info;
    } catch (error) {
      this.ticketPrintInfo = null;
      console.log(error);
    }
  };

  @action
  createPrintTicketInfo = async (issuePrimaryKey, issueResourceType?) => {
    const _params = {
      issueServiceType: this.issueServiceType,
      issuePrimaryKey,
      logCreateDate: this.logCreateDate,
      filterIds: this.filterList,
    };

    const params = issueResourceType ? { ..._params, issueResourceType } : _params;

    try {
      await pTicketApis.createPrintTicketInfo(params);
      this.getPrintTicketInfo(this.issueServiceType, issuePrimaryKey);
    } catch ({ data }) {
      return data;
    }
  };

  @action
  createTestPrintTicket = async () => {
    try {
      const data = await pTicketApis.createTestPrintTicket();
      return data;
    } catch ({ data }) {
      return data;
    }
  };
}
export default TicketStore;
