import { observable, computed } from "mobx";

import { languageSet, Common_Utils, dateUtil, SetterModel } from "@qubit-utill/src";

class GroupModel extends SetterModel {
  constructor(params, CodeStore) {
    super(params);
    this.CodeStore = CodeStore;
    if (params) {
      this.memberInfoString = params.roleCountList.map((data) => {
        return `${CodeStore.getCodeName("roleList", data.roleCode)} ${data.roleUserCount}`;
      });

      if (params.useLicense) {
        const { useLicenseType, startUseLicenseDate, endUseLicenseDate } = params.useLicense;
        this.useLicense = CodeStore.getCodeName("useLicenseType", useLicenseType);

        this.licenseTerms = `(${dateUtil.defaultFormat(startUseLicenseDate)}
        ~${dateUtil.defaultFormat(endUseLicenseDate)})`;
      } else {
        this.useLicense = languageSet("무료");
      }
      this.isUseGlobalFilter = params.webExtendsConfig.webExtendsDataBreach.isUseGlobalFilter;

      if (params.resourceConfigUserGroup) {
        this.resourceConfigCount = params.resourceConfigUserGroup.totalCount;
        this.isUseResourceConfig = params.resourceConfigUserGroup.isUse;
      }

      if (params.servicePreferencesList) {
        for (const key in params.servicePreferencesList) {
          const itemList = params.servicePreferencesList[key];
          itemList.forEach(({ servicePreferencesId, servicePreferencesItemValue }) => {
            this[servicePreferencesId] = servicePreferencesItemValue;
          });
        }
      }
    }
  }

  isTicket;

  @observable
  CodeStore;

  @computed
  get isUseGlobalFilterFlag() {
    return Common_Utils.isCheckBool(this.isUseGlobalFilter);
  }

  @computed
  get isUseIpBlockFlag() {
    return Common_Utils.isCheckBool(this.isUseIpBlock);
  }

  @computed
  get isUsePinFlag() {
    return Common_Utils.isCheckBool(this.pin.isUse);
  }

  @computed
  get isUseOtpMailFlag() {
    return Common_Utils.isCheckBool(this.otpmail.isUse);
  }

  @computed
  get isUsePasswordFlag() {
    return Common_Utils.isCheckBool(this.password.isUse);
  }

  @computed
  get isUsePasswordChangeFlag() {
    return Common_Utils.isCheckBool(this.passwordchange.isUse);
  }

  @computed
  get nationText() {
    return this.CodeStore.getCodeName("supportNation", this.nation);
  }

  @computed
  get langText() {
    return this.CodeStore.getCodeName("supportLang", this.lang);
  }

  @computed
  get isTicketFlag() {
    return Common_Utils.isCheckBool(this.isTicket);
  }

  @computed
  get isUseResourceConfigFlag() {
    return Common_Utils.isCheckBool(this.isUseResourceConfig);
  }

  @computed
  get isUseHAProxyFlag() {
    return Common_Utils.isCheckBool(this.isDisplayHAProxy);
  }

  @computed
  get isUseSquidFlag() {
    return Common_Utils.isCheckBool(this.isDisplaySquid);
  }

  @computed
  get isUseFirewallFlag() {
    return Common_Utils.isCheckBool(this.isDisplayFirewall);
  }

  @computed
  get isUseHostShutdownFlag() {
    return Common_Utils.isCheckBool(this.isUseHostShutdown);
  }

  @computed
  get isUseHostIsolateFlag() {
    return Common_Utils.isCheckBool(this.isUseHostIsolate);
  }

  @computed
  get isUseCursorMarkFlag() {
    return Common_Utils.isCheckBool(this.isUseCursorMark);
  }

  @computed
  get isUseForensicArtifactFlag() {
    return Common_Utils.isCheckBool(this.isUseForensicArtifact);
  }

  @computed
  get isUseForensicDumpFlag() {
    return Common_Utils.isCheckBool(this.isUseForensicDump);
  }

  //////////////////////// 서비스 ////////////////////////
  //서비스 > 서비스 전환신청하기 링크 왼쪽 라이센스 정보
  @observable
  useLicense = "";

  //서비스 > 서비스 전환신청하기 링크 하단 라이센스 이용기간
  @observable
  licenseTerms: string = "";

  //////////////////////// 멤버 ////////////////////////
  //멤버 > 가입멤버
  @observable
  userCount: number = 0;

  //멤버 > 가입멤버 (권한별 멤버수)
  @observable
  memberInfoString: string = "";

  //멤버 > 가입대기
  @observable
  userGuestCount: number = 0;

  //////////////////////// 보안 ////////////////////////
  //보안 > 비밀번호 변경
  @observable
  passwordchange = {
    isUse: "0",
    passwordChangeDay: 0,
    passwordSkipDay: 0,
  };

  //보안 > 인증
  @observable
  password = {
    isUse: "0",
    isLoginFailLock: "0",
    loginFailCount: 0,
    loginFailMin: "",
    loginAuthType: "password",
  };

  //보안 > 세션
  @observable
  session = {
    sessionCode: "",
    sessionCodeName: "",
  };

  //보안 > 로그인허용IP주소
  @observable
  isUseIpBlock: string = "";

  //보안 > 2차 인증:PIN번호
  @observable
  pin = {
    isUse: "0",
    isLoginFailLock: "0",
    loginFailCount: 0,
    loginFailMin: "",
    loginAuthType: "pin",
  };

  //보안 > 2차 인증:OTP메일
  @observable
  otpmail = {
    isUse: "0",
    isLoginFailLock: "0",
    loginFailCount: 0,
    loginFailMin: "",
    loginAuthType: "otpmail",
  };

  //////////////////////// 목록 ////////////////////////
  //목록 > 사용자
  @observable
  referenceCount: number = 0;

  //목록 > 호스트
  @observable
  userHostCount: number = 0;

  //목록 > 응용프로그램 태그
  @observable
  logTagCount: number = 0;

  // 목록 > IP주소, MAC주소
  @observable
  tagInfo = {
    ipGlobalCount: 0,
    ipUserCount: 0,
    macGlobalCount: 0,
    macUserCount: 0,
  };

  //////////////////////// 사용 ////////////////////////
  //사용 > 데이터유출
  @observable
  isUseGlobalFilter: string = "";

  //사용 > 웹 개인정보숨김
  @observable
  logMaskingCount: number = 0;

  //사용 > 웹 탐지 예외
  @observable
  webExceptCount = 0;

  //사용 > 계정탈취 예외
  @observable
  dosExceptCount = 0;

  //사용 > 웹업로드 : 선별
  @observable
  webSelectExceptType: number = 0;

  //사용 > 웹업로드 : 예외
  @observable
  webExceptExceptType: number = 0;

  // 사용 > 포렌식 : 아티팩트
  @observable
  isUseForensicArtifact: string = "0";

  // 사용 > 포렌식 : 덤프
  @observable
  isUseForensicDump: string = "0";

  // 사용 > 제어 : 호스트 종료
  @observable
  isUseHostShutdown: string = "0";

  // 사용 > 제어 : 네트워크 격리
  @observable
  isUseHostIsolate: string = "0";

  //사용 > 호스트보안 업로드 : 선별
  @observable
  sysSelectExceptType: number = 0;

  //사용 > 호스트보안 업로드 : 예외
  @observable
  sysExceptExceptType: number = 0;

  //사용 > 응용프로그램 사용자정의 : 사용유무
  @observable
  isUseResourceConfig: string = "0";

  //사용 > 응용프로그램 사용자정의 : 사용 수
  @observable
  resourceConfigCount: number = 0;

  //사용 > 응용프로그램 Default : 선별
  @observable
  appSelectExceptType: number = 0;

  //사용 > 응용프로그램 Default : 예외
  @observable
  appExceptExceptType: number = 0;

  //사용 > 네트워크 업로드 : 선별
  @observable
  netSelectExceptType: number = 0;

  //사용 > 네트워크 업로드 : 예외
  @observable
  netExceptExceptType: number = 0;

  /////////////////////// 시스템 ///////////////////////
  //시스템 > 국가
  @observable
  nation: string = "";

  //시스템 > 언어 설정
  @observable
  lang: string = "";

  //시스템 > 시간설정
  @observable
  timezone: string = "";

  //시스템 > 커서
  @observable
  isUseCursorMark: string = "0";

  ////////////////////// 웹방화벽 //////////////////////
  //웹방화벽 > 그룹
  @observable
  wafConfigCount: number = 0;

  ////////////////////// 방화벽 //////////////////////
  // 방화벽 > 그룹
  @observable
  isDisplayFirewall: string = "0";

  @observable
  firewallCount: number = 0;

  ////////////////////// HAProxy //////////////////////
  // HAProxy > 그룹
  @observable
  isDisplayHAProxy: string = "0";

  @observable
  haproxyCount: number = 0;

  ////////////////////// SQUID //////////////////////
  // SQUID > 그룹
  @observable
  isDisplaySquid: string = "0";

  @observable
  squidCount: number = 0;
}
export default GroupModel;
