import { languageSet } from "@qubit-utill/src";
import getURI from "@src/constants/uriset";
import { TicketListContainer } from "@src/pages";

export default {
  TICKETLIST: {
    text: languageSet("발행티켓"),
    link: getURI("TICKETLIST"),
    component: TicketListContainer,
  },
};

export interface topCountListType {
  title: string;
  count: number;
  color: string;
  textColor: string;
  link: string;
  code: string;
}
