import { observable, computed } from "mobx";
import { SetterModel, Common_Utils, languageSet } from "@qubit-utill/src";
import { CSVINFO } from "@src/constants";

interface MapType {
  MB: number;
  KB: number;
  GB: number;
}

class HostResourceMonitoringModel extends SetterModel {
  @computed
  get isUseAgentFlag() {
    return Common_Utils.isCheckBool(this.isUseAgent);
  }

  @computed
  get inboundTrafficText() {
    const { unitText } = this.isByte(this.inboundTrafficMap);
    return unitText;
  }

  @computed
  get outboundTrafficText() {
    const { unitText } = this.isByte(this.outboundTrafficMap);
    return unitText;
  }

  @computed
  get inCapacityClass() {
    const { className } = this.isByte(this.inboundTrafficMap);
    return className;
  }

  @computed
  get outCapacityClass() {
    const { className } = this.isByte(this.outboundTrafficMap);
    return className;
  }

  @computed
  get isAgentActiveFlag() {
    return Common_Utils.isCheckBool(this.isAgentActive);
  }

  @computed
  get isServerResourceUpdateDelayFlag() {
    return Common_Utils.isCheckBool(this.isServerResourceUpdateDelay === null ? "0" : this.isServerResourceUpdateDelay);
  }

  @computed
  get isAgentActiveText() {
    return this.isUseAgentFlag ? languageSet(this.isAgentActiveFlag ? "정상" : "중지") : "-";
  }

  @computed
  get getCpuUsage() {
    return Common_Utils.isDataWithDashBar(this.cpuUsage);
  }

  @computed
  get getMemoryUsage() {
    return Common_Utils.isDataWithDashBar(this.memoryUsage);
  }

  @computed
  get getDiskUsage() {
    return Common_Utils.isDataWithDashBar(this.diskUsage);
  }

  @computed
  get getDiskActiveTime() {
    return Common_Utils.isDataWithDashBar(this.diskActiveTime);
  }

  @computed
  get getInboundTrafficText() {
    return Common_Utils.isDataWithDashBar(this.inboundTrafficText);
  }

  @computed
  get getOutboundTrafficText() {
    return Common_Utils.isDataWithDashBar(this.outboundTrafficText);
  }

  @computed
  get getServerResourceUpdateDate() {
    return Common_Utils.isDataWithDashBar(this.serverResourceUpdateDate);
  }

  @computed
  get getServerName() {
    return Common_Utils.isDataWithDashBar(this.serverName);
  }

  @computed
  get getServerGroup() {
    return Common_Utils.isDataWithDashBar(this.serverGroup);
  }

  parseByte = (capacity: number) => {
    return Number(capacity).toFixed(2);
  };

  isByte = (boundMap: MapType | null) => {
    if (boundMap) {
      if (Number(boundMap.GB) > 1) {
        return { unitText: `${this.parseByte(boundMap.GB)} GB/s`, className: "red" };
      } else if (Number(boundMap.MB) > 1) {
        return { unitText: `${this.parseByte(boundMap.MB)} MB/s`, className: "orange" };
      } else {
        return { unitText: `${this.parseByte(boundMap.KB)}  KB/s`, className: "" };
      }
    }
    return { unitText: "-", className: "" };
  };

  @computed
  get csvData() {
    return Common_Utils.makeCsvRowData(this, CSVINFO.RESOURCEMONITORING_HOST.infoKey);
  }

  @observable
  isAgentActive = "";
  @observable
  isServerResourceUpdateDelay = "";
  @observable
  isUseAgent = "";
  @observable
  serverGroup = "";
  @observable
  typeos = "";
  @observable
  serverIp = "";
  @observable
  serverId: number = 0;
  @observable
  hostname = "";
  @observable
  serverName = "";
  @observable
  cpuUsage = 0; //CPU
  @observable
  memoryUsage = 0; //Memory
  @observable
  diskUsage = 0; //HDD
  @observable
  diskActiveTime = 0; //Disk I/O
  @observable
  inboundTraffic = 0;
  @observable
  inboundTrafficMap: MapType | null = {
    MB: 0,
    KB: 0,
    GB: 0,
  };
  @observable
  outboundTrafficMap: MapType | null = {
    MB: 0,
    KB: 0,
    GB: 0,
  };
  @observable
  serverResourceUpdateDate = "";

  @observable
  cpuUsageTag = "NORMAL";
  @observable
  memoryUsageTag = "NORMAL";
  @observable
  diskUsageTag = "NORMAL";
  @observable
  diskActiveTimeTag = "NORMAL";
}

export default HostResourceMonitoringModel;
