import { observable, action, computed } from "mobx";
import { SetterModel, arrayForSelectBox } from "@qubit-utill/src";

export default class DataTypeModel extends SetterModel {
  constructor(param, constructorType?) {
    super(param);
    this.isSelected = param.isSelected !== undefined ? param.isSelected : false;
    this.isAttrSelected = param.isAttrSelected !== undefined ? param.isAttrSelected : false;
    this.filterElementName = param.filterElementName || "";
    this.parrentDepth = param.parrentDepth || 0;
    this.parrentFilterEventElementId = param.parrentFilterEventElementId || 0;
    this.filterEventElementId = param.filterEventElementId || null;
    this.filterElementAttributeName = param.filterElementAttributeName || "";
    this.filterElementAttributeValue = param.filterElementAttributeValue || "";
    this.filterElementRepeatSequence = param.filterElementRepeatSequence || null;
    this.filterEventElementAttributeValues = param.filterEventElementAttributeValues || "";
    this.elementAttributes = param.elementAttributes || null;
    this.filterEventElementRepeatSize = param.filterEventElementRepeatSize || 0;

    if (constructorType === "edit") {
      if (param.elementAttributes) {
        const elementAttribute = param.elementAttributes.find(
          (element) => param.filterElementAttributeName === element.filterEventElementAttributeName
        );
        if (elementAttribute) {
          this.filterEventElementAttributeValues = elementAttribute.filterEventElementAttributeValues;
        }
      }
    }
  }

  @computed
  get elementAttributesOptionList() {
    return arrayForSelectBox.parseValueObjectForSelectBox(this.elementAttributes, "filterEventElementAttributeName");
  }

  @computed
  get attributeValues() {
    return arrayForSelectBox.parsePrimitiveValueForSelectBox(this.filterEventElementAttributeValues.split(","));
  }

  @computed
  get filterEventElementRepeatSizeOptionList() {
    const optionList: Array<{
      name;
      value;
      uiId;
    }> = [];
    for (let i = 1; i <= this.filterEventElementRepeatSize; i++) {
      optionList.push({
        name: i,
        value: i,
        uiId: i,
      });
    }
    return optionList;
  }

  @observable
  isSelected: boolean;

  @observable
  filterElementName: string;

  @observable
  parrentDepth: number;

  @observable
  parrentFilterEventElementId: number;

  @observable
  filterEventElementId: number | null;

  @observable
  filterElementAttributeName: string | null;

  @observable
  filterElementAttributeValue: string | null;

  @observable
  filterElementRepeatSequence: number | null;

  @observable
  isAttrSelected: boolean;

  @observable
  filterEventElementAttributeValues: string;

  @observable
  elementAttributes: Array<any> | null;

  @observable
  filterEventElementRepeatSize: number;

  @observable
  isDefense: boolean = false;

  @action
  selectRepeatSize(value) {
    this.filterElementAttributeName = null;
    this.filterElementAttributeValue = null;
    this.filterElementRepeatSequence = value;
    this.isAttrSelected = false;
  }

  @action
  selectAttrValue(value) {
    this.filterElementAttributeValue = value;
    this.filterElementRepeatSequence = null;
  }

  @action
  selectAttrName({ filterEventElementAttributeValues, filterEventElementAttributeName }) {
    this.filterElementAttributeValue = null;
    this.filterEventElementAttributeValues = filterEventElementAttributeValues;
    this.filterElementAttributeName = filterEventElementAttributeName;
    this.isAttrSelected = true;
    this.filterElementRepeatSequence = null;
  }
}
