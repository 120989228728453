import React from "react";
import { languageSet,Common_Utils }  from "@qubit-utill/src";


interface PropTypes {
  totalCount: number;
  className?: string;
  title?: string;
}

const TotalCount: React.FC<PropTypes> = ({
  totalCount = 0,
  className = "f-right p-t-7",
  title = languageSet("총_로그_수")
}) => {
  return (
    <div className={`dis-inline-b bold f-12 c-orange ${className}`}>
      <span>{title}</span> : <span>{Common_Utils.numberWithComma(totalCount)}</span>
    </div>
  );
};

export default TotalCount;
