import { axiosModules } from "@qubit-utill/src";

export default {
  // 태그 > siem > 태그 서비스 목록
  getTagServiceList: () => axiosModules.get("/pfilter/tagserviceconfig/list"),

  // 태그 > siem > 태그 참조 유형 목록
  getTagReferenceTypeList: (tagServiceId) => axiosModules.get(`/pfilter/tagreferencetype/${tagServiceId}/list`),

  // 태그 > siem > 태그 주소 유형 서비스별 목록
  getTagValueTypeList: (tagServiceId) => axiosModules.get(`/pfilter/tagvaluetype/${tagServiceId}/list`),

  // 태그 > siem > 태그 주소 유형 조회
  getTagValueTypeInfo: (tagServiceId, tagValueType) => axiosModules.get(`/pfilter/tagvaluetype/${tagServiceId}/${tagValueType}`),

  // 태그 > siem > 태그 데이터 목록
  getTagServiceDataList: (tagServiceId, params) => axiosModules.get(`/pfilter/tagservicedata/${tagServiceId}/list`, params),

  // 태그 > siem > 태그 데이터 조회
  getTagServiceDataInfo: (tagServiceDataId) => axiosModules.get(`/pfilter/tagservicedata/${tagServiceDataId}`),

  // 태그 > siem > 태그 property 목록
  getTagServicePropertyList: (tagServiceId) => axiosModules.get(`/pfilter/tagserviceproperty/${tagServiceId}/list`),

  // 태그 > siem > 태그 데이터 등록
  createSingleTagServiceData: (tagServiceId, params) => axiosModules.post(`/pfilter/tagservicedata/${tagServiceId}`, params),

  // 태그 > siem > 태그 데이터 다중 등록 체크
  checkCreateTagServiceData: (tagServiceId, params) =>
    axiosModules.post(`/pfilter/tagservicedatas/${tagServiceId}/check`, params),

  // 태그 > siem > 태그 데이터 다중 등록
  createMultiTagServiceData: (tagServiceId, params) => axiosModules.post(`/pfilter/tagservicedatas/${tagServiceId}`, params),

  // 태그 > siem > 태그 데이터 수정
  updateTagServiceData: (tagServiceDataId, params) => axiosModules.put(`/pfilter/tagservicedata/${tagServiceDataId}`, params),

  // 태그 > siem > 태그 데이터 on/off 수정
  updateIsUseTagServiceData: (tagServiceDataId, params) =>
    axiosModules.put(`/pfilter/tagservicedata/isuse/${tagServiceDataId}`, params),

  // 태그 > siem > 태그 데이터 다중 삭제
  deleteTagServiceData: (params) => axiosModules.del("/pfilter/tagservicedatas", params),

  // 태그 > siem > 태그 데이터 다중 삭제 (조건) : 전체삭제
  deleteTagServiceDataAll: (tagServiceId, params) => axiosModules.patch(`/pfilter/tagservicedatas/${tagServiceId}`, params),
};
