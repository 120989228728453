import { computed, observable } from "mobx";

import { SetterModel, Common_Utils } from "@qubit-utill/src";

export default class ExceptEntryModel extends SetterModel {
  @computed
  get isRegexFlag() {
    return Common_Utils.isCheckBool(this.isRegex);
  }
  @observable
  isRegex: string = "0";

  @observable
  exceptEntryType: string = "";

  @observable
  exceptEntryValue: string = "";

  @observable
  sysUploadExceptEntryId: number = 0;

  @observable
  webUploadExceptEntryId: number = 0;

  @observable
  appUploadExceptEntryId: number = 0;

  @observable
  netUploadExceptEntryId: number = 0;

  @computed
  get apiData() {
    const { isRegex, exceptEntryType, exceptEntryValue } = this;
    return {
      isRegex,
      exceptEntryType,
      exceptEntryValue,
    };
  }
  @computed
  get webApiData() {
    return {
      ...this.apiData,
      webUploadExceptEntryId: this.webUploadExceptEntryId,
    };
  }

  @computed
  get systemApiData() {
    return {
      ...this.apiData,
      sysUploadExceptEntryId: this.sysUploadExceptEntryId,
    };
  }

  @computed
  get appApiData() {
    return {
      ...this.apiData,
      appUploadExceptEntryId: this.appUploadExceptEntryId,
    };
  }

  @computed
  get networkApiData() {
    return {
      ...this.apiData,
      netUploadExceptEntryId: this.netUploadExceptEntryId,
    };
  }
}
