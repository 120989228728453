import { observable, action, computed } from "mobx";
import { SetterModel, Common_Utils } from "@qubit-utill/src";

import { FILTER_OPERATOR_OPTION, CUSTOM_INPUT_OPTION_REGEX } from "@src/constants/code";

export interface dataTypeAttributeModelType {
  setFilterElementSearchType: (value) => void;
  filterElementSearchTypeName: Function;
  filterElementSearchType: string;
  setSelectFilterElementSearchType: (value, name) => void;
  filterElementSearchDisplayText: string;
  filterElementSearchText: string;
  setFilterElementSearchText: (text) => void;
  filterElementSearchFlagName: Function;
  setFilterElementSearchFlag: (value) => void;
}

export default class DataTypeAttributeModel extends SetterModel {
  constructor(param?) {
    super(param);
    if (
      param &&
      param.filterElementReferenceList &&
      param.filterElementSearchType !== "input" &&
      param.filterElementSearchType !== "regex"
    ) {
      const item = param.filterElementReferenceList.find(
        ({ value }) => value.filterElementReferenceId === param.filterElementSearchText
      );
      if (item) {
        this.filterElementSearchDisplayText = item.name;
      } else {
        this.filterElementSearchDisplayText = param.filterElementSearchText;
      }
    }
  }

  @action
  setFilterElementSearchText = (text) => {
    this.filterElementSearchText = Common_Utils.getBlockFirstBlank(text);
  };

  @computed
  get filterElementSearchFlagName() {
    const getOptionValue = FILTER_OPERATOR_OPTION.find((option) => {
      return this.filterElementSearchFlag === option.value;
    });

    return getOptionValue && getOptionValue.name;
  }

  @action
  setFilterElementSearchFlag = ({ value }) => {
    this.filterElementSearchFlag = value;
  };

  @action
  setFilterElementSearchType = ({ value }) => {
    this.filterElementSearchType = value;
    this.filterElementSearchText = "";
    this.filterElementSearchDisplayText = "";
    this.filterElementSearchFlag = FILTER_OPERATOR_OPTION[0].value;
  };

  @computed
  get filterElementSearchTypeName() {
    let setSelectName;
    const getOptionValue = CUSTOM_INPUT_OPTION_REGEX.find((option) => {
      if (option.value === "select") {
        setSelectName = option.name;
      }
      return this.filterElementSearchType === option.value;
    });

    return getOptionValue ? getOptionValue.name : setSelectName;
  }

  @action
  setSelectFilterElementSearchType = ({ value, name }) => {
    this.filterElementSearchType = value.filterElementReferenceType;
    this.filterElementSearchText = value.filterElementReferenceId;
    this.filterElementSearchDisplayText = name;
  };

  @observable
  filterElementSearchText: string = "";

  @observable
  filterElementRepeatSequence: number = 1;

  @observable
  filterElementSearchType: string = CUSTOM_INPUT_OPTION_REGEX[0].value;

  @observable
  filterElementSearchFlag: string = FILTER_OPERATOR_OPTION[0].value;

  @observable
  filterElementSearchDisplayText: string = "";

  @observable
  parrentDepth: number = 0;

  @observable
  isSelected: boolean = true;
}
