import { action, observable } from "mobx";

import { withLoading } from "@qubit-utill/src";

import { wafConfigApis } from "@src/apis/pServer";
import { filterApis } from "@src/apis/pFilter";

import { GroupWafModel } from "@src/pages/Group/WAF/models";

class GroupWafStore {
  constructor(root) {
    this.root = root;
  }
  root = {};

  @observable
  isLoading = false;

  @observable
  isViewLoading = true;

  @observable
  hasFilter = false;

  @observable
  list: GroupWafModel[] = [];

  @observable
  countryList = [] as {
    code: string;
    name: string;
  }[];

  @observable
  info: GroupWafModel = new GroupWafModel(this.root);

  @observable
  originInfo: GroupWafModel = new GroupWafModel(this.root);

  @withLoading("isLoading")
  @action
  getList = async () => {
    try {
      const { list } = await wafConfigApis.getWafConfigList();
      this.list = list.map((info) => new GroupWafModel(this.root, info));
    } catch (error) {
      this.list = [];
      console.log(error);
    }
  };

  @withLoading("isViewLoading")
  @action
  getData = async (wafConfigId) => {
    try {
      const { info } = await wafConfigApis.getWafConfig(wafConfigId);
      this.info = new GroupWafModel(this.root, info);
      this.originInfo = new GroupWafModel(this.root, info);
    } catch (error) {
      this.info = new GroupWafModel(this.root);
      this.originInfo = new GroupWafModel(this.root);
      console.log(error);
    }
  };

  @withLoading("isLoading")
  @action
  createGroup = async (groupId) => {
    try {
      await wafConfigApis.createWafConfig(groupId);
      this.getList();
    } catch ({ data }) {
      throw data;
    }
  };

  @withLoading("isLoading")
  @action
  deleteGroup = async (wafConfigId) => {
    try {
      await wafConfigApis.deleteWafConfig(wafConfigId);
      this.getList();
    } catch ({ data }) {
      throw data;
    }
  };

  @action
  updateInfo = (key) => async () => {
    try {
      const { wafConfigId } = this.info;
      await wafConfigApis.updateWafConfig(wafConfigId, { [key]: this.info[key] });
      this.getData(wafConfigId);
    } catch ({ data }) {
      throw data;
    }
  };

  @action
  updateWafUploadMode = async (wafConfigId, data) => {
    try {
      await wafConfigApis.updateWafConfig(wafConfigId, data);
      this.getData(wafConfigId);
    } catch ({ data }) {
      throw data;
    }
  };

  @action
  updateXForwardInfo = async () => {
    try {
      const { wafConfigId } = this.info;
      await wafConfigApis.updateWafConfig(wafConfigId, {
        isInXForwardedFor: this.info.isInXForwardedFor,
        outXForwardedForType: this.info.outXForwardedForType,
      });
      this.getData(wafConfigId);
    } catch ({ data }) {
      throw data;
    }
  };

  @action
  updateLimit = async () => {
    try {
      const { wafConfigId, limitApiData } = this.info;
      await wafConfigApis.updateWafConfig(wafConfigId, limitApiData);
      this.getData(wafConfigId);
    } catch ({ data }) {
      throw data;
    }
  };

  @action
  updateProxy = async () => {
    try {
      const { isUseProxy, wafConfigId, proxyList } = this.info;
      await wafConfigApis.updateWafProxyConfig(wafConfigId, {
        isUseProxy,
        wafConfigProxyList: proxyList.map(({ apiData }) => apiData),
      });
      this.getData(wafConfigId);
    } catch ({ data }) {
      throw data;
    }
  };

  @action
  updateHealth = async () => {
    try {
      const { wafConfigId, isUseHealthChecker, healthList } = this.info;
      await wafConfigApis.updateWafHealthConfig(wafConfigId, {
        isUseHealthChecker,
        wafConfigHealthCheckerList: healthList.map(({ apiData }) => apiData),
      });
      this.getData(wafConfigId);
    } catch ({ data }) {
      throw data;
    }
  };

  @action
  updateWafSync = async (wafConfigId) => {
    try {
      await wafConfigApis.updateWafSync(wafConfigId);
      this.getList();
    } catch ({ data }) {
      console.log(data);
      throw data;
    }
  };

  @action
  updateNotice = async () => {
    try {
      const { wafConfigId, isMaintenance, isUseMaintenanceExceptIp, noticeText, wafConfigExceptIpList } = this.info;
      const _wafConfigExceptIpList = wafConfigExceptIpList
        ? wafConfigExceptIpList
            .map((exceptIp) => ({
              ipAddress: exceptIp.ipAddress,
              exceptType: "notice",
            }))
            .reverse()
        : [];

      await wafConfigApis.updateWafConfig(wafConfigId, {
        isMaintenance,
        isUseMaintenanceExceptIp,
        noticeText,
        wafConfigExceptIpList: _wafConfigExceptIpList,
      });
      this.getData(wafConfigId);
    } catch ({ data }) {
      console.log(data);
      throw data;
    }
  };

  @action
  getFilterCount = async () => {
    try {
      const { hasFilter } = await filterApis.getDosFilterCount();
      this.hasFilter = hasFilter;
    } catch (error) {}
  };

  @action
  getSelectHostList = (hostList, indexNumber) => {
    if (hostList.length > 0) {
      this.info.proxyList[indexNumber].userHostReferenceList = hostList.filter(({ isChecked }) => isChecked);

      this.info.proxyList[indexNumber].userHostIds = [];
      this.info.proxyList[indexNumber].userHostReferenceList.map(({ userHostId }) =>
        this.info.proxyList[indexNumber].userHostIds.push(userHostId)
      );
    }
  };

  @action
  deleteIp = (index) => {
    return this.info.wafConfigExceptIpList.splice(index, 1);
  };

  @action
  updateBlockNotice = async () => {
    try {
      const { wafConfigId, blockNoticeText } = this.info;
      await wafConfigApis.updateWafConfig(wafConfigId, { blockNoticeText, isBlockNoticeUpdate: "1" });
      this.getData(wafConfigId);
    } catch ({ data }) {
      console.log(data);
      throw data;
    }
  };
}

export default GroupWafStore;
