// 인섬니아 : pserver > pserver > Middleware > 그룹
// 컴포넌트 : 관리 > HAProxy / SQUID

import { axiosModules } from "@qubit-utill/src";

export default {
  // 그룹 리스트 조회
  getMiddlewareGroupList: (middlewareId, params?) => {
    return axiosModules.get(`/pserver/middleware/list/${middlewareId}`, params);
  },
  // 그룹 정보 조회
  getMiddlewareGroupInfo: (middlewareUserConfigId) => {
    return axiosModules.get(`/pserver/middleware/${middlewareUserConfigId}`);
  },
  // 그룹 추가
  createMiddlewareGroup: (middlewareId, param) => {
    return axiosModules.post(`/pserver/middleware/init/${middlewareId}`, param);
  },
  // 그룹 삭제
  deleteMiddlewareGroup: (middlewareUserConfigId) => {
    return axiosModules.del(`/pserver/middleware/${middlewareUserConfigId}`);
  },
  // 그룹 설정검사
  validationConfigMiddlewareGroup: (middlewareId, params) => {
    return axiosModules.put(`/pserver/middlewareuserconfig/validateconf/${middlewareId}`, params);
  },
  // 그룹 최종적용
  applicationMiddlewareGroup: (middlewareId, params) => {
    return axiosModules.put(`/pserver/middlewareuserconfig/deployconf/${middlewareId}`, params);
  },
  // 그룹 재설정
  reloadMiddlewareGroup: (serverGroupId, middlewareUserConfigId, middlewareId) => {
    return axiosModules.post(`/pserver/middlewareexecutecmd/${serverGroupId}/${middlewareUserConfigId}/reload/${middlewareId}`);
  },
  // 그룹 재시작
  restartMiddlewareGroup: (serverGroupId, middlewareUserConfigId, middlewareId) => {
    return axiosModules.post(`/pserver/middlewareexecutecmd/${serverGroupId}/${middlewareUserConfigId}/restart/${middlewareId}`);
  },
};
