import React from "react";
import { languageSet } from "@qubit-utill/src";

interface Props {
  height?: number;
  nullNotice?: string;
}

const NullLogData: React.FC<Props> = ({ height = 250, nullNotice = languageSet("에러_발생로그없습니다") }) => {
  return (
    <div
      className="v_table "
      style={{
        height: `${height}px`,
      }}
    >
      <div className="v_cell">
        <span className="c-red">{nullNotice}</span>
      </div>
    </div>
  );
};

export default NullLogData;
