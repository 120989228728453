import { observable, action } from "mobx";

import { withLoading } from "@qubit-utill/src/decorator";
import { roleLicenseApis } from "@qubit-utill/src/apis";

import { pAccountApis, pAuthApis } from "@src/apis";
import { UserModel, UserPasswordChangeModel } from "@src/common/models";

class MyInfoStore {
  constructor(root) {
    this.root = root;
  }

  root;

  @observable
  isLoading: boolean = false;

  @observable
  useLicense: object = {};

  @observable
  userInfo: UserModel = new UserModel({}, this.useLicense, this.root.CodeStore);

  @observable
  userPasswordChangeInfo: UserPasswordChangeModel = new UserPasswordChangeModel();

  @observable
  updateData: object = {};

  @observable
  passwordInfo: object = {
    password0: "",
    password1: "",
    password2: "",
  };

  @withLoading("isLoading")
  @action
  getUserInfo = async () => {
    try {
      const [{ info }, { useLicense }, { pin }] = await Promise.all([
        pAccountApis.getUserInfo(),
        roleLicenseApis.getUseLicenseInfo(),
        pAuthApis.getUserGroupLoginAuth(),
        this.root.CodeStore.getRoleList(),
      ]);

      info.lastLoginSuccessDate = await this.getLastLoginDate();
      info.isPin = await pin.isUse;

      this.useLicense = useLicense;

      const { info: passwordChangeInfo } = await pAuthApis.getPasswordChangeInfoCheck();

      this.userPasswordChangeInfo = new UserPasswordChangeModel(passwordChangeInfo);

      // 에이전트 인스톨 설치 동의 여부 확인
      const agentParams = { agreementSourceId: 1, uuid: info.uuid, guid: info.guid };
      const { totalCount } = await pAuthApis.getAgreementRegistration(agentParams);
      info.serviceAgreement = totalCount > 0 ? true : false;

      this.userInfo = new UserModel(info, useLicense, this.root.CodeStore);
    } catch (e) {
      console.log("data", e);
      this.userInfo = new UserModel({}, this.useLicense, this.root.CodeStore);
      this.userPasswordChangeInfo = new UserPasswordChangeModel();
    }
  };

  @action
  getLastLoginDate = async () => {
    const param = {
      limit: 1,
      offset: 1,
      orderDesc: "DESC",
    };
    try {
      const { list } = await pAuthApis.getUserLoginLogListByUuid(param);
      return list && list[0].registerDate;
    } catch (e) {
      return null;
    }
  };

  @action
  setUpdateData = (key) => (value) => {
    this.updateData = {
      [key]: value,
    };
  };

  @action
  setPasswordInfo = (elementName: string, value: any) => {
    this.passwordInfo[elementName] = value;
  };

  @action
  updateInfo = async (data, key) => {
    try {
      const { info } = await pAccountApis.putUserInfo(
        key
          ? {
              [key]: this.userInfo[key],
            }
          : data
      );

      const { pin } = await pAuthApis.getUserGroupLoginAuth();
      info.isPin = pin.isUse;

      this.userInfo = new UserModel(info, this.useLicense, this.root.CodeStore);
      key && delete this.updateData[key];
    } catch ({ data }) {
      return data;
    }
  };

  @action
  updateCompanyName = async () => {
    try {
      await pAccountApis.updateUser(this.userInfo.guid, this.updateData);
      this.getUserInfo();
    } catch ({ data }) {
      return data;
    }
  };
}

export default MyInfoStore;
