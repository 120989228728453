import { observable } from "mobx";
import { SetterModel } from "@qubit-utill/src";

class FilterChannelModel extends SetterModel {
  constructor(params) {
    super(params);
    this.name = params.filterChannelName || "";
    this.value = params || {};
    this.filterChannelId = params.filterChannelId || 0;
    this.filterChannelName = params.filterChannelName || "";
    this.isSingleEvent = params.isSingleEvent || "";
    this.typeos = params.typeos || "";
    this.filterChannelOrderNo = params.filterChannelOrderNo || 0;
    this.isUse = params.isUse || "";
  }

  @observable
  name: string;

  @observable
  value: Object;

  @observable
  filterChannelId: number;

  @observable
  filterChannelName: string;

  @observable
  isSingleEvent: string;

  @observable
  typeos: string;

  @observable
  filterChannelOrderNo: number;

  @observable
  isUse: string;
}
export default FilterChannelModel;
