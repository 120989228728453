import { observable, action, computed } from "mobx";
import ThemeOptionModel from "./ThemeOptionModel";

interface InfoType {
  className: string;
  servicePreferencesItemId: number;
}

class ThemeInfoModel {
  constructor(params?: ThemeInfoModel) {
    if (params) {
      this.MENU_COLOR = params.MENU_COLOR;
      this.BG_COLOR = params.BG_COLOR;
      this.THEME_COLOR = params.THEME_COLOR;
    } else {
      this.MENU_COLOR = { className: "", servicePreferencesItemId: 0 };
      this.BG_COLOR = { className: "", servicePreferencesItemId: 0 };
      this.THEME_COLOR = { className: "", servicePreferencesItemId: 0 };
    }
  }

  @action
  setInfo = (key: string, info: ThemeOptionModel) => {
    this[key] = { servicePreferencesItemId: info.servicePreferencesItemId, className: info.servicePreferencesItemValue };
  };

  @computed
  get apiData() {
    return [
      {
        servicePreferencesId: "THEME_COLOR",
        servicePreferencesItemId: this.THEME_COLOR.servicePreferencesItemId,
      },
      {
        servicePreferencesId: "MENU_COLOR",
        servicePreferencesItemId: this.MENU_COLOR.servicePreferencesItemId,
      },
      {
        servicePreferencesId: "BG_COLOR",
        servicePreferencesItemId: this.BG_COLOR.servicePreferencesItemId,
      },
    ];
  }

  @observable
  THEME_COLOR: InfoType;

  @observable
  BG_COLOR: InfoType;

  @observable
  MENU_COLOR: InfoType;
}

export default ThemeInfoModel;
