export default {
  validParam(arr, callBack) {
    if (arr) {
      return callBack(arr);
    } else {
      return [];
    }
  },
  //data parsing for selectbox react component
  parseValueForSelectBox(prevArr, pValue, pName) {
    return this.validParam(prevArr, () =>
      prevArr.map(value => ({
        value: value[pValue],
        name: value[pName],
      }))
    );
  },

  parseValueForSelectBoxDefault(prevArr) {
    return this.validParam(prevArr, () =>
      prevArr.map(({ value, name }) => ({
        value,
        name,
      }))
    );
  },

  //셀렉트 옵션의 value를 오브젝트로 반환하기 위해 변환
  parseValueObjectForSelectBox: function(prevArr, pName) {
    return this.validParam(prevArr, () =>
      prevArr.map(value => ({
        value,
        name: value[pName],
      }))
    );
  },

  //원시 원소 배열을 셀렉트 옵션 형태로 변경 해준다.
  parsePrimitiveValueForSelectBox: function(prevArr) {
    return this.validParam(prevArr, () =>
      prevArr.map(value => ({
        value,
        name: value,
      }))
    );
  },
};
