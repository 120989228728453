import { observable, action, toJS } from "mobx";

import { ListCondition, withLoading, languageSet, Common_Utils, SearchWordModel } from "@qubit-utill/src";

import { applicationDetectApis } from "@src/apis/pLogDetect";
import { complianceSettingApis } from "@src/apis/pFilter";
import { resourceConfigUserApis, resourceMetaApis } from "@src/apis/pMetrics";
import { SelectOptionModel } from "@src/common/models";
import { CSVINFO, UISET } from "@src/constants";

import { DefenceStore } from "@src/common/stores";
import ApplicationResourceFilterDetectModel from "@src/pages/FilterDetection/ApplicationFilterDetection/Resource/models/ApplicationResourceFilterDetectModel";

class ApplicationResourceFilterDetectStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }

  root;

  @observable
  tableList: ApplicationResourceFilterDetectModel[] = [];

  @observable
  applicationFilterDetectInfo = new ApplicationResourceFilterDetectModel(null, this.root.CodeStore);

  @observable
  ipDefenceType = "DEFENCEIP_APPLICATION";

  @action
  clear = () => {
    this.tableList = [];
    this.applicationFilterDetectInfo = new ApplicationResourceFilterDetectModel(null, this.root.CodeStore);
  };

  @observable
  resourceThList = [];

  @observable
  resourceMetaList = [];

  @observable
  optionList: SelectOptionModel[] = [];

  @observable
  resourceMetaSearchParams = [];

  @action
  setResourceParams = (resourceId: string, isGetData: boolean, isClearMetaParmas: boolean) => {
    isClearMetaParmas && this.clearMetaParams();
    this.resourceMetaSearchParams = [];
    const searchKey = this.root.SearchWordStore.getPathName(resourceId);
    const localList = window.localStorage.getItem(searchKey);

    const params = localList && observable(JSON.parse(localList).map((data) => new SearchWordModel(data, true)));

    params &&
      params.map((info) => {
        if (info.isApply) {
          if (info.isMetaProperty === "1") {
            info.resourceMetaSearchField.forEach((value) => {
              if (value.includes("resourceMetaId")) {
                this.setListParams(value, info.searchOption);
              } else {
                this.setListParams(value, info.assign !== "" ? `${info.assign}:${info.searchText}` : info.searchText);
              }
            });
          } else {
            this.setListParams(info.searchOption, info.assign !== "" ? `${info.assign}:${info.searchText}` : info.searchText);
          }
        }
      });

    this.setListParams("resourceId", resourceId);
    isGetData && this.getList(false);
  };

  //서비스 리스트
  @action
  getServiceListForOption = async () => {
    try {
      const { list } = await resourceConfigUserApis.getResourceConfigUserGroupList();
      this.optionList = list.map((info) => new SelectOptionModel({ name: info.resourceName, code: info.resourceId }));

      if (this.optionList.length > 0) {
        this.params = this.params.resourceId ? this.params : { ...this.params, resourceId: this.optionList[0].code };
      }

      //보고서에서 넘어올 경우 전달받은 리소스 아이디가 있으면 파라미터에 새로 셋팅
      if (sessionStorage.getItem("resourceId")) {
        this.params = { ...this.params, resourceId: sessionStorage.getItem("resourceId") };
      }
    } catch ({ data }) {
      this.optionList = [];
      return data;
    }
  };

  @action
  getResourceMetaList = async () => {
    try {
      const { list } = await resourceMetaApis.getResourceMetaList(this.params.resourceId);
      return list;
    } catch (e) {
      return [];
    }
  };

  @withLoading("isLoading")
  @action
  getList = async (isSetResourceParams: boolean = true) => {
    const tagServicePropertyList = this.tagServicePropertyList;
    const tagValueTypeList = this.tagValueTypeList;
    const getTagServiceDataList = this.openIpTagModal;

    await this.getServiceListForOption();
    isSetResourceParams && (await this.setResourceParams(this.params.resourceId, false, false));
    try {
      const resourceMetaList = await this.getResourceMetaList();
      this.resourceMetaList = resourceMetaList;

      this.resourceThList = resourceMetaList
        ? resourceMetaList.map((data) => {
            return {
              displayName: data.metaName,
              columnName: data.metaName,
              useSort: true,
              useCustom: true,
              className: "w_110px",
            };
          })
        : [];

      const { list, totalCount, nextCursorMark } = await applicationDetectApis.getResourceLogDetectList(this.params);
      this.tableList = list.map((info) => {
        const _info = { ...info, resourceMetaList, tagServicePropertyList, tagValueTypeList, getTagServiceDataList };
        new ApplicationResourceFilterDetectModel(_info, this.root.CodeStore);
      });

      this.setCursorMarkInfo(nextCursorMark);
      this.totalCount = totalCount;

      sessionStorage.removeItem("resourceId");
    } catch (e) {
      this.tableList = [];
      this.totalCount = 0;
    }
  };

  getComplianceEntityCountByFilterKeys = async (filterIds) => {
    try {
      const { totalCount } = await complianceSettingApis.getComplianceEntityCountByFilterKeys(filterIds);

      return totalCount;
    } catch (e) {
      return 0;
    }
  };

  @withLoading("isSubLoading")
  @action
  getInfo = async (documentId) => {
    try {
      const tagServicePropertyList = this.tagServicePropertyList;
      const tagValueTypeList = this.tagValueTypeList;
      const getTagServiceDataList = this.openIpTagModal;
      const getTagServiceDataListFn = this.getTagServiceDataList;

      const { info, viewTemplate, filterInfoList, programMessageTemplate } = await applicationDetectApis.getResourceLogDetect(
        documentId
      );

      const _info = { ...info, tagServicePropertyList, tagValueTypeList, getTagServiceDataList, getTagServiceDataListFn };

      const resourceMetaList = this.resourceMetaList;
      this.applicationFilterDetectInfo = await new ApplicationResourceFilterDetectModel(
        {
          ..._info,
          viewTemplate,
          filterInfoList,
          programMessageTemplate,
          resourceMetaList,
        },
        this.root.CodeStore
      );
      const totalCount = await this.getComplianceEntityCountByFilterKeys(this.applicationFilterDetectInfo.filterIds);
      this.applicationFilterDetectInfo.setInfo("complianceEntityCount", totalCount);

      this.applicationFilterDetectInfo.attackIp && (await DefenceStore.getDefenceInfo(this.applicationFilterDetectInfo)); //get 방어정보
    } catch (err) {
      console.log(err);
      this.applicationFilterDetectInfo = new ApplicationResourceFilterDetectModel(null, this.root.CodeStore);
    }
  };

  @action
  downloadCSV = async (isUseBulk?, no?) => {
    //사용자정의 항목은 column이 동적정보(리소스정보) 이기 때문에 다음과 같이 처리함
    let appResource = JSON.stringify(CSVINFO.FILTERDETECT_APP_RESOURCE_BASIC);
    CSVINFO.FILTERDETECT_APP_RESOURCE = JSON.parse(appResource);

    this.resourceMetaList.map((info, index) => CSVINFO.FILTERDETECT_APP_RESOURCE.head.splice(7 + index, 0, info["metaName"]));
    this.resourceMetaList.map((info, index) => CSVINFO.FILTERDETECT_APP_RESOURCE.infoKey.splice(7 + index, 0, info["metaName"]));
    const resourceName = this.resourceMetaList[0]["resourceName"];

    try {
      if (this.tableList.length > 0) {
        let csv;
        if (isUseBulk && no) {
          csv = await applicationDetectApis.getResourceLogDetectCsvList({
            ...toJS(this.params),
            // serverIds: toJS(this.params.serverIds),
            // typeosList: toJS(this.params.typeosList),
            // serverGroupIds: toJS(this.params.serverGroupIds),
            offset: 0,
            limit: no,
          });
        } else {
          csv = Common_Utils.makeCSV(CSVINFO.FILTERDETECT_APP_RESOURCE.head, this.tableList);
        }

        Common_Utils.csvDownload(
          csv,
          Common_Utils.setCsvFileName({
            title: UISET.FILTERDETECT_APP_RESOURCE.title,
            category: `${languageSet("사용자정의")}_${resourceName}_${languageSet("필터탐지")}`,
            ...this.params,
          })
        );
      } else {
        return languageSet("에러_데이터가없습니다");
      }
    } catch ({ data }) {
      console.log(data);
    }
  };
}

export default ApplicationResourceFilterDetectStore;
