import { observable } from "mobx";
import { dateUtil } from "@qubit-utill/src";
import BarGraphModel from "@src/pages/Report/models/extends/BarGraphModel";

class StackBarModel extends BarGraphModel {
  constructor(param) {
    super(param);
    if (param) {
      const { categoryGroupList, dailySumList, category, weeklyFilterCategorySumList, detectDate, isMain, isResource } =
        param || {};
      if (categoryGroupList) {
        this.chartData = categoryGroupList.map((data) => ({
          name: data.filterCategoryGroupName || data.codeName,
          filterCategoryGroupId: data.filterCategoryGroupId,
          data: dailySumList || weeklyFilterCategorySumList ? category.map(() => null) : null,
        }));
      }

      if (dailySumList) {
        dailySumList.forEach((data) => {
          let index = this.getIndex(
            this.chartData,
            isResource ? data.resourceId : data.filterCategoryGroupId,
            "filterCategoryGroupId"
          );
          let subIndex = this.getIndex(category, data.detectDate, "index");
          if (index >= 0 && subIndex >= 0) this.chartData[index].data[subIndex] = data.detectCount;
        });
      }

      if (weeklyFilterCategorySumList) {
        this.category = [];
        let _category = dateUtil.setWeekWithMonthForReport(detectDate);
        const weekList = category.map((data, index) => {
          this.category.push(_category[index]);
          return data.detectWeek;
        });

        isMain
          ? weeklyFilterCategorySumList.forEach((data) => {
              let index = data.index;
              let subIndex = this.getIndex(weekList, data.detectWeek, "index");
              if (index >= 0 && subIndex >= 0) this.chartData[index].data[subIndex] = data.detectCount;
            })
          : weeklyFilterCategorySumList.forEach((data, _index) => {
              let index = isResource ? 0 : this.getIndex(this.chartData, data.filterCategoryGroupId, "filterCategoryGroupId");
              let subIndex = this.getIndex(weekList, data.detectWeek, "index");
              if (index >= 0 && subIndex >= 0) this.chartData[index].data[subIndex] = data.detectCount;
            });
      }
    }
  }

  @observable
  category: string[] = [];

  @observable
  chartData: { data: number[] }[] = [];
}

export default StackBarModel;
