import { observable, action } from "mobx";

import { withLoading, ListCondition } from "@qubit-utill/src";

import { middlewareGroupApis, middlewareConfigApis } from "@src/apis/pServer";

import { GroupFirewallModel, GroupFirewallSectionModel } from "@src/pages/Group/Firewall/models";

export default class GroupFirewallStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }

  root;

  @observable
  middlewareId = "FIREWALL";

  @observable
  firewallGroupList: Array<GroupFirewallModel> = [];

  @observable
  firewallSectionInfo: GroupFirewallSectionModel = new GroupFirewallSectionModel();

  @observable
  middlewareUserConfigId: number = 0;

  @observable
  serverGroupId: number = 0;

  @observable
  firewalldInfo = {};

  @withLoading("isLoading")
  @action
  getList = async () => {
    try {
      const { list } = await middlewareGroupApis.getMiddlewareGroupList(this.middlewareId);
      this.firewallGroupList = list.map((groupInfo) => new GroupFirewallModel(groupInfo));
    } catch ({ data }) {
      this.firewallGroupList = [];
      console.log("error : ", data);
    }
  };

  @withLoading("isSubLoading")
  @action
  getInfo = async (middlewareUserConfigId) => {
    try {
      const { info } = await middlewareGroupApis.getMiddlewareGroupInfo(middlewareUserConfigId);
      this.firewallSectionInfo = new GroupFirewallSectionModel(info);
      this.firewalldInfo = info.middlewareFirewalldList[0];
      this.middlewareUserConfigId = info.middlewareUserConfigId;
      this.serverGroupId = info.serverGroupId;
    } catch ({ data }) {
      this.firewallSectionInfo = new GroupFirewallSectionModel();
      this.firewalldInfo = {};
      this.middlewareUserConfigId = 0;
      this.serverGroupId = 0;
      console.log("error : ", data);
    }
  };

  @action
  createGroup = async (serverGroupId) => {
    try {
      await middlewareGroupApis.createMiddlewareGroup(this.middlewareId, { serverGroupId });
      this.getList();
    } catch ({ data }) {
      throw data;
    }
  };

  @action
  deleteGroup = async (middlewareUserConfigId) => {
    try {
      await middlewareGroupApis.deleteMiddlewareGroup(middlewareUserConfigId);
      this.getList();
    } catch ({ data }) {
      return data;
    }
  };

  @action
  updateMiddlewareFirewalld = async (param) => {
    try {
      await middlewareConfigApis.updateMiddlewareFirewalld(this.middlewareUserConfigId, param);
      this.getInfo(this.middlewareUserConfigId);
    } catch ({ data }) {
      throw data;
    }
  };

  @action
  executeMiddlewareFirewalld = async () => {
    try {
      await middlewareConfigApis.executeMiddlewareFirewalld(this.middlewareUserConfigId, this.serverGroupId, this.middlewareId);
    } catch ({ data }) {
      return data;
    }
  };
}
