import { action, computed, observable } from "mobx";
import { languageSet, SetterModel } from "@qubit-utill/src";

export default class GroupWafHealthModel extends SetterModel {
  constructor(params?) {
    super(params);
    this.ipAddressList.length === 0 && this.pustIp();
    if (params) {
      const { wafConfigHealthCheckerAllowList } = params;
      if (wafConfigHealthCheckerAllowList && wafConfigHealthCheckerAllowList.length > 0) {
        this.ipAddressList = wafConfigHealthCheckerAllowList.map(({ allowIp }) => allowIp);
      }
    }
  }
  @observable
  wafConfigHealthCheckerId = 0;
  @observable
  listenPort: number | string = 80;
  @observable
  httpHost = "127.0.0.1";
  @observable
  ipAddressList: string[] = [];
  @action
  pustIp = () => {
    this.ipAddressList.push("");
  };
  @action
  setIpAddress = (index) => (value) => {
    this.ipAddressList[index] = value;
  };
  @action
  deleteIp = (index) => {
    this.ipAddressList.splice(index, 1);
  };
  validation = () => {
    if (this.listenPort === "") {
      throw languageSet("메세지_listenPort입력해주세요");
    }
    if (this.httpHost === "") {
      throw languageSet("메세지_Host를입력");
    }
    if (this.ipAddressList.some((ip) => ip.length === 0)) {
      throw languageSet("메세지_허용IP주소를입력해주세요");
    }
  };
  @computed
  get apiData() {
    const { wafConfigHealthCheckerId, listenPort, httpHost, ipAddressList } = this;
    return {
      wafConfigHealthCheckerId,
      listenPort,
      httpHost,
      wafConfigHealthCheckerAllowList: ipAddressList.map((allowIp) => ({ allowIp })),
    };
  }
}
