import { observable, action, computed } from "mobx";

import { ListCondition, Common_Utils, withLoading, arrayUtils } from "@qubit-utill/src";

import { serverApis, logTagApis } from "@src/apis/pServer";

import { LogConfigPathModel } from "@src/pages/Server/ServerManagement/models";
import { LogTagListModel } from "@src/pages/Group/RefItem/models";

class LogConfigPathModalStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }

  root;

  @observable
  tableList: LogConfigPathModel[] = [];

  @observable
  uiTableList: LogConfigPathModel[] = [];

  @observable
  serverId = 0;

  @observable
  logTagList = [];

  @action
  setData = (key, value) => {
    this[key] = value;
  };

  @computed
  get checkList() {
    let list: string[] = [];
    this.uiTableList.forEach(({ isSelected, configPath }) => {
      isSelected && list.push(configPath);
    });
    return list;
  }

  @action
  setIsDelFilterList = () => {
    this.uiTableList.forEach((info) => {
      if (info.isSelected === true) info.setInfo("isDel", true);
    });
  };

  @action
  allCheck = (isSelected) => {
    this.uiTableList.forEach((data) => data.setInfo("isSelected", !isSelected));
  };

  @withLoading("isLoading")
  @action
  getList = async (serverId?) => {
    const _serverId = isNaN(serverId) ? this.serverId : serverId;
    const { logType } = this.params;

    try {
      const { list } = await serverApis.getServerConfigPathList(_serverId, {
        ...this.params,
        limit: "99999",
        logType,
      });
      const sortList = arrayUtils.sortByKey(list, "configPathId");
      const _list = Common_Utils.constructClassList(sortList, LogConfigPathModel);

      this.tableList = Common_Utils.constructClassList(list, LogConfigPathModel);
      this.uiTableList = _list;
    } catch (error) {
      this.tableList = [];
      this.uiTableList = [];
    }
  };

  @action
  addLogConfigPath = async (info) => {
    const { isApplicationFlag, configPath, logTag, logType } = info;

    const uiParams = { isApplicationFlag, configPath, logTag, logType, serverId: this.serverId };
    await this.uiTableList.unshift(new LogConfigPathModel(uiParams));
    return true;
  };

  @action
  createServerLogConfigPath = async (serverId, logType) => {
    try {
      const washedUiTableList = await this.uiTableList.filter((info) => !info.isDel);

      const data = await washedUiTableList.map(({ logType, configPath, logTag, serverId, isUse }) => {
        return { logType, configPath, logTag, serverId, isUse };
      });

      await serverApis.createServerLogConfigPath(serverId || this.serverId, logType, data);
    } catch ({ data }) {
      return data;
    }
  };

  @action
  getCodes = async () => {
    const { list } = await logTagApis.getLogTagList({ limit: 99999 });
    this.logTagList = list.map(
      (data) =>
        new LogTagListModel({
          ...data,
          value: data.logTag,
          name: data.logTag,
        })
    );
  };
}

export default LogConfigPathModalStore;
