import { observable, computed } from "mobx";

import { SetterModel, Common_Utils } from "@qubit-utill/src";
import { HostListModel } from "@src/common/models";

import { HAProxySectionModel } from "@src/pages/Group/HAProxy/models";

export default class GroupHAProxyModel extends SetterModel {
  constructor(root?, params?) {
    super(params);
    if (root) {
      this.root = root;
    }
  }

  root;

  @computed
  get isSyncFlag() {
    return Common_Utils.isCheckBool(this.isSync);
  }

  @observable
  middlewareUserConfigId: number = 0;

  @observable
  middlewareId: string = "";

  @observable
  guid: string = "";

  @observable
  serverGroupId: number = 0;

  @observable
  validatorState: string | null = "" || null;

  @observable
  isUseSsl: string = "0";

  @observable
  registerDate: string = "";

  @observable
  updateDate: string = "";

  @observable
  registerUuid: string = "";

  @observable
  updateUuid: string = "";

  @observable
  serverHashKey: string = "";

  @observable
  serverGroup: string = "";

  @observable
  isSync: string = "0";

  @observable
  middlewareExecuteCmdList: Array<object> = [{ middlewareExecuteType: "", executeState: "" }];

  @observable
  middlewareUserSectionList: Array<HAProxySectionModel> = [];

  @observable
  middlewareFirewalldList = [];

  @observable
  middlewareSslList: Array<HostListModel> = [];

  @observable
  middlewareManagerConsoleList = [];
}
