import { observable, action } from "mobx";

import { languageSet, withLoading, ListCondition, isRunning } from "@qubit-utill/src";

import { dosFilterApis, filterApis } from "@src/apis/pFilter";

import DosFilterModel from "@src/pages/Filter/SecurityFilter/models/ExtortAccount/DosFilterModel";

class ExtortAccountFilterStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }
  root;

  @observable
  isLoading = false;

  @observable
  isRunning = false;

  @observable
  isSetLoading = true;

  @observable
  tableList: DosFilterModel[] = [];

  @observable
  securityFilterInfo = new DosFilterModel({}, this.root.CodeStore);

  @observable
  typeList: { name: string; value: string }[] = [];

  @observable
  isCopy = false;

  @observable
  isCopyCheck = false;

  @action
  setIsCopy = (flag) => {
    this.isCopy = flag;
  };
  @action
  setIsCopyCheck = (flag) => {
    this.isCopyCheck = flag;
  };

  @action
  initInfo = async () => {
    await this.getCodes();
    if (this.isCopyCheck) {
      // await this.getChannelByTypeos(this.filterInfo.typeos);
      // this.filterInfo.filterName = "";
      // this.filterInfo.filterDescription = "";
      this.isCopyCheck = false;
      this.isCopy = true;
    } else {
      this.securityFilterInfo = new DosFilterModel({}, this.root.CodeStore);
    }
  };

  @action
  clearInfo = () => {
    if (this.isCopy) {
      this.isCopy = false;
    }
    if (!this.isCopyCheck) {
      this.securityFilterInfo = new DosFilterModel({}, this.root.CodeStore);
    }
  };

  @action
  getCodes = async () => {
    try {
      await Promise.all([this.getDosFilterTypeList()]);
    } catch (error) {}
  };

  @action
  getDosFilterTypeList = async () => {
    try {
      const { list } = await dosFilterApis.getDosFilterTypeList();
      this.typeList = list.map(({ dosFilterType, dosFilterTypeName }) => ({ name: dosFilterTypeName, value: dosFilterType }));
    } catch (error) {}
  };

  @action
  getFilterCount = async () => {
    try {
      const { canRegistFilter } = await filterApis.getDosFilterCount();
      return canRegistFilter;
    } catch (error) {
      return false;
    }
  };

  //보안필터 리스트
  @withLoading("isLoading")
  getList = async () => {
    try {
      const { list, totalCount } = await dosFilterApis.getDosFilterList(this.params);
      this.totalCount = totalCount;
      this.tableList = list.map((info) => new DosFilterModel(info, this.root.CodeStore));
    } catch ({ data }) {
      this.tableList = [];
      this.totalCount = 0;
    }
  };

  @withLoading("isSetLoading")
  @action
  getSecurityFilterInfo = async (dosFilterId) => {
    try {
      this.getCodes();
      const { info } = await dosFilterApis.getDosFilter(dosFilterId);
      this.securityFilterInfo = new DosFilterModel(info, this.root.CodeStore);
    } catch (e) {
      console.log(e);
    }
  };

  @action
  @isRunning("isRunning")
  createFilter = async () => {
    try {
      await dosFilterApis.createDosFilter(this.securityFilterInfo.apiData);
    } catch ({ data }) {
      throw data;
    }
  };

  @action
  @isRunning("isRunning")
  updateFilter = async () => {
    const { dosFilterId, apiData } = this.securityFilterInfo;
    try {
      await dosFilterApis.updateDosFilter(dosFilterId, apiData);
    } catch ({ data }) {
      throw data;
    }
  };

  @isRunning("isRunning")
  @action
  deleteFilter = async () => {
    const { dosFilterId } = this.securityFilterInfo;
    try {
      await dosFilterApis.deleteDosFilterInfo(dosFilterId);
    } catch ({ data }) {
      return data;
    }
  };

  @action
  @isRunning("isRunning")
  updateIsUseFilter = async (dosFilterId, isUseFlag) => {
    try {
      await dosFilterApis.updateIsUseDosFilter(dosFilterId, { isUse: isUseFlag ? "0" : "1" });
    } catch ({ data }) {
      return data;
    }
  };

  @action
  validData = () => {
    const {
      dosFilterType,
      dosFilterName,
      userHostIds,
      httpPath,
      httpLoginParameterName,
      httpStatus,
      loginStatusType,
      isDetectTimeRangeUseFlag,
      detectStartTime,
      detectEndTime,
      httpPathCount,
      httpIDCount,
      responseContentLengthMin,
      responseContentLengthMax,
      isUseResposeSizeFlag,
    } = this.securityFilterInfo;

    try {
      if (!dosFilterType) {
        throw languageSet("메세지_분류를_선택하세요");
      }
      if (!dosFilterName.trim()) {
        throw languageSet("메세지_필터명입력");
      }
      if (userHostIds && userHostIds.length <= 0) {
        throw languageSet("메세지_호스트입력");
      }
      if (!httpPath.trim()) {
        throw languageSet("메세지_경로입력");
      }
      if (!httpLoginParameterName.trim()) {
        throw languageSet("메세지_로그인속성입력");
      }
      if (!httpStatus) {
        throw languageSet("메세지_상태값입력");
      }
      if (+httpStatus < 100 || +httpStatus > 599) {
        throw languageSet("메세지_상태값입력값확인");
      }
      if (!loginStatusType) {
        throw languageSet("메세지_로그인성공실패선택");
      }
      if (
        isUseResposeSizeFlag &&
        (responseContentLengthMin === "" ||
          responseContentLengthMin === null ||
          responseContentLengthMax === "" ||
          responseContentLengthMax === null)
      ) {
        throw languageSet("메세지_응답크기입력");
      }
      if (isUseResposeSizeFlag && Number(responseContentLengthMin) > Number(responseContentLengthMax)) {
        throw languageSet("메세지_응답크기입력값확인");
      }
      if (isUseResposeSizeFlag && Number(responseContentLengthMax) > 9999999) {
        throw languageSet("메세지_응답크기최댓값확인");
      }
      if (isDetectTimeRangeUseFlag && (!detectStartTime || !detectEndTime)) {
        throw languageSet("메세지_시간을설정");
      }
      if (!httpPathCount) {
        throw languageSet("메세지_시도횟수를입력");
      }
      if (+httpPathCount < 1 || +httpPathCount > 9999) {
        throw languageSet("메세지_시도횟수값확인");
      }
      if (!httpIDCount) {
        throw languageSet("메세지_사용ID수를입력");
      }
      if (+httpIDCount < 1 || +httpIDCount > 99999) {
        throw languageSet("메세지_사용ID수값확인");
      }
      return {
        isValid: true,
      };
    } catch (message) {
      return {
        isValid: false,
        message,
      };
    }
  };

  @action
  getSelectHostList = (hostList) => {
    if (hostList.length > 0) {
      this.securityFilterInfo.userHostReferenceList = hostList.filter(({ isChecked }) => isChecked);
      this.securityFilterInfo.userHostIds = [];
      this.securityFilterInfo.userHostReferenceList.map(({ userHostId }) => this.securityFilterInfo.userHostIds.push(userHostId));
    }
  };

  @action
  setInfo = (elementName, value) => {
    this[elementName] = value;
  };
}

export default ExtortAccountFilterStore;
