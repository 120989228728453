import { observable, action } from "mobx";
import { roleLicenseApis } from "../apis";
import { differenceInMinutes } from "date-fns";
import { RoleUserCodeModel, LicenseCodeModel } from "../model";
import { arrayUtils } from "../utils";
import { LoadingStore } from ".";

class RoleLicenseStore {
  constructor(root) {
    this.root = root;
  }
  root;

  @observable
  userLicenseSummery = {
    businessLicenseTypes: [],
    roleCodes: [],
    useLicenseType: "",
  };

  @observable
  roleList = [];

  @observable
  userRoleList = {};

  @observable
  licenseCodeList = {};

  @observable
  isCallApiDone = false;

  @observable
  lastUserLicenseTime = new Date();

  @action
  getUserTotalRoleLicense = async () => {
    this.isCallApiDone = false;
    LoadingStore.Loading();
    await Promise.all([this.getUserRoleList(), this.getLicenseList()]);
    LoadingStore.completed();
    this.isCallApiDone = true;
  };

  @action
  getUserLicense = async () => {
    try {
      const {
        businessLicenseTypes,
        roleCodes,
        useLicenseType,
      } = await roleLicenseApis.getUserLicense();

      const _businessLicenseTypes = businessLicenseTypes || [];
      const _roleCodes = roleCodes || [];

      if (
        this.userLicenseSummery.useLicenseType !== useLicenseType ||
        arrayUtils.isDifference(this.userLicenseSummery.roleCodes, _roleCodes) ||
        arrayUtils.isDifference(this.userLicenseSummery.businessLicenseTypes, _businessLicenseTypes)
      ) {
        this.userLicenseSummery = { businessLicenseTypes, roleCodes, useLicenseType };
        this.getUserTotalRoleLicense();
      }
    } catch (e) {
      console.error("getUserLicense error", e);
    }
  };

  @action
  checkChangeUserLicense = async () => {
    const timeDifference = differenceInMinutes(new Date(), this.lastUserLicenseTime);
    if (timeDifference > 1) {
      this.lastUserLicenseTime = new Date();
      this.getUserLicense();
    }
  };

  @action
  getUserRoleList = async () => {
    try {
      const { businessLicenseTypes } = await roleLicenseApis.getRolePermission();
      const result = businessLicenseTypes.reduce(
        (a, { apiPermissionCode, isMethodGet, isMethodPost }) => {
          a[apiPermissionCode] = a[apiPermissionCode] || {};
          a[apiPermissionCode] = new RoleUserCodeModel({
            apiPermissionCode,
            isMethodGet:
              a[apiPermissionCode].isMethodGet === "1" || isMethodGet === "1" ? "1" : "0",
            isMethodPost:
              a[apiPermissionCode].isMethodPost === "1" || isMethodPost === "1" ? "1" : "0",
          });
          return a;
        },
        {}
      );
      this.userRoleList = result;
    } catch (e) {
      console.error("getUserRoleList error", e);
    }
    // const list = [
    //   { apiPermissionCode: "singlefilter", isMethodGet: true, isMethodPost: false },
    //   { apiPermissionCode: "defenceexcute", isMethodGet: true, isMethodPost: false },
    //   { apiPermissionCode: "weblogdefence", isMethodGet: true, isMethodPost: false },
    //   { apiPermissionCode: "servermanagement", isMethodGet: true, isMethodPost: false },
    //   { apiPermissionCode: "immediatelydefence", isMethodGet: true, isMethodPost: false },
    //   { apiPermissionCode: "test3", isMethodGet: "1", isMethodPost: "0" }
    // ]
  };

  @action
  getLicenseList = async () => {
    try {
      const { list } = await roleLicenseApis.getLicensePermission();
      // const list = [
      //   { licenseActivityType: "securitydetect", limitCount: 0 },
      //   { licenseActivityType: "report", limitCount: 0 },
      //   // { licenseActivityType: "totallog", limitCount: 0 },
      //   { licenseActivityType: "filterdetectweb" },
      //   { licenseActivityType: "filterdetectnet" },
      //   { licenseActivityType: "filter" },
      //   { licenseActivityType: "groupmemberadd" },
      //   { licenseActivityType: "groupmemberinvite" },
      //   { licenseActivityType: "memberAdditionalInfo" },
      //   // { licenseActivityType: "ticketConfig" },
      //   { licenseActivityType: "customApi" },
      //   {
      //     licenseActivityType: "filterdetectml",
      //     licenseActivityOption: "redirectPaymentInfo",
      //   },
      // ];
      const result = list.reduce(
        (a, { licenseActivityType, limitCount, licenseActivityOption }) => {
          a[licenseActivityType] = new LicenseCodeModel({
            licenseActivityType,
            limitCount,
            licenseActivityOption,
          });
          return a;
        },
        {}
      );
      this.licenseCodeList = result;
    } catch (e) {
      console.error("getLicenseList error", e);
    }
  };

  @action
  hasLicense = licenseCode => {
    return Boolean(this.licenseCodeList[licenseCode]);
  };

  @action
  getCompareLimitCount = (licenseCode, targetCount) => {
    return (
      this.hasLicense(licenseCode) && targetCount >= this.licenseCodeList[licenseCode].limitCount
    );
  };

  @action
  hasRole = roleCode => {
    return this.userRoleList[roleCode]
      ? {
        isRender: this.userRoleList[roleCode].isMethodGet === "1",
        isAction: this.userRoleList[roleCode].isMethodPost === "1",
      }
      : { isRender: false, isAction: false };
  };
}

export default RoleLicenseStore;
