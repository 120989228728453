import { observable, action, toJS } from "mobx";

import { ListCondition, withLoading, Common_Utils, languageSet } from "@qubit-utill/src";

import { pDetectFilterApis } from "@src/apis";
import { complianceSettingApis } from "@src/apis/pFilter";
import DefenceStore from "@src/common/stores/DefenceStore";
import { CSVINFO, UISET } from "@src/constants";

import CorrelationModel from "@src/pages/SecurityDetect/Correlation/models/CorrelationModel";

class CorrelationStore extends ListCondition {
  @observable
  tableList = [];

  @observable
  correlationInfo = new CorrelationModel(null);

  @observable
  filterIds = [];

  @withLoading("isLoading")
  @action
  getList = async () => {
    try {
      const tagServicePropertyList = this.tagServicePropertyList;
      const tagValueTypeList = this.tagValueTypeList;
      const getTagServiceDataList = this.openIpTagModal;

      const { list, totalCount } = await pDetectFilterApis.getDetectPackageList(this.params);

      this.tableList = list.map((info) => {
        const _info = { ...info, tagServicePropertyList, tagValueTypeList, getTagServiceDataList };

        return new CorrelationModel(_info);
      });
      this.totalCount = totalCount;
    } catch (response) {
      this.tableList = [];
      this.totalCount = 0;
    }
  };

  @action
  clear = () => {
    this.tableList = [];
    this.correlationInfo = new CorrelationModel(null);
  };

  getComplianceEntityCountByFilterKeys = async (filterIds) => {
    try {
      const { totalCount } = await complianceSettingApis.getComplianceEntityCountByFilterKeys(toJS(filterIds));
      return totalCount;
    } catch (e) {
      return 0;
    }
  };

  @withLoading("isSubLoading")
  @action
  getInfo = async (detectPackageId) => {
    try {
      const tagServicePropertyList = this.tagServicePropertyList;
      const tagValueTypeList = this.tagValueTypeList;
      const getTagServiceDataList = this.openIpTagModal;
      const getTagServiceDataListFn = this.getTagServiceDataList;

      const { info, filterIds } = await pDetectFilterApis.getDetectPackage(detectPackageId);

      const _info = { ...info, tagServicePropertyList, tagValueTypeList, getTagServiceDataList, getTagServiceDataListFn };
      this.correlationInfo = new CorrelationModel(_info);

      this.correlationInfo.attackIp && (await DefenceStore.getDefenceInfo(this.correlationInfo)); //get 방어정보

      this.filterIds = filterIds;
      const totalCount = await this.getComplianceEntityCountByFilterKeys(this.correlationInfo.filterIds);
      this.correlationInfo.setInfo("complianceEntityCount", totalCount);
    } catch ({ response }) {
      this.correlationInfo = new CorrelationModel(null);
    }
  };

  @action
  downloadCSV = () => {
    if (this.tableList.length > 0) {
      const csv = Common_Utils.makeCSV(CSVINFO.SECURITYDETECT_CORRELATION.head, this.tableList);
      Common_Utils.csvDownload(
        csv,
        Common_Utils.setCsvFileName({
          title: UISET.SECURITYDETECT_CORRELATION.title,
          category: languageSet("보안탐지"),
          ...this.params,
        })
      );
    } else {
      return languageSet("에러_데이터가없습니다");
    }
  };
}

export default new CorrelationStore();
