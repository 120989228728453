import { observable, action } from "mobx";
import { languageSet } from "@qubit-utill/src";
import BarGraphModel from "./extends/BarGraphModel";

interface ParamProps {
  hourSumList?: Array<{
    detectHour: number;
    detectCount: number;
  }>;
  yesterdayHourSumList?: Array<{
    detectHour: number;
    detectCount: number;
  }>;
  weekHourSumList?: Array<{
    detectHour: number;
    detectCount: number;
  }>;
  category?: string[];
  categoryGroupNames?: Array<{
    filterCategoryGroupId: number;
    filterCategoryGroupName: string;
  }>;
  categoryDataList?: Array<{
    detectCount: number;
    filterCategoryGroupId: number;
    filterCategoryGroupName: string;
  }>;
  categoryDataListBefore?: Array<{
    detectCount: number;
    filterCategoryGroupId: number;
    filterCategoryGroupName: string;
  }>;
  beforeMonth?: string;
  month?: string;
}

class BarLineMixedModel extends BarGraphModel {
  constructor(param: ParamProps) {
    super(param);
    if (param) {
      const {
        //일일
        hourSumList,
        //전일
        yesterdayHourSumList,
        //전주
        weekHourSumList,
        category,
        //주별
        categoryGroupNames,
        //주별, 월별 같이 사용
        categoryDataList,
        //월별
        categoryDataListBefore,
        beforeMonth,
        month,
      } = param;
      if (hourSumList) {
        this.category = category as string[];
        this.chartData.push(this.getChartData(hourSumList));
      }

      if (yesterdayHourSumList) {
        this.category = category as string[];
        this.chartData.push(this.getChartData(yesterdayHourSumList, languageSet("전일"), "line"));
      }

      if (weekHourSumList) {
        this.category = category as string[];
        this.chartData.push(this.getChartData(weekHourSumList, languageSet("n일_전", 7), "line"));
      }

      if (categoryGroupNames) {
        this.category = categoryGroupNames.map((data) => data.filterCategoryGroupName);
      }

      if (categoryDataListBefore) {
        this.chartData.push(this.getChartData(categoryDataListBefore, beforeMonth));
      }

      if (categoryDataList) {
        this.chartData.push(this.getChartData(categoryDataList, month));
      }
    }
  }

  @action
  getChartData = (
    dataList: Array<{
      detectCount: number;
      detectHour?: number;
      filterCategoryGroupId?: number;
      filterCategoryGroupName?: string;
    }>,
    name?: string,
    type?: string
  ) => {
    let chart: Array<any> = this.category.map(() => null);
    dataList.forEach((data) => {
      let index =
        data.detectHour !== undefined ? data.detectHour : this.getIndex(this.category, data.filterCategoryGroupName, "index");
      if (index >= 0) chart[index] = data.detectCount;
    });

    /*
    let maxValueIndex = chart.findIndex((value) => value === Math.max(...chart));
    chart[maxValueIndex] = {
      y: chart[maxValueIndex],
      color: "red",
    };
   */

    return {
      type: type ? type : "column",
      name: name ? name : languageSet("금일"),
      data: chart,
    };
  };

  @observable
  chartData:
    | {
        type: string;
        name: string;
        data: any[];
      }[]
    | any[] = [];

  @observable
  category: Array<string> = [];
}
export default BarLineMixedModel;
