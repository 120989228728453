export default {
  setValueForMultiplePathHost: (JSON_Data: string, keyName: string) => {
    const returnData: Array<any> = [];
    try {
      // JSON array 처리
      const _JSON_Data = JSON.parse(JSON_Data);
      returnData.push(..._JSON_Data);
    } catch {
      // JSON string 처리
      const objectData: any = {};
      objectData[keyName] = JSON_Data;
      returnData.push(objectData);
    }
    return returnData;
  },
};
