import { observable, computed, action } from "mobx";
import EntryFilterModel from "./EntryFilterModel";
import { SetterModel, Common_Utils } from "@qubit-utill/src";

class FilterGroupModel extends SetterModel {
  constructor(params?) {
    super(params);
    if (params && params.entryList) {
      this.entryList = params.entryList.map((element) => new EntryFilterModel(element, element.packageFilterEntryType));
    }
  }
  @computed
  get isCheckPointFlag() {
    return Common_Utils.isCheckBool(this.isCheckPoint);
  }

  @action
  deleteEntry = (entryIndex) => {
    this.entryList.splice(entryIndex, 1);
  };

  @action
  setFilterGroupEntryList = (groupIndex, entryList, isSequenceCheckFlag) => {
    if (groupIndex === 0 && isSequenceCheckFlag) {
      this.entryList = entryList;
    } else {
      const filteredList = entryList.filter(
        (selectItem) => !this.entryList.some((currentItem) => currentItem.entryFilterId === selectItem.entryFilterId)
      );
      this.entryList.push(...filteredList);
    }
  };

  @observable
  packageFilterId = "";
  @observable
  packageFilterGroupId = 0;
  @observable
  packageFilterGroupRatio = 0; //공격 확률
  @observable
  packageFilterGroupOrderNo = 0; //순서
  @observable
  isCheckPoint = "0"; //탐지 알림
  @observable
  entryList: { entryFilterId: number }[] = [];
}

export default FilterGroupModel;
