import { languageSet } from "@qubit-utill/src";

import getURI from "@src/constants/uriset";
import {
  MITRE_LICENSECODE,
  CORRELATION_LICENSECODE,
  WEBEXTENDSDATABREACH_LICENSECODE,
  EXTORTACCOUNT_LICENSECODE,
  WEBSITEFORGERY_LICENSECODE,
} from "@src/constants/licenseCode";

import {
  CorrelationContainer,
  DataBreachContainer,
  ExtortAccountContainer,
  MitreContainer,
  WebsiteforgeryContainer,
} from "@src/pages";

export default {
  //마이터
  MITRE: {
    text: languageSet("마이터_어택"),
    link: getURI("MITRE"),
    tabMenus: [
      { to: getURI("MITRE") },
      { to: getURI("MITRE_MATRIX") },
      { to: getURI("MITRE_LIST") },
      { to: getURI("MITRE_HACK") },
      { to: getURI("MITRE_SOFT") },
    ],
    licenseCode: MITRE_LICENSECODE,
    component: MitreContainer,
  },
  //상관분석
  SECURITYDETECT_CORRELATION: {
    title: languageSet("상관분석"),
    text: languageSet("상관분석"),
    link: getURI("SECURITYDETECT_CORRELATION"),
    licenseCode: CORRELATION_LICENSECODE,
    component: CorrelationContainer,
  },
  //데이터유출
  SECURITYDETECT_DATABREACH: {
    title: languageSet("데이터유출"),
    text: languageSet("데이터유출"),
    link: getURI("SECURITYDETECT_DATABREACH"),
    isUseCursorMark: true,
    licenseCode: WEBEXTENDSDATABREACH_LICENSECODE,
    component: DataBreachContainer,
  },
  //계정탈취
  SECURITYDETECT_EXTORTACCOUNT: {
    title: languageSet("계정탈취"),
    text: languageSet("계정탈취"),
    link: getURI("SECURITYDETECT_EXTORTACCOUNT"),
    licenseCode: EXTORTACCOUNT_LICENSECODE,
    component: ExtortAccountContainer,
  },
  //홈페이지 위변조
  SECURITYDETECT_WEBSITEFORGERY: {
    title: languageSet("홈페이지_위변조"),
    text: languageSet("홈페이지_위변조"),
    link: getURI("SECURITYDETECT_WEBSITEFORGERY"),
    isUseCursorMark: true,
    licenseCode: WEBSITEFORGERY_LICENSECODE,
    component: WebsiteforgeryContainer,
  },
};
