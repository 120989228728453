import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { computed } from "mobx";
import { getURI } from "@qubit-utill/src/common_constants";
import customHistory from "../../../History";
//TODO 임시방편 WithLicenseChecker 훅 등으로 리펙토링 해야 된다.
export const WithLicenseChecker = licenseCode => WrappedComponent =>
  inject(({ RoleLicenseStore }) => {
    if (RoleLicenseStore) {
      return {
        licenseCodeList: RoleLicenseStore.licenseCodeList,
        isCallApiDone: RoleLicenseStore.isCallApiDone,
        checkChangeUserLicense: RoleLicenseStore.checkChangeUserLicense,
      };
    } else {
      return {
        licenseCodeList: [],
        isCallApiDone: false,
        checkChangeUserLicense: () => {},
      };
    }
  })(
    observer(
      class WithLicenseChecker extends Component {
        @computed
        get checkHasLicense() {
          const { licenseCodeList } = this.props;
          const _licenseCode = licenseCode || this.props.licenseCode;
          if (Array.isArray(_licenseCode)) {
            return _licenseCode.some(code => licenseCodeList[code]);
          }
          return licenseCodeList[_licenseCode];
        }

        componentDidMount() {
          this.props.checkChangeUserLicense();
        }

        goToLink = () => {
          customHistory.push(getURI("ERROR_FREETOPREMIUM"));
        };

        render() {
          const { isCallApiDone } = this.props;
          const _licenseCode = licenseCode || this.props.licenseCode;

          if (isCallApiDone && _licenseCode) {
            if (this.checkHasLicense) {
              if (this.checkHasLicense.licenseActivityOption === "redirectPaymentInfo")
                return (
                  <WrappedComponent
                    {...this.props}
                    link={""}
                    isRedirect
                    goToRediret={this.goToLink}
                  />
                );

              return <WrappedComponent {...this.props} />;
            } else {
              return null;
            }
          } else {
            return <WrappedComponent {...this.props} />;
          }
        }
      }
    )
  );

WithLicenseChecker.displayName = "WithLicenseChecker";
export default WithLicenseChecker;
