import { observable, computed } from "mobx";
import { Common_Utils } from "@qubit-utill/src";
import { SetterModel } from "@qubit-utill/src";

export default class GuestModel extends SetterModel {
  @computed
  get isSignUpFlag() {
    return Common_Utils.isCheckBool(this.isSignUp);
  }

  @observable
  isSignUp = ""; //가입여부
  @observable
  registerDate = ""; //발송일
  @observable
  userGuestEmail = "";
  @observable
  userGuestId = 0;
}
