import { observable, computed } from "mobx";

import { Common_Utils, dateUtil, formatUtils } from "@qubit-utill/src";

import { ApplicationLogModel } from "@src/common/models";
import { CSVINFO } from "@src/constants";
import { APPISSUETYPE } from "@src/constants/code";

import getTrFilters from "@src/pages/FilterDetection/SystemFilterDetection/utill/getTrFilters";

interface FilterInfo {
  filterRiskLevel: string;
  filterCategoryName: string;
  filterName: string;
  filterId: string;
  filterType: string;
}

class ApplicationDefaultFilterDetectionModel extends ApplicationLogModel {
  constructor(param, CodeStore) {
    super(param);
    this.CodeStore = CodeStore;
    if (param) {
      this.logHash = param.logHash || "-";
      this.withMicroSecondsLogCreateDate = param.logCreateDate + param.logCreateMicroSeconds;
      this.logTag = param.logTag || "-";
    }
  }
  @observable
  CodeStore;

  @computed
  get attackIp() {
    return this.logClientIp;
  }

  @computed
  get trFilters() {
    return getTrFilters(this.filters, this.CodeStore);
  }

  @computed
  get attackNation() {
    return this.countryIsoCode ? this.countryIsoCode.toLowerCase() : "";
  }

  @computed
  get isDefenceFlag() {
    return Common_Utils.isCheckBool(this.isDefence);
  }

  @computed
  get serverIpAddr() {
    return this.hostIp || this.serverIp;
  }

  @computed
  get ticketText() {
    return this.userTicketPublish ? dateUtil.timeage(this.userTicketPublish.registerDate) : false;
  }

  @computed
  get stepText() {
    return Common_Utils.getTrFiltersText("step", this.trFilters);
  }
  @computed
  get attackText() {
    return Common_Utils.getTrFiltersText("attack", this.trFilters);
  }

  @computed
  get classficationText() {
    return formatUtils.IsNullFormat(Common_Utils.getTrFiltersText("classfication", this.trFilters));
  }

  @computed
  get csvData() {
    return Common_Utils.makeCsvRowData(this, CSVINFO.FILTERDETECT_APP_DEFAULT.infoKey);
  }

  @computed
  get logInfo() {
    return {
      issueServiceType: APPISSUETYPE,
      logCreateDate: this.logCreateDate,
      filterList: this.filterIds,
    };
  }

  @computed
  get filterIds() {
    return this.filters ? this.filters.map(({ filterId }) => filterId) : [this.filterId];
  }

  @computed
  get getServerGroup() {
    return Common_Utils.isDataWithDashBar(this.serverGroup);
  }

  @observable
  logMessage: string = "";
  @observable
  hostIp: string = "";
  @observable
  serverIp: string = "";
  @observable
  documentId: string = ""; //로그 id
  @observable
  serverGroup: string = "";
  @observable
  typeos: string = "";
  @observable
  hostName: string = "";
  @observable
  logClientIp: string = "";
  @observable
  filterChannelName: string = "";
  @observable
  logPriorityId: string = "";
  @observable
  filterEventName: string = "";
  @observable
  filterEventKey: string = "";
  @observable
  logSeverity: string = "";
  @observable
  logCreateDate: string = "";
  @observable
  filters: FilterInfo[] = [];
  @observable
  viewTemplate = [];
  @observable
  filterInfoList: Array<any> = [];
  @observable
  countryIsoCode: string = "";
  @observable
  isDefence: string = "";
  @observable
  complianceEntityCount: number = 0;
  @observable
  filterId: string = "";
  @observable
  serverId: number = 0;
  @observable
  detectDefenseId: number = 0;
  @observable
  logOrigin: string = "";
  @observable
  logHash: string = "";
  @observable
  userTicketPublish: {
    registerDate: string;
  } = {
    registerDate: "",
  };
  @observable
  isActive: string = "0";

  @observable
  defenseDescription = "";

  defenseConfigFilterType: string = "filter";

  @observable
  logCreateMicroSeconds = "";

  @observable
  withMicroSecondsLogCreateDate = "";

  @observable
  serverName: string = "";

  @observable
  logTag: string = "";
}

export default ApplicationDefaultFilterDetectionModel;
