import { axiosModules } from "@qubit-utill/src";

export default {
  /////////////////////////// pserver > Ocean 관리 ///////////////////////////
  //pserver > pserver > Ocean 관리 > DLC서버 생성
  createServerManualDLC: (data) => {
    return axiosModules.post("/pserver/servermanual/dlc", data);
  },

  //pserver > pserver > Ocean 관리 > 운영체제별 유형 리스트 조회
  getPublicLogRfcTypeOsList: (params) => {
    return axiosModules.get("/publiclogrfctypeos/list", params);
  },

  /////////////////////////// pserver > 서버그룹권환 ///////////////////////////
  //pserver > pserver > 서버그룹권환 > 서버그룹별 서버그룹권한 수정,생성
  createUserServerGroupByServerGroupId: (serverGroupId, data) => {
    return axiosModules.put(`/pserver/userservergroup/byservergroupid/${serverGroupId}`, data);
  },

  //pserver > pserver > 서버그룹권환 > 사용자별 서버그룹권한 수정,생성
  createUserServerGroup: (uuid, data) => {
    return axiosModules.put(`/pserver/userservergroup/${uuid}`, data);
  },

  //pserver > pserver > 서버그룹권환 > 사용자 서버그룹권한 조회 ( 로그인한 유저의 서버그룹권한 )
  getUserServerGroupList: () => {
    return axiosModules.get("/pserver/userservergroup");
  },

  //pserver > pserver > 서버그룹권환 > 특정 사용자의 서버그룹권한 조회 ( 각 멤버의 서버그룹권한 )
  getMemberUserServerGroupList: (uuid) => {
    return axiosModules.get(`/pserver/userservergroup/byuuid/${uuid}`);
  },

  //pserver > pserver > 서버그룹권환 > 서버그룹별 소속 맴버 조회
  getUserServerGroupByServerGroupIdList: (serverGroupId) => {
    return axiosModules.get(`/pserver/userservergroup/byservergroupid/${serverGroupId}`);
  },

  /////////////////////////// pserver > 서버관리 > 자식 서버 생성 ///////////////////////////
  //pserver > pserver > 서버관리 > 자식 서버 생성
  createServerManual: (data) => {
    return axiosModules.post("/pserver/servermanual", data);
  },

  /////////////////////////// pserver > 서버관리 > 로그 수집 경로 ///////////////////////////
  //pserver > pserver > 서버관리 > 로그 수집 경로 > ServerLogConfigPath 리스트조회
  getServerConfigPathList: (serverId, params) => {
    return axiosModules.get(`/pserver/serverlogconfigpath/${serverId}`, params);
  },

  //pserver > pserver > 서버관리 > 로그 수집 경로 > ServerLogConfigPath 수정
  createServerLogConfigPath: (serverId, logType, data) => {
    return axiosModules.put(`/pserver/serverlogconfigpath/${serverId}/${logType}`, data);
  },

  /////////////////////////// pserver > 서버 그룹 > 서버관리 > 서버 수정 ///////////////////////////
  //pserver > pserver > 서버 그룹 > 서버관리 > 서버 수정 > SIEM 서버 수정
  //pserver > pserver > 서버 그룹 > 서버관리 > 서버 수정 > 자식 서버 수정
  updateServer: (serverId, data) => {
    return axiosModules.put(`/pserver/server/${serverId}`, data);
  },

  /////////////////////////// pserver > 서버 그룹 > 서버관리 ///////////////////////////
  //pserver > pserver > 서버 그룹 > 서버관리 > 서버 리스트 삭제
  deleteServer: (data) => {
    return axiosModules.del("/pserver/server/list", data);
  },

  //pserver > pserver > 서버 그룹 > 서버관리 > 모드프루라 삭제 요청
  deleteServerModPlura: (serverId) => {
    return axiosModules.post(`/pserver/server/${serverId}/modplurastatus`);
  },

  //pserver > pserver > 서버 그룹 > 서버관리 > 서버그룹 수정(이동)
  updateServerMoveByServerGroupId: (data) => {
    return axiosModules.put("/pserver/server/move/servergroup", data);
  },

  //pserver > pserver > 서버 그룹 > 서버 그룹 > 서버관리 > 서버이름 변경
  updateServerName: (data) => {
    return axiosModules.put("/pserver/server/servername", data);
  },

  //pserver > pserver > 서버 그룹 > 서버관리 > 서버 정보 조회
  getServer: (serverId) => {
    return axiosModules.get(`/pserver/server/${serverId}`);
  },

  //pserver > pserver > 서버 그룹 > 서버관리 > 모드프루라 업데이트 체크
  getServerMopluraState: (serverId) => {
    return axiosModules.get(`/pserver/server/modplura/check/${serverId}`);
  },

  //pserver > pserver > 서버 그룹 > 서버관리 > 서버목록(취약점)
  getInspectServerUseAbleList: (params) => {
    return axiosModules.get("/pserver/inspect/serveruseable", params);
  },

  //pserver > pserver > 서버 그룹 > 서버관리 > 서버목록(SIEM)
  getServerList: (params) => {
    return axiosModules.get("/pserver/server", params);
  },

  //pserver > pserver > 서버 그룹 > 서버관리 > 서버목록 카운트
  getServerCount: (params?) => {
    return axiosModules.get("/pserver/server/servercount", params);
  },

  //pserver > pserver > 서버 그룹 > 서버관리 > 서버 count summary
  getSummaryList: () => {
    return axiosModules.get("/pserver/server/summary");
  },

  //pserver > pserver > 서버 그룹 > 서버관리 > 모드프루라 업데이트 요청
  updateServerModPlura: (params) => {
    return axiosModules.post("/pserver/modplura/update", params);
  },

  /////////////////////////////////////////////////////////////////////////////////
  getServerMopluraCount: () => {
    return axiosModules.get("/pserver/modplura/updatecount");
  },

  getInspectServerUseAbleInfo: (serverId, params) => {
    return axiosModules.get(`/pserver/inspect/serveruseable/${serverId}`, params);
  },

  updateIsLogUpload: (serverId, data) => {
    return axiosModules.put(`/pserver/server/${serverId}/islogupload`, data);
  },
};
