import { action, observable } from "mobx";

import { SetterModel, CodeStore } from "@qubit-utill/src";

import UISET from "@src/constants/uiset";

interface StatusSumType {
  detectStatus200: number;
  detectStatus300: number;
  detectStatus400: number;
  detectStatus500: number;
}

interface MethodSumType {
  detectMethod: string;
  detectCount: number;
}

interface RiskSumType {
  riskLevelCode: string;
  detectCount: number;
}

interface ParamType {
  CodeStore: CodeStore;
  riskLevelCodeSource?: string;
  riskSumList?: RiskSumType[];
  statusSumList?: StatusSumType[];
  statusCountSumList?: object;
  methodSumList?: MethodSumType[];
}

class PieModel extends SetterModel {
  constructor(param: ParamType) {
    super(param);
    const { CodeStore, riskLevelCodeSource, riskSumList, statusSumList, methodSumList, statusCountSumList } = param || {};

    if (riskSumList) {
      this.chartData = riskSumList.map((data) => ({
        name: CodeStore.getCodeName(riskLevelCodeSource, data.riskLevelCode),
        y: data.detectCount,
        color:
          riskLevelCodeSource === "packageFilterDetectRatioRange"
            ? UISET.CHART_PERCENTRISKCODECOLOR[data.riskLevelCode]
            : CodeStore.getCodeAdditionalValue("filterRiskLevel", data.riskLevelCode),
      }));
    }

    if (statusSumList) {
      Object.keys(statusSumList[0]).forEach((data) => {
        const name = data.split("detectStatus")[1];
        if (data.indexOf("detectStatus") > -1) {
          this.chartData.push({
            name,
            y: statusSumList[0][data],
            color: UISET.CHART_WEBSTATUS_COLOR[name],
          });
        }
      });
    }

    if (statusCountSumList) {
      this.getStatusDetailList(statusCountSumList);
    }

    if (methodSumList) {
      this.chartData = methodSumList.map(({ detectMethod: name, detectCount: y }) => ({ name, y }));
    }

    let _chartData: number[] = [];

    this.chartData.forEach(({ name, y, color }) => {
      color && this.colors.push(color);
      this.category.push(name);
      _chartData.push(y);
    });
    this.chartData = _chartData;
  }

  @action
  getStatusDetailList = (statusCountSumList) => {
    const subData: { id: string; data: any[] }[] = [];

    for (const key in statusCountSumList) {
      const value = Object.entries(statusCountSumList[key][0]);

      subData.push({
        id: key,
        data: value,
      });
    }

    this.chartDetailData = subData;
  };

  @observable
  chartData: Array<any> = [];

  @observable
  chartDetailData: {
    id: string;
    data: any[];
  }[] = [];

  @observable
  category: Array<string> = [];

  @observable
  colors: Array<string> = [];
}

export default PieModel;
