import { observable, computed } from "mobx";

import { SetterModel, Common_Utils } from "@qubit-utill/src";

export default class GroupSquidPropertyModel extends SetterModel {
  @computed
  get isSelectFlag() {
    return Common_Utils.isCheckBool(this.isSelect);
  }

  @computed
  get isSectionLabelPropertyFlag() {
    return Common_Utils.isCheckBool(this.isSectionLabelProperty);
  }

  @observable
  middlewareUserPropertyId: number = 0;

  @observable
  middlewarePropertyKey: string = "";

  @observable
  middlewarePropertyValue: string = "";

  @observable
  isSelect: string = "0";

  @observable
  registerUuid: string = "";

  @observable
  updateUuid: string = "";

  @observable
  middlewareUserSectionId: number = 0;

  @observable
  isSectionLabelProperty: string = "0";
}
