import { observable, action, computed } from "mobx";
import WebDetectExceptEntityModel from "./WebDetectExceptEntityModel";
import { SetterModel, Common_Utils } from "@qubit-utill/src";

export default class WebDetectExceptModel extends SetterModel {
  constructor(params) {
    super(params);
    if (params) {
      this.webExceptCustomEntry = params.webExceptCustomEntry.map((entity) => new WebDetectExceptEntityModel(entity));
    }
  }

  @action
  addEntry = () => {
    this.webExceptCustomEntry.push(new WebDetectExceptEntityModel());
  };
  @action
  deleteEntry = (index) => () => {
    this.webExceptCustomEntry.splice(index, 1);
  };

  @computed
  get isBlockOnlyFlag() {
    return Common_Utils.isCheckBool(this.isBlockOnly);
  }

  @observable
  webExceptCustomGroupId = 0;
  @observable
  webExceptCustomEntry = [new WebDetectExceptEntityModel()];
  @observable
  isBlockOnly = "0";
}
