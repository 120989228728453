import { axiosModules } from "@qubit-utill/src";

export default {
  // plogdetect > 웹 탐지로그 > 탐지 웹로그 조회
  getWebLog: (documentId) => {
    return axiosModules.get(`/plogdetect/weblogdetect/${documentId}`);
  },

  // plogdetect > 웹 탐지로그 > 탐지 웹로그 리스트
  getWebLogList: (params) => {
    return axiosModules.get("/plogdetect/weblogdetect/list", params);
  },

  // plogdetect > 웹 탐지로그 > 탐지 웹로그 리스트 CSV 다운로드
  getWebLogCsvList: (params) => {
    return axiosModules.getCsv("/plogdetect/weblogdetect/list/csv", params);
  },
};
