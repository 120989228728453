import { observable, action } from "mobx";

import { SetterModel } from "@qubit-utill/src";

import { DataTypeAttributeModel, DataTypeModel, EventElementOptionModel } from "@src/pages/Filter/RegisterFilter/model/DataType";

export interface dataTypeSelectorModelType {
  selectedTypes: Array<DataTypeModel>;
  eventElementOptionList: Object;
  dataValues: Array<DataTypeAttributeModel>;
  filterEventElementsList?: Array<any>;
  addAttributeItem: (value) => void;
  setSelectDetectType: (value) => void;
}

export default class DataTypeSelectorModel extends SetterModel {
  constructor(param, filterElementJson?, listForSelect?, isUseFilterElementAdditionalFlag?) {
    super(param);

    if (param) {
      if (param.isDefense) {
        this.defenseTypeOrderList = listForSelect;
      } else {
        this.filterElementReferenceList = listForSelect;
      }
    }

    //수정시 elementJson 파싱용도
    if (!isUseFilterElementAdditionalFlag && filterElementJson) {
      this.parsingElementJson(filterElementJson, param.filterEventElementsList, listForSelect, param.isDefense);
    } else if (isUseFilterElementAdditionalFlag && filterElementJson) {
      filterElementJson.map((data) => {
        return this.parsingElementJson(data, param.filterEventElementsList, listForSelect, param.isDefense);
      });
    }

    //UI용 element 네임으로 변경한다.
    if (param && param.filterEventElementsList) {
      this.setEventElementOptionList(param.filterEventElementsList);
    }

    //등록 혹은 dataTypeSelector 추가 시 사용
    if (param && param.eventRootElement && !filterElementJson) {
      this.setDefaultEventRoot(param.eventRootElement);
    }
  }

  @observable
  isDefense: boolean = false;

  @observable
  eventElementOptionList: Object = {};

  @observable
  selectedTypes: Array<DataTypeModel> = [];

  @observable
  dataValues: Array<DataTypeAttributeModel> = [];

  @observable
  defenseTypeOrderList: Array<any> = [];

  @observable
  filterElementReferenceList: Array<any> = [];

  @action
  parsingElementJson = (filterElementJson, filterEventElementsList, listForSelect, isDefense) => {
    let parrentDepth = 0;

    const parseForDataTypeModel = (dataType, index?) => {
      //데이터 유형에 맞는 이벤트 타입 요소
      let eventType = {
        parrentFilterEventElementId: 0,
        elementAttributes: [],
        filterEventElementRepeatSize: 0,
      };
      if (filterEventElementsList) {
        if (isDefense) {
          eventType = filterEventElementsList;
        } else {
          eventType =
            filterEventElementsList.find((_element) => {
              return parrentDepth === _element.parrentDepth && dataType.filterElementName === _element.filterEventElementName;
            }) || {};
        }
      }

      if (dataType.filterElementSearchText) {
        this.dataValues.push(
          new DataTypeAttributeModel({
            filterElementSearchText: dataType.filterElementSearchText,
            filterElementRepeatSequence: dataType.filterElementRepeatSequence,
            filterElementSearchType: dataType.filterElementSearchType,
            filterElementSearchFlag: dataType.filterElementSearchFlag,
            parrentDepth,
            isSelected: true,
            filterElementReferenceList: listForSelect,
          })
        );
      }

      if (!index || index < 1) {
        this.selectedTypes.push(
          new DataTypeModel(
            {
              parrentFilterEventElementId: eventType.parrentFilterEventElementId,
              elementAttributes: eventType.elementAttributes,
              filterEventElementRepeatSize: eventType.filterEventElementRepeatSize,

              filterElementName: dataType.filterElementName,
              filterElementRepeatSequence: dataType.filterElementRepeatSequence,
              filterElementAttributeName: dataType.filterElementAttributeName,
              filterElementAttributeValue: dataType.filterElementAttributeValue,

              parrentDepth,
              isSelected: true,
              isAttrSelected: Boolean(dataType.filterElementAttributeName),
              isDefense,
            },
            "edit"
          )
        );
      }

      if (dataType.childArray) {
        parrentDepth = ++parrentDepth;
        dataType.childArray.forEach((_dataType, index) => {
          parseForDataTypeModel(_dataType, index);
        });
      }

      if (dataType.childObject) {
        parrentDepth = ++parrentDepth;
        parseForDataTypeModel(dataType.childObject);
      }
    };
    parseForDataTypeModel(filterElementJson);
  };

  @action
  setEventElementOptionList = (filterEventElementsList) => {
    if (this.isDefense) {
      filterEventElementsList.forEach((element) => {
        const elementKey = `${element.parrentDepth}/${element.parrentFilterEventElementId}`;
        this.eventElementOptionList[elementKey] = this.eventElementOptionList[elementKey] || [];
        this.eventElementOptionList[elementKey].push(new EventElementOptionModel(element));
      });
    } else {
      filterEventElementsList.forEach((element) => {
        const elementKey = `${element.parrentDepth}/${element.parrentFilterEventElementId}`;
        this.eventElementOptionList[elementKey] = this.eventElementOptionList[elementKey] || [];
        this.eventElementOptionList[elementKey].push(new EventElementOptionModel(element));
      });
    }
  };

  @action
  setDefaultEventRoot = (eventRootElement) => {
    const { filterEventElementName, filterEventElementId } = eventRootElement;
    this.selectedTypes = [
      new DataTypeModel({
        isSelected: true,
        parrentDepth: 0,
        filterElementName: filterEventElementName,
        filterEventElementId,
        parrentFilterEventElementId: 0,
      }),
      new DataTypeModel({
        isSelected: false,
        parrentDepth: 1,
        parrentFilterEventElementId: filterEventElementId,
      }),
    ];
  };

  @action
  setSelectDetectType(value) {
    const { parrentDepth, filterEventElementId } = value;
    value.isSelected = true;
    this.selectedTypes[parrentDepth] = new DataTypeModel(value);
    this.selectedTypes = this.selectedTypes.filter((_, index) => index <= parrentDepth);

    const childArr = this.eventElementOptionList[`${parrentDepth + 1}/${filterEventElementId}`];

    //하위 요소가 있으면 셀렉트 에 추가
    if (childArr) {
      this.setChildSelectType(value);
    } else {
      if (this.dataValues.length === 0) {
        this.addAttributeItem();
      }
    }
  }

  @action
  setChildSelectType(value) {
    this.selectedTypes.push(
      new DataTypeModel({
        isSelected: false,
        parrentDepth: value.parrentDepth + 1,
        parrentFilterEventElementId: value.filterEventElementId,
      })
    );
  }

  @action
  addAttributeItem = (isRecommend?) => {
    if (isRecommend) {
      this.dataValues.push(
        new DataTypeAttributeModel({
          filterElementSearchType: "select",
        })
      );
    } else {
      if (!this.isDefense) {
        this.dataValues.push(new DataTypeAttributeModel());
      }
    }
  };

  @action
  deleteAttributeItem = (attrItemIndex) => {
    let newDataValues = [...this.dataValues];
    newDataValues.splice(attrItemIndex, 1);
    this.dataValues = newDataValues;
  };

  @observable
  childArray: Array<DataTypeSelectorModel> = [];

  @observable
  filterEventElementsList;

  @observable
  eventRootElement;
}
