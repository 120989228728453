import { observable, computed } from "mobx";
import { languageSet, SetterModel, stringUtils, Common_Utils } from "@qubit-utill/src";
import { COOKIELANG, CSVINFO } from "@src/constants";

class IpListModel extends SetterModel {
  constructor(params, CodeStore) {
    super(params);
    this.CodeStore = CodeStore;
    if (params) {
      this.nation = params.detectNationCode && params.detectNationCode.toLowerCase();
      this.registerDate = params.detectDate;
      this.extracionIP = params.detectIp;
      if (params.detectNationCode) {
        this.nationName = stringUtils.getNationFullName(params.detectNationCode, COOKIELANG);
      } else {
        this.nationName = params.isPrivateIp ? languageSet("내부_IP") : languageSet("미확인_IP");
      }
    }
  }

  @observable
  CodeStore;

  @computed
  get service() {
    return this.CodeStore.getCodeName("detectFilterType", this.detectFilterType);
  }

  @computed
  get csvData() {
    return Common_Utils.makeCsvRowData(this, CSVINFO.EXTRACTION_IP.infoKey);
  }

  @computed
  get formatDetectCount() {
    return Common_Utils.getCountNumberFormat(this.detectCount);
  }

  @computed
  get getNation() {
    return Common_Utils.isDataWithDashbar(this.nationName);
  }

  @observable
  nation = "";
  @observable
  nationName = "";
  @observable
  extracionIP = "";
  @observable
  registerDate = "";
  @observable
  detectCount = 0;
  @observable
  isPrivateIp = false;
  @observable
  serverIp = "";
  @observable
  serverOs = "";
  @observable
  detectFilterType = "";
}

export default IpListModel;
