import { observable, action, toJS } from "mobx";

import { ListCondition, withLoading, languageSet, Common_Utils } from "@qubit-utill/src";

import { applicationDetectApis } from "@src/apis/pLogDetect";
import { complianceSettingApis } from "@src/apis/pFilter";
import { CSVINFO, UISET } from "@src/constants";

import DefenceStore from "@src/common/stores/DefenceStore";
import ApplicationDefaultFilterDetectionModel from "@src/pages/FilterDetection/ApplicationFilterDetection/Default/models/ApplicationDefaultFilterDetectionModel";

class ApplicationDefaultFilterDetectStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }

  root;

  @observable
  tableList = [];

  @observable
  applicationFilterDetectInfo = new ApplicationDefaultFilterDetectionModel(null, this.root.CodeStore);

  @observable
  ipDefenceType = "DEFENCEIP_APPLICATION";

  @action
  clear = () => {
    this.tableList = [];
    this.applicationFilterDetectInfo = new ApplicationDefaultFilterDetectionModel(null, this.root.CodeStore);
  };

  @withLoading("isLoading")
  @action
  getList = async () => {
    try {
      const tagServicePropertyList = this.tagServicePropertyList;
      const tagValueTypeList = this.tagValueTypeList;
      const getTagServiceDataList = this.openIpTagModal;

      const { list, totalCount, nextCursorMark } = await applicationDetectApis.getAppLogDetectList(this.params);
      this.tableList = list.map((info) => {
        const _info = { ...info, tagServicePropertyList, tagValueTypeList, getTagServiceDataList };
        return new ApplicationDefaultFilterDetectionModel(_info, this.root.CodeStore);
      });
      this.setCursorMarkInfo(nextCursorMark);
      this.totalCount = totalCount;
    } catch (e) {
      this.tableList = [];
      this.totalCount = 0;
    }
  };

  getComplianceEntityCountByFilterKeys = async (filterIds) => {
    try {
      const { totalCount } = await complianceSettingApis.getComplianceEntityCountByFilterKeys(filterIds);

      return totalCount;
    } catch (e) {
      return 0;
    }
  };

  @withLoading("isSubLoading")
  @action
  getInfo = async (documentId) => {
    try {
      const tagServicePropertyList = this.tagServicePropertyList;
      const tagValueTypeList = this.tagValueTypeList;
      const getTagServiceDataList = this.openIpTagModal;
      const getTagServiceDataListFn = this.getTagServiceDataList;

      const { info, viewTemplate, filterInfoList, programMessageTemplate } = await applicationDetectApis.getAppLogDetect(
        documentId
      );

      const _info = { ...info, tagServicePropertyList, tagValueTypeList, getTagServiceDataList, getTagServiceDataListFn };

      this.applicationFilterDetectInfo = await new ApplicationDefaultFilterDetectionModel(
        {
          ..._info,
          viewTemplate,
          filterInfoList,
          programMessageTemplate,
        },
        this.root.CodeStore
      );
      const totalCount = await this.getComplianceEntityCountByFilterKeys(this.applicationFilterDetectInfo.filterIds);
      this.applicationFilterDetectInfo.setInfo("complianceEntityCount", totalCount);

      this.applicationFilterDetectInfo.attackIp && (await DefenceStore.getDefenceInfo(this.applicationFilterDetectInfo)); //get 방어정보
    } catch (err) {
      console.log(err);
      this.applicationFilterDetectInfo = new ApplicationDefaultFilterDetectionModel(null, this.root.CodeStore);
    }
  };

  @action
  downloadCSV = async (isUseBulk?, no?) => {
    try {
      if (this.tableList.length > 0) {
        let csv;

        if (isUseBulk && no) {
          csv = await applicationDetectApis.getAppLogDetectCsvList({
            ...toJS(this.params),
            // serverIds: toJS(this.params.serverIds),
            // typeosList: toJS(this.params.typeosList),
            // serverGroupIds: toJS(this.params.serverGroupIds),
            offset: 0,
            limit: no,
          });
        } else {
          csv = Common_Utils.makeCSV(CSVINFO.FILTERDETECT_APP_DEFAULT.head, this.tableList);
        }

        Common_Utils.csvDownload(
          csv,
          Common_Utils.setCsvFileName({
            title: UISET.FILTERDETECT_APP_DEFAULT.title,
            category: `${languageSet("원본")}_${languageSet("필터탐지")}`,
            ...this.params,
          })
        );
      } else {
        return languageSet("에러_데이터가없습니다");
      }
    } catch ({ data }) {
      console.log(data);
    }
  };
}

export default ApplicationDefaultFilterDetectStore;
