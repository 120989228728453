import { languageSet } from "@qubit-utill/src";

import getURI from "@src/constants/uriset";
import { HostSecurityFilterDetectContainer } from "@src/pages";

export default {
  //호스트 보안 탐지
  FILTERDETECTHOSTSECURITY: {
    title: languageSet("호스트보안"),
    text: languageSet("호스트보안"),
    link: getURI("FILTERDETECTHOSTSECURITY"),
    isUseCursorMark: true,
    iconId: "fa fa-delicious f-14",
    component: HostSecurityFilterDetectContainer,
  },
};
