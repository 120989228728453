import React, { Fragment } from "react";
import { languageSet, parseCode } from "@qubit-utill/src";

const AttackTypeStep3 = ({ step, name, color, isSecurity, filterDetectType }) => {
  return (
    <Fragment>
      <span className={`dis-inline-b ${parseCode.getRiskLevelClass(color, "background")} f-10 circle_basic m-r-5`}>
        <span className="c-white bold">{step}</span>
      </span>
      <span className="m-r-5">{name}</span>
      {filterDetectType === "defense" && <i className="fa fa-ban f-13 c-red"></i>}
      {isSecurity && (
        <span className="dis-inline-b">
          <span className="label label-danger b_radius10">{languageSet("보안")}</span>
        </span>
      )}
    </Fragment>
  );
};

export default AttackTypeStep3;
