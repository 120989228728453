import { observable } from "mobx";
import { SetterModel } from "@qubit-utill/src";

export default class ResourceDataTypeModel extends SetterModel {
  @observable
  resourceFilterElementId: number = 0;

  @observable
  resourceMetaId: number = 0;

  @observable
  resourceFilterElementOrderNo: number = 0;

  @observable
  resourceFilterId: number = 0;

  @observable
  resourceFilterElementSelectorList = [];

  @observable
  resourceId: string = "";

  @observable
  isSelected: boolean = false;

  @observable
  filterElementName: string = "";
}
