import { axiosModules } from "@qubit-utill/src";

export default {
  //탭메뉴
  getFilterCategoryGroupDayList: (params) => {
    return axiosModules.get("/pstats/systemdetect/day/categorygroup", params);
  },
  //리스트
  getSystemDayMainDetect: (params) => {
    return axiosModules.get("/pstats/systemdetect/day/main", params);
  },
  //시스템 > 일 정보 > 일 필터기준 탐지 랭크 목록 조회
  getSystemDayFilterRankDetect: (params) => {
    return axiosModules.get("/pstats/systemdetect/day/filterrank", params);
  },
  //차트
  getSystemDayMainChart: (params) => {
    return axiosModules.get("/pstats/systemdetect/day/mainchart", params);
  },
  //서브 차트
  getSystemDaySubChart: (params) => {
    return axiosModules.get("/pstats/systemdetect/day/subchart", params);
  },
  //리스트 주별
  getSystemWeekMainDetect: (params) => {
    return axiosModules.get("/pstats/systemdetect/week/main", params);
  },
  //시스템 > 주 정보 > 주 필터기준 탐지 랭크 목록 조회
  getSystemWeekFilterRankDetect: (params) => {
    return axiosModules.get("/pstats/systemdetect/week/filterrank", params);
  },
  //차트 주별
  getSystemWeekMainChart: (params) => {
    return axiosModules.get("/pstats/systemdetect/week/mainchart", params);
  },
  //차트 주별
  getSystemWeekSubChart: (params) => {
    return axiosModules.get("/pstats/systemdetect/week/subchart", params);
  },
  //리스트 월별
  getSystemMonthMainDetect: (params) => {
    return axiosModules.get("/pstats/systemdetect/month/main", params);
  },
  //시스템 > 월 정보 > 월 필터기준 탐지 랭크 목록 조회
  getSystemMonthFilterRankDetect: (params) => {
    return axiosModules.get("/pstats/systemdetect/month/filterrank", params);
  },
  //차트 월별
  getSystemMonthMainChart: (params) => {
    return axiosModules.get("/pstats/systemdetect/month/mainchart", params);
  },
  //박스플롯 minDate
  getPlotChartMinDate: () => axiosModules.get("/pstats/systemdetect/plotchart/mindate"),
  //카테고리 그룹 리스트 (카운트 x)
  getFilterCategoryGroupList: () => axiosModules.get("/pstats/systemdetect/categorygroup"),
  //박스플롯 Data
  getPlotChart: (params) => axiosModules.get(`/pstats/systemdetect/plotchart`, params),
};
