import { observable, computed } from "mobx";

import { Common_Utils, languageSet, dateUtil, formatConstants, SetterModel } from "@qubit-utill/src";

class NetworkFilterModel extends SetterModel {
  constructor(params, CodeStore) {
    super(params);
    this.CodeStore = CodeStore;
    if (params) {
      this.filterOs = params.typeos;
      this.riskLevel = params.filterRiskLevel;
      this.filterEventType = params.filterEventName
        ? `${
            params.filterEventName.length > 50
              ? Common_Utils.textOverflow(params.filterEventName.replace(/\n/gi, ""), 50)
              : params.filterEventName
          }(${params.filterEventKey})`
        : "-";
      this.detectStartTime = dateUtil.newDate(params.detectStartTime, null);
      this.detectEndTime = dateUtil.newDate(params.detectEndTime, null);
    }
  }
  @observable
  CodeStore;

  @observable
  osFamily = "";

  @computed
  get isUseFlag() {
    return Common_Utils.isCheckBool(this.isUse);
  }

  @computed
  get isLockFlag() {
    return Common_Utils.isCheckBool(this.isLock);
  }

  @computed
  get isDetectDisplayFlag() {
    return Common_Utils.isCheckBool(this.isDetectDisplay);
  }

  @computed
  get isUseUserDescriptionFlag() {
    return Common_Utils.isCheckBool(this.isUseUserDescription);
  }

  @computed
  get isCustomFilterName() {
    return this.isCustomFilter === "0" ? languageSet("추천") : languageSet("사용자");
  }

  @computed
  get riskName() {
    return this.CodeStore.getCodeName("filterRiskLevel", this.filterRiskLevel);
  }

  @computed
  get isUseCustomFlag() {
    return Common_Utils.isCheckBool(this.isUseCustom);
  }

  @computed
  get isDetectTimeRangeUseFlag() {
    return Common_Utils.isCheckBool(this.isDetectTimeRangeUse);
  }

  @computed
  get detectStartTimeFormat() {
    return this.detectStartTime ? dateUtil.format(this.detectStartTime, formatConstants.TIMEFORMAT) : null;
  }
  @computed
  get detectEndTimeFormat() {
    return this.detectEndTime ? dateUtil.format(this.detectEndTime, formatConstants.TIMEFORMAT) : null;
  }

  @computed
  get isPossibleAddDataType() {
    return Boolean(this.filterEventId);
  }

  @computed
  get isUseFilterElementAdditionalFlag() {
    return Boolean(this.refFilterId);
  }

  @observable
  isDisplay = "";

  @observable
  filterNo = 0;

  @observable
  filterId = "";

  @observable
  filterType = ""; //20 = 보안 10 = 관리

  @observable
  isDetectDisplay = "";

  @observable
  isUseUserDescription = "";

  @observable
  isUse = "1";

  isGlobalFilter = "";

  @observable
  filterRiskLevel = "";

  @observable
  isCustomFilter = "1";

  isLock = "";

  @observable
  filterName = "";

  @observable
  filterDescription = "";

  @observable
  filterUserDescription = "";

  @observable
  filterEventId = 0;

  @observable
  filterEventName = "";

  @observable
  filterElement = "";

  @observable
  filterElementJson = [];

  @observable
  filterCustomConfigId = 0;

  @observable
  serverGroupId = null;

  @observable
  typeos = "";

  @observable
  isUseCustom = "0";

  @observable
  filterElementRoot = "";

  @observable
  filterChannelId = 0;

  @observable
  filterChannelName = "";

  @observable
  isSingleEvent = "";

  filterChannelOrderNo = "";

  @observable
  filterCategoryId = 1; //분류

  @observable
  filterCategoryName = ""; //분류

  filterCategoryType = "";

  @observable
  filterOs = "";

  @observable
  serverGroup = "";

  @observable
  coverage = "";

  @observable
  riskLevel = "";

  @observable
  filterEventType = "-";

  @observable
  securityFilterType = "";

  @observable
  isSelected = false;

  @observable
  isDetectTimeRangeUse = "";

  @observable
  detectStartTime = null;

  @observable
  detectEndTime = null;

  @observable
  filterEventKey = "";

  @observable
  filterDetectType = "";

  @observable
  refFilterId = "";

  @observable
  isUseFilterElementAdditional = "0";

  @observable
  filterElementAdditionalJson = [];
}

export default NetworkFilterModel;
