import { observable } from "mobx";

import SetterModel from "./SetterModel";

interface PropsTypes {
  params: any;
  LICENSECODE?: { [key: string]: string };
}

class SummaryListModel extends SetterModel {
  constructor({ params, LICENSECODE }: PropsTypes) {
    super(params);
    if (params) {
      if (!params.child) {
        if (LICENSECODE && params.code.toLowerCase().includes(LICENSECODE.WAF_LICENSECODE)) {
          this.licenseCode = LICENSECODE.WAF_LICENSECODE;
        }
      }

      if (params.orderNo < 3) {
        this.summaryType = "default";
      }
      if (params.name.includes("_")) {
        this.name = params.name.split("_")[0];
      }
    }
  }

  @observable
  summaryType: string = "";

  @observable
  child: Array<any> = [];

  @observable
  code: string = "";

  @observable
  count: number | string = "";

  @observable
  name: string = "";

  @observable
  orderNo: number | string = "";

  @observable
  searchParam: Array<any> = [];

  @observable
  licenseCode: string = "";
}

export default SummaryListModel;
