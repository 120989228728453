import { languageSet } from "@qubit-utill/src";

import getURI from "@src/constants/uriset";
import { FILTER_LICENSECODE, WAF_LICENSECODE } from "@src/constants/licenseCode";
import {
  RecommendFilterContainer,
  RecommendFilterDetailContainer,
  RecommendFilterEditContainer,
  RegisterFilterContainer,
  SecurityFilterContainer,
  RecommendFilterSetContainer,
} from "@src/pages";

export default {
  //보안필터
  SECURITYFILTER: {
    title: languageSet("보안"),
    text: languageSet("보안"),
    link: getURI("SECURITYFILTER_CORRELATION"),
    withPageLink: `${getURI("SECURITYFILTER_CORRELATION")}/page/:page`,
    licenseCode: FILTER_LICENSECODE,
    component: SecurityFilterContainer,
    tabMenus: [
      {
        to: getURI("SECURITYFILTER_CORRELATION"),
        name: languageSet("상관분석"),
      },
      {
        to: getURI("SECURITYFILTER_DATABREACH"),
        name: languageSet("데이터유출"),
      },
      {
        to: getURI("SECURITYFILTER_EXTORTACCOUNT"),
        name: languageSet("계정탈취"),
      },
      {
        to: getURI("SECURITYFILTER_WEBSITEFORERY"),
        name: languageSet("홈페이지_위변조"),
      },
    ],
  },

  //추천필터
  RECOMMENDFILTER: {
    title: languageSet("추천"),
    text: languageSet("추천"),
    link: getURI("RECOMMENDFILTER_SYSTEM"),
    withPageLink: `${getURI("RECOMMENDFILTER_SYSTEM")}/page/:page`,
    licenseCode: FILTER_LICENSECODE,
    component: RecommendFilterContainer,
    tabMenus: [
      {
        to: getURI("RECOMMENDFILTER_SYSTEM"),
        name: languageSet("시스템"),
      },
      {
        to: getURI("RECOMMENDFILTER_APPLICATION"),
        name: languageSet("응용프로그램"),
      },
      {
        to: getURI("RECOMMENDFILTER_NETWORK"),
        name: languageSet("네트워크"),
      },
    ],
  },
  RECOMMENDFILTER_DETAIL: {
    link: `${getURI("RECOMMENDFILTER_DETAIL")}/:filterId`,
    title: languageSet("추천필터"),
    isDetail: true,
    licenseCode: FILTER_LICENSECODE,
    component: RecommendFilterDetailContainer,
  },
  //추천필터수정 -> 복사한 경우
  RECOMMENDFILTER_REGIST: {
    link: getURI("RECOMMENDFILTER_REGIST"),
    title: languageSet("필터등록"),
    component: RecommendFilterSetContainer,
  },
  RECOMMENDFILTER_EDIT: {
    link: `${getURI("RECOMMENDFILTER_EDIT")}/:filterId`,
    title: languageSet("추천필터수정"),
    licenseCode: FILTER_LICENSECODE,
    isEdit: true,
    component: RecommendFilterEditContainer,
  },

  //등록필터
  REGISTERATIONFILTER: {
    title: languageSet("등록"),
    text: languageSet("등록"),
    link: {
      originLink: getURI("REGISTERATIONFILTER_SYSTEM"),
      licenseLink: getURI("REGISTERATIONFILTER_WEBFIREWALL"),
      license: WAF_LICENSECODE,
    },
    // withPageLink: `${getURI("REGISTERATIONFILTER_SYSTEM")}/page/:page`,
    licenseCode: FILTER_LICENSECODE,
    component: RegisterFilterContainer,
    tabMenus: [
      {
        to: getURI("REGISTERATIONFILTER_WEBFIREWALL"),
        name: languageSet("웹방화벽"),
      },
      {
        to: getURI("REGISTERATIONFILTER_SYSTEM"),
        name: languageSet("시스템"),
      },
      {
        to: getURI("REGISTERATIONFILTER_APPLICATION_DEFAULT"),
        name: languageSet("응용프로그램"),
      },
      {
        to: getURI("REGISTERATIONFILTER_APPLICATION_RESOURCE"),
        name: languageSet("응용프로그램"),
      },
      {
        to: getURI("REGISTERATIONFILTER_WEB"),
        name: languageSet("웹"),
      },
      {
        to: getURI("REGISTERATIONFILTER_NETWORK"),
        name: languageSet("네트워크"),
      },
    ],
  },
};
