import React from "react";

interface Props {
  message?: string;
}

const TableMessageWrap: React.FC<Props> = ({ children, message }) => {
  return (
    <div className="v_table h-250">
      <div className="v_cell t-center">
        {children}
        {message &&
          message.split("<br/>").map((line) => {
            return (
              <span>
                {line}
                <br />
              </span>
            );
          })}
      </div>
    </div>
  );
};

export default TableMessageWrap;
