import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";

import useInjectStore from "@qubit-utill/src/hooks/useInjectStore";
import { Common_Utils } from "@qubit-utill/src/utils";
import { LicenseCheckRoute } from "@qubit-utill/src/components/organisms/Route";
type Props = {
  checkIsCurrentPage?: (path: string) => void;
};
type DefaultLayoutType = {
  bodyClassName?: any;
  SideBar?: any;
  TopBar: any;
  defaultLink: any;
  routes?: any[];
  licenseCheck?: any;
  notUseMainContent?: boolean;
};

const DefaultLayout: React.FC<DefaultLayoutType> = ({
  SideBar,
  TopBar,
  defaultLink,
  routes,
  licenseCheck,
  bodyClassName,
  notUseMainContent = false,
}) => {
  const [keyState, setKeyState] = useState(0);
  const [unlisten, setUnlisten] = useState(() => {});

  let history = useHistory();
  const { ModalStore } = useInjectStore();

  useEffect(() => {
    if (bodyClassName) document.body.className = bodyClassName;
    setUnlisten(
      history.listen((location, action) => {
        console.log("on route change");
        ModalStore.modals = {};
      })
    );

    return unlisten;
  }, [bodyClassName]);

  const checkIsCurrentPage = (path: string) => {
    if (path === Common_Utils.getCurrentPathUrl()) setKeyState(keyState + 1);
  };

  return (
    <section style={{ opacity: 1 }}>
      {SideBar && <SideBar checkIsCurrentPage={checkIsCurrentPage} />}
      <div className={!notUseMainContent ? "main-content" : ""}>
        <TopBar checkIsCurrentPage={checkIsCurrentPage} />
        <Switch>
          <Route exact path={"/"} render={() => <Redirect to={defaultLink} />} />
          {routes &&
            routes.map(({ link, withPageLink, exact, component: Component, ...rest }, idx) => {
              const paths = withPageLink ? [link, withPageLink] : [link];
              return Component
                ? paths.map(path =>
                    licenseCheck ? (
                      <LicenseCheckRoute
                        exact={exact}
                        key={"props_route" + idx + keyState}
                        path={path}
                        component={Component}
                        {...rest}
                      />
                    ) : (
                      <Route
                        exact={exact}
                        key={"props_route" + idx}
                        path={path}
                        render={() => <Component {...rest} />}
                      />
                    )
                  )
                : null;
            })}
        </Switch>
      </div>
    </section>
  );
};

export default DefaultLayout;
