import { observable, computed, action } from "mobx";

import { Common_Utils, dateUtil, formatUtils } from "@qubit-utill/src";

import { WAF_LICENSECODE } from "@src/constants/licenseCode";
import { CSVINFO } from "@src/constants";
import { WebLogModel } from "@src/common/models";

class DataBreachModel extends WebLogModel {
  constructor({ info, rootStore, issueServiceType, isDeleteInfo = true }) {
    super(info);
    this.CodeStore = rootStore.CodeStore;
    this.RoleLicenseStore = rootStore.RoleLicenseStore;
    this.issueServiceType = issueServiceType || "";
    this.isDeleteInfo = isDeleteInfo;
  }

  @observable
  CodeStore;

  @observable
  RoleLicenseStore;

  issueServiceType;

  @observable
  isDeleteInfo;

  @computed
  get isWafLicense() {
    return this.RoleLicenseStore.hasLicense(WAF_LICENSECODE);
  }

  @computed
  get ticketText() {
    return this.userTicketPublish ? dateUtil.timeage(this.userTicketPublish.registerDate) : false;
  }

  @computed
  get csvData() {
    return Common_Utils.makeCsvRowData(this, CSVINFO.SECURITYDETECT_DATABREACH.infoKey);
  }

  @computed
  get logInfo() {
    return {
      issueServiceType: this.issueServiceType,
      logCreateDate: this.logCreateDate,
      filterList: this.filterIds,
    };
  }

  @action
  checkedEmptyValue = (val) => {
    return !(val === null || val.length === 0);
  };

  @computed
  get webExtends() {
    return this.detectList.reduce(
      (acc, cur) => {
        const step = this.CodeStore.getCodeName("webFilterRiskCode", cur.webExtendsFilterRiskCode).substring(0, 1);
        const color = this.CodeStore.getCodeOption("filterRiskLevel", cur.webExtendsFilterRiskCode) || "gray";

        acc.step.push(step);
        acc.categoryType.push({
          filterCategoryName: Common_Utils.isDataWithDashBar(cur.filterCategoryName),
        });
        acc.filterName.push({
          webExtendsFilterName: Common_Utils.isDataWithDashBar(cur.webExtendsFilterName),
          step,
          color,
        });
        acc.webExtendsFilterId.push(cur.webExtendsFilterId);

        acc.webExtendsDetectList.push({
          step,
          color,
          webExtendsFilterRiskCode: cur.webExtendsFilterRiskCode,
          filterCategoryName: Common_Utils.isDataWithDashBar(cur.filterCategoryName),
          webExtendsFilterName: Common_Utils.isDataWithDashBar(cur.webExtendsFilterName),
          matchedKeywordsText: this.checkedEmptyValue(cur.matchedKeywords) && cur.matchedKeywords,
          matchedContentsText: this.checkedEmptyValue(cur.matchedContents) && cur.matchedContents.join("\n\n"),
          responseBody: this.checkedEmptyValue(cur.matchedContents) && cur.matchedContents.join(" "),
        });

        acc.categoryName.push(Common_Utils.isDataWithDashBar(cur.filterCategoryName));
        acc.webExtendsFilterName.push(Common_Utils.isDataWithDashBar(cur.webExtendsFilterName));

        return acc;
      },
      {
        step: [],
        categoryType: [],
        filterName: [],
        webExtendsFilterId: [],
        webExtendsDetectList: [],
        categoryName: [],
        webExtendsFilterName: [],
      }
    );
  }

  @computed
  get filterIds() {
    return this.webExtends.webExtendsFilterId;
  }

  @computed
  get categoryNameText() {
    return this.webExtends.categoryName.join("/");
  }

  @computed
  get stepText() {
    return this.webExtends.step.join("/");
  }

  @computed
  get webExtendsFilterNameText() {
    return this.webExtends.webExtendsFilterName.join("/");
  }

  @computed
  get attackIp() {
    return this.logClientIp;
  }

  @computed
  get serverIpAddr() {
    return this.hostIp || this.serverIp;
  }

  @computed
  get isDefenceFlag() {
    return Common_Utils.isCheckBool(this.isDefence);
  }
  @computed
  get isMime() {
    return this.mime || "-";
  }

  @computed
  get getServerGroup() {
    return Common_Utils.isDataWithDashBar(this.serverGroup);
  }

  @computed
  get getLogStatus() {
    return Common_Utils.isDataWithDashBar(this.logStatus);
  }

  @computed
  get getLogHost() {
    return Common_Utils.isDataWithDashBar(this.logHost);
  }

  @computed
  get getLogUri() {
    return Common_Utils.isDataWithDashBar(this.logUri);
  }

  @computed
  get getLogQueryText() {
    const decodeString = formatUtils.tryDecode(this.logQueryText);
    return Common_Utils.isDataWithDashBar(decodeString);
  }

  @computed
  get getLogCreateDate() {
    return Common_Utils.isDataWithDashBar(this.logCreateDate);
  }

  @computed
  get getCategoryNameText() {
    return Common_Utils.isDataWithDashBar(this.categoryNameText);
  }

  @observable
  documentId = 0;

  @observable
  isPrivateIp = false;

  @observable
  logClientIp = "";

  @observable
  logStatus = "";

  @observable
  logUri = "";

  @observable
  logCreateDate = "";

  @observable
  serverIp = "";

  @observable
  typeos = "";

  @observable
  userTicketPublish = {};

  @observable
  detectList = [];

  @observable
  isWebExtendsDetect = true;

  @observable
  logRequestDate = "";

  @observable
  logHost = "";

  @observable
  logServer = "";

  @observable
  logMethod = "";

  @observable
  logRequest = "";

  @observable
  logReferer = "";

  @observable
  logUserAgent = "";

  @observable
  serverGroup = "";

  @observable
  hostIp = "";

  @observable
  logRequestBody = "";

  @observable
  detectDefenseId = 0;

  @observable
  isDefence = "0";

  @observable
  serverId = 0;

  defaultTab = "webExtends";
  defenseConfigFilterType = "webextendsdatabreach";

  @observable
  isWaf = "";

  @observable
  mime = "";

  @observable
  serverName = "";
}

export default DataBreachModel;
