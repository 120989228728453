import { computed, observable } from "mobx";
import { SetterModel } from "@qubit-utill/src";

class AgentExcuteModel extends SetterModel {
  constructor(param, codeStore) {
    super(param);
    this.codeStore = codeStore;
  }
  @computed
  get agentExecuteServiceCodeName() {
    return this.codeStore && this.codeStore.getCodeName("agentExecuteServiceCode", this.agentExecuteServiceCode);
  }

  @computed
  get displayAgentExecuteStateName() {
    return this.codeStore && this.codeStore.getCodeName("displayAgentExecuteState", this.displayAgentExecuteState);
  }

  @computed
  get displayUpdateDate() {
    return this.updateDate || "-";
  }

  @computed
  get AgentExecuteStateColor() {
    switch (this.displayAgentExecuteState) {
      case "30":
        return "blue";
      case "20":
        return "info";
      case "40":
        return "danger";
      default:
        return "";
    }
  }

  @observable
  codeStore;

  @observable
  agentExecuteLogId: number = 0;
  @observable
  agentExecuteServiceCode: string = "";
  @observable
  agentExecuteType: string = "";
  @observable
  agentExecuteState: string = "";
  @observable
  agentExecuteDescription: string = "";
  @observable
  registerDate: string = "";
  @observable
  updateDate: string = "";
  @observable
  registerUuid: string = "";
  @observable
  updateUuid: string = "";
  @observable
  registerUserName: string = "";
  @observable
  updateUserName: string = "";
  @observable
  serverId: number = 0;
  @observable
  displayAgentExecuteState: string = "";
  @observable
  serverName: string = "";
  @observable
  hostname: string = "";
  @observable
  typeos: string = "";
  @observable
  serverIp: string = "";
}

export default AgentExcuteModel;
