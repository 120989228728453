import React from "react";
import { Row, Col } from "reactstrap";
import { observer, Observer } from "mobx-react";

import { Common_Utils, useInjectStore } from "@qubit-utill/src";

interface ComponentProps {
  selectedValue: number | string;
}

const ResourceClassificationTab: React.FC<ComponentProps> = ({ selectedValue }) => {
  const { ReportStore } = useInjectStore();

  const _renderItems = () => {
    return ReportStore.subTabMenus.map(
      (
        {
          value,
          count,
          name,
          isUndefinedCount,
        }: {
          value: number;
          count: number;
          name: string;
          isUndefinedCount: boolean;
        },
        index: number
      ) => {
        const isActive = count > 0 || isUndefinedCount;
        let color = isActive ? "c-white" : "c-gray";
        color = value === selectedValue ? "c-yellow" : color;

        !ReportStore.resourceName && index === 0 && ReportStore.setResourceName(name);

        return (
          <>
            <span className="f-12 p-5 dis-inline-b">
              <a
                onClick={() => {
                  if (isActive) {
                    ReportStore.setListParams("resourceId", value, true);
                    ReportStore.setResourceName(name);
                  }
                }}
                className={`t_deconone p-5 ${isActive ? "cursor_p" : ""}`}
              >
                <span className={color}>
                  {name}
                  {!isUndefinedCount && <span>{` (${Common_Utils.numberWithComma(count)})`}</span>}
                </span>
              </a>
            </span>
            {ReportStore.subTabMenus.length !== index + 1 && (
              <span className="txt_bar opacity-50" key={"classification_tab_dash_" + index}>
                |
              </span>
            )}
          </>
        );
      }
    );
  };

  return (
    <Row className="m-b-15">
      <Col md="12" sm="12">
        <Col md="12" className="bg-blue-dark b_radius5 p-tb-3 min_h33">
          <Observer render={_renderItems} />
        </Col>
      </Col>
    </Row>
  );
};

export default observer(ResourceClassificationTab);
