import { axiosModules } from "@qubit-utill/src";

export default {
  //월별 리스트
  getIpDetectListTop: (termsTab, params) => {
    return axiosModules.get(`/pstats/ipdetect/${termsTab}/iptop`, params);
  },
  //기간별 차트
  getIpDetectChart: (termsTab, params) => {
    return axiosModules.get(`/pstats/ipdetect/${termsTab}/chart`, params);
  },
};
