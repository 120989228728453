import { observable } from "mobx"
import SetterModel from "./SetterModel"

class FilterChannelModel extends SetterModel {
  constructor(params) {
    super(params)
    if (params) {
      this.name = params.filterChannelName
      this.value = params
    }
  }

  @observable
  filterChannelId = 0

  @observable
  filterChannelName = ""

  @observable
  isSingleEvent = ""

  @observable
  typeos = ""

  @observable
  filterChannelOrderNo = 0

  @observable
  isUse = 0
}
export default FilterChannelModel
