import { axiosModules } from "@qubit-utill/src";

export default {
  // plog > 네트워크로그 > netLog 조회
  getNetLog: (documentId) => {
    return axiosModules.get(`/plog/netlog/${documentId}`);
  },

  // plog > 네트워크로그 > netLog List 조회
  getNetLogList: (params) => {
    return axiosModules.get("/plog/netlog/list", params);
  },

  // plog > 네트워크로그 > netLog List CSV 다운로드
  getNetLogCsvList: (params) => {
    return axiosModules.getCsv("/plog/netlog/list/csv", params);
  },
};
