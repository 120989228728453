import { observable, action, computed, set } from "mobx";
import { SetterModel, languageSet } from "@qubit-utill/src";
import DataBreachElementSelectorModel from "./DataBreachElementSelectorModel";

const SUGGEST_VALUE_TYPE_NAME = {
  input: languageSet("직접입력"),
  select: languageSet("선택입력"),
  regex: languageSet("정규식"),
};

export default class DataBreachElementModel extends SetterModel {
  constructor(info, CodeStore, filterFieldList?) {
    super(info);
    this.CodeStore = CodeStore;
    if (info && filterFieldList) {
      const field = filterFieldList.find(
        ({ value: { webExtendsFilterFieldName } }) => info.webExtendsFilterFieldName === webExtendsFilterFieldName
      );
      set(this, field.value);
      this.webExtendsFilterElementId = info.webExtendsFilterElementId;
    }

    if (info && info.webExtendsFilterElementSelectors) {
      this.webExtendsFilterElementSelectors = info.webExtendsFilterElementSelectors.map((info) => {
        info.webExtendsFilterFieldSelectValueTypeName = SUGGEST_VALUE_TYPE_NAME[info.webExtendsFilterFieldSelectValueType];
        return new DataBreachElementSelectorModel(info, CodeStore);
      });
    }
  }
  CodeStore;

  @computed
  get suggestValueTypeCodeOption() {
    const arr = this.webExtendsFilterFieldSuggestValueTypeCode.split(",");
    return arr.map((suggest) => ({
      name: suggest,
      value: suggest,
    }));
  }

  @computed
  get operatorOption() {
    const arr = this.webExtendsFilterFieldSuggestOperator.split(",");
    return arr.map((operator) => ({
      name: this.CodeStore.getCodeName("searchColumnAssign", operator),
      value: operator,
    }));
  }

  @computed
  get valueTypeCodeOption() {
    const arr = this.webExtendsFilterFieldSuggestValueTypeCode.split(",");

    let result = arr.map((unit) => ({ name: unit, value: unit }));

    if (this.webExtendsFilterFieldType === "ip") {
      result.push({ name: languageSet("직접입력"), value: "custom" });
    }

    return result;
  }

  @computed
  get suggestValueTypeOption() {
    const arr = this.webExtendsFilterFieldSuggestValueType.split(",");
    return arr.map((type) => ({ name: SUGGEST_VALUE_TYPE_NAME[type], value: type }));
  }

  //옵션에서 사용
  @observable
  webExtendsFilterFieldDisplayName = "";
  @observable
  webExtendsFilterFieldSuggestOperator = ""; //el,eq,nel
  @observable
  webExtendsFilterFieldSuggestValueType = ""; //input,regex
  @observable
  webExtendsFilterFieldSuggestValueTypeCode = ""; //kb,mb,gb

  @observable
  webExtendsFilterElementId = 0;
  @observable
  webExtendsFilterFieldName = "";
  @observable
  webExtendsFilterFieldType = ""; //number
  @observable
  webExtendsFilterElementSelectors: DataBreachElementSelectorModel[] = [];

  @computed
  get initAttributeValue() {
    return {
      webExtendsFilterFieldSelectValueTypeCode: this.valueTypeCodeOption[0].value,
      webExtendsFilterFieldSelectOperator: this.operatorOption[0].value,
      webExtendsFilterFieldSelectValueType: this.suggestValueTypeOption[0].value,
      webExtendsFilterFieldSelectValueTypeName: this.suggestValueTypeOption[0].name,
      webExtendsFilterFieldBitValue: this.valueTypeCodeOption[0].value,
    };
  }

  @action
  setField = (dataBreachField) => {
    set(this, dataBreachField);
    this.webExtendsFilterElementSelectors = [new DataBreachElementSelectorModel(this.initAttributeValue, this.CodeStore)];
  };

  @action
  addAttributeItem = () => {
    this.webExtendsFilterElementSelectors.push(new DataBreachElementSelectorModel(this.initAttributeValue, this.CodeStore));
  };

  @action
  deleteAttributeItem = (index) => {
    this.webExtendsFilterElementSelectors.splice(index, 1);
  };

  @computed
  get apiData() {
    const { webExtendsFilterElementId, webExtendsFilterFieldName, webExtendsFilterFieldType, attributeApiData } = this;
    return {
      webExtendsFilterElementId,
      webExtendsFilterFieldName,
      webExtendsFilterFieldType,
      webExtendsFilterElementSelectors: attributeApiData,
    };
  }

  @computed
  get attributeApiData() {
    return this.webExtendsFilterElementSelectors.map(
      ({
        webExtendsFilterElementSelectorId,
        webExtendsFilterFieldSelectOperator,
        webExtendsFilterFieldSelectValueType,
        webExtendsFilterFieldSelectValueTypeCode,
        webExtendsFilterFieldValue,
      }) => ({
        webExtendsFilterElementSelectorId,
        webExtendsFilterFieldSelectOperator,
        webExtendsFilterFieldSelectValueType,
        webExtendsFilterFieldSelectValueTypeCode,
        webExtendsFilterElementId: this.webExtendsFilterElementId,
        webExtendsFilterFieldValue,
      })
    );
  }
}
