import { observable, action, computed, toJS } from "mobx";

import {
  arrayForSelectBox,
  UserException,
  languageSet,
  withLoading,
  isRunning,
  ListCondition,
  Common_Utils,
} from "@qubit-utill/src";

import {
  filterApis,
  filterCategoryApis,
  filterCustomConfigApis,
  filterElementReferenceApis,
  eventTypeApis,
} from "@src/apis/pFilter";
import { serverGroupApis } from "@src/apis/pServer";

import {
  DataTypeSelectorModel,
  DataTypeForApiModel,
  DataTypeAttributeModel,
} from "@src/pages/Filter/RegisterFilter/model/DataType";
import { FilterChannelModel, FilterCategoryModel } from "@src/pages/Filter/RegisterFilter/model/Filter";
import { NetworkFilterModel } from "@src/pages/Filter/RegisterFilter/model";
import validFilterTimeRange from "@src/pages/Filter/valid/validFilterTimeRange";

class NetworkFilterStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }
  root;

  @observable
  isRunning = false;

  @observable
  filterElementReferenceListForSelect = [];

  @action
  getFilterElementList = async () => {
    try {
      const { list } = await filterElementReferenceApis.getFilterElementReferenceList({
        isUseManager: "0",
        limit: 9999,
      });
      this.filterElementReferenceListForSelect = arrayForSelectBox.parseValueObjectForSelectBox(
        list,
        "filterElementReferenceName"
      );
    } catch (e) {
      this.filterElementReferenceListForSelect = [];
    }
  };

  @observable
  isCopy = false;
  @observable
  isCopyCheck = false;

  @action
  setIsCopy = (flag) => {
    this.isCopy = flag;
  };
  @action
  setIsCopyCheck = (flag) => {
    this.isCopyCheck = flag;
  };

  @observable
  tableList: NetworkFilterModel[] = [];

  @observable
  filterInfo = new NetworkFilterModel(null, this.root.CodeStore);

  @observable
  selectedChannelInfo = {};

  @observable
  filterCategoryList = [];

  @observable
  filterCategoryListForSelect = [];

  @observable
  osFamilyList = {};

  //단일필터 등록등에서 사용하는 데이터 유형 (filterElement)
  @observable
  filterEventElementsList = [];

  @observable
  eventElementOptionList = {};

  //UI용 데이터 조작을 위한 프로퍼티
  //필터 데이터 유형
  @observable
  dataTypeSelects: DataTypeSelectorModel[] = [];

  @observable
  isTotalCheck = false;

  @action
  setIsTotalCheck = (flag: boolean) => {
    this.isTotalCheck = flag;
  };

  @computed
  get totalCountForUI() {
    return this.isTotalCheck ? this.totalCount : this.checkList.length;
  }

  @computed
  get typeosListForSelect() {
    return arrayForSelectBox.parsePrimitiveValueForSelectBox(this.osFamilyList[this.filterInfo.osFamily]);
  }

  @computed
  get checkList() {
    return this.tableList.filter((data) => data.isSelected);
  }

  @computed
  get totalCheckParams() {
    return this.isTotalCheck
      ? { filterCustomConfigIdsAll: "1" }
      : { filterCustomConfigIds: this.checkList.map(({ filterCustomConfigId }) => filterCustomConfigId) };
  }

  @withLoading("isLoading")
  @action
  getList = async () => {
    const _params = toJS(this.params);
    const params = {
      ..._params,
      orderDesc: "DESC",
    };

    try {
      const { list, totalCount } = await filterCustomConfigApis.getFilterCustomConfigList("network", params);
      this.tableList = list.map((data) => new NetworkFilterModel(data, this.root.CodeStore));
      this.totalCount = totalCount;
    } catch (e) {
      console.error("e", e);
      this.tableList = [];
      this.totalCount = 0;
    }
  };

  @action
  setfilterEventElementsList = async (eventInfo) => {
    if (eventInfo) {
      try {
        const { info, elements } = await eventTypeApis.getEventTypeInfo(eventInfo.filterEventId);
        this.filterInfo.setInfo("filterEventName", info.filterEventName);
        this.filterInfo.setInfo("filterEventKey", info.filterEventKey);
        this.filterInfo.setInfo("filterEventId", info.filterEventId);
        this.filterEventElementsList = elements;
      } catch (e) {
        this.clearEventElementsList();
      }
    } else {
      this.clearEventElementsList();
    }
  };

  @action
  clearEventElementsList = () => {
    this.filterInfo.setInfo("filterEventName", "");
    this.filterInfo.setInfo("filterEventId", null);
    this.dataTypeSelects = [];
  };

  @withLoading("isLoading")
  @action
  initFilterInfo = async () => {
    await this.getCodes();
    if (this.isCopyCheck) {
      await this.getChannelByTypeos(this.filterInfo.typeos);
      this.filterInfo.filterName = "";
      this.filterInfo.filterDescription = "";
      this.isCopyCheck = false;
      this.isCopy = true;
    } else {
      this.filterInfo = new NetworkFilterModel(null, this.root.CodeStore);
      this.dataTypeSelects = [];
    }
  };

  @action
  clearFilterInfo = () => {
    if (this.isCopy) {
      this.isCopy = false;
    }
    if (!this.isCopyCheck) {
      this.filterInfo = new NetworkFilterModel(null, this.root.CodeStore);
      this.dataTypeSelects = [];
    }
  };

  @withLoading("isLoading")
  @action
  getFilterInfo = async (filterCustomConfigId) => {
    const { info } = await filterCustomConfigApis.getFilterCustomConfigInfo(filterCustomConfigId);
    this.filterInfo = new NetworkFilterModel(info, this.root.CodeStore);
  };

  @action
  getChannelByTypeos = async (typeos) => {
    await this.root.CodeStore.getChannelByTypeos(typeos);
  };

  @action
  getServerGroupInfo = async (serverGroupId) => {
    try {
      const { result } = await serverGroupApis.getServerGroupInfo(serverGroupId);
      this.filterInfo.setInfo("serverGroup", result.serverGroupInfo.serverGroup);
    } catch ({ data }) {
      return data;
    }
  };

  @withLoading("isLoading")
  @action
  async getFilterWithTotalInfo(filterId) {
    try {
      await this.getCodes();
      await this.getFilterInfo(filterId);
      if (this.filterInfo.serverGroupId) {
        await this.getServerGroupInfo(this.filterInfo.serverGroupId);
      }
      //osFamily를 set 해준다
      const osList = this.root.CodeStore.osList.networkfilter;
      if (osList) {
        osList.osFamily.forEach(({ typeosList, osFamily }) => {
          if (typeosList.indexOf(this.filterInfo.typeos) > -1 && osFamily) {
            this.filterInfo.setInfo("osFamily", osFamily);
          }
        });
      }
      await this.setfilterEventElementsList({
        filterEventId: this.filterInfo.filterEventId,
      });
      //UI에서 수정시 채널리스트 노출하지 않기 떄문에 일단 주석
      // await this.getChannelByTypeos(this.filterInfo.typeos)
      // this.setFilterChannel(
      //   CodeStore.channelListForSelect.get(this.filterInfo.typeos).filter(({ name }) => {
      //     return this.filterInfo.filterChannelName === name
      //   })[0]
      // )

      if (this.filterInfo.isUseFilterElementAdditionalFlag) {
        this.parseFilterElementsForUI(this.filterInfo.filterElementAdditionalJson);
      } else {
        this.parseFilterElementsForUI(this.filterInfo.filterElementJson);
      }
    } catch (e) {
      console.error("tag", e);
    }
  }

  @action
  parseFilterElementsForUI = (filterElementJson) => {
    if (!this.filterInfo.isUseFilterElementAdditionalFlag) {
      this.dataTypeSelects = filterElementJson.map((element) => {
        return new DataTypeSelectorModel(
          {
            filterEventElementsList: this.filterEventElementsList,
            // eventElementOptionList: this.eventElementOptionList
          },
          element,
          this.filterElementReferenceListForSelect
        );
      });
    } else {
      this.dataTypeSelects = [
        new DataTypeSelectorModel(
          {
            filterEventElementsList: this.filterEventElementsList,
          },
          filterElementJson,
          this.filterElementReferenceListForSelect,
          this.filterInfo.isUseFilterElementAdditionalFlag
        ),
      ];
    }
  };

  @action
  getCodes = async () => {
    await this.root.CodeStore.getOsListByType("networkfilter");
    await this.root.ServerGroupStore.getListForOptions();
    this.getFilterCategoryList("filter");
  };

  @action
  setFilterChannel = async (element) => {
    if (element) {
      this.filterInfo.setInfo("filterChannelName", element.filterChannelName);
      this.filterInfo.setInfo("filterChannelId", element.filterChannelId);
      this.selectedChannelInfo = new FilterChannelModel(element);
      if (element.isSingleEvent === "1") {
        try {
          const { list } = await eventTypeApis.getEventTypeList({
            filterChannelId: element.filterChannelId,
          });
          this.setfilterEventElementsList(list[0]);
        } catch (error) {
          this.filterInfo.setInfo("filterEventId", null);
          this.dataTypeSelects = [];
        }
      }
    } else {
      this.filterInfo.setInfo("filterChannelName", "");
      this.filterInfo.setInfo("filterChannelId", "");
      this.selectedChannelInfo = {};
    }
  };

  dataTypeValidationCheck = (dataTypeElement) => {
    if (!dataTypeElement.filterElementName) {
      throw new UserException(languageSet("메세지_요소선택"));
    }

    if (dataTypeElement.elementAttributes && !dataTypeElement.filterElementAttributeName)
      throw new UserException(languageSet("메세지_속성선택"));

    if (dataTypeElement.filterEventElementAttributeValues && !dataTypeElement.filterElementAttributeValue)
      throw new UserException(languageSet("메세지_속성값선택"));

    if (dataTypeElement.filterEventElementRepeatSize > 0 && !dataTypeElement.filterElementRepeatSequence)
      throw new UserException(languageSet("메세지_요소수선택"));
  };

  validData = () => {
    try {
      let newDataTypeList: DataTypeForApiModel[] = [];
      this.dataTypeSelects.forEach(({ selectedTypes, dataValues }) => {
        const dataTypeList: any = toJS(selectedTypes) as any;
        const dataTypeValues = toJS(dataValues) as DataTypeAttributeModel[];

        const slectedLastIndex: number = dataTypeList.length - 1;
        const selectedLastElement = dataTypeList[slectedLastIndex];
        //텍스트 밸류에 엘리먼트 네임 삽입

        !this.filterInfo.isUseFilterElementAdditionalFlag && this.dataTypeValidationCheck(selectedLastElement);

        if (dataTypeValues.length <= 0) {
          throw new UserException(languageSet("메세지_요소선택"));
        }

        //마지막 요소에 텍스트 밸류 삽입
        const lastDataType = dataTypeValues.map((element) => {
          if (element.filterElementSearchText) {
            return new DataTypeForApiModel({
              filterElementName: selectedLastElement.filterElementName,
              filterElementAttributeValue: selectedLastElement.filterElementAttributeValue,
              filterElementAttributeName: selectedLastElement.filterElementAttributeName,
              filterElementRepeatSequence: selectedLastElement.filterElementRepeatSequence,
              filterElementSearchText: element.filterElementSearchText,
              filterElementSearchType: element.filterElementSearchType,
              filterElementSearchFlag: element.filterElementSearchFlag,
              // filterEventElementId: selectedLastElement.filterEventElementId
            });
          } else {
            throw new UserException(languageSet("메세지_데이터값입력"));
          }
        });

        dataTypeList[slectedLastIndex] = lastDataType;

        if (!this.filterInfo.isUseFilterElementAdditionalFlag) {
          for (let i = slectedLastIndex; i >= 1; i--) {
            this.dataTypeValidationCheck(dataTypeList[i - 1]);

            if (i === slectedLastIndex) {
              dataTypeList[i - 1].childArray = dataTypeList[i];
            } else {
              dataTypeList[i - 1].childArray = [new DataTypeForApiModel(dataTypeList[i])];
            }
          }

          newDataTypeList.push(new DataTypeForApiModel(dataTypeList[0]));
        } else {
          dataTypeList[0] = lastDataType;

          dataTypeList[0].map((data) => {
            return newDataTypeList.push(new DataTypeForApiModel(data));
          });
        }

        if (!this.filterInfo.isUseFilterElementAdditionalFlag && !dataTypeList[0].childArray) {
          throw new UserException(languageSet("메세지_요소선택"));
        }
      });

      if (this.filterInfo.isDetectTimeRangeUseFlag && !(this.filterInfo.detectStartTime && this.filterInfo.detectEndTime)) {
        throw new UserException(languageSet("메세지_시간선택"));
      }

      if (
        this.filterInfo.isDetectTimeRangeUseFlag &&
        this.filterInfo.detectStartTimeFormat === this.filterInfo.detectEndTimeFormat
      ) {
        throw new UserException(languageSet("메세지_시간설정다시"));
      }

      if (newDataTypeList.length === 0 || !this.filterInfo.filterName || !this.filterInfo.filterDescription) {
        throw new UserException(languageSet("에러_정보를입력해라"));
      }

      if (!this.filterInfo.isUseFilterElementAdditionalFlag) {
        this.filterInfo.setInfo("filterElementJson", newDataTypeList);
      } else {
        this.filterInfo.setInfo("filterElementAdditionalJson", newDataTypeList);
      }

      return {
        isValid: true,
      };
    } catch ({ message }) {
      return {
        isValid: false,
        message: message,
      };
    }
  };

  validTime = () => {
    const {
      isDetectTimeRangeUseFlag,
      detectStartTime,
      detectEndTime,
      detectStartTimeFormat,
      detectEndTimeFormat,
    } = this.filterInfo;
    return validFilterTimeRange(
      isDetectTimeRangeUseFlag,
      detectStartTime,
      detectEndTime,
      detectStartTimeFormat,
      detectEndTimeFormat
    );
  };

  @isRunning("isRunning")
  @action
  createFilter = async () => {
    const {
      filterType,
      isDetectDisplay,
      isUseUserDescription,
      isUse,
      isGlobalFilter,
      filterRiskLevel,
      isLock,
      filterName,
      filterDescription,
      filterUserDescription,
      filterNo,
      filterEventId,
      filterElementJson,
      filterElementAdditionalJson,
      isDisplay,
      filterCategoryId,
      isCustomFilter,
      isUseCustom,
      serverGroupId,
      isDetectTimeRangeUse,
      detectStartTimeFormat,
      detectEndTimeFormat,
      refFilterId,
    } = this.filterInfo;

    try {
      await filterApis.createFilter({
        filterType,
        isDetectDisplay,
        isUseUserDescription,
        isUse,
        isGlobalFilter,
        filterRiskLevel,
        isLock,
        filterName,
        filterDescription,
        filterUserDescription,
        filterNo,
        filterEventId,
        filterElementJson,
        filterElementAdditionalJson,
        isDisplay,
        filterCategoryId,
        isCustomFilter,
        isUseCustom,
        serverGroupId,
        isDetectTimeRangeUse,
        detectStartTime: detectStartTimeFormat,
        detectEndTime: detectEndTimeFormat,
        refFilterId,
      });
    } catch (error) {
      return (
        error.data || {
          returnCodeMessage: error,
        }
      );
    }
  };

  @action
  updateCustomConfig = async () => {
    try {
      await filterCustomConfigApis.updateFilterCustomConfig(
        [this.filterInfo.filterId],
        [this.filterInfo.serverGroupId],
        this.filterInfo.isUseCustom,
        this.filterInfo.isDetectTimeRangeUse,
        this.filterInfo.detectStartTimeFormat,
        this.filterInfo.detectEndTimeFormat,
        [this.filterInfo.filterCustomConfigId],
        "" // 응용프로그램 시에 logTag 필요한데 같은 api 사용하면서 network는 logTag 없음
      );
    } catch ({ data }) {
      return data;
    }
  };

  @action
  updateCustomConfigIsUse = async (onOff) => {
    try {
      await filterCustomConfigApis.updateFilterCustomConfigIsUse(onOff ? "1" : "0", this.totalCheckParams, this.params);
    } catch ({ data }) {
      return data;
    }
  };

  @action
  updateIsUseCustom = async (info) => {
    const isUseValue = Common_Utils.BoolToString(!info.isUseCustomFlag);
    try {
      const { rowCount } = await filterCustomConfigApis.updateFilterCustomConfigIsUse(isUseValue, {
        filterCustomConfigIds: [info.filterCustomConfigId],
      });
      if (rowCount >= 1) info.setInfo("isUseCustom", isUseValue);
    } catch ({ data }) {
      return data;
    }
  };

  @action
  deleteCustomFilter = async () => {
    try {
      await filterCustomConfigApis.deleteFilterCustomConfig(this.totalCheckParams, this.params);
    } catch ({ data }) {
      return data;
    }
  };

  @action
  deleteRecommendFilterCustomConfig = async (filterCustomConfigId) => {
    try {
      await filterCustomConfigApis.deleteFilterCustomConfig({ filterCustomConfigIds: [filterCustomConfigId] });
    } catch ({ data }) {
      return data;
    }
  };

  @isRunning("isRunning")
  @action
  updateFilter = async () => {
    const {
      filterId,
      filterType,
      isDetectDisplay,
      isUseUserDescription,
      isUse,
      isGlobalFilter,
      filterRiskLevel,
      isLock,
      filterName,
      filterDescription,
      filterUserDescription,
      filterNo,
      filterEventId,
      filterElementJson,
      filterElementAdditionalJson,
      isDisplay,
      filterCategoryId,
      isCustomFilter,
      serverGroupId,
      isUseCustom,
      filterCustomConfigId,
      isDetectTimeRangeUse,
      detectStartTimeFormat,
      detectEndTimeFormat,
      refFilterId,
    } = this.filterInfo;

    try {
      await filterApis.updateFilter({
        filterId,
        filterType,
        isDetectDisplay,
        isUseUserDescription,
        isUse,
        isGlobalFilter,
        filterRiskLevel,
        isLock,
        filterName,
        filterDescription,
        filterUserDescription,
        filterNo,
        filterEventId,
        filterElementJson,
        filterElementAdditionalJson,
        isDisplay,
        filterCategoryId,
        isCustomFilter,
        serverGroupId,
        isUseCustom,
        filterCustomConfigId,
        isDetectTimeRangeUse,
        detectStartTime: detectStartTimeFormat,
        detectEndTime: detectEndTimeFormat,
        refFilterId,
      });
    } catch ({ data }) {
      return data;
    }
  };

  @action
  addNewDataType = () => {
    const eventRootElement = this.filterEventElementsList[0];
    const param = {
      filterEventElementsList: this.filterEventElementsList,
      eventRootElement: eventRootElement,
    };
    this.dataTypeSelects.push(new DataTypeSelectorModel(param));
  };

  @action
  deleteDataElement = (index) => {
    this.dataTypeSelects.splice(index, 1);
  };

  @action
  getFilterCategoryList = async (filterType) => {
    const { list } = await filterCategoryApis.getFilterCategoryList(filterType);
    this.filterCategoryList = list.map((element) => new FilterCategoryModel(element));
    this.filterCategoryListForSelect = arrayForSelectBox.parseValueForSelectBoxDefault(this.filterCategoryList);
  };

  @action
  allCheck = (checked) => {
    this.tableList.forEach((data) => {
      data.setInfo("isSelected", checked);
    });
  };
}

export default NetworkFilterStore;
