import { observable, computed } from "mobx";

import { Common_Utils, dateUtil, SetterModel } from "@qubit-utill/src";

class ApplicationRecommendFilterModel extends SetterModel {
  constructor(params, CodeStore) {
    super(params);
    this.CodeStore = CodeStore;
    if (params) {
      this.recommendFilterName = params.filterName;
      this.recommendFilterRiskLevel = params.filterRiskLevel;
      this.recommendFilterDescription = params.filterDescription;
      this.recommendFilterUserDescription = params.filterUserDescription;
    }
  }

  @observable
  CodeStore;

  @computed
  get recommendFilterNameType() {
    return this.CodeStore.getCodeName("filterRiskLevel", this.recommendFilterRiskLevel);
  }

  @computed
  get isUseUserDescriptionFlag() {
    return Common_Utils.isCheckBool(this.isUseUserDescription);
  }

  @computed
  get isNew() {
    return dateUtil.isCompareDateFlag(30, this.registerDate);
  }

  @observable
  filterId: string = "";

  @observable
  registerDate: string = "";

  @observable
  recommendFilterName: string = "";

  @observable
  recommendFilterDescription: string = "";

  @observable
  recommendFilterUserDescription: string = "";

  @observable
  recommendFilterRiskLevel: string = "";

  @observable
  isUseUserDescription: string = "0";

  @observable
  userGuid: string = "";

  @observable
  isSelected: boolean = false;
}

export default ApplicationRecommendFilterModel;
