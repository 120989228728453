import { observable } from "mobx";

import { SetterModel } from "@qubit-utill/src";

export default class HAProxyPropertySelectOptionModel extends SetterModel {
  @observable
  middlewarePropertyKey: string = "";

  @observable
  middlewarePropertyValueList: Array<{ middlewarePropertyValue: string; middlewarePropertyKey: string }> = [];
}
