import { action, observable, computed } from "mobx";

import { withLoading, ListCondition, isRunning, Common_Utils } from "@qubit-utill/src";

import { complianceSettingApis } from "@src/apis/pFilter";
import { pAccountApis, pAlarmApis, pAuthApis, pCustomerApis, pTicketApis, resourceConfigUserApis } from "@src/apis";

import { HOST_ISOLATION_CONFIG, SOLR_SEARCH_CURSOR, FORENSIC_CONFIG } from "@src/constants/code";

import { AlarmModel } from "@src/pages/Alarm/models";
import { SignMemberModel } from "@src/pages/SignUp/models";
import { GroupModel, GuestModel } from "@src/pages/Group/models";

class GroupStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
    this.params.limit = 5;
  }
  root;

  @observable
  info = new GroupModel(null, this.root.CodeStore);
  @observable
  guestList = [];
  @observable
  userGuestEmail = "";
  @observable
  createUserInfo = new SignMemberModel({});
  @observable
  complianceCount = 0;
  @observable
  ticketSystemName = "";
  @observable
  webhookInfo: any = {};
  @observable
  webhookSysInfo: any = {};

  @observable
  isDisplayHAProxy: string | null = null;

  @observable
  isDisplaySquid: string | null = null;

  @observable
  isDisplayFirewall: string | null = null;

  @observable
  isUseResource: boolean = false;

  @observable
  isModalLoading: boolean = false;

  @computed
  get isDisplayHAProxyFlag() {
    return Common_Utils.isCheckBool(this.isDisplayHAProxy);
  }

  @computed
  get isDisplaySquidFlag() {
    return Common_Utils.isCheckBool(this.isDisplaySquid);
  }

  @computed
  get isDisplayFirewallFlag() {
    return Common_Utils.isCheckBool(this.isDisplayFirewall);
  }

  @action
  createCustomerSupport = async (text) => {
    try {
      await pCustomerApis.createCustomerSupport({
        customerSupportDescription: text,
      });
    } catch ({ data }) {
      console.log("createCustomerSupport", data);
      return data;
    }
  };

  @action
  getGroupManager = async () => {
    try {
      const groupManager = await pAuthApis.getGroupManager();
      this.isDisplayHAProxy = groupManager.isDisplayHAProxy;
      this.isDisplaySquid = groupManager.isDisplaySquid;
      this.isDisplayFirewall = groupManager.isDisplayFirewall;
    } catch (error) {
      console.log("err");
      this.isDisplayHAProxy = null;
      this.isDisplaySquid = null;
      this.isDisplayFirewall = null;
    }
  };

  @withLoading("isLoading")
  @action
  async getGroupMain() {
    try {
      await this.root.CodeStore.getRoleList({ isDisplay: "1" });
      const [groupMain, groupMainSecurity, { list: servicePreferencesList }, { totalCount, isUse }] = await Promise.all([
        pAuthApis.getGroupManager(),
        pAuthApis.getUserGroupLoginAuth(),
        pCustomerApis.getUserPreferencesList({
          servicePreferencesTypeCodes: [SOLR_SEARCH_CURSOR, HOST_ISOLATION_CONFIG, FORENSIC_CONFIG],
        }), // 커서마크, 호스트종료, 네트워크격리, 포렌식
        resourceConfigUserApis.getResourceConfigUserGroupList(),
      ]);

      const data = {
        ...groupMain,
        ...groupMainSecurity,
        servicePreferencesList,
        resourceConfigUserGroup: { totalCount, isUse },
      };

      this.getComplianceSettingCount();
      this.getTicketInfo();
      this.root.RoleLicenseStore.hasRole("alarmsetting").isRender && (await this.getAlarmConfigWebhookInfo());

      this.info = new GroupModel(data, this.root.CodeStore);
    } catch (err) {
      console.log("getGroupMain", err);
      this.info = new GroupModel(null, this.root.CodeStore);
    }
  }

  @action
  getComplianceSettingCount = async () => {
    try {
      const { totalCount } = await complianceSettingApis.getComplianceSettingCount();
      this.complianceCount = totalCount;
    } catch (err) {
      this.complianceCount = 0;
      console.log("getComplianceSettingCount", err);
    }
  };

  @action
  getTicketInfo = async () => {
    try {
      const { info } = await pTicketApis.getTicketInfo();
      this.ticketSystemName = info.isUse === "1" ? info.ticketSystemName : "";
    } catch (err) {
      console.log("getTicketInfo", err);
      this.ticketSystemName = "";
    }
  };

  @action
  setGuestEmail = (value) => {
    this.userGuestEmail = value;
  };

  @withLoading("isModalLoading")
  @action
  getList = async () => {
    try {
      await this.root.CodeStore.getRoleList({ isDisplay: "1" });
      const { list, totalCount } = await pAccountApis.getUserGuest(this.params);
      this.guestList = list.map((data) => new GuestModel(data));
      this.totalCount = totalCount;
    } catch ({ data }) {}
  };

  @action
  createGuestMemeber = async (userGuestServerGroupList, isServerGroupAllFlag) => {
    try {
      let isServerGroupAll = Common_Utils.BoolToString(isServerGroupAllFlag);

      const data = {
        userGuestEmail: this.userGuestEmail,
        companyName: this.root.MyInfoStore.userInfo.companyName,
        inviteMasterName: this.root.MyInfoStore.userInfo.userName,
        isServerGroupAll,
        userGuestServerGroupList,
      };
      await pAccountApis.createUserGuest(data);
      this.getList();
    } catch ({ data }) {
      console.log("createGuestMemeber", data);
      return data;
    }
  };

  @action
  deleteGuestMember = async (userGuestId) => {
    try {
      await pAccountApis.deleteUserGuest(userGuestId);
      this.getList();
    } catch ({ data }) {
      console.log("deleteGuestMember", data);
      return data;
    }
  };

  @action
  getEmailOverlap = async () => {
    try {
      const { rowCount } = await pAccountApis.getUserEmailCount(this.createUserInfo.userEmail);
      rowCount > 0 ? this.createUserInfo.setInfo("overlapEmail", true) : this.createUserInfo.setInfo("overlapEmail", false);
    } catch ({ data }) {
      console.log("getEmailOverlap", data);
    }
  };

  @action
  createMember = async (userGuestServerGroupList, isServerGroupAllFlag) => {
    let isServerGroupAll = Common_Utils.BoolToString(isServerGroupAllFlag);
    const data = {
      userEmail: this.createUserInfo.userEmail,
      password1: this.createUserInfo.password01,
      userName: this.createUserInfo.userName,
      userPhone: this.createUserInfo.userPhone,
      department: this.createUserInfo.department,
      position: this.createUserInfo.position,
      pinPassword: this.createUserInfo.pinPassword01,
      isServerGroupAll,
      userGuestServerGroupList,
    };
    try {
      await pAccountApis.createSubUser(data);
    } catch ({ data }) {
      console.log("createMember", data);
      throw data.returnCodeMessage || data.dataNotFoundCodeMessage;
    }
  };

  @action
  clearCreateUserInfo = () => {
    this.createUserInfo = new SignMemberModel({});
  };

  //웹훅
  @withLoading("isLoading")
  @action
  getAlarmConfigWebhookInfo = async () => {
    try {
      await Promise.all([this.getAlarmConfigSyslogWebhookInfo(), this.getAlarmConfigBasicWebhookInfo()]);
    } catch (e) {
      console.log(e);
    }
  };
  @action
  getAlarmConfigBasicWebhookInfo = async () => {
    try {
      const { info, alarmWebhookList } = await pAlarmApis.getAlarmConfigWebhookInfo({
        alarmWebhookType: "webhook",
      });

      this.webhookInfo = new AlarmModel(info, "webhook", alarmWebhookList, this.root.CodeStore);
    } catch (error) {
      console.log(error);
      this.webhookInfo = new AlarmModel({}, "webhook", [], this.root.CodeStore);
    }
  };
  @action
  getAlarmConfigSyslogWebhookInfo = async () => {
    try {
      const { info, alarmWebhookList, alarmWebhookConfigEntryOptionValues } = await pAlarmApis.getAlarmConfigWebhookInfo({
        alarmWebhookType: "syslog",
      });

      let webDetectStatusEntryList: Array<string> = [];
      alarmWebhookConfigEntryOptionValues.web.map((entryInfo) => {
        webDetectStatusEntryList.push(entryInfo.entryValue);
      });

      let wafDetectStatusEntryList: Array<string> = [];
      alarmWebhookConfigEntryOptionValues.waf.map((entryInfo) => {
        wafDetectStatusEntryList.push(entryInfo.entryValue);
      });
      const _info = { ...info, webDetectStatusEntryList, wafDetectStatusEntryList };

      this.webhookSysInfo = new AlarmModel(_info, "syslog", alarmWebhookList, this.root.CodeStore);
    } catch (error) {
      console.log(error);
      this.webhookSysInfo = new AlarmModel({}, "syslog", [], this.root.CodeStore);
    }
  };

  @isRunning("isRunning")
  @action
  updateWebhook = async ({ isWebhook, isUseDefaultWebhook, webhookList, alarmWebhookType, isSingleSend }) => {
    try {
      await pAlarmApis.updateAlarmConfigSettingWebhook({
        isWebhook,
        isUseDefaultWebhook,
        alarmWebhookList: webhookList.map(({ apiData }) => apiData),
        alarmWebhookType,
        isSingleSend,
      });
      this.getAlarmConfigWebhookInfo();
    } catch ({ data }) {
      return data;
    }
  };

  @action
  getIsUseResourceFlag = async () => {
    const { isUse } = await resourceConfigUserApis.getResourceConfigUserGroupList();
    this.isUseResource = Common_Utils.isCheckBool(isUse);
  };
}

export default GroupStore;
