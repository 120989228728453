import { axiosModules } from "@qubit-utill/src";

export default {
  // pfilter > 필터참조 > 필터엘리먼트참조 > 필터 엘리먼트 참조 목록 가져오기
  getFilterElementReferenceList: (params) => {
    return axiosModules.get(`/pfilter/filterelementreference`, params);
  },

  // pfilter > 필터참조 > 필터엘리먼트참조 > 필터 엘리먼트 참조 정보 조회
  getFilterElementReferenceInfo: (filterElementReferenceId) => {
    return axiosModules.get(`/pfilter/filterelementreference/${filterElementReferenceId}`);
  },

  // pfilter > 필터참조 > 필터엘리먼트참조 > 필터 엘리먼트 참조 정보 삭제
  deleteFilterElementReference: (filterElementReferenceId) => {
    return axiosModules.del(`/pfilter/filterelementreference/${filterElementReferenceId}`);
  },

  // pfilter > 필터참조 > 필터엘리먼트참조 > 필터 엘리먼트 참조 정보 등록
  createFilterElementReference: (data) => {
    return axiosModules.post(`/pfilter/filterelementreference`, data);
  },

  // pfilter > 필터참조 > 필터엘리먼트참조 > 필터 엘리먼트 참조 정보 수정
  updateFilterElementReference: (filterElementReferenceId, data) => {
    return axiosModules.put(`/pfilter/filterelementreference/${filterElementReferenceId}`, data);
  },

  // pfilter > 필터참조 > 필터엘리먼트참조데이터 > 필터 엘리먼트 참조 데이터 목록
  getFilterElementReferenceDataList: (filterElementReferenceId, params) => {
    return axiosModules.get(`/pfilter/filterelementreferencedata/${filterElementReferenceId}`, params);
  },

  // pfilter > 필터참조 > 필터엘리먼트참조데이터 > 필터 엘리먼트 참조 데이터 목록 csv 다운로드
  getFilterElementReferenceDataCsvList: (filterElementReferenceId, param) => {
    return axiosModules.getCsv(`/pfilter/filterelementreferencedata/${filterElementReferenceId}/csv`, param);
  },

  // pfilter > 필터참조 > 필터엘리먼트참조데이터 > 테이터 값으로 필터 엘리먼트 참조 데이터 정보 조회
  getFilterElementReferenceDataInfo: (filterElementReferenceId, params) => {
    return axiosModules.get(`/pfilter/filterelementreferencedata/value/${filterElementReferenceId}`, params);
  },

  // pfilter > 필터참조 > 필터엘리먼트참조데이터 > 필터 엘리먼트 참조 데이터 다중 등록
  createFilterElementReferenceData: (filterElementReferenceId, data) => {
    return axiosModules.post(`/pfilter/filterelementreferencedata/array/${filterElementReferenceId}`, data);
  },

  // pfilter > 필터참조 > 필터엘리먼트참조데이터 > 필터 엘리먼트 참조 데이터 정보 삭제
  deleteFilterElementReferenceData: (filterElementReferenceId, data) => {
    return axiosModules.patch(`/pfilter/filterelementreferencedata/${filterElementReferenceId}`, data);
  },
};
