import { UserException, languageSet } from "@qubit-utill/src";

export default function(isDetectTimeRangeUseFlag, detectStartTime, detectEndTime, detectStartTimeFormat, detectEndTimeFormat) {
  try {
    if (isDetectTimeRangeUseFlag && !(detectStartTime && detectEndTime)) {
      throw new UserException(languageSet("메세지_시간선택"));
    }
    if (isDetectTimeRangeUseFlag && detectStartTimeFormat === detectEndTimeFormat) {
      throw new UserException(languageSet("메세지_시간설정다시"));
    }
    return {
      isValid: true,
    };
  } catch ({ message }) {
    return {
      isValid: false,
      message: message,
    };
  }
}
