import SetterModel from "../SetterModel";
import { observable } from "mobx";

export default class LicenseCodeModel extends SetterModel {
  @observable
  licenseActivityType = "";
  @observable
  limitCount = 0;
  @observable
  licenseActivityOption = null;
}
