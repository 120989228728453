import { axiosModules } from "@qubit-utill/src";

export default {
  //pfilter > 태그 > siem > 태그 서비스 목록
  getTagServiceConfigList: () => {
    return axiosModules.get("/pfilter/tagserviceconfig/list");
  },

  //pfilter > 태그 > siem > 태그 참조 유형 목록
  getTagReferenceTypeList: (tagServiceId: string) => {
    return axiosModules.get(`/pfilter/tagreferencetype/${tagServiceId}/list`);
  },

  //pfilter > 태그 > siem > 태그 주소 유형 목록
  getTagValueTypes: () => {
    return axiosModules.get("/pfilter/tagvaluetypes");
  },

  //pfilter > 태그 > siem > 태그 주소 유형 서비스별 목록
  getTagValueTypeList: (tagServiceId: string) => {
    return axiosModules.get(`/pfilter/tagvaluetype/${tagServiceId}/list`);
  },

  //pfilter > 태그 > siem > 태그 주소 유형 조회
  getTagValueTypeInfo: (tagServiceId: string, tagValueType: string) => {
    return axiosModules.get(`/pfilter/tagvaluetype/${tagServiceId}/${tagValueType}`);
  },

  //pfilter > 태그 > siem > 태그 데이터 목록
  getTagServiceDataList: (tagServiceId: string, params: object) => {
    return axiosModules.get(`/pfilter/tagservicedata/${tagServiceId}/list`, params);
  },

  //pfilter > 태그 > siem > 태그 데이터 조회
  getTagServiceDataInfo: (tagServiceDataId: string) => {
    return axiosModules.get(`/pfilter/tagservicedata/${tagServiceDataId}`);
  },

  //pfilter > 태그 > siem > 태그 property 목록
  getTagServicePropertyList: (tagServiceId: string) => {
    return axiosModules.get(`/pfilter/tagserviceproperty/${tagServiceId}/list`);
  },

  //pfilter > 태그 > siem > 태그 데이터 등록
  createTagServiceData: (tagServiceId: string, datas: object) => {
    return axiosModules.post(`/pfilter/tagservicedata/${tagServiceId}`, {
      ...datas,
    });
  },

  //pfilter > 태그 > siem > 태그 데이터 다중 등록 체크
  createCheckedTagServiceDatas: (tagServiceId: string, datas: object) => {
    return axiosModules.post(`/pfilter/tagservicedatas/${tagServiceId}/check`, {
      ...datas,
    });
  },

  //pfilter > 태그 > siem > 태그 데이터 다중 등록
  createTagServiceDatas: (tagServiceId: string, datas: object) => {
    return axiosModules.post(`/pfilter/tagservicedatas/${tagServiceId}`, {
      ...datas,
    });
  },

  //pfilter > 태그 > siem > 태그 데이터 수정
  updateTagServiceData: (tagServiceDataId: string, data: object) => {
    return axiosModules.put(`/pfilter/tagservicedata/${tagServiceDataId}`, data);
  },

  //pfilter > 태그 > siem > 태그 데이터 다중 등록/수정
  updateTagServiceDatas: (data: object) => {
    return axiosModules.put("/pfilter/tagservicedatas", data);
  },

  //pfilter > 태그 > siem > 태그 데이터 다중 삭제
  deleteTagServiceDatas: (tagServiceDataIds: string) => {
    return axiosModules.del("pfilter/tagservicedatas", tagServiceDataIds);
  },
};
