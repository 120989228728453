import { languageSet } from "@qubit-utill/src";

import {
  COMPLIANCE_LICENSECODE,
  CUSTOMAPI_LICENSECODE,
  GROUPSUPPORTSERVICE_LICENSECODE,
  GROUPALARM_LICENSECODE,
  TICKETCONFIG_LICENSECODE,
  WAF_LICENSECODE,
} from "@src/constants/licenseCode";
import getURI from "@src/constants/uriset";
import TabMenusType from "@src/type/TabMenusType";

import { GroupManageMainContainer, GroupManageContainer } from "@src/pages";

export default {
  //그룹
  GROUP: {
    link: getURI("GROUP"),
    title: languageSet("관리"),
    tabMenus: [
      {
        to: getURI("GROUP"),
        name: "HOME",
      },
      {
        to: getURI("GROUP_SERVICE"),
        name: languageSet("서비스"),
        licenseCode: [GROUPSUPPORTSERVICE_LICENSECODE],
      },
      {
        to: getURI("GROUP_MEMBER"),
        name: languageSet("멤버"),
      },
      {
        to: getURI("GROUP_SECURITY"),
        name: languageSet("보안"),
        roleCode: "groupmanagement",
      },
      {
        to: getURI("GROUP_IP"),
        name: languageSet("목록"),
        roleCode: "grouplist",
      },
      {
        to: getURI("GROUP_TICKET"),
        name: languageSet("연동"),
        licenseCode: [TICKETCONFIG_LICENSECODE, CUSTOMAPI_LICENSECODE, COMPLIANCE_LICENSECODE],
        roleCode: "groupmanagement",
      },
      {
        to: getURI("GROUP_SETTINGDETECT"),
        name: languageSet("사용"),
        roleCode: "groupmanagement",
      },
      {
        to: getURI("GROUP_SYSTEM"),
        name: languageSet("타이틀_시스템"),
      },
      {
        to: getURI("GROUP_WAF"),
        name: languageSet("웹방화벽"),
        licenseCode: [WAF_LICENSECODE],
        // todo - WAF 라이센스 추가로 웹방화벽 설정은 라이센스로 사용유무 체크하고 롤권한 체크여부는 차후 논의
        roleCode: "groupwaf",
      },
      {
        to: getURI("GROUP_FIREWALL"),
        name: languageSet("방화벽"),
        isUseFlag: false,
        roleCode: "firewallmanagement",
      },
      {
        to: getURI("GROUP_HAPROXY"),
        name: "HAProxy",
        isUseFlag: false,
        roleCode: "haproxymanagement",
      },
      {
        to: getURI("GROUP_SQUID"),
        name: "SQUID",
        isUseFlag: false,
        roleCode: "squidmanagement",
      },
      {
        to: getURI("GROUP_ALARM"),
        name: languageSet("그룹알림"),
        licenseCode: [GROUPALARM_LICENSECODE],
        roleCode: "alarmsetting",
      },
      {
        to: getURI("GROUP_USERLOG"),
        name: languageSet("사용로그"),
        roleCode: "groupmanagement",
      },
    ] as TabMenusType[],
    component: GroupManageMainContainer,
  },
  GROUP_MEMBER: {
    link: getURI("GROUP_MEMBER"),
    component: GroupManageContainer,
  },
  GROUP_SERVICE: {
    link: getURI("GROUP_SERVICE"),
    component: GroupManageContainer,
  },
  GROUP_IP: {
    link: getURI("GROUP_IP"),
    component: GroupManageContainer,
  },
  GROUP_IP_SUB: {
    link: getURI("GROUP_IP"),
  },
  HASHMANAGEMENT_SUB: {
    link: getURI("GROUP_IP"),
  },
  GROUP_SECURITY: {
    link: getURI("GROUP_SECURITY"),
    component: GroupManageContainer,
  },
  GROUP_TICKET: {
    link: getURI("GROUP_TICKET"),
    component: GroupManageContainer,
  },
  GROUP_SETTINGDETECT: {
    link: getURI("GROUP_SETTINGDETECT"),
    component: GroupManageContainer,
  },
  GROUP_USERLOG: {
    link: getURI("GROUP_USERLOG"),
    component: GroupManageContainer,
  },
  WEBFIREWALLSETTING: {
    link: getURI("WEBFIREWALLSETTING"),
    component: GroupManageContainer,
  },
  GROUP_ALARM: {
    link: getURI("GROUP_ALARM"),
    component: GroupManageContainer,
  },
  GROUP_SYSTEM: {
    link: getURI("GROUP_SYSTEM"),
    component: GroupManageContainer,
  },
  GROUP_WAF: {
    link: getURI("GROUP_WAF"),
    component: GroupManageContainer,
  },
  GROUP_FIREWALL: {
    link: getURI("GROUP_FIREWALL"),
    component: GroupManageContainer,
  },
  GROUP_HAPROXY: {
    link: getURI("GROUP_HAPROXY"),
    component: GroupManageContainer,
  },
  GROUP_SQUID: {
    link: getURI("GROUP_SQUID"),
    component: GroupManageContainer,
  },
};
