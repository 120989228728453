import axiosModules from "../axiosSetting";

export default {
  //채널 목록 (필터 생성/수정시 사용)
  getChannelListForFilter: typeos => {
    return axiosModules.get(`/pfilter/filterchannel/registerfilter?typeos=${typeos}`);
  },

  // pfilter > filters > 필터 자동완성 검색용 > 필터 채널목록 - 자동완성용
  getChannelListForAutoComplete: channelLogType =>
    axiosModules.get(`/pfilter/filterchannel/simple/${channelLogType}`, { limit: 9999 }),
};
