import { languageSet } from "@qubit-utill/src";

import getURI from "@src/constants/uriset";
import { FILTER_LICENSECODE } from "@src/constants/licenseCode";

import {
  RecommendFilterContainer,
  RecommendFilterDetailContainer,
  RecommendFilterEditContainer,
  RecommendFilterSetContainer,
} from "@src/pages";

export default {
  //필터등록 : 추천필터수정 -> 복사한 경우
  RECOMMENDFILTER_REGIST: {
    link: getURI("RECOMMENDFILTER_REGIST"),
    title: languageSet("필터등록"),
    component: RecommendFilterSetContainer,
  },
  RECOMMENDFILTER_SYSTEM: {
    withPageLink: `${getURI("RECOMMENDFILTER_SYSTEM")}/page/:page`,
    link: getURI("RECOMMENDFILTER_SYSTEM"),
    licenseCode: FILTER_LICENSECODE,
    isDefault: true,
    component: RecommendFilterContainer,
  },
  RECOMMENDFILTER_SYSTEM_DETAIL: {
    title: languageSet("추천필터"),
    link: `${getURI("RECOMMENDFILTER_SYSTEM_DETAIL")}/:filterId`,
    licenseCode: FILTER_LICENSECODE,
    isDetail: true,
    component: RecommendFilterDetailContainer,
  },
  RECOMMENDFILTER_SYSTEM_EDIT: {
    title: languageSet("추천필터수정"),
    link: `${getURI("RECOMMENDFILTER_SYSTEM_EDIT")}/:filterId`,
    licenseCode: FILTER_LICENSECODE,
    isEdit: true,
    component: RecommendFilterEditContainer,
  },
  RECOMMENDFILTER_APPLICATION: {
    withPageLink: `${getURI("RECOMMENDFILTER_APPLICATION")}/page/:page`,
    link: getURI("RECOMMENDFILTER_APPLICATION"),
    licenseCode: FILTER_LICENSECODE,
    component: RecommendFilterContainer,
  },
  RECOMMENDFILTER_APPLICATION_DETAIL: {
    title: languageSet("추천필터"),
    link: `${getURI("RECOMMENDFILTER_APPLICATION_DETAIL")}/:filterId`,
    licenseCode: FILTER_LICENSECODE,
    isDetail: true,
    component: RecommendFilterDetailContainer,
  },
  RECOMMENDFILTER_APPLICATION_EDIT: {
    title: languageSet("추천필터수정"),
    link: `${getURI("RECOMMENDFILTER_APPLICATION_EDIT")}/:filterId`,
    lincesCode: FILTER_LICENSECODE,
    isEdit: true,
    component: RecommendFilterEditContainer,
  },
  RECOMMENDFILTER_NETWORK: {
    withPageLink: `${getURI("RECOMMENDFILTER_NETWORK")}/page/:page`,
    link: getURI("RECOMMENDFILTER_NETWORK"),
    licenseCode: FILTER_LICENSECODE,
    component: RecommendFilterContainer,
  },
  RECOMMENDFILTER_NETWORK_DETAIL: {
    title: languageSet("추천필터"),
    link: `${getURI("RECOMMENDFILTER_NETWORK_DETAIL")}/:filterId`,
    licenseCode: FILTER_LICENSECODE,
    isDetail: true,
    component: RecommendFilterDetailContainer,
  },
  RECOMMENDFILTER_NETWORK_EDIT: {
    title: languageSet("추천필터수정"),
    link: `${getURI("RECOMMENDFILTER_NETWORK_EDIT")}/:filterId`,
    licenseCode: FILTER_LICENSECODE,
    isEdit: true,
    component: RecommendFilterEditContainer,
  },
};
