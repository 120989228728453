import { languageSet } from "@qubit-utill/src";

import getURI from "@src/constants/uriset";
import {
  CORRELATION_LICENSECODE,
  MITRE_LICENSECODE,
  WAF_LICENSECODE,
  REPORT_LICENSECODE,
  WEBEXTENDSDATABREACH_LICENSECODE,
  EXTORTACCOUNT_LICENSECODE,
  EXTRACTIONLOGIN_LICENSECODE,
  BOXPLOT_LICENSECODE,
  WEB_LICENSECODE,
} from "@src/constants/licenseCode";
import {
  IPReportContainer,
  NetworkReportContainer,
  WebReportContainer,
  CorrelationReportContainer,
  SystemReportContainer,
  BoxPlotReportContainer,
  WordCloudReportContainer,
  HeatMapReportContainer,
  WebFirewallReportContainer,
  LogUploadReportContainer,
  DataBreachReportContainer,
  MitreReportContainer,
  ExtortAccountReportContainer,
  LoginReportContainer,
  MainReportContainer,
  ApplicationReportContainer,
  HostSecurityReportContainer,
} from "@src/pages";

export default {
  //보고서
  REPORT_MAIN: {
    text: languageSet("메인"),
    title: languageSet("보고서"),
    link: getURI("REPORT_MAIN"),
    component: MainReportContainer,
  },
  REPORT_WEBFIREWALL: {
    text: languageSet("웹방화벽"),
    link: getURI("REPORT_WEBFIREWALL"),
    licenseCode: WAF_LICENSECODE,
    component: WebFirewallReportContainer,
  },
  REPORT_HOSTSECURITY: {
    text: languageSet("호스트보안"),
    link: getURI("REPORT_HOSTSECURITY"),
    component: HostSecurityReportContainer,
  },
  REPORT_MITRE: {
    text: languageSet("마이터_어택"),
    link: getURI("REPORT_MITRE"),
    licenseCode: MITRE_LICENSECODE,
    component: MitreReportContainer,
  },
  REPORT_CORRELATION: {
    text: languageSet("상관분석"),
    link: getURI("REPORT_CORRELATION"),
    licenseCode: CORRELATION_LICENSECODE,
    component: CorrelationReportContainer,
  },
  REPORT_DATABREACH: {
    text: languageSet("데이터유출"),
    link: getURI("REPORT_DATABREACH"),
    licenseCode: WEBEXTENDSDATABREACH_LICENSECODE,
    component: DataBreachReportContainer,
  },
  REPORT_EXTORTACCOUNT: {
    text: languageSet("계정탈취"),
    link: getURI("REPORT_EXTORTACCOUNT"),
    licenseCode: EXTORTACCOUNT_LICENSECODE,
    component: ExtortAccountReportContainer,
  },
  REPORT_SYS: {
    text: languageSet("시스템"),
    link: getURI("REPORT_SYS"),
    licenseCode: REPORT_LICENSECODE,
    component: SystemReportContainer,
  },
  REPORT_APP: {
    text: languageSet("응용프로그램"),
    link: getURI("REPORT_APP"),
    component: ApplicationReportContainer,
  },
  REPORT_WEB: {
    text: languageSet("웹"),
    link: getURI("REPORT_WEB"),
    licenseCode: WEB_LICENSECODE,
    // licenseCode: REPORT_LICENSECODE,
    component: WebReportContainer,
  },
  REPORT_NET: {
    text: languageSet("네트워크"),
    link: getURI("REPORT_NET"),
    licenseCode: REPORT_LICENSECODE,
    component: NetworkReportContainer,
  },

  //통계
  STATISTIC_IP: {
    text: languageSet("IP주소"),
    link: getURI("STATISTIC_IP"),
    licenseCode: REPORT_LICENSECODE,
    component: IPReportContainer,
  },
  STATISTIC_LOGIN: {
    text: "Login",
    link: getURI("STATISTIC_LOGIN"),
    licenseCode: EXTRACTIONLOGIN_LICENSECODE,
    component: LoginReportContainer,
  },
  STATISTIC_UPLOAD: {
    text: languageSet("업로드용량"),
    link: getURI("STATISTIC_UPLOAD"),
    licenseCode: REPORT_LICENSECODE,
    component: LogUploadReportContainer,
    localStorageName: "STATISTIC_UPLOAD",
  },
  STATISTIC_WORDCLOUD: {
    text: languageSet("워드클라우드"),
    link: getURI("STATISTIC_WORDCLOUD"),
    licenseCode: REPORT_LICENSECODE,
    component: WordCloudReportContainer,
    localStorageName: "STATISTIC_WORDCLOUD",
  },
  STATISTIC_HEATMAP: {
    text: languageSet("히트맵"),
    link: getURI("STATISTIC_HEATMAP"),
    licenseCode: REPORT_LICENSECODE,
    component: HeatMapReportContainer,
  },
  STATISTIC_BOXPLOT: {
    text: languageSet("박스플롯"),
    link: getURI("STATISTIC_BOXPLOT"),
    licenseCode: BOXPLOT_LICENSECODE,
    component: BoxPlotReportContainer,
    localStorageName: "STATISTIC_BOXPLOT",
  },

  DATE_CATEGORY: {
    WEEK: [
      languageSet("일"),
      languageSet("월"),
      languageSet("화"),
      languageSet("수"),
      languageSet("목"),
      languageSet("금"),
      languageSet("토"),
    ],
    MONTH: (() => {
      let a = new Array(31);
      a.fill(null);
      a = a.map((d, i) => i + 1);
      return a;
    })(),
    HOUR: (() => {
      let a = new Array(24);
      a.fill(null);
      a = a.map((d, i) => languageSet("n시", i));
      return a;
    })(),
  },
};
