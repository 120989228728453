import { observable, computed } from "mobx";
import SetterModel from "../SetterModel";
import { Common_Utils } from "@qubit-utill/src/utils";

class SearchColumnModel extends SetterModel {
  constructor(params, codeList) {
    super(params);
    this.name = params.searchMenuName;
    this.value = params.searchColumn;

    const assignList = params.searchColumnAssign.split(",");
    if (codeList) {
      this.searchColumnAssignList = assignList.map(assign => ({
        name: codeList.find(info => info.code === assign)[
          params.searchColumnType === "number" ? "codeOption" : "codeName"
        ],
        value: assign,
      }));
    }
  }

  @computed
  get isSearchArrayFlag() {
    return Common_Utils.isCheckBool(this.isSearchArray);
  }

  @computed
  get isSearchDataMappingFlag() {
    //true: db, false: solr 검색
    return Common_Utils.isCheckBool(this.isSearchDataMapping);
  }

  @computed
  get isFilterName() {
    return this.schemaName && this.schemaName.includes("filterName");
  }

  @computed
  get isChannel() {
    return this.schemaName && this.schemaName.includes("channel");
  }

  @observable
  name: string = "";

  @observable
  value: string = "";

  @observable
  searchColumnType: string = ""; //textAnalysis, number, date

  @observable
  isSearchArray: string = "0";

  @observable
  searchColumnAssignList = [];

  @observable
  isSearchDataMapping: string = "";

  @observable
  schemaName: string = "";
}
export default SearchColumnModel;
