import { observable, computed } from "mobx";
import { SetterModel, languageSet, Common_Utils, dateUtil, formatConstants } from "@qubit-utill/src";

class UserModel extends SetterModel {
  constructor(params, useLicenseInfo, CodeStore) {
    super(params);
    if (params) {
      this.lastLoginSuccessDate = dateUtil.modelDateFormat(params.lastLoginSuccessDate);
      this.isMailingOrigin = params.isMailing;
      this.isInfomailingOrigin = params.isInfomailing;
      this.paramLang = params.lang;
      this.isPin = params.isPin;
    }
    if (useLicenseInfo) {
      this.useLicenseType = useLicenseInfo.useLicenseType;
      this.startUseLicenseDate = dateUtil.format(
        dateUtil.newDate(useLicenseInfo.startUseLicenseDate),
        formatConstants.DATEFORMAT
      );
      this.endUseLicenseDate = dateUtil.format(dateUtil.newDate(useLicenseInfo.endUseLicenseDate), formatConstants.DATEFORMAT);
    }
    this.CodeStore = CodeStore;
  }

  @observable
  CodeStore;

  @computed
  get authority() {
    return this.CodeStore.getCodeName("roleList", this.roleCode, "");
  }

  @computed
  get isMailingFlag() {
    return Common_Utils.isCheckBool(this.isMailing);
  }

  @computed
  get isPinFlag() {
    return Common_Utils.isCheckBool(this.isPin);
  }

  @computed
  get isInfomailingFlag() {
    return Common_Utils.isCheckBool(this.isInfomailing);
  }

  @computed
  get useLicenseTypeName() {
    return this.useLicenseType
      ? this.CodeStore.code.useLicenseType && this.CodeStore.code.useLicenseType[this.useLicenseType].codeName
      : languageSet("무료");
  }

  @computed
  get langText() {
    return this.CodeStore.getCodeName("supportLang", this.paramLang);
  }

  @observable
  paramLang: string = "";

  @observable
  isMailingOrigin: string = "";

  @observable
  isInfomailingOrigin: string = "";

  @observable
  startUseLicenseDate = "";
  @observable
  endUseLicenseDate = "";
  @observable
  useLicenseType = "";

  @observable
  companyName = "";
  @observable
  companyNo = 0;
  @observable
  department = "";
  @observable
  fuid = null;
  @observable
  guid = "";
  @observable
  guidCreateDate = "";
  @observable
  isInfomailing = "0";
  @observable
  isMailing = "0";
  @observable
  isPin = "0";
  @observable
  lang = "";
  @observable
  lastLoginSuccessDate = "";
  @observable
  nation = "";
  @observable
  position = "";
  @observable
  registerDate = "";
  @observable
  roleCode = "";
  @observable
  updateDate = "";
  @observable
  userActivationKey = null;
  @observable
  userActivationState = "1";
  @observable
  userEmail = "";
  @observable
  userGroupNo = 0;
  @observable
  userGroupStatus = "1";
  @observable
  userName = "";
  @observable
  userNo = 0;
  @observable
  userPhone = "";
  @observable
  userState = "1";
  @observable
  userStateType = null;
  @observable
  uuid = "";
  @observable
  masterYN = "";
  @observable
  service = "";
  @observable
  serverCount = 1;
  @observable
  onpromise = false;
  @observable
  roleName = "";
  @observable
  userType = "";
  @observable
  serviceAgreement = "";
  @observable
  lastPasswordChangePeriod = 0;
}

export default UserModel;
