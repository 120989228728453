import React from "react";
import { observer } from "mobx-react";
import { RadioButton } from "@qubit-utill/src/components/atoms";
import PropTypes from "prop-types";

const WrapRadioButton = ({
  children,
  onSelect,
  isChecked,
  disabled = false,
  className,
  tag = "div",
  prClass,
  color,
}) => {
  return (
    <span className={`dis-inline-b ${prClass} verti_top ${className}`}>
      <RadioButton
        onSelect={onSelect}
        isChecked={isChecked}
        disabled={disabled}
        tag={tag}
        color={color}
      >
        {children}
      </RadioButton>
    </span>
  );
};

WrapRadioButton.propTypes = {
  className: PropTypes.string,
  prClass: PropTypes.string,
};

WrapRadioButton.defaultProps = {
  className: "p-t-8",
  prClass: "p-r-10",
};

export default observer(WrapRadioButton);
