import { useHistory } from "react-router-dom";
import { Common_Utils } from "@qubit-utill/src";
import { getURI } from "@src/constants";

export default function useLink(store?: any) {
  const history = useHistory();

  const setObjectTosession = (name: string, obj: object) => {
    sessionStorage.setItem(Common_Utils.getPathName(name), JSON.stringify(obj));
  };

  return {
    goToLinkWithInfo: (path: string, info: any, newTab?: boolean) => {
      const link = getURI(path);
      sessionStorage.setItem(link, info);
      newTab ? window.open(link) : history.push(link);
    },
    goToLink: (path: string, newTab?: boolean) => {
      const link = getURI(path);
      newTab ? window.open(link) : history.push(link);
    },
    goToLinkWithParams: (link: string) => {
      setObjectTosession(Common_Utils.getCurrentPathName(), store.params);
      history.push(link);
    },
    goToListWithPage: (path: string) => {
      const saved = sessionStorage.getItem(Common_Utils.getPathName(getURI(path)));
      if (saved) {
        const params: { offset: number; limit: number } = JSON.parse(saved);
        const page = Common_Utils.getPage(params.offset, params.limit);
        history.push(getURI(path) + "/page/" + page);
      } else {
        history.push(getURI(path));
      }
    },
    goToLinkWithPathParams: (storeParams: any, newTab: boolean, link: string) => {
      setObjectTosession(link, storeParams);
      if (newTab === true) {
        window.open(link);
        sessionStorage.removeItem(Common_Utils.getPathName(link));
        sessionStorage.removeItem("searchWord");
      } else {
        history.push(link);
      }
    },
    newTabWithParam: (storeParams: object, link: string) => {
      setObjectTosession(link, storeParams);
      window.open(link);
      sessionStorage.removeItem(Common_Utils.getPathName(link));
      sessionStorage.removeItem("searchWord");
    },
    goToBackPage: () => {
      const url = document.referrer || document.URL;
      url.indexOf("plura.io") !== -1 ? history.goBack() : history.push(getURI("DASHBOARD"));
    },
  };
}
