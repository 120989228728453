import React, { useState } from "react";
import Chart from "react-apexcharts";
import { observer } from "mobx-react";
import { toJS } from "mobx";

import { Common_Utils } from "qubit-utill/src";

import UISET from "@src/constants/uiset";
import NullLogData from "@src/common/components/organisms/Graphs/NullLogData";

interface GraphOptionPropsTypes {
  tooltip?: {
    isUseTooltipMaxHeight?: boolean;
  };
}

interface PropsTypes {
  id: string;
  title: string;
  data: { name: string; data: number[] }[];
  category: string[];
  selectedIndex?: number;
  onClick?: Function;
  valueFormater?: Function;
  height?: number;
  useOnClick?: boolean;
  graphOption?: GraphOptionPropsTypes;
}

const StackBar: React.FC<PropsTypes> = ({
  id,
  title,
  data,
  category,
  selectedIndex = -1,
  onClick,
  valueFormater,
  height = 250,
  useOnClick,
  graphOption,
}) => {
  const [chartIndex, setChartIndex] = useState<number>(selectedIndex); //현재 x축 index

  //onClick event 있을 경우만 category 색상 변경.
  let categoriesColors: string[] = [];
  if (onClick && category && category.length > 0) {
    categoriesColors = new Array<string>(category.length).fill("");
    categoriesColors[chartIndex] = "#FF0000";
  }

  let sumMaxColor = "#ff0000";

  const chartUpdate = function(opts) {
    const { w } = opts;

    const sumMaxValues = w.globals.stackedSeriesTotals;
    const sumMaxValue = Math.max(...sumMaxValues);
    const formatSumMaxValue = _valueFormatter(sumMaxValue);

    if (sumMaxValue > 0) {
      const dataLabels = opts.el.querySelectorAll(".apexcharts-text");
      dataLabels.forEach((label) => {
        if (label.classList.length > 1) {
          // X축, Y축 레이블을 제외
          return;
        }
        var value = label.textContent;
        if (value === formatSumMaxValue) {
          return (label.style.fill = sumMaxColor);
        }
      });
    }
  };

  let dataSum: any = [];

  const _valueFormatter = (value) =>
    Common_Utils.isNull(value) ? (valueFormater ? valueFormater(value) : Common_Utils.numberWithComma(value)?.toString()) : "";

  const series = data && data.length > 0 ? toJS(data) : [];

  const options = {
    title: { text: title, align: "center" as const },
    chart: {
      id: id,
      stacked: true,
      toolbar: {
        show: false,
      },
      redrawOnParentResize: true,
      events: {
        dataPointSelection: function(event, chartContext, config) {
          if (useOnClick) {
            const dataPointIndex: number = config.dataPointIndex; //선택한 x축 data index
            const _series = config.w.globals.series;
            let stackedData: number[] = [];

            for (let i = 0; i < _series.length; i++) {
              if (!Common_Utils.isEmpty(_series[i][dataPointIndex])) {
                stackedData.push(_series[i][dataPointIndex]);
              }
            }

            onClick && onClick(config.w.globals.labels[dataPointIndex], stackedData, dataPointIndex);
            onClick && setChartIndex(dataPointIndex);
          }
        },
        dataPointMouseEnter: function(event, chartContext) {
          if (useOnClick) {
            event.target.style.cursor = "pointer";
          }
          if (graphOption?.tooltip?.isUseTooltipMaxHeight) {
            const tooltipItmes = chartContext.el.querySelectorAll(".apexcharts-tooltip-series-group");
            if (tooltipItmes) {
              for (let i = 0; i < tooltipItmes.length; i++) {
                tooltipItmes[i].style.maxHeight = "27px";
              }
            }
          }
        },
        mounted: chartUpdate,
        updated: chartUpdate,
      },
    },
    xaxis: {
      categories: category && category.length > 0 ? toJS(category) : [],
      labels: {
        style: {
          colors: categoriesColors,
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function(value, index) {
          return _valueFormatter(value) as string;
        },
      },
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      x: {
        formatter: function(value, opts) {
          const nullValue = dataSum[opts.dataPointIndex] === 0;
          return nullValue ? null : value;
        },
      },
      y: {
        formatter: function(value, opts) {
          const nullValue = dataSum[opts.dataPointIndex] === 0 || !Common_Utils.isNull(value);
          return nullValue ? null : _valueFormatter(value);
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "60%",
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 0,
            formatter: function(value, opts) {
              dataSum[opts.dataPointIndex] = value;
              const nullValue = data.every(({ data }) => data[opts.dataPointIndex] === null);
              return nullValue ? "-" : (_valueFormatter(value) as string);
            },
          },
        },
      },
    },
    dataLabels: {
      formatter: function(value) {
        return (Common_Utils.isNull(value) ? _valueFormatter(value) : "") as string;
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      position: "bottom" as const,
      horizontalAlign: "center" as const,
      offsetY: 8,
      markers: {
        radius: 12,
      },
    },
    colors: UISET.CHART_COLOR,
  };

  const isNullData =
    !data ||
    data.length === 0 ||
    (data.length > 0 && data.every(({ data }) => !data || data.every((item) => !Common_Utils.isNull(item))));

  return isNullData ? (
    <NullLogData height={height} />
  ) : (
    <Chart options={options} series={series} type="bar" height={`${height}px`} />
  );
};

export default observer(StackBar);
