import { observable, action, toJS } from "mobx";

import { ListCondition, withLoading, Common_Utils, languageSet } from "@qubit-utill/src";

import { resourceMetaApis } from "@src/apis/pMetrics";
import { applicationLogApis } from "@src/apis/pLog";

import { UISET } from "@src/constants";

import HostTotalResourceMonitoringModel from "@src/pages/ResourceMonitoring/HostTotalResourceMonitoring/models/HostTotalResourceMonitoringModel";

class HostTotalResourceMonitoringStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }

  root;

  @observable
  tableList = [];

  @observable
  resourceMetaList = [];

  @observable
  resourceThList = [];

  @observable
  resourceId: string = "";

  @withLoading("isLoading")
  @action
  getList = async () => {
    try {
      await this.getResourceId();
      const { list, totalCount, nextCursorMark } =
        this.resourceId && (await applicationLogApis.getResourceLogList(toJS(this.params)));
      this.tableList = list.map((info) => {
        const _info = {
          ...info,
          resourceMetaList: this.resourceMetaList,
        };
        return new HostTotalResourceMonitoringModel(_info);
      });

      this.setCursorMarkInfo(nextCursorMark);
      this.totalCount = totalCount;
    } catch (e) {
      this.tableList = [];
      this.totalCount = 0;
    }
  };

  @action
  getResourceMetaList = async () => {
    try {
      const { list } = this.resourceId && (await resourceMetaApis.getResourceMetaList(this.resourceId));
      this.resourceMetaList = list;

      this.resourceThList = list
        ? list.map((data) => {
            return {
              displayName: data.metaName,
              columnName: data.metaName,
              useSort: true,
              useCustom: true,
              // className: STYLE.TABLE.META_COLUMN,
            };
          })
        : [];
    } catch (e) {
      return (this.resourceThList = []);
    }
  };

  @action
  getResourceId = async () => {
    this.resourceId = await this.root.CodeStore.getDefaultCode("resourceMonitoringCode");
    this.setListParams("resourceId", this.resourceId);
  };

  @action
  getResourceData = async () => {
    await this.getResourceId();
    await this.getResourceMetaList();
  };

  @action
  downloadCSV = async (isUseBulk?, no?) => {
    try {
      if (this.tableList.length > 0) {
        let csv;
        csv = await applicationLogApis.getResourceLogCsvList({
          ...toJS(this.params),
          resourceId: this.resourceId,
          offset: 0,
          limit: no,
        });
        Common_Utils.csvDownload(
          csv,
          Common_Utils.setCsvFileName({
            title: UISET.RESOURCEMONITORING_HOST_TOTAL.title,
            category: languageSet("리소스모니터링"),
            ...this.params,
          })
        );
      } else {
        return languageSet("에러_데이터가없습니다");
      }
    } catch ({ data }) {
      console.log(data);
    }
  };
}

export default HostTotalResourceMonitoringStore;
