import { axiosModules } from "@qubit-utill/src";

export default {
  // plogdetect > 홈페이지 위변조 > 홈페이지 위변조 로그 조회
  getForgeryLog: (documentId) => axiosModules.get(`/plogdetect/detectforgery/${documentId}`),

  // plogdetect > 홈페이지 위변조 > 홈페이지 위변조 로그 리스트 조회
  getForgeryLogList: (params) => axiosModules.get("/plogdetect/detectforgery/list", params),

  // plogdetect > 홈페이지 위변조 > 홈페이지 위변조 로그 리스트 CSV 다운로드
  getForgeryLogDataCsvList: (params) => axiosModules.getCsv("/plogdetect/detectforgery/list/csv", params),
};
