import { observable, action } from "mobx";

import { withLoading, Common_Utils } from "@qubit-utill/src";

import { userFilterConfigApis } from "@src/apis/pFilter";
import { codeApis, pAccountApis, pCustomerApis } from "@src/apis";

import { WEBEXTENDSDATABREACHTYPE } from "@src/constants/code";

class GroupSystemStore {
  constructor(root) {
    this.root = root;
  }
  root;

  @observable
  isLoading = false;

  @observable
  timezones = [];

  @observable
  userTimezone = "";

  @observable
  userLang = "";

  @observable
  userNation = "";

  @observable
  isUseCursorMark: boolean = false;

  @observable
  servicePreferencesId: string = "";

  @observable
  servicePreferencesItemId: number = 0;

  @action
  setData = (key) => (value) => {
    this[key] = value;
  };

  @withLoading("isLoading")
  async getData() {
    await Promise.all([this.getUser(), this.getTimezones(), this.getCursorMarkInfo()]);
  }

  @action
  getUser = async () => {
    try {
      const data = await pAccountApis.getUserGroup();
      this.userTimezone = data.timezoneName;
      this.userNation = this.root.CodeStore.getCodeName("supportNation", data.nation);
      this.userLang = this.root.CodeStore.getCodeName("supportLang", data.lang);
    } catch (err) {}
  };

  @action
  getTimezones = async () => {
    try {
      const { list } = await codeApis.getTimezones();
      this.timezones = list.map(({ name, timezone }) => ({
        name,
        value: timezone,
      }));
    } catch (e) {
      console.log("getTimezones error", e);
    }
  };

  @action
  updateLang = ({ value }) =>
    pAccountApis.updateLang({
      paramLang: value.code,
    });

  @action
  updateNation = ({ value }) =>
    pAccountApis.updateNation({
      paramNation: value.code,
    });

  @action
  updateUserTimezone = (timezone) =>
    pAccountApis.updateUserTimezone({
      timezone,
    });

  @action
  updateUserFilterConfig = async (isUseGlobalFilter) => {
    const data = {
      isUseGlobalFilter,
      isUseCustomFilter: "1",
      serviceFilterType: WEBEXTENDSDATABREACHTYPE,
    };
    try {
      await userFilterConfigApis.updateUserFilterConfig(data);
    } catch ({ data }) {
      return data;
    }
  };

  @action
  getCursorMarkInfo: () => Promise<void | unknown> = async () => {
    try {
      const { list } = await pCustomerApis.getUserPreferences({ servicePreferencesId: "isUseCursorMark" });
      const { servicePreferencesItemValue, servicePreferencesId, servicePreferencesItemId } = list?.find(
        ({ servicePreferencesId }) => servicePreferencesId === "isUseCursorMark"
      );
      this.isUseCursorMark = Common_Utils.isCheckBool(servicePreferencesItemValue === "1" ? "1" : "0");
      this.servicePreferencesId = servicePreferencesId;
      this.servicePreferencesItemId = servicePreferencesItemId;
    } catch (err) {
      console.log("err :", err);
      this.isUseCursorMark = false;
    }
  };

  @action
  setCursorMarkInfo: (info: {
    servicePreferencesId: string;
    servicePreferencesItemId: number;
  }) => Promise<unknown | void> = async (info) => {
    try {
      // Service Preference 전체 목록을 가져와서, 현재 Service Preference 의 반대 정보를 가져온다. (양자택일로 기능 동작)
      const { list } = await pCustomerApis.getServicePreferenceList({ servicePreferencesId: "isUseCursorMark" });
      const { servicePreferencesId, servicePreferencesItemId } = list?.isUseCursorMark?.find(
        ({ servicePreferencesItemId }) => servicePreferencesItemId !== info.servicePreferencesItemId
      );
      // Service Preference 로 반대 정보를 등록한다.
      await pCustomerApis.setThemeUserInfo([{ servicePreferencesId, servicePreferencesItemId }]);
      this.servicePreferencesId = servicePreferencesId;
      this.servicePreferencesItemId = servicePreferencesItemId;
      this.isUseCursorMark = !this.isUseCursorMark;
    } catch (err) {
      console.log("err : ", err);
      return err;
    }
  };
}

export default GroupSystemStore;
