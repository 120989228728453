import { observable, action, toJS } from "mobx";

import { ListCondition, withLoading, languageSet, Common_Utils } from "@qubit-utill/src";

import { webDetectApis, webExtendsDetectApis } from "@src/apis/pLogDetect";
import { complianceSettingApis } from "@src/apis/pFilter";
import { DefenceStore, UserCustomStore } from "@src/common/stores";
import { CSVINFO, UISET } from "@src/constants";
import { WAFWEBISSUETYPE, WEBEXTENDSDATABREACHTYPE } from "@src/constants/code";

import WebFilterDetectionModel from "@src/pages/FilterDetection/WebFilterDetection/models/WebFilterDetectionModel";
import DataBreachModel from "@src/pages/SecurityDetect/DataBreach/models/DataBreachModel";

class WebFirewallFilterDetectStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
    this.params.isWaf = true;
  }

  @observable
  root = {
    CodeStore: {},
  };

  @observable
  tableList: WebFilterDetectionModel[] = [];

  @observable
  webFirewallInfo: WebFilterDetectionModel = new WebFilterDetectionModel({
    info: null,
    CodeStore: this.root.CodeStore,
    csvInfoKey: "",
    issueServiceType: "",
  });

  @observable
  webExtendsInfo: DataBreachModel = new DataBreachModel({
    info: null,
    rootStore: this.root,
    issueServiceType: WEBEXTENDSDATABREACHTYPE,
  });

  @observable
  ipDefenceType = "DEFENSE_IP_WEBFIREWALL";

  @action
  clear = () => {
    this.tableList = [];
    this.clearInfo();
  };

  @action
  clearInfo = () => {
    this.webFirewallInfo = new WebFilterDetectionModel({
      info: null,
      CodeStore: this.root.CodeStore,
      csvInfoKey: "",
      issueServiceType: "",
    });
    this.webExtendsInfo = new DataBreachModel({
      info: null,
      rootStore: this.root,
      issueServiceType: WEBEXTENDSDATABREACHTYPE,
    });
  };

  @withLoading("isLoading")
  @action
  getList = async () => {
    this.params.isWaf = true;
    try {
      //태그 프로퍼티 목록
      const tagServicePropertyList = this.tagServicePropertyList;
      const tagValueTypeList = this.tagValueTypeList;
      const getTagServiceDataList = this.openIpTagModal;

      const { list, totalCount, nextCursorMark } = await webDetectApis.getWebLogList(this.params);

      this.tableList = list.map((info) => {
        const _info = { ...info, tagServicePropertyList, tagValueTypeList, getTagServiceDataList };
        return new WebFilterDetectionModel({
          info: _info,
          CodeStore: this.root.CodeStore,
          csvInfoKey: "FILTERDETECTWEBFIREWALL",
          issueServiceType: WAFWEBISSUETYPE,
        });
      });
      this.setCursorMarkInfo(nextCursorMark);
      this.totalCount = totalCount;
    } catch (error) {
      console.log(error);
      this.tableList = [];
      this.totalCount = 0;
    }
  };

  getComplianceEntityCountByFilterKeys = async (filterIds) => {
    try {
      const { totalCount } = await complianceSettingApis.getComplianceEntityCountByFilterKeys(filterIds);
      return totalCount;
    } catch (e) {
      return 0;
    }
  };

  @withLoading("isSubLoading")
  @action
  getInfo = async (documentId) => {
    try {
      const tagServicePropertyList = this.tagServicePropertyList;
      const tagValueTypeList = this.tagValueTypeList;
      const getTagServiceDataList = this.openIpTagModal;
      const getTagServiceDataListFn = this.getTagServiceDataList;

      const { info } = await webDetectApis.getWebLog(documentId);

      const _info = { ...info, tagServicePropertyList, tagValueTypeList, getTagServiceDataList, getTagServiceDataListFn };

      this.webFirewallInfo = await new WebFilterDetectionModel({
        info: _info,
        CodeStore: this.root.CodeStore,
        csvInfoKey: null,
        issueServiceType: WAFWEBISSUETYPE,
      });

      if (this.webFirewallInfo.isWebExtendsDetect) {
        const { info } = await webExtendsDetectApis.getWebLogExtendsDetect(documentId);

        const _info = { ...info, tagServicePropertyList, tagValueTypeList, getTagServiceDataList, getTagServiceDataListFn };

        this.webExtendsInfo = await new DataBreachModel({
          info: _info,
          rootStore: this.root,
          issueServiceType: WEBEXTENDSDATABREACHTYPE,
        });
      }

      const totalCount = await this.getComplianceEntityCountByFilterKeys(this.webFirewallInfo.filterIds);
      this.webFirewallInfo.setInfo("complianceEntityCount", totalCount);

      this.webFirewallInfo.isReplayAttack && UserCustomStore.getUserCustomProtocol(this.webFirewallInfo);
      !this.webFirewallInfo.logBlockedFlag &&
        this.webFirewallInfo.attackIp &&
        (await DefenceStore.getDefenceInfo(this.webFirewallInfo)); //get 방어정보

      this.webFirewallInfo.attackIp && (await DefenceStore.getWafDefenceInfo(this.webFirewallInfo)); //get 방어정보
    } catch (err) {
      console.log(err);
      this.webFirewallInfo = new WebFilterDetectionModel({
        info: null,
        CodeStore: this.root.CodeStore,
        csvInfoKey: "",
        issueServiceType: WAFWEBISSUETYPE,
      });
      this.webExtendsInfo = new DataBreachModel({
        info: null,
        rootStore: this.root,
        issueServiceType: WEBEXTENDSDATABREACHTYPE,
      });
    }
  };

  @action
  downloadCSV = async (isUseBulk?, no?) => {
    try {
      if (this.tableList.length > 0) {
        let csv;
        if (isUseBulk && no) {
          csv = await webDetectApis.getWebLogCsvList({
            ...toJS(this.params),
            // serverIds: toJS(this.params.serverIds),
            // typeosList: toJS(this.params.typeosList),
            // serverGroupIds: toJS(this.params.serverGroupIds),
            offset: 0,
            limit: no,
          });
        } else {
          csv = Common_Utils.makeCSV(CSVINFO.FILTERDETECTWEBFIREWALL.head, this.tableList);
        }

        Common_Utils.csvDownload(
          csv,
          Common_Utils.setCsvFileName({
            title: UISET.FILTERDETECTWEBFIREWALL.title,
            category: languageSet("필터탐지"),
            ...this.params,
          })
        );
      } else {
        return languageSet("에러_데이터가없습니다");
      }
    } catch ({ data }) {
      console.log(data);
    }
  };
}

export default WebFirewallFilterDetectStore;
