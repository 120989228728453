import { getURI, UISET } from "@src/constants";
import { InstallContainer, FreeToPremiumContainer, ThemeContainer } from "@src/pages";
const routes = [
  /////////////////////////////////////////SIDE-BAR/////////////////////////////////////////
  //대시보드
  {
    exact: true,
    ...UISET.DASHBOARD,
  },
  //웹방화벽 탐지
  {
    ...UISET.FILTERDETECTWEBFIREWALL,
  },
  //호스트보안 탐지
  {
    ...UISET.FILTERDETECTHOSTSECURITY,
  },
  /////////////////////////////////////////////////////////////////////////////////////////
  //보안탐지 > 마이터
  {
    ...UISET.MITRE,
  },
  //보안탐지 > 상관분석
  {
    ...UISET.SECURITYDETECT_CORRELATION,
  },
  //보안탐지 > 데이터유출
  {
    ...UISET.SECURITYDETECT_DATABREACH,
  },
  //보안탐지 > 계정탈취
  {
    ...UISET.SECURITYDETECT_EXTORTACCOUNT,
  },
  //보안탐지 > 홈페이지 위변조
  {
    ...UISET.SECURITYDETECT_WEBSITEFORGERY,
  },
  /////////////////////////////////////////////////////////////////////////////////////////
  //필터탐지 > 시스템
  {
    ...UISET.FILTERDETECT_SYS,
  },
  //필터탐지 > 응용프로그램
  {
    ...UISET.FILTERDETECT_APP,
  },
  {
    ...UISET.FILTERDETECT_APP_DEFAULT,
  },
  {
    ...UISET.FILTERDETECT_APP_RESOURCE,
  },
  //필터탐지 > 웹
  {
    ...UISET.FILTERDETECT_WEB,
  },
  //필터탐지 > 네트워크
  {
    ...UISET.FILTERDETECT_NET,
  },
  /////////////////////////////////////////////////////////////////////////////////////////
  //ML탐지
  {
    ...UISET.ML,
  },
  /////////////////////////////////////////////////////////////////////////////////////////
  //전체로그 > 웹방화벽
  {
    ...UISET.TOTALLOG_WEBFIREWALL,
  },
  //전체로그 > 호스트보안
  {
    ...UISET.TOTALLOG_HOSTSECURITY,
  },
  //전체로그 > 시스템
  {
    ...UISET.TOTALLOG_SYS,
  },
  //전체로그 > 응용프로그램
  {
    ...UISET.TOTALLOG_APP,
  },
  {
    ...UISET.TOTALLOG_APP_DEFAULT,
  },
  {
    ...UISET.TOTALLOG_APP_RESOURCE,
  },
  //전체로그 > 웹
  {
    ...UISET.TOTALLOG_WEB,
  },
  //전체로그 > 네트워크
  {
    ...UISET.TOTALLOG_NET,
  },
  /////////////////////////////////////////////////////////////////////////////////////////
  //필터 > 보안필터
  {
    exact: true,
    ...UISET.SECURITYFILTER,
  },
  {
    exact: true,
    ...UISET.SECURITYFILTER_CORRELATION,
  },
  {
    ...UISET.SECURITYFILTER_CORRELATION_REGIST,
  },
  {
    ...UISET.SECURITYFILTER_CORRELATION_EDIT,
  },
  {
    exact: true,
    ...UISET.SECURITYFILTER_DATABREACH,
  },
  {
    ...UISET.SECURITYFILTER_DATABREACH_REGIST,
  },
  {
    ...UISET.SECURITYFILTER_DATABREACH_EDIT,
  },
  {
    exact: true,
    ...UISET.SECURITYFILTER_EXTORTACCOUNT,
  },
  {
    ...UISET.SECURITYFILTER_EXTORTACCOUNT_REGIST,
  },
  {
    ...UISET.SECURITYFILTER_EXTORTACCOUNT_EDIT,
  },
  {
    exact: true,
    ...UISET.SECURITYFILTER_WEBSITEFORERY,
  },
  {
    ...UISET.SECURITYFILTER_WEBSITEFORERY_EDIT,
  },
  {
    ...UISET.RECOMMENDFILTER_REGIST,
  },
  // //필터 > 추천필터 > 시스템
  {
    exact: true,
    ...UISET.RECOMMENDFILTER_SYSTEM,
  },
  {
    ...UISET.RECOMMENDFILTER_SYSTEM_DETAIL,
  },
  {
    ...UISET.RECOMMENDFILTER_SYSTEM_EDIT,
  },
  //필터 > 추천필터 > 응용프로그램
  {
    exact: true,
    ...UISET.RECOMMENDFILTER_APPLICATION,
  },
  {
    ...UISET.RECOMMENDFILTER_APPLICATION_DETAIL,
  },
  {
    ...UISET.RECOMMENDFILTER_APPLICATION_EDIT,
  },
  //필터 > 추천필터 > 네트워크
  {
    exact: true,
    ...UISET.RECOMMENDFILTER_NETWORK,
  },
  {
    ...UISET.RECOMMENDFILTER_NETWORK_DETAIL,
  },
  {
    ...UISET.RECOMMENDFILTER_NETWORK_EDIT,
  },
  //필터 > 등록필터
  {
    exact: true,
    ...UISET.REGISTERATIONFILTER,
  },
  {
    exact: true,
    ...UISET.REGISTERATIONFILTER_WEBFIREWALL,
  },
  {
    exact: true,
    ...UISET.REGISTERATIONFILTER_WEBFIREWALL_REGIST,
  },
  {
    ...UISET.REGISTERATIONFILTER_WEBFIREWALL_EDIT,
  },
  {
    exact: true,
    ...UISET.REGISTERATIONFILTER_SYSTEM,
  },
  {
    exact: true,
    ...UISET.REGISTERATIONFILTER_SYSTEM_REGIST,
  },
  {
    ...UISET.REGISTERATIONFILTER_SYSTEM_EDIT,
  },
  {
    exact: true,
    ...UISET.REGISTERATIONFILTER_APPLICATION_DEFAULT,
  },
  {
    exact: true,
    ...UISET.REGISTERATIONFILTER_APPLICATION_DEFAULT_REGIST,
  },
  {
    ...UISET.REGISTERATIONFILTER_APPLICATION_DEFAULT_EDIT,
  },
  {
    exact: true,
    ...UISET.REGISTERATIONFILTER_APPLICATION_RESOURCE,
  },
  {
    exact: true,
    ...UISET.REGISTERATIONFILTER_APPLICATION_RESOURCE_REGIST,
  },
  {
    ...UISET.REGISTERATIONFILTER_APPLICATION_RESOURCE_EDIT,
  },
  {
    exact: true,
    ...UISET.REGISTERATIONFILTER_WEB,
  },
  {
    exact: true,
    ...UISET.REGISTERATIONFILTER_WEB_REGIST,
  },
  {
    ...UISET.REGISTERATIONFILTER_WEB_EDIT,
  },
  {
    exact: true,
    ...UISET.REGISTERATIONFILTER_NETWORK,
  },
  {
    exact: true,
    ...UISET.REGISTERATIONFILTER_NETWORK_REGIST,
  },
  {
    ...UISET.REGISTERATIONFILTER_NETWORK_EDIT,
  },
  /////////////////////////////////////////////////////////////////////////////////////////
  //방어 > 차단IP주소
  {
    ...UISET.DEFENCEIP,
  },
  //방어 > 차단IP주소 > 시스템
  {
    ...UISET.DEFENSE_IP_SYSTEM,
  },
  //방어 > 차단IP주소 > 웹방화벽
  {
    ...UISET.DEFENSE_IP_WEBFIREWALL,
  },
  // //방어 > 로그
  // {
  //   ...UISET.DEFENSELOG,
  // },
  //방어 > 로그 > 시스템
  {
    ...UISET.DEFENSE_LOG_SYSTEM,
  },
  //방어 > 로그 > 웹방화벽
  {
    ...UISET.DEFENSE_LOG_WEBFIREWALL,
  },
  //방어 > 관리 > 시스템
  {
    ...UISET.DEFENSE_MANAGEMENT_SYSTEM,
  },
  //방어 > 관리 > 웹방화벽
  {
    ...UISET.DEFENSE_MANAGEMENT_WEBFIREWALL,
  },
  //방어 > 방어관리
  // {
  //   ...UISET.DEFENCEMANAGEMENT,
  // },
  {
    ...UISET.DEFENSE_MANAGEMENT_CORRELATION,
  },
  {
    ...UISET.DEFENSE_MANAGEMENT_SYSTEMLOG,
  },
  {
    ...UISET.DEFENSE_MANAGEMENT_EXTORTACCOUNTBLOCK,
  },
  {
    ...UISET.DEFENSE_MANAGEMENT_EXTORTACCOUNTRESTRICTION,
  },
  {
    ...UISET.DEFENSE_MANAGEMENT_DATABREACH,
  },
  {
    ...UISET.DEFENSE_MANAGEMENT_OWASP,
  },
  // {
  //   ...UISET.EXTORTACCOUNTDEFENCE,
  // },

  // {
  //   ...UISET.WEBLOGDEFENCE,
  // },
  //전체로그
  /////////////////////////////////////////////////////////////////////////////////////////
  //시스템 > 시스템관리
  {
    exact: true,
    ...UISET.SERVER_MANAGEMENT,
  },
  //시스템 > 그룹관리
  {
    exact: true,
    ...UISET.SERVERGROUP_MANAGEMENT,
  },
  {
    ...UISET.SERVERGROUP_EDIT,
  },
  {
    ...UISET.SERVERGROUP_REGIST,
  },
  // 리소스모니터링 > 호스트
  {
    exact: true,
    ...UISET.RESOURCEMONITORING_HOST,
  },
  // 리소스모니터링 > 호스트 전체
  {
    ...UISET.RESOURCEMONITORING_HOST_TOTAL,
  },
  //리소스모니터링 > 알림필터
  {
    exact: true,
    ...UISET.RESOURCEMONITORING_ALARMFILTER,
  },
  {
    exact: true,
    ...UISET.RESOURCEMONITORING_ALARMFILTER_REGIST,
  },
  {
    ...UISET.RESOURCEMONITORING_ALARMFILTER_EDIT,
  },
  /////////////////////////////////////////////////////////////////////////////////////////
  //추출로그 > IP주소
  {
    exact: true,
    ...UISET.EXTRACTION_IP,
  },
  //추출로그 > Login
  {
    exact: true,
    ...UISET.EXTRACTION_LOGIN,
  },
  //추출로그 > URL
  {
    exact: true,
    ...UISET.EXTRACTION_URL,
  },
  /////////////////////////////////////////////////////////////////////////////////////////
  //보고서 > 메인
  { ...UISET.REPORT_MAIN },
  //보고서 > 웹방화벽
  {
    ...UISET.REPORT_WEBFIREWALL,
  },
  //보고서 > 호스트보안
  {
    ...UISET.REPORT_HOSTSECURITY,
  },
  //보고서 > 마이터어택
  UISET.REPORT_MITRE,
  //보고서 > 상관분석
  {
    ...UISET.REPORT_CORRELATION,
  },
  //보고서 > 데이터유출
  {
    ...UISET.REPORT_DATABREACH,
  },
  //보고서 > 계정탈취
  {
    ...UISET.REPORT_EXTORTACCOUNT,
  },
  //보고서 > 시스템
  {
    ...UISET.REPORT_SYS,
  },
  //보고서 > 응용프로그램
  {
    ...UISET.REPORT_APP,
  },
  //보고서 > 웹
  {
    ...UISET.REPORT_WEB,
  },
  //보고서 > 네트워크
  {
    ...UISET.REPORT_NET,
  },
  /////////////////////////////////////////////////////////////////////////////////////////
  //통계 > IP주소
  {
    ...UISET.STATISTIC_IP,
  },
  //통계 > Login
  {
    ...UISET.STATISTIC_LOGIN,
  },
  //통계 > 업로드용량
  {
    ...UISET.STATISTIC_UPLOAD,
  },
  //통계 > 워드클라우드
  {
    ...UISET.STATISTIC_WORDCLOUD,
  },
  //통계 > 히트맵
  {
    ...UISET.STATISTIC_HEATMAP,
  },
  //통계 > 박스플롯
  {
    ...UISET.STATISTIC_BOXPLOT,
  },
  /////////////////////////////////////////////////////////////////////////////////////////
  //컴플라이언스
  {
    ...UISET.COMPLIANCE,
  },
  //업데이트 현황
  {
    ...UISET.AGENTLOG,
  },
  /////////////////////////////////////////TOP-BAR/////////////////////////////////////////
  //그룹관리
  {
    exact: true,
    ...UISET.GROUP,
  },
  //그룹관리 > 서비스
  {
    ...UISET.GROUP_SERVICE,
  },
  //그룹관리 > 멤버
  {
    ...UISET.GROUP_MEMBER,
  },
  //그룹관리 > 보안
  {
    ...UISET.GROUP_SECURITY,
  },
  //그룹관리 > 목록
  {
    ...UISET.GROUP_IP,
  },
  //그룹관리 > 연동설정
  {
    ...UISET.GROUP_TICKET,
  },
  //그룹관리 > 사용설정
  {
    ...UISET.GROUP_SETTINGDETECT,
  },
  //그룹관리 > 시스템
  {
    ...UISET.GROUP_SYSTEM,
  },
  //그룹관리 > 웹방화벽
  {
    ...UISET.GROUP_WAF,
  },
  //그룹관리 > 방화벽
  {
    ...UISET.GROUP_FIREWALL,
  },
  //그룹관리 > HAProxy
  {
    ...UISET.GROUP_HAPROXY,
  },
  //그룹관리 > SQUID
  {
    ...UISET.GROUP_SQUID,
  },
  //그룹관리 > 그룹알림
  {
    ...UISET.GROUP_ALARM,
  },
  //그룹관리 > 사용로그
  {
    ...UISET.GROUP_USERLOG,
  },
  /////////////////////////////////////////////////////////////////////////////////////////
  //Q&A
  {
    exact: true,
    ...UISET.QNA,
  },
  {
    ...UISET.QNA_REGIST,
  },
  {
    ...UISET.QNA_DETAIL,
  },
  {
    ...UISET.QNA_EDIT,
  },
  /////////////////////////////////////////////////////////////////////////////////////////
  //나의정보
  {
    exact: true,
    ...UISET.MYINFO,
  },
  //나의정보 > 사용자정보
  {
    ...UISET.MYINFO_USERINFO,
  },
  //나의정보 > 보안
  {
    ...UISET.MYINFO_SECURITY,
  },
  //나의정보 > 비밀번호 변경
  {
    ...UISET.MYINFO_EDITPWD,
  },
  //나의정보 > 기타정보
  {
    ...UISET.MYINFO_ETCINFO,
  },
  /////////////////////////////////////////////////////////////////////////////////////////
  //개인알림
  {
    ...UISET.ALARM,
  },
  //테마설정
  {
    link: getURI("THEME"),
    component: ThemeContainer,
  },
  /////////////////////////////////////////////////////////////////////////////////////////
  //Install Agents
  {
    component: InstallContainer,
    link: getURI("INSTALL_WINDOWS"),
  },
  {
    component: InstallContainer,
    link: getURI("INSTALL_LINUX"),
  },
  {
    component: InstallContainer,
    link: getURI("INSTALL_UBUNTU"),
  },
  {
    component: InstallContainer,
    link: getURI("INSTALL_UNIX"),
  },
  {
    component: InstallContainer,
    link: getURI("INSTALL_COLLECTOR"),
  },
  {
    component: InstallContainer,
    link: getURI("INSTALL_WEBFIREWALL"),
  },
  {
    component: InstallContainer,
    link: getURI("INSTALL_LOGFORENSIC"),
  },
  ////////////////////
  // InstallAgentGuide 인스톨 에이전트 가이드
  {
    ...UISET.INSTALL_AGENT_GUIDE,
  },
  /////////////////////////////////////////ETC/////////////////////////////////////////
  //티켓
  {
    ...UISET.TICKETLIST,
  },
  //Error
  {
    component: FreeToPremiumContainer,
    link: getURI("ERROR_FREETOPREMIUM"),
  },
];

export default routes;
