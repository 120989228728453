import { action, toJS } from "mobx";
import { subMonths } from "date-fns";

import { dateUtil, languageSet, withLoading, Common_Utils } from "@qubit-utill/src";

import { UISET } from "@src/constants";

import {
  LogListModel,
  StackBarModel,
  PieModel,
  BarModel,
  MultiBarModel,
  LineModel,
  BarLineMixedModel,
} from "@src/pages/Report/models";
import { APIClass } from "@src/pages/Report/stores/extends";

class FilterReportStore extends APIClass {
  @action
  setFilterReportParams = () => {
    this.params = {
      filterCategoryGroupId: this.params.filterCategoryGroupId,
      offset: this.params.offset,
      limit: this.params.limit,
      serverGroupList: toJS(this.params.serverGroupList),
      serverGroupIds: toJS(this.params.serverGroupIds),
      serverIds: toJS(this.params.serverIds),
      blocked: this.params.blocked,
      resourceId: this.params.resourceId,
    };
  };

  //일일
  @action
  getDayTop10List = async (params) => {
    await this.getTagServicePropertyList();
    await this.getTagValueTypes();

    try {
      const tagServicePropertyList = this.tagServicePropertyList;
      const tagValueTypeList = this.tagValueTypeList;
      const getTagServiceDataList = this.openIpTagModal;

      const { list, totalCount } = await this.getDayTop10ListApi(params);

      let logList = list.map((info: object) => {
        const _info = { ...info, tagServicePropertyList, tagValueTypeList, getTagServiceDataList };
        return new LogListModel({
          ..._info,
          isSystem: this.isSystem,
          CodeStore: this.root.CodeStore,
          csvInfoKey: this.csvInfoKey,
        });
      });

      this.setInfo("logList", logList);
      this.setInfo("totalCount", totalCount);
    } catch ({ data }) {
      this.setInfo("logList", []);
      this.setInfo("totalCount", 0);
    }
  };

  @action
  getDayChart = async (params) => {
    try {
      const {
        categoryGroupList,
        dailySumList,
        hourSumList,
        yesterdayHourSumList,
        riskSumList,
        riskLevelCodeSource,
        startDetectDate,
        endDetectDate,
        statusSumList,
        statusCountSumList,
        methodSumList,
        weekHourSumList,
      } = await this.getDayChartApi(params);

      this.setInfo(
        "mainChartData",
        new StackBarModel({
          categoryGroupList,
          dailySumList,
          category: this.getDateRange(startDetectDate, endDetectDate),
          isResource: Boolean(this.params.resourceId),
        })
      );
      this.setInfo(
        "barChartData",
        new BarLineMixedModel({
          hourSumList,
          yesterdayHourSumList,
          category: UISET.DATE_CATEGORY.HOUR,
          weekHourSumList,
        })
      );
      this.setInfo(
        "pieChartData",
        new PieModel({
          riskSumList,
          riskLevelCodeSource,
          statusSumList,
          statusCountSumList,
          CodeStore: this.root.CodeStore,
        })
      );
      this.setInfo(
        "methodChartData",
        methodSumList
          ? new PieModel({
              methodSumList,
              CodeStore: this.root.CodeStore,
            })
          : {}
      );
    } catch (e) {
      console.log(e);
      this.setInfo("mainChartData", {});
      this.setInfo("barChartData", {});
      this.setInfo("pieChartData", {});
      this.setInfo("methodChartData", {});
    }
  };

  @action
  getDaySubChart = async (params) => {
    try {
      const {
        hourSumList,
        yesterdayHourSumList,
        riskSumList,
        riskLevelCodeSource,
        statusSumList,
        statusCountSumList,
        methodSumList,
        weekHourSumList,
      } = await this.getDaySubChartApi(params);

      this.setInfo(
        "barChartData",
        new BarLineMixedModel({
          hourSumList,
          yesterdayHourSumList,
          category: UISET.DATE_CATEGORY.HOUR,
          weekHourSumList,
        })
      );
      this.setInfo(
        "pieChartData",
        new PieModel({
          riskLevelCodeSource,
          riskSumList,
          statusSumList,
          statusCountSumList,
          CodeStore: this.root.CodeStore,
        })
      );
      this.setInfo(
        "methodChartData",
        methodSumList
          ? new PieModel({
              methodSumList,
              CodeStore: this.root.CodeStore,
            })
          : {}
      );
    } catch (error) {
      this.setInfo("barChartData", {});
      this.setInfo("pieChartData", {});
      this.setInfo("methodChartData", {});
    }
  };

  @action
  getDay = async (date?: string) => {
    this.setFilterReportParams();
    this.setStartEndDate(date);
    this.params.detectDate = date ? date : this.dateInfo.detectDate;
    await Promise.all([
      this.getDayTop10List(this.params),
      date ? this.getDaySubChart(this.params) : this.getDayChart(this.params),
    ]);
  };

  //주별
  @action
  getWeekTop10List = async (params) => {
    await this.getTagServicePropertyList();
    await this.getTagValueTypes();

    try {
      const tagServicePropertyList = this.tagServicePropertyList;
      const tagValueTypeList = this.tagValueTypeList;
      const getTagServiceDataList = this.openIpTagModal;

      const { list, totalCount } = await this.getWeekTop10ListApi(params);

      const logList = list.map((info: object) => {
        const _info = { ...info, tagServicePropertyList, tagValueTypeList, getTagServiceDataList };
        return new LogListModel({
          ..._info,
          isSystem: this.isSystem,
          CodeStore: this.root.CodeStore,
          csvInfoKey: this.csvInfoKey,
        });
      });
      this.setInfo("logList", logList);
      this.setInfo("totalCount", totalCount);
    } catch (err) {
      this.setInfo("logList", []);
      this.setInfo("totalCount", 0);
    }
  };

  @action
  getWeekChart = async (params) => {
    try {
      const {
        riskSumList,
        categoryGroupNames,
        categoryDataList,
        weeklySumList,
        riskLevelCodeSource,
        statusSumList,
        statusCountSumList,
        searchWeeklyList,
        weeklyFilterCategorySumList,
        methodSumList,
        detectDate,
      } = await this.getWeekChartApi(params);
      this.setInfo(
        "weekChartData",
        new StackBarModel({
          categoryGroupList: categoryGroupNames,
          weeklyFilterCategorySumList,
          category: searchWeeklyList,
          detectDate,
          isResource: Boolean(this.params.resourceId),
        })
      );
      this.setInfo(
        "mainChartData",
        new MultiBarModel({
          weeklySumList,
          category: UISET.DATE_CATEGORY.WEEK,
          searchWeeklyList,
          detectDate,
        })
      );
      this.setInfo(
        "barChartData",
        new BarModel({
          categoryGroupNames,
          categoryDataList,
        })
      );
      this.setInfo(
        "pieChartData",
        new PieModel({
          riskLevelCodeSource,
          riskSumList,
          statusSumList,
          statusCountSumList,
          CodeStore: this.root.CodeStore,
        })
      );
      this.setInfo(
        "methodChartData",
        methodSumList
          ? new PieModel({
              methodSumList,
              CodeStore: this.root.CodeStore,
            })
          : {}
      );
    } catch (err) {
      this.setInfo("weekChartData", {});
      this.setInfo("mainChartData", {});
      this.setInfo("barChartData", {});
      this.setInfo("pieChartData", {});
      this.setInfo("methodChartData", {});
    }
  };

  @action
  getWeekSubChart = async (params) => {
    try {
      const {
        categoryGroupNames,
        categoryDataList,
        riskSumList,
        riskLevelCodeSource,
        statusSumList,
        statusCountSumList,
        methodSumList,
      } = await this.getWeekSubChartApi(params);
      this.setInfo(
        "barChartData",
        new BarModel({
          categoryGroupNames,
          categoryDataList,
        })
      );
      this.setInfo(
        "pieChartData",
        new PieModel({
          riskLevelCodeSource,
          riskSumList,
          statusSumList,
          statusCountSumList,
          CodeStore: this.root.CodeStore,
        })
      );
      this.setInfo(
        "methodChartData",
        methodSumList
          ? new PieModel({
              methodSumList,
              CodeStore: this.root.CodeStore,
            })
          : {}
      );
    } catch (error) {
      this.setInfo("barChartData", {});
      this.setInfo("pieChartData", {});
      this.setInfo("methodChartData", {});
    }
  };

  @action
  getWeek = async (date?: Date) => {
    this.setStartEndDate(date);
    this.setFilterReportParams();
    const dateInfo = date ? dateUtil.getDateInfo(date) : this.dateInfo;

    this.params.detectDate = dateInfo.detectDate;
    this.params.detectYear = dateInfo.detectWeekYear;
    this.params.detectWeek = dateInfo.detectWeek;

    await Promise.all([
      this.getWeekTop10List(this.params),
      date ? this.getWeekSubChart(this.params) : this.getWeekChart(this.params),
    ]);
  };

  //월별
  @action
  getMonthTop10List = async (params) => {
    await this.getTagServicePropertyList();
    await this.getTagValueTypes();

    try {
      const tagServicePropertyList = this.tagServicePropertyList;
      const tagValueTypeList = this.tagValueTypeList;
      const getTagServiceDataList = this.openIpTagModal;

      const { list, totalCount } = await this.getMonthTop10ListApi(params);
      let logList = list.map((info: object) => {
        const _info = { ...info, tagServicePropertyList, tagValueTypeList, getTagServiceDataList };
        return new LogListModel({
          ..._info,
          isSystem: this.isSystem,
          CodeStore: this.root.CodeStore,
          csvInfoKey: this.csvInfoKey,
        });
      });
      this.setInfo("logList", logList);
      this.setInfo("totalCount", totalCount);
    } catch (err) {
      console.log(err);
      this.setInfo("logList", []);
      this.setInfo("totalCount", 0);
    }
  };

  //월별 차트
  @action
  getMonthChart = async (params) => {
    try {
      const {
        categoryGroupNames,
        categoryDataList,
        categoryDataListBefore,
        monthlySumList,
        monthlySumListBefore,
        riskLevelCodeSource,
        riskSumList,
        statusSumList,
        statusCountSumList,
        methodSumList,
      } = await this.getMonthChartApi(params);

      this.setInfo(
        "mainChartData",
        new LineModel({
          monthlySumList,
          monthlySumListBefore,
          category: UISET.DATE_CATEGORY.MONTH,
          beforeMonth: subMonths(new Date(this.dateInfo.detectDate), 1).getMonth() + 1,
          month: this.dateInfo.detectMonth,
        })
      );
      this.setInfo(
        "barChartData",
        new BarModel({
          categoryGroupNames,
          categoryDataList,
          categoryDataListBefore,
          beforeMonth:
            categoryDataListBefore && languageSet("n월", subMonths(new Date(this.dateInfo.detectDate), 1).getMonth() + 1),
          month: categoryDataList && languageSet("n월", this.dateInfo.detectMonth),
        })
      );
      this.setInfo(
        "pieChartData",
        new PieModel({
          riskLevelCodeSource,
          riskSumList,
          statusSumList,
          statusCountSumList,
          CodeStore: this.root.CodeStore,
        })
      );
      this.setInfo(
        "methodChartData",
        methodSumList
          ? new PieModel({
              methodSumList,
              CodeStore: this.root.CodeStore,
            })
          : {}
      );
    } catch (err) {
      console.log(err);
      this.setInfo("mainChartData", { category: UISET.DATE_CATEGORY.MONTH });
      this.setInfo("barChartData", {});
      this.setInfo("pieChartData", {});
      this.setInfo("methodChartData", {});
    }
  };

  @action
  getMonth = async () => {
    this.setStartEndDate();
    this.setFilterReportParams();

    this.params.detectDate = "";
    this.params.detectYear = this.dateInfo.detectYear;
    this.params.detectMonth = this.dateInfo.detectMonth;

    await Promise.all([this.getMonthTop10List(this.params), this.getMonthChart(this.params)]);
  };

  @action
  getCsvData = async (title?, isUpload?) => {
    if (this.logList.length > 0) {
      try {
        const termsStr = this.termsTab[0].toUpperCase() + this.termsTab.slice(1);
        let { list } = await this[`get${termsStr}Top10ListApi`](isUpload ? {} : { ...toJS(this.params), offset: 0, limit: 3000 });

        list = list.map((info) => new LogListModel({ ...info, csvInfoKey: this.csvInfoKey, CodeStore: this.root.CodeStore }));
        this.downloadCSV(list, title);
        isUpload && this.setInfo("isCsv", false);
      } catch (e) {
        return languageSet("에러_데이터가없습니다");
      }
    } else {
      return languageSet("에러_데이터가없습니다");
    }
  };

  @withLoading("isTableLoading")
  @action
  getTableList = async (params) => {
    try {
      const termsStr = this.termsTab[0].toUpperCase() + this.termsTab.slice(1);
      await this[`get${termsStr}Top10List`](params || this.params);
    } catch (e) {
      console.log(e);
    }
  };

  @action
  changePage = (page) => {
    this.params.offset = Common_Utils.getOffset(page, this.params.limit);
    this.getTableList(this.params);
  };
}

export default FilterReportStore;
