import { languageSet } from "@qubit-utill/src";

import getURI from "@src/constants/uriset";
import { FILTER_LICENSECODE } from "@src/constants/licenseCode";

import {
  RegisterFilterContainer,
  WebFirewallFilterSetContainer,
  SystemFilterSetContainer,
  ApplicationFilterDefaultSetContainer,
  ApplicationFilterResourceSetContainer,
  WebFilterSetContainer,
  NetworkFilterSetContainer,
} from "@src/pages";

export default {
  //필터 > 등록필터 : 웹방화벽
  REGISTERATIONFILTER_WEBFIREWALL: {
    withPageLink: `${getURI("REGISTERATIONFILTER_WEBFIREWALL")}/page/:page`,
    link: getURI("REGISTERATIONFILTER_WEBFIREWALL"),
    licenseCode: FILTER_LICENSECODE,
    component: RegisterFilterContainer,
  },

  //필터 > 등록필터 : 웹방화벽 필터등록
  REGISTERATIONFILTER_WEBFIREWALL_REGIST: {
    link: getURI("REGISTERATIONFILTER_WEBFIREWALL_REGIST"),
    title: languageSet("필터등록"),
    licenseCode: FILTER_LICENSECODE,
    component: WebFirewallFilterSetContainer,
  },

  //필터 > 등록필터 : 웹방화벽 필터수정
  REGISTERATIONFILTER_WEBFIREWALL_EDIT: {
    link: `${getURI("REGISTERATIONFILTER_WEBFIREWALL_EDIT")}/:filterId`,
    title: languageSet("필터수정"),
    licenseCode: FILTER_LICENSECODE,
    isEdit: true,
    component: WebFirewallFilterSetContainer,
  },

  //필터 > 등록필터 : 시스템
  REGISTERATIONFILTER_SYSTEM: {
    withPageLink: `${getURI("REGISTERATIONFILTER_SYSTEM")}/page/:page`,
    link: getURI("REGISTERATIONFILTER_SYSTEM"),
    licenseCode: FILTER_LICENSECODE,
    component: RegisterFilterContainer,
  },

  //필터 > 등록필터 : 시스템 필터등록
  REGISTERATIONFILTER_SYSTEM_REGIST: {
    link: getURI("REGISTERATIONFILTER_SYSTEM_REGIST"),
    title: languageSet("필터등록"),
    licenseCode: FILTER_LICENSECODE,
    component: SystemFilterSetContainer,
  },

  //필터 > 등록필터 : 시스템 필터수정
  REGISTERATIONFILTER_SYSTEM_EDIT: {
    link: `${getURI("REGISTERATIONFILTER_SYSTEM_EDIT")}/:filterId`,
    title: languageSet("필터수정"),
    licenseCode: FILTER_LICENSECODE,
    isEdit: true,
    component: SystemFilterSetContainer,
  },

  //필터 > 등록필터 : 응용프로그램 원본
  REGISTERATIONFILTER_APPLICATION_DEFAULT: {
    withPageLink: `${getURI("REGISTERATIONFILTER_APPLICATION_DEFAULT")}/page/:page`,
    link: getURI("REGISTERATIONFILTER_APPLICATION_DEFAULT"),
    licenseCode: FILTER_LICENSECODE,
    component: RegisterFilterContainer,
  },

  //필터 > 등록필터 : 응용프로그램 원본 : 필터등록
  REGISTERATIONFILTER_APPLICATION_DEFAULT_REGIST: {
    link: getURI("REGISTERATIONFILTER_APPLICATION_DEFAULT_REGIST"),
    title: languageSet("필터등록"),
    licenseCode: FILTER_LICENSECODE,
    component: ApplicationFilterDefaultSetContainer,
  },

  //필터 > 등록필터 : 응용프로그램 원본 : 필터수정
  REGISTERATIONFILTER_APPLICATION_DEFAULT_EDIT: {
    link: `${getURI("REGISTERATIONFILTER_APPLICATION_DEFAULT_EDIT")}/:filterId`,
    title: languageSet("필터수정"),
    licenseCode: FILTER_LICENSECODE,
    isEdit: true,
    component: ApplicationFilterDefaultSetContainer,
  },

  //필터 > 등록필터 : 응용프로그램 사용자
  REGISTERATIONFILTER_APPLICATION_RESOURCE: {
    withPageLink: `${getURI("REGISTERATIONFILTER_APPLICATION_RESOURCE")}/page/:page`,
    link: getURI("REGISTERATIONFILTER_APPLICATION_RESOURCE"),
    licenseCode: FILTER_LICENSECODE,
    component: RegisterFilterContainer,
  },

  //필터 > 등록필터 : 응용프로그램 사용자 : 필터등록
  REGISTERATIONFILTER_APPLICATION_RESOURCE_REGIST: {
    link: getURI("REGISTERATIONFILTER_APPLICATION_RESOURCE_REGIST"),
    title: languageSet("필터등록"),
    licenseCode: FILTER_LICENSECODE,
    component: ApplicationFilterResourceSetContainer,
  },

  //필터 > 등록필터 : 응용프로그램 사용자 : 필터수정
  REGISTERATIONFILTER_APPLICATION_RESOURCE_EDIT: {
    link: `${getURI("REGISTERATIONFILTER_APPLICATION_RESOURCE_EDIT")}/:resourceFilterId`,
    title: languageSet("필터수정"),
    licenseCode: FILTER_LICENSECODE,
    isEdit: true,
    component: ApplicationFilterResourceSetContainer,
  },

  //필터 > 등록필터 : 웹
  REGISTERATIONFILTER_WEB: {
    withPageLink: `${getURI("REGISTERATIONFILTER_WEB")}/page/:page`,
    link: getURI("REGISTERATIONFILTER_WEB"),
    licenseCode: FILTER_LICENSECODE,
    component: RegisterFilterContainer,
  },

  //필터 > 등록필터 : 웹 필터등록
  REGISTERATIONFILTER_WEB_REGIST: {
    link: getURI("REGISTERATIONFILTER_WEB_REGIST"),
    title: languageSet("필터등록"),
    licenseCode: FILTER_LICENSECODE,
    component: WebFilterSetContainer,
  },

  //필터 > 등록필터 : 웹 필터수정
  REGISTERATIONFILTER_WEB_EDIT: {
    link: `${getURI("REGISTERATIONFILTER_WEB_EDIT")}/:filterId`,
    title: languageSet("필터수정"),
    licenseCode: FILTER_LICENSECODE,
    isEdit: true,
    component: WebFilterSetContainer,
  },

  //필터 > 등록필터 : 네트워크
  REGISTERATIONFILTER_NETWORK: {
    withPageLink: `${getURI("REGISTERATIONFILTER_NETWORK")}/page/:page`,
    link: getURI("REGISTERATIONFILTER_NETWORK"),
    licenseCode: FILTER_LICENSECODE,
    component: RegisterFilterContainer,
  },

  //필터 > 등록필터 : 네트워크 필터등록
  REGISTERATIONFILTER_NETWORK_REGIST: {
    link: getURI("REGISTERATIONFILTER_NETWORK_REGIST"),
    title: languageSet("필터등록"),
    licenseCode: FILTER_LICENSECODE,
    component: NetworkFilterSetContainer,
  },

  //필터 > 등록필터 : 네트워크 필터수정
  REGISTERATIONFILTER_NETWORK_EDIT: {
    link: `${getURI("REGISTERATIONFILTER_NETWORK_EDIT")}/:filterId`,
    title: languageSet("필터수정"),
    licenseCode: FILTER_LICENSECODE,
    isEdit: true,
    component: NetworkFilterSetContainer,
  },
};
