import { observable, action } from "mobx";
import { pauthApis } from "@qubit-utill/src/apis";
import { COOKIENAME, getURI, LANGCOOKIENAME } from "@qubit-utill/src/common_constants";
import { withLoading } from "@qubit-utill/src/decorator";
import { Common_Utils } from "@qubit-utill/src/utils";
import UserPasswordRuleModel from "../model/UserPasswordRuleModel";

class CookieStore {
  constructor(root: any) {
    this.root = root;
  }

  root: any;

  @observable
  isLogin = true;

  @observable
  isRenderPasswordChangePage = false;

  @observable
  userPasswordRuleInfo: UserPasswordRuleModel = new UserPasswordRuleModel([]);

  @withLoading("isLoading")
  @action
  getUserPasswordRule = async () => {
    try {
      const { list } = await pauthApis.getUserPasswordRule();
      this.userPasswordRuleInfo = new UserPasswordRuleModel(list);
    } catch {
      this.userPasswordRuleInfo = new UserPasswordRuleModel([]);
    }
  };

  @action
  createLoginPin = async (params: {
    userEmail: string;
    pinPassword: string;
    loginAuthToken: string;
  }) => {
    try {
      await pauthApis.createLoginPin(params);
    } catch (error) {
      return error;
    }
  };

  @action
  createLoginOtpMail = async (params: {
    userEmail: string;
    otpMailToken: string;
    loginAuthToken: string;
  }) => {
    try {
      await pauthApis.createLoginOtpMail(params);
    } catch (error) {
      return error;
    }
  };

  @action
  resendOtpMail = async (params: { userEmail: string; loginAuthToken: string }) => {
    try {
      await pauthApis.resendOtpMail(params);
    } catch (error) {
      return error;
    }
  };

  @action
  updateChangePin = async (params: {
    userEmail: string;
    pinPassword: string;
    loginAuthToken: string;
  }) => {
    try {
      await pauthApis.updateChangePin(params);
    } catch (error) {
      return error;
    }
  };

  @action
  updateChangePassword = async (params: {
    userEmail: string;
    password: string;
    loginAuthToken: string;
  }) => {
    try {
      await pauthApis.updateChangePassword(params);
    } catch (error) {
      return error;
    }
  };

  @action
  checkIsLogin = () => {
    let cookieName = `${COOKIENAME}`;
    this.isLogin = Boolean(Common_Utils.checkCookie(cookieName));
    return this.isLogin;
  };

  @action
  createLoginWeb = async (params: {
    userEmail: string;
    password: string;
    recaptchaToken?: string;
    isOnPremise: string;
  }) => {
    try {
      await pauthApis.createLoginWeb(params);
      await pauthApis.getGlobalDNS(params.userEmail);

      this.checkIsLogin();
    } catch (error) {
      return error;
    }
  };

  @action
  setIsLogin = flag => {
    this.isLogin = flag;
  };

  @action
  setIsRenderPasswordChangePage = (flag: boolean) => {
    this.isRenderPasswordChangePage = flag;
  };

  @action
  logout = () => {
    pauthApis.setLogoutWeb().then(() => {
      this.setIsLogin(false);
      this.deleteLocalStorageItem();
      window.location.replace(getURI("LOGIN"));
    });
  };

  @action
  checkLang = async () => {
    const cookieName = `${LANGCOOKIENAME}`;
    const cookie = Common_Utils.checkCookie(cookieName);
    if (Boolean(cookie)) {
      Common_Utils.lang = cookie[2];
    } else {
      try {
        const { info } = await pauthApis.getLangCookie();
        Common_Utils.lang = info.lang;
      } catch ({ data }) {}
    }
  };

  @action
  setLang = async lang => {
    try {
      await pauthApis.setLangCookie(lang);
      window.location.reload();
    } catch ({ data }) {}
  };

  @action
  deleteLocalStorageItem = () => {
    // autoCompleteList
    localStorage.removeItem("filterNameList");
    localStorage.removeItem("channelList");
    // 리소스모니터링 > 호스트 > 상세
    localStorage.removeItem("RESOURCE_MONITORING_SERVER_INFO");
    localStorage.removeItem("RESOURCE_MONITORING_DATE");
  };
}

export default CookieStore;
