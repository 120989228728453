import { languageSet } from "@qubit-utill/src";
import getURI from "../uriset";
import { InstallAgentGuideContainer } from "@src/pages";

export default {
  INSTALL: {
    link: getURI("INSTALL"),
    title: "Install Agents",
    tabMenus: [
      {
        to: getURI("INSTALL_WINDOWS"),
        name: "Windows",
      },
      {
        to: getURI("INSTALL_LINUX"),
        name: "Linux",
      },
      {
        to: getURI("INSTALL_UNIX"),
        name: "UNIX",
      },
      {
        to: getURI("INSTALL_COLLECTOR"),
        name: "PLURA Log Collector (PLC)",
      },
      // 탭에서 숨김처리 후 향후 삭제판단
      // {
      //   to: getURI("INSTALL_WEBFIREWALL"),
      //   name: "WAF",
      // },
    ],
  },
  INSTALL_WINDOWS: {
    menus: [
      {
        code: "EventLog",
        title: languageSet("Event_Log_IIS포함"),
        isDefault: true,
      },
      {
        code: "Sysmon",
        title: "Sysmon (Sysinternals)",
        isDefault: false,
      },
    ],
  },
  INSTALL_LINUX: {
    menus: [
      {
        code: "Syslog",
        title: "Syslog",
        isDefault: true,
      },
      {
        code: "SyslogAudit",
        title: "Syslog-Audit",
        isDefault: false,
      },
      {
        code: "Apache",
        title: "Apache | Nginx",
        isDefault: false,
      },
      {
        code: "Plurawebd",
        title: "Plurawebd",
        isDefault: false,
      },
      // { code: "HAProxy", title: "HAProxy", isDefault: false }, HAProxy탭제거
      {
        code: "Command",
        title: "Command",
        isDefault: false,
      },
    ],
    subMenus: [
      {
        code: "RPM",
        title: "RPM",
        isDefault: true,
      },
      {
        code: "JAVA",
        title: "JAVA",
        isDefault: false,
      },
    ],
  },
  INSTALL_UBUNTU: {
    menus: [
      {
        code: "Syslog",
        title: "Syslog",
        isDefault: true,
      },
      {
        code: "Apache",
        title: "Apache",
        isDefault: false,
      },
      {
        code: "Nginx",
        title: "Nginx",
        isDefault: false,
      },
      {
        code: "WebServer",
        title: "Web Server",
        isDefault: false,
      },
      // { code: "HAProxy", title: "HAProxy", isDefault: false }, HAProxy탭제거
      {
        code: "Command",
        title: "Command",
        isDefault: false,
      },
    ],
    subMenus: [
      {
        code: "DEB",
        title: "DEB",
        isDefault: true,
      },
      {
        code: "JAVA",
        title: "JAVA",
        isDefault: false,
      },
    ],
  },
  INSTALL_UNIX: {
    menus: [
      {
        code: "AIX",
        title: "AIX",
        isDefault: true,
      },
      {
        code: "HPUX",
        title: "HP-UX",
        isDefault: false,
      },
      {
        code: "Solaris",
        title: "Solaris",
        isDefault: false,
      },
      {
        code: "FreeBSD",
        title: "FreeBSD",
        isDefault: false,
      },
    ],
  },
  INSTALL_COLLECTOR: {
    menus: [
      {
        code: "System",
        title: "System",
        isDefault: true,
      },
      {
        code: "Application",
        title: "Application",
        isDefault: false,
      },
      {
        code: "Web",
        title: "Web",
        isDefault: false,
      },
      {
        code: "Network",
        title: "Network",
        isDefault: false,
      },
    ],
  },
  INSTALL_AGENT_GUIDE: {
    title: languageSet("인스톨에이전트_설치_안내"),
    link: getURI("INSTALL_AGENT_GUIDE"),
    component: InstallAgentGuideContainer,
  },
};
