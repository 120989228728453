import { languageSet } from "@qubit-utill/src";

export const EXTRA_TAB = ["waf", "hostsecurity"];
export const SECURITY_TAB = ["mitre", "package", "webExtendsDataBreach", "ddos"];
export const FILTER_TAB = ["system", "web", "network", "application"];

export const DASHBOARDCUTOM = "custom";
export const EXTORTACCOUNT = "EXTORTACCOUNT";
export const EXTRACTION_IP = "IPEXTRACTION";
export const EXTRACTION_LOGIN = "LOGINEXTRACTION";
export const EXTRACTION_URL = "URLEXTRACTION";
export const DASHBOARDTYPE = {
  waf: languageSet("웹방화벽"),
  hostsecurity: languageSet("호스트보안"),
  mitre: languageSet("마이터"),
  package: languageSet("상관분석"),
  webExtendsDataBreach: languageSet("데이터유출"),
  ddos: languageSet("계정탈취"),
  system: languageSet("시스템"),
  application: languageSet("응용프로그램"),
  applicationTab: languageSet("원본"),
  resourceTab: languageSet("사용자정의"),
  web: languageSet("웹"),
  network: languageSet("네트워크"),
};

//보안탐지 > 상관분석
export const PACKAGEISSUETYPE = "packagefilter";
//보안탐지 > 데이터유출
export const WEBEXTENDSDATABREACHTYPE = "webextendsdatabreach";

//보안탐지 > 계정탈취공격
export const DETECTDOSISSUETYPE = "detectdos";
//보안탐지 > 홈페이지 위변조
export const DETECTFORGERYISSUETYPE = "detectforgery";

//필터탐지 > 시스템
export const SYSISSUETYPE = "syslogdetect";
//필터탐지 > 응용프로그램 > 원본
export const APPISSUETYPE = "applogdetect";
//필터탐지 > 응용프로그램 > 사용자정의
export const RESOURCEISSUETYPE = "resourcelogdetect";
//필터탐지 > 웹
export const WEBISSUETYPE = "weblogdetect";
//필터탐지 > 네트워크
export const NETISSUETYPE = "networklogdetect";
//필터탐지 > 웹방화벽
export const WAFWEBISSUETYPE = "wafweblogdetect";
// 필터탐지 > 호스트보안
export const HOSTSECURITYISSUETYPE = "edrlogdetect";

//ML탐지
export const WEBLOGMLISSUETYPE = "weblogmldetect";

//전체로그 > 시스템
export const SYSLOGISSUETYPE = "syslog";
//전체로그 > 응용프로그램 > 원본
export const APPLOGISSUETYPE = "applog";
//전체로그 > 응용프로그램 > 사용자정의
export const RESOURCELOGISSUETYPE = "resourcelog";
//전체로그 > 웹
export const WEBLOGISSUETYPE = "weblog";
//전체로그 > 네트워크
export const NETWORKLOGISSUETYPE = "networklog";
//전체로그 > 웹방화벽
export const WAFLOGISSUETYPE = "wafweblog";
//전체로그 > 호스트보안
export const HOSTSECURITYLOGISSUETYPE = "edrlog"; //서버쪽이 기존 edr로 정의되어있음

export const METHOD = languageSet("메소드");
export const URLPATH = languageSet("경로");
export const QUERYWORD = "Query";
export const MSGEVENTDATA = "msg || eventdata";

//마이터 관련

export const MITRE_SOFT = "MITRE_SOFT";
export const MITRE_HACK = "MITRE_HACK";
export const MITRE_LIST_CODE = "MITRE_LIST";
export const MITRE_DETECT_FILTER_SEARCHKEY = "mitredetectfilterlist";
//마이터에서 구분용도 키
export const SYSLOGDETECT = "syslogDetect";
export const PACKAGEDETECT = "packageDetect";
//마이터에서 구분용도 키
export const SYSTEM = "system";
export const PACKAGE = "package";
//마이터 티켓
export const MITREISSUETYPE = "mitredetect";
export const ENTERPRISE = "enterprise";
export const DETECTFILTER = "detectfilter";
export const MITREDETECTLISTSEARCHKEY = "securitydetectmitrelist";

export const ISOPEN_MITRE_DETECT_TR = "isOpenMitreDetectTr";

export const CSV_DOWNLOAD_COUNT = 4000;

// pcustomer > userpreferencegroup
export const HOST_ISOLATION_CONFIG = "HOST_ISOLATION_CONFIG";
export const SOLR_SEARCH_CURSOR = "SOLR_SEARCH_CURSOR";
export const FORENSIC_CONFIG = "FORENSIC_CONFIG";

export const CUSTOM_INPUT_OPTION = [
  {
    value: "select",
    name: languageSet("선택입력"),
  },
  {
    value: "input",
    name: languageSet("직접입력"),
  },
];

export const CUSTOM_INPUT_OPTION_REGEX = [
  {
    value: "input",
    name: languageSet("직접입력"),
  },
  {
    value: "select",
    name: languageSet("선택입력"),
  },
  {
    value: "regex",
    name: languageSet("정규식"),
  },
];

export const DEFENSE_INPUT_OPTION_REGEX = [
  {
    value: "input",
    name: languageSet("직접입력"),
  },
  {
    value: "regex",
    name: languageSet("정규식"),
  },
];

export const FILTER_OPERATOR_OPTION_DEFAULT = [
  {
    name: languageSet("포함"),
    value: "el",
  },
  {
    name: languageSet("포함제외"),
    value: "nel",
  },
];

export const FILTER_OPERATOR_OPTION = [
  {
    name: languageSet("포함"),
    value: "el",
  },
  {
    name: languageSet("일치"),
    value: "eq",
  },
  {
    name: languageSet("포함제외"),
    value: "nel",
  },
];

export const FILTER_INTEGER_OPERATOR_OPTION = [
  {
    name: languageSet("이상"),
    value: "ge",
  },
  {
    name: languageSet("이하"),
    value: "le",
  },
];

export const FILTER_RISK_LEVEL_COLOR_OPTION = [
  {
    name: languageSet("높음"),
    value: "4",
    color: "c-red",
  },
  {
    name: languageSet("중간"),
    value: "3",
    color: "c-orange",
  },
  {
    name: languageSet("낮음"),
    value: "2",
    color: "c-yellow",
  },
];

export const PROTOCOL_OPTION = [
  { name: "http", value: "http" },
  { name: "https", value: "https" },
];

export const BIT_OPTION = [
  { name: "32", value: 32 },
  { name: "31", value: 31 },
  { name: "30", value: 30 },
  { name: "29", value: 29 },
  { name: "28", value: 28 },
  { name: "27", value: 27 },
  { name: "26", value: 26 },
  { name: "25", value: 25 },
  { name: "24", value: 24 },
  { name: "23", value: 23 },
  { name: "16", value: 16 },
  { name: languageSet("직접입력"), value: "custom" },
];

export const HTTP_PORT_OPTION = [
  { name: "80", value: 80 },
  { name: "443", value: 443 },
  { name: languageSet("직접입력"), value: "custom" },
];

export const PROXY_PORT_OPTION = [
  { name: "80", value: 80 },
  { name: "443", value: 443 },
  { name: languageSet("포트번호_유지"), value: 0 },
  { name: languageSet("직접입력"), value: "custom" },
];

export const LIMIT_OPTION = [
  { name: 10, value: 10 },
  { name: 20, value: 20 },
  { name: 30, value: 30 },
  { name: 40, value: 40 },
  { name: 50, value: 50 },
  { name: 100, value: 100 },
  { name: 200, value: 200 },
];

export const COOKIEWORD = "Cookie";
export const REFERERWORD = "Referer";
export const UAWORD = "UA";
export const SUCCESSWORD = "Success";
export const FAILWORD = "Fail";
export const DATEWORD = "Date";
export const TICKETWORD = "Ticket";
export const OWASP = "OWASP";

export const SECONDS_OPTION = [
  { name: "120s", value: 120 },
  { name: "90s", value: 90 },
  { name: "60s", value: 60 },
  { name: "30s", value: 30 },
];

export const LOG_UPLOAD_OPTION = [
  { name: languageSet("용량"), value: "logUploadBytesModel" },
  { name: languageSet("수"), value: "logUploadCountModel" },
];

export const OWASP_LINK = [
  { code: "A1", link: "https://owasp.org/Top10/A01_2021-Broken_Access_Control/" },
  { code: "A2", link: "https://owasp.org/Top10/A02_2021-Cryptographic_Failures/" },
  { code: "A3", link: "https://owasp.org/Top10/A03_2021-Injection/" },
  { code: "A4", link: "https://owasp.org/Top10/A04_2021-Insecure_Design/" },
  { code: "A5", link: "https://owasp.org/Top10/A05_2021-Security_Misconfiguration/" },
  { code: "A6", link: "https://owasp.org/Top10/A06_2021-Vulnerable_and_Outdated_Components/" },
  { code: "A7", link: "https://owasp.org/Top10/A07_2021-Identification_and_Authentication_Failures/" },
  { code: "A8", link: "https://owasp.org/Top10/A08_2021-Software_and_Data_Integrity_Failures/" },
  { code: "A9", link: "https://owasp.org/Top10/A09_2021-Security_Logging_and_Monitoring_Failures/" },
  { code: "A10", link: "https://owasp.org/Top10/A10_2021-Server-Side_Request_Forgery_%28SSRF%29/" },
];

export const TIME_OPTION = [
  { name: "00:00", value: "00" },
  { name: "01:00", value: "01" },
  { name: "02:00", value: "02" },
  { name: "03:00", value: "03" },
  { name: "04:00", value: "04" },
  { name: "05:00", value: "05" },
  { name: "06:00", value: "06" },
  { name: "07:00", value: "07" },
  { name: "08:00", value: "08" },
  { name: "09:00", value: "09" },
  { name: "10:00", value: "10" },
  { name: "11:00", value: "11" },
  { name: "12:00", value: "12" },
  { name: "13:00", value: "13" },
  { name: "14:00", value: "14" },
  { name: "15:00", value: "15" },
  { name: "16:00", value: "16" },
  { name: "17:00", value: "17" },
  { name: "18:00", value: "18" },
  { name: "19:00", value: "19" },
  { name: "20:00", value: "20" },
  { name: "21:00", value: "21" },
  { name: "22:00", value: "22" },
  { name: "23:00", value: "23" },
];

export const REPORT_CODE_SWITCH = [
  { code: "waf", mappingKey: "webfirewall", link: "REPORT_WEBFIREWALL" },
  { code: "edr", mappingKey: "hostsecurity", link: "REPORT_HOSTSECURITY" },
  { code: "mitre", mappingKey: "mitre", link: "REPORT_MITRE" },
  { code: "package", mappingKey: "correlation", link: "REPORT_CORRELATION" },
  { code: "webextendsdatabreach", mappingKey: "databreach", link: "REPORT_DATABREACH" },
  { code: "dos", mappingKey: "extortaccount", link: "REPORT_EXTORTACCOUNT" },
  { code: "system", mappingKey: "system", link: "REPORT_SYS" },
  { code: "application", mappingKey: "application", link: "REPORT_APP" },
  { code: "web", mappingKey: "web", link: "REPORT_WEB" },
  { code: "network", mappingKey: "network", link: "REPORT_NET" },
];

/**
 * local/session storage key name
 */
export const STORAGE_KEY_DASHBOARD_HOUR = "dashboradDetectHour"; //local storage 대시보드 시간 설정 key name
export const STORAGE_KEY_FORENSIC_ORIGINAL = "forensicOriginalValue"; //forensic 이전값
export const STORAGE_KEY_FORENSIC_CURRENT = "forensicCurrentValue"; //forensic 현재값
