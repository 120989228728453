import { observable, action } from "mobx";

import { SetterModel } from "@qubit-utill/src";

import {
  ResourceDataTypeAttributeModel,
  ResourceDataTypeModel,
  ResourceEventElementOptionModel,
} from "@src/pages/Filter/RegisterFilter/model/ResourceDataType";

export interface resourceDataTypeSelectorModelType {
  selectedTypes: Array<ResourceDataTypeModel>;
  eventElementOptionList: Object;
  dataValues: Array<ResourceDataTypeAttributeModel>;
  addAttributeItem: () => void;
  deleteAttributeItem: (index) => void;
  setSelectType: (value, dataType) => void;
  dataType: string;
}

export default class ResourceDataTypeSelectorModel extends SetterModel {
  constructor(param, resourceId, resourceFilterElementList?, filterElementReferenceListForSelect?) {
    super(param);

    this.eventElementOptionList = {};
    this.selectedTypes = [];
    this.dataValues = [];

    this.resourceId = resourceId;

    //수정시 elementJson 파싱용도
    if (resourceFilterElementList) {
      resourceFilterElementList.resourceFilterElementSelectorList.forEach((info) => {
        info.resourceMetaId = resourceFilterElementList.resourceMetaId;
      });

      this.parsingElementJson(resourceFilterElementList, param.filterEventElementsList, filterElementReferenceListForSelect);
    }

    //UI용 element 네임으로 변경한다.
    if (param && param.filterEventElementsList) {
      this.setEventElementOptionList(param.filterEventElementsList);

      if (resourceFilterElementList) {
        const _dataInfo = param.filterEventElementsList.find(
          (info) => info.resourceMetaId === resourceFilterElementList.resourceMetaId
        );

        if (_dataInfo) {
          this.dataType = _dataInfo.dataType;
        }
      }
    }

    //등록 혹은 dataTypeSelector 추가 시 사용
    if (param && param.eventRootElement && !resourceFilterElementList) {
      this.setDefaultEventRoot(param.eventRootElement);
    }
  }
  @observable
  eventElementOptionList: Object;

  @observable
  selectedTypes: Array<ResourceDataTypeModel>;

  @observable
  dataValues: Array<ResourceDataTypeAttributeModel>;

  @action
  parsingElementJson = (resourceFilterElementList, filterEventElementsList, filterElementReferenceListForSelect) => {
    let parrentDepth = 0;
    const parseForDataTypeModel = (dataType, index?) => {
      //데이터 유형에 맞는 이벤트 타입 요소
      let eventType = {
        parrentFilterEventElementId: 0,
        filterEventElementName: "",
      };

      if (filterEventElementsList) {
        eventType = filterEventElementsList.find((info) => info.resourceMetaId === resourceFilterElementList.resourceMetaId);
      }

      if (dataType.resourceFilterElementSelectorValue) {
        this.dataValues.push(
          new ResourceDataTypeAttributeModel({
            resourceFilterElementSelectorId: dataType.resourceFilterElementSelectorId,
            resourceFilterElementSelectorType: dataType.resourceFilterElementSelectorType,
            resourceFilterElementSelectorOperator: dataType.resourceFilterElementSelectorOperator,
            resourceFilterElementSelectorValue: dataType.resourceFilterElementSelectorValue,
            resourceFilterElementSelectorOrderNo: dataType.resourceFilterElementSelectorOrderNo,
            resourceFilterElementId: resourceFilterElementList.resourceFilterElementId,
          })
        );
      }

      if (!(index >= 1)) {
        this.selectedTypes.push(
          new ResourceDataTypeModel(
            {
              resourceFilterElementId: dataType.resourceFilterElementId,
              resourceMetaId: dataType.resourceMetaId,
              resourceFilterElementOrderNo: dataType.resourceFilterElementOrderNo,
              resourceFilterId: dataType.resourceFilterId,
              resourceFilterElementSelectorList: dataType.resourceFilterElementSelectorList,
              filterElementName: eventType && eventType.filterEventElementName,
              resourceId: this.resourceId,
            }
            // "edit"
          )
        );
      }

      if (dataType.resourceFilterElementSelectorList) {
        parrentDepth = ++parrentDepth;
        dataType.resourceFilterElementSelectorList.forEach((_dataType, index) => {
          parseForDataTypeModel(_dataType, index);
        });
      }
    };

    parseForDataTypeModel(resourceFilterElementList);
  };

  @action
  setEventElementOptionList = async (filterEventElementsList) => {
    await filterEventElementsList.forEach((element) => {
      const elementKey = `${element.parrentDepth}/${element.parrentFilterEventElementId}`;
      this.eventElementOptionList[elementKey] = this.eventElementOptionList[elementKey] || [];
      this.eventElementOptionList[elementKey].push(new ResourceEventElementOptionModel(element));
    });
  };

  @action
  setDefaultEventRoot = (eventRootElement) => {
    const { filterEventElementName, filterEventElementId, parrentFilterEventElementId } = eventRootElement;
    this.selectedTypes = [
      new ResourceDataTypeModel({
        isSelected: true,
        parrentDepth: 0,
        filterElementName: filterEventElementName,
        filterEventElementId,
        parrentFilterEventElementId: 0,
        resourceId: this.resourceId,
      }),
      new ResourceDataTypeModel({
        isSelected: false,
        parrentDepth: 1,
        parrentFilterEventElementId: parrentFilterEventElementId,
        resourceId: this.resourceId,
      }),
    ];
  };

  @action
  setSelectType(value) {
    const { parrentDepth, filterEventElementId, dataType } = value;
    value.isSelected = true;
    this.selectedTypes[parrentDepth] = new ResourceDataTypeModel(value);
    //todo 확인
    this.selectedTypes = this.selectedTypes.filter((_, index) => index <= parrentDepth);

    //선택한 요소의 입력가능 데이터 타입
    this.dataType = dataType;

    this.dataValues = [];

    const childArr = this.eventElementOptionList[`${parrentDepth + 1}/${filterEventElementId}`];

    //하위 요소가 있으면 셀렉트 에 추가
    !childArr && this.dataValues.length === 0 && this.addAttributeItem();
  }

  @action
  setChildSelectType(value) {
    this.selectedTypes.push(
      new ResourceDataTypeModel({
        isSelected: false,
        parrentDepth: value.parrentDepth + 1,
        parrentFilterEventElementId: value.filterEventElementId,
      })
    );
  }

  @action
  addAttributeItem = () => {
    this.dataValues.push(new ResourceDataTypeAttributeModel({ dataType: this.dataType }));
  };

  @action
  deleteAttributeItem = (attrItemIndex) => {
    let newDataValues = [...this.dataValues];
    newDataValues.splice(attrItemIndex, 1);
    this.dataValues = newDataValues;
  };

  @observable
  resourceId: string = "";

  @observable
  dataType: string = "String";
}
