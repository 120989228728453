const { APIURL, PURPLECOWURL, REPOURL, ADMINURL, SIEMURL, COOKIELANG } = window.GlobalPathMap || {
  APIURL: "",
  PURPLECOWURL: "",
  REPOURL: "",
  ADMINURL: "",
  SIEMURL: "",
};

export default {
  PURPLECOWURL,
  CDNURL: PURPLECOWURL + "/web/assets/images",
  APIURL,
  REPOURL,
  BLOGURL: "https://blog.plura.io",
  QUBITCORPURL: "https://w.qubitsec.com/" + COOKIELANG,
  LANG: "KO",
  SYSMONDOWNURL: "https://docs.microsoft.com/en-us/sysinternals/downloads/sysmon",
  ADMINURL,
  SIEMURL,
  MITREURL: "https://attack.mitre.org/techniques",
};
