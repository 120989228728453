import React from "react";
import PropTypes from "prop-types";

import { formatConstants } from "../../../common_constants";
import DayPicker from "react-day-picker/DayPicker";
import "react-day-picker/lib/style.css";
import languageSet from "../../../lang/languageSet";
import isSameDay from "date-fns/isSameDay";

const WEEKDAYS_SHORT = [
  languageSet("일"),
  languageSet("월"),
  languageSet("화"),
  languageSet("수"),
  languageSet("목"),
  languageSet("금"),
  languageSet("토")
];
const MONTHS = (() => {
  let arr = [];
  for (var i = 1; i <= 12; i++) {
    arr.push(languageSet("n월", i));
  }
})();

const WEEKDAYS_LONG = [
  languageSet("일요일"),
  languageSet("월요일"),
  languageSet("화요일"),
  languageSet("수요일"),
  languageSet("목요일"),
  languageSet("금요일"),
  languageSet("토요일")
];

const MultiDateSelect = ({ onChange, value, maxDate, minDate, propsDays, disabled }) => {
  const onDayClick = (day, flag) => {
    // disabled && onChange(day)

    let selectedDays = [...propsDays];
    if (!flag.disabled && !disabled) {
      if (flag.selected) {
        const selectedIndex = selectedDays.findIndex(selectedDay => {
          return isSameDay(selectedDay, day);
        });
        selectedDays.splice(selectedIndex, 1);
      } else {
        selectedDays.push(day);
      }

      onChange(selectedDays);
    }
  };

  return (
    <DayPicker
      selectedDays={propsDays ? propsDays.map(d => new Date(d)) : null}
      onDayClick={onDayClick}
      disabledDays={{
        before: minDate,
        after: maxDate
      }}
      months={MONTHS}
      weekdaysLong={WEEKDAYS_LONG}
      weekdaysShort={WEEKDAYS_SHORT}
    />
  );
};

MultiDateSelect.propTypes = {
  size: PropTypes.string,
  dateFormat: PropTypes.string
};

MultiDateSelect.defaultProps = {
  dateFormat: formatConstants.DATEFORMAT,
  value: "2019-10-12",
  onChange: () => {}
};

export default MultiDateSelect;
