import { axiosModules } from "@qubit-utill/src";

export default {
  // pserver > pserver > 서버관리 > 서버 및 리소스 목록 조회
  getHostResourceMonitoringList: (params) => {
    return axiosModules.get("/pserver/serverresources", params);
  },

  // pserver > pserver > 서버관리 > 리소스모니터링 커렌트 데이터 조회
  getHostResourceMonitoringSummaryList: () => {
    return axiosModules.get("/pserver/serverresources/summary");
  },
};
