import React from "react";

// mobx 상태 가져오는 hook 공통으로 사용함
function useGetData(getData: Function, clearFunc?: () => void) {
  React.useEffect(() => {
    getData();
    return clearFunc;
  }, []);
}

export default useGetData;
