import React from "react";
import { ModalFooter, ModalBody, Modal, Button } from "reactstrap";
import { observer } from "mobx-react";
import Draggable from "react-draggable";

import { Buttons } from "@qubit-utill/src/components/atoms";
import { path } from "@qubit-utill/src/common_constants";
import { languageSet } from "@qubit-utill/src";
import ModalType from "@qubit-utill/src/type/ModalType";

import StringFormat from "../../atoms/Format/StringFormat";

const CustomModal: React.FC<ModalType> = ({
  title,
  clickOk,
  largeModal,
  xlargeModal,
  closeModal,
  isUseCancelBtn,
  isUseOkBtn,
  message,
  isLoading,
  children,
  closeBlock,
  isUseCustomBtn,
  customBtnTitle,
  customCloseBlock,
  clickCustom,
  disabledOkBtn,
  titleBg,
  color,
  customBtns: CustomBtns,
  iconColor,
  customIcon,
  customAttribute,
  backdrop,
  scrollable = false,
  clickCancel,
  customOkText,
  style,
  modalBodyClassName,
}) => {
  const bsSize = largeModal
    ? { className: "modal-lg" }
    : xlargeModal
    ? { className: "modal-xl" }
    : true;
  const okCallBack = () => {
    clickOk && clickOk();
    !closeBlock && closeModal();
  };
  const cancelCallBack = () => {
    clickCancel && clickCancel();
    closeModal();
  };
  const customCallBack = () => {
    clickCustom && clickCustom();
    !customCloseBlock && closeModal();
  };
  const customClassAttribute = customAttribute ? customAttribute : "";
  let isScrollable = scrollable
    ? {
        maxHeight: "calc(100vh - 210px)",
        overflowY: "auto",
      }
    : null;

  return (
    <Draggable handle=".modal-header">
      <Modal
        {...bsSize}
        isOpen={true}
        fade={false}
        toggle={cancelCallBack}
        contentClassName={customClassAttribute}
        backdrop={backdrop}
      >
        <div className={`modal-header ${titleBg ? titleBg : "bg-dark"}`} style={style && style}>
          <Button className="close" onClick={cancelCallBack}>
            <i
              className={
                customIcon ? customIcon : `icons-office-52 ${iconColor ? iconColor : "c-white"}`
              }
            />
          </Button>
          <h4
            className={`modal-title ${title ? "w-600" : "h-20"}`}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </div>
        <ModalBody className={`p-b-0 f-13 ${modalBodyClassName}`} style={isScrollable}>
          {message && (
            <div className="p-l-20 p-r-20">
              <p className="f-16 w-600">{StringFormat(message)}</p>
            </div>
          )}
          {isLoading && (
            <div className="t-center">
              <img src={`${path.CDNURL}/preloader/2.gif`} height="50px" alt="load_img" />
            </div>
          )}
          {children}
        </ModalBody>
        <ModalFooter>
          {CustomBtns}
          {isUseOkBtn && (
            <Buttons.Embossed
              color={!disabledOkBtn && "dark"}
              text={customOkText || languageSet("확인")}
              onClick={okCallBack}
              disabled={disabledOkBtn}
            />
          )}
          {isUseCancelBtn && (
            <Buttons.Embossed color="default" text={languageSet("취소")} onClick={cancelCallBack} />
          )}
          {isUseCustomBtn && (
            <Buttons.Embossed color={color} text={customBtnTitle} onClick={customCallBack} />
          )}
        </ModalFooter>
      </Modal>
    </Draggable>
  );
};

export default observer(CustomModal);
