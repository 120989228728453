import { observable, computed } from "mobx";

import { SetterModel, Common_Utils } from "@qubit-utill/src";

class SystemLogModel extends SetterModel {
  constructor(params) {
    super(params);
    if (params) {
      this.registerDate = params.logCreateDate;
      this.serverOs = params.typeos;
      this.defenceIp = params.logClientIp;
      if (params.typeos === "windows") {
        this.logOriginType = "xml";
      } else {
        this.logOriginType = "json";
      }
      this.serverGroup = params.serverGroup;
      this.serverIp = params.serverIp;
    }
  }

  @computed
  get defenceNation() {
    return this.countryIsoCode && this.countryIsoCode.toLowerCase();
  }

  @computed
  get getServerIp() {
    return Common_Utils.isDataWithDashBar(this.serverIp);
  }

  @computed
  get getServerGroup() {
    return Common_Utils.isDataWithDashBar(this.serverGroup);
  }

  @observable
  countryIsoCode = "";
  @observable
  documentId = "";
  @observable
  defenceIp = ""; //방어IP
  @observable
  serverOs = "";
  @observable
  serverIp = "";
  @observable
  registerDate = "";
  @observable
  isPrivateIp = false; //공격IP가 내부인지 아닌지
  @observable
  logOrigin = "";
  @observable
  logOriginType = "";
  @observable
  serverGroup: string = "";
  @observable
  serverId: string = "";
}

export default SystemLogModel;
