import { observable, action, computed } from "mobx";

import { Common_Utils, withLoading } from "@qubit-utill/src";
import { FilterChannelModel } from "qubit-utill/src/model";

import { serverApis } from "@src/apis/pServer";
import { webExceptFilterApis, logMaskingFilterApis, userFilterConfigApis, exceptEntryApis, channelApis } from "@src/apis/pFilter";
import { resourceConfigUserApis } from "@src/apis/pMetrics";
import { pCustomerApis } from "@src/apis";

import { WEBEXTENDSDATABREACHTYPE, HOST_ISOLATION_CONFIG, FORENSIC_CONFIG } from "@src/constants/code";
import {
  UploadExceptModel,
  UserFilterConfigModel,
  WebDetectExceptModel,
  ResourceConfigModel,
  WebExtortAccountModel,
  WebExtortAccountEntryModel,
  ServicePreferenceItemModel,
} from "@src/pages/Group/SettingDetect/models";

interface UploadType {
  except?: number;
  select?: number;
  list?: UploadExceptModel[];
}

interface updateWebExtortAccountParamType {
  dosExceptCustomGroupId?: number;
  dosExceptCustomEntryModelList: Array<{
    dosExceptCustomEntryType: string;
    dosExceptCustomEntryValue: string;
    isRegex: string;
  }> | null;
}

class SettingDetectStore {
  constructor(root) {
    this.root = root;
  }
  root;

  @observable
  userFilterConfigInfo = new UserFilterConfigModel(null);

  @observable
  isLoading = false;

  @observable
  userInfoList = [];

  @observable
  webDetectList = []; //origin

  @observable
  customWebDetectList: WebDetectExceptModel[] = []; //수정시 사용

  @observable
  webExtortAccountList: Array<WebExtortAccountModel> = [];

  @observable
  customWebExtortAccountList: Array<WebExtortAccountModel> = [];

  @observable
  webUpload: UploadType = { except: 0, select: 0, list: [] };

  @observable
  systemUpload: UploadType = { except: 0, select: 0, list: [] };

  @observable
  appUpload: UploadType = { except: 0, select: 0, list: [] };

  @observable
  resourceConfigUserList: ResourceConfigModel[] = [];

  @observable
  networkUpload: UploadType = { except: 0, select: 0, list: [] };

  @observable
  channelList = new Map();

  @observable
  channelObject: { [key: number]: string } = {};

  @observable
  isWaf = false;

  @observable
  isUseResourceConfig = "0";

  servicePreferencesTypeCodes = [HOST_ISOLATION_CONFIG, FORENSIC_CONFIG];

  @observable
  servicePreferencesItemlist: any | null = null;

  @observable
  isUseHostShutdown: string = "0";

  @observable
  isUseHostIsolate: string = "0";

  @observable
  isUseForensicArtifact: string = "0";

  @observable
  isUseForensicDump: string = "0";

  @computed
  get isShutdownFlag() {
    return Common_Utils.isCheckBool(this.isUseHostShutdown);
  }

  @computed
  get isIsolationFlag() {
    return Common_Utils.isCheckBool(this.isUseHostIsolate);
  }

  @computed
  get isUseForensicArtifactFlag() {
    return Common_Utils.isCheckBool(this.isUseForensicArtifact);
  }

  @computed
  get isUseForensicDumpFlag() {
    return Common_Utils.isCheckBool(this.isUseForensicDump);
  }

  @action
  setData = (key) => (value) => {
    this[key] = value;
  };

  @withLoading("isLoading")
  async getData() {
    await Promise.all([
      this.getUserFilterConfig(), // 데이터유출
      this.getUserInfoList(), // 웹 개인정보 숨김
      this.getWebDetectList(), // 웹 탐지 예외
      this.getWebExtortAccountList(), // 계정탈취 예외
      this.getWebExceptEntryList(), // 웹 업로드

      this.getUserPreferenceConfigList(), // 포렌식, 제어
      this.getSystemExceptEntryList(), // 호스트보안 업로드

      this.getAppExceptEntryList(), // 응용프로그램 업로드
      this.getApplicationResourceList(), // 응용프로그램 사용자 정의

      this.getNetworkExceptEntryList(), // 네트워크 업로드
    ]);
  }

  // 데이터유출
  @action
  getUserFilterConfig = async () => {
    try {
      const data = await userFilterConfigApis.getUserFilterConfig(WEBEXTENDSDATABREACHTYPE);
      this.userFilterConfigInfo = new UserFilterConfigModel(data);
    } catch ({ data }) {
      this.userFilterConfigInfo = new UserFilterConfigModel(null);
    }
  };

  // 웹 개인정보 숨김
  @action
  getUserInfoList = async () => {
    try {
      const { list } = await logMaskingFilterApis.getLogMaskingFilterList();
      this.userInfoList = list.map((data) => ({
        id: data.logMaskingFilterId,
        text: data.logMaskingFilterWord,
      }));
    } catch (e) {
      this.userInfoList = [];
    }
  };

  // 웹 탐지 예외
  @action
  getWebDetectList = async () => {
    try {
      this.getWafServerCount();
      const { list } = await webExceptFilterApis.getWebExceptCustomEntryInfoList();
      this.webDetectList = list.map((info) => new WebDetectExceptModel(info));
      this.customWebDetectList = list.map((info) => new WebDetectExceptModel(info));
    } catch (error) {
      this.webDetectList = [];
      this.customWebDetectList = [];
    }
  };

  @action
  getWafServerCount = async () => {
    try {
      const { totalCount } = await serverApis.getServerCount({
        isWaf: "1",
      });
      this.isWaf = totalCount > 0;
    } catch (e) {
      this.isWaf = false;
    }
  };

  // 계정탈취 예외
  @action
  getWebExtortAccountList = async () => {
    try {
      const { list } = await webExceptFilterApis.getWebExtortAccountExceptList();
      this.webExtortAccountList = list.map((info) => new WebExtortAccountModel(info));
      this.customWebExtortAccountList = list.map((info) => new WebExtortAccountModel(info));
    } catch ({ data }) {
      this.webExtortAccountList = [];
      this.customWebExtortAccountList = [];
    }
  };

  // 웹 업로드 리스트
  @action
  getWebExceptEntryList = async () => {
    try {
      const { list } = await exceptEntryApis.getWebExceptEntryList();
      this.webUpload = { except: 0, select: 0, list: [] };
      this.webUpload.list = list.map((info) => {
        this.webUpload[info.exceptType]++;
        return new UploadExceptModel("web", info);
      });
    } catch (error) {
      this.webUpload = { except: 0, select: 0, list: [] };
    }
  };

  // 호스트보안 > 포렌식, 제어
  @action
  getUserPreferenceConfigList = async () => {
    try {
      const { list } = await pCustomerApis.getUserPreferencesList({
        servicePreferencesTypeCodes: this.servicePreferencesTypeCodes,
      });

      for (const key in list) {
        const itemList = list[key];
        itemList.forEach(({ servicePreferencesId, servicePreferencesItemValue }) => {
          this[servicePreferencesId] = servicePreferencesItemValue;
        });
      }
    } catch (error) {
      this.isUseHostShutdown = "0";
      this.isUseHostIsolate = "0";
      this.isUseForensicArtifact = "0";
      this.isUseForensicDump = "0";
    }
  };

  // 호스트보안 업로드 리스트
  @action
  getSystemExceptEntryList = async () => {
    try {
      const { list } = await exceptEntryApis.getSystemExceptEntryList();
      this.systemUpload = { except: 0, select: 0, list: [] };
      this.systemUpload.list = list.map((info) => {
        this.systemUpload[info.exceptType]++;
        return new UploadExceptModel("sys", info);
      });
    } catch (error) {
      this.systemUpload = { except: 0, select: 0, list: [] };
    }
  };

  // 응용프로그램 원본 리스트
  @action
  getAppExceptEntryList = async () => {
    try {
      const { list } = await exceptEntryApis.getAppExceptEntryList();
      this.appUpload = { except: 0, select: 0, list: [] };
      this.appUpload.list = list.map((info) => {
        this.appUpload[info.exceptType]++;
        return new UploadExceptModel("app", info);
      });
    } catch (error) {
      this.appUpload = { except: 0, select: 0, list: [] };
    }
  };

  // 응용프로그램 사용자 정의 리스트
  @action
  getApplicationResourceList = async () => {
    try {
      const [{ list }, { list: useList, isUse, isFirst }] = await Promise.all([
        resourceConfigUserApis.getApplicationResourceList(),
        resourceConfigUserApis.getResourceConfigUserGroupList(),
      ]);

      this.resourceConfigUserList = await list.map((info) => new ResourceConfigModel(info, isFirst, useList));
      this.isUseResourceConfig = isUse;
    } catch ({ data }) {
      this.resourceConfigUserList = [];
    }
  };

  @action
  updateResourceConfigUserGroup = async (data, isUse) => {
    try {
      await resourceConfigUserApis.updateResourceConfigUserGroup(isUse, data);
    } catch ({ data }) {
      return data;
    }
  };

  // 네트워크 업로드 리스트
  @action
  getNetworkExceptEntryList = async () => {
    try {
      const { list } = await exceptEntryApis.getNetworkExceptEntryList();
      this.networkUpload = { except: 0, select: 0, list: [] };
      this.networkUpload.list = list.map((info) => {
        this.networkUpload[info.exceptType]++;
        return new UploadExceptModel("net", info);
      });
    } catch (error) {
      this.networkUpload = { except: 0, select: 0, list: [] };
    }
  };

  @action
  addWebDetectList = () => {
    this.customWebDetectList.unshift(new WebDetectExceptModel());
  };

  @action
  addWebExtortAccountList = () => {
    this.customWebExtortAccountList.unshift(new WebExtortAccountModel());
  };

  @action
  deleteWebDetectList = (index) => {
    this.customWebDetectList.splice(index, 1);
  };

  @action
  deleteWebExtortAccountList = (index) => {
    this.customWebExtortAccountList.splice(index, 1);
  };

  @action
  setOriginWebDetectList = () => {
    this.customWebDetectList = this.webDetectList.map((info) => new WebDetectExceptModel(info));
  };

  @action
  setOriginWebExtortAccountList = () => {
    this.customWebExtortAccountList = this.webExtortAccountList.map((info) => new WebExtortAccountModel(info));
  };

  @action
  setUserInfo = async (list) => {
    let data = list.map((data) => ({
      logMaskingFilterId: data.id,
      logMaskingFilterWord: data.text,
    }));

    try {
      await logMaskingFilterApis.createUpdateLogMaskingFilter({
        list: data,
      });
      this.getUserInfoList();
    } catch ({ data }) {
      return data;
    }
  };

  @action
  setWebDetect = async () => {
    try {
      await webExceptFilterApis.createWebExceptCustomEntry({
        list: this.customWebDetectList,
      });
    } catch ({ data }) {
      return data;
    }
  };

  @action
  setWebExtortAccount = async () => {
    try {
      const customListIds: Array<number> = [];
      this.customWebExtortAccountList.forEach(({ dosExceptCustomGroupId }) => customListIds.push(dosExceptCustomGroupId));

      const updateList: Array<updateWebExtortAccountParamType> = [];

      this.customWebExtortAccountList.forEach((customInfo: WebExtortAccountModel) => {
        const _dosExceptCustomEntryModelList = customInfo.dosExceptCustomEntryModelList.map(
          (entryInfo: WebExtortAccountEntryModel) => ({
            dosExceptCustomEntryType: entryInfo.dosExceptCustomEntryType,
            dosExceptCustomEntryValue: entryInfo.dosExceptCustomEntryValue,
            isRegex: entryInfo.isRegex === "" ? "0" : entryInfo.isRegex,
            dosExceptSelectOperator: entryInfo.isRegexFlag ? "" : entryInfo.dosExceptSelectOperator,
          })
        );

        if (customInfo.dosExceptCustomGroupId === 0) {
          // 추가된 경우
          return updateList.push({
            dosExceptCustomEntryModelList: _dosExceptCustomEntryModelList,
          });
        } else {
          let isDiffArr: Array<boolean> = [];
          customInfo.dosExceptCustomEntryModelList.forEach((customEntryInfo: WebExtortAccountEntryModel, customEntryIndex) => {
            const customType = customEntryInfo.dosExceptCustomEntryType;
            const customValue = customEntryInfo.dosExceptCustomEntryValue;
            const customIsRegex = customEntryInfo.isRegex;
            const customOperator = customEntryInfo.dosExceptSelectOperator;

            const originInfo = this.webExtortAccountList.find(
              ({ dosExceptCustomGroupId }) => dosExceptCustomGroupId === customInfo.dosExceptCustomGroupId
            );

            const sameLength =
              (originInfo && originInfo.dosExceptCustomEntryModelList.length) === customInfo.dosExceptCustomEntryModelList.length;
            const originEntryInfo = originInfo && originInfo.dosExceptCustomEntryModelList[customEntryIndex];
            const originType = originEntryInfo && originEntryInfo.dosExceptCustomEntryType;
            const originValue = originEntryInfo && originEntryInfo.dosExceptCustomEntryValue;
            const originIsRegex = originEntryInfo && originEntryInfo.isRegex;
            const originOperator = originEntryInfo && originEntryInfo.dosExceptSelectOperator;

            let isDiff = false;

            isDiff =
              !sameLength ||
              customType !== originType ||
              customValue !== originValue ||
              customIsRegex !== originIsRegex ||
              customOperator !== originOperator;

            isDiffArr.push(isDiff);
          });

          if (isDiffArr.some((isDiff) => isDiff === true)) {
            // 수정한 경우
            return updateList.push({
              dosExceptCustomGroupId: customInfo.dosExceptCustomGroupId,
              dosExceptCustomEntryModelList: _dosExceptCustomEntryModelList,
            });
          }
        }
      });

      this.webExtortAccountList.forEach((originInfo: WebExtortAccountModel) => {
        if (!customListIds.includes(originInfo.dosExceptCustomGroupId)) {
          // 삭제된 경우
          return updateList.push({
            dosExceptCustomGroupId: originInfo.dosExceptCustomGroupId,
            dosExceptCustomEntryModelList: null,
          });
        }
      });

      await webExceptFilterApis.createWebExtortAccountExcept(updateList);
    } catch ({ data }) {
      return data;
    }
  };

  @action
  updateUserFilterConfig = async (isUseGlobalFilter) => {
    const data = {
      isUseGlobalFilter,
      isUseCustomFilter: "1",
      serviceFilterType: WEBEXTENDSDATABREACHTYPE,
    };
    try {
      await userFilterConfigApis.updateUserFilterConfig(data);
    } catch ({ data }) {
      return data;
    }
  };

  // 웹 업로드 등록
  @action
  setWebUploadList = async (data) => {
    try {
      await exceptEntryApis.setWebExceptEntryList(data);
    } catch ({ data }) {
      throw data;
    }
  };

  // 호스트보안 업로드 등록
  @action
  setSystemUploadList = async (data) => {
    try {
      await exceptEntryApis.setSystemExceptEntryList(data);
    } catch ({ data }) {
      throw data;
    }
  };

  // 응용프로그램 원본 업로드 등록
  @action
  setAppUploadList = async (data) => {
    try {
      await exceptEntryApis.setAppExceptEntryList(data);
    } catch ({ data }) {
      throw data;
    }
  };

  // 네트워크 업로드 등록
  @action
  setNetworkUploadList = async (data) => {
    try {
      await exceptEntryApis.setNetworkExceptEntryList(data);
    } catch ({ data }) {
      throw data;
    }
  };

  @action
  getChannelByTypeos = async (typeos) => {
    try {
      const { list } = await channelApis.getChannelListByTypeos({ typeos });
      this.channelList.set(
        typeos,
        list.map((info) => {
          this.channelObject[info.filterChannelId] = info.filterChannelName;
          return new FilterChannelModel(info);
        })
      );
    } catch (error) {}
  };

  // 포렌식, 제어 옵션 리스트
  @action
  getUserHostPreferenceList = async () => {
    try {
      const { list } = await pCustomerApis.getServicePreferenceList({
        servicePreferencesTypeCodes: this.servicePreferencesTypeCodes,
      });

      for (const key in list) {
        list[key] = list[key].map((configItem) => new ServicePreferenceItemModel(configItem));
      }

      this.servicePreferencesItemlist = list;
    } catch (error) {
      console.log("error : ", error);
      this.servicePreferencesItemlist = null;
    }
  };

  // 포렌식, 제어 수정
  @action
  updateUserPreferenceConfig = async (isUse, key) => {
    try {
      if (!this.servicePreferencesItemlist || !this.servicePreferencesItemlist[key]) {
        await this.getUserHostPreferenceList();
      }

      const reverseItem = this.servicePreferencesItemlist[key].find(
        ({ servicePreferencesItemValue }) => isUse === servicePreferencesItemValue
      );

      await pCustomerApis.updateUserPreference([
        {
          servicePreferencesId: reverseItem?.servicePreferencesId,
          servicePreferencesItemId: reverseItem?.servicePreferencesItemId,
        },
      ]);
    } catch (error) {
      return error;
    }
  };
}

export default SettingDetectStore;
