import React from "react";
import Chart from "react-apexcharts";
import { observer } from "mobx-react";
import { toJS } from "mobx";

import { Common_Utils } from "qubit-utill/src";

import UISET from "@src/constants/uiset";
import NullLogData from "@src/common/components/organisms/Graphs/NullLogData";

//추가 그래프 옵션
interface GraphOptionPropsTypes {
  detailData?: number[];
  detailCategory?: string[];
  legend?: { horizontalAlign?: string };
}
interface PropsTypes {
  id: string;
  title?: string;
  data: number[];
  category: string[];
  colors?: string[];
  valueFormater?: Function;
  isDetail?: boolean;
  height?: number;
  onClick?: Function;
  graphOption?: GraphOptionPropsTypes;
}

const Pie: React.FC<PropsTypes> = ({
  id,
  title = "",
  data,
  category,
  colors = [],
  valueFormater,
  isDetail = false,
  height = 250,
  onClick,
  graphOption,
}) => {
  const _valueFormater = (value) => (valueFormater ? valueFormater(value) : Common_Utils.numberWithComma(value)?.toString());

  const seriesData = isDetail ? graphOption?.detailData : data;
  const categoryData = isDetail ? graphOption?.detailCategory : category;

  const series = seriesData && seriesData.length > 0 ? toJS(seriesData) : [];

  const options = {
    title: { text: title, align: "center" as const },
    labels: categoryData && categoryData.length > 0 ? toJS(categoryData) : [],

    chart: {
      id: id,
      toolbar: {
        show: false,
      },
      events: {
        dataPointSelection: function(event, chartContext, config) {
          if (isDetail) return;
          if (!graphOption?.detailData || !graphOption?.detailCategory) return;

          const dataPointIndex: number = config.dataPointIndex; //선택한 data index
          const label = config.w.globals.labels;
          onClick && onClick(label[dataPointIndex]);
        },
      },
      redrawOnParentResize: true,
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function(value, opts) {
          return opts.globals.seriesPercent[opts.seriesIndex][0].toFixed(2) + `% (${_valueFormater(value)})`;
        },
      },
    },
    dataLabels: {
      enabled: false,
      formatter: function(val, opts) {
        const name = opts.w.globals.labels[opts.seriesIndex];
        return name.concat(`${val.toFixed(2)}%`);
      },
    },
    legend: {
      show: true,
      position: "bottom" as const,
      horizontalAlign: (graphOption?.legend?.horizontalAlign as any) || ("center" as const),
      itemMargin: {
        // horizontal: 0,
        vertical: 0,
      },
      formatter: function(seriesName, opts) {
        const percent = opts.w.globals.seriesPercent[opts.seriesIndex];
        return seriesName.concat(` (${percent && percent[0].toFixed(2)}%)`);
      },
    },
    colors: colors.length > 0 ? toJS(colors) : UISET.CHART_COLOR,
  };

  return data && data.length > 0 ? (
    <Chart options={options} series={series} type="pie" height={`${height}px`} />
  ) : (
    <NullLogData height={265} />
  );
};

export default observer(Pie);
