import React from "react";
import { Row, Col } from "reactstrap";
import { observer, Observer } from "mobx-react";
import { languageSet, Common_Utils, useInjectStore } from "@qubit-utill/src";

interface ComponentProps {
  title?: string;
  selectedValue: number | string;
  paramName?: string;
}

const ClassificationTab: React.FC<ComponentProps> = ({
  paramName = "filterCategoryGroupId",
  selectedValue,
  title = languageSet("공격유형_분포"),
}) => {
  const { ReportStore } = useInjectStore();

  const _renderItems = () => {
    return ReportStore.tabMenus.map(
      (
        {
          value,
          count,
          name,
          isUndefinedCount,
        }: {
          value: number;
          count: number;
          name: string;
          isUndefinedCount: boolean;
        },
        index: number
      ) => {
        const isActive = count > 0 || isUndefinedCount;
        let color = isActive ? "c-white" : "c-gray";
        color = value === selectedValue ? "c-yellow" : color;

        return (
          <>
            <span className="f-12 p-5 dis-inline-b">
              <a
                onClick={() => {
                  if (isActive) {
                    ReportStore.setListParams(paramName, value, true);
                  }
                }}
                className={`t_deconone p-5 ${isActive ? "cursor_p" : ""}`}
              >
                <span className={color}>
                  {name}
                  {!isUndefinedCount && <span>{` (${Common_Utils.numberWithComma(count)})`}</span>}
                </span>
              </a>
            </span>
            {ReportStore.tabMenus.length !== index + 1 && (
              <span className="txt_bar opacity-50" key={"classification_tab_dash_" + index}>
                |
              </span>
            )}
          </>
        );
      }
    );
  };

  return (
    <Row className="m-b-15">
      <Col md="2" sm="2" className="p-r-0">
        <Col md="12" className="bg-red b_radius5 p-tb-3 t-center">
          <span className="bold f-12 p-5 dis-inline-b">
            <a
              onClick={() => {
                ReportStore.setListParams(paramName, 0, true);
              }}
              className={`t_deconone p-5 cursor_p`}
            >
              <span className={selectedValue === 0 || selectedValue === "" ? "c-yellow" : "c-white"}>{title}</span>
            </a>
          </span>
        </Col>
      </Col>
      <Col md="10" sm="10">
        <Col md="12" className="bg-blue-dark b_radius5 p-tb-3 min_h33">
          <Observer render={_renderItems} />
        </Col>
      </Col>
    </Row>
  );
};

export default observer(ClassificationTab);
