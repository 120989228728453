import { observable, computed } from "mobx";

import { Common_Utils, SetterModel } from "@qubit-utill/src";

import { TicketInputModel } from "@src/pages/Group/Interlock/Ticket/models";

export default class TicketModel extends SetterModel {
  constructor(params) {
    super(params);
    if (params && params.ticketSystemParameterList) {
      this.ticketSystemParameterList = params.ticketSystemParameterList.map((data) => new TicketInputModel(data));
    }
  }

  @computed
  get isUseFlag() {
    return Common_Utils.isCheckBool(this.isUse);
  }

  @computed
  get isAttachLogFlag() {
    return Common_Utils.isCheckBool(this.isAttachLog);
  }

  @observable
  ticketSystemId: string = "";

  @observable
  ticketSystemName: string = "";

  @observable
  isUse: string = "0";

  @observable
  ticketSystemParameterList: TicketInputModel[] = [];

  @observable
  version: string = "";

  @observable
  isAttachLog: string = "";
}
