import { observable, computed } from "mobx";

import { Common_Utils, SetterModel } from "@qubit-utill/src";

export default class TicketInputModel extends SetterModel {
  @computed
  get isRequiredFlag() {
    return Common_Utils.isCheckBool(this.isRequired);
  }
  @computed
  get isUserDataFlag() {
    return Common_Utils.isCheckBool(this.isUserData);
  }

  @observable
  ticketSystemId: string = "";

  @observable
  ticketSystemParameterId: number = 0;

  @observable
  parameterDisplayName: string = "";

  @observable
  parameterName: string = "";

  @observable
  parameterValue: string = "";

  @observable
  isRequired: string = "";

  @observable
  isUserData: string = "";
}
