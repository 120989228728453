import { axiosModules } from "@qubit-utill/src";

export default {
  getChannelListByTypeos: (params) => {
    return axiosModules.get(`/pfilter/filterchannel`, params);
  },

  //채널 목록 (필터 생성/수정시 사용)
  getChannelListForFilter: (typeos) => axiosModules.get(`/pfilter/filterchannel/registerfilter?typeos=${typeos}`),
};
