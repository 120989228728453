import { computed, observable } from "mobx";
import { SetterModel, Common_Utils } from "@qubit-utill/src";

export default class UserFilterConfigModel extends SetterModel {
  constructor(params) {
    super(params);
    if (params) {
      this.serviceFilterType = params.info.serviceFilterType;
      this.isUseGlobalFilter = params.info.isUseGlobalFilter;
      this.isUseCustomFilter = params.info.isUseCustomFilter;
    }
  }

  @computed
  get isUseGlobalFilterFlag() {
    return Common_Utils.isCheckBool(this.isUseGlobalFilter);
  }

  @observable
  serviceFilterType = "";

  @observable
  isUseGlobalFilter = "1";

  @observable
  isUseCustomFilter = "";
}
