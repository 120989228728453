import { observable, action, computed } from "mobx";

import { pAuthApis } from "@src/apis";
import { UISET } from "@src/constants";

class InstallStore {
  constructor(root) {
    this.MyInfoStore = root.MyInfoStore;
  }

  @observable
  MyInfoStore;
  @observable
  menus = [];
  @observable
  subMenus = [];
  @observable
  selectedTab = "";
  @observable
  selectedSubTab = "";
  @observable
  license = "";
  @observable
  serviceAgreement;

  @computed
  get setParams() {
    return { agreementSourceId: 1, guid: this.MyInfoStore.userInfo.guid, uuid: this.MyInfoStore.userInfo.uuid };
  }

  @action
  setMenus = (code) => {
    this.clear();
    this.menus = UISET[code].menus;
    this.subMenus = UISET[code].subMenus || [];
  };

  @action
  clear = () => {
    this.menus = [];
    this.subMenus = [];
    this.selectedTab = "";
    this.selectedSubTab = "";
  };

  @action
  setSelectTab = (code) => () => {
    this.selectedTab = code;
  };

  @action
  setSelectSubTab = (code) => () => {
    this.selectedSubTab = code;
  };

  @action
  getLicenseKey = async () => {
    try {
      const { licenseKey } = await pAuthApis.getLicenseKey();
      this.license = licenseKey;
    } catch (error) {}
  };

  // 최초 에이전트 설치 동의는 MyInfoStore.getuserInfo에서 호출해서 등록함
  @action
  getIsInstallAgentAgreement = async () => {
    try {
      const { totalCount } = await pAuthApis.getAgreementRegistration(this.setParams);
      this.MyInfoStore.userInfo.serviceAgreement = totalCount > 0 ? true : false;
    } catch (err) {
      console.log("err: ", err);
    }
  };

  @action
  setIsInstallAgentAgreement = async (agreeParams) => {
    try {
      this.params = { ...this.setParams, agreementRegistrationEntryModelList: agreeParams };
      await pAuthApis.setAgreementRegistration(this.params);
      this.getIsInstallAgentAgreement();
    } catch (err) {
      console.log("err: ", err);
    }
  };
}
export default InstallStore;
