import { axiosModules } from "@qubit-utill/src";

export default {
  //사용자 호스트 목록 조회
  getUserHostList: (params) => {
    return axiosModules.get("/pfilter/userhost", params);
  },

  //사용자 호스트 등록
  createUserHost: (data) => {
    return axiosModules.post("/pfilter/userhost", data);
  },

  //사용자 호스트 삭제
  deleteUserHost: (userHostId) => {
    return axiosModules.del(`/pfilter/userhost/${userHostId}`);
  },

  //사용자 호스트 수정
  putUserHost: (userHostId, data) => {
    return axiosModules.put(`/pfilter/userhost/${userHostId}`, data);
  },

  //사용자 호스트 참조 사용처별 목록
  getUserHostReferenceList: (userHostId) => {
    return axiosModules.get(`/pfilter/userhostreference/${userHostId}/servicecodegroup`);
  },
};
