import { observable, action, toJS } from "mobx";

import { ListCondition, Common_Utils, withLoading, languageSet } from "@qubit-utill/src";

import { systemTotalLogApis } from "@src/apis/pLog";
import { DefenceStore, EdrDefenseStore } from "@src/common/stores";
import { UISET } from "@src/constants";
import { HOSTSECURITYLOGISSUETYPE } from "@src/constants/code";

import HostSecurityTotalLogModel from "@src/pages/TotalLog/HostSecurityTotalLog/Models/HostSecurityTotalLogModel";

export default class HostSecurityTotalLogStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }

  root;

  @observable
  tableList: HostSecurityTotalLogModel[] = [];

  @observable
  hostSecurityTotalLogInfo = new HostSecurityTotalLogModel({});

  @observable
  ipDefenceType = "DEFENSE_IP_SYSTEM";

  @observable
  autoDefenseInfo = {};

  @observable
  manualDefenseInfo = {};

  @withLoading("isLoading")
  @action
  getList = async () => {
    try {
      const tagServicePropertyList = this.tagServicePropertyList;
      const tagValueTypeList = this.tagValueTypeList;
      const getTagServiceDataList = this.openIpTagModal;

      this.params = {
        ...this.params,
        isEdrLog: true,
      };
      const { list, totalCount, nextCursorMark } = await systemTotalLogApis.getSysLogList(toJS(this.params));
      this.tableList = list.map((info) => {
        const _info = { ...info, tagServicePropertyList, tagValueTypeList, getTagServiceDataList };
        return new HostSecurityTotalLogModel(_info);
      });

      this.setCursorMarkInfo(nextCursorMark);
      this.totalCount = totalCount;
    } catch (e) {
      console.log("HostSecurityTotalLogStore", e);
      this.tableList = [];
      this.totalCount = 0;
    }
  };

  @withLoading("isSubLoading")
  @action
  getInfo = async (documentId) => {
    try {
      const tagServicePropertyList = this.tagServicePropertyList;
      const tagValueTypeList = this.tagValueTypeList;
      const getTagServiceDataList = this.openIpTagModal;
      const getTagServiceDataListFn = this.getTagServiceDataList;

      const { info, viewTemplate, programMessageTemplate } = await systemTotalLogApis.getSysLog(documentId);

      const _info = {
        ...info,
        viewTemplate,
        programMessageTemplate,
        tagServicePropertyList,
        tagValueTypeList,
        getTagServiceDataList,
        getTagServiceDataListFn,
      };

      this.hostSecurityTotalLogInfo = new HostSecurityTotalLogModel(_info);
      info.logClientIp && (await DefenceStore.getDefenceInfo(this.hostSecurityTotalLogInfo));

      await this.getEdrDefense(documentId, this.hostSecurityTotalLogInfo.serverId);

      this.root.TicketStore.getPrintTicketInfo(HOSTSECURITYLOGISSUETYPE, documentId, info.logCreateDate); //get 티켓발행정보
    } catch (err) {
      console.log(err);
      this.hostSecurityTotalLogInfo = new HostSecurityTotalLogModel({});
    }
  };

  /**
   * 호스트보안 정보 가져오기
   *
   * @param documentId
   * @param serverId
   */
  @action
  getEdrDefense = async (documentId, serverId) => {
    const { autoDefenseInfo, manualDefenseInfo } = await EdrDefenseStore.getEdrDefense(documentId, serverId);

    this.autoDefenseInfo = await autoDefenseInfo;
    this.manualDefenseInfo = await manualDefenseInfo;
  };

  /**
   * 호스트보안 차단 실행
   *
   * @param documentId
   * @param serverId
   * @param edrFilterElementId
   * @param data
   * @returns
   */
  @action
  createEdrDefenseCmd = async (documentId, serverId, edrFilterElementId, data) => {
    try {
      await EdrDefenseStore.createEdrDefenseCmd(documentId, edrFilterElementId, data);
    } catch (error) {
      return error;
    }

    //정보 갱신
    await this.getEdrDefense(documentId, serverId);
  };

  @action
  downloadCSV = async (isUseBulk?, no?) => {
    try {
      if (this.tableList.length <= 0) {
        return languageSet("에러_데이터가없습니다");
      }

      this.params = {
        ...toJS(this.params),
        isEdrLog: true,
      };

      let csv = await systemTotalLogApis.getSysLogCsvList({
        ...toJS(this.params),
        // serverIds: toJS(this.params.serverIds),
        // typeosList: toJS(this.params.typeosList),
        // serverGroupIds: toJS(this.params.serverGroupIds),
        // edrDetectTypes: toJS(this.params.edrDetectTypes),
        offset: 0,
        limit: no,
      });

      Common_Utils.csvDownload(
        csv,
        Common_Utils.setCsvFileName({
          title: UISET.TOTALLOG_HOSTSECURITY.title,
          category: languageSet("전체로그"),
          ...this.params,
        })
      );
    } catch ({ data }) {
      console.log(data);
    }
  };
}
