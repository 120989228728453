import React from "react";
import { Observer, observer } from "mobx-react";
import { useBlogLink, languageSet, TooltipWrapper } from "@qubit-utill/src";
import Footer from "./Footer";

interface PropsTypes {
  title?: string;
  subTitle?: any;
  isUseHelp?: boolean;
  titles?: any;
  UISET?: { title: string };
  linkToUrl?: Function;
  reloadTimer?: any;
  searchList?: any;
  subTitleComponent?: any;
  headerClassName?: string;
  timePickerBoard?: any;
}

const BlogButton = ({ linkToUrl }: any) => {
  const { linkToUrl: defaultLinkToUrl } = useBlogLink();
  return (
    <TooltipWrapper tooltipText={languageSet("도움말")}>
      {(targetRef: any) => (
        <span ref={targetRef} className="f-right cursor_p">
          <a onClick={linkToUrl || defaultLinkToUrl}>
            <span className="fa fa-question-circle f-20 c-red m-r-0"></span>
          </a>
        </span>
      )}
    </TooltipWrapper>
  );
};

const ContentLayout: React.FC<PropsTypes> = ({
  title,
  children,
  subTitle,
  isUseHelp,
  titles,
  UISET = {},
  linkToUrl,
  reloadTimer,
  searchList,
  subTitleComponent,
  headerClassName = "header",
  timePickerBoard,
}) => {
  const _title = UISET.title || title;

  return (
    <div className="page-content">
      <div className={`${headerClassName} ${subTitle ? "p-t-0" : ""}`}>
        {_title && !timePickerBoard && (
          <div className="number c-black f-24 dis-inline-b">
            <span className="bold">{_title}</span>
            {subTitleComponent}
          </div>
        )}
        {timePickerBoard && timePickerBoard}
        {subTitle && <span className="f-13 p-r-10 c-black txt_hide">{subTitle}</span>}
        {searchList && <div className="dis-inline-b min_w150 m-r-5">{searchList}</div>}
        {titles}
        {isUseHelp && <BlogButton linkToUrl={linkToUrl} />}
        {reloadTimer && <Observer render={reloadTimer} />}
      </div>
      {children}
      <Footer />
    </div>
  );
};

export default observer(ContentLayout);
