import { axiosModules } from "@qubit-utill/src";

export default {
  // plogdetect > 웹 ML 탐지로그 > 탐지 ML로그 조회
  getWebLogMLDetectInfo: (documentId) => {
    return axiosModules.get(`/plogdetect/weblogmldetect/${documentId}`);
  },

  // plogdetect > 웹 ML 탐지로그 > 탐지 ML로그 리스트 조회
  getWebLogMLDetectList: (params) => {
    return axiosModules.get("/plogdetect/weblogmldetect/list", params);
  },

  // plogdetect > 웹 ML 탐지로그 > 탐지 ML로그 리스트 CSV 다운로드
  getWebLogMLDetectCsvList: (params) => {
    return axiosModules.getCsv("/plogdetect/weblogmldetect/list/csv", params);
  },
};
