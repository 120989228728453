import { languageSet } from "@qubit-utill/src";

import { FILTERDETECTML_LICENSECODE } from "@src/constants/licenseCode";
import getURI from "@src/constants/uriset";

import { MLContainer } from "@src/pages";

export default {
  //ML
  ML: {
    title: languageSet("ML탐지"),
    text: languageSet("ML탐지"),
    link: getURI("ML"),
    iconId: "fa fa-magic f-14",
    licenseCode: FILTERDETECTML_LICENSECODE,
    component: MLContainer,
  },
};
