import { observable, computed } from "mobx";

import { Common_Utils, SetterModel } from "@qubit-utill/src";

export default class ResourceConfigPathModel extends SetterModel {
  constructor(params, serverResourceConfigPathList) {
    super(params);

    //params : 관리 > 사용 > 응용프로그램원본 > 사용자 정의 : 선택된 항목들
    //serverResourceConfigPathList : 서버에서 보내준 설정 항목

    const setParams =
      serverResourceConfigPathList && serverResourceConfigPathList.find((info) => info.resourceId === params.resourceId);
    if (setParams) {
      this.configPath = setParams.configPath;
      this.isUse = setParams.isUse;
    }
  }

  @observable
  configPath: string = ""; //serverResourceConfigPathList.configPath

  @observable
  isUse: string = "0"; //serverResourceConfigPathList.isUse

  @observable
  resourceId: string = ""; //params.resourceId

  @observable
  resourceName: string = ""; //params.resourceId

  @computed
  get isUseFlag() {
    return Common_Utils.isCheckBool(this.isUse);
  }
}
