import { observable, action, computed, toJS } from "mobx";

import { languageSet, Common_Utils, SetterModel } from "@qubit-utill/src";

class UploadChartModel extends SetterModel {
  constructor(params) {
    super(params);
    this.unit = params.unit;
    this.getIndex = params.getIndex;
    this.lastIndex = params.lastIndex;
    this.timeText = params.timeText;
    this.termsTab = params.termsTab;

    this.originData[0] = {
      data: this.getChartData(params.system),
      name: languageSet("호스트보안"),
      type: `${this.termsTab === "month" && this.timeText === "Daily" ? "line" : "column"}`,
    };
    this.originData[1] = {
      data: this.getChartData(params.app),
      name: languageSet("응용프로그램"),
      type: `${this.termsTab === "month" && this.timeText === "Daily" ? "line" : "column"}`,
    };
    this.originData[2] = {
      data: this.getChartData(params.web),
      name: languageSet("웹"),
      type: `${this.termsTab === "month" && this.timeText === "Daily" ? "line" : "column"}`,
    };
    this.originData[3] = {
      data: this.getChartData(params.network),
      name: languageSet("네트워크"),
      type: `${this.termsTab === "month" && this.timeText === "Daily" ? "line" : "column"}`,
    };
    if (params.timeText === "Hour") {
      this.originData[4] = {
        data: this.getChartData(params.yesterdaySystem),
        name: `${languageSet("전일")} ${languageSet("호스트보안")}`,
        type: "line",
      };
      this.originData[5] = {
        data: this.getChartData(params.yesterdayApp),
        name: `${languageSet("전일")} ${languageSet("응용프로그램")}`,
        type: "line",
      };
      this.originData[6] = {
        data: this.getChartData(params.yesterdayWeb),
        name: `${languageSet("전일")} ${languageSet("웹")}`,
        type: "line",
      };
      this.originData[7] = {
        data: this.getChartData(params.yesterdayNetwork),
        name: `${languageSet("전일")} ${languageSet("네트워크")}`,
        type: "line",
      };
    }
  }
  timeText = "";
  termsTab = "day";
  getIndex = (data: any, category: string[]) => 0;
  lastIndex = -1;

  getChartData = (apiData) => {
    let uploadData: any[] = this.category.map(() => 0);
    apiData &&
      apiData.forEach((data) => {
        const index = this.getIndex(data, this.category);
        uploadData[index] = data.logUploadBytes;
      });
    this.lastIndex > -1 && this.setNullData(uploadData);
    return uploadData;
  };

  setNullData = (array) => {
    for (let i = this.lastIndex + 1; i < this.category.length; i++) {
      array[i] = null;
    }
  };

  @observable
  unit = "KB";

  @observable
  category: string[] = [];

  @observable
  originData: { data: any[]; name: string; type: string }[] = [];

  @computed
  get chartData() {
    let _chartData = toJS(this.originData);

    return (_chartData = _chartData.map((item) => {
      const parseData = item.data.map((value) => Common_Utils.getParseBytes(this.unit, value));
      item.data = parseData;
      return item;
    }));
  }

  @action
  setUnitData = (unit: string) => {
    this.unit = unit;
  };
}

export default UploadChartModel;
