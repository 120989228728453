import { observable } from "mobx";

import { SetterModel } from "@qubit-utill/src";

class UserServerGroupModel extends SetterModel {
  @observable
  serverGroupId: number = 0;

  @observable
  serverGroup: string = "";

  @observable
  serverCount: number = 0;

  @observable
  filterCustomCount: number = 0;

  @observable
  registerUuid: string = "";

  @observable
  isSelected: boolean = true;
}

export default UserServerGroupModel;
