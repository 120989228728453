import { observable, action } from "mobx";

import { withLoading, ListCondition } from "@qubit-utill/src";

import { middlewareGroupApis, middlewareConfigApis } from "@src/apis/pServer";
import { HostListModel } from "@src/common/models";

import { GroupHAProxyModel, HAProxySectionModel, HAProxyPropertyModel } from "@src/pages/Group/HAProxy/models";

class GroupHAProxyStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }

  root = {};

  @observable
  isPropertyListLoading: boolean = true;

  @observable
  isViewLoading: boolean = true;

  @observable
  haproxyGroupList: Array<GroupHAProxyModel> = [];

  @observable
  haproxyGroupInfo = new GroupHAProxyModel(this.root);

  @observable
  haproxyGroupDetailInfo = new GroupHAProxyModel(this.root);

  @observable
  haproxySectionList: Array<HAProxySectionModel> = [];

  @observable
  haproxySectionOriginList: Array<HAProxySectionModel> = [];

  @observable
  firewalldInfo: object = {};

  @observable
  haproxySslInfo: { isUseSsl: string; middlewareSslList: Array<HostListModel>; middlewareUserConfigId: number } = {
    isUseSsl: "",
    middlewareSslList: [],
    middlewareUserConfigId: 0,
  };

  @observable
  managerConsoleList: Array<object> = [];

  @observable
  haproxySectionInfo = new HAProxySectionModel(null);

  @observable
  haproxySectionOriginInfo = new HAProxySectionModel(null);

  @observable
  haproxyPropertyList: Array<HAProxyPropertyModel> = [];

  @observable
  middlewareId = "HAPROXY";

  @observable
  serverGroupId: number = 0;

  @observable
  middlewareUserConfigId: number = 0;

  @observable
  middlewareSslOriginList: Array<HostListModel> = [];

  @observable
  middlewareUserPropertyList: Array<HAProxyPropertyModel> = [];

  // 그룹 리스트 조회
  @withLoading("isLoading")
  @action
  getList = async () => {
    try {
      const { list } = await middlewareGroupApis.getMiddlewareGroupList(this.middlewareId);
      this.haproxyGroupList = list.map((groupInfo) => new GroupHAProxyModel(this.root, groupInfo));
    } catch (error) {
      this.haproxyGroupList = [];
    }
  };

  // 그룹 정보 조회
  @withLoading("isViewLoading")
  @action
  getInfo = async (middlewareUserConfigId) => {
    try {
      const { info } = await middlewareGroupApis.getMiddlewareGroupInfo(middlewareUserConfigId);
      this.haproxyGroupInfo = new GroupHAProxyModel(this.root, info);

      const middlewareSectionNameList = info.middlewareUserSectionList.map(({ middlewareSectionName }) => {
        return middlewareSectionName;
      });

      let beforeMiddlewareSectionId = 0;

      this.haproxySectionList = info.middlewareUserSectionList.map((sectionInfo, index) => {
        let isUseDelBtn;
        if (beforeMiddlewareSectionId !== sectionInfo.middlewareSectionId) {
          beforeMiddlewareSectionId = sectionInfo.middlewareSectionId;
          isUseDelBtn = false;
        } else {
          beforeMiddlewareSectionId = sectionInfo.middlewareSectionId;
          isUseDelBtn = true;
        }

        const overlapCount = middlewareSectionNameList.reduce(
          (_overlapCount, name) => _overlapCount + (name === sectionInfo.middlewareSectionName),
          0
        );
        const overlapLastIndex = middlewareSectionNameList.lastIndexOf(sectionInfo.middlewareSectionName);
        const isLast = overlapCount > 1 && overlapLastIndex === index;

        sectionInfo.middlewareUserPropertyList = sectionInfo.middlewareUserPropertyList.map(
          (property) => new HAProxyPropertyModel(property)
        );

        return (this.haproxySectionInfo = new HAProxySectionModel(sectionInfo, isUseDelBtn, isLast));
      });

      this.haproxySectionOriginList = info.middlewareUserSectionList.map((sectionInfo) => {
        sectionInfo.middlewareUserPropertyList = sectionInfo.middlewareUserPropertyList.map(
          (property) => new HAProxyPropertyModel(property)
        );
        return (this.haproxySectionOriginInfo = new HAProxySectionModel(sectionInfo));
      });

      this.firewalldInfo = info.middlewareFirewalldList[0];
      this.setIsCheckedTrue(info);
      this.haproxySslInfo = {
        isUseSsl: info.isUseSsl,
        middlewareSslList: info.middlewareSslList.map((ssl) => new HostListModel(ssl)),
        middlewareUserConfigId: info.middlewareUserConfigId,
      };
      this.middlewareSslOriginList = info.middlewareSslList.map((ssl) => new HostListModel(ssl));
      this.managerConsoleList = info.middlewareManagerConsoleList.map((stats) => {
        return {
          managerConsoleAddress: stats.managerConsoleAddress,
          middlewareManagerConsole: stats.middlewareManagerConsole,
        };
      });
      this.serverGroupId = info.serverGroupId;
      this.middlewareUserConfigId = info.middlewareUserConfigId;
    } catch (error) {
      this.haproxyGroupInfo = new GroupHAProxyModel();
    }
  };

  @action
  setIsCheckedTrue = (info?) => {
    return info
      ? info.middlewareSslList.forEach((ssl) => (ssl.isChecked = true))
      : this.haproxyGroupInfo.middlewareSslList.forEach((ssl) => (ssl.isChecked = true));
  };

  // 미리보기용 정보 조회
  @action
  getDetailInfo = async (middlewareUserConfigId) => {
    try {
      const { info } = await middlewareGroupApis.getMiddlewareGroupInfo(middlewareUserConfigId);

      this.haproxyGroupDetailInfo.middlewareUserSectionList = info.middlewareUserSectionList.map((sectionInfo) => {
        return new HAProxySectionModel(sectionInfo);
      });
    } catch ({ data }) {
      return (this.haproxyGroupDetailInfo = new GroupHAProxyModel(this.root));
    }
  };

  // 그룹 추가
  @withLoading("isLoading")
  @action
  createGroup = async (serverGroupId) => {
    try {
      await middlewareGroupApis.createMiddlewareGroup(this.middlewareId, { serverGroupId });
      this.getList();
    } catch ({ data }) {
      throw data;
    }
  };

  // 그룹 삭제
  @action
  deleteGroup = async (middlewareUserConfigId) => {
    try {
      await middlewareGroupApis.deleteMiddlewareGroup(middlewareUserConfigId);
      this.getList();
    } catch ({ data }) {
      throw data;
    }
  };

  // config 추가
  @action
  createConfig = async (middlewareUserConfigId, middlewareSectionId) => {
    try {
      await middlewareConfigApis.createMiddlewareConfig(this.middlewareId, middlewareUserConfigId, middlewareSectionId);
      this.getInfo(middlewareUserConfigId);
    } catch ({ data }) {
      throw data;
    }
  };

  // config 수정
  @action
  updateConfig = async (middlewareUserSectionId, isUse, middlewareUserConfigId, params) => {
    const isScheduler = "0";
    try {
      await middlewareConfigApis.updateMiddlewareConfig(
        middlewareUserSectionId,
        isUse,
        middlewareUserConfigId,
        isScheduler,
        params
      );
      this.getInfo(middlewareUserConfigId);
    } catch ({ data }) {
      console.log(data);
      return data;
    }
  };

  // config 삭제
  @action
  deleteConfig = async (middlewareUserSectionId) => {
    try {
      await middlewareConfigApis.deleteMiddlewareConfig(middlewareUserSectionId, this.middlewareUserConfigId);
      this.getInfo(this.middlewareUserConfigId);
    } catch ({ data }) {
      throw data;
    }
  };

  @action
  addHAProxyPropertyList = (propertyList, middlewarePropertyKey) => {
    return propertyList.push(new HAProxyPropertyModel({ middlewarePropertyKey }));
  };

  // property 삭제
  @withLoading("isPropertyListLoading")
  @action
  deleteHAProxyPropertyList = (propertyList, index) => {
    propertyList.splice(index, 1);
  };

  @action
  getPropertySelectList = async (middlewareSectionId) => {
    let propertyKeySelectOptionList: Array<{ name: string; value: string }> = [];
    let propertyValueSelectOptionList: Array<{ name: string; value: string; key: string }> = [];
    try {
      const { list } = await middlewareConfigApis.getMiddlewarePropertySelectList(this.middlewareId, middlewareSectionId);

      list.map((optionInfo) => {
        return propertyKeySelectOptionList.push({
          name: optionInfo.middlewarePropertyKey,
          value: optionInfo.middlewarePropertyKey,
        });
      });

      list.map((optionInfo) => {
        if (optionInfo.middlewarePropertyValueList.length > 0) {
          return optionInfo.middlewarePropertyValueList.map((valueInfo) => {
            return propertyValueSelectOptionList.push({
              name: valueInfo.middlewarePropertyValue,
              value: valueInfo.middlewarePropertyValue,
              key: valueInfo.middlewarePropertyKey,
            });
          });
        }
        return "";
      });
      return { propertyKeySelectOptionList, propertyValueSelectOptionList };
    } catch ({ data }) {
      propertyKeySelectOptionList = [];
      propertyValueSelectOptionList = [];
      console.log(data);
    }
  };

  // firewalld 수정
  @action
  updateMiddlewareFirewalld = async (param) => {
    try {
      await middlewareConfigApis.updateMiddlewareFirewalld(this.middlewareUserConfigId, param);
      await this.getInfo(this.middlewareUserConfigId);
    } catch ({ data }) {
      return data;
    }
  };

  // firewalld 실행
  @action
  executeMiddlewareFirewalld = async () => {
    try {
      await middlewareConfigApis.executeMiddlewareFirewalld(this.middlewareUserConfigId, this.serverGroupId, this.middlewareId);
    } catch ({ data }) {
      return data;
    }
  };

  // ssl 추가/수정/삭제
  @action
  updateMiddlewareSsl = async (isUseSsl, params) => {
    try {
      await middlewareConfigApis.updateMiddlewareSsl(this.middlewareUserConfigId, isUseSsl, params);
      await this.getInfo(this.middlewareUserConfigId);
    } catch ({ data }) {
      return data;
    }
  };

  // stats 추가
  @action
  createManagerConsole = async () => {
    try {
      await middlewareConfigApis.createMiddlewareManagerConsole(this.middlewareUserConfigId);
      await this.getInfo(this.middlewareUserConfigId);
    } catch ({ data }) {
      return data;
    }
  };

  // stats 수정
  @action
  updateManagerConsole = async (middlewareManagerConsole, param) => {
    try {
      await middlewareConfigApis.updateMiddlewareManagerConsole(middlewareManagerConsole, param);
      await this.getInfo(this.middlewareUserConfigId);
    } catch ({ data }) {
      return data;
    }
  };

  // stats 삭제
  @action
  deleteManagerConsole = async (middlewareManagerConsole) => {
    try {
      await middlewareConfigApis.deleteMiddlewareManagerConsole(middlewareManagerConsole);
      await this.getInfo(this.middlewareUserConfigId);
    } catch ({ data }) {
      return data;
    }
  };

  @action
  getSelectHostList = (hostList) => {
    if (hostList.length > 0) {
      this.haproxySslInfo.middlewareSslList = hostList.filter(({ isChecked }) => isChecked);
    }
  };

  // 설정검사 실행
  @action
  validationConfigGroup = async (middlewareUserConfigId, validatorState, serverGroupId) => {
    const params = {
      middlewareUserConfigId,
      validatorState,
      serverGroupId,
    };
    try {
      await middlewareGroupApis.validationConfigMiddlewareGroup(this.middlewareId, params);
      await this.getList();
    } catch ({ data }) {
      return data;
    }
  };

  // 최종적용 실행
  @action
  applicationGroup = async (middlewareUserConfigId, validatorState, serverGroupId) => {
    const params = {
      middlewareUserConfigId,
      validatorState,
      serverGroupId,
    };
    try {
      await middlewareGroupApis.applicationMiddlewareGroup(this.middlewareId, params);
      await this.getList();
    } catch ({ data }) {
      return data;
    }
  };

  // 재설정 실행
  @action
  reloadGroup = async (serverGroupId, middlewareUserConfigId) => {
    try {
      await middlewareGroupApis.reloadMiddlewareGroup(serverGroupId, middlewareUserConfigId, this.middlewareId);
      await this.getList();
    } catch ({ data }) {
      return data;
    }
  };

  // 재시작 실행
  @action
  restartGroup = async (serverGroupId, middlewareUserConfigId) => {
    try {
      await middlewareGroupApis.restartMiddlewareGroup(serverGroupId, middlewareUserConfigId, this.middlewareId);
      await this.getList();
    } catch ({ data }) {
      return data;
    }
  };
}

export default GroupHAProxyStore;
