import { useLicenceCheck, useBlogLink } from "@qubit-utill/src";
import { LOGFORENSIC_LICENSECODE } from "@src/constants/licenseCode";

export const BranchUrlCode = (urlCode) => {
  const { _linkToUrl } = useBlogLink();
  const { isRender } = useLicenceCheck(LOGFORENSIC_LICENSECODE);

  return urlCode && _linkToUrl(isRender ? urlCode.forensicLink : urlCode.link);
};

export default {
  //관리
  GROUP_URLCODE: {
    link: "group",
    forensicLink: "forensicgroup",
  },

  //관리 > 연동
  GROUPLINKING_URLCODE: {
    link: "grouplinking",
    forensicLink: "forensicgrouplinking",
  },

  //관리 > 사용
  GROUPSETTING_URLCODE: {
    link: "groupsetting",
    forensicLink: "forensicgroupsetting",
  },

  //대시보드
  DASHBOARD_URLCODE: {
    link: "dashboard",
    forensicLink: "forensicdashboard",
  },

  //시스템 > 시스템관리
  SYSTEMMGMT_URLCODE: {
    link: "systemmgmt",
    forensicLink: "forensicsystemmgmt",
  },

  //필터 > 등록필터 > 웹방화벽
  FILTERREGISTRATIONWAF_URLCODE: {
    link: "filterregistrationwaf",
    forensicLink: "forensicfilterregistrationwaf",
  },

  //필터 > 등록필터 > 호스트보안
  FILTERREGISTRATIONEDR_URLCODE: {
    link: "filterregistrationedr",
    forensicLink: "forensicfilterregistrationedr",
  },

  //필터 > 등록필터 > 응용프로그램 > 원본
  FILTERREGISTRATIONAPPDEFAULT_URLCODE: {
    link: "filterregistrationappdefault",
    forensicLink: "filterregistrationappdefault",
  },

  //필터 > 등록필터 > 응용프로그램 > 사용자
  FILTERREGISTRATIONAPPCUSTOM_URLCODE: {
    link: "filterregistrationappcustom",
    forensicLink: "filterregistrationappcustom",
  },

  //필터 > 등록필터 > 웹
  FILTERREGISTRATIONWEB_URLCODE: {
    link: "filterregistrationweb",
    forensicLink: "forensicfilterregistrationweb",
  },

  //필터 > 등록필터 > 네트워크
  FILTERREGISTRATIONNET_URLCODE: {
    link: "filterregistrationnet",
    forensicLink: "forensicfilterregistrationnet",
  },

  //사이드바 > MANUAL
  SIDEBARMANUAL_URLCODE: {
    link: "sideBarMANUAL",
    forensicLink: "forensicsideBarMANUAL",
  },

  //사이드바 > FAQ
  SIDEBARFAQ_URLCODE: {
    link: "sideBarFAQ",
    forensicLink: "forensicsideBarFAQ",
  },

  //사이드바 > UPDATE
  SIDEBARUPDATE_URLCODE: {
    link: "sideBarUPDATE",
    forensicLink: "forensicsideBarUPDATE",
  },

  //사이드바 > NEWS
  SIDEBARNEWS_URLCODE: {
    link: "sideBarNEWS",
    forensicLink: "forensicsideBarNEWS",
  },
};
