import { languageSet } from "@qubit-utill/src";

import getURI from "@src/constants/uriset";
import { WebFirewallContainer } from "@src/pages";
import { WAF_LICENSECODE } from "@src/constants/licenseCode";

export default {
  //웹방화벽 탐지
  FILTERDETECTWEBFIREWALL: {
    title: languageSet("웹방화벽"),
    text: languageSet("웹방화벽"),
    link: getURI("FILTERDETECTWEBFIREWALL"),
    isUseCursorMark: true,
    iconId: "icon-layers f-13",
    licenseCode: WAF_LICENSECODE,
    component: WebFirewallContainer,
  },
};
