import { observable, computed } from "mobx";

import { SetterModel, Common_Utils } from "@qubit-utill/src";

import { GroupSquidSectionModel } from "@src/pages/Group/SQUID/models";

export default class GroupSquidModel extends SetterModel {
  @computed
  get isUseSslFlag() {
    return Common_Utils.isCheckBool(this.isUseSsl);
  }

  @computed
  get isSyncFlag() {
    return Common_Utils.isCheckBool(this.isSync);
  }

  @observable
  middlewareUserConfigId: number = 0;

  @observable
  middlewareId: string = "";

  @observable
  guid: string = "";

  @observable
  serverGroupId: number = 0;

  @observable
  validatorState: string | null = "" || null;

  @observable
  isUseSsl: string = "0";

  @observable
  registerUuid: string = "";

  @observable
  updateUuid: string = "";

  @observable
  serverHashKey: string = "";

  @observable
  serverGroup: string = "";

  @observable
  isSync: string = "0";

  @observable
  middlewareExecuteCmdList: Array<object> = [{ middlewareExecuteType: "", executeState: "" }];

  @observable
  middlewareUserSectionList: Array<GroupSquidSectionModel> = [];

  @observable
  middlewareFirewalldList = [];

  @observable
  middlewareSslList = [];

  @observable
  middlewareManagerConsoleList = [];
}
