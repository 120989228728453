import { axiosModules } from "@qubit-utill/src";

export default {
  //응용프로그램 리소스 목록 조회
  getApplicationResourceList: () => {
    return axiosModules.get("/pmetrics/applicationresource/list");
  },

  //사용자 정의 설정 리스트 조회
  getResourceConfigUserGroupList: () => {
    return axiosModules.get("/pmetrics/resourceconfigusergroup/list");
  },

  //사용자 정의 설정 수정(등록/삭제)
  updateResourceConfigUserGroup: (isUse, data) => {
    return axiosModules.put(`/pmetrics/resourceconfigusergroup/${isUse}`, data);
  },
};
