import { observable, computed } from "mobx";

import { Common_Utils, languageSet, SetterModel, stringUtils } from "@qubit-utill/src";
import { COOKIELANG } from "@qubit-utill/src/common_constants";

import { CSVINFO } from "@src/constants";

class WebFirewallDefenceModel extends SetterModel {
  constructor(info, CodeStore) {
    super(info);
    this.CodeStore = CodeStore;
    if (info) {
      this.defenceIp = info.detectDefenseIp;
      this.defenceSeparate = CodeStore.getCodeName("customDefenseFilterType", info.customDefenseFilterType);
      this.defenseIpValue = info.detectDefenseIp.split("/")[0];
      this.defenseBitValue = info.detectDefenseIp.split("/")[1] || this.defenseBitValue;

      this.detectDefenseId = info.customDefenseWafId;
      this.defenseConfigFilterType = info.customDefenseFilterType;

      this.originExpiredDays = info.isCustomExpiredDay === "1" ? info.expiredDays : "";
    }
  }
  @observable
  CodeStore;

  @computed
  get csvData() {
    return Common_Utils.makeCsvRowData(this, CSVINFO.DEFENCEIP_WEBFIRE.infoKey);
  }

  @computed
  get isActiveFlag() {
    return Common_Utils.isCheckBool(this.isActive);
  }

  @computed
  get defenceNation() {
    //방어IP국가
    return this.detectDefenseNation && this.detectDefenseNation.toLowerCase();
  }

  @computed
  get getDefenceNation() {
    return stringUtils.getNationFullName(this.detectDefenseNation, COOKIELANG);
  }

  @computed
  get firewallSetting() {
    return Common_Utils.isCheckBool(this.isActive);
  }

  @computed
  get checkValid() {
    return this.defenseDescription !== "";
  }

  @computed
  get getExpiredDays() {
    return Common_Utils.numberWithComma(this.expiredDays);
  }

  @computed
  get getSelectExpiredDays() {
    return this.expiredDays === "0000" || this.isCustomExpiredDay === "1" ? languageSet("직접입력") : this.getExpiredDays;
  }

  @observable
  defenseIpValue: String = "";

  @observable
  defenseBitValue: String = "32";

  @observable
  customDefenseWafIds = []; //차단IP고유 키

  @observable
  customDefenseWafId: number = 0; //차단IP고유 키

  @observable
  customDefenseFilterType: string = "register"; //방어분류(즉시차단/수동등록)

  @observable
  detectDefenseType: string = "manual"; //방어실행유형(auto, manual)

  @observable
  isActive: string = "1"; //방어상태

  @observable
  detectDefenseIp: string = ""; //공격 아이피

  @observable
  detectDefenseNation: string = ""; //공격 아이피 지역코드

  @observable
  defenseDescription: string = ""; //방어 설명

  @observable
  registerDate: string = ""; //등록일

  @observable
  updateDate: string = ""; //수정일

  @observable
  defenceIp: string = ""; //방어IP

  @observable
  defenceSeparate: string = ""; //분류

  @observable
  isPrivateIp: boolean = false; //공격IP가 내부인지 아닌지

  @observable
  isSelected: boolean = false; //체크박스 선택 여부

  @observable
  detectDefenseId: string = "";

  @observable
  defenseConfigFilterType: string = "register"; //방어분류(즉시차단/수동등록)

  @observable
  logType: string = "waf";

  @observable
  expiredDays: string = "7";

  @observable
  originExpiredDays: string = "";

  @observable
  expiredDate: string = "";

  @observable
  registerUserName: string = "";

  @observable
  isCustomExpiredDay: string = "";
}

export default WebFirewallDefenceModel;
