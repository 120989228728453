import { observable, action, toJS } from "mobx";

import { withLoading, ListCondition, Common_Utils, UserException } from "@qubit-utill/src";

import { pAccountApis, pAuthApis } from "@src/apis";
import { serverApis } from "@src/apis/pServer";

import { MemberModel } from "@src/pages/Group/Member/models";
import { UserServerGroupModel } from "@src/pages/Server/ServerGroupManagement/models";

class MemberStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }

  root;

  @observable
  currentComponent = "MemberTr";

  @observable
  memberInfo = new MemberModel(null, this.root.CodeStore);

  @observable
  tableList = [];

  @observable
  userServerGroupList: any = [];

  @observable
  totalServerGroupList: Array<UserServerGroupModel> = [];

  @observable
  selectedServerGroupCount: number = 0;

  @observable
  selectedServerGroupList: Array<{ serverGroupId: number }> = [];

  @observable
  isServerGroupAllFlag: boolean = true;

  @observable
  isModalLoading = false;

  @withLoading("isLoading")
  @action
  getList = async () => {
    try {
      await this.root.CodeStore.getRoleList({ isDisplay: "1" });
      const { list, totalCount } = await pAccountApis.getUserList(this.params);
      this.tableList = list.map((data) => new MemberModel(data, this.root.CodeStore));
      this.totalCount = totalCount;
    } catch ({ data }) {
      this.tableList = [];
      this.totalCount = 0;
    }
  };

  @action
  deleteMember = (uuid) => async (_, password) => {
    try {
      await pAccountApis.deleteUser(uuid, {
        masterPassword: password,
      });
      await this.getList();
    } catch ({ data }) {
      return data;
    }
  };

  @action
  setRole = (uuid, isGetInfo?) => async (roleCode, password) => {
    try {
      await pAuthApis.updateRoleUserPublic({
        password,
        uuid,
        roleCodeList: [roleCode],
      });

      isGetInfo ? this.getMemberInfo(uuid) : this.getList();
    } catch (e) {
      return e.data;
    }
  };

  @action
  deleteUserFailLoginAuth = async (uuid, loginAuth) => {
    try {
      await pAuthApis.deleteUserFailLoginAuth(uuid, loginAuth);
      this.getList();
    } catch ({ data }) {
      return data;
    }
  };

  @action
  setCurrentComponet = (value) => {
    this.currentComponent = value;
  };

  @action
  getMemberInfo = async (uuid) => {
    try {
      const { info, userLastLogin } = await pAccountApis.getMemberInfo(uuid);
      const _info = { ...info, lastLoginSuccessDate: userLastLogin?.registerDate };
      this.memberInfo = new MemberModel(_info, this.root.CodeStore);
    } catch (err) {
      this.memberInfo = new MemberModel(null, this.root.CodeStore);
    }
  };

  @action
  createUserServerGroup = async (uuid, isServerGroupAll, checkList, password) => {
    const data = {
      isServerGroupAll,
      serverGroupIds: toJS(checkList),
      password,
    };

    try {
      await serverApis.createUserServerGroup(uuid, data);
      this.getMemberInfo(uuid);
    } catch ({ data }) {
      return data;
    }
  };

  @action
  serverGroupHasList = (list) => {
    const t = new Set();
    list.forEach(({ serverGroupId }) => t.add(serverGroupId));
    return t;
  };

  @withLoading("isModalLoading")
  @action
  getServerGroupList = async (uuid?, isCreate?) => {
    !isCreate && (await this.getUserServerGroupList(uuid));
    await this.getTotalServerGroupList(isCreate);
  };

  @action
  getTotalServerGroupList = async (isCreate?) => {
    try {
      const { list } = await serverApis.getUserServerGroupList();
      this.totalServerGroupList = list.map((element: UserServerGroupModel) => {
        return new UserServerGroupModel(element);
      });
      this.selectedServerGroupCount = this.totalServerGroupList.length;
      if (!isCreate) {
        this.totalServerGroupList = this.totalServerGroupList.map((element) => {
          if (this.userServerGroupList.has(element.serverGroupId)) {
            element.isSelected = true;
          } else {
            element.isSelected = false;
          }
          return new UserServerGroupModel(element);
        });
      }
    } catch ({ data }) {
      this.totalServerGroupList = [];
    }
  };

  @action
  getUserServerGroupList = async (uuid) => {
    try {
      const { list } = await serverApis.getMemberUserServerGroupList(uuid);
      this.userServerGroupList = this.serverGroupHasList(list);
      this.selectedServerGroupCount = toJS(this.userServerGroupList).length;
    } catch ({ data }) {
      this.userServerGroupList = [];
      this.selectedServerGroupCount = 0;
    }
  };

  @action
  setSelectedServerGroup = (checkList, isServerGroupAll) => {
    this.isServerGroupAllFlag = Common_Utils.isCheckBool(isServerGroupAll);
    if (this.isServerGroupAllFlag) {
      this.selectedServerGroupList = this.totalServerGroupList.map(({ serverGroupId }) => ({
        serverGroupId,
      }));
      this.selectedServerGroupCount = this.totalServerGroupList.length;
    } else {
      this.selectedServerGroupList = checkList.map((value) => ({
        serverGroupId: value,
      }));
      this.selectedServerGroupCount = checkList.length;
    }

    this.totalServerGroupList.forEach(({ serverGroupId }, index) => {
      return this.totalServerGroupList[index]._setInfo("isSelected")(checkList.some((value) => value === serverGroupId));
    });
  };

  @action
  initSelectedServerGroup = () => {
    this.isServerGroupAllFlag = true;
    this.totalServerGroupList.forEach((_, index) => {
      this.totalServerGroupList[index]._setInfo("isSelected")(true);
    });
    this.selectedServerGroupCount = this.totalServerGroupList.length;
  };

  @action
  updateUserBlocked = async ({ uuid, masterPassword, isBlocked }) => {
    try {
      await pAccountApis.updateUserBlocked(uuid, { masterPassword, isBlocked });
      await this.getMemberInfo(uuid);
    } catch ({ data }) {
      throw new UserException(data.returnCodeMessage);
    }
  };
}

export default MemberStore;
