import { languageSet } from "@qubit-utill/src";

import {
  SystemFilterDetectContainer,
  ApplicationDefaultFilterDetectContainer,
  ApplicationResourceFilterDetectContainer,
  WebFilterDetectContainer,
  NetworkFilterDetectContainer,
} from "@src/pages";
import getURI from "@src/constants/uriset";
import { FILTERDETECTNET_LICENSECODE, WEB_LICENSECODE } from "@src/constants/licenseCode";

export default {
  //필터탐지
  FILTERDETECT_SYS: {
    title: languageSet("시스템"),
    text: languageSet("시스템"),
    link: getURI("FILTERDETECT_SYS"),
    isUseCursorMark: true,
    component: SystemFilterDetectContainer,
  },
  FILTERDETECT_APP: {
    text: languageSet("응용프로그램"),
    isUseCursorMark: true,
    childList: [
      //응용프로그램 원본
      {
        title: languageSet("원본"),
        text: languageSet("원본"),
        link: getURI("FILTERDETECT_APP_DEFAULT"),
        component: ApplicationDefaultFilterDetectContainer,
      },
      //응용프로그램 사용자정의
      {
        title: languageSet("사용자정의"),
        text: languageSet("사용자정의"),
        link: getURI("FILTERDETECT_APP_RESOURCE"),
        component: ApplicationResourceFilterDetectContainer,
      },
    ],
  },
  FILTERDETECT_WEB: {
    title: languageSet("웹"),
    text: languageSet("웹"),
    link: getURI("FILTERDETECT_WEB"),
    isUseCursorMark: true,
    licenseCode: WEB_LICENSECODE,
    component: WebFilterDetectContainer,
  },
  FILTERDETECT_NET: {
    title: languageSet("네트워크"),
    text: languageSet("네트워크"),
    link: getURI("FILTERDETECT_NET"),
    isUseCursorMark: true,
    licenseCode: FILTERDETECTNET_LICENSECODE,
    component: NetworkFilterDetectContainer,
  },
  //응용프로그램 원본
  FILTERDETECT_APP_DEFAULT: {
    title: languageSet("응용프로그램"),
    text: languageSet("응용프로그램"),
    link: getURI("FILTERDETECT_APP_DEFAULT"),
    isUseCursorMark: true,
    component: ApplicationDefaultFilterDetectContainer,
  },
  //응용프로그램 사용자정의
  FILTERDETECT_APP_RESOURCE: {
    title: languageSet("응용프로그램"),
    text: languageSet("응용프로그램"),
    link: getURI("FILTERDETECT_APP_RESOURCE"),
    isUseCursorMark: true,
    component: ApplicationResourceFilterDetectContainer,
  },
};
