import { observable, computed } from "mobx";

import { Common_Utils, languageSet, SetterModel } from "@qubit-utill/src";

class GroupAlarmModel extends SetterModel {
  constructor(params, CodeStore) {
    super(params);
    this.CodeStore = CodeStore;

    this.CodeStore.codeListForSelect.filterRiskLevel.map(({ value }) => {
      this.riskOptions.push(value.code);
    });

    this.CodeStore.codeListForSelect.filterSeverityCode.map(({ value }) => {
      this.severityOptionList.push(value.codeOption);
    });

    this.CodeStore.codeListForSelect.filterFacilityCode.map(({ value }) => {
      this.facilityOptionList.push(value.codeName);
    });

    if (params) {
      this.serverGroupList = params.serverGroupList
        ? params.serverGroupList.map(({ serverGroup, name, value, serverGroupId }) => ({
            name: serverGroup || name,
            value: serverGroupId || value,
          }))
        : [];

      if (!params.alarmWebhookProtocol) {
        this.alarmWebhookProtocol = "UDP";
      }

      if (!params.messageFormat) {
        this.messageFormat = "RFC_5424";
      }

      if (!params.newlineType) {
        this.newlineType = "STRNL";
      }

      if (!params.alarmWebhookPort) {
        this.alarmWebhookPort = 514;
      }

      this.actionLogCodeList = params.actionLogCodeList ?? [];
      this.detectCodeList = params.detectCodeList ?? [];
      this.defenseCodeList = params.defenseCodeList ?? [];

      if (params.alarmWebhookDetectList === null || params.alarmWebhookDetectList === undefined) {
        this.alarmWebhookDetectList = [];

        if (params.alarmWebhookType === "syslog") {
          this.riskCodeByFilterType.map((info) => {
            for (const el of this.riskOptions) {
              this.alarmWebhookDetectList.push({
                alarmDetectTypeRiskCode: el,
                alarmDetectType: info.filterType,
                alarmDetectTypeRiskCodeGroup: info.riskCodeGroup,
              });
            }
          });
        }
      }

      if (params.alarmWebhookConfigEntryList === null || params.alarmWebhookConfigEntryList === undefined) {
        this.alarmWebhookConfigEntryList = [];
        if (params.alarmWebhookType === "syslog") {
          this.configEntryInfo.map((info) => {
            if (info.useSeverity) {
              for (const el of this.severityOptionList) {
                this.alarmWebhookConfigEntryList.push({
                  alarmServiceType: info.filterType,
                  alarmConfigType: "detect",
                  isOptionUse: "0",
                  isUse: "1",
                  entryValue: el,
                  alarmConfigEntryType: "severity",
                });
              }
            }
            if (info.useFacility) {
              for (const el of this.facilityOptionList) {
                this.alarmWebhookConfigEntryList.push({
                  alarmServiceType: info.filterType,
                  alarmConfigType: "detect",
                  isOptionUse: "0",
                  isUse: "1",
                  entryValue: el,
                  alarmConfigEntryType: "facility",
                });
              }
            }
          });
        }
      }

      if (params.alarmWebhookConfigEntryUsageList === null || params.alarmWebhookConfigEntryUsageList === undefined) {
        this.alarmWebhookConfigEntryUsageList = [];
        if (params.alarmWebhookType === "syslog") {
          this.configEntryInfo.map((info) => {
            this.alarmWebhookConfigEntryUsageList.push({
              alarmServiceType: info.filterType,
              alarmConfigType: "detect",
              isUse: info.isUse,
            });
          });
        }
      }
    }
  }

  CodeStore;

  @observable
  riskOptions: string[] = [];

  @observable
  severityOptionList: string[] = [];

  @observable
  facilityOptionList: string[] = [];

  @observable
  riskCodeByFilterType = [
    { filterType: "system", riskCodeGroup: "filterRiskLevel" },
    { filterType: "edr", riskCodeGroup: "filterRiskLevel" },
    { filterType: "web", riskCodeGroup: "webFilterRiskCode" },
    { filterType: "network", riskCodeGroup: "filterRiskLevel" },
    { filterType: "webextendsdatabreach", riskCodeGroup: "filterRiskLevel" },
    { filterType: "waf", riskCodeGroup: "webFilterRiskCode" },
    { filterType: "application", riskCodeGroup: "filterRiskLevel" },
  ];

  @observable
  configEntryInfo = [
    { filterType: "system", useSeverity: true, useFacility: true, isUse: "1" },
    { filterType: "edr", useSeverity: true, useFacility: true, isUse: "1" },
    { filterType: "web", useSeverity: false, useFacility: false, isUse: "0" },
    { filterType: "waf", useSeverity: false, useFacility: false, isUse: "0" },
  ];

  validation = () => {
    if (!this.isDefaultFlag && !this.localServerGroupIds) {
      throw languageSet("메세지_시스템그룹을_선택");
    }

    if (this.alarmWebhookType === "webhook") {
      if (!this.alarmWebhookUrl || !this.alarmWebhookUrl.trim()) {
        throw languageSet("에러_모든값을입력해라");
      }
    } else if (this.alarmWebhookType === "syslog") {
      if (
        !this.alarmWebhookProtocol ||
        !this.messageFormat ||
        !this.alarmWebhookUrl ||
        !this.alarmWebhookUrl.trim() ||
        !this.alarmWebhookPort ||
        !this.newlineType
      ) {
        throw languageSet("에러_모든값을입력해라");
      }
    }
  };

  @computed
  get useAlarm() {
    return (
      !this.isWebhookDetectFlag && !this.isWebhookDefenseFlag && !this.isWebhookActionLogFlag && !this.isWebhookAgentStopFlag
    );
  }

  @computed
  get apiData() {
    return {
      alarmWebhookId: this.alarmWebhookId,
      isWebhookDetect: this.isWebhookDetect,
      isWebhookDefense: this.isWebhookDefense,
      alarmWebhookUrl: this.alarmWebhookUrl,
      isWebhookAgentStop: this.isWebhookAgentStop,
      alarmWebhookProtocol: this.alarmWebhookProtocol,
      alarmWebhookPort: this.alarmWebhookPort,
      newlineType: this.newlineType,
      alarmWebhookType: this.alarmWebhookType,
      messageFormat: this.messageFormat,
      serverGroupIds: this.localServerGroupIds,
      isDefault: this.isDefault,
      isWebhookActionLog: this.isWebhookActionLog,
      detectCodeList: this.detectCodeList,
      defenseCodeList: this.defenseCodeList,
      actionLogCodeList: this.actionLogCodeList,
      isUseLogOrigin: this.isUseLogOrigin,
      alarmWebhookDetectList: this.alarmWebhookDetectList,
      alarmWebhookConfigEntryList: this.alarmWebhookConfigEntryList,
      alarmWebhookConfigEntryUsageList: this.alarmWebhookConfigEntryUsageList,
    };
  }

  @computed
  get localServerGroupIds() {
    return this.serverGroupList.length > 0 ? this.serverGroupList.map(({ value }) => value) : null;
  }

  @computed
  get isWebhookDetectFlag() {
    return Common_Utils.isCheckBool(this.isWebhookDetect);
  }
  @computed
  get isWebhookDefenseFlag() {
    return Common_Utils.isCheckBool(this.isWebhookDefense);
  }

  @computed
  get isWebhookAgentStopFlag() {
    return Common_Utils.isCheckBool(this.isWebhookAgentStop);
  }

  @computed
  get isWebhookActionLogFlag() {
    return Common_Utils.isCheckBool(this.isWebhookActionLog);
  }

  @computed
  get isDefaultFlag() {
    return Common_Utils.isCheckBool(this.isDefault);
  }

  @computed
  get isUseLogOriginFlag() {
    return Common_Utils.isCheckBool(this.isUseLogOrigin);
  }

  @observable
  index: number = 0;

  @observable
  alarmWebhookId: number = 0;

  @observable
  isWebhookDetect: string = "1";

  @observable
  isWebhookDefense: string = "0";

  @observable
  isDefault: string = "0";

  @observable
  alarmWebhookUrl: string = "";

  @observable
  serverGroupList = [];

  @observable
  alarmWebhookPort: number = 514;

  @observable
  alarmWebhookProtocol: string = "UDP";

  @observable
  alarmWebhookType: "webhook" | "syslog" | "" = "";

  @observable
  isWebhookAgentStop: string = "0";

  @observable
  messageFormat: string = "RFC_5424";

  @observable
  newlineType: string = "STRNL";

  @observable
  isWebhookActionLog: string = "0";

  @observable
  actionLogCodeList: string[] = [];

  @observable
  detectCodeList: string[] = [];

  @observable
  defenseCodeList: string[] = [];

  @observable
  isUseLogOrigin: string = "0";

  @observable
  alarmWebhookDetectList: Array<object> = [];

  @observable
  alarmWebhookConfigEntryList: Array<object> = [];

  @observable
  webDetectStatusEntryList: string[] = [];

  @observable
  wafDetectStatusEntryList: string[] = [];

  @observable
  alarmWebhookConfigEntryUsageList: Array<object> = [];
}

export default GroupAlarmModel;
