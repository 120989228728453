import { observable } from "mobx";

import { SetterModel } from "@qubit-utill/src";

export default class servicePreferencesItemModel extends SetterModel {
  @observable
  isDefault: string = "0";

  @observable
  isDel: string = "0";

  @observable
  isGuidConfig: string = "0";

  @observable
  isUse: string = "0";

  @observable
  registerDate: string = "";

  @observable
  registerUuid: string | null = null;

  @observable
  servicePreferencesId: string = "";

  @observable
  servicePreferencesItemId: number = 0;

  @observable
  servicePreferencesItemName: string = "";

  @observable
  servicePreferencesItemOrderNo: string = "0";

  @observable
  servicePreferencesItemTag: string | null = "";

  @observable
  servicePreferencesItemValue: string = "1";

  @observable
  servicePreferencesName: string = "";

  @observable
  servicePreferencesTypeCode: string = "";

  @observable
  updateDate: string = "";

  @observable
  updateUuid: string | null = null;
}
