import { observable, action, computed } from "mobx";

import last from "lodash-es/last";

import { LINECOUNT } from "@qubit-utill/src/common_constants";
import Common_Utils from "@qubit-utill/src/utils/utils";

import { cursorMarkApis } from "@qubit-utill/src/apis";
import { tagServiceApis } from "@qubit-utill/src/apis/pfilter";

import customHistory from "../../History";
import { ModalStore } from ".";
import IpTagModal from "../components/organisms/Modal/IpTagModal";

export default class ListCondition {
  @observable
  isLoading = false;

  @observable
  isSubLoading = false;

  @observable
  totalCount = 0;

  @observable
  params: { [key: string]: any } = {
    limit: String(LINECOUNT),
    offset: 0,
    ascDesc: "DESC",
  };
  @action
  setParams = (params: { [key: string]: any }) => {
    this.params = params;
  };

  @action
  getList: Function = () => {};

  @observable
  initParamsList: any[] = ["isUseCursorMark", "offset"];

  @action
  setSelectList = async (list: Array<object>) => {
    list.map((info: any) => {
      if (info.componentType === "combineDatepicker") {
        this.initParamsList.push(info.datepickerMaxDateParameter);
        this.initParamsList.push(info.datepickerMinDateParameter);
      } else {
        this.initParamsList.push(info.searchMenuSelectCode);
      }
    });
  };

  @action
  clearMetaParams = () => {
    for (var key in this.params) {
      this.initParamsList.every(info => info !== key) && delete this.params[key];
    }
  };

  @action
  clearParams = () => {
    this.params = {
      limit: String(LINECOUNT),
      offset: 0,
      ascDesc: "DESC",
    };
  };

  @action
  setListParams = (elementName: string, value: any, isGetData?: boolean) => {
    this.params = { ...this.params, [elementName]: value };
    isGetData && this.getList();
  };

  @action
  setListNewParams = (
    elementName: string,
    value: any,
    isGetData: boolean,
    usePagination: boolean = true
  ) => {
    this.params[elementName] = value;
    this.params.offset = 0;
    this.params.cursorMark = "";
    this.cursorMarkInfo = [];
    usePagination && customHistory.push(`${Common_Utils.getCurrentPathUrl()}/page/1`);
    isGetData && this.getList();
  };

  @action
  setListParamsWithGetData = (elementName: string, value: any) => {
    this.params[elementName] = value;
    this.getList();
  };

  @action
  deleteListParams = (key: string) => {
    delete this.params[key];
  };

  @action
  deleteListParamsWithGetData = (key: string) => {
    delete this.params[key];
    this.getList();
  };

  @action
  changePage = (page: number) => {
    this.params.offset = Common_Utils.getOffset(page, this.params.limit);
    this.getList();
  };

  @action
  setIsLoading = (flag: boolean) => {
    this.isLoading = flag;
  };

  @computed
  get currentPage() {
    return Common_Utils.getPage(this.params.offset, this.params.limit);
  }

  // 커서 마크
  @observable
  isUseCursorMark: boolean = false;

  @observable
  cursorMarkInfo: Array<string> = [];

  @computed
  get cursorMarkOffset() {
    return (
      (this.cursorMarkInfo.length > 1 ? this.cursorMarkInfo.length - 1 : 0) * this.params.limit
    );
  }

  @computed
  get isUseCursorMarkFlag() {
    return this.isUseCursorMark;
  }

  @action
  setCursorMarkInfo = (cursorMark: string) => {
    //if.최초 커서마크 획득 이후 페이지를 이동하지 않았거나 이동 후 다시 첫페이지로 왔을 경우.
    //if.페이지를 갱신했을 경우(페이지내 새로고침 또는 검색에 의한 갱신) 새로운 커서마크를 획득하여 배열에 누적시킴을 막기 위함.
    //if.커서검색 사용 시 페이지의 갯수를 커서마크의 갯수로 판단하기 때문.
    //else.최초 커서마크 획득 부분.
    if (this.cursorMarkInfo.length > 0 && !this.isClickButtonFlag) {
      if (cursorMark) {
        this.cursorMarkInfo = [];
        this.cursorMarkInfo.push(cursorMark);
      }
    } else {
      cursorMark &&
        cursorMark !== this.cursorMarkInfo[this.cursorMarkInfo.length - 1] &&
        this.cursorMarkInfo.push(cursorMark);
    }
  };

  @action
  getCursorMarkFlagInfo = async () => {
    try {
      const { list } = await cursorMarkApis.getUserSettingInfo();
      const { isSave, servicePreferencesItemValue } = list.find(
        ({ servicePreferencesId }: any) => servicePreferencesId === "isUseCursorMark"
      );
      // TODO : #5788# 커서마크. 잔존 로그 문제로 커서마크 디폴트 적용을 막음. 약 2022년 1월 쯤 풀 예정 - Mark
      // 아래 코드로 치환하면 됨.
      // const cursorMarkFlag = isSave === "false" || servicePreferencesItemValue === "1" ? "1" : "0";
      //----
      const isUseCursorMarkFlag = isSave === "true" && servicePreferencesItemValue === "1";
      const cursorMarkFlag = isUseCursorMarkFlag ? "1" : "0";
      // this.params = { ...this.params, isUseCursorMark: isUseCursorMarkFlag };
      //----
      this.isUseCursorMark = Common_Utils.isCheckBool(cursorMarkFlag);
    } catch (err) {
      this.isUseCursorMark = false;
    }
  };

  @action
  setInitCursorMark = () => {
    this.isClickButtonFlag = false;
    this.cursorMarkInfo = [];
    this.params.cursorMark = "";
  };

  @action
  setInitPage = () => {
    this.isClickButtonFlag = false;
    this.cursorMarkInfo = [];
    this.params.cursorMark = "";
    this.getList();
  };

  @action
  setPrevPage = async () => {
    await this.cursorMarkInfo.splice(this.cursorMarkInfo.length - 2, 2);
    this.params.cursorMark = await last(this.cursorMarkInfo);
    this.cursorMarkInfo.length === 0 && (this.isClickButtonFlag = false);
    this.getList();
  };

  @action
  setNextPage = () => {
    this.isClickButtonFlag = true;
    this.isClickButtonFlag && (this.params.cursorMark = last(this.cursorMarkInfo));
    this.getList();
  };

  @observable
  isClickButtonFlag = false;

  @observable
  tagServicePropertyList = [];

  //태그 property 목록
  @action
  getTagServicePropertyList = async (tagServiceId?: string) => {
    try {
      const { list } = await tagServiceApis.getTagServicePropertyList(
        tagServiceId ? tagServiceId : "all"
      );
      this.tagServicePropertyList = list;
    } catch (error) {
      this.tagServicePropertyList = [];
    }
  };

  @observable
  tagServiceDataList = [];

  // 태그 데이터 목록
  @action
  getTagServiceDataList = async (params: object, tagServiceId?: string) => {
    try {
      const { list } = await tagServiceApis.getTagServiceDataList(
        tagServiceId ? tagServiceId : "all",
        params
      );

      this.tagServiceDataList = list;
      return this.tagServiceDataList;
    } catch (error) {
      this.tagServiceDataList = [];
    }
  };

  @observable
  tagReferenceTypeList: Array<{ name: string; value: string; isDefault: boolean }> = [];

  //태그 참조 유형 목록
  @action
  getTagReferenceTypeList = async (tagServiceId?: string) => {
    try {
      const { list } = await tagServiceApis.getTagReferenceTypeList(
        tagServiceId ? tagServiceId : "ip"
      );

      this.tagReferenceTypeList = list
        .filter((info: any) => info.referenceRegisterType !== "manager")
        .map((info: any) => ({
          name: info.tagReferenceTypeName,
          value: info.tagReferenceType,
          isDefault: info.isDefault === "1",
          tagReferenceTypeOrderNo: info.tagReferenceTypeOrderNo,
          tagServiceDataId: info.tagServiceDataId,
          tagServiceId: info.tagServiceId,
        }));
    } catch ({ data }) {
      return data;
    }
  };

  @observable
  tagValueTypeList: Array<{
    name: string;
    value: string;
    isDefault: boolean;
    token: string;
    size: number;
    tagValueTypeRegex: string;
    tagServiceId: string;
  }> = [];

  //태그 주소 유형 목록
  @action
  getTagValueTypes = async () => {
    try {
      const { list } = await tagServiceApis.getTagValueTypes();
      this.tagValueTypeList = list.map((info: any) => ({
        name: info.tagValueTypeName,
        value: info.tagValueType,
        isDefault: info.isDefault === "1",
        token: info.tagValueTypeToken,
        size: info.tagValueTypeTokenSize,
        tagValueTypeOrderNo: info.tagValueTypeOrderNo,
        tagValueTypeRegex: info.tagValueTypeRegex,
        tagServiceId: info.tagServiceId,
      }));
    } catch ({ data }) {
      this.tagValueTypeList = [];
      return data;
    }
  };

  //태그 데이터 다중 등록/수정
  @action
  updateTagServiceDatas = async (data: Array<object>) => {
    try {
      await tagServiceApis.updateTagServiceDatas(data);
    } catch ({ data }) {
      return data;
    }
  };

  @action
  openIpTagModal = async (params: any) => {
    await Promise.all([
      this.getTagReferenceTypeList(),
      this.getTagServiceDataList(params),
      this.getTagValueTypes(),
    ]);

    //태그 주소 유형 목록들의 정규식 정보로 ip를 체크해서 서비스 목록을 구분(tagServiceId: "ip, mac", tagValueType : "ipv6, ipv4, mac")
    const typeInfo = this.tagValueTypeList.find(info => {
      const re = new RegExp(info.tagValueTypeRegex.replace(/\\\n/gi, `/`));
      return re.test(params.tagValue);
    });

    const isUpdate =
      this.tagServiceDataList &&
      this.tagServiceDataList.some(({ tagReferenceType }) => tagReferenceType === "general");

    ModalStore.createNewModal({
      isUseCustomModal: true,
      children: IpTagModal,
      tagReferenceTypeList: this.tagReferenceTypeList,
      tagValueTypeList: this.tagValueTypeList,
      tagServiceDataList: this.tagServiceDataList
        ? this.tagServiceDataList.filter((info: any) => info.isGlobal === "0")
        : [],
      updateTagServiceDatas: this.updateTagServiceDatas,
      isUpdate: isUpdate,
      params,
      typeInfo,
    });
  };
}
