import { action } from "mobx";
import { pDetectFilterApis } from "@src/apis";

class DefenceStore {
  @action
  getDefenceInfo = async (info) => {
    /**
     * totalCount '0'일때: '즉시 방어 처리' pDetectFilterApis.createDetectDefense
     * totalCount '1'이상이면서 isActive '0' 일때:'방화벽 설정' (/detectdefense/isactive) 이때는 isActive를 '1'로 바꾸고 호출한다.
     *                                        '방화벽설정' pDetectFilterApis.updateDetectDefenseByIsActive
     * totalCount '1'이상이면서 isActive '1' 일때:'방어 실행중' 버튼으로 노출.
     */
    try {
      let params = {
        serverId: info.serverId,
        detectDefenseIp: info.attackIp && info.attackIp.split(":")[0],
      };
      const { list, totalCount } = await pDetectFilterApis.getDetectPackageIsActive(params);
      totalCount > 0 && info.setInfo("detectDefenseId", list[0].detectDefenseId);
      totalCount > 0 && info.setInfo("isDefence", list[0].isActive);
    } catch (err) {}
  };

  @action
  createDefence = async (
    defenseConfigFilterType,
    serverId,
    detectDefenseIp,
    detectDefenseLogId,
    filterIds,
    detectRatio,
    defenseDescription
  ) => {
    try {
      // 즉시차단은 defenseConfigFilterType의 값과 무관하게 항상 "log"라는 코드로 등록되어야 한다.
      const _defenseConfigFilterType = "log";
      const params = {
        serverId,
        detectDefenseIp: detectDefenseIp.split(":")[0],
        detectDefenseLogId,
        filterIds,
        defenseDescription,
      };
      if (defenseConfigFilterType === "package") {
        params[detectRatio] = detectRatio;
      }
      return await pDetectFilterApis.createDetectDefense(_defenseConfigFilterType, params);
    } catch ({ data }) {
      throw data;
    }
  };

  @action
  updateDefence = async (serverId, detectDefenseId, isActive) => {
    let params = {
      serverId,
      detectDefenseId,
      isActive,
    };
    try {
      await pDetectFilterApis.updateDetectDefenseByIsActive(params);
    } catch ({ data }) {
      return data;
    }
  };

  @action
  getWafDefenceInfo = async (info) => {
    try {
      let params = {
        detectDefenseIp: info.logClientIp,
      };

      const { list, totalCount } = await pDetectFilterApis.searchDetectDefenseIp(params);

      totalCount > 0 && info.setInfo("detectDefenseId", list[0].customDefenseWafId);
      totalCount > 0 && info.setInfo("isDefence", list[0].isActive);
    } catch (err) {}
  };

  @action
  createWafDefence = async (
    { detectDefenseIp, defenseDescription, expiredDays, isCustomExpiredDay },
    detectDefenseType = "manual",
    customDefenseFilterType = "log",
    isActive = "1"
  ) => {
    try {
      const params = {
        detectDefenseIp,
        defenseDescription,
        detectDefenseType,
        customDefenseFilterType,
        isActive,
        expiredDays,
        isCustomExpiredDay,
      };
      await pDetectFilterApis.createCustomDefenseWaf(params);
    } catch ({ data }) {
      throw data;
    }
  };
}

export default new DefenceStore();
