import { observable } from "mobx";

export default class ResourceEventElementOptionModel {
  constructor(param) {
    const filterElementName = param.filterEventElementName;
    this.name = filterElementName || "";

    this.value = {
      elementAttributes: param.elementAttributes,
      filterEventElementId: param.filterEventElementId,
      filterElementName,
      parrentDepth: param.parrentDepth,
      parrentFilterEventElementId: param.parrentFilterEventElementId,
      filterEventElementRepeatSize: param.filterEventElementRepeatSize,
      ////////
      resourceFilterElementId: param.resourceFilterElementId,
      resourceMetaId: param.resourceMetaId,
      resourceFilterElementOrderNo: param.resourceFilterElementOrderNo,
      resourceFilterId: param.resourceFilterId,
      resourceFilterElementSelectorList: param.resourceFilterElementSelectorList,
      resourceId: param.resourceId,
      dataType: param.dataType,
    };
  }

  @observable
  name: string;

  @observable
  value: {
    elementAttributes: Array<any>;
    filterEventElementId: number;
    filterElementName: string;
    parrentDepth: number;
    parrentFilterEventElementId: number;
    filterEventElementRepeatSize: number;
    ////////
    resourceFilterElementId: string;
    resourceMetaId: number;
    resourceFilterElementOrderNo: number;
    resourceFilterId: number;
    resourceFilterElementSelectorList: Array<any>;
    resourceId: string;
    dataType: string;
  };
}
