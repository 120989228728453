import { languageSet } from "@qubit-utill/src";

import { MSGEVENTDATA, URLPATH } from "@src/constants/code";

export const CSVINFO = {
  FILTERDETECT_SYS: {
    head: [
      languageSet("분류"),
      languageSet("위험도"),
      languageSet("필터명"),
      languageSet("그룹"),
      languageSet("운영체제"),
      languageSet("시스템"),
      languageSet("호스트명"),
      languageSet("심각도"),
      languageSet("채널"),
      languageSet("이벤트타입"),
      languageSet("우선순위"),
      `${languageSet("본문")} (${MSGEVENTDATA})`,
      languageSet("주요개체"),
      languageSet("날짜"),
    ],
    infoKey: [
      "classficationText",
      "stepText",
      "attackText",
      "getServerGroup",
      "typeos",
      "serverIpAddr",
      "hostName",
      "logSeverity",
      "filterChannelName",
      "eventType",
      "priority",
      "msgEventDataForCSV",
      "importantTextForCSV",
      "withMicroSecondsLogCreateDate",
    ],
  },
  FILTERDETECT_APP_DEFAULT: {
    head: [
      languageSet("위험도"),
      languageSet("필터명"),
      languageSet("그룹"),
      languageSet("운영체제"),
      languageSet("시스템"),
      languageSet("호스트명"),
      languageSet("닉네임"),
      languageSet("태그"),
      languageSet("본문"),
      languageSet("날짜"),
    ],
    infoKey: [
      "stepText",
      "attackText",
      "getServerGroup",
      "typeos",
      "serverIpAddr",
      "hostName",
      "serverName",
      "logTag",
      "msgEventDataForCSV",
      "withMicroSecondsLogCreateDate",
    ],
  },
  FILTERDETECT_APP_RESOURCE_BASIC: {
    head: [
      languageSet("위험도"),
      languageSet("필터명"),
      languageSet("그룹"),
      languageSet("운영체제"),
      languageSet("시스템"),
      languageSet("호스트명"),
      languageSet("닉네임"),
      languageSet("날짜"),
    ],
    infoKey: [
      "stepText",
      "attackText",
      "getServerGroup",
      "typeos",
      "serverIpAddr",
      "hostName",
      "serverName",
      "withMicroSecondsLogCreateDate",
    ],
  },
  FILTERDETECT_APP_RESOURCE: {
    head: [],
    infoKey: [],
  },
  FILTERDETECT_WEB: {
    head: [
      languageSet("분류"),
      languageSet("코드"),
      languageSet("위험도"),
      languageSet("필터명"),
      languageSet("그룹"),
      languageSet("운영체제"),
      languageSet("시스템"),
      languageSet("국가"),
      languageSet("공격자"),
      languageSet("상태값"),
      languageSet("호스트"),
      languageSet("메소드"),
      languageSet("경로"),
      languageSet("쿼리_요청본문"),
      languageSet("요청"),
      languageSet("응답"),
      languageSet("동일로그"),
      languageSet("유출정보"),
      languageSet("유형"),
      languageSet("날짜"),
    ],
    infoKey: [
      "attackText",
      "OWASPText",
      "stepText",
      "attackPurposeText",
      "getServerGroup",
      "typeos",
      "serverIpAddr",
      "attackNationForCSV",
      "attackIp",
      "logStatus",
      "logHost",
      "logMethod",
      "logUri",
      "queryPostBodyForPopover",
      "getLogRequestContentLength",
      "getLogResponseContentLength",
      "getLogConcurrentLogCount",
      "isWebExtendsDetectFlag",
      "logBlockedTextForCSV",
      "logCreateDate",
    ],
  },
  FILTERDETECT_NET: {
    head: [
      languageSet("분류"),
      languageSet("위험도"),
      languageSet("필터명"),
      languageSet("그룹"),
      languageSet("운영체제"),
      languageSet("시스템"),
      languageSet("호스트명"),
      languageSet("구분"),
      languageSet("심각도"),
      languageSet("우선순위"),
      `${languageSet("본문")} (msg)`,
      languageSet("날짜"),
    ],
    infoKey: [
      "classficationText",
      "stepText",
      "attackText",
      "serverGroupName",
      "typeos",
      "serverIpAddr",
      "hostName",
      "osFamily",
      "logSeverity",
      "priority",
      "logMessage",
      "withMicroSecondsLogCreateDate",
    ],
  },
  EXTRACTION_IP: {
    head: [
      languageSet("서비스"),
      `${languageSet("국가")} (${languageSet("지역")})`,
      languageSet("추출IP주소"),
      languageSet("횟수"),
      languageSet("날짜"),
    ],
    infoKey: ["service", "nationName", "extracionIP", "detectCount", "registerDate"],
  },
  STATISTIC_BOXPLOT: {
    head: [
      languageSet("이탈값"),
      languageSet("위험도"),
      languageSet("필터"),
      languageSet("분류"),
      languageSet("그룹"),
      languageSet("운영체제"),
      languageSet("시스템"),
      languageSet("호스트명"),
      languageSet("요일"),
      languageSet("날짜"),
    ],
    infoKey: [
      "detectCount",
      "getRiskLevelCodeName",
      "getFilterName",
      "getFilterCategoryName",
      "getServerGroup",
      "getServerOs",
      "getServerIp",
      "getHostName",
      "detectDataWeekend",
      "detectDate",
    ],
  },
  REPORT_CORRELATION: {
    head: [
      languageSet("공격확률"),
      languageSet("필터"),
      languageSet("분류"),
      languageSet("그룹"),
      languageSet("운영체제"),
      languageSet("시스템"),
      languageSet("호스트명"),
      languageSet("횟수"),
    ],
    infoKey: [
      "attackPercent",
      "getFilterName",
      "getFilterCategoryName",
      "getServerGroup",
      "getServerOs",
      "getServerIp",
      "getHostName",
      "detectCount",
    ],
  },
  STATISTIC_HEATMAP: {
    head: [
      languageSet("위험도"),
      languageSet("필터"),
      languageSet("분류"),
      languageSet("그룹"),
      languageSet("운영체제"),
      languageSet("시스템"),
      languageSet("호스트명"),
      languageSet("히트맵_정보"),
      languageSet("횟수"),
    ],
    infoKey: [
      "getRiskLevelCodeName",
      "getFilterName",
      "getFilterCategoryName",
      "getServerGroup",
      "getServerOs",
      "getServerIp",
      "getHostName",
      "actionDetectStatsValue",
      "detectCount",
    ],
  },
  STATISTIC_IP: {
    head: [
      languageSet("지역"),
      languageSet("IP주소"),
      languageSet("횟수"),
      languageSet("상관분석"),
      languageSet("시스템"),
      languageSet("호스트보안"),
      languageSet("웹"),
      languageSet("네트워크"),
      languageSet("계정탈취"),
      languageSet("웹방화벽"),
      languageSet("데이터유출"),
    ],
    infoKey: [
      "getNation",
      "attackIp",
      "detectCount",
      "packageDetectCount",
      "systemDetectCount",
      "edrDetectCount",
      "webDetectCount",
      "networkDetectCount",
      "dosDetectCount",
      "wafDetectCount",
      "webExtendsDataBreachDetectCount",
    ],
  },
  REPORT_SYS_NET_ATTACK: {
    head: [
      languageSet("위험도"),
      languageSet("필터"),
      languageSet("분류"),
      languageSet("그룹"),
      languageSet("운영체제"),
      languageSet("시스템"),
      languageSet("호스트명"),
      languageSet("지역"),
      languageSet("공격자"),
      languageSet("횟수"),
    ],
    infoKey: [
      "getRiskLevelCodeName",
      "getFilterName",
      "getFilterCategoryName",
      "getServerGroup",
      "getServerOs",
      "getServerIp",
      "getHostName",
      "getNation",
      "attackIp",
      "detectCount",
    ],
  },
  REPORT_SYS_NET_FILTER: {
    head: [languageSet("위험도"), languageSet("필터"), languageSet("분류"), languageSet("횟수")],
    infoKey: ["getRiskLevelCodeName", "getFilterName", "getFilterCategoryName", "detectCount"],
  },
  REPORT_APP_DEFAULT: {
    head: [
      languageSet("위험도"),
      languageSet("필터"),
      languageSet("그룹"),
      languageSet("운영체제"),
      languageSet("시스템"),
      languageSet("호스트명"),
      languageSet("태그"),
      languageSet("횟수"),
    ],
    infoKey: [
      "getRiskLevelCodeName",
      "getFilterName",
      "getServerGroup",
      "getServerOs",
      "getServerIp",
      "getHostName",
      "logTag",
      "detectCount",
    ],
  },
  REPORT_APP_RESOURCE: {
    head: [
      languageSet("위험도"),
      languageSet("필터"),
      languageSet("그룹"),
      languageSet("운영체제"),
      languageSet("시스템"),
      languageSet("호스트명"),
      languageSet("횟수"),
    ],
    infoKey: [
      "getRiskLevelCodeName",
      "getFilterName",
      "getServerGroup",
      "getServerOs",
      "getServerIp",
      "getHostName",
      "detectCount",
    ],
  },
  REPORT_WEB_WEBFIREWALL_ATTACK: {
    head: [
      languageSet("필터분류"),
      languageSet("위험도"),
      languageSet("필터명"),
      languageSet("보고서_분류"),
      languageSet("상태값"),
      languageSet("그룹"),
      "OS",
      languageSet("시스템"),
      languageSet("호스트명"),
      languageSet("국가"),
      languageSet("공격자"),
      languageSet("접속수"),
    ],
    infoKey: [
      "getFilterAttackType",
      "getRiskLevelCodeName",
      "getFilterName",
      "getFilterCategoryGroupName",
      "detectStatusCode",
      "getServerGroup",
      "getServerOs",
      "getServerIp",
      "getHostName",
      "getNation",
      "attackIp",
      "detectCount",
    ],
  },
  REPORT_WEB_WEBFIREWALL_HOST: {
    head: [
      languageSet("필터분류"),
      languageSet("위험도"),
      languageSet("필터명"),
      languageSet("보고서_분류"),
      languageSet("상태값"),
      `${languageSet("호스트")}(${languageSet("도메인")})`,
      languageSet("국가"),
      languageSet("공격자"),
      languageSet("접속수"),
    ],
    infoKey: [
      "getFilterAttackType",
      "getRiskLevelCodeName",
      "getFilterName",
      "getFilterCategoryGroupName",
      "detectStatusCode",
      "detectHost",
      "getNation",
      "attackIp",
      "detectCount",
    ],
  },
  REPORT_WEB_WEBFIREWALL_URL: {
    head: [URLPATH, `${languageSet("호스트")}(${languageSet("도메인")})`, languageSet("접속수")],
    infoKey: ["detectURL", "detectHost", "detectCount"],
  },
  REPORT_HOSTSECURITY_ATTACK: {
    head: [
      languageSet("위험도"),
      languageSet("필터"),
      languageSet("분류"),
      languageSet("그룹"),
      languageSet("운영체제"),
      languageSet("시스템"),
      languageSet("호스트명"),
      languageSet("지역"),
      languageSet("공격자"),
      languageSet("횟수"),
    ],
    infoKey: [
      "getRiskLevelCodeName",
      "getFilterName",
      "getFilterCategoryName",
      "getServerGroup",
      "getServerOs",
      "getServerIp",
      "getHostName",
      "getNation",
      "attackIp",
      "detectCount",
    ],
  },
  REPORT_HOSTSECURITY_FILTER: {
    head: [languageSet("위험도"), languageSet("필터"), languageSet("분류"), languageSet("횟수")],
    infoKey: ["getRiskLevelCodeName", "getFilterName", "getFilterCategoryName", "detectCount"],
  },

  STATISTIC_UPLOAD: {
    head: [
      languageSet("그룹"),
      languageSet("운영체제"),
      languageSet("시스템"),
      languageSet("호스트명"),
      `${languageSet("호스트보안로그_업로드")} (etcText)`,
      `${languageSet("응용프로그램_로그_업로드")} (etcText)`,
      `${languageSet("웹로그_업로드")} (etcText)`,
      `${languageSet("네트워크로그_업로드")} (etcText)`,
      `${languageSet("총_로그업로드")} (etcText)`,
    ],
    infoKey: [
      "getServerGroup",
      "getServerOs",
      "getServerIp",
      "getHostName",
      "sysLogUploadBytesText",
      "applicationLogUploadBytesText",
      "webLogUploadBytesText",
      "networkLogUploadBytesText",
      "totalLogUploadBytesText",
    ],
  },
  STATISTIC_UPLOAD_COUNT: {
    head: [
      languageSet("그룹"),
      languageSet("운영체제"),
      languageSet("시스템"),
      languageSet("호스트명"),
      languageSet("호스트보안로그_업로드_수"),
      languageSet("응용프로그램_로그_업로드_수"),
      languageSet("웹로그_업로드_수"),
      languageSet("네트워크로그_업로드_수"),
      languageSet("총_로그업로드_수"),
    ],
    infoKey: [
      "getServerGroup",
      "getServerOs",
      "getServerIp",
      "getHostName",
      "getSystemCount",
      "getAppCount",
      "getWebCount",
      "getNetCount",
      "getTotalLogUploadCount",
    ],
  },
  SECURITYDETECT_EXTORTACCOUNT: {
    head: [
      languageSet("분류"),
      languageSet("필터명"),
      languageSet("지역"),
      languageSet("공격자"),
      languageSet("호스트"),
      languageSet("경로"),
      languageSet("쿠키"),
      languageSet("레퍼러"),
      languageSet("사용자에이전트"),
      languageSet("아이디"),
      languageSet("성공"),
      languageSet("실패"),
      languageSet("횟수"),
      languageSet("날짜"),
    ],
    infoKey: [
      "categoryName",
      "filterName",
      "attackNation",
      "attackIp",
      "host",
      "path",
      "cookieCount",
      "refererCount",
      "userAgentCount",
      "loginIdCount",
      "loginSuccessCount",
      "loginFailCount",
      "logCount",
      "registerDate",
    ],
  },
  SECURITYDETECT_CORRELATION: {
    head: [
      languageSet("분류"),
      languageSet("필터명"),
      languageSet("공격확률"),
      languageSet("운영체제"),
      languageSet("시스템"),
      languageSet("호스트명"),
      languageSet("탐지필터"),
      languageSet("날짜"),
    ],
    infoKey: [
      "getCategoryName",
      "getFilterName",
      "attackProbability",
      "serverOs",
      "getServerIp",
      "getHostname",
      "tagListName",
      "updateDate",
    ],
  },
  FILTERDETECTWEBFIREWALL: {
    head: [
      languageSet("위험도"),
      languageSet("분류"),
      languageSet("코드"),
      languageSet("필터명"),
      languageSet("그룹"),
      languageSet("운영체제"),
      languageSet("시스템"),
      languageSet("국가"),
      languageSet("공격자"),
      languageSet("상태값"),
      languageSet("호스트"),
      languageSet("메소드"),
      languageSet("경로"),
      languageSet("쿼리_요청본문"),
      languageSet("요청"),
      languageSet("응답"),
      languageSet("동일로그"),
      languageSet("유형"),
      languageSet("유출정보"),
      languageSet("날짜"),
    ],
    infoKey: [
      "stepText",
      "attackText",
      "OWASPText",
      "attackPurposeText",
      "serverGroup",
      "typeos",
      "serverIpAddr",
      "attackNationForCSV",
      "attackIp",
      "logStatus",
      "logHost",
      "logMethod",
      "logUri",
      "queryPostBodyForPopover",
      "getLogRequestContentLength",
      "getLogResponseContentLength",
      "getLogConcurrentLogCount",
      "logBlockedTextForCSV",
      "isWebExtendsDetectFlag",
      "logCreateDate",
    ],
  },
  SECURITYDETECT_WEBSITEFORGERY: {
    head: [
      languageSet("필터명"),
      languageSet("운영체제"),
      languageSet("시스템"),
      languageSet("호스트명"),
      languageSet("파일"),
      languageSet("날짜"),
    ],
    infoKey: ["getFilterName", "serverOs", "getServerIp", "getHostname", "getUrl", "withMicroSecondsLogCreateDate"],
  },
  SYSTEM: {
    head: [
      languageSet("동작"),
      languageSet("에이전트"),
      languageSet("에이전트버전"),
      languageSet("그룹"),
      languageSet("시스템"),
      languageSet("호스트명"),
      languageSet("별칭"),
      languageSet("운영체제버전"),
      languageSet("업데이트버전"),
      languageSet("업데이트일"),
      `H${languageSet("유형")}`,
      languageSet("대상for서버"),
      languageSet("전체로그"),
      languageSet("등록방법"),
      languageSet("날짜"),
    ],
    infoKey: [
      "agentStatusText",
      "isAgentActiveText",
      "agentVersionText",
      "serverGroupName",
      "serverIp",
      "hostname",
      "serverName",
      "osReleaseName",
      "osLatestVersion",
      "updateDate",
      "blockModeText",
      "logCollectForCSV",
      "isSaveFullLogName",
      "registerWay",
      "registerDate",
    ],
  },
  SYSTEM_LOGFORENSIC: {
    head: [
      languageSet("그룹"),
      languageSet("시스템"),
      languageSet("호스트명"),
      languageSet("별칭"),
      languageSet("운영체제버전"),
      languageSet("업데이트버전"),
      languageSet("업데이트일"),
      `H${languageSet("유형")}`,
      languageSet("대상for서버"),
      languageSet("등록방법"),
      languageSet("날짜"),
    ],
    infoKey: [
      "serverGroupName",
      "serverIp",
      "hostname",
      "serverName",
      "osReleaseName",
      "osLatestVersion",
      "updateDate",
      "blockModeText",
      "logCollectForCSV",
      "registerWay",
      "registerDate",
    ],
  },
  RESOURCEMONITORING_HOST: {
    head: [
      languageSet("에이전트상태"),
      languageSet("그룹"),
      languageSet("운영체제"),
      languageSet("시스템"),
      languageSet("호스트명"),
      languageSet("별칭"),
      "CPU",
      "Memory",
      "HDD",
      "Disk Active Time",
      "Network Traffic Received",
      "Network Traffic Sent",
      languageSet("날짜"),
    ],
    infoKey: [
      "isAgentActiveText",
      "getServerGroup",
      "typeos",
      "serverIp",
      "hostname",
      "getServerName",
      "getCpuUsage",
      "getMemoryUsage",
      "getDiskUsage",
      "getDiskActiveTime",
      "getInboundTrafficText",
      "getOutboundTrafficText",
      "serverResourceUpdateDate",
    ],
  },
  TOTALLOG_NET: {
    head: [
      languageSet("그룹"),
      languageSet("운영체제"),
      languageSet("시스템"),
      languageSet("호스트명"),
      languageSet("구분"),
      languageSet("심각도"),
      languageSet("우선순위"),
      `${languageSet("본문")} (msg)`,
      languageSet("날짜"),
    ],
    infoKey: [
      "serverGroupName",
      "typeos",
      "totalLogIp",
      "hostName",
      "getOsFamily",
      "logSeverity",
      "priority",
      "logMessage",
      "withMicroSecondsLogCreateDate",
    ],
  },
  TOTALLOG_SYS: {
    head: [
      languageSet("그룹"),
      languageSet("운영체제"),
      languageSet("시스템"),
      languageSet("호스트명"),
      languageSet("심각도"),
      languageSet("채널"),
      languageSet("이벤트타입"),
      languageSet("우선순위"),
      `${languageSet("본문")} (${MSGEVENTDATA})`,
      languageSet("주요개체"),
      languageSet("날짜"),
    ],
    infoKey: [
      "getServerGroup",
      "typeos",
      "totalLogIp",
      "hostName",
      "logSeverity",
      "filterChannelName",
      "eventType",
      "priority",
      "msgEventDataForCSV",
      "importantTextForCSV",
      "withMicroSecondsLogCreateDate",
    ],
  },
  TOTALLOG_APP_DEFAULT: {
    head: [
      languageSet("그룹"),
      languageSet("운영체제"),
      languageSet("시스템"),
      languageSet("호스트명"),
      languageSet("닉네임"),
      languageSet("태그"),
      languageSet("본문"),
      languageSet("날짜"),
    ],
    infoKey: [
      "getServerGroup",
      "typeos",
      "totalLogIp",
      "hostName",
      "serverName",
      "logTag",
      "msgEventDataForCSV",
      "withMicroSecondsLogCreateDate",
    ],
  },
  TOTALLOG_APP_RESOURCE_BASIC: {
    head: [
      languageSet("그룹"),
      languageSet("운영체제"),
      languageSet("시스템"),
      languageSet("호스트명"),
      languageSet("닉네임"),
      languageSet("날짜"),
    ],
    infoKey: ["getServerGroup", "typeos", "totalLogIp", "hostName", "serverName", "withMicroSecondsLogCreateDate"],
  },
  TOTALLOG_APP_RESOURCE: { head: [], infoKey: [] },
  TOTALLOG_WEBFIREWALL: {
    head: [
      languageSet("그룹"),
      languageSet("운영체제"),
      languageSet("시스템"),
      languageSet("국가"),
      languageSet("대상_시스템"),
      languageSet("상태값"),
      languageSet("호스트"),
      languageSet("메소드"),
      languageSet("경로"),
      languageSet("쿼리_요청본문"),
      languageSet("요청"),
      languageSet("응답"),
      languageSet("동일로그"),
      languageSet("유형"),
      languageSet("날짜"),
    ],
    infoKey: [
      "getServerGroup",
      "typeos",
      "totalLogIp",
      "attackNationForCSV",
      "logClientIp",
      "logStatus",
      "logHost",
      "logMethod",
      "logUri",
      "queryPostBodyForPopover",
      "getLogRequestContentLength",
      "getLogResponseContentLength",
      "getLogConcurrentLogCount",
      "logBlockedTextForCSV",
      "logCreateDate",
    ],
  },
  TOTALLOG_WEB: {
    head: [
      languageSet("그룹"),
      languageSet("운영체제"),
      languageSet("시스템"),
      languageSet("국가"),
      languageSet("대상_시스템"),
      languageSet("상태값"),
      languageSet("호스트"),
      languageSet("메소드"),
      languageSet("경로"),
      languageSet("쿼리_요청본문"),
      languageSet("요청"),
      languageSet("응답"),
      languageSet("동일로그"),
      languageSet("유형"),
      languageSet("날짜"),
    ],
    infoKey: [
      "getServerGroup",
      "typeos",
      "totalLogIp",
      "attackNationForCSV",
      "logClientIp",
      "logStatus",
      "logHost",
      "logMethod",
      "logUri",
      "queryPostBodyForPopover",
      "getLogRequestContentLength",
      "getLogResponseContentLength",
      "getLogConcurrentLogCount",
      "logBlockedTextForCSV",
      "logCreateDate",
    ],
  },
  EXTRACTION_URL: {
    head: [
      languageSet("서비스"),
      languageSet("상태값"),
      languageSet("호스트"),
      languageSet("경로"),
      languageSet("최대_요청"),
      languageSet("최대_응답"),
      languageSet("횟수"),
      languageSet("날짜"),
    ],
    infoKey: [
      "service",
      "detectStatusCode",
      "detectHost",
      "detectURL",
      "maxRequestContentLength",
      "maxResponseContentLength",
      "detectCount",
      "detectDate",
    ],
  },
  REPORT_DATABREACH: {
    head: [
      languageSet("위험도"),
      languageSet("필터명"),
      languageSet("상태"),
      languageSet("분류"),
      languageSet("그룹"),
      languageSet("운영체제"),
      languageSet("시스템"),
      languageSet("호스트명"),
      languageSet("지역"),
      languageSet("공격자"),
      languageSet("횟수"),
    ],
    infoKey: [
      "getRiskLevelCodeName",
      "getFilterName",
      "detectStatusCode",
      "getFilterCategoryName",
      "getServerGroup",
      "getServerOs",
      "getServerIp",
      "getHostName",
      "getNation",
      "attackIp",
      "detectCount",
    ],
  },
  SECURITYDETECT_DATABREACH: {
    head: [
      languageSet("분류"),
      languageSet("위험도"),
      languageSet("필터명"),
      languageSet("그룹"),
      languageSet("시스템"),
      languageSet("공격자"),
      languageSet("상태값"),
      languageSet("호스트"),
      languageSet("경로"),
      languageSet("쿼리"),
      languageSet("요청"),
      languageSet("응답"),
      languageSet("날짜"),
    ],
    infoKey: [
      "getCategoryNameText",
      "stepText",
      "webExtendsFilterNameText",
      "getServerGroup",
      "serverIpAddr",
      "logClientIp",
      "getLogStatus",
      "getLogHost",
      "getLogUri",
      "getLogQueryText",
      "getLogRequestContentLength",
      "getLogResponseContentLength",
      "getLogCreateDate",
    ],
  },
  MITREREPORTTOPLIST: {
    head: [languageSet("도메인"), languageSet("플랫폼"), "ID", languageSet("전술"), languageSet("기술"), languageSet("횟수")],
    infoKey: ["mitreDomainId", "mitrePlatformEntryName", "mitreID", "mitreTacticsEntryName", "mitreTechniqueName", "detectCount"],
  },
  MITRE_DETECT_LIST: {
    head: ["ID", languageSet("플랫폼"), languageSet("전술"), languageSet("기술"), languageSet("횟수"), languageSet("날짜")],
    infoKey: ["mitreID", "mitrePlatformEntryText", "mitreTacticsEntryText", "mitreTechniqueName", "detectCount", "detectDate"],
  },

  STATISTIC_LOGIN_DAY: {
    head: ["NO", languageSet("시간대"), languageSet("합계"), languageSet("성공"), languageSet("실패"), "ID"],
    infoKey: ["title", "detectHour", "detectCount", "detectSuccessCount", "detectFailCount", "detectLoginIdCount"],
  },
  STATISTIC_LOGIN: {
    head: [
      "NO",
      languageSet("시간대"),
      `${languageSet("합계")} ${languageSet("평균")}`,
      `${languageSet("합계")} ${languageSet("최대")}`,
      `${languageSet("합계")} ${languageSet("최소")}`,
      `${languageSet("성공")} ${languageSet("평균")}`,
      `${languageSet("성공")} ${languageSet("최대")}`,
      `${languageSet("성공")} ${languageSet("최소")}`,
      `${languageSet("실패")} ${languageSet("평균")}`,
      `${languageSet("실패")} ${languageSet("최대")}`,
      `${languageSet("실패")} ${languageSet("최소")}`,
      `ID ${languageSet("평균")}`,
      `ID ${languageSet("최대")}`,
      `ID ${languageSet("최소")}`,
    ],
    infoKey: [
      "title",
      "detectHour",
      "detectAvgCount",
      "detectMaxCount",
      "detectMinCount",
      "detectAvgSuccessCount",
      "detectMaxSuccessCount",
      "detectMinSuccessCount",
      "detectAvgFailCount",
      "detectMaxFailCount",
      "detectMinFailCount",
      "detectAvgLoginIdCount",
      "detectMaxLoginIdCount",
      "detectMinLoginIdCount",
    ],
  },

  EXTRACTION_LOGIN: {
    head: [
      languageSet("지역"),
      languageSet("시스템"),
      languageSet("호스트"),
      languageSet("경로"),
      languageSet("쿠키"),
      languageSet("레퍼러"),
      languageSet("사용자에이전트"),
      "ID",
      languageSet("응답"),
      languageSet("횟수"),
      languageSet("날짜"),
    ],
    infoKey: [
      "getNation",
      "clientIp",
      "logHost",
      "logPath",
      "logCookie",
      "logReferer",
      "logUserAgent",
      "logLoginId",
      "loginStatusTypeName",
      "count",
      "registerDate",
    ],
  },
  REPORT_EXTORTACCOUNT: {
    head: [
      languageSet("분류"),
      languageSet("필터"),
      languageSet("호스트"),
      languageSet("경로"),
      languageSet("지역"),
      languageSet("공격자"),
      "ID",
      languageSet("횟수"),
    ],
    infoKey: [
      "getFilterTypeName",
      "getFilterName",
      "getDetectHosts",
      "getDetectPath",
      "getNation",
      "attackIp",
      "detectLoginIdCount",
      "formatDetectCount",
    ],
  },

  MLDETECT: {
    head: [
      languageSet("분류"),
      languageSet("공격확률"),
      languageSet("그룹"),
      languageSet("운영체제"),
      languageSet("시스템"),
      languageSet("지역"),
      languageSet("공격자"),
      languageSet("상태값"),
      languageSet("호스트"),
      languageSet("메소드"),
      languageSet("경로"),
      languageSet("쿼리_요청본문"),
      languageSet("요청"),
      languageSet("응답"),
      languageSet("날짜"),
    ],
    infoKey: [
      "attackTypeText",
      "attackPercentText",
      "getServerGroup",
      "typeos",
      "totalLogIp",
      "attackNationForCSV",
      "logClientIp",
      "logStatus",
      "logHost",
      "logMethod",
      "logUri",
      "queryPostBodyForPopover",
      "getLogRequestContentLength",
      "getLogResponseContentLength",
      "logCreateDate",
    ],
  },

  DEFENCEIP_WEBFIRE: {
    head: [
      languageSet("분류"),
      languageSet("국가"),
      languageSet("공격자"),
      languageSet("설명"),
      `${languageSet("차단기간")}(${languageSet("일_며칠")})`,
      languageSet("등록일"),
      languageSet("만료일"),
    ],
    infoKey: [
      "defenceSeparate",
      "getDefenceNation",
      "defenceIp",
      "defenseDescription",
      "getExpiredDays",
      "registerDate",
      "expiredDate",
    ],
  },

  DEFENSE_IP_SYSTEM: {
    head: [
      languageSet("분류"),
      languageSet("국가"),
      languageSet("공격자"),
      languageSet("설명"),
      languageSet("운영체제"),
      languageSet("시스템"),
      languageSet("호스트명"),
      languageSet("날짜"),
    ],
    infoKey: [
      "defenceSeparate",
      "getDefenceNation",
      "defenceIp",
      "defenseDescription",
      "typeos",
      "serverIp",
      "hostName",
      "registerDate",
    ],
  },

  GROUP_USERLOG: {
    head: [
      languageSet("날짜"),
      languageSet("그룹"),
      languageSet("분류"),
      languageSet("유형"),
      languageSet("상세"),
      languageSet("멤버"),
    ],
    infoKey: [
      "registerDate",
      "userActionCategoryParentGroupName",
      "userActionCategoryGroupName",
      "userActionCategoryName",
      "actionDetail",
      "userName",
    ],
  },

  LOGCOUNT_BY_CHANNEL: {
    infoKey: [
      "typeos",
      "serverIp",
      "filterChannelName",
      "dayOfWeekData_1",
      "dayOfWeekData_2",
      "dayOfWeekData_3",
      "dayOfWeekData_4",
      "dayOfWeekData_5",
      "dayOfWeekData_6",
      "dayOfWeekData_7",
      "logUploadCountAverage",
    ],
  },
};
