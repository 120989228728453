import { observable, computed } from "mobx";

import { Common_Utils, dateUtil, formatUtils, logUtils } from "@qubit-utill/src";

import { WebLogModel } from "@src/common/models";
import { CSVINFO } from "@src/constants";

class WebFilterDetectionModel extends WebLogModel {
  constructor({ info, CodeStore, csvInfoKey, issueServiceType }) {
    super(info);
    this.CodeStore = CodeStore;
    this.csvInfoKey = csvInfoKey || "";
    this.issueServiceType = issueServiceType || "";

    //기존 로그에는 webExtendsFilterType 에 대한 지정값이 없어서 이전로그에 분석정보를 보기 위하여 null일 경우에 모드시큐리티 탭에서 표기
    if (info) {
      this.modeSecurityLogInfo =
        info.detectList &&
        info.detectList.filter(
          ({ webExtendsFilterType }) => webExtendsFilterType === "modsecurityfilter" || webExtendsFilterType === null
        );

      this.userFilterLogInfo =
        info.webExtendsDetectList &&
        info.webExtendsDetectList.filter(
          ({ webExtendsFilterType }) => webExtendsFilterType === "webfilter" || webExtendsFilterType === "waffilter"
        );
      if (info.logBlocked) {
        const [logBlockedTextToRender, logBlockedTextForCSV] = logUtils.setLogBlockedInfo(
          info.logBlocked,
          info.webExtendsFilterTypes,
          this.CodeStore
        );
        this.logBlockedTextToRender = logBlockedTextToRender;
        this.logBlockedTextForCSV = logBlockedTextForCSV;
      }
    }
  }

  @computed
  get attackNation() {
    return this.countryIsoCode ? this.countryIsoCode.toLowerCase() : "";
  }

  @computed
  get attackNationForCSV() {
    return formatUtils.IsNullFormat(!this.attackNation ? null : this.attackNation);
  }

  csvInfoKey;
  issueServiceType;

  @computed
  get attackIp() {
    return this.logClientIp;
  }

  @computed
  get webAttacks() {
    return (
      this.detectList &&
      this.detectList.reduce(
        (acc, cur) => {
          const attack = `${
            cur.webFilterName || cur.webFilterAttackType
              ? `${cur.webFilterAttackType}${cur.webFilterPattern ? ">" + cur.webFilterPattern : ""}`
              : "-"
          }`;
          const step = this.CodeStore.getCodeName("webFilterRiskCode", cur.webFilterRiskCode);

          acc.attackStage.push(cur.webFilterRiskCode);
          acc.attack.push(attack);
          acc.OWASP.push(Common_Utils.isDataWithDashBar(cur.webFilterOwaspCode));
          acc.attackPurpose.push({
            attack: Common_Utils.isDataWithDashBar(cur.webFilterName),
            step,
            attackStage: cur.webFilterRiskCode,
          });
          acc.attackPurposeText.push(Common_Utils.isDataWithDashBar(cur.webFilterName));
          acc.step.push(step);
          acc.classfication.push(cur.filterCategoryName);
          acc.webFilterId.push(cur.webFilterId);
          acc.renderAttackType.push(attack);
          return acc;
        },
        {
          attackStage: [],
          attack: [],
          OWASP: [],
          attackPurpose: [],
          attackPurposeText: [],
          step: [],
          classfication: [],
          webFilterId: [],
          renderAttackType: [],
        }
      )
    );
  }

  LOG_TYPE = "web";

  @computed
  get filterIds() {
    return this.webAttacks && this.webAttacks.webFilterId;
  }

  @computed
  get isDefenceFlag() {
    return Common_Utils.isCheckBool(this.isDefence);
  }

  @computed
  get serverIpAddr() {
    return this.hostIp || this.serverIp;
  }

  @computed
  get logBlockedFlag() {
    return Common_Utils.isCheckBool(this.logBlocked);
  }

  @computed
  get ticketText() {
    return this.userTicketPublish ? dateUtil.timeage(this.userTicketPublish.registerDate) : false;
  }

  @computed
  get stepText() {
    return this.webAttacks && this.webAttacks.step.join("/");
  }
  @computed
  get attackText() {
    return this.webAttacks && this.webAttacks.attack.join("/");
  }
  @computed
  get OWASPText() {
    return this.webAttacks && this.webAttacks.OWASP.join("/");
  }
  @computed
  get attackPurposeText() {
    return this.webAttacks && this.webAttacks.attackPurposeText.join("/");
  }

  @computed
  get csvData() {
    return Common_Utils.makeCsvRowData(this, CSVINFO[this.csvInfoKey].infoKey);
  }

  @computed
  get logInfo() {
    return {
      issueServiceType: this.issueServiceType,
      logCreateDate: this.logCreateDate,
      filterList: this.filterIds,
    };
  }

  @computed
  get isWebExtendsDetectFlag() {
    return this.isWebExtendsDetect ? "o" : "-";
  }

  @computed
  get isMime() {
    return this.mime || "-";
  }

  @computed
  get getServerGroup() {
    return Common_Utils.isDataWithDashBar(this.serverGroup);
  }

  @computed
  get isSquid() {
    return !this.isWaf;
  }

  CodeStore;

  @observable
  modeSecurityLogInfo = [];

  @observable
  userFilterLogInfo = [];

  @observable
  logMethod = "";

  @observable
  totalLogIp = "";

  @observable
  documentId = ""; //로그 id

  @observable
  serverGroup = "";

  @observable
  typeos = "";

  @observable
  logStatus = "";

  @observable
  logUri = "";

  @observable
  logClientIp = "";

  @observable
  logCreateDate = "";

  @observable
  detectList = [];

  @observable
  isDefence = "0";

  @observable
  isPrivateIp = false; //공격IP가 내부인지 아닌지

  @observable
  serverId = 0;

  @observable
  detectDefenseId = 0;

  @observable
  formatLogOrigin = "";

  @observable
  logOrigin = "";

  @observable
  serverIp = "";

  @observable
  complianceEntityCount = 0;

  @observable
  agentType = "";

  @observable
  logBlocked = "";

  @observable
  userTicketPublish = {};

  @observable
  logRequestBody = "";

  @observable
  isWebExtendsDetect = false;

  @observable
  isReplayAttack = false;

  @observable
  isActive = "0";

  @observable
  defenseDescription = "";

  defenseConfigFilterType = "webfilter";

  @observable
  mime = "";

  @observable
  logBlockedText = [];

  @observable
  logBlockedTextToRender = [];

  @observable
  logBlockedTextForCSV = "";

  @observable
  logProxyStatus = "";

  @observable
  logProxyStatusDescription = "";

  @observable
  isWaf = false;

  @observable
  hscore = "";
}

export default WebFilterDetectionModel;
