import { observable, computed } from "mobx";

import { SetterModel, languageSet, Common_Utils } from "@qubit-utill/src";

class MemberModel extends SetterModel {
  constructor(params, CodeStore) {
    super(params);
    this.CodeStore = CodeStore;
    this.lastLoginDate = params?.lastLoginSuccessDate || "-";
  }

  @observable
  CodeStore;

  @computed
  get authority() {
    return this.CodeStore.getCodeName("roleList", this.roleCode);
  }

  @computed
  get userServerGroup() {
    return this.isServerGroupAll === "1" ? languageSet("전체") : this.userServerGroupCount;
  }

  @computed
  get getIsPasswordLock() {
    return !Common_Utils.isCheckBool(this.isPasswordLock) ? languageSet("정상") : languageSet("잠김");
  }

  @computed
  get getIsPinLock() {
    return !Common_Utils.isCheckBool(this.isPinLock) ? languageSet("정상") : languageSet("잠김");
  }

  @computed
  get getIsOtpMailLock() {
    return !Common_Utils.isCheckBool(this.isOtpMailLock) ? languageSet("정상") : languageSet("잠김");
  }

  @computed
  get isBlockedFlag() {
    return Common_Utils.isCheckBool(this.isBlocked);
  }

  @computed
  get isLastAdminFlag() {
    return Common_Utils.isCheckBool(this.isLastAdmin);
  }

  @observable
  uuid: string = ""; //사용자고유ID

  @observable
  isUserLoginActivation: string = ""; //로그인상태

  @observable
  userName: string = ""; //이름

  @observable
  userEmail: string = ""; //이메일

  @observable
  registerDate: string = ""; //가입일

  @observable
  lastLoginDate: string = ""; //최근접속일

  @observable
  lastLoginSuccessDate: string = "-"; //최근 접속일

  @observable
  roleCode: string = ""; //권한코드

  @observable
  isServerGroupAll: string = ""; //소속그룹 전체 여부(0:전체아님 1:전체)

  @observable
  userServerGroupCount: number = 0;

  @observable
  isPasswordLock: string = ""; //인증상태

  @observable
  isPinLock: string = ""; //2차인증(PIN번호)상태

  @observable
  isOtpMailLock: string = ""; //2차인증(OTP메일)상태

  @observable
  isBlocked: string = ""; //로그인 제한(0:해제, 1:제한)

  @observable
  isLastAdmin: string = ""; //마지막 관리자 여부
}

export default MemberModel;
