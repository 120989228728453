import { axiosModules } from "@qubit-utill/src";

export default {
  //사용자 리소스 필터 조회
  getUserResourceFilterInfo: (resourceFilterId) => {
    return axiosModules.get(`/pmetrics/userresourcefilter/${resourceFilterId}`);
  },

  //사용자 리소스 필터 목록 조회
  getUserResourceFilterList: (params) => {
    return axiosModules.get("/pmetrics/userresourcefilter/list", params);
  },

  //사용자 리소스 필터 생성
  createUserResourceFilter: (data) => {
    return axiosModules.post("/pmetrics/userresourcefilter", data);
  },

  //사용자 리소스 필터 수정
  updateUserResourceFilter: (resoureceFilterId, data) => {
    return axiosModules.put(`/pmetrics/userresourcefilter/${resoureceFilterId}`, data);
  },

  //사용자 리소스 필터 사용여부 수정
  isUseUpdateUserResourceFilter: (data) => {
    return axiosModules.patch("/pmetrics/userresourcefilter/isuse", data);
  },

  //사용자 리소스 필터 삭제
  deleteUserResourceFilterInfo: (resoureceFilterId) => {
    return axiosModules.del(`/pmetrics/userresourcefilter/${resoureceFilterId}`);
  },

  //사용자 리소스 필터 일괄 삭제
  deleteUserResourceFilterList: (params) => {
    return axiosModules.del("/pmetrics/userresourcefilter", params);
  },

  // pmetrics > 리소스 필터 > 사용자 리소스 필터 목록 조회 - 자동완성용
  getUserResourceFilterListForAutoComplete: (param?) => {
    return axiosModules.get("/pmetrics/userresourcefilter/simple/list", { ...param, limit: 9999 });
  },

  //pmetrics > 리소스모니터링 필터 > 리소스모니터링 필터 목록 조회
  getResourceMonitorFilterList: (params) => {
    return axiosModules.get("/pmetrics/resourcemonitorfilter/list", params);
  },
};
