import React from "react";
import { languageSet, Buttons } from "@qubit-utill/src";

interface Props {
  setListParams(a, b, c): void;
  selectedButton: String;
}

const GraphButtons: React.SFC<Props> = ({ setListParams, selectedButton }) => {
  const activeColor = (service: String) => (selectedButton === service ? "bg-orange" : "btn-default");
  return (
    <p className="bold t-center f-12 m-b-0">
      <Buttons.Rounded
        onClick={() => setListParams("actionDetectStatsType", "processName", true)}
        size="sm"
        className={`${activeColor("processName")} min_w100 m-r-0 m-b-0`}
      >
        {languageSet("응용프로그램")}
      </Buttons.Rounded>
      <Buttons.Rounded
        onClick={() => setListParams("actionDetectStatsType", "ipAddress", true)}
        size="sm"
        className={`${activeColor("ipAddress")} min_w100 m-b-0`}
      >
        {languageSet("IP주소")}
      </Buttons.Rounded>
      <Buttons.Rounded
        onClick={() => setListParams("actionDetectStatsType", "outboundport", true)}
        size="sm"
        className={`${activeColor("outboundport")} min_w100 m-l-0 m-r-0 m-b-0`}
      >
        Port
      </Buttons.Rounded>
    </p>
  );
};

export default GraphButtons;
