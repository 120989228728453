import { axiosModules } from "@qubit-utill/src";

export default {
  // pfilter > filters > 사용자 패키지 필터(상관필터) > 사용자 상관분석 구성 선택 필터 목록
  getFilterListForCustomPackage: (param) => axiosModules.get(`/pfilter/custompackageselectfilter`, param),

  // pfilter > filters > 사용자 패키지 필터(상관필터) > 사용자 패키지 필터 조회
  getUserPackageFilterInfo: (packageFilterId) => axiosModules.get(`/pfilter/custompackagefilter/${packageFilterId}`),

  // pfilter > filters > 사용자 패키지 필터(상관필터) > 사용자 패키지 필터 목록
  getUserPackageFilterList: (params) => axiosModules.get(`/pfilter/custompackagefilter`, params),

  // pfilter > filters > 사용자 패키지 필터(상관필터) > 사용자 패키지 필터 정보 등록
  createUserPackageFilter: (param) => axiosModules.post(`/pfilter/custompackagefilter`, param),

  // pfilter > filters > 사용자 패키지 필터(상관필터) > 사용자 패키지 필터 정보 수정
  updateUserPackageFilter: (packageFilterId, param) => axiosModules.put(`/pfilter/custompackagefilter/${packageFilterId}`, param),

  // pfilter > filters > 사용자 패키지 필터(상관필터) > 사용자 패키지 필터 정보 수정 ( 사용여부만 수정 )
  setUserPackageIsUse: (packageFilterId, param) =>
    axiosModules.put(`/pfilter/custompackagefilterisuse/${packageFilterId}`, param),

  // pfilter > filters > 사용자 패키지 필터(상관필터) > 사용자 패키지 필터 정보 논리적 삭제
  deleteUserPackageFilter: (packageFilterId) => axiosModules.patch(`/pfilter/custompackagefilter/${packageFilterId}`, {}),

  // pfilter > filters > 필터 자동완성 검색용 > 사용자 패키지 필터 목록 - 자동완성용
  getUserPackageFilterListForAutoComplete: () => axiosModules.get("/pfilter/custompackagefilter/simple", { limit: 9999 }),
};
