import React from "react";
import { Row, Col } from "reactstrap";
import { observer } from "mobx-react";

import { SearchListFilter, useInjectStore } from "@qubit-utill/src";

import {
  ReportDatePicker,
  TermTab,
  ServiceButtons,
  ClassificationTab,
  ResourceClassificationTab,
} from "@src/pages/Report/templates/menus";

interface PropTypes {
  customDateComponent?: any;
  isUseServiceButton?: any;
  isUseTermTab?: any;
  OptionTemplate?: any;
  isUseClassification?: any;
  classificationTitle?: any;
  isUseSearchListFilter?: any;
  paramName?: any;
  isUseCustomListFilter?: any;
  CustomListFilter?: any;
  isAlawaysUseClassification?: any;
  isUsePdf?: boolean;
  PdfBtnComponent?: any;
}

const SearchOption: React.FC<PropTypes> = ({
  customDateComponent,
  isUseServiceButton,
  isUseTermTab,
  OptionTemplate,
  isUseClassification,
  classificationTitle,
  isUseSearchListFilter,
  paramName = "filterCategoryGroupId",
  isUseCustomListFilter,
  CustomListFilter,
  isAlawaysUseClassification,
  isUsePdf = false,
  PdfBtnComponent,
}) => {
  const { ReportStore } = useInjectStore();
  const { termsTab, dateInfo, params, setListParams, limitDate, isUseSubTab } = ReportStore;

  return (
    <>
      <Row>
        <Col md="12" className="m-b-10 f-12">
          <div className="dis-inline-b m-r-10">
            <ReportDatePicker
              CustomDate={customDateComponent}
              termsTab={termsTab}
              selectedDate={dateInfo.detectDate}
              limitDate={limitDate}
            />
          </div>
          {isUseSearchListFilter && <SearchListFilter store={ReportStore} />}
          {isUseCustomListFilter && <CustomListFilter store={ReportStore} />}
          {isUseServiceButton && (
            <ServiceButtons selectedButton={params.selectedService} setListParams={setListParams} paramName={paramName} />
          )}
          {isUseTermTab && (
            <div className="dis-inline-b f-right">
              {isUsePdf && <PdfBtnComponent />}
              <TermTab />
            </div>
          )}
          <p className="border-bottom p-b-10"></p>
        </Col>
      </Row>
      <Row>{OptionTemplate && <OptionTemplate />}</Row>
      {(termsTab === "day" || isAlawaysUseClassification) && isUseClassification && (
        <ClassificationTab paramName={paramName} selectedValue={params[paramName]} title={classificationTitle} />
      )}
      {isUseSubTab && <ResourceClassificationTab selectedValue={params["resourceId"]} />}
    </>
  );
};

export default observer(SearchOption);
