import React from "react";
import Chart from "react-apexcharts";
import { observer } from "mobx-react";
import { toJS } from "mobx";

import UISET from "@src/constants/uiset";
import NullLogData from "@src/common/components/organisms/Graphs/NullLogData";

interface propsType {
  id: string;
  data: {
    data: {
      x: string;
      y: number;
    }[];
  }[];
}
const TreeMap: React.FC<propsType> = ({ id, data }) => {
  const options = {
    chart: {
      id: id,
      toolbar: {
        show: false,
      },
      offsetX: 10,
      animations: {
        speed: 300,
      },
    },
    legend: {
      show: false,
    },
    colors: UISET.CHART_COLOR,
    plotOptions: {
      treemap: {
        distributed: true,
        enableShades: false,
        dataLabels: {
          format: "truncate" as const,
        },
      },
    },
  };

  return data && data.length > 0 ? (
    <Chart options={options} series={toJS(data)} type="treemap" height={400} />
  ) : (
    <NullLogData height={400} />
  );
};

export default observer(TreeMap);
