import { observable, computed } from "mobx";
import { Common_Utils, dateUtil, formatConstants, SetterModel } from "@qubit-utill/src";
import { path, CSVINFO } from "@src/constants";
import { PACKAGEISSUETYPE } from "@src/constants/code";

class CorrelationModel extends SetterModel {
  constructor(params) {
    super(params);
    if (params) {
      this.updateDate = params.lastCheckPointDate && params.lastCheckPointDate.split(".")[0];
      this.attackProbability = `${params.detectRatio}%`;
      this.serverIp = params.serverIp || "";
      this.filterName = params.packageFilterName;
      this.categoryName = params.filterCategoryName;
      this.serverOs = params.typeos;
      if (params.typeos === "windows") {
        this.logOriginType = "xml";
      } else {
        this.logOriginType = "json";
      }
      this.documentId = params.detectPackageId;

      let tagList: string[] = [];
      if (params.detectPackageGroupList) {
        params.detectPackageGroupList.forEach((data) => {
          data.detectFilterPackageList.length &&
            data.detectFilterPackageList.forEach((subData) => {
              let newTag: string = "";
              if (subData.isDisplay) {
                if (subData.webFilterId !== null && subData.webFilterId !== "") {
                  newTag = Boolean(subData.webFilterPattern)
                    ? `${subData.webFilterAttackType} > ${subData.webFilterPattern}`
                    : subData.webFilterAttackType;
                } else {
                  newTag = subData.filterName || subData.applicationFilterName || subData.networkFilterName;
                }
                tagList.push(newTag);
              }
            });
        });
        this.tagList = tagList;
      }

      let detectLogList: any = [];
      if (params.detectLogTemplateList) {
        params.detectLogTemplateList.forEach((data) => {
          const detectLog = data.detectLog;

          if (data.isDisplay && detectLog !== null) {
            const newTag: any = {};
            const tmpList = {};
            //웹로그는 분석팀에서 탬플릿을 확정하지 못하여 임시로 하드코딩 처리함
            if (data.logType === "weblogDetect") {
              tmpList["Target IP addr"] = detectLog.logClientIp;
              tmpList["Request time"] = detectLog.logRequestDate;
              tmpList["Country"] = detectLog.countryIsoCode
                ? `<img
                alt="${detectLog.countryIsoCode}" src="${path.CDNURL}/ip_flags/${detectLog.countryIsoCode}.gif"
                />`
                : "Unknown";
              tmpList["Domain"] = detectLog.hostIp;
              tmpList["Equal number of logs"] = detectLog.logConcurrentLogCount;
              tmpList["Web Server"] = detectLog.logModPluraVersion;
              tmpList["Status"] = detectLog.logStatus;
              tmpList["Method"] = detectLog.logMethod;

              newTag.filterName = Boolean(detectLog.detectFilter.webFilterPattern)
                ? `${detectLog.detectFilter.webFilterAttackType} > ${detectLog.detectFilter.webFilterPattern}`
                : detectLog.detectFilter.webFilterAttackType;
              newTag.webFilterAttackType = detectLog.detectFilter.webFilterAttackType;
              newTag.webFilterPattern = detectLog.detectFilter.webFilterPattern;
            } else {
              // TODO 상관분석 viewTemplate 이 업데이트 되어야 한단다
              //근데 지금은 안되어 있으니 사용을 하지 않는가 보다 읭?
              //증거는 마이터 기획서 201008 일자 19페이지 > 다시원복합니다
              data.viewTemplate.forEach((subData) => {
                tmpList[subData.name] = subData.value;
              });

              newTag.filterName = detectLog.detectFilter.filterName;
            }
            newTag.tmpList = tmpList;
            newTag.typeos = detectLog.typeos;
            newTag.packageGroupRatio = data.packageGroupRatio;

            newTag.logType = data.logType;
            newTag.detectDate = dateUtil.format(new Date(detectLog.logCreateDate), formatConstants.DATEFORMAT);
            newTag.documentId = detectLog.documentId;
            newTag.formatLogOrigin = detectLog.formatLogOrigin;
            newTag.logOriginType = detectLog.logOriginType;
            newTag.edrDetectMode = detectLog?.edrDetectMode || null;

            detectLogList.push(newTag);
          }
        });
        this.detectLogList = detectLogList;
      }

      this.filterId = params.packageId;
      this.filterIds = [this.filterId];
    }
  }

  @computed
  get tagListName() {
    return this.tagList.toString();
  }

  @computed
  get csvData() {
    return Common_Utils.makeCsvRowData(this, CSVINFO.SECURITYDETECT_CORRELATION.infoKey);
  }

  @computed
  get isDefenceFlag() {
    return Common_Utils.isCheckBool(this.isDefence);
  }

  @computed
  get ticketText() {
    return this.userTicketPublish ? dateUtil.timeage(this.userTicketPublish.registerDate) : false;
  }

  @computed
  get logInfo() {
    return {
      issueServiceType: PACKAGEISSUETYPE,
      logCreateDate: this.registerDate,
      filterList: [this.packageId],
    };
  }

  @computed
  get getCategoryName() {
    return Common_Utils.isDataWithDashBar(this.categoryName);
  }

  @computed
  get getFilterName() {
    return Common_Utils.isDataWithDashBar(this.filterName);
  }

  @computed
  get getServerIp() {
    return Common_Utils.isDataWithDashBar(this.serverIp);
  }

  @computed
  get getHostname() {
    return Common_Utils.isDataWithDashBar(this.hostname);
  }

  @observable
  detectRatio = 0;
  @observable
  packageId: string = "";
  @observable
  tagList: string[] = [];
  @observable
  detectLogList: any[] = [];
  @observable
  detectPackageId = 0;
  @observable
  attackLevel = ""; //공격단계 (code)
  @observable
  categoryName = ""; //분류
  @observable
  attackProbability = ""; //공격확률
  @observable
  filterName = ""; //필터명
  @observable
  attackNation = ""; //공격국가
  @observable
  attackIp = ""; //공격IP
  @observable
  isPrivateIp = false; //공격IP가 내부인지 아닌지
  @observable
  serverOs = ""; //서버os
  @observable
  serverIp = ""; //서버IP
  @observable
  hostname = ""; //호스트명
  @observable
  url = ""; //url
  @observable
  updateDate = ""; //업데이트일
  @observable
  finalCheck = ""; //최종검사
  @observable
  logOrigin = ""; //최종검사
  @observable
  serverId = 0;
  @observable
  detectDefenseId = 0;
  @observable
  isDefence = "0";
  @observable
  defenseConfigFilterType = "package";
  @observable
  documentId = "";
  @observable
  attackIpList = [];
  @observable
  dnsList = [];
  @observable
  logOriginType = "";
  @observable
  formatLogOrigin = "";
  @observable
  filterId = "";
  @observable
  filterIds: string[] = [];
  @observable
  complianceEntityCount = 0;
  @observable
  userTicketPublish = { registerDate: "" };
  @observable
  packageFilterId = "";
  @observable
  filterCategoryName = "";
  @observable
  packageFilterName = "";
  @observable
  packageFilterDescription = "";
}

export default CorrelationModel;
