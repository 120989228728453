import { languageSet } from "@qubit-utill/src";
import getURI from "../uriset";
import { MyInfoContainer, MyInfoTabContainer } from "../../pages";

export default {
  //MY INFO
  MYINFO: {
    link: getURI("MYINFO"),
    title: languageSet("나의_정보"),
    tabMenus: [
      {
        to: getURI("MYINFO"),
        name: "HOME",
        iconId: "icon-home",
      },
      {
        to: getURI("MYINFO_USERINFO"),
        name: languageSet("사용자_정보"),
        iconId: "icon-user",
      },
      {
        to: getURI("MYINFO_SECURITY"),
        name: languageSet("보안"),
        iconId: "icon-lock",
      },
      {
        to: getURI("MYINFO_EDITPWD"),
        name: languageSet("비밀번호_변경"),
        iconId: "icon-key",
      },
      {
        to: getURI("MYINFO_ETCINFO"),
        name: languageSet("기타_정보"),
        iconId: "fa fa-asterisk",
      },
    ],
    component: MyInfoContainer,
  },
  MYINFO_USERINFO: {
    link: getURI("MYINFO_USERINFO"),
    component: MyInfoTabContainer,
  },
  MYINFO_SECURITY: {
    link: getURI("MYINFO_SECURITY"),
    component: MyInfoTabContainer,
  },
  MYINFO_EDITPWD: {
    link: getURI("MYINFO_EDITPWD"),
    component: MyInfoTabContainer,
  },
  MYINFO_ETCINFO: {
    link: getURI("MYINFO_ETCINFO"),
    component: MyInfoTabContainer,
  },
};
