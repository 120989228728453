import { axiosModules } from "@qubit-utill/src";

export default {
  // 보고서 > 보고서 메인 > 일정보 > 일 분류 정보 가져오기
  getCategoryList: () => axiosModules.get("/pstats/reportsummary/day/categorygroup"),

  // 보고서 > 보고서 메인 > 일정보 > 일 탐지 정보 가져오기
  getMainReportDayChart: (params?) => axiosModules.get("/pstats/reportsummary/day/mainchart", params),

  // 보고서 > 보고서 메인 > 일정보 > 일 시간별 탐지 정보 가져오기
  getMainReportDaySubChart: (params) => axiosModules.get("/pstats/reportsummary/day/subchart", params),

  // 보고서 > 보고서 메인 > 주정보 > 주 탐지 정보 가져오기
  getMainReportWeekChart: (params?) => axiosModules.get("/pstats/reportsummary/week/mainchart", params),

  // 보고서 > 보고서 메인 > 월정보 > 월 탐지 정보 가져오기
  getMainReportMonthChart: (params?) => axiosModules.get("/pstats/reportsummary/month/mainchart", params),

  // 보고서 자동 발송 > PDF 제공 API
  getMainDayReportPdfData: (params) => axiosModules.getBlob("/kpi/v1/report/save", params),
};
