import { languageSet } from "@qubit-utill/src";

import getURI from "@src/constants/uriset";
import { DEFENCE_LICENSECODE } from "@src/constants/licenseCode";
import { DefenceIpContainer, DefenseLogContainer, DefenceManagementContainer } from "@src/pages";

export default {
  //차단IP주소
  DEFENCEIP: {
    title: languageSet("차단IP주소"),
    text: languageSet("차단IP주소"),
    link: getURI("DEFENSE_IP_SYSTEM"),
    licenseCode: DEFENCE_LICENSECODE,
    tabMenus: [
      {
        to: getURI("DEFENSE_IP_SYSTEM"),
        name: languageSet("시스템"),
      },
      {
        to: getURI("DEFENSE_IP_WEBFIREWALL"),
        name: languageSet("웹방화벽"),
      },
    ],
    component: DefenceIpContainer,
  },
  DEFENSELOG: {
    title: languageSet("로그"),
    text: languageSet("로그"),
    link: getURI("DEFENSE_LOG_SYSTEM"),
    licenseCode: DEFENCE_LICENSECODE,
    tabMenus: [
      {
        to: getURI("DEFENSE_LOG_SYSTEM"),
        name: languageSet("시스템"),
      },
      {
        to: getURI("DEFENSE_LOG_WEBFIREWALL"),
        name: languageSet("웹방화벽"),
      },
    ],
    component: DefenseLogContainer,
  },
  DEFENCEMANAGEMENT: {
    text: languageSet("설정"),
    link: getURI("DEFENSE_MANAGEMENT_SYSTEM"),
    title: languageSet("설정"),
    licenseCode: DEFENCE_LICENSECODE,
    tabMenus: [
      {
        to: getURI("DEFENSE_MANAGEMENT_SYSTEM"),
        name: languageSet("시스템"),
        subTabMenus: [{ to: getURI("DEFENSE_MANAGEMENT_CORRELATION") }, { to: getURI("DEFENSE_MANAGEMENT_SYSTEMLOG") }],
      },
      {
        to: getURI("DEFENSE_MANAGEMENT_WEBFIREWALL"),
        name: languageSet("웹방화벽"),
        subTabMenus: [
          { to: getURI("DEFENSE_MANAGEMENT_EXTORTACCOUNTBLOCK") },
          { to: getURI("DEFENSE_MANAGEMENT_EXTORTACCOUNTRESTRICTION") },
          { to: getURI("DEFENSE_MANAGEMENT_DATABREACH") },
          { to: getURI("DEFENSE_MANAGEMENT_OWASP") },
        ],
      },
      // {
      //   to: getURI("DEFENSE_MANAGEMENT_CORRELATION"),
      //   name: languageSet("상관_분석_방어"),
      // },
      // {
      //   to: getURI("DEFENSE_MANAGEMENT_DATABREACH"),
      //   name: languageSet("데이터유출_방어"),
      // },
      // {
      //   to: getURI("EXTORTACCOUNTDEFENCE"),
      //   name: languageSet("계정_탈취_공격_방어"),
      // },
      // {
      //   to: getURI("DEFENSE_MANAGEMENT_SYSTEMLOG"),
      //   name: languageSet("시스템로그_방어"),
      // },
      // {
      //   to: getURI("WEBLOGDEFENCE"),
      //   name: languageSet("웹로그_방어"),
      // },
    ],
    component: DefenceManagementContainer,
  },
};
