import { observable, computed } from "mobx";

import { SetterModel, Common_Utils } from "@qubit-utill/src";

export default class WebExtortAccountEntryModel extends SetterModel {
  @computed
  get isRegexFlag() {
    return Common_Utils.isCheckBool(this.isRegex);
  }

  @observable
  dosExceptCustomEntryId: number = 0;

  @observable
  dosExceptCustomEntryType: string = "";

  @observable
  dosExceptCustomEntryValue: string = "";

  @observable
  dosExceptSelectOperator: string = "";

  @observable
  isRegex: string = "";
}
