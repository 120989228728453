import agentlog from "./agentlog";
import alarm from "./alarm";
import compliance from "./compliance";
import dashboard from "./dashboard";
import defence from "./defence";
import filterDetect from "./filterDetect";
import filter from "./filter";
import recommendFilter from "./recommendFilter";
import registrationFilter from "./registrationFilter";
import group from "./group";
import install from "./install";
import extractionLog from "./extractionLog";
import ml from "./ml";
import myInfo from "./myInfo";
import qna from "./qna";
import report from "./report";
import securityDetect from "./securityDetect";
import securityFilter from "./securityFilter";
import server from "./server";
import resourceMonitoring from "./resourceMonitoring";
import ticket from "./ticket";
import totalLog from "./totalLog";
import uicode from "./uicode";
import defenceIp from "./defenceIp";
import defenseLog from "./defenseLog";
import defenseManagement from "./defenseManagement";
import webFirewallFilterDetect from "./webFirewallFilterDetect";
import hostSecurityFilterDetect from "./hostSecurityFilterDetect";

export default {
  ...agentlog,
  ...alarm,
  ...compliance,
  ...dashboard,
  ...defence,
  ...filter,
  ...filterDetect,
  ...recommendFilter,
  ...registrationFilter,
  ...group,
  ...install,
  ...extractionLog,
  ...ml,
  ...myInfo,
  ...qna,
  ...report,
  ...securityDetect,
  ...securityFilter,
  ...server,
  ...resourceMonitoring,
  ...ticket,
  ...totalLog,
  ...uicode,
  ...defenceIp,
  ...defenseLog,
  ...defenseManagement,
  ...webFirewallFilterDetect,
  ...hostSecurityFilterDetect,
};
