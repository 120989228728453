import { observable, computed, action } from "mobx";
import FilterGroupModel from "./FilterGroupModel";
import { SetterModel, Common_Utils, arrayUtils, languageSet } from "@qubit-utill/src";

export default class CorrelationFilterModel extends SetterModel {
  constructor(params?) {
    super(params);
    if (params && params.packageFilterGroupList) {
      this.packageFilterGroupList = Common_Utils.constructClassListResetOrder(
        params.packageFilterGroupList,
        FilterGroupModel,
        "packageFilterGroupOrderNo"
      );
    }

    this.TimeLimit = `${this.TimeLimitSecond}${languageSet("초")}(${this.TimeLimitSecond / 60}${languageSet("분")})`;
  }

  @computed
  get isSequenceCheckFlag() {
    return Common_Utils.isCheckBool(this.isSequenceCheck);
  }

  @computed
  get isUseFlag() {
    return Common_Utils.isCheckBool(this.isUse);
  }

  @computed
  get sortedPackageFilterGroupList() {
    return arrayUtils.sortByKey(this.packageFilterGroupList, "packageFilterGroupOrderNo");
  }

  @computed
  get hasPackageFilterGroupList() {
    return this.sortedPackageFilterGroupList.length > 1;
  }

  @computed
  get validAttackRatio() {
    return (
      this.hasPackageFilterGroupList &&
      this.sortedPackageFilterGroupList.some(({ packageFilterGroupRatio }, index) => {
        return index !== 0 && (packageFilterGroupRatio === 0 || packageFilterGroupRatio === "");
      })
    );
  }

  @computed
  get validDetectTime() {
    return Number(this.packageFilterTimeRange) === 0 || this.packageFilterTimeRange > this.TimeLimitSecond;
  }

  @computed
  get validDetectShow() {
    return Number(this.packageFilterCheckPointRatio) === 0;
  }

  @computed
  get getPackageFilterDescription() {
    return Common_Utils.isDataWithDashBar(this.packageFilterDescription);
  }

  @action
  addPackageFilterGroup = () => {
    this.packageFilterGroupList.push(
      new FilterGroupModel({
        packageFilterGroupOrderNo: this.packageFilterGroupList.length,
      })
    );
  };

  @action
  deletePackageFilterGroup = (info) => {
    this.packageFilterGroupList = this.packageFilterGroupList.filter((element) => {
      return element !== info;
    });
  };

  @action
  handleEditOrder = (groupIndex, isDown) => {
    arrayUtils.handleEditOrder(this.sortedPackageFilterGroupList, "packageFilterGroupOrderNo", groupIndex, isDown);
  };

  //pfilter/filtercategory/sys 목록
  // sys 단일,
  // web 웹 필터 ,
  // pkg 패키지 필터

  @observable
  packageFilterNo = 0;
  @observable
  division = "";
  @observable
  registerFilterCount = 0;
  @observable
  packageFilterId = "";
  @observable
  packageFilterName = "";
  @observable
  packageFilterTimeRange = 0; // 탐지 시간 초 단위
  @observable
  isSequenceCheck = "1"; // 탐지 타입? "1" 순차 "0" 가 랜덤
  @observable
  isUse = "0";
  @observable
  nonSequenceCheckPointRatio = 0;
  @observable
  isCustomPackage = 0;
  @observable
  customPackageFilterGuid = null;
  @observable
  packageFilterDescription = "";
  @observable
  packageFilterCheckPointRatio = 0;
  @observable
  isLock = 0;
  @observable
  filterCategoryId = 0; //분류
  @observable
  filterCategoryName = "";
  @observable
  packageFilterGroupList = [new FilterGroupModel()];
  @observable
  registerUserName = "";
  @observable
  TimeLimitSecond = 1800;
  @observable
  TimeLimit = "";
}
