import React from "react"
import PropTypes from "prop-types"
import { path } from "@qubit-utill/src/common_constants"

// 1 = IsPrivetIP, other = NotPrivateIP

const GeoIpFLag = ({ geoIpCountryName, geoIpCountryIsoCode, ipIntelIpTypeEnum, isPrivateIp }) => {
  if (ipIntelIpTypeEnum && "PRIVATE_IP" === ipIntelIpTypeEnum) {
    isPrivateIp = 1
  }

  let flagIsoCode, imgFlag, imgFlagCss

  if (geoIpCountryIsoCode) {
    flagIsoCode = geoIpCountryIsoCode
    imgFlag = <img src={`${path.CDNURL}/ip_flags/${flagIsoCode.toLowerCase()}.gif`} alt="flag" />
    imgFlagCss = "ip_w ip_area"
  } else if ("1" === isPrivateIp) {
    flagIsoCode = "privateip"
    imgFlag = <i className="fa fa-linkedin-square" />
    imgFlagCss = "ip_w"
  } else {
    flagIsoCode = "question"
    imgFlag = <i className="fa fa-question" />
    imgFlagCss = "ip_w"
  }

  return (
    <p>
      <span className={imgFlagCss}>{imgFlag}</span>&nbsp;
      <span data-translate={flagIsoCode}>{geoIpCountryName}</span>
    </p>
  )
}

GeoIpFLag.propTypes = {
  ipIntelIpTypeEnum: PropTypes.string,
  isPrivateIp: PropTypes.string,
  geoIpCountryName: PropTypes.string,
  geoIpCountryIsoCode: PropTypes.string
}

export default GeoIpFLag
