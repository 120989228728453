import { axiosModules } from "@qubit-utill/src";

export default {
  //탭메뉴
  getFilterCategoryGroupDayList: (params) => {
    return axiosModules.get("/pstats/packagedetect/day/categorygroup", params);
  },
  //리스트
  getPackageDayMainDetect: (params) => {
    return axiosModules.get("/pstats/packagedetect/day/main", params);
  },
  //차트
  getPackageDayMainChart: (params) => {
    return axiosModules.get("/pstats/packagedetect/day/mainchart", params);
  },
  //서브 차트
  getPackageDaySubChart: (params) => {
    return axiosModules.get("/pstats/packagedetect/day/subchart", params);
  },
  //리스트 주별
  getPackageWeekMainDetect: (params) => {
    return axiosModules.get("/pstats/packagedetect/week/main", params);
  },
  //차트 주별
  getPackageWeekMainChart: (params) => {
    return axiosModules.get("/pstats/packagedetect/week/mainchart", params);
  },
  //차트 주별
  getPackageWeekSubChart: (params) => {
    return axiosModules.get("/pstats/packagedetect/week/subchart", params);
  },
  //리스트 월별
  getPackageMonthMainDetect: (params) => {
    return axiosModules.get("/pstats/packagedetect/month/main", params);
  },
  //차트 월별
  getPackageMonthMainChart: (params) => {
    return axiosModules.get("/pstats/packagedetect/month/mainchart", params);
  },
};
