import { observable } from "mobx";
import { SetterModel } from "@qubit-utill/src";

class ThemeOptionModel extends SetterModel {
  getActiveClass = (value) => {
    return this.servicePreferencesItemValue === value ? "active" : "";
  };

  @observable
  servicePreferencesId: string = "";
  @observable
  servicePreferencesItemId: number = 0;
  @observable
  servicePreferencesItemName: string = "";
  @observable
  servicePreferencesItemTag: string = "";
  @observable
  servicePreferencesItemValue: string = "";
}

export default ThemeOptionModel;
