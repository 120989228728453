import { action, observable } from "mobx";

import SetterModel from "./SetterModel";
import { Common_Utils } from "@qubit-utill/src";

class UserPasswordRuleModel extends SetterModel {
  constructor(list: any) {
    super();
    if (list) {
      this.passwordRuleList = list;
      this.returnMessage = "";
    }
  }

  @observable
  passwordRuleList = [];

  @observable
  returnMessage: string = "";

  /**
   * isLimit: 0: 매칭, 1: 제한
   * Boolean return 없이 message로 판단?
   *
   * @param value
   * @param rule
   * @param type
   */
  @action
  validPassword = (value: any, ruleList: Array<any>, type: string) => {
    this.returnMessage = "";

    if (Common_Utils.isEmpty(value)) return;

    for (let data of ruleList) {
      if (data.loginAuthType !== type) continue;

      if (Common_Utils.isCheckBool(data.isLimit)) {
        //제한
        if (new RegExp(data.ruleRegex).test(value)) {
          this.returnMessage = data.ruleName;
          return;
        }
      } else {
        //매칭
        if (!new RegExp(data.ruleRegex).test(value)) {
          this.returnMessage = data.ruleName;
          return;
        }
      }
    }
  };
}

export default UserPasswordRuleModel;
