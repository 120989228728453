import { observable } from "mobx";

export default class EventElementOptionModel {
  constructor(param) {
    const filterElementName = param.filterEventElementName;
    this.name = filterElementName || "";
    this.value = {
      elementAttributes: param.elementAttributes,
      filterEventElementId: param.filterEventElementId,
      filterElementName,
      parrentDepth: param.parrentDepth,
      parrentFilterEventElementId: param.parrentFilterEventElementId,
      filterEventElementRepeatSize: param.filterEventElementRepeatSize,
    };
  }

  @observable
  name: string;

  @observable
  value: {
    elementAttributes: Array<any>;
    filterEventElementId: number;
    filterElementName: string;
    parrentDepth: number;
    parrentFilterEventElementId: number;
    filterEventElementRepeatSize: number;
  };
}
