import { axiosModules } from "@qubit-utill/src";

export default {
  //웹 업로드
  getWebExceptEntryList: () => {
    return axiosModules.get(`/pfilter/webuploadexceptcustomgroups?orderDesc=ASC`);
  },
  setWebExceptEntryList: (data) => {
    return axiosModules.post(`/pfilter/webuploadexceptcustomgroup`, data);
  },

  //시스템 업로드
  getSystemExceptEntryList: () => {
    return axiosModules.get(`/pfilter/sysuploadexceptcustomgroups?orderDesc=ASC`);
  },
  setSystemExceptEntryList: (data) => {
    return axiosModules.post(`/pfilter/sysuploadexceptcustomgroup`, data);
  },

  //응용프로그램 Default
  getAppExceptEntryList: () => {
    return axiosModules.get(`/pfilter/appuploadexceptcustomgroups?orderDesc=ASC`);
  },
  setAppExceptEntryList: (data) => {
    return axiosModules.post(`/pfilter/appuploadexceptcustomgroup`, data);
  },

  //네트워크 업로드
  getNetworkExceptEntryList: () => {
    return axiosModules.get(`/pfilter/netuploadexceptcustomgroups?orderDesc=ASC`);
  },
  setNetworkExceptEntryList: (data) => {
    return axiosModules.post(`/pfilter/netuploadexceptcustomgroup`, data);
  },
};
