import { observable, action, computed, toJS } from "mobx";

import { UserException, languageSet, withLoading, isRunning, ListCondition, Common_Utils } from "@qubit-utill/src";

import { serverGroupApis } from "@src/apis/pServer";
import { resourceConfigUserApis, userResourceFilterApis, resourceMetaApis } from "@src/apis/pMetrics";
import { SelectOptionModel } from "@src/common/models";

import {
  ResourceDataTypeSelectorModel,
  ResourceDataTypeApiModel,
  ResourceDataTypeAttributeModel,
} from "@src/pages/Filter/RegisterFilter/model/ResourceDataType";
import { ApplicationFilterResourceModel } from "@src/pages/Filter/RegisterFilter/model";
import validFilterTimeRange from "@src/pages/Filter/valid/validFilterTimeRange";

class ApplicationFilterResourceStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }
  root;

  @observable
  isRunning = false;

  @observable
  filterElementReferenceListForSelect = [];

  @observable
  isCopy = false;

  @observable
  isCopyCheck = false;

  @observable
  originResourceId = "";

  @observable
  originDataTypeSelects: ResourceDataTypeSelectorModel[] = [];

  @observable
  tableList: ApplicationFilterResourceModel[] = [];

  @observable
  filterInfo = new ApplicationFilterResourceModel(null, this.root.CodeStore);

  @observable
  filterCategoryList = [];

  //단일필터 등록등에서 사용하는 데이터 유형 (filterElement)
  @observable
  filterEventElementsList = [];

  @observable
  logTagList = [];

  //UI용 데이터 조작을 위한 프로퍼티
  //필터 데이터 유형
  @observable
  dataTypeSelects: ResourceDataTypeSelectorModel[] = [];

  @observable
  isTotalCheck = false;

  @observable
  optionList: SelectOptionModel[] = [];

  @action
  setIsTotalCheck = (flag: boolean) => {
    this.isTotalCheck = flag;
  };

  @action
  setIsCopy = (flag) => {
    this.isCopy = flag;
  };
  @action
  setIsCopyCheck = (flag) => {
    this.isCopyCheck = flag;
  };

  @computed
  get totalCountForUI() {
    return this.isTotalCheck ? this.totalCount : this.checkList.length;
  }

  @computed
  get checkList() {
    return this.tableList.filter((data) => data.isSelected);
  }

  @computed
  get totalCheckParams() {
    return { resourceFilterIds: this.checkList.map(({ resourceFilterId }) => resourceFilterId) };
  }

  //사용자 리소스 필터 목록 조회
  @withLoading("isLoading")
  @action
  getList = async () => {
    const params = {
      ...toJS(this.params),
      orderDesc: "DESC",
    };

    try {
      const { list, totalCount } = await userResourceFilterApis.getUserResourceFilterList(params);
      this.tableList = list.map((data) => new ApplicationFilterResourceModel(data, this.root.CodeStore));
      this.totalCount = totalCount;
    } catch (e) {
      console.error("e", e);
      this.tableList = [];
      this.totalCount = 0;
    }
  };

  //필터 정보(필터 수정)
  @withLoading("isLoading")
  @action
  async getFilterWithTotalInfo(resourceFilterId) {
    try {
      await this.getServiceListForOption(); //서비스 목록
      await this.getFilterInfo(resourceFilterId); //필터 정보
      if (this.filterInfo.serverGroupId) {
        await this.getServerGroupInfo(this.filterInfo.serverGroupId); //서버그룹명을 매칭시켜주는 부분
      }
      if (this.filterInfo.resourceId && !this.filterInfo.resourceName) {
        await this.getServiceName(this.filterInfo.resourceId);
      }

      await this.setfilterEventElementsList(this.filterInfo.resourceId); //필터 요소값 셋팅

      if (this.filterInfo.resourceFilterElementList) {
        this.parseFilterElementsForUI(this.filterInfo.resourceFilterElementList);
      }
    } catch (e) {
      console.error("tag", e);
    }
  }

  @action
  parseFilterElementsForUI = async (resourceFilterElementList) => {
    this.dataTypeSelects = await resourceFilterElementList.map((element) => {
      return new ResourceDataTypeSelectorModel(
        {
          filterEventElementsList: this.filterEventElementsList,
        },
        this.filterInfo.resourceId,
        element,
        this.filterElementReferenceListForSelect
      );
    });

    this.originDataTypeSelects = this.dataTypeSelects;
  };

  //필터 요소값 셋팅
  @action
  setfilterEventElementsList = async (resourceId?) => {
    if (resourceId) {
      try {
        const { list } = await resourceMetaApis.getResourceMetaList(resourceId);

        this.filterEventElementsList = list.map((info) => {
          return {
            ...info,
            filterEventElementName: info.logProperty,
            parrentDepth: 1,
            parrentFilterEventElementId: resourceId,
            dataType: info.dataType,
          };
        });
      } catch (e) {
        console.log("catch");
        this.clearEventElementsList();
      }
    } else {
      this.clearEventElementsList();
    }
  };

  //사용자 리소스 필터 조회
  @withLoading("isLoading")
  @action
  getFilterInfo = async (resourceFilterId) => {
    const { info } = await userResourceFilterApis.getUserResourceFilterInfo(resourceFilterId);

    this.filterInfo = await new ApplicationFilterResourceModel(info, this.root.CodeStore);
    this.originResourceId = this.filterInfo.resourceId;
    await this.getCodes();
  };

  //사용자 리소스 필터 수정
  @isRunning("isRunning")
  @action
  updateFilter = async () => {
    const {
      resourceFilterId,
      resourceId,
      resourceName,
      serverGroupId,
      serverGroup,
      resourceFilterName,
      resourceFilterDescription,
      resourceFilterRiskLevel,
      isUse,
      resourceFilterElementList,
      detectStartTimeFormat,
      detectEndTimeFormat,
      isDetectTimeRangeUse,
      isDetectTimeRangeUseFlag,
    } = this.filterInfo;

    try {
      await userResourceFilterApis.updateUserResourceFilter(resourceFilterId, {
        resourceId,
        resourceName,
        serverGroupId,
        serverGroup,
        resourceFilterName,
        resourceFilterDescription,
        resourceFilterRiskLevel,
        isUse,
        resourceFilterElementList,
        isDetectTimeRangeUse,
        detectStartTime: isDetectTimeRangeUseFlag ? detectStartTimeFormat : null,
        detectEndTime: isDetectTimeRangeUseFlag ? detectEndTimeFormat : null,
      });
    } catch ({ data }) {
      return data;
    }
  };

  //사용자 리소스 필터 삭제
  @action
  deleteRecommendFilterCustomConfig = async (resourceFilterId) => {
    try {
      await userResourceFilterApis.deleteUserResourceFilterInfo(resourceFilterId);
    } catch ({ data }) {
      return data;
    }
  };

  //사용자 리소스 필터 일괄 삭제
  @action
  deleteCustomFilter = async () => {
    try {
      await userResourceFilterApis.deleteUserResourceFilterList(this.totalCheckParams);
    } catch ({ data }) {
      return data;
    }
  };

  //사용자 리소스 필터 생성
  @isRunning("isRunning")
  @action
  createFilter = async () => {
    const {
      resourceId,
      resourceName,
      serverGroupId,
      serverGroup,
      resourceFilterName,
      resourceFilterDescription,
      resourceFilterRiskLevel,
      isDetectTimeRangeUse,
      isDetectTimeRangeUseFlag,
      detectStartTimeFormat,
      detectEndTimeFormat,
      isUse,
      resourceFilterElementList,
    } = this.filterInfo;

    try {
      await userResourceFilterApis.createUserResourceFilter({
        resourceId,
        resourceName,
        serverGroupId,
        serverGroup,
        resourceFilterName,
        resourceFilterDescription,
        resourceFilterRiskLevel,
        isDetectTimeRangeUse,
        detectStartTime: isDetectTimeRangeUseFlag ? detectStartTimeFormat : null,
        detectEndTime: isDetectTimeRangeUseFlag ? detectEndTimeFormat : null,
        isUse,
        resourceFilterElementList,
      });
    } catch ({ data }) {
      return data;
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////

  //생성 초기화
  @withLoading("isLoading")
  @action
  initFilterInfo = async () => {
    if (this.isCopyCheck) {
      this.filterInfo.resourceFilterName = "";
      this.filterInfo.resourceFilterDescription = "";
      this.isCopyCheck = false;
      this.isCopy = true;
    } else {
      this.filterInfo = await new ApplicationFilterResourceModel(null, this.root.CodeStore);
      this.getServiceListForOption();
      this.getCodes();
      this.clearEventElementsList();
    }
  };

  //초기화
  @action
  clearFilterInfo = () => {
    if (this.isCopy) {
      this.isCopy = false;
    }
    if (!this.isCopyCheck) {
      this.filterInfo = new ApplicationFilterResourceModel(null, this.root.CodeStore);
      this.clearEventElementsList();
    }
  };

  //정보 입력 구간 초기화
  @action
  clearEventElementsList = () => {
    this.dataTypeSelects = [];
    this.originDataTypeSelects = [];
  };

  //서비스 리스트
  @action
  getServiceListForOption = async () => {
    try {
      const { list } = await resourceConfigUserApis.getResourceConfigUserGroupList();
      this.optionList = list.map((info) => new SelectOptionModel({ name: info.resourceName, code: info.resourceId }));
    } catch ({ data }) {
      return data;
    }
  };

  //서비스명 매칭
  @action
  getServiceName = async (resourceId) => {
    try {
      const serviceInfo = await this.optionList.find(({ value }) => value.code === resourceId);
      this.filterInfo.setInfo("resourceName", serviceInfo && serviceInfo.name);
    } catch ({ data }) {
      return data;
    }
  };

  @action
  getCodes = () => {
    this.root.ServerGroupStore.getListForOptions();
  };

  //그룹명 매칭
  @action
  getServerGroupInfo = async (serverGroupId) => {
    try {
      const { result } = await serverGroupApis.getServerGroupInfo(serverGroupId);
      this.filterInfo.setInfo("serverGroup", result.serverGroupInfo.serverGroup);
    } catch ({ data }) {
      return data;
    }
  };

  ///////check Valid////////
  dataTypeValidationCheck = (dataTypeElement) => {
    if (!dataTypeElement.filterElementName) throw new UserException(languageSet("메세지_요소선택"));

    if (dataTypeElement.elementAttributes && !dataTypeElement.filterElementAttributeName)
      throw new UserException(languageSet("메세지_속성선택"));

    if (dataTypeElement.filterEventElementAttributeValues && !dataTypeElement.filterElementAttributeValue)
      throw new UserException(languageSet("메세지_속성값선택"));

    if (dataTypeElement.filterEventElementRepeatSize > 0 && !dataTypeElement.filterElementRepeatSequence)
      throw new UserException(languageSet("메세지_요소수선택"));
  };

  validData = () => {
    try {
      let newDataTypeList: ResourceDataTypeApiModel[] = [];
      this.dataTypeSelects.forEach(({ selectedTypes, dataValues }) => {
        const dataTypeList: any = toJS(selectedTypes) as any; //요소선택내용들
        const dataTypeValues = toJS(dataValues) as ResourceDataTypeAttributeModel[]; // 데이터값

        const slectedLastIndex: number = dataTypeList.length - 1;
        const selectedLastElement = dataTypeList[slectedLastIndex];
        //텍스트 밸류에 엘리먼트 네임 삽입

        this.dataTypeValidationCheck(selectedLastElement);
        dataTypeList[0].resourceMetaId = selectedLastElement.resourceMetaId;

        if (dataTypeValues.length <= 0) throw new UserException(languageSet("메세지_요소선택"));

        //마지막 요소에 텍스트 밸류 삽입
        const lastDataType = dataTypeValues.map((element) => {
          if (element.resourceFilterElementSelectorValue) {
            return new ResourceDataTypeApiModel({
              resourceFilterElementSelectorId: element.resourceFilterElementSelectorId,
              resourceFilterElementSelectorType: element.resourceFilterElementSelectorType,
              resourceFilterElementSelectorOperator: element.resourceFilterElementSelectorOperator,
              resourceFilterElementSelectorValue: element.resourceFilterElementSelectorValue,
              resourceFilterElementSelectorOrderNo: element.resourceFilterElementSelectorOrderNo,
              resourceFilterElementId: element.resourceFilterElementId,
            });
          } else {
            throw new UserException(languageSet("메세지_데이터값입력"));
          }
        });

        dataTypeList[slectedLastIndex] = lastDataType;

        for (let i = slectedLastIndex; i >= 1; i--) {
          this.dataTypeValidationCheck(dataTypeList[i - 1]);

          if (i === slectedLastIndex) {
            dataTypeList[i - 1].resourceFilterElementSelectorList = dataTypeList[i];
          } else {
            dataTypeList[i - 1].resourceFilterElementSelectorList = [new ResourceDataTypeApiModel(dataTypeList[i])];
          }
        }

        if (!dataTypeList[0].resourceFilterElementSelectorList) {
          throw new UserException(languageSet("메세지_요소선택"));
        }

        newDataTypeList.push(dataTypeList[0]); // 데이터 값
      });

      if (this.filterInfo.isDetectTimeRangeUseFlag && !(this.filterInfo.detectStartTime && this.filterInfo.detectEndTime)) {
        throw new UserException(languageSet("메세지_시간선택"));
      }

      if (
        this.filterInfo.isDetectTimeRangeUseFlag &&
        this.filterInfo.detectStartTimeFormat === this.filterInfo.detectEndTimeFormat
      ) {
        throw new UserException(languageSet("메세지_시간설정다시"));
      }

      if (!this.filterInfo.serverGroupId) {
        throw new UserException(languageSet("메세지_시스템그룹을_선택"));
      }

      if (newDataTypeList.length === 0 || !this.filterInfo.resourceFilterName || !this.filterInfo.resourceFilterDescription) {
        throw new UserException(languageSet("에러_정보를입력해라"));
      }

      if (this.filterInfo.resourceFilterRiskLevel === "1") {
        throw new UserException(languageSet("메세지_위험도별설정체크"));
      }

      this.filterInfo.setInfo("resourceFilterElementList", newDataTypeList);

      return {
        isValid: true,
      };
    } catch ({ message }) {
      return {
        isValid: false,
        message: message,
      };
    }
  };

  //동작시간 체크
  validTime = () => {
    const {
      isDetectTimeRangeUseFlag,
      detectStartTime,
      detectEndTime,
      detectStartTimeFormat,
      detectEndTimeFormat,
    } = this.filterInfo;
    return validFilterTimeRange(
      isDetectTimeRangeUseFlag,
      detectStartTime,
      detectEndTime,
      detectStartTimeFormat,
      detectEndTimeFormat
    );
  };

  //동작 사용여부 일괄 수정
  @action
  updateCustomConfigIsUse = async (onOff) => {
    const params = {
      ...this.totalCheckParams,
      isUse: Common_Utils.BoolToString(onOff),
    };
    try {
      await userResourceFilterApis.isUseUpdateUserResourceFilter(params);
      this.getList();
    } catch ({ data }) {
      return data;
    }
  };

  //동작 사용여부 수정
  @action
  isUseUpdateUserResourceFilter = async (info) => {
    const isUseValue = Common_Utils.BoolToString(!info.isUseFlag);
    const params = {
      resourceFilterIds: [info.resourceFilterId],
      isUse: isUseValue,
    };
    try {
      const { rowCount } = await userResourceFilterApis.isUseUpdateUserResourceFilter(params);
      if (rowCount >= 1) info.setInfo("isUse", isUseValue);
    } catch ({ data }) {
      return data;
    }
  };

  //정보 입력 > 추가(요소 추가)
  @action
  addNewDataType = () => {
    const eventRootElement = this.filterEventElementsList[0];
    const param = {
      filterEventElementsList: this.filterEventElementsList,
      eventRootElement: eventRootElement,
    };
    this.dataTypeSelects.push(new ResourceDataTypeSelectorModel(param, this.filterInfo.resourceId));
  };

  //정보 입력 > 삭제(요소 삭제)
  @action
  deleteDataElement = (index) => {
    this.dataTypeSelects.splice(index, 1);
  };

  //정보 입력 > 삭제(요소 삭제)
  @action
  allDeleteDataElement = () => {
    this.dataTypeSelects = [];
  };

  //수정 시 가져온 요소 정보를 다시 원복시킴
  @action
  restoreDataElement = () => {
    this.dataTypeSelects = this.originDataTypeSelects;
  };

  @action
  allCheck = (checked) => {
    this.tableList.forEach((data) => {
      data.setInfo("isSelected", checked);
    });
  };
}

export default ApplicationFilterResourceStore;
