import { observable, action, toJS } from "mobx";

import { ListCondition, Common_Utils, withLoading, languageSet } from "@qubit-utill/src";

import { systemTotalLogApis } from "@src/apis/pLog";
import DefenceStore from "@src/common/stores/DefenceStore";
import { CSVINFO, UISET } from "@src/constants";
import { SYSLOGISSUETYPE } from "@src/constants/code";

import SystemTotalLogModel from "@src/pages/TotalLog/SystemTotalLog/Models/SystemTotalLogModel";

export default class SystemTotalLogStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }

  root;

  @observable
  tableList: SystemTotalLogModel[] = [];

  @observable
  systemTotalLogInfo = new SystemTotalLogModel({});

  @observable
  ipDefenceType = "DEFENSE_IP_SYSTEM";

  @observable
  autoDefenseInfo = {};

  @observable
  manualDefenseInfo = {};

  @withLoading("isLoading")
  @action
  getList = async () => {
    const tagServicePropertyList = this.tagServicePropertyList;
    const tagValueTypeList = this.tagValueTypeList;
    const getTagServiceDataList = this.openIpTagModal;

    try {
      const { list, totalCount, nextCursorMark } = await systemTotalLogApis.getSysLogList(toJS(this.params));
      this.tableList = list.map((info) => {
        const _info = { ...info, tagServicePropertyList, tagValueTypeList, getTagServiceDataList };
        return new SystemTotalLogModel(_info);
      });

      this.setCursorMarkInfo(nextCursorMark);
      this.totalCount = totalCount;
    } catch (e) {
      console.log("SystemTotalLogStore", e);
      this.tableList = [];
      this.totalCount = 0;
    }
  };

  @withLoading("isSubLoading")
  @action
  getInfo = async (documentId) => {
    try {
      const tagServicePropertyList = this.tagServicePropertyList;
      const tagValueTypeList = this.tagValueTypeList;
      const getTagServiceDataList = this.openIpTagModal;
      const getTagServiceDataListFn = this.getTagServiceDataList;

      const { info, viewTemplate, programMessageTemplate } = await systemTotalLogApis.getSysLog(documentId);

      const _info = {
        ...info,
        viewTemplate,
        programMessageTemplate,
        tagServicePropertyList,
        tagValueTypeList,
        getTagServiceDataList,
        getTagServiceDataListFn,
      };

      this.systemTotalLogInfo = new SystemTotalLogModel(_info);
      info.logClientIp && (await DefenceStore.getDefenceInfo(this.systemTotalLogInfo));

      this.root.TicketStore.getPrintTicketInfo(SYSLOGISSUETYPE, documentId, info.logCreateDate); //get 티켓발행정보
    } catch (err) {
      console.log(err);
      this.systemTotalLogInfo = new SystemTotalLogModel({});
    }
  };

  @action
  downloadCSV = async (isUseBulk?, no?) => {
    try {
      if (this.tableList.length > 0) {
        let csv;
        if (isUseBulk && no) {
          csv = await systemTotalLogApis.getSysLogCsvList({
            ...toJS(this.params),
            // serverIds: toJS(this.params.serverIds),
            // typeosList: toJS(this.params.typeosList),
            // serverGroupIds: toJS(this.params.serverGroupIds),
            offset: 0,
            limit: no,
          });
        } else {
          csv = Common_Utils.makeCSV(CSVINFO.TOTALLOG_SYS.head, this.tableList);
        }
        Common_Utils.csvDownload(
          csv,
          Common_Utils.setCsvFileName({ title: UISET.TOTALLOG_SYS.title, category: languageSet("전체로그"), ...this.params })
        );
      } else {
        return languageSet("에러_데이터가없습니다");
      }
    } catch ({ data }) {
      console.log(data);
    }
  };
}
