import axiosModules from "../axiosSetting";

export default {
  //pserver > pserver > os > 사용타입별 OS 목록 조회 ( osinfobytype )
  getOsListByType: (osInfoType, logType) => {
    return axiosModules.get(
      `/pserver/osinfobytype/type/${osInfoType}${logType ? `/${logType}` : ""}`
    );
  },
};
