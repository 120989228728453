import { languageSet, Common_Utils } from "@qubit-utill/src";

import getURI from "@src/constants/uriset";
import { DashBoardMainContainer } from "@src/pages";

import { LIMITLOGUPLOAD_LICENSECODE } from "@src/constants/licenseCode";

export default {
  DASHBOARD: {
    text: languageSet("대시보드"),
    link: getURI("DASHBOARD"),
    iconId: "fa fa-tachometer f-13",
    title: languageSet("대시보드"),
    component: DashBoardMainContainer,
    topCountList: [
      {
        title: languageSet("총_탐지건수"),
        count: 0,
        color: "or-card-main",
        code: "totalDetectCount",
        sm: "4",
      },
      {
        title: languageSet("등록_시스템"),
        count: 0,
        color: "",
        link: getURI("SERVER_MANAGEMENT"),
        code: "registerServerCount",
        sm: "4",
      },
      {
        title: languageSet("탐지로그_발생_시스템"),
        count: 0,
        color: "",
        link: getURI("SERVER_MANAGEMENT"),
        code: "detectServerCount",
        isAdditionalLink: true,
        sm: "4",
      },
      {
        title: languageSet("시스로그미업로드시스템"),
        count: 0,
        color: "",
        link: getURI("SERVER_MANAGEMENT"),
        code: "inActiveSystemLogAgentCount",
        isAdditionalLink: true,
        sm: "4",
      },
      {
        title: languageSet("웹로그미업로드시스템"),
        count: 0,
        color: "",
        link: getURI("SERVER_MANAGEMENT"),
        code: "inActiveWebLogAgentCount",
        isAdditionalLink: true,
        sm: "4",
      },
      {
        title: languageSet("로그업로드량"),
        count: 0,
        color: "or-card-anther",
        link: getURI("STATISTIC_UPLOAD"),
        code: "totalLogUploadBytes",
        isAdditionalLink: true,
        licenseCode: LIMITLOGUPLOAD_LICENSECODE,
        warningText: languageSet("업로드_중지"),
        valueFormater: (value) =>
          Common_Utils.numberWithComma(Common_Utils.getParseBytes("GB", value)) + " <span class='f-14'>GB</span>",
        sm: "4",
      },
    ],
  },
};

export interface topCountListType {
  title: string;
  count: number;
  color: string;
  link: string;
  code: string;
  action: any;
  isAdditionalLink: any;
  sm: string;
  licenseCode?: string;
  valueFormater?: Function;
  warningText?: string;
}
