import { action } from "mobx";

import { withLoading, UserException, Common_Utils } from "@qubit-utill/src";

import { pDetectFilterApis } from "@src/apis";
import IpFileUploadStore from "@src/common/components/organisms/Input/Buttons/FileUpload/stores/IpFileUploadStore";

class SystemIpFileUploadStore extends IpFileUploadStore {
  constructor() {
    super();
  }

  @withLoading("isLoading")
  @action
  getIpFileUploadValidCheck = async (fileList: string[] | undefined) => {
    try {
      if (!fileList || fileList.length === 0) return;

      const list = fileList
        .map((data, index) => {
          if (index === 0) return null;

          const splitList = data.split(",");

          const detectDefenseIp = (splitList[3] || "").trim();
          const defenseDescription = (splitList[4] || "").trim();
          const serverIp = (splitList[6] || "").trim();
          const hostName = (splitList[7] || "").trim();

          return {
            detectDefenseIp,
            defenseDescription,
            serverIp,
            hostName,
          };
        })
        .filter((data) => data && !Common_Utils.isEmpty(data.serverIp) && !Common_Utils.isEmpty(data.hostName));

      const { successList, successCount, failList, failCount } = await pDetectFilterApis.createDetectDefenseListCheck(list);

      this.successList = successList;
      this.successCount = successCount;
      this.failList = failList;
      this.failCount = failCount;
    } catch ({ data }) {
      this.successList = [];
      this.successCount = 0;
      this.failList = [];
      this.failCount = 0;

      throw new UserException(data.returnCodeMessage);
    }
  };

  @withLoading("isLoading")
  @action
  createIpFileUpload = async () => {
    try {
      if (!this.successList || this.successList.length === 0) return;
      await pDetectFilterApis.createDetectDefenseList(this.successList);
    } catch ({ data }) {
      throw new UserException(data.returnCodeMessage);
    }
  };
}

export default SystemIpFileUploadStore;
