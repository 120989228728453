import { axiosModules } from "@qubit-utill/src";

export default {
  // plogdetect > 웹확장필터 탐지로그 > 웹 확장필터 탐지로그 조회
  getWebLogExtendsDetect: (documentId) => {
    return axiosModules.get(`/plogdetect/weblogextendsdetect/${documentId}`);
  },

  // plogdetect > 웹확장필터 탐지로그 > 웹 확장필터 탐지로그 리스트 조회
  getWebLogExtendsDetectList: (params) => {
    return axiosModules.get("/plogdetect/weblogextendsdetect/list?webExtendsFilterType=webextendsdatabreach", params);
  },

  // plogdetect > 웹확장필터 탐지로그 > 웹 확장필터 탐지로그 리스트 CSV 다운로드
  getWebLogExtendsDetectCsvList: (param) => {
    return axiosModules.getCsv("/plogdetect/weblogextendsdetect/list/csv", param);
  },
};
