import { action } from "mobx";

import { pCustomerApis } from "@src/apis";

class UserActionLogStore {
  @action
  createCustomUserActionLog = async (categoryGroupType, categoryType, userActionLogMessage?) => {
    await pCustomerApis.createCustomUserActionLog({
      userActionCategoryGroupType: categoryGroupType,
      userActionCategoryType: categoryType,
      userActionLogMessage,
    });
  };
}

export default new UserActionLogStore();
