import { axiosModules } from "@qubit-utill/src";

export default {
  //탭메뉴
  getFilterCategoryGroupDayList: (params) => {
    return axiosModules.get("/pstats/wafdetect/day/categorygroup", params);
  },
  //리스트
  getWafDayMainDetect: (params) => {
    return axiosModules.get("/pstats/wafdetect/day/main", params);
  },
  //HOST리스트
  getWafDayMainDetectHost: (params) => {
    return axiosModules.get("/pstats/wafhostdetect/day/main", params);
  },
  //차트
  getWafDayMainChart: (params) => {
    return axiosModules.get("/pstats/wafdetect/day/mainchart", params);
  },
  //서브 차트
  getWafDaySubChart: (params) => {
    return axiosModules.get("/pstats/wafdetect/day/subchart", params);
  },
  //리스트 주별
  getWafWeekMainDetect: (params) => {
    return axiosModules.get("/pstats/wafdetect/week/main", params);
  },
  //HOST리스트 주별
  getWafWeekMainDetectHost: (params) => {
    return axiosModules.get("/pstats/wafhostdetect/week/main", params);
  },
  //차트 주별
  getWafWeekMainChart: (params) => {
    return axiosModules.get("/pstats/wafdetect/week/mainchart", params);
  },
  //차트 주별
  getWafWeekSubChart: (params) => {
    return axiosModules.get("/pstats/wafdetect/week/subchart", params);
  },
  //리스트 월별
  getWafMonthMainDetect: (params) => {
    return axiosModules.get("/pstats/wafdetect/month/main", params);
  },
  //HOST리스트 월별
  getWafMonthMainDetectHost: (params) => {
    return axiosModules.get("/pstats/wafhostdetect/month/main", params);
  },
  //차트 월별
  getWafMonthMainChart: (params) => {
    return axiosModules.get("/pstats/wafdetect/month/mainchart", params);
  },
};
