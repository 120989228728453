import { observable, computed } from "mobx";

import { Common_Utils, SetterModel } from "@qubit-utill/src";

class ServerGroupModel extends SetterModel {
  constructor(params?) {
    super(params);
    if (params) {
      this.serverGroupName = params.serverGroup;
    }
  }

  @computed
  get disableCheckbox() {
    return Common_Utils.isCheckBool(this.isDefault);
  }

  @computed
  get defaultFlag() {
    return Common_Utils.isCheckBool(this.isDefault);
  }

  @computed
  get hasServerTotalCount() {
    return this.serverGroupOsCount && this.serverGroupOsCount.reduce((acc, cur) => acc + cur.count, 0);
  }

  @observable
  uuid: string = "";

  @observable
  isSelected: boolean = false;

  @observable
  serverGroupName: string = ""; //서버그룹명

  @observable
  filterCustomConfigCount: number = 0;

  @observable
  serverGroupOsCount: Array<{
    serverGroupId: number;
    typeos: string;
    count: number;
  }> = []; //서버그룹내 서버들

  @observable
  memberCount: number = 0;

  @observable
  registerName: string = ""; //등록자

  @observable
  registerDate: string = "";

  @observable
  serverGroupId: number = 0; //serverGroupId

  @observable
  serverGroup;

  @observable
  isDefault: string = "";

  @observable
  registerUuid: string = "";

  @observable
  serverGroupList: number = 0;

  @observable
  checked: boolean = false;

  @observable
  name: string = "";

  @observable
  value: number = 0;

  @observable
  userName: string = "";

  @observable
  isServerGroupAll: string = "";
}

export default ServerGroupModel;
