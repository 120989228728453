import { languageSet } from "@qubit-utill/src";

import getURI from "@src/constants/uriset";
import { COMPLIANCE_LICENSECODE } from "@src/constants/licenseCode";

import { ComplianceContainer } from "@src/pages";

export default {
  COMPLIANCE: {
    link: getURI("COMPLIANCE"),
    title: languageSet("컴플라이언스"),
    text: languageSet("컴플라이언스"),
    iconId: "fa fa-globe f-15",
    licenseCode: COMPLIANCE_LICENSECODE,
    exact: true,
    component: ComplianceContainer,
  },
};
