import { globalType } from "@qubit-utill/src";
import { TABLE, TD, SPAN } from "@src/constants/tableStyleCode/tableStyleCode";
declare global {
  interface Window {
    GlobalPathMap: globalType;
  }
}

const { COOKIENAME, ONPREMISE, COOKIELANG, RESTOREURL, IS_ACTIVELIVE } = window.GlobalPathMap || {
  COOKIENAME: "",
  ONPREMISE: "false",
  COOKIELANG: "ko",
  IS_ACTIVELIVE: false,
};

const LINECOUNT = 20;
const DEFAULTLANG = "KO";
const LANGCOOKIENAME = "LANG";
const LANG = "ko";
const STYLE = { TABLE, TD, SPAN };

export { DASHBOARDCUTOM, DASHBOARDTYPE, EXTORTACCOUNT } from "./code";
export { default as getURI } from "./uriset";
export { default as UISET } from "./uiset/index";
export { default as path } from "./path";
export { LINECOUNT, DEFAULTLANG, COOKIENAME, ONPREMISE, COOKIELANG, LANG, LANGCOOKIENAME, RESTOREURL, IS_ACTIVELIVE };
export { CSVINFO } from "./csvInfo";
export { CONTEXT } from "./contextMenu/contextMenu";
export { STYLE };
export { default as linkUrlCode } from "./linkUrlCode";
export { default as licenseCode } from "./licenseCode";
