import React from "react";

import { useInjectStore } from "@qubit-utill/src";

import { StackBar } from "@src/common/components/organisms/Graphs/ApexCharts";

const WeekChart = () => {
  const { ReportStore } = useInjectStore();
  const { weeklyGraphTitle, weekChartData } = ReportStore;
  return (
    <div className="h-273">
      <StackBar id={weeklyGraphTitle} title={weeklyGraphTitle} category={weekChartData.category} data={weekChartData.chartData} />
    </div>
  );
};

export default WeekChart;
