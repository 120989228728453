import { observable, action, computed } from "mobx";

import { withLoading, ListCondition } from "@qubit-utill/src";

import { filterApis, filterCategoryApis } from "@src/apis/pFilter";
import { logTagApis } from "@src/apis/pServer";

import { ApplicationRecommendFilterModel } from "@src/pages/Filter/RecommendFilter/models";

class ApplicationRecommendFilterStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }

  root;

  @observable
  isModalLoading = true;

  @observable
  filterInfo = new ApplicationRecommendFilterModel(null, this.root.CodeStore);

  @observable
  tableList: Array<ApplicationRecommendFilterModel> = [];

  @observable
  totalCount = 0;

  @observable
  osList = [];

  @observable
  logTagList: Array<object> = [];

  @observable
  filterCategoryId: number = 1;

  @computed
  get checkList() {
    return this.tableList.filter((data) => data.isSelected);
  }

  @action
  clearStore = () => {
    this.filterInfo = new ApplicationRecommendFilterModel(null, this.root.CodeStore);
  };

  @withLoading("isLoading")
  @action
  getList = async () => {
    try {
      const { list, totalCount } = await filterApis.getFilterList(this.params, "application");
      this.tableList = list.map((data) => new ApplicationRecommendFilterModel(data, this.root.CodeStore));
      this.totalCount = totalCount;
    } catch ({ data }) {
      this.tableList = [];
      this.totalCount = 0;
    }
  };

  @withLoading("isLoading")
  @action
  getRecommendFilterInfo = async (filterId) => {
    try {
      const { info } = await filterApis.getFilterInfo(filterId);
      this.filterInfo = new ApplicationRecommendFilterModel(info, this.root.CodeStore);
    } catch ({ data }) {
      console.log(data);
    }
  };

  @withLoading("isModalLoading")
  @action
  getFilterOsCount = async () => {
    try {
      const { list } = await filterApis.getFilterOsCount();
      this.osList = list;
    } catch (error) {}
  };

  @action
  getCodes = async () => {
    await this.root.CodeStore.getCodeList("filterRiskLevel");
  };

  @action
  getRecommendFilterInfoWithCode = async (filterId) => {
    await this.getCodes();
    this.getRecommendFilterInfo(filterId);
  };

  @action
  getLogTagList = async () => {
    try {
      this.logTagList = [];
      const { list } = await logTagApis.getLogTagList({ limit: 99999 });
      list.map((log: object) => this.logTagList.push({ name: log["logTag"], value: log["logTag"] }));
    } catch {
      this.logTagList = [];
    }
  };

  @action
  getFilterCategoryId = async () => {
    const { list } = await filterCategoryApis.getFilterCategoryList("applicationfilter");
    this.filterCategoryId = list[0].filterCategoryId;
  };

  @action
  allCheck = (checked) => {
    this.tableList.forEach((data) => {
      data.setInfo("isSelected", checked);
    });
  };

  @observable
  isTotalCheck = false;

  @action
  setIsTotalCheck = (flag) => {
    this.isTotalCheck = flag;
  };
}

export default ApplicationRecommendFilterStore;
