import { observable, action, computed } from "mobx";

import { withLoading, ListCondition, arrayForSelectBox, UserException, languageSet } from "@qubit-utill/src";

import { codeApis } from "@src/apis";
import { filterApis, filterCustomConfigApis, filterElementReferenceApis } from "@src/apis/pFilter";

import { DataTypeAttributeModel, DataTypeForApiModel } from "@src/pages/Filter/RegisterFilter/model/DataType";
import { NetworkRecommendFilterModel } from "@src/pages/Filter/RecommendFilter/models";

class NetworkRecommendFilterStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }

  root;

  @observable
  isModalLoading = true;

  @observable
  filterInfo: NetworkRecommendFilterModel = new NetworkRecommendFilterModel(null, this.root.CodeStore);

  @observable
  tableList: Array<NetworkRecommendFilterModel> = [];

  @observable
  totalCount = 0;

  @observable
  osList = [];

  //추천필터등록에서 유형에 해당하는 radioButton list
  @observable
  filterDetectTypeList = [];

  @observable
  filterRiskList = [];

  @observable
  filterElementReferenceListForSelect = [];

  @observable
  dataTypeSelects: Array<DataTypeAttributeModel> = [];

  @observable
  filterEventElementsList = [];

  @observable
  tabInfo = "";

  @computed
  get checkList() {
    return this.tableList.filter((data) => data.isSelected);
  }

  @action
  clearStore = () => {
    this.filterInfo = new NetworkRecommendFilterModel(null, this.root.CodeStore);
  };

  @withLoading("isLoading")
  @action
  getList = async () => {
    try {
      const { list, totalCount } = await filterApis.getFilterList(this.params, "network");
      this.tableList = list.map((data) => new NetworkRecommendFilterModel(data, this.root.CodeStore));
      this.totalCount = totalCount;
    } catch ({ data }) {
      this.tableList = [];
      this.totalCount = 0;
    }
  };

  @withLoading("isLoading")
  @action
  getRecommendFilterInfo = async (filterId) => {
    try {
      const { info } = await filterApis.getFilterInfo(filterId);
      this.filterInfo = new NetworkRecommendFilterModel(info, this.root.CodeStore);
    } catch ({ data }) {
      console.log(data);
    }
  };

  // 복사된 추천필터로 사용자필터 등록
  @action
  createFilter = async () => {
    this.filterInfo.setInfo("filterElementAdditionalJson", this.dataTypeSelects);

    const {
      filterType,
      refFilterId,
      filterDetectType,
      isDetectDisplay,
      isUse,
      isUseCustom,
      isGlobalFilter,
      recommendFilterRiskLevel,
      isCustomFilter,
      recommendFilterName,
      recommendFilterDescription,
      recommendFilterUserDescription,
      isUseUserDescription,
      filterCategoryId,
      recommendFilterType,
      filterEventId,
      filterElementAdditionalJson,
      serverGroup,
      serverGroupId,
    } = this.filterInfo;

    try {
      const params = {
        filterType,
        refFilterId,
        filterDetectType,
        isDetectDisplay,
        isUse,
        isUseCustom,
        isGlobalFilter,
        filterRiskLevel: recommendFilterRiskLevel,
        isCustomFilter,
        filterName: recommendFilterName,
        filterDescription: recommendFilterDescription,
        recommendFilterUserDescription,
        isUseUserDescription,
        filterCategoryId,
        filterCategoryName: recommendFilterType,
        filterEventId,
        filterElementAdditionalJson,
        serverGroup,
        serverGroupId,
      };
      await filterApis.createFilter(params);
    } catch ({ data }) {
      console.log(data);
    }
  };

  @withLoading("isLoading")
  @action
  initFilterInfo = async (filterCustomConfigId) => {
    try {
      const { info } = await filterCustomConfigApis.getFilterCustomConfigInfo(filterCustomConfigId);
      this.filterInfo = new NetworkRecommendFilterModel(info, this.root.CodeStore);
      this.filterInfo.setInfo("recommendFilterName", "");
      this.filterInfo.setInfo("recommendFilterDescription", "");
      this.getTypeosFamily();
    } catch ({ data }) {
      console.log(data);
    }
  };

  @withLoading("isModalLoading")
  @action
  getFilterOsCount = async () => {
    try {
      const { list } = await filterApis.getFilterOsCount();
      this.osList = list;
    } catch (error) {}
  };

  @action
  getFilterRiskLevelCodes = async () => {
    await this.root.CodeStore.getCodeList("filterRiskLevel");
  };

  @action
  getRecommendFilterInfoWithCode = async (filterId) => {
    await this.getFilterRiskLevelCodes();
    this.getRecommendFilterInfo(filterId);
  };

  @action
  getFilterRiskList = async () => {
    const { list } = await codeApis.getCodeList("filterRiskLevel");
    const _list = list.map((code) => ({ code: code.code, codeName: code.codeName }));
    this.filterRiskList = _list;
  };

  @action
  getFilterDetectTypeList = async () => {
    const { list } = await codeApis.getCodeList("filterDetectType");
    const _list = list.map((code) => ({ code: code.code, codeName: code.codeName }));
    this.filterDetectTypeList = _list;
  };

  @action
  getTypeosFamily = async () => {
    try {
      this.tabInfo === "system"
        ? await this.root.CodeStore.getOsListByType("filter")
        : await this.root.CodeStore.getOsListByType("networkfilter");

      const osList = this.tabInfo === "system" ? this.root.CodeStore.osList.filter : this.root.CodeStore.osList.networkfilter;

      if (osList) {
        osList.osFamily.forEach(({ typeosList, osFamily }) => {
          if (typeosList.indexOf(this.filterInfo.recommendFilterOs) > -1 && osFamily) {
            this.filterInfo.setInfo("osFamily", osFamily);
          }
        });
      }
    } catch ({ data }) {
      console.log("error", data);
    }
  };

  @action
  allCheck = (checked) => {
    this.tableList.forEach((data) => {
      data.setInfo("isSelected", checked);
    });
  };

  @observable
  isTotalCheck = false;

  @action
  setIsTotalCheck = (flag) => {
    this.isTotalCheck = flag;
  };

  // filterElementHandler
  @action
  getFilterElementList = async () => {
    const params = {
      limit: 9999,
      isUseManager: "0",
    };
    try {
      const { list } = await filterElementReferenceApis.getFilterElementReferenceList(params);
      this.filterElementReferenceListForSelect = arrayForSelectBox.parseValueObjectForSelectBox(
        list,
        "filterElementReferenceName"
      );
      this.dataTypeSelects = [
        new DataTypeAttributeModel({
          filterElementSearchType: "select",
        }),
      ];
    } catch ({ data }) {
      this.filterElementReferenceListForSelect = [];
      console.log(data);
    }
  };

  @action
  addDataTypeAttributeHandler = () => {
    this.dataTypeSelects.push(
      new DataTypeAttributeModel({
        filterElementSearchType: "select",
      })
    );
  };

  @action
  deleteDataTypeAttributeHandler = (index) => {
    this.dataTypeSelects.splice(index, 1);
  };

  @action
  validData = () => {
    try {
      if (!this.filterInfo.recommendFilterName || !this.filterInfo.recommendFilterDescription) {
        throw new UserException(languageSet("에러_정보를입력해라"));
      }

      if (this.filterInfo.isDetectTimeRangeUseFlag && !(this.filterInfo.detectStartTime && this.filterInfo.detectEndTime)) {
        throw new UserException(languageSet("메세지_시간선택"));
      }

      if (
        this.filterInfo.isDetectTimeRangeUseFlag &&
        this.filterInfo.detectStartTimeFormat === this.filterInfo.detectEndTimeFormat
      ) {
        throw new UserException(languageSet("메세지_시간설정다시"));
      }

      let newDataTypeList = [];
      this.dataTypeSelects.forEach((data) => {
        if (data.filterElementSearchText) {
          return new DataTypeForApiModel({
            filterElementRepeatSequence: data.filterElementRepeatSequence,
            filterElementSearchText: data.filterElementSearchText,
            filterElementSearchType: data.filterElementSearchType,
            filterElementSearchFlag: data.filterElementSearchFlag,
          });
        } else {
          throw new UserException(languageSet("메세지_데이터값입력"));
        }
      });

      this.filterInfo.setInfo("filterElementAdditionalJson", newDataTypeList);

      return {
        isValid: true,
      };
    } catch ({ message }) {
      return {
        isValid: false,
        message: message,
      };
    }
  };
}

export default NetworkRecommendFilterStore;
