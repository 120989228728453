import { languageSet } from "@qubit-utill/src";

import { DEFENCE_LICENSECODE } from "@src/constants/licenseCode";
import getURI from "@src/constants/uriset";

import { DefenseLogContainer } from "@src/pages";

export default {
  //방어 > 로그 : 시스템
  DEFENSE_LOG_SYSTEM: {
    title: languageSet("시스템"),
    withPageLink: `${getURI("DEFENSE_LOG_SYSTEM")}/page/:page`,
    link: getURI("DEFENSE_LOG_SYSTEM"),
    licenseCode: DEFENCE_LICENSECODE,
    component: DefenseLogContainer,
  },

  //방어 > 로그 : 웹방화벽
  DEFENSE_LOG_WEBFIREWALL: {
    title: languageSet("웹방화벽"),
    withPageLink: `${getURI("DEFENSE_LOG_WEBFIREWALL")}/page/:page`,
    link: getURI("DEFENSE_LOG_WEBFIREWALL"),
    licenseCode: DEFENCE_LICENSECODE,
    component: DefenseLogContainer,
  },
};
