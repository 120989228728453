import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

const RadioButton = ({
  onSelect,
  disabled,
  children,
  type,
  color,
  isChecked,
  tag: TagComponent,
  className,
  labelClassName,
}) => {
  const _onSelect = e => {
    e.preventDefault();
    if (!disabled) {
      onSelect && onSelect();
    }
  };

  const cursorStyle = disabled ? { cursor: "inherit" } : {};
  const typeColor = disabled ? "iradio_flat-aero" : `iradio_${type}${color ? "-" : ""}${color}`;

  return (
    <label onClick={_onSelect} className={labelClassName}>
      <TagComponent
        className={`${typeColor} ${isChecked && "checked"} ${className} m-r-3`}
        style={{ position: "relative", ...cursorStyle }}
      >
        <input
          type="radio"
          name="radio1"
          checked=""
          style={{ position: "absolute", opacity: 0 }}
          readOnly
        />
        <ins
          className={`iCheck-helper ${disabled ? "cursor_d" : "cursor_p"}`}
          style={{
            position: "absolute",
            top: "0%",
            left: "0%",
            display: "block",
            width: "100%",
            height: "100%",
            margin: "0px",
            padding: "0px",
            background: "rgb(255, 255, 255)",
            border: 0,
            opacity: 0,
          }}
        />
      </TagComponent>
      {children}
    </label>
  );
};

RadioButton.propTypes = {
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  color: PropTypes.string,
  tag: PropTypes.string,
  className: PropTypes.string,
};

RadioButton.defaultProps = {
  disabled: false,
  type: "flat",
  color: "blue",
  tag: "div",
  className: "",
};

export default observer(RadioButton);
