import { languageSet } from "@qubit-utill/src";

import getURI from "@src/constants/uriset";
import { EXTRACTIONLOGIN_LICENSECODE, WEB_LICENSECODE } from "@src/constants/licenseCode";
import { IpExtractionContainer, UrlExtractionContainer, LoginExtractionContainer } from "@src/pages";

export default {
  EXTRACTION_IP: {
    text: languageSet("IP주소"),
    link: getURI("EXTRACTION_IP"),
    withPageLink: `${getURI("EXTRACTION_IP")}/page/:page`,
    component: IpExtractionContainer,
  },
  EXTRACTION_LOGIN: {
    text: "Login",
    link: getURI("EXTRACTION_LOGIN"),
    withPageLink: `${getURI("EXTRACTION_LOGIN")}/page/:page`,
    licenseCode: EXTRACTIONLOGIN_LICENSECODE,
    component: LoginExtractionContainer,
  },
  EXTRACTION_URL: {
    text: "URL",
    link: getURI("EXTRACTION_URL"),
    withPageLink: `${getURI("EXTRACTION_URL")}/page/:page`,
    licenseCode: WEB_LICENSECODE,
    component: UrlExtractionContainer,
  },
};
