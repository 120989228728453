import { axiosModules } from "@qubit-utill/src";

export default {
  // 사용자 백업 설정 정보
  getLogBackupInfo: () => {
    return axiosModules.get("/pcustomer/logbackup");
  },

  // 기존 어드민 회사정보
  getCompanyInfo: (guid: string) => {
    return axiosModules.get(`/paccount/company/${guid}`);
  },
};
