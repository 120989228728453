import { axiosModules } from "@qubit-utill/src";

export default {
  getForgeryFilterList: () => {
    return axiosModules.get(`/pfilter/servicelinkedfilter/fileforgery`);
  },
  getAuditCustomFilterInfo: (param) => {
    return axiosModules.get(`/pfilter/auditcustomfile`, param);
  },
  updateAuditCustomFilterInfo: (param) => {
    return axiosModules.post(`/pfilter/auditcustomfile`, param);
  },
};
