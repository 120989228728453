import { axiosModules } from "@qubit-utill/src";

interface params {
  serverGroupIds?: number[];
  detectDate?: string;
  blocked?: string;
  detectStatusCodeRange?: string;
  detectHours?: string;
}
const DATABREACH_PARAM = "?webExtendsFilterType=webextendsdatabreach";

export default {
  //웹 detectDate: ?detectDate=2019-09-25
  getDashBoardWebDetect: (params: params) => {
    return axiosModules.get("/pstats/dashboard/web", params);
  },
  //시스템
  getDashBoardSystemDetect: (params: params) => {
    return axiosModules.get("/pstats/dashboard/system", params);
  },
  //네트워크 ?detectDate=2019-09-11
  getDashBoardNetworkDetect: (params: params) => {
    return axiosModules.get("/pstats/dashboard/network", params);
  },
  //카운트
  getDashBoardSummary: (params: object) => {
    return axiosModules.get("/pstats/dashboard/summary", params);
  },
  //계정탈취
  getDashBoardDdosDetect: (params: params) => {
    return axiosModules.get("/pstats/dashboard/dos", params);
  },
  //상관분석
  getDashBoardPackageDetect: (params: params) => {
    return axiosModules.get("/pstats/dashboard/package", params);
  },
  //커스텀 대시보드
  getDashBoardCustom: (params?: params) => {
    return axiosModules.get("/pstats/dashboard/custom", params);
  },
  //커스텀 필터
  getDashBoardCustomByFilter: (params: object) => {
    return axiosModules.get("/pstats/dashboard/custom/filtersum", params);
  },
  //웹방화벽
  getDashBoardWafDetect: (params: params) => {
    return axiosModules.get("/pstats/dashboard/waf", params);
  },
  //데이터유출
  getDashBoardWebExtendsDataBreachDetect: (params: params) => {
    return axiosModules.get("/pstats/dashboard/webextends" + DATABREACH_PARAM, params);
  },
  //마이터
  getDashBoard: (type, params) => {
    return axiosModules.get(`/pstats/dashboard/${type}`, params);
  },
  //응용프로그램
  getDashBoardApplicationDetect: (params: params) => {
    return axiosModules.get("/pstats/dashboard/application", params);
  },
  //리소스(사용자)
  getDashBoardResourceDetect: (params) => {
    return axiosModules.get(`/pstats/dashboard/resource/${params.resourceId}`, params);
  },
  //호스트보안
  getDashBoardHostsecurityDetect: (params: params) => {
    return axiosModules.get("/pstats/dashboard/edr", params);
  },
  ////////////////////////////////////////
  //로그포렌식 대시보드 집계 정보
  getForensicDashBoardSummary: (params: object) => {
    return axiosModules.get("/pstats/forensic/dashboard/summary", params);
  },
  //로그포렌식 대시보드 년별
  getForensicDashBoardYearly: (type, params) => {
    return axiosModules.get(`/pstats/forensic/dashboard/${type}/yearlysum`, params);
  },
  //로그포렌식 대시보드 월별
  getForensicDashBoardMonthly: (type, params) => {
    return axiosModules.get(`/pstats/forensic/dashboard/${type}/monthlysum`, params);
  },
  //로그포렌식 대시보드 일별
  getForensicDashBoardDaily: (type, params) => {
    return axiosModules.get(`/pstats/forensic/dashboard/${type}/dailysum`, params);
  },
  //로그포렌식 대시보드 마이터 전술 매트릭스
  getForensicDashBoardTacticsmatrix: (params) => {
    return axiosModules.get(`/pstats/forensic/dashboard/mitre/tacticsmatrix`, params);
  },
};
