import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { action, observable } from "mobx";

const WithToggle = WrappedComponent =>
  observer(
    class WithToggle extends Component {
      @observable
      isToggle = false;

      @action
      setToggle = e => {
        e && e.preventDefault();
        this.isToggle = !this.isToggle;
      };

      @action
      onMouseOver = e => {
        e && e.preventDefault();
        this.isToggle = true;
      };

      @action
      onMouseLeave = e => {
        e && e.preventDefault();
        this.isToggle = false;
      };

      render() {
        return (
          <WrappedComponent
            {...this.props}
            toggle={this.isToggle}
            setToggle={this.setToggle}
            onMouseOver={this.onMouseOver}
            onMouseLeave={this.onMouseLeave}
          />
        );
      }
    }
  );

WithToggle.propTypes = {
  WrappedComponent: PropTypes.func.isRequired,
};

export default WithToggle;
