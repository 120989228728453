import { axiosModules } from "@qubit-utill/src";

export default {
  //pdetectfilter > 마이터 > 마이터 탐지 조회
  getDetectFilterMitreInfo: ({ detectMitreTechniqueFilterNo }) => {
    return axiosModules.get(`/pdetectfilter/detectmitretechniquefilter/${detectMitreTechniqueFilterNo}`);
  },

  //pdetectfilter > 마이터 > 마이터 탐지목록 조회
  getDetectFilterMitreList: (params) => {
    return axiosModules.get("/pdetectfilter/detectmitretechniquefilters", params);
  },
};
