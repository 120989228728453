import { observable } from "mobx";

export default class DataTypeForApiModel {
  constructor(param) {
    this.filterElementName = param.filterElementName || param.filterEventElementName;
    this.filterElementAttributeValue = param.filterElementAttributeValue || null;
    this.filterElementAttributeName = param.filterElementAttributeName || null;
    this.filterElementRepeatSequence = param.filterElementRepeatSequence || null;
    this.filterElementSearchText = param.filterElementSearchText || null;
    this.filterElementSearchType = param.filterElementSearchType || null;
    this.filterElementSearchFlag = param.filterElementSearchFlag || null;
    this.childArray = param.childArray || null;
    this.filterEventElementId = param.filterEventElementId || null;
  }

  @observable
  filterElementName: string | null;
  @observable
  filterElementAttributeValue: string | null;
  @observable
  filterElementAttributeName: string | null;
  @observable
  filterElementRepeatSequence: number | null;
  @observable
  filterElementSearchText: string | null;
  @observable
  filterElementSearchType: string | null;
  @observable
  filterElementSearchFlag?: any;
  @observable
  childArray: DataTypeForApiModel[] | DataTypeForApiModel | null;
  @observable
  filterEventElementId: string | null;
}
