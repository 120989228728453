import React, { useState } from "react";
import Chart from "react-apexcharts";
import { observer } from "mobx-react";
import { toJS } from "mobx";

import { languageSet, Common_Utils } from "@qubit-utill/src";

import UISET from "@src/constants/uiset";
import NullLogData from "@src/common/components/organisms/Graphs/NullLogData";

interface propsType {
  id: string;
  isResource?: boolean;
  graphInfo?: any;
  category?: Array<string>;
  graphOption: any;
  isUseCustomSeries?: boolean;
  customSeries?: Array<{ name: string; data: any }>;
  isDetail?: boolean;
  unit?: string;
  title?: string;
  useOnClick?: boolean;
}

const Line: React.FC<propsType> = ({
  id,
  isResource = false,
  graphInfo,
  category,
  graphOption = {},
  isUseCustomSeries = false,
  customSeries,
  isDetail,
  unit,
  title,
  useOnClick,
}) => {
  const [yTickAmount, setYTickAmount] = useState<number | undefined>(undefined);
  const [maxValue, setMaxValue] = useState(0);
  const _category =
    category && category.length > 0 ? toJS(category) : graphInfo?.category ? toJS(graphInfo.category.map((time) => time)) : [];
  const _unit = isResource ? graphInfo.unit : unit;

  const defaultSeries = [
    {
      name: languageSet("금일"),
      data: isResource ? (isDetail ? graphInfo.currentDetailData : graphInfo.currentData) : [],
    },
    {
      name: languageSet("전일"),
      data: isResource ? (isDetail ? graphInfo.beforeDetailData : graphInfo.beforeData) : [],
    },
  ];

  const series = isUseCustomSeries && customSeries && customSeries.length > 0 ? toJS(customSeries) : toJS(defaultSeries);

  const getMaxValue = () => {
    let allData: Array<number> = [];
    series.forEach(({ data }) => (allData = [...allData, ...data]));
    setMaxValue(Math.max(...allData));
  };

  const options = {
    tooltip: {
      enabled: graphOption?.tooltip?.enabled ?? true,
      y: {
        formatter: function(value, opts) {
          const val = Common_Utils.numberWithComma(value);
          const nullValue = !Common_Utils.isNull(val);
          return nullValue ? null : val && _unit ? val + _unit : val;
        },
      },
      x: {
        formatter: function(value, opts) {
          const nullValue = opts.series.every((seriesItem) => {
            const data = seriesItem[opts.dataPointIndex];
            return !Common_Utils.isNull(data);
          });
          const xLabels = opts.w.globals.categoryLabels[opts.dataPointIndex];
          return nullValue ? null : xLabels;
        },
      },
    },
    xaxis: {
      categories: isDetail ? graphInfo.detailCategory : _category,
      labels: {
        style: graphOption.labelStyle || {},
      },
      tickAmount: graphOption?.xTickAmount || undefined,
      tooltip: { enabled: false },
    },
    yaxis: {
      show: graphOption?.yaxis?.show === undefined ? true : graphOption?.yaxis?.show,
      labels: {
        formatter: function(val, index) {
          return Common_Utils.numberWithComma(val)?.toString() as string;
        },
      },
      tooltip: { enabled: false },
      tickAmount: yTickAmount,
    },
    chart: {
      height: graphOption?.chart?.height || "auto",
      id: id,
      events: {
        xAxisLabelClick: graphOption.isUseXLabelClick ? graphOption.events.xAxisLabelClick : undefined,
        markerClick: (e, chartContext, { dataPointIndex }) =>
          graphOption.isUseMarkerClick ? graphOption.events.markerClick(_category[dataPointIndex]) : undefined,
        mounted: (chartContext, config) => {
          setYTickAmount(config.globals.maxY > 3 ? undefined : 3);
          getMaxValue();
        },
        updated: (chartContext, config) => setYTickAmount(config.globals.maxY > 3 ? undefined : 3),
        //dataPointMouseEnter: 활성화 조건있음
        //tooltip.intersect: true & tooltip.shared: false along with markers.size has to be greater than 0
        dataPointMouseEnter: function(event) {
          if (useOnClick) {
            event.target.style.cursor = "pointer";
          }
        },
        mouseMove: function(event) {
          if (useOnClick) {
            event.target.style.cursor = "pointer";
          }
        },
      },
      toolbar: {
        show: false,
        tools: {
          zoom: false,
          zoomin: false,
          zoomout: false,
          selection: false,
          reset: false,
          pan: false,
        },
      },
      animations: {
        enabled: graphOption?.animation,
        easing: "easeout" as const,
        speed: 500,
      },
    },
    colors: isResource ? ["#3682f4", "#a7a7a7"] : UISET.CHART_COLOR,
    stroke: graphOption?.stroke || {},
    legend: {
      offsetX: graphOption?.legendOffsetX || 0,
      offsetY: graphOption?.legendOffsetY || 0,
      showForSingleSeries: true,
    },
    markers: graphOption?.markers || {
      size: 3,
      strokeWidth: 0,
      hover: {
        sizeOffset: 0,
      },
    },
    dataLabels: {
      enabled: graphOption?.dataLabels?.enabled === undefined ? true : graphOption.dataLabels.enabled,
      offsetY: graphOption?.dataLabels?.offsetY || 0,
      background: graphOption?.dataLabels?.background || {
        enabled: false,
      },
      style: graphOption?.dataLabels?.style || {
        colors: [
          function(opts) {
            const { dataPointIndex, series, seriesIndex } = opts;
            const value = series[seriesIndex][dataPointIndex];
            const isMax = value === maxValue;
            return isMax ? "#ff0000" : "#000000";
          },
        ],
      },
      textAnchor: graphOption?.dataLabels?.textAnchor || "middle",
      formatter:
        graphOption?.dataLabels?.formatter ||
        function(val) {
          if (val === 0 || val) {
            return Common_Utils.numberWithComma(val)?.toString();
          }
        },
    },
    title: {
      text: title,
      align: "center" as const,
      style: {
        fontWeight: 700,
      },
    },
  };

  const isNullData = series.every(({ data }) => data.every((item) => item === null));

  return (
    <>
      {isNullData ? (
        <NullLogData />
      ) : (
        <Chart id={id} options={options} series={series} type="line" height={`${graphOption.graphHeight || 300}px`} />
      )}
    </>
  );
};

export default observer(Line);
