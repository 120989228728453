import { computed, observable } from "mobx";

import { SetterModel, Common_Utils, languageSet } from "@qubit-utill/src";

import { HostListModel } from "@src/common/models";

export default class GroupWafProxyModel extends SetterModel {
  constructor(params, indexNumber?) {
    super(params);
    this.indexNumber = indexNumber - 1;
    if (params) {
      if (params.httpIpType === "ip") {
        this.httpIp = params.httpIp;
      } else {
        this.httpCname = params.httpIp;
      }

      this.listenPortsArr =
        params.listenPorts &&
        params.listenPorts.split(",").map((data) => {
          return parseInt(data);
        });

      this.userHostIds = [];
      if (params.userHostReferenceList) {
        this.userHostReferenceList = params.userHostReferenceList.map((data) => {
          this.userHostIds.push(data.userHostId);
          return new HostListModel(data, true);
        });
      } else {
        this.userHostReferenceList = [];
      }
    }
  }

  @computed
  get isUseAccessIpFlag() {
    return Common_Utils.isCheckBool(this.isUseAccessIp);
  }

  @computed
  get isUseAccessCountryFlag() {
    return Common_Utils.isCheckBool(this.isUseAccessCountry);
  }

  @computed
  get isUseSslFlag() {
    return Common_Utils.isCheckBool(this.isUseSsl);
  }

  @computed
  get isUseAccessFlag() {
    return Common_Utils.isCheckBool(this.isUseAccess);
  }

  @computed
  get wafProxyIpFlag() {
    return this.wafConfigProxyIpList.length < this.wafProxyIpLimitCount;
  }

  @computed
  get getSslExpirationDate() {
    return Common_Utils.isDataWithDashBar(this.sslExpirationDate);
  }

  @computed
  get getDiffDay() {
    return this.sslExpirationDate ? this.diffDay : "-";
  }

  //전송 파라미터 여기서부터
  @observable
  wafConfigProxyId: number = 0;

  @observable
  listenPorts: any = [];

  @observable
  httpIpType: string = "ip";

  @observable
  httpScheme: string = "http";

  @observable
  httpIp: string = "";

  @observable
  httpPort: number | string = 80;

  @observable
  isUseSsl: string = "0";

  @observable
  sslCrt: string = "";

  @observable
  sslKey: string = "";

  @observable
  sslPassphrase: string = "";

  @observable
  sslExpirationDate: string = "";

  @observable
  diffDay: number = 0;

  @observable
  isUseAccess: string = "0";

  @observable
  isUseAccessIp: string = "0";

  @observable
  isUseAccessCountry: string = "0";

  @observable
  wafConfigProxyIpList: any = [];

  @observable
  wafConfigProxyCountryList: any = [];

  @observable
  userHostReferenceList: any = [];

  //전송 파라미터 여기까지

  @observable
  searchCountryText: string = "";

  @observable
  indexNumber: number = 0;

  @observable
  wafProxyIpLimitCount: number = 30;

  @observable
  listenPortsArr: any = [];

  @observable
  userHostIds: any = [];

  @observable
  listenPort: number | string = 80;

  @observable
  httpCname: string = "";

  validation = () => {
    if (this.listenPorts.length === 0) {
      throw languageSet("메세지_listenPort입력해주세요");
    }
    if (this.isUseSslFlag && this.sslCrt.length < 1000) {
      throw languageSet("메세지_SSL_CRT값확인");
    }
    if (this.isUseSslFlag && this.sslKey.length < 1000) {
      throw languageSet("메세지_SSL_Key값확인");
    }
    if (this.httpPort === "") {
      throw languageSet("메세지_Port입력해주세요");
    }
    if (this.httpIpType === "ip" && this.httpIp === "...") {
      throw languageSet("메세지_IP주소를입력해주세요");
    }

    if (this.httpIpType === "cname" && this.httpCname === "") {
      throw languageSet("메세지_CNAME을입력해주세요");
    }
  };

  @computed
  get apiData() {
    const {
      wafConfigProxyId,
      listenPorts,
      httpIpType,
      httpScheme,
      httpIp,
      httpPort,
      isUseSsl,
      sslCrt,
      sslKey,
      sslPassphrase,
      isUseAccess,
      isUseAccessIp,
      isUseAccessCountry,
      wafConfigProxyIpList,
      wafConfigProxyCountryList,
      userHostReferenceList,
    } = this;
    return {
      wafConfigProxyId,
      listenPorts,
      httpIpType,
      httpScheme,
      httpIp,
      httpPort,
      isUseSsl,
      sslCrt,
      sslKey,
      sslPassphrase,
      isUseAccess,
      isUseAccessIp,
      isUseAccessCountry,
      wafConfigProxyIpList,
      wafConfigProxyCountryList,
      userHostReferenceList,
    };
  }
}
