import React, { MouseEventHandler } from "react";
import { Row } from "reactstrap";
import { observer } from "mobx-react";

import { WrapRadioButton } from "@qubit-utill/src";

interface PropsType {
  tabName?: string;
  condition: string;
  onClick: MouseEventHandler;
  title: string;
}

interface PropsTypes {
  ReportTabsInfo: Array<PropsType>;
  tabBoxName: string;
  defaultTab: string;
  selectedRadioTab: string;
  radioTab?: Array<any>;
  termsTab?: string;
  setInfo: Function;
}

const ReportTab: React.FC<PropsType> = ({ tabName, condition, onClick, title }) => {
  return (
    <li className={`col-xlg-2 col-md-2 col-sm-2 p-5 ${tabName && tabName === condition ? "active" : ""}`}>
      <a className={`p-tb-5 ${tabName && tabName === condition ? "cursor_d" : "cursor_p"}`} onClick={onClick}>
        <div className="f-13">
          <p className="t-center f-13 bold m-b-0">{title}</p>
        </div>
      </a>
    </li>
  );
};

const ReportTabs: React.FC<PropsTypes> = ({
  ReportTabsInfo,
  tabBoxName,
  defaultTab,
  selectedRadioTab,
  children,
  radioTab,
  termsTab,
  setInfo,
}) => {
  const _selectedRadioTab = radioTab && radioTab.find((data) => data.termsTab?.includes(termsTab));
  return (
    <>
      <Row className="m-b-5 m-l-0 m-r-0 nav-tabs33">
        <ul className="col-md-12 p-r-0 nav nav-tabs">
          {ReportTabsInfo &&
            ReportTabsInfo.length > 0 &&
            ReportTabsInfo.map(({ condition, onClick, title }) => (
              <ReportTab tabName={tabBoxName} condition={condition} onClick={onClick} title={title} />
            ))}
          {
            <span className="dis-inline-b m-t-10 f-right f-13">
              {tabBoxName === defaultTab ? (
                children
              ) : (
                <span className="p-tb-3 p-r-10 dis-inline-b">
                  {radioTab &&
                    _selectedRadioTab.list.map(({ title, switchCode }) => (
                      <WrapRadioButton
                        isChecked={selectedRadioTab === switchCode}
                        onSelect={selectedRadioTab === switchCode ? "" : () => setInfo(switchCode, title)}
                      >
                        <span>{title}</span>
                      </WrapRadioButton>
                    ))}
                </span>
              )}
            </span>
          }
        </ul>
      </Row>
    </>
  );
};

export default observer(ReportTabs);
