import { observable, action, toJS } from "mobx";

import { withLoading, languageSet, Common_Utils, ListCondition } from "@qubit-utill/src";

import { networkDetectApis } from "@src/apis/pLogDetect";
import { complianceSettingApis } from "@src/apis/pFilter";
import DefenceStore from "@src/common/stores/DefenceStore";
import { CSVINFO, UISET } from "@src/constants";

import NetworkFilterDetectionModel from "@src/pages/FilterDetection/NetworkFilterDetection/models/NetworkFilterDetectionModel";

class NetworkFilterDetectStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }

  root;

  @observable
  tableList: NetworkFilterDetectionModel[] = [];

  @observable
  networkFilterDetectInfo = new NetworkFilterDetectionModel({}, this.root.CodeStore);

  @action
  clear = () => {
    this.tableList = [];
    this.networkFilterDetectInfo = new NetworkFilterDetectionModel({}, this.root.CodeStore);
  };

  @withLoading("isLoading")
  @action
  getList = async () => {
    try {
      const tagServicePropertyList = this.tagServicePropertyList;
      const tagValueTypeList = this.tagValueTypeList;
      const getTagServiceDataList = this.openIpTagModal;

      const { list, totalCount, nextCursorMark } = await networkDetectApis.getNetLogDetectList(this.params);
      this.tableList = list.map((info) => {
        const _info = { ...info, tagServicePropertyList, tagValueTypeList, getTagServiceDataList };
        return new NetworkFilterDetectionModel(_info, this.root.CodeStore);
      });
      this.setCursorMarkInfo(nextCursorMark);
      this.totalCount = totalCount;
    } catch (error) {
      this.tableList = [];
      this.totalCount = 0;
    }
  };

  getComplianceEntityCountByFilterKeys = async (filterIds) => {
    try {
      const { totalCount } = await complianceSettingApis.getComplianceEntityCountByFilterKeys(filterIds);
      return totalCount;
    } catch (e) {
      return 0;
    }
  };

  @withLoading("isSubLoading")
  @action
  getInfo = async (documentId) => {
    try {
      const tagServicePropertyList = this.tagServicePropertyList;
      const tagValueTypeList = this.tagValueTypeList;
      const getTagServiceDataList = this.openIpTagModal;
      const getTagServiceDataListFn = this.getTagServiceDataList;

      const { info, viewTemplate, filterInfoList, programMessageTemplate } = await networkDetectApis.getNetLogDetect(documentId);

      const _info = { ...info, tagServicePropertyList, tagValueTypeList, getTagServiceDataList, getTagServiceDataListFn };

      this.networkFilterDetectInfo = await new NetworkFilterDetectionModel(
        {
          ..._info,
          viewTemplate,
          filterInfoList,
          programMessageTemplate,
        },
        this.root.CodeStore
      );

      const totalCount = await this.getComplianceEntityCountByFilterKeys(this.networkFilterDetectInfo.filterIds);
      this.networkFilterDetectInfo.setInfo("complianceEntityCount", totalCount);

      this.networkFilterDetectInfo.attackIp && (await DefenceStore.getDefenceInfo(this.networkFilterDetectInfo)); //get 방어정보
      this.root.TicketStore.getPrintTicketInfo(
        "networklogdetect",
        documentId,
        info.logCreateDate,
        this.networkFilterDetectInfo.filterIds
      ); //get 티켓발행정보
    } catch (err) {
      this.networkFilterDetectInfo = new NetworkFilterDetectionModel({}, this.root.CodeStore);
    }
  };

  @action
  downloadCSV = async (isUseBulk?, no?) => {
    try {
      if (this.tableList.length > 0) {
        let csv;
        if (isUseBulk && no) {
          csv = await networkDetectApis.getNetLogDetectCsvList({
            ...toJS(this.params),
            // serverIds: toJS(this.params.serverIds),
            // typeosList: toJS(this.params.typeosList),
            // serverGroupIds: toJS(this.params.serverGroupIds),
            offset: 0,
            limit: no,
          });
        } else {
          csv = Common_Utils.makeCSV(CSVINFO.FILTERDETECT_NET.head, this.tableList);
        }

        Common_Utils.csvDownload(
          csv,
          Common_Utils.setCsvFileName({ title: UISET.FILTERDETECT_NET.title, category: languageSet("필터탐지"), ...this.params })
        );
      } else {
        return languageSet("에러_데이터가없습니다");
      }
    } catch ({ data }) {
      console.log(data);
    }
  };
}

export default NetworkFilterDetectStore;
