import axiosModules from "../axiosSetting";
import { COOKIELANG } from "../../common_constants";

let codeApis = {
  // code > geoip > geoip 조회
  getGeoIpInfo: (ip) => {
    return axiosModules.get(`/code/geoip/${ip}`)
  },

  // code > countrycode > countrycode 리스트
  getCountryCodeList: () => {
    return axiosModules.get("/code/countrycode/list", {
      lang: COOKIELANG,
    });
  },

  // code > 외부링크(블로그주소매핑) > 외부링크 목록
  getLinkUrl: () => axiosModules.get(`/code/linkurl`),

  // code > 코드 > 코드 목록
  getCodeList: codeGroup => {
    return axiosModules.get(`/code/bycodegroup?codeGroup=${codeGroup}`);
  },

  // code > 코드 > 다중 코드그룹의 코드 목록 조회
  getCodeListByArr: codeGroups => {
    return axiosModules.get("/code/bycodegroups", { codeGroups });
  },

  // code > 코드그룹 > 코드그룹목록
  getCodeGroupList: () => {
    return axiosModules.get("/code/codegroups");
  },

  // code > 검색 메뉴 > 검색메뉴 서비스코드별 리스트 조회
  getSearchMenus: params => {
    return axiosModules.get("/code/searchmenus", { ...params, lang: COOKIELANG });
  },
};

export default codeApis;
