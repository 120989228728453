import { observable, action, computed } from "mobx";

import { ListCondition, withLoading, Common_Utils, languageSet, dateUtil } from "@qubit-utill/src";

import { pDetectFilterApis } from "@src/apis";
import { WebFirewallDefenceModel } from "@src/pages/Defence/DefenceIp/models";
import { CSVINFO, UISET } from "@src/constants";

class WebFirewallDefenceStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }
  root;

  @observable
  isWaf: boolean = true;

  @observable
  isLoading: boolean = true;

  @observable
  isSubLoading: boolean = true;

  @observable
  tableList: WebFirewallDefenceModel[] = [];

  @observable
  defenseInfo = new WebFirewallDefenceModel(null, this.root.CodeStore);

  @observable
  defenseConfigFilterType: string = "";

  @observable
  isTotalCheck: boolean = false;

  @computed
  get checkList() {
    return this.tableList.filter((data) => data.isSelected);
  }

  @computed
  get totalCountForUI() {
    return this.isTotalCheck ? this.totalCount : this.checkList.length;
  }

  @action
  allCheck = (checked) => {
    this.tableList.forEach((data) => {
      data.setInfo("isSelected", checked);
    });
  };

  @action
  clearInfo = async () => {
    this.defenseInfo = new WebFirewallDefenceModel(null, this.root.CodeStore);
  };

  @withLoading("isLoading")
  @action
  getList = async () => {
    try {
      await this.root.CodeStore.getCodeList("defenseConfigFilterType");
      const { list, totalCount } = await pDetectFilterApis.getCustomDefencseWafList(this.params);
      this.tableList = list.map((data) => new WebFirewallDefenceModel(data, this.root.CodeStore));
      this.totalCount = totalCount;
    } catch (response) {
      this.tableList = [];
      this.totalCount = 0;
      return response;
    }
  };

  @withLoading("isSubLoading")
  @action
  getInfo = async (customDefenseWafId) => {
    try {
      const { info } = await pDetectFilterApis.getCustomDefencseWaf(customDefenseWafId);
      this.defenseInfo = new WebFirewallDefenceModel(info, this.root.CodeStore);
    } catch (err) {}
  };

  @action
  updateDetectDefenseByIsActive = async (params, isCheckBox?) => {
    let data = {
      isActive: isCheckBox || params.isActive,
      customDefenseWafIds: isCheckBox
        ? this.checkList.map(({ customDefenseWafId }) => customDefenseWafId)
        : [params.customDefenseWafId],
    };
    try {
      await pDetectFilterApis.updateCustomDefenseWafIsActive(data);
    } catch ({ data }) {
      return data;
    }
  };

  @action
  updateDetectDefense = async () => {
    const data = {
      defenseDescription: this.defenseInfo.defenseDescription,
      isActive: this.defenseInfo.isActive,
      expiredDays: this.defenseInfo.expiredDays,
      isCustomExpiredDay: this.defenseInfo.isCustomExpiredDay,
    };

    try {
      await pDetectFilterApis.updateCustomDefenseWaf(this.defenseInfo.detectDefenseId, data);
    } catch ({ data }) {
      return data;
    }
  };

  @action
  deleteCustomDefenseWaf = async (customDefenseWafId) => {
    try {
      await pDetectFilterApis.deleteCustomDefenseWaf(customDefenseWafId);
    } catch ({ data }) {
      return data;
    }
  };

  @action
  deleteDetectDefenseIds = async () => {
    const customDefenseWafIds = this.checkList.reduce((acc, cur) => (acc += cur.customDefenseWafId + ","), "");
    const params = {
      customDefenseWafIds: customDefenseWafIds.slice(0, -1),
    };

    try {
      await pDetectFilterApis.deleteCustomDefenseWafs(params);
    } catch ({ data }) {
      return data;
    }
  };

  @withLoading("isLoading")
  @action
  createDetectDefense = async () => {
    const data = {
      detectDefenseIp: this.defenseInfo.detectDefenseIp,
      detectDefenseType: this.defenseInfo.detectDefenseType,
      customDefenseFilterType: this.defenseInfo.defenseConfigFilterType,
      defenseDescription: this.defenseInfo.defenseDescription,
      expiredDays: this.defenseInfo.expiredDays,
      isCustomExpiredDay: this.defenseInfo.isCustomExpiredDay,
    };
    try {
      await pDetectFilterApis.createCustomDefenseWaf(data);
    } catch ({ data }) {
      throw data;
    }
  };

  @action
  csvDownload = () => {
    if (this.tableList.length > 0) {
      const csv = Common_Utils.makeCSV(CSVINFO.DEFENCEIP_WEBFIRE.head, this.tableList);
      const { detectDate } = dateUtil.getDateInfo(new Date());
      Common_Utils.csvDownload(
        csv,
        Common_Utils.setCsvFileName({
          title: languageSet("차단IP주소"),
          category: UISET.DEFENSE_IP_WEBFIREWALL.title,
          date: detectDate,
        })
      );
    } else {
      return languageSet("에러_데이터가없습니다");
    }
  };
}

export default WebFirewallDefenceStore;
