import React from "react";
import Chart from "react-apexcharts";
import { observer } from "mobx-react";
import { toJS } from "mobx";

import { Common_Utils } from "qubit-utill/src";

import UISET from "@src/constants/uiset";
import NullLogData from "@src/common/components/organisms/Graphs/NullLogData";

interface GraphOptionPropsTypes {
  chart?: {
    animations?: {
      enabled?: boolean;
    };
  };
  stroke?: object;
  markers?: object;
  dataLabels?: object;
  plotOptions?: {
    bar?: {
      columnWidth?: string;
      dataLabels?: {
        positions?: string;
        total?: {
          enabled?: boolean;
          formatter?: () => string;
          style?: {};
        };
      };
    };
  };
}

interface PropsTypes {
  id: string;
  title?: string;
  data: { type?: string; name: string; data: number[] }[];
  category: string[];
  valueFormater?: Function;
  height?: number;
  nullNotice?: string;
  graphOption?: GraphOptionPropsTypes;
}

const StackBarLineMixed: React.FC<PropsTypes> = ({
  id,
  title,
  data,
  category,
  valueFormater,
  height = 250,
  nullNotice,
  graphOption,
}) => {
  const _valueFormatter = (value) =>
    Common_Utils.isNull(value) ? (valueFormater ? valueFormater(value) : Common_Utils.numberWithComma(value)?.toString()) : "";

  const series = data && data.length > 0 ? toJS(data) : [];

  const strokeWidth = series.map(({ type }) => (type === "line" ? 2 : 0));
  const enabledOnSeries = series
    .map(({ type }, index) => (type === "column" ? index : NaN))
    .filter((index) => !Number.isNaN(index));

  let sumMaxColor = "#ff0000";
  const chartUpdate = function(opts) {
    const sumMaxValues = series
      .filter((item) => item.type === "bar" || item.type === "column")
      .reduce((acc, item) => {
        item.data.forEach((value, index) => {
          acc[index] = (acc[index] || 0) + value;
        });
        return acc;
      }, [] as number[]);

    const sumMaxValue = Math.max(...sumMaxValues);
    const formatSumMaxValue = _valueFormatter(sumMaxValue);

    if (sumMaxValue > 0) {
      const dataLabels = opts.el.querySelectorAll(".apexcharts-text");
      dataLabels.forEach((label) => {
        if (label.classList.length > 1) {
          // X축, Y축 레이블을 제외
          return;
        }
        var value = label.textContent;
        if (value === formatSumMaxValue) {
          return (label.style.fill = sumMaxColor);
        }
      });
    }
  };

  const options = {
    title: { text: title || "", align: "center" as const },
    chart: {
      id: id,
      stacked: true,
      stackOnlyBar: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      redrawOnParentResize: true,
      animations: graphOption?.chart?.animations,
      events: {
        mounted: chartUpdate,
        updated: chartUpdate,
      },
    },
    xaxis: {
      categories: category && category.length > 0 ? toJS(category) : [],
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      labels: {
        formatter: function(value) {
          return _valueFormatter(value) as string;
        },
      },
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      x: {
        formatter: function(value, opts) {
          const nullValue = opts.series.every((seriesItem) => {
            const data = seriesItem[opts.dataPointIndex];
            return !Common_Utils.isNull(data);
          });
          return nullValue ? null : value;
        },
      },
      y: {
        formatter: function(value, opts) {
          const nullValue = !Common_Utils.isNull(value);
          return nullValue ? null : _valueFormatter(value);
        },
      },
    },

    plotOptions: {
      bar: {
        columnWidth: graphOption?.plotOptions?.bar?.columnWidth || "60%",
        dataLabels: {
          position: graphOption?.plotOptions?.bar?.dataLabels?.positions || "top",
          total: {
            enabled: graphOption?.plotOptions?.bar?.dataLabels?.total?.enabled || false,
            offsetX: 0,
            formatter:
              graphOption?.plotOptions?.bar?.dataLabels?.total?.formatter ||
              function(value, opts) {
                const nullValue = data.every(({ data }) => data[opts.dataPointIndex] === null);
                return nullValue ? "-" : (_valueFormatter(value) as string);
              },
            style: graphOption?.plotOptions?.bar?.dataLabels?.total?.style || {},
          },
        },
      },
    },
    dataLabels: graphOption?.dataLabels || {
      enabled: true,
      enabledOnSeries: enabledOnSeries,
      formatter: function(value) {
        return (Common_Utils.isNull(value) ? _valueFormatter(value) : "") as string;
      },
      background: {
        enabled: false,
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      position: "bottom" as const,
      horizontalAlign: "center" as const,
      offsetY: 8,
      markers: {
        radius: 12,
      },
    },
    colors: UISET.CHART_COLOR,
    stroke: graphOption?.stroke || {
      width: strokeWidth,
    },
    markers: {
      size: 3,
      strokeWidth: 0,
      hover: {
        sizeOffset: 0,
      },
    },
  };

  const isNullData =
    !data ||
    data.length === 0 ||
    (data.length > 0 && data.every(({ data }) => !data || data.every((item) => !Common_Utils.isNull(item))));

  return isNullData ? (
    <NullLogData height={height} nullNotice={nullNotice} />
  ) : (
    <Chart options={options} series={series} type="bar" height={`${height}px`} />
  );
};

export default observer(StackBarLineMixed);
