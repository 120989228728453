import { observable, action, computed } from "mobx";

import { ListCondition, makeStore, withLoading, Common_Utils, UserException, languageSet, dateUtil } from "@qubit-utill/src";
import { SummaryListModel } from "@qubit-utill/src/model";

import { serverResourceApis } from "@src/apis/pServer";
import { applicationLogApis } from "@src/apis/pLog";
import { pAuthApis } from "@src/apis";
import { CSVINFO, UISET } from "@src/constants";

import HostResourceMonitoringModel from "@src/pages/ResourceMonitoring/HostResourceMonitoring/models/HostResourceMonitoringModel";

class HostResourceMonitoringStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }

  root;

  @observable
  tableList: HostResourceMonitoringModel[] = [];

  @observable
  isTrSelected: boolean = false;

  @observable
  resourceInfoList: Array<{
    id: number;
    title: string;
    currentData: Array<number>;
    beforeData: Array<number>;
    currentDetailData: Array<number>;
    beforeDetailData: Array<number>;
    category: Array<string>;
    detailCategory: Array<string>;
    isUseBefore: boolean;
    isDetail: boolean;
    unit: string;
  }> = [];

  @observable
  resourceDetailInfoList: Array<{
    id: number;
    isDetail: boolean;
    selectedTime: string;
  }> = [];

  @observable
  resourceInfoParam = {
    detectDate: dateUtil.getDateByForm(new Date()),
    detectTime: "30",
    isHour: false,
  };

  @observable
  summaryList: any = [];

  @observable
  timeCodeList = [];

  @observable
  resourceId: string = "";

  @observable
  resourceMetaId: number = 0;

  @observable
  serverId: number = 0;

  @observable
  isDetailLoading = {};

  @observable
  isChange: boolean = false;

  @observable
  limitCount = 0;

  @observable
  startDate: string = "";

  @observable
  endDate: string = "";

  @action
  getToday = () => {
    return (this.today = dateUtil.getDateByForm(new Date()));
  };

  @observable
  today = "";

  @computed
  get isToday() {
    return this.resourceInfoParam.detectDate === this.today;
  }

  @observable
  isCallReloadTimer: boolean = false;

  @withLoading("isLoading")
  @action
  getList = async (isCallReloadTimer?) => {
    try {
      this.params.isResourceUpload = "1";
      this.params.orderMap = this.params.orderMap === "" ? "serverResourceUpdateDate|DESC" : this.params.orderMap;
      this.isCallReloadTimer = typeof isCallReloadTimer === "boolean" ? isCallReloadTimer : false;
      const { list, totalCount } = await serverResourceApis.getHostResourceMonitoringList(this.params);
      this.tableList = Common_Utils.constructClassList(list, HostResourceMonitoringModel);
      this.totalCount = totalCount;
      this.isTrSelected && (await this.getGraphInfo());
    } catch (e) {
      console.log(e);
      this.totalCount = 0;
      this.tableList = [];
    }
  };

  @withLoading("isSubLoading")
  @action
  getGraphInfo = async () => {
    try {
      await this.getDetectDateParam();
      const param = {
        serverId: this.serverId,
        logCreateDateStart: this.startDate,
        logCreateDateEnd: this.endDate,
        resourceId: this.resourceId,
        graphAppearTime: this.resourceInfoParam.detectTime,
      };

      if (this.resourceId) {
        const { list, beforeList } = await applicationLogApis.getResourceMonitoringGraphInfo(param);

        this.resourceInfoList =
          list.length > 0 &&
          list.map(({ dataUnit, resourceMetaId, metaName, logMetaStatsValueList }) => {
            const currentData: Array<number> = [];
            const category: Array<string> = [];
            logMetaStatsValueList.forEach(({ logCreateDate, logMetaStatsValue }) => {
              const _logMetaStatsValue = typeof logMetaStatsValue === "number" ? logMetaStatsValue : Number(logMetaStatsValue);
              currentData.push(Number(_logMetaStatsValue.toFixed(2)));
              category.push(logCreateDate);
            });

            this.isDetailLoading[resourceMetaId] = true;

            const detailItem = this.resourceDetailInfoList.find(({ id }) => id === resourceMetaId);

            detailItem &&
              this.getGraphDetailInfo(detailItem.id, `${detailItem.selectedTime}:00:00`, `${detailItem.selectedTime}:59:59`);

            const unit = this.root.CodeStore.getCodeName("resourceMetaUnitValue", dataUnit);

            return {
              id: resourceMetaId,
              title: metaName,
              category: category,
              currentData: currentData,
              beforeData: [],
              currentDetailData: [],
              beforeDetailData: [],
              isUseBefore: false,
              isDetail: detailItem?.isDetail,
              unit: unit,
            };
          });

        beforeList.length > 0 &&
          beforeList.forEach(({ resourceMetaId, logMetaStatsValueList }) => {
            const resourceInfoItem = this.resourceInfoList.find(({ id }) => id === resourceMetaId);
            resourceInfoItem && (resourceInfoItem.isUseBefore = true);

            return logMetaStatsValueList.forEach(({ logMetaStatsValue }) => {
              const _logMetaStatsValue = typeof logMetaStatsValue === "number" ? logMetaStatsValue : Number(logMetaStatsValue);
              return resourceInfoItem?.beforeData.push(Number(_logMetaStatsValue.toFixed(2)));
            });
          });
      }
    } catch (e) {
      this.resourceInfoList = [];
    }
  };

  @action
  getGraphDetailInfo = async (resourceMetaId, startTime, endTime) => {
    const param = {
      serverId: this.serverId,
      logCreateDateStart: `${this.resourceInfoParam.detectDate} ${startTime}`,
      logCreateDateEnd: `${this.resourceInfoParam.detectDate} ${endTime}`,
      resourceId: this.resourceId,
      resourceMetaId: resourceMetaId,
      graphAppearTime: "60",
    };

    try {
      const { list, beforeList } = await applicationLogApis.getResourceMonitoringGraphInfo(param);
      const metaCurrentInfo = list && list[0];
      const metaBeforeInfo = beforeList && beforeList[0];

      const index = this.resourceInfoList.findIndex(({ id }) => id === metaCurrentInfo.resourceMetaId);
      const resourceInfo = this.resourceInfoList[index];

      const detailCategory: Array<string> = [];
      const currentDetailData: Array<number> = [];
      const beforeDetailData: Array<number> = [];

      metaCurrentInfo.logMetaStatsValueList.forEach(({ logCreateDate, logMetaStatsValue }) => {
        const _logMetaStatsValue = typeof logMetaStatsValue === "number" ? logMetaStatsValue : Number(logMetaStatsValue);
        currentDetailData.push(Number(_logMetaStatsValue.toFixed(2)));
        detailCategory.push(logCreateDate);
      });

      if (metaBeforeInfo) {
        resourceInfo.isUseBefore = true;
        metaBeforeInfo.logMetaStatsValueList.forEach(({ logMetaStatsValue }) => {
          const _logMetaStatsValue = typeof logMetaStatsValue === "number" ? logMetaStatsValue : Number(logMetaStatsValue);
          return beforeDetailData.push(Number(_logMetaStatsValue.toFixed(2)));
        });
      }

      resourceInfo.detailCategory = detailCategory;
      resourceInfo.currentDetailData = currentDetailData;
      resourceInfo.beforeDetailData = beforeDetailData;

      await this.setDetailLoading(resourceMetaId, true);
    } catch (e) {
      console.log(e);
    }
  };

  csvDownload = () => {
    if (this.tableList.length > 0) {
      const csv = Common_Utils.makeCSV(CSVINFO.RESOURCEMONITORING_HOST.head, this.tableList);
      Common_Utils.csvDownload(
        csv,
        Common_Utils.setCsvFileName({
          title: UISET.RESOURCEMONITORING_HOST.title,
          category: languageSet("리소스모니터링"),
          date: dateUtil.getDateByForm(new Date()),
        })
      );
    } else {
      throw new UserException(languageSet("에러_데이터가없습니다"));
    }
  };

  @action
  getSummaryList = async () => {
    try {
      const { list } = await serverResourceApis.getHostResourceMonitoringSummaryList();
      const _list = list.reduce((acc, cur) => {
        if (cur.additionalValue) {
          const matchIndex = acc.findIndex(({ additionalValue }) => additionalValue === cur.additionalValue);

          if (matchIndex !== -1) {
            acc[matchIndex].child.push(cur);
          } else {
            cur.child = [{ ...cur }];
            acc.push(cur);
          }
        } else {
          acc.push({ ...cur, additionalValue: cur.code });
        }
        return acc;
      }, []);

      this.summaryList = _list.map((data, curIndex) => {
        data.orderNo = curIndex;
        return new SummaryListModel({ params: data });
      });
    } catch ({ data }) {
      this.summaryList = [];
      return data;
    }
  };

  @action
  setResourceInfoParam = async (obj, isGetInfo = true) => {
    for (const key in obj) {
      const param = { ...this.resourceInfoParam, [key]: obj[key] };
      await this.setInfo("resourceInfoParam", param);
    }

    await this.getDetectDateParam();
    this.resourceDetailInfoList = [];
    isGetInfo && (await this.getGraphInfo());
  };

  @action
  setIsSubLoading = (flag: boolean) => {
    this.isSubLoading = flag;
  };

  @action
  getTimeCodeList = async () => {
    await this.root.CodeStore.getCodeList("graphAppearTime");
    this.timeCodeList = this.root.CodeStore.codeListForSelect["graphAppearTime"].map(({ name, value }) => {
      return {
        name,
        value,
        isDefault: Common_Utils.isCheckBool(value.isDefault),
        isHour: Boolean(value.codeAdditionalValue === "HOUR"),
      };
    });
  };

  @action
  setInfo = (key, value) => {
    return (this[key] = value);
  };

  @action
  setResourceInfoItemDetail = (index, flag, hour?) => {
    const item = this.resourceInfoList[index];
    const itemIndex = this.resourceDetailInfoList.findIndex(({ id }) => id === item.id);
    if (flag) {
      this.resourceDetailInfoList.push({
        id: item.id,
        isDetail: flag,
        selectedTime: flag ? hour : "",
      });
    } else {
      this.resourceDetailInfoList.splice(itemIndex, 1);
    }
    return (this.resourceInfoList[index].isDetail = flag);
  };

  @action
  setDetailLoading = (id, flag) => {
    return (this.isDetailLoading[id] = flag);
  };

  @action
  getDetectDateParam = async () => {
    let startDate;
    let endDate;
    let startTime;

    if (this.isToday && this.resourceInfoParam.detectTime !== "0") {
      const nowMilliSec = Date.now();
      const endTime = dateUtil.format(new Date(), "HH:mm:ss");

      const selectedDateMilliSec = new Date(`${this.resourceInfoParam.detectDate} 00:00:00`).getTime();
      const selectedTimeMilliSec = Number(this.resourceInfoParam.detectTime) * 60 * 1000;
      const startMilliSec = nowMilliSec - selectedTimeMilliSec;

      if (selectedDateMilliSec > startMilliSec) {
        startTime = "00:00:00";
      } else {
        startTime = dateUtil.format(new Date(nowMilliSec - selectedTimeMilliSec), "HH:mm:ss");
      }

      startDate = `${this.resourceInfoParam.detectDate} ${startTime}`;
      endDate = `${this.resourceInfoParam.detectDate} ${endTime}`;
    } else {
      startDate = `${this.resourceInfoParam.detectDate} 00:00:00`;
      endDate = `${this.resourceInfoParam.detectDate} 23:59:59`;
    }

    this.startDate = startDate;
    this.endDate = endDate;
  };

  @action
  getIsDetailLoading = () => {
    return this.isDetailLoading;
  };

  @action
  getLimitCountInfo = async () => {
    try {
      const { info } = await pAuthApis.getLicenseResourceMonitorSearchDateRange();
      this.limitCount = info.limitCount;
    } catch (e) {
      this.limitCount = 0;
      console.log(e);
    }
  };
}

const [HostResourceMonitoringStoreContext, HostResourceMonitoringStoreProvider, useHostResourceMonitoringStore] = makeStore(
  HostResourceMonitoringStore,
  "HostResourceMonitoringStore"
);

export { HostResourceMonitoringStoreContext, HostResourceMonitoringStoreProvider, useHostResourceMonitoringStore };
export default HostResourceMonitoringStore;
