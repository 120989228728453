import { observable, action } from "mobx";

import { ListCondition, withLoading } from "@qubit-utill/src";

import { detectBlockedApis } from "@src/apis/pLogDetect";
import { WebFirewallLogModel } from "@src/pages/Defence/DefenseLog/models";

class WebFirewallLogStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }
  root;

  @observable
  tableList = [];

  @observable
  defenceLogInfo = {};

  @withLoading("isLoading")
  @action
  getList = async () => {
    try {
      const { list, totalCount } = await detectBlockedApis.getWafBlockedLogList(this.params);
      this.tableList = list.map((data) => new WebFirewallLogModel(data));
      this.totalCount = totalCount;
    } catch (response) {
      this.tableList = [];
      this.totalCount = 0;
      return response;
    }
  };

  // @withLoading("isSubLoading")
  // @action
  // getInfo = async (documemtId) => {
  //   try {
  //     const { info } = await detectBlockedApis.getBlockedLog(documemtId);
  //     this.defenceLogInfo = new WebFirewallLogModel(info);
  //   } catch (response) {
  //     this.defenceLogInfo = {};
  //   }
  // };
}

export default WebFirewallLogStore;
