import { languageSet } from "@qubit-utill/src";
import getURI from "@src/constants/uriset";

import { RESOURCEMONITORING_LICENSECODE } from "@src/constants/licenseCode";

import {
  HostResourceMonitoringContainer,
  HostTotalResourceMonitoringContainer,
  AlarmFilterResourceMonitoringContainer,
  AlarmFilterResourceMonitoringSetContainer,
} from "@src/pages";

export default {
  RESOURCEMONITORING_HOST: {
    title: languageSet("시스템"),
    text: languageSet("시스템"),
    link: getURI("RESOURCEMONITORING_HOST"),
    licenseCode: RESOURCEMONITORING_LICENSECODE,
    withPageLink: `${getURI("RESOURCEMONITORING_HOST")}/page/:page`,
    component: HostResourceMonitoringContainer,
  },
  RESOURCEMONITORING_HOST_TOTAL: {
    title: languageSet("시스템_전체"),
    text: languageSet("시스템_전체"),
    link: getURI("RESOURCEMONITORING_HOST_TOTAL"),
    licenseCode: RESOURCEMONITORING_LICENSECODE,
    withPageLink: `${getURI("RESOURCEMONITORING_HOST_TOTAL")}/page/:page`,
    component: HostTotalResourceMonitoringContainer,
  },
  RESOURCEMONITORING_ALARMFILTER: {
    title: languageSet("알림필터"),
    text: languageSet("알림필터"),
    link: getURI("RESOURCEMONITORING_ALARMFILTER"),
    licenseCode: RESOURCEMONITORING_LICENSECODE,
    withPageLink: `${getURI("RESOURCEMONITORING_ALARMFILTER")}/page/:page`,
    component: AlarmFilterResourceMonitoringContainer,
  },
  RESOURCEMONITORING_ALARMFILTER_REGIST: {
    title: languageSet("알림필터_등록"),
    text: languageSet("알림필터_등록"),
    link: getURI("RESOURCEMONITORING_ALARMFILTER_REGIST"),
    isEdit: false,
    licenseCode: RESOURCEMONITORING_LICENSECODE,
    withPageLink: `${getURI("RESOURCEMONITORING_ALARMFILTER_REGIST")}/:resourceFilterId`, //복사용
    component: AlarmFilterResourceMonitoringSetContainer,
  },
  RESOURCEMONITORING_ALARMFILTER_EDIT: {
    title: languageSet("알림필터_수정"),
    text: languageSet("알림필터_수정"),
    link: `${getURI("RESOURCEMONITORING_ALARMFILTER_EDIT")}/:resourceFilterId`,
    isEdit: true,
    licenseCode: RESOURCEMONITORING_LICENSECODE,
    component: AlarmFilterResourceMonitoringSetContainer,
  },
};
