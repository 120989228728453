import { action, computed, observable } from "mobx";

import { SetterModel } from "@qubit-utill/src";

import { ExceptEntryModel } from "@src/pages/Group/SettingDetect/models";

export default class UploadExceptModel extends SetterModel {
  constructor(key, params?) {
    super(params);
    if (params) {
      const list = params[`${key}UploadExceptEntry`];
      if (list && list.length > 0) {
        this.entryList = list.map((info) => new ExceptEntryModel(info));
      }
    } else {
      this.entryList = [new ExceptEntryModel()];
    }
  }

  @computed
  get webApiData() {
    const { exceptType, webUploadExceptGroupId, entryList } = this;
    return {
      exceptType,
      webUploadExceptGroupId,
      webUploadExceptEntry: entryList.map(({ webApiData }) => webApiData),
    };
  }

  @computed
  get systemApiData() {
    const { exceptType, sysUploadExceptGroupId, entryList, filterChannelId, typeos } = this;
    return {
      filterChannelId,
      typeos,
      exceptType,
      sysUploadExceptGroupId,
      sysUploadExceptEntry: entryList.length > 0 ? entryList.map(({ systemApiData }) => systemApiData) : null,
    };
  }

  @computed
  get appApiData() {
    const { exceptType, appUploadExceptGroupId, entryList } = this;
    return {
      exceptType,
      appUploadExceptGroupId,
      appUploadExceptEntry: entryList.map(({ appApiData }) => appApiData),
    };
  }

  @computed
  get networkApiData() {
    const { exceptType, netUploadExceptGroupId, entryList, filterChannelId, typeos } = this;
    return {
      filterChannelId,
      typeos,
      exceptType,
      netUploadExceptGroupId,
      netUploadExceptEntry: entryList.length > 0 ? entryList.map(({ networkApiData }) => networkApiData) : null,
    };
  }

  @computed
  get validationEntry() {
    return this.entryList.every(({ exceptEntryType, exceptEntryValue }) => exceptEntryType !== "" && exceptEntryValue !== "");
  }

  @action
  addEntry = () => {
    const empty = this.entryList.filter(
      ({ exceptEntryType, exceptEntryValue }) => exceptEntryType === "" || exceptEntryValue === ""
    );
    empty.length <= 0 && this.entryList.push(new ExceptEntryModel());
  };

  @action
  deleteEntry = (index) => () => {
    this.entryList.splice(index, 1);
  };

  @observable
  exceptType: string = "except";

  @observable
  filterChannelId: number = 0;

  @observable
  typeos: string = "";

  @observable
  typeosName: string = "";

  @observable
  entryList: ExceptEntryModel[] = [];

  @observable
  webUploadExceptGroupId: number = 0;

  @observable
  sysUploadExceptGroupId: number = 0;

  @observable
  appUploadExceptGroupId: number = 0;

  @observable
  netUploadExceptGroupId: number = 0;
}
