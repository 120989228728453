import React, { useRef } from "react";
import { observer } from "mobx-react";
import { toJS } from "mobx";

import * as echarts from "echarts/core";
import EChartsReactCore from "echarts-for-react/lib/core";
import { MapChart } from "echarts/charts";
import { SVGRenderer } from "echarts/renderers";
import { TooltipComponent, GridComponent, VisualMapComponent, DatasetComponent } from "echarts/components";

import { languageSet, Common_Utils, stringUtils } from "qubit-utill/src";

import { COOKIELANG, DEFAULTLANG } from "@src/constants";
import { WorldmapGrapType } from "@src/type/DashboardType";
import { worldCountriesGeojson } from "@src/common/components/organisms/Graphs/ECharts/data/map/world.geo";

const MAP_REGISTER_KEY = "World";

// 사용 할 모듈 등록
echarts.use([SVGRenderer, MapChart, TooltipComponent, GridComponent, VisualMapComponent, DatasetComponent]);

// echarts 지도에 worldmap Geojson 등록
echarts.registerMap(MAP_REGISTER_KEY, worldCountriesGeojson as any);

interface PropsTypes {
  data: WorldmapGrapType[];
  valueFormater?: Function;
}

const Worldmap: React.FC<PropsTypes> = ({ data, valueFormater }) => {
  const echartRef = useRef(null as any);

  const nationName = (params) => stringUtils.getNationFullName(params["name"], COOKIELANG || DEFAULTLANG);
  const _valueFormater = (value) =>
    Common_Utils.isNull(value) ? (valueFormater ? valueFormater(value) : Common_Utils.numberWithComma(value)?.toString()) : "";

  const series = data && data.length > 0 ? toJS(data) : [];

  //max값 구하기
  const seriesData: WorldmapGrapType[] = [];
  series.forEach((data) => {
    const findIndex = seriesData.findIndex((item) => item.name === data.name);
    if (findIndex >= 0) {
      seriesData[findIndex].value += data.value;
    } else {
      seriesData.push({ ...data });
    }
  });

  const maxValue = series.length > 0 ? Math.max(...seriesData.map((item) => item.value)) : 0;

  // worldmap 옵션
  const worldmapOptions = {
    tooltip: {
      trigger: "item",
      showDelay: 0,
      transitionDuration: 0.2,
      formatter: function(params) {
        // 데이터가 있는 국가만 tooltip 을 띄우도록 처리
        if (!params.value) {
          return;
        }

        return `${params.seriesName}<br/><b>${languageSet("국가")}: ${nationName(params.data)}</b><br/><b>${languageSet(
          "접속_횟수"
        )}: ${_valueFormater(params.value)}</b>`;
      },
    },
    visualMap: {
      top: "middle",
      left: "right",
      min: maxValue === 0 ? 1 : 0, //데이터가 0일때 모든색상 변경되는 이슈있어서 보정함.
      max: maxValue,
      // precision: 0,
      // calculable: true,
      text: [_valueFormater(maxValue), 0],
      inRange: {
        color: ["#efecf3", "#990041"],
      },
      formatter: function(value) {
        value = Math.trunc(value); //소수점 제거
        return _valueFormater(value);
      },
    },
    series: [
      {
        name: languageSet("접속IP"),
        type: "map",
        roam: "scale",
        nameProperty: "ISO_A2",
        map: MAP_REGISTER_KEY,
        emphasis: {
          disabled: true,
        },
        selectedMode: false,
        // silent: true,
        data: series,
      },
    ],
  };

  // worldmap 이벤트
  const onEvents = {
    mousemove: (params) => {
      // 지도 위에 마우스 올릴때 마우스 커서 포인터 default 설정
      echartRef.current.getZr().setCursorStyle("default");
    },
  };

  // worldmap 확대/축소 함수
  const worldmapZoom = (type) => {
    // type: in - 확대, type: out - 축소
    if (!echartRef.current) {
      return;
    }

    let currentZoom = echartRef.current.getOption().series[0].zoom;
    let zoom = currentZoom;
    if (type === "in") {
      zoom = zoom + 1;
    } else if (type === "out") {
      zoom = zoom - 1;
      if (zoom < 1) {
        zoom = 1;
      }
    }

    echartRef.current.setOption({
      series: [
        {
          zoom,
        },
      ],
    });
  };

  // 지도 초기화
  const worldmapReset = () => {
    echartRef.current.setOption(worldmapOptions, true);
  };

  return (
    <div className="worldmap-container">
      <EChartsReactCore
        echarts={echarts}
        option={worldmapOptions}
        ref={(e) => {
          if (e && e.getEchartsInstance) {
            // echart instance 얻기
            echartRef.current = e.getEchartsInstance();
          }
        }}
        className="map"
        onEvents={onEvents}
      />
      <button
        type="button"
        onClick={() => {
          worldmapZoom("in");
        }}
        className="control-btns zoom-in"
      >
        <i className="fa fa-plus p-r-0" aria-hidden="true"></i>
      </button>
      <button
        type="button"
        onClick={() => {
          worldmapZoom("out");
        }}
        className="control-btns zoom-out"
      >
        <i className="fa fa-minus p-r-0" aria-hidden="true"></i>
      </button>
      <button
        type="button"
        onClick={() => {
          worldmapReset();
        }}
        className="control-btns reset"
      >
        <i className="fa fa-refresh p-r-0" aria-hidden="true"></i>
      </button>
    </div>
  );
};

export default observer(Worldmap);
