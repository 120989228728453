import getURI from "@src/constants/uriset";
import { languageSet } from "@qubit-utill/src";

import {
  EXTORTACCOUNT_LICENSECODE,
  FILTER_LICENSECODE,
  WEBEXTENDSDATABREACH_LICENSECODE,
  WEBSITEFORGERY_LICENSECODE,
} from "@src/constants/licenseCode";
import {
  CorrelationFilterSetContainer,
  SecurityFilterContainer,
  DataBreachFilterSetContainer,
  ExtortAccountFilterEditContainer,
  WebsiteForgeryFilterEditContainer,
} from "@src/pages";

export default {
  //상관분석필터
  SECURITYFILTER_CORRELATION: {
    withPageLink: `${getURI("SECURITYFILTER_CORRELATION")}/page/:page`,
    link: getURI("SECURITYFILTER_CORRELATION"),
    licenseCode: FILTER_LICENSECODE,
    component: SecurityFilterContainer,
  },
  SECURITYFILTER_CORRELATION_REGIST: {
    link: getURI("SECURITYFILTER_CORRELATION_REGIST"),
    title: languageSet("상관분석_보안필터_등록"),
    isEdit: false,
    exact: true,
    component: CorrelationFilterSetContainer,
  },
  SECURITYFILTER_CORRELATION_EDIT: {
    link: `${getURI("SECURITYFILTER_CORRELATION_EDIT")}/:packageFilterId`,
    title: languageSet("상관분석_보안필터_수정"),
    exact: true,
    isEdit: true,
    component: CorrelationFilterSetContainer,
  },
  //데이터유출
  SECURITYFILTER_DATABREACH: {
    withPageLink: `${getURI("SECURITYFILTER_DATABREACH")}/page/:page`,
    link: getURI("SECURITYFILTER_DATABREACH"),
    licenseCode: WEBEXTENDSDATABREACH_LICENSECODE,
    component: SecurityFilterContainer,
  },
  SECURITYFILTER_DATABREACH_REGIST: {
    link: `${getURI("SECURITYFILTER_DATABREACH_REGIST")}`,
    title: languageSet("데이터유출_보안필터_등록"),
    exact: true,
    isEdit: false,
    component: DataBreachFilterSetContainer,
  },
  SECURITYFILTER_DATABREACH_EDIT: {
    link: `${getURI("SECURITYFILTER_DATABREACH_EDIT")}/:webExtendsFilterId`,
    title: languageSet("데이터유출_보안필터_수정"),
    exact: true,
    isEdit: true,
    component: DataBreachFilterSetContainer,
  },
  //계정탈취
  SECURITYFILTER_EXTORTACCOUNT: {
    link: getURI("SECURITYFILTER_EXTORTACCOUNT"),
    withPageLink: `${getURI("SECURITYFILTER_EXTORTACCOUNT")}/page/:page`,
    licenseCode: EXTORTACCOUNT_LICENSECODE,
    component: SecurityFilterContainer,
  },
  SECURITYFILTER_EXTORTACCOUNT_REGIST: {
    title: languageSet("계정탈취_보안필터_등록"),
    licenseCode: EXTORTACCOUNT_LICENSECODE,
    link: `${getURI("SECURITYFILTER_EXTORTACCOUNT_REGIST")}`,
    component: ExtortAccountFilterEditContainer,
  },
  SECURITYFILTER_EXTORTACCOUNT_EDIT: {
    title: languageSet("계정탈취_보안필터_수정"),
    licenseCode: EXTORTACCOUNT_LICENSECODE,
    link: `${getURI("SECURITYFILTER_EXTORTACCOUNT_EDIT")}/:dosFilterId`,
    isEdit: true,
    component: ExtortAccountFilterEditContainer,
  },
  //홈페이지위변조
  SECURITYFILTER_WEBSITEFORERY: {
    link: getURI("SECURITYFILTER_WEBSITEFORERY"),
    licenseCode: WEBSITEFORGERY_LICENSECODE,
    component: SecurityFilterContainer,
  },
  SECURITYFILTER_WEBSITEFORERY_EDIT: {
    title: languageSet("홈페이지_위변조_보안필터_수정"),
    licenseCode: WEBSITEFORGERY_LICENSECODE,
    link: `${getURI("SECURITYFILTER_WEBSITEFORERY_EDIT")}/:serviceLinkedFilterCode/:filterId`,
    isEdit: true,
    component: WebsiteForgeryFilterEditContainer,
  },
};
