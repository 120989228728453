import { observable, computed, action } from "mobx";

import { SetterModel, Common_Utils, languageSet, dateUtil, formatConstants } from "@qubit-utill/src";

import { HostListModel } from "@src/common/models";

class DosFilterModel extends SetterModel {
  constructor(params, CodeStore) {
    super(params);
    if (params) {
      if (params.detectStartTime) {
        this.detectStartTime = dateUtil.newDate(params.detectStartTime, null);
      }
      if (params.detectEndTime) {
        this.detectEndTime = dateUtil.newDate(params.detectEndTime, null);
      }
      if (params.responseContentLengthMin || params.responseContentLengthMin === 0) {
        this.isUseResposeSize = "1";
      }

      // 다중 호스트 관련 처리
      // Ids 는 서버로 전달하는 파라미터, userHostReferenceList는 호스트 데이터
      this.userHostIds = [];
      if (params.userHostReferenceList) {
        this.userHostReferenceList = params.userHostReferenceList.map((data) => {
          this.userHostIds.push(data.userHostId);
          return new HostListModel(data, true);
        });
      } else {
        // 서버에서 null 값을 전달하여, 초기값 설정
        this.userHostReferenceList = [];
      }
      this.isHttpHostRegex = Common_Utils.isCheckBool(params.isHttpHostRegex);
      this.isHttpPathRegex = Common_Utils.isCheckBool(params.isHttpPathRegex);
    }
    this.CodeStore = CodeStore;
  }
  CodeStore;

  @computed
  get isUseFlag() {
    return Common_Utils.isCheckBool(this.isUse);
  }

  @computed
  get isDetectTimeRangeUseFlag() {
    return Common_Utils.isCheckBool(this.isDetectTimeRangeUse);
  }

  @computed
  get loginStatusTypeName() {
    return this.CodeStore.getCodeName("loginStatusType", this.loginStatusType);
  }

  @computed
  get timeRangeText() {
    return this.isDetectTimeRangeUseFlag ? `${this.detectStartTimeFormat}~${this.detectEndTimeFormat}` : languageSet("_24시간");
  }

  @computed
  get detectStartTimeFormat() {
    return this.detectStartTime ? dateUtil.format(this.detectStartTime, formatConstants.TIMEFORMAT) : null;
  }
  @computed
  get detectEndTimeFormat() {
    return this.detectEndTime ? dateUtil.format(this.detectEndTime, formatConstants.TIMEFORMAT) : null;
  }
  @computed
  get isUseResposeSizeFlag() {
    return Common_Utils.isCheckBool(this.isUseResposeSize);
  }

  @computed
  get fomatHttpPathCount() {
    return Common_Utils.numberWithComma(this.httpPathCount);
  }

  @computed
  get fomatHttpIDCount() {
    return Common_Utils.numberWithComma(this.httpIDCount);
  }

  @action
  toggleIsUseResposeSize = () => {
    this.toggleIsFlag("isUseResposeSize");
    if (!this.isUseResposeSizeFlag) {
      this.responseContentLengthMin = null;
      this.responseContentLengthMax = null;
    }
  };

  @observable
  isUseResposeSize = "0";
  @observable
  dosFilterId = "";
  @observable
  dosFilterName = "";
  @observable
  httpHost = "";
  @observable
  httpPath = "";
  @observable
  httpMethod = "POST";
  @observable
  httpStatus: number | string = 200;
  @observable
  responseContentLengthMin: any = null;
  @observable
  responseContentLengthMax: any = null;
  @observable
  httpLoginParameterName = "";
  @observable
  httpPathCount = "";
  @observable
  httpIDCount = "";
  @observable
  httpCountOperator = "OR";
  @observable
  loginStatusType = "";
  @observable
  isDetectTimeRangeUse = "0";
  @observable
  detectStartTime: string | Date = "";
  @observable
  detectEndTime: string | Date = "";
  @observable
  isUse = "0";
  @observable
  dosFilterType = "";
  @observable
  dosFilterTypeName = "";
  @observable
  isCustomStatus = false;

  @observable
  isHttpHostRegex = false;

  @observable
  isHttpPathRegex = false;

  @observable
  userHostIds: any = [];

  @observable
  userHostReferenceList: any = [];

  @computed
  get apiData() {
    const {
      isHttpHostRegex,
      isHttpPathRegex,
      dosFilterType,
      dosFilterName,
      userHostIds,
      httpPath,
      httpLoginParameterName,
      httpStatus,
      loginStatusType,
      isDetectTimeRangeUse,
      isDetectTimeRangeUseFlag,
      httpPathCount,
      httpIDCount,
      responseContentLengthMin,
      responseContentLengthMax,
      isUse,
      httpMethod,
      httpCountOperator,
      detectStartTimeFormat,
      detectEndTimeFormat,
      isUseResposeSizeFlag,
    } = this;
    return {
      isHttpHostRegex: Common_Utils.BoolToString(isHttpHostRegex),
      isHttpPathRegex: Common_Utils.BoolToString(isHttpPathRegex),
      dosFilterType,
      dosFilterName,
      userHostIds,
      httpPath,
      httpLoginParameterName,
      httpStatus,
      loginStatusType,
      isDetectTimeRangeUse,
      detectStartTime: isDetectTimeRangeUseFlag ? detectStartTimeFormat : null,
      detectEndTime: isDetectTimeRangeUseFlag ? detectEndTimeFormat : null,
      httpPathCount,
      httpIDCount,
      responseContentLengthMin: isUseResposeSizeFlag ? responseContentLengthMin : null,
      responseContentLengthMax: isUseResposeSizeFlag ? responseContentLengthMax : null,
      isUse,
      httpMethod,
      httpCountOperator,
    };
  }
}

export default DosFilterModel;
