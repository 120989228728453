import React from "react";
import { observer } from "mobx-react";
import Chart from "react-apexcharts";

import { languageSet } from "@qubit-utill/src";

import TableMessageWrap from "@src/common/components/organisms/Wrap/TableMessageWrap";
import GraphType from "@src/type/GraphType";

interface PropsType {
  category: string[];
  chartData: { log: any[]; original: any[] };
  exceptionData: { log: any[]; original: any[] };
  scale: { value: string };
  errorMessage: string;
  title?: string;
}

const BoxPlot: React.FC<PropsType & GraphType> = ({ category, chartData, exceptionData, errorMessage, scale, title }) => {
  const options = {
    chart: {
      type: "boxPlot" as any,
      toolbar: {
        show: false,
        tools: { zoom: false },
      },
    },
    colors: ["#FEB019"],
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    stroke: {
      width: 0.7,
    },
    title: {
      text: (title ? title : null) as any,
      style: {
        fontWeight: 600,
        fontSize: "15px",
      },
      align: "center" as any,
    },
    xaxis: {
      tickAmount: 6,
      tooltip: { enabled: false },
      labels: {
        formatter: function(value) {
          return category[value];
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function(value) {
          if (String(value).includes(".")) {
            return value.toFixed(1);
          }

          if (value >= 1000) {
            return value / 1000 + "k";
          }

          if (value >= 1000000) {
            return value / 1000000 + "M";
          }

          return value;
        },
      },
    },
    tooltip: { shared: false },
    legend: { show: false },
  };

  const data =
    chartData &&
    chartData[scale.value].map((data, index) => {
      const getDataArr = data ? data.map((item) => item) : [];
      return [index, getDataArr];
    });

  let isDefault = false;

  const scatterdata =
    exceptionData && exceptionData[scale.value].length > 0
      ? exceptionData[scale.value].map((data) => {
          if (data[0] === 0) {
            isDefault = true;
          }
          return data.map((item) => {
            return item;
          });
        })
      : [[0, ""]];

  if (!isDefault) {
    scatterdata.unshift([0, ""]);
  }

  const series = [
    {
      type: "boxPlot",
      data: data,
    },
    {
      name: languageSet("이탈값"),
      type: "scatter",
      data: scatterdata,
    },
  ];

  return errorMessage === "" ? (
    <Chart options={options} series={series} type="boxPlot" height={350} />
  ) : (
    <TableMessageWrap message={errorMessage} />
  );
};

export default observer(BoxPlot);
