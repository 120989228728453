import { observable, action } from "mobx";
import { UserException, withLoading } from "@qubit-utill/src";
import { pCustomerApis } from "@src/apis";
import ThemeOptionModel from "@src/pages/Theme/models/ThemeOptionModel";
import ThemeInfoModel from "../models/ThemeInfoModel";

class ThemeStore {
  constructor(root) {
    this.root = root;
  }
  root;

  @observable
  useFullPage = false;

  @action
  setUseFullPage = (flag) => {
    this.useFullPage = flag;
  };

  @observable
  isSideBarOpen = false;

  @observable
  isLoading = false;
  @observable
  isAPICalling = false;

  @observable
  THEME_COLOR = [];
  @observable
  MENU_COLOR = [];
  @observable
  BG_COLOR = [];

  @observable
  themeInfo = new ThemeInfoModel();

  @action
  @withLoading("isLoading")
  getOptions = async () => {
    try {
      const { list } = await pCustomerApis.getThemeOptionList();
      this.BG_COLOR = list.BG_COLOR.map((info) => new ThemeOptionModel(info));
      this.MENU_COLOR = list.MENU_COLOR.map((info) => new ThemeOptionModel(info));
      this.THEME_COLOR = list.THEME_COLOR.map((info) => new ThemeOptionModel(info));
    } catch (error) {}
  };

  @action
  getInfo = async () => {
    this.isAPICalling = true;
    this.root.LoadingStore.Loading();
    try {
      const { list } = await pCustomerApis.getThemeUserInfo();
      list.forEach((info) => {
        this.themeInfo.setInfo(info.servicePreferencesId, info);
      });
      this.setThemeClass(this.themeInfo);
    } catch (error) {
    } finally {
      this.root.LoadingStore.completed();
    }
  };

  @action
  setInfo = async (info: ThemeInfoModel) => {
    try {
      await pCustomerApis.setThemeUserInfo(info.apiData);
      this.themeInfo = new ThemeInfoModel(info);
    } catch ({ data }) {
      throw new UserException(data.returnCodeMessage || data.dataNotFoundCodeMessage);
    }
  };

  setThemeClass = (info = this.themeInfo) => {
    document.body.className = `${this.useFullPage ? "" : "fixed-topbar fixed-sidebar"} ${info.THEME_COLOR.className} ${
      info.BG_COLOR.className
    } ${info.MENU_COLOR.className} ${this.isSideBarOpen ? "sidebar-collapsed sidebar-show" : ""}`;
  };

  setBodyClass = (className) => (document.body.className = className);

  clearBodyClass = () => {
    this.setThemeClass(this.themeInfo);
  };
}

export default ThemeStore;
