import React, { Fragment, useRef } from "react";
import { UncontrolledTooltip } from "reactstrap";
import { observer } from "mobx-react";
import { path } from "@qubit-utill/src/common_constants";
import WithToggle from "../../../hoc/WithToggle";
import styles from "./NationAndIp.css";
import { stringUtils } from "@qubit-utill/src/utils";
import { COOKIELANG } from "@qubit-utill/src/common_constants";

const NationAndIp: React.FC<{
  nation: string;
  ip: string;
  isPrivateIp: boolean;
  toggle: boolean;
  setToggle: Function;
  onMouseOver: (event: any) => void;
  onMouseLeave: (event: any) => void;
  warpClass: string;
  ipClass: string;
  notUseIcon?: boolean;
  displayNationName?: string;
  customCOOKIELANG?: string;
}> = observer(
  ({
    nation,
    ip,
    isPrivateIp,
    toggle,
    setToggle,
    onMouseOver,
    onMouseLeave,
    warpClass,
    ipClass,
    notUseIcon,
    displayNationName,
    customCOOKIELANG = COOKIELANG,
  }) => {
    const targetRef = useRef(null);

    return !notUseIcon ? (
      <Fragment>
        <span
          className={`ip_w ip_area ${warpClass}`}
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
          ref={targetRef}
        >
          {isPrivateIp || !nation ? (
            <i className="fa fa-linkedin-square" />
          ) : (
            <img alt={nation} src={`${path.CDNURL}/ip_flags/${nation.toLowerCase()}.gif`} />
          )}
        </span>
        {ip && <span className={ipClass}>{ip}</span>}
        {nation && (
          <UncontrolledTooltip
            placement="right"
            isOpen={toggle}
            target={targetRef.current}
            toggle={setToggle}
            cssModule={styles}
          >
            {stringUtils.getNationFullName(nation, customCOOKIELANG)}
          </UncontrolledTooltip>
        )}
        {displayNationName && (
          <span className="p-lr-3 dis-inline-b width-70p verti_top">{displayNationName}</span>
        )}
      </Fragment>
    ) : (
      <span className={ipClass}>{ip}</span>
    );
  }
);

export default WithToggle(NationAndIp);
