import { axiosModules } from "@qubit-utill/src";

export default {
  getAgentCmdLogList: (params) => {
    return axiosModules.get(`/pserver/agent/agentcmdlogs`, params);
  },
  getAgentExcuteLogList: (params) => {
    return axiosModules.get(`/pserver/agentexecutelog`, params);
  },
  //todo dprecated
  getAgentExcuteLogRecnet: (serverId) => {
    return axiosModules.get(`/pserver/agentexecutelog/recent/${serverId}`, {
      agentExecuteServiceCode: "modPlura",
      agentExecuteType: "U",
    });
  },
};
