import { observable, computed } from "mobx";
import { SetterModel, Common_Utils, languageSet } from "@qubit-utill/src";

export default class DataBreachFilterModel extends SetterModel {
  constructor(params, CodeStore) {
    super(params);
    this.CodeStore = CodeStore;
    this.responseBodySizeCode = params ? params.responseBodySizeCode : CodeStore.getDefaultCode("responseBodySizeCode");
    this.keywordSizeCode = params ? params.keywordSizeCode : CodeStore.getDefaultCode("keywordSizeCode");
  }
  CodeStore;

  isPossibleAddDataType = true;
  isGlobalFilter = "0";
  isCustomFilter = "1";
  //아래 두값은 추후 선택값으로 빠질 우려있음
  isAgentFilter = "1";
  webExtendsFilterType = "webextendsdatabreach";

  @computed
  get isUseFlag() {
    return Common_Utils.isCheckBool(this.isUse);
  }

  @computed
  get webExtendsFilterAttackTypeName() {
    return this.webExtendsFilterAttackType
      ? this.CodeStore.getCodeName("webExtendsFilterAttackType", this.webExtendsFilterAttackType)
      : languageSet("매핑안함");
  }

  @computed
  get responseBodySizeCodeName() {
    return this.CodeStore.getCodeName("responseBodySizeCode", this.responseBodySizeCode);
  }

  @computed
  get keywordSizeCodeName() {
    return this.CodeStore.getCodeName("keywordSizeCode", this.keywordSizeCode);
  }

  @computed
  get webExtendsFilterRiskCodeName() {
    return this.CodeStore.getCodeName("webFilterRiskCode", this.webExtendsFilterRiskCode);
  }

  @observable
  webExtendsFilterId: string = "";
  @observable
  webExtendsFilterName: string = "";
  @observable
  webExtendsFilterRiskCode: string = "";
  @observable
  isUse: string = "0";
  @observable
  webExtendsFilterAttackType = ""; //sqli
  @observable
  keywordSizeCode = "";
  @observable
  responseBodyStartPosition = 0;
  @observable
  responseBodySizeCode = "";
  @observable
  filterCategoryId = 0;
  @observable
  registerUserName = "";
  @observable
  filterCategoryName = "";

  validation = () => {
    if (this.webExtendsFilterName.length > 60) {
      throw languageSet("메세지_입력된정보를확인하세요");
    }

    if (this.responseBodyStartPosition > 10000000) {
      throw languageSet("메세지_입력된정보를확인하세요");
    }

    if (!this.filterCategoryId) {
      throw languageSet("메세지_분류를_선택하세요");
    }

    if (!this.webExtendsFilterName) {
      throw languageSet("메세지_필터명입력필요");
    }

    if (!this.webExtendsFilterRiskCode) {
      throw languageSet("메세지_필터레벨선택");
    }
  };

  @computed
  get apiData() {
    const {
      webExtendsFilterName,
      webExtendsFilterAttackType,
      webExtendsFilterRiskCode,
      webExtendsFilterType,
      isGlobalFilter,
      isCustomFilter,
      isUse,
      isAgentFilter,
      keywordSizeCode,
      responseBodyStartPosition,
      responseBodySizeCode,
      filterCategoryId,
      webExtendsFilterId,
    } = this;
    let obj: any = {
      webExtendsFilterId,
      webExtendsFilterName,
      webExtendsFilterAttackType,
      webExtendsFilterRiskCode,
      webExtendsFilterType,
      isGlobalFilter,
      isCustomFilter,
      isUse,
      isAgentFilter,
      keywordSizeCode,
      responseBodyStartPosition,
      responseBodySizeCode,
      filterCategoryId,
      webExtendsFilterElements: [],
    };

    return obj;
  }
}
