import SetterModel from "../SetterModel"
import { observable, computed } from "mobx"
import { Common_Utils } from "../../utils"

export default class RoleUserCodeModel extends SetterModel {
  @observable
  apiPermissionCode = ""
  @observable
  isMethodGet = ""
  @observable
  isMethodPost = ""
  @computed
  get isRender() {
    return Common_Utils.isCheckBool(this.isMethodGet)
  }
  @computed
  get isAction() {
    return Common_Utils.isCheckBool(this.isMethodPost)
  }
}
