import { observable, action } from "mobx";

import { SetterModel } from "@qubit-utill/src";

import { WebExtortAccountEntryModel } from "@src/pages/Group/SettingDetect/models";

export default class WebExtortAccountModel extends SetterModel {
  constructor(param?) {
    super(param);
    if (param) {
      this.dosExceptCustomEntryModelList = param.dosExceptCustomEntryModelList.map(
        (info) => new WebExtortAccountEntryModel(info)
      );
    }
  }

  @action
  addEntry = () => {
    this.dosExceptCustomEntryModelList.push(new WebExtortAccountEntryModel());
  };
  @action
  deleteEntry = (index) => () => {
    this.dosExceptCustomEntryModelList.splice(index, 1);
  };

  @observable
  dosExceptCustomGroupId: number = 0;

  @observable
  guid: string = "";

  @observable
  registerUuid: string = "";

  @observable
  updateUuid: string = "";

  @observable
  dosExceptCustomEntryModelList: Array<WebExtortAccountEntryModel> = [new WebExtortAccountEntryModel()];
}
