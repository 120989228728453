// 인섬니아 : pserver > pserver > Middleware > Config
// 컴포넌트 : 관리 > HAProxy / SQUID

import { axiosModules } from "@qubit-utill/src";

export default {
  // config 추가
  createMiddlewareConfig: (middlewareId, middlewareUserConfigId, middlewareSectionId) => {
    return axiosModules.post(
      `/pserver/middlewareusersection/init/${middlewareId}/${middlewareUserConfigId}/${middlewareSectionId}`
    );
  },
  // config 수정
  updateMiddlewareConfig: (middlewareUserSectionId, isUse, middlewareUserConfigId, isScheduler, params) => {
    return axiosModules.put(
      `/pserver/middlewareuserproperty/${middlewareUserSectionId}/list/${isUse}/${middlewareUserConfigId}/${isScheduler}`,
      params
    );
  },
  // config 삭제
  deleteMiddlewareConfig: (middlewareUserSectionId, middlewareUserConfigId) => {
    return axiosModules.del(`/pserver/middlewareusersection/${middlewareUserSectionId}/${middlewareUserConfigId}`);
  },
  // managerConsole 추가
  createMiddlewareManagerConsole: (middlewareUserConfigId) => {
    return axiosModules.post(`/pserver/middlewaremanagerconsole/init/${middlewareUserConfigId}`);
  },
  // managerConsole 수정
  updateMiddlewareManagerConsole: (middlewareManagerConsole, param) => {
    return axiosModules.put(`/pserver/middlewaremanagerconsole/${middlewareManagerConsole}`, param);
  },
  // managerConsole 삭제
  deleteMiddlewareManagerConsole: (middlewareManagerConsole) => {
    return axiosModules.del(`/pserver/middlewaremanagerconsole/${middlewareManagerConsole}`);
  },
  // firewalld 수정
  updateMiddlewareFirewalld: (middlewareUserConfigId, param) => {
    return axiosModules.put(`/pserver/middlewarefirewalld/${middlewareUserConfigId}`, param);
  },
  // firewalld 실행
  executeMiddlewareFirewalld: (middlewareUserConfigId, serverGroupId, middlewareId) => {
    return axiosModules.get(`/pserver/middlewareexecute/firewalld/${middlewareUserConfigId}/${serverGroupId}/${middlewareId}
    `);
  },
  // ssl 추가/수정/삭제
  updateMiddlewareSsl: (middlewareUserConfigId, isUseSsl, params?) => {
    return axiosModules.put(`/pserver/middlewaressl/${middlewareUserConfigId}/list/${isUseSsl}`, params);
  },
  // property selectList 조회
  getMiddlewarePropertySelectList: (middlewareId, middlewareSectionId) => {
    return axiosModules.get(`/pserver/middlewareproperty/list/${middlewareId}/${middlewareSectionId}`);
  },
  // file 수정
  updateMiddlewareFile: (middlewareUserConfigId, middlewareUserSectionId, param) => {
    return axiosModules.put(`/pserver/middlewareusersectionfile/${middlewareUserConfigId}/${middlewareUserSectionId}`, param);
  },
};
