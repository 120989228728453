import { observable, computed } from "mobx";
import { SetterModel, Common_Utils } from "@qubit-utill/src";

class RefManageModel extends SetterModel {
  constructor(params?, CodeStore?) {
    super(params);
    this.CodeStore = CodeStore;
    if (params) {
      this.ipGroupName = params.filterElementReferenceName;
      this.isDefault = params.isDefault === "1";
      this.ipGroupId = params.filterElementReferenceId;
      this.ipCountInGroup = params.filterElementReferenceDataCount;
    }
  }

  @observable
  CodeStore;

  @computed
  get filterElementReferenceTypeText() {
    return this.CodeStore.getCodeName("filterElementReferenceType", this.filterElementReferenceType);
  }

  @computed
  get isProtectedGroup() {
    return Common_Utils.isCheckBool(this.isProtected);
  }

  @computed
  get isUseCustomGroup() {
    return Common_Utils.isCheckBool(this.isUseCustom);
  }

  @computed
  get filterElementReferenceKindText() {
    return this.CodeStore && this.CodeStore.getCodeName("filterElementReferenceKind", this.filterElementReferenceKind);
  }

  @computed
  get getRegisterUserName() {
    return Common_Utils.isDataWithDashBar(this.registerUserName);
  }

  @observable
  ipGroupId: number = 0;

  @observable
  ipGroupName: string = "";

  @observable
  ipCountInGroup: number = 0;

  @observable
  registerUserName: string = "";

  @observable
  isDefault: boolean = true;

  @observable
  ipList: Array<any> = [];

  @observable
  filterElementReferenceOrderNo: number = 0;

  @observable
  filterElementReferenceType: string = "";

  @observable
  isProtected: string = "0";

  @observable
  isUseCustom: string = "1";

  @observable
  filterElementReferenceKind: string = "";
}

export default RefManageModel;
