import { observable, computed } from "mobx";

import { Common_Utils, SetterModel, stringUtils } from "@qubit-utill/src";
import { COOKIELANG } from "@qubit-utill/src/common_constants";

import { CSVINFO } from "@src/constants";

class SystemDefenceModel extends SetterModel {
  constructor(info, CodeStore) {
    super(info);
    this.CodeStore = CodeStore;
    if (info) {
      this.defenceIp = info.detectDefenseIp;
      this.defenceSeparate = CodeStore.getCodeName("defenseConfigFilterType", info.defenseConfigFilterType);
      this.defenseIpValue = info.detectDefenseIp.split("/")[0];
      this.defenseBitValue = info.detectDefenseIp.split("/")[1] || this.defenseBitValue;
      this.isEdit = true;
    }
  }
  @observable
  CodeStore;

  @computed
  get getDefenceNation() {
    return stringUtils.getNationFullName(this.detectDefenseNation, COOKIELANG);
  }

  @computed
  get csvData() {
    return Common_Utils.makeCsvRowData(this, CSVINFO.DEFENSE_IP_SYSTEM.infoKey);
  }

  @computed
  get isActiveFlag() {
    return Common_Utils.isCheckBool(this.isActive);
  }

  @computed
  get defenceNation() {
    //방어IP국가
    return this.detectDefenseNation && this.detectDefenseNation.toLowerCase();
  }

  @computed
  get firewallSetting() {
    return Common_Utils.isCheckBool(this.isActive);
  }

  @computed
  get checkValid() {
    if (this.isEdit) {
      return this.serverId !== 0 && this.defenseDescription !== "";
    } else {
      return this.serverIds.length !== 0 && this.defenseDescription !== "";
    }
  }

  @observable
  defenseConfigFilterType: string = "register"; //방어분류(즉시차단/수동등록)

  @observable
  defenseDescription: string = ""; //방어 설명

  @observable
  detectDefenseFilterId: string = "";

  @observable
  detectDefenseId: number = 0; //차단IP고유 키

  @observable
  detectDefenseLogId: string = "";

  @observable
  detectDefenseNation: string = ""; //공격 아이피 지역코드

  @observable
  detectDefenseType: string = "manual"; //방어실행유형(auto, manual)

  @observable
  filterName: string = "";

  @observable
  isActive: string = "1";

  @observable
  registerDate: string = ""; //등록일

  @observable
  serverId: number = 0;

  @observable
  serverIds: Array<number> = [];

  @observable
  serverIp: string = "";

  @observable
  typeos: string = "";

  @observable
  updateDate: string = ""; //수정일

  @observable
  webFilterAttackType: string = "";

  @observable
  detectDefenseIp: string = ""; //공격 아이피

  @observable
  isPrivateIp: string = "";

  @observable
  defenceSeparate: string = ""; //방어구분

  @observable
  defenseIpValue: string = "";

  @observable
  defenseBitValue: string = "32";

  @observable
  defenceIp: string = ""; //방어IP

  @observable
  isSelected: boolean = false; //체크박스 선택 여부

  @observable
  logType: string = "system";

  @observable
  isEdit: boolean = false; //등록/수정 여부

  @observable
  hostName: string = ""; //호스트명
}

export default SystemDefenceModel;
