import { RISK_COLOR_PREFIX } from "@qubit-utill/src/constants/code";

export default {
  riskLevelToClass(filterRiskLevel: string) {
    if (filterRiskLevel === "4") {
      return "c-red";
    } else if (filterRiskLevel === "3") {
      return "c-orange";
    } else if (filterRiskLevel === "2") {
      return "c-yellow";
    } else {
      return "c-yellow";
    }
  },
  //risk level className 구하기.
  getRiskLevelClass(filterRiskLevelColor: string, colorType: "text" | "background") {
    return RISK_COLOR_PREFIX[colorType.toUpperCase()].concat(filterRiskLevelColor);
  },
};
