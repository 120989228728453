import { observable, computed, set } from "mobx";

import { Common_Utils } from "@qubit-utill/src";

import { ApplicationLogModel } from "@src/common/models";
import { CSVINFO } from "@src/constants";

export default class ApplicationResourceTotalLogModel extends ApplicationLogModel {
  constructor(params) {
    super(params);
    if (params) {
      this.logHash = params.logHash || "-";
      this.withMicroSecondsLogCreateDate = params.logCreateDate + params.logCreateMicroSeconds;

      if (params.resourceMetaList) {
        this.resourceArray = params.resourceMetaList.map((data) => {
          const logMetaInfo = params.logMetaList.find((el) => el.resourceMetaId === data.resourceMetaId);
          set(this, { [data.metaName]: Boolean(logMetaInfo) ? logMetaInfo.logMetaValue : "-" });

          return {
            [data.metaName]: Boolean(logMetaInfo) ? logMetaInfo.logMetaValue : "-",
            metaName: data.metaName,
          };
        });
      }
    }
  }

  @computed
  get totalLogIp() {
    return this.hostIp || this.serverIp;
  }

  @computed
  get csvData() {
    return Common_Utils.makeCsvRowData(this, CSVINFO.TOTALLOG_APP_RESOURCE.infoKey);
  }

  @computed
  get attackIp() {
    return this.logClientIp;
  }

  @computed
  get filterIds() {
    return this.filters ? this.filters.map(({ filterId }) => filterId) : [this.filterId];
  }

  @computed
  get isDefenceFlag() {
    return Common_Utils.isCheckBool(this.isDefence);
  }

  @computed
  get getServerGroup() {
    return Common_Utils.isDataWithDashBar(this.serverGroup);
  }

  @observable
  filters = [];

  @observable
  resourceArray = [];

  @observable
  filterId: string = "";

  @observable
  documentId = "";

  @observable
  serverGroup = "";

  @observable
  typeos = "";

  @observable
  serverIp = "";

  @observable
  hostIp = "";

  @observable
  hostName = "";

  @observable
  logSeverity = "";

  @observable
  filterChannelName = "";

  @observable
  logCreateDate = "";

  @observable
  serverId = "";

  @observable
  logHash = "";

  @observable
  logOrigin = "";

  @observable
  filterEventKey = "";

  @observable
  logMessage = "";

  @observable
  filterEventName = "";

  @observable
  logClientIp = "";

  @observable
  detectDefenseId = 0;

  @observable
  isActive: string = "0";

  @observable
  isDefence = "";

  defenseConfigFilterType: string = "filter";

  @observable
  logCreateMicroSeconds = "";

  @observable
  withMicroSecondsLogCreateDate: string = "";

  @observable
  serverName: string = "";

  @observable
  logTag: string = "";
}
