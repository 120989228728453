import { observable, computed, action } from "mobx";

import { SetterModel, Common_Utils, formatUtils, stringUtils } from "@qubit-utill/src";

import { CSVINFO, UISET, COOKIELANG } from "@src/constants";
import extortAccountUtils from "@src/utils/extortAccountUtils";

class LogListModel extends SetterModel {
  constructor(params) {
    super(params);
    this.riskLevel = params.riskLevelCode || params.webFilterRiskCode;
    this.serverOs = params.typeos;
    this.typeos = params.typeos;
    this.nation = params.detectNationCode && params.detectNationCode.toLowerCase();
    this.attackIp = params.detectIp;
    this.filterCategoryName = params.filterCategoryName;
    this.serverGroup = params.serverGroup;
    this.attackPercent = params.detectRatio && params.detectRatio + "%";
    this.detectURL = params.detectURL || "";
    this.CodeStore = params.CodeStore;
    this.csvInfoKey = params.csvInfoKey;
    this.detectStatusCodeParam = params.detectStatusCode ? `${params.detectStatusCode.toString().substring(0, 1)}00` : "";
    this.detectStatusCode = formatUtils.IsNullFormat(params.detectStatusCode);
    this.filterTypeName = params.filterTypeName;
    this.detectHosts = extortAccountUtils.setValueForMultiplePathHost(params.detectHost, "detectHttpHost");
    this.detectHostForSort = this.detectHosts.map(({ detectHttpHost }) => detectHttpHost);
    this.detectPath = extortAccountUtils.setValueForMultiplePathHost(params.detectPath, "detectHttpPath");
    this.detectPathForSort = this.detectPath.map(({ detectHttpPath }) => detectHttpPath);
    this.detectHost = params.detectHost;

    if (params) {
      if (params.logUploadBytesModel) {
        this.applicationLogUploadBytes = params.logUploadBytesModel.applicationLogUploadBytes;
        this.networkLogUploadBytes = params.logUploadBytesModel.networkLogUploadBytes;
        this.systemLogUploadBytes = params.logUploadBytesModel.systemLogUploadBytes;
        this.totalLogUploadBytes = params.logUploadBytesModel.totalLogUploadBytes;
        this.webLogUploadBytes = params.logUploadBytesModel.webLogUploadBytes;
      }

      if (params.logUploadCountModel) {
        this.applicationLogUploadCount = params.logUploadCountModel.applicationLogUploadCount;
        this.networkLogUploadCount = params.logUploadCountModel.networkLogUploadCount;
        this.systemLogUploadCount = params.logUploadCountModel.systemLogUploadCount;
        this.totalLogUploadCount = params.logUploadCountModel.totalLogUploadCount;
        this.webLogUploadCount = params.logUploadCountModel.webLogUploadCount;
      }
    }
  }

  CodeStore;

  @computed
  get getFilterName() {
    return Common_Utils.isDataWithDashBar(this.filterName);
  }

  @computed
  get getFilterTypeName() {
    return Common_Utils.isDataWithDashBar(this.filterTypeName);
  }

  @computed
  get riskLevelName() {
    return this.CodeStore.getCodeName("filterRiskLevel", this.riskLevel).substring(0, 1);
  }

  @computed
  get riskLevelCodeName() {
    return this.CodeStore.getCodeName("filterRiskLevel", this.riskLevel);
  }

  @computed
  get getRiskLevelCodeName() {
    return Common_Utils.isDataWithDashBar(this.riskLevelCodeName);
  }

  @computed
  get csvData() {
    return Common_Utils.makeCsvRowData(this, CSVINFO[this.csvInfoKey].infoKey);
  }

  @computed
  get getDetectCount() {
    return this.detectCount !== null ? Common_Utils.numberWithComma(this.detectCount) : "-";
  }

  csvInfoKey;

  @computed
  get formatDetectCount() {
    return Common_Utils.getCountNumberFormat(this.detectCount);
  }

  @computed
  get formatLoginIdCount() {
    return Common_Utils.getCountNumberFormat(this.detectLoginIdCount);
  }

  @computed
  get getCategoryName() {
    return Common_Utils.inspectValue(this.categoryName);
  }

  @computed
  get getFilterCategoryName() {
    return Common_Utils.isDataWithDashBar(this.filterCategoryName);
  }

  @action
  getParseSystemBytes = (unit) => {
    return this.isUseSysLog === "1" ? this.getParseBytes(unit, this.systemLogUploadBytes) : "-";
  };

  @action
  getParseWebBytes = (unit) => {
    return this.isUseWebLog === "1" ? this.getParseBytes(unit, this.webLogUploadBytes) : "-";
  };

  @action
  getParseAppBytes = (unit) => {
    return this.isUseApplicationLog === "1" ? this.getParseBytes(unit, this.applicationLogUploadBytes) : "-";
  };

  @action
  getParseNetBytes = (unit) => {
    return this.isUseNetworkLog === "1" ? this.getParseBytes(unit, this.networkLogUploadBytes) : "-";
  };

  @action
  getParseTotalBytes = (unit) => {
    return this.isUseSysLog === "0" &&
      this.isUseWebLog === "0" &&
      this.isUseApplicationLog === "0" &&
      this.isUseNetworkLog === "0"
      ? "-"
      : this.getParseBytes(unit, this.totalLogUploadBytes);
  };

  getParseBytes = (unit, value) => Common_Utils.numberWithComma(Common_Utils.getParseBytes(unit, value));
  getLocalUnit = () => localStorage.getItem(UISET[this.csvInfoKey].localStorageName) || "MB";

  @computed
  get getSystemCount() {
    return this.isUseSysLog === "1" ? Common_Utils.numberWithComma(this.systemLogUploadCount) : "-";
  }

  @computed
  get getAppCount() {
    return this.isUseApplicationLog === "1" ? Common_Utils.numberWithComma(this.applicationLogUploadCount) : "-";
  }

  @computed
  get getWebCount() {
    return this.isUseWebLog === "1" ? Common_Utils.numberWithComma(this.webLogUploadCount) : "-";
  }

  @computed
  get getNetCount() {
    return this.isUseNetworkLog === "1" ? Common_Utils.numberWithComma(this.networkLogUploadCount) : "-";
  }

  @computed
  get getTotalLogUploadCount() {
    return this.isUseSysLog === "0" &&
      this.isUseWebLog === "0" &&
      this.isUseApplicationLog === "0" &&
      this.isUseNetworkLog === "0"
      ? "-"
      : Common_Utils.numberWithComma(this.totalLogUploadCount);
  }

  @computed
  get sysLogUploadBytesText() {
    const unit = this.getLocalUnit();
    return this.getParseSystemBytes(unit);
  }

  @computed
  get webLogUploadBytesText() {
    const unit = this.getLocalUnit();
    return this.getParseWebBytes(unit);
  }

  @computed
  get applicationLogUploadBytesText() {
    const unit = this.getLocalUnit();
    return this.getParseAppBytes(unit);
  }

  @computed
  get networkLogUploadBytesText() {
    const unit = this.getLocalUnit();
    return this.getParseNetBytes(unit);
  }
  @computed
  get totalLogUploadBytesText() {
    const unit = this.getLocalUnit();
    return this.getParseTotalBytes(unit);
  }

  @computed
  get filterAttackPattern() {
    return this.webFilterPattern ? `${this.filterAttackType} > ${this.webFilterPattern}` : this.filterAttackType;
  }

  @computed
  get getServerGroup() {
    return Common_Utils.isDataWithDashBar(this.serverGroup);
  }

  @computed
  get getFilterAttackType() {
    return Common_Utils.isDataWithDashBar(this.filterAttackType);
  }

  @computed
  get getFilterCategoryGroupName() {
    return Common_Utils.isDataWithDashBar(this.filterCategoryGroupName);
  }

  @computed
  get getHostName() {
    return Common_Utils.isDataWithDashBar(this.hostname);
  }

  @computed
  get getServerIp() {
    return Common_Utils.isDataWithDashBar(this.serverIp);
  }

  @computed
  get getNation() {
    return Common_Utils.isDataWithDashBar(stringUtils.getNationFullName(this.nation, COOKIELANG));
  }

  @computed
  get getServerOs() {
    return Common_Utils.isDataWithDashBar(this.serverOs);
  }

  @computed
  get getDetectHosts() {
    return Common_Utils.isDataWithDashBar(Common_Utils.setArrayObjToStringForCSV(this.detectHosts, "detectHttpHost"));
  }

  @computed
  get getDetectPath() {
    return Common_Utils.isDataWithDashBar(Common_Utils.setArrayObjToStringForCSV(this.detectPath, "detectHttpPath"));
  }

  @computed
  get isVisibleLogCountByChannelFlag() {
    return this.osFamily === "windows" || this.osFamily === "linux";
  }

  @computed
  get isLinuxFlag() {
    return this.osFamily === "linux";
  }

  @action
  getTypeosFamily = async () => {
    try {
      const osList = this.CodeStore.osList.filter;

      if (osList) {
        osList.osFamily.forEach(({ typeosList, osFamily }) => {
          if (typeosList.indexOf(this.typeos) > -1 && osFamily) {
            this.osFamily = osFamily;
          }
        });
      }
    } catch ({ data }) {
      console.log("error", data);
    }
  };

  @observable
  detectStatusCodeParam = "";

  @observable
  filterAttackType = "";

  @observable
  webFilterPattern = "";

  @observable
  detectRatio = 0;

  @observable
  riskLevel = "";

  @observable
  filterName: string = "";

  @observable
  registerDate: string = "";

  @observable
  serverOs: string = "";

  @observable
  serverIp: string = "";

  @observable
  nation = "";

  @observable
  attackIp = "";

  @observable
  detectCount = 0;

  @observable
  hostname: string = "";

  @observable
  categoryName: string = "";

  @observable
  attackPercent = "";

  @observable
  searchLogStatusRange = "0";

  @observable
  detectStatusCode: string = "";

  @observable
  serverGroup = "";

  @observable
  filterCategoryName: string = "";

  @observable
  detectURL: string = "";

  @observable
  detectDate: string = "";

  @observable
  filterId: string = "";

  @observable
  actionDetectStatsValue = "";

  @observable
  serverId: number = 0;

  @observable
  filterType: string = "";

  @observable
  detectHosts: any = [];

  @observable
  detectHost: string = "";

  @observable
  detectPath: any = [];

  @observable
  detectLoginIdCount: number = 0;

  @observable
  filterCategoryGroupName: string = "";

  @observable
  filterTypeName = "";

  @observable
  detectHostForSort = "";

  @observable
  detectPathForSort = "";

  @observable
  typeos: string = "";

  @observable
  osFamily: string = "";

  @observable
  logTag: string = "";

  @observable
  packageDetectCount: number = 0;

  @observable
  systemDetectCount: number = 0;

  @observable
  webDetectCount: number = 0;

  @observable
  networkDetectCount: number = 0;

  @observable
  dosDetectCount: number = 0;

  @observable
  wafDetectCount: number = 0;

  @observable
  webExtendsDataBreachDetectCount: number = 0;

  @observable
  edrDetectCount: number = 0;

  //시스템로그 업로드
  @observable
  systemLogUploadBytes: number = 0;

  //응용프로그램 원본로그 업로드
  @observable
  applicationLogUploadBytes: number = 0;

  //웹로그 업로드
  @observable
  webLogUploadBytes: number = 0;

  //네트워크로그 업로드
  @observable
  networkLogUploadBytes: number = 0;

  //총로그업로드
  @observable
  totalLogUploadBytes: number = 0;

  //시스템로그 업로드 수
  @observable
  systemLogUploadCount: number = 0;

  //응용프로그램 원본로그 업로드 수
  @observable
  applicationLogUploadCount: number = 0;

  //웹로그 업로드 수
  @observable
  webLogUploadCount: number = 0;

  //네트워크로그 업로드 수
  @observable
  networkLogUploadCount: number = 0;

  //총로그업로드 수
  @observable
  totalLogUploadCount: number = 0;

  @observable
  isUseSysLog: string = "0";

  @observable
  isUseWebLog: string = "0";

  @observable
  isUseApplicationLog: string = "0";

  @observable
  isUseNetworkLog: string = "0";
}

export default LogListModel;
