import { observable, action } from "mobx";

import { ListCondition, withLoading } from "@qubit-utill/src";

import { detectBlockedApis } from "@src/apis/pLogDetect";
import { SystemLogModel } from "@src/pages/Defence/DefenseLog/models";

class SystemLogStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }
  root;

  @observable
  tableList = [];

  @observable
  defenceLogInfo = {};

  @withLoading("isLoading")
  @action
  getList = async () => {
    try {
      const { list, totalCount } = await detectBlockedApis.getBlockedLogList(this.params);
      this.tableList = list.map((data) => new SystemLogModel(data));
      this.totalCount = totalCount;
    } catch (response) {
      this.tableList = [];
      this.totalCount = 0;
      return response;
    }
  };

  @withLoading("isSubLoading")
  @action
  getInfo = async (documemtId) => {
    try {
      const { info } = await detectBlockedApis.getBlockedLog(documemtId);
      this.defenceLogInfo = new SystemLogModel(info);
    } catch (response) {
      this.defenceLogInfo = {};
    }
  };
}

export default SystemLogStore;
