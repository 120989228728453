import { axiosModules } from "@qubit-utill/src";

export default {
  //웹필터관리 리스트
  getWebFilterList: (param) => axiosModules.get(`/pfilter/webfilter`, param),
  //웹필터정보 조회
  getWebFilterInfo: (webFilterId) => axiosModules.get(`/pfilter/webfilter/${webFilterId}`),
  //웹필터등록
  createWebFilterInfo: (param) => axiosModules.post(`/pfilter/webfilter`, param),
  //웹필터수정
  updateWebFilterInfo: (webFilterId, param) => axiosModules.put(`/pfilter/webfilter/${webFilterId}`, param),
  //웹필터삭제
  deleteWebFilterInfo: (webFilterId) => axiosModules.del(`/pfilter/webfilter/${webFilterId}`),
};
