import { observable, action, set } from "mobx";
import { codeApis, pserverApis, pauthApis, channelApis } from "../apis";
import { OsOptionModel, OsFamilyModel, FilterChannelModel } from "../model";
import { arrayForSelectBox } from "../utils";

class CodeStore {
  constructor() {
    this.setCode();
    this.setLinkUrl();
  }

  @observable
  code = {
    roleList: [],
  };

  @observable
  codeListForSelect: { [key: string]: any[] } = { roleList: [] };

  @observable
  osList = {};

  @observable
  osFamilyListForSelect = new Map();

  @observable
  typeosListForSelect = new Map();

  @observable
  typeosListByOsFamilyForSelect = new Map();

  @observable
  channelList = {};

  @observable
  channelListForSelect = new Map();

  @observable
  codeList = {
    roleList: [],
  };

  @observable
  linkUrlList = {};

  @observable
  isRender = false;

  @action
  setCode = async () => {
    try {
      const { list } = await codeApis.getCodeListByArr([
        "filterType",
        "filterRiskLevel",
        "webFilterRiskCode",
        "packageFilterDetectRatioRange",
        "detectFilterType",
        "defaultDashBoardFilterType",
        "webExceptCustomEntryType",
        "agentCmdLogType",
        "filterSeverityCode",
        "useLicenseType",
        "alarmIntervalTime",
        "alarmReportType",
        "mappingDetectFilterTypeToIpLog",
        "typeos",
        "supportNation",
        "serverLogType",
        "qnaCategoryCode",
        "webFilterOwaspCode",
        "filterResourceType",
        "searchColumnType",
        "customerSupportStatus",
        "ticketSystemParameterScheme",
        "ticketSystemResponseType",
        "complianceFilterType",
        "linkUrlCode",
        "webFilterAttackType",
        "detectStatusCodeRange",
        "storageType",
        "serviceRegion",
        "logBlocked",
        "wafUploadMode",
        "ticketIssueType",
        "searchColumnAssign",
        "supportLang",
        "filterElementReferenceType",
        "agentExecuteServiceCode",
        "displayAgentExecuteState",
        "logUploadOrder",
        "converterType",
        "removeValueTextType",
        "removeTextType",
        "agentResourceTypeCode",
        "responseBodySizeCode",
        "keywordSizeCode",
        "webExtendsFilterAttackType",
        "mitreTechniqueAdditionEntry",
        "passwordLoginFailMin",
        "pinLoginFailMin",
        "otpMailLoginFailMin",
        "syslogProtocolType",
        "messageFormatUDP",
        "messageFormatTCP",
        "newlineType",
        "loginStatusType",
        "scheduleDowType",
        "scheduleHourType",
        "WebUploadExceptEntryType",
        "alarmMessageFilterDetectType",
        "alarmMessageFilterDefenseType",
        "userActionCategoryGroupService",
        "forensicSearchYear",
        "defenselogsystem",
        "defenselogwebfirewall",
        "customDefenseFilterType",
        "webExtendsFilterType",
        "filterElementReferenceKind",
        "encodeType",
        "defenseConfigFilterType",
        "userHostReferenceServiceCode",
        "outXForwardedForType",
        "httpIpType",
        "ipAddressType",
        "statsReportType",
        "logTagReferenceCode",
        "serverLogConfigDefaultPath",
        "resourceMetaType",
        "filterSelectorOperatorType",
        "filterSelectorOperator",
        "resourceMetaUnitValue",
        "resourceGraphType",
        "resourceRiskSelectorOperator",
        "filterDetectType",
        "webhookProvider",
        "displayLogType",
        "logPeriodServiceLogType",
        "searchLimitCount",
        "filterFacilityCode",
        "userPasswordRuleCode",
        "resourceMonitoringCode",
        "graphAppearTime",
        "dosExceptCustomEntryType",
        "dosExceptSelectorOperator",
        "webFilterPattern_XXE",
        "webFilterPattern_SQLI",
        "forensicType",
        "forensicCategory",
        "forensicExecuteType",
        "forensicManualType",
        "forensicOs",
      ]);

      if (list) {
        const code = {};
        const _codeList = {};
        const codeListForSelect = {};
        list.forEach(({ list: codeList, codeGroup }) => {
          if (codeList) {
            const codeObj = codeList.reduce((acc, cur, i) => {
              acc[cur.code] = cur;
              return acc;
            }, {});
            code[codeGroup] = codeObj;
            _codeList[codeGroup] = codeList;
            codeListForSelect[codeGroup] = arrayForSelectBox.parseValueObjectForSelectBox(
              codeList,
              "codeName"
            );
          }
        });

        this.code = { ...this.code, ...code };
        this.codeListForSelect = { ...this.codeListForSelect, ...codeListForSelect };
        this.codeList = { ...this.codeList, ..._codeList };
        this.isRender = true;
      }
    } catch (e) {
      console.error("setCode error", e);
    }
  };

  @action
  setLinkUrl = async () => {
    try {
      const { list } = await codeApis.getLinkUrl();
      if (list) {
        list.forEach(info => {
          set(this.linkUrlList, { [info.linkUrlCode]: info });
        });
      }
    } catch (e) {
      console.log("setLinkUrl error", e);
    }
  };

  @action
  getCodeData = (key, value) => {
    if (this.code[key] && this.code[key][value]) {
      return this.code[key][value];
    }
  };

  @action
  getCodeName = (key, value, initValue?) => {
    if (this.code[key] && this.code[key][value]) {
      return this.code[key][value].codeName;
    } else {
      return initValue ? initValue : "";
    }
  };

  @action
  getCodeOption = (key, value, initValue?) => {
    if (this.code[key] && this.code[key][value]) {
      return this.code[key][value].codeOption;
    } else {
      return initValue ? initValue : "";
    }
  };

  @action
  getCodeAdditionalValue = (key, value, initValue?) => {
    if (this.code[key] && this.code[key][value]) {
      return this.code[key][value].codeAdditionalValue;
    } else {
      return initValue ? initValue : "";
    }
  };

  @action
  getDefaultCode = key => {
    if (this.codeList[key]) {
      const defaultCode = this.codeList[key].find(codeInfo => codeInfo.isDefault === "1");
      return defaultCode ? defaultCode.code : "";
    }
    return "";
  };

  @action
  getCodeList = async codeGroup => {
    if (!this.code[codeGroup]) {
      try {
        const { list } = await codeApis.getCodeList(codeGroup);
        this.code[codeGroup] = list.reduce((acc, cur, i) => {
          acc[cur.code] = cur;
          return acc;
        }, {});
        this.codeListForSelect[codeGroup] = arrayForSelectBox.parseValueObjectForSelectBox(
          list,
          "codeName"
        );
      } catch (e) {
        console.error("getCodeList error", e);
      }
    }
  };

  @action
  getOsListByType = async (osType, logType?) => {
    // if (!this.osList[osType]) {
    try {
      const { list } = await pserverApis.getOsListByType(osType, logType);
      this.osList[osType] = new OsFamilyModel(list);
      this.osFamilyListForSelect.set(
        osType,
        list.osFamily.map(info => new OsOptionModel(info, "osFamily"))
      );
      this.typeosListForSelect.set(
        osType,
        list.typeos.map(info => new OsOptionModel(info, "typeos"))
      );

      let typeosListByOsFamily = {};

      list.osFamily.map(
        ({ typeosList, osFamily }) =>
          (typeosListByOsFamily[osFamily] = arrayForSelectBox.parsePrimitiveValueForSelectBox(
            typeosList
          ))
      );

      this.typeosListByOsFamilyForSelect.set(osType, typeosListByOsFamily);
    } catch (e) {
      console.error("getOsListByType error", e);
    }
    // }
  };

  @action
  getRoleList = async (params?) => {
    try {
      const data = await pauthApis.getRoleListPublic(params);
      const codeObj = await data.list.reduce((acc, cur, i) => {
        acc[cur.roleCode] = {
          code: cur.roleCode,
          codeName: cur.roleName,
        };
        return acc;
      }, {});
      this.code.roleList = codeObj;
      this.codeList.roleList = data.list.map(({ roleCode, roleName }) => ({
        code: roleCode,
        codeName: roleName,
      }));
      this.codeListForSelect.roleList = arrayForSelectBox.parseValueObjectForSelectBox(
        data.list,
        "roleName"
      );
    } catch (e) {
      console.error("getRoleList error", e);
    }
  };

  //필터 등록시에만 사용하는 채널 리스트 api
  @action
  getChannelByTypeos = async typeos => {
    if (!this.channelList[typeos]) {
      try {
        const { list } = await channelApis.getChannelListForFilter(typeos);
        this.channelList[typeos] = list;
        this.channelListForSelect.set(typeos, list.map(element => new FilterChannelModel(element)));
      } catch (e) {
        console.error("getChannelByTypeos error", e);
      }
    }
  };
}

export default CodeStore;
