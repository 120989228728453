//------------------------------- TopBar -------------------------------
export const GROUPSUPPORTSERVICE_LICENSECODE = "groupSupportService";
export const GROUPMEMBERADD_LICENSECODE = "groupmemberadd";
export const GROUPMEMBERINVITE_LICENSECODE = "groupmemberinvite";
export const LOGINOTPMAIL_LICENSECODE = "loginOtpMail";
export const MEMBERADDITIONALINFO_LICENSECODE = "memberAdditionalInfo";
export const CUSTOMAPI_LICENSECODE = "customApi";
export const SYSLOG_LICENSECODE = "syslog";
export const CUSTOMERSUPPORT_LICENSECODE = "customerSupport";
export const SERVERLOSTSTATUS_LICENSECODE = "serverLostStatus";
export const TICKETCONFIG_LICENSECODE = "ticketConfig";
export const QUESTIONANDANSWER_LICENSECODE = "questionAndAnswer";
export const SUPPORTLANG_LICENSECODE = "supportLang";
export const ALARM_LICENSECODE = "alarm";
export const GROUPALARM_LICENSECODE = "groupalarm";
export const WEBUPLOAD_LICENSECODE = "webupload";
export const SYSTEMUPLOAD_LICENSECODE = "systemupload";

//------------------------------- 대시보드 -------------------------------
export const DASHBOARDCONFIG_LICENSECODE = "dashboardConfig";
export const LIMITLOGUPLOAD_LICENSECODE = "limitlogupload";
export const LOGFORENSIC_LICENSECODE = "logforensic";

//------------------------------- 서비스 -------------------------------
//보안탐지
export const SECURITYDETECT_LICENSECODE = "securitydetect";
//보안탐지 > 마이터
export const MITRE_LICENSECODE = "mitre";
//보안탐지 > 상관분석
export const CORRELATION_LICENSECODE = "correlation";
//보안탐지 > 데이터유출
export const WEBEXTENDSDATABREACH_LICENSECODE = "webextendsdatabreach";
//보안탐지 > 계정탈취
export const EXTORTACCOUNT_LICENSECODE = "ddos";
//보안탐지 > 홈페이지 위변조
export const WEBSITEFORGERY_LICENSECODE = "websiteforgery";
//대시보드 > 웹로그 & 화면설정 > 웹 /// 필터탐지 > 웹 /// 전체로그 > 웹 /// 추출로그 > URL /// 보고서 > 웹분석
export const WEB_LICENSECODE = "web";
//필터탐지 > 네트워크
export const FILTERDETECTNET_LICENSECODE = "filterdetectnet";
//필터탐지 > 웹방화벽
export const WAF_LICENSECODE = "waf";
//ML탐지
export const FILTERDETECTML_LICENSECODE = "filterdetectml";
//전체로그 메뉴
export const TOTALLOG_LICENSECODE = "totallog";
//필터메뉴
export const FILTER_LICENSECODE = "filter";
//방어메뉴/즉시방어 버튼 랜더링 라이센스 코드
export const DEFENCE_LICENSECODE = "defence";
//시스템 > 시스템관리 :  토글뷰
export const SERVERMANAGEMENTDETAIL_LICENSECODE = "serverManagementDetail";
//시스템 > 시스템관리 > 설정TAB : 전체로그 저장 영역 랜더링
export const SERVERTOTALLOG_LICENSECODE = "servertotallog";
//시스템 > 리소스모니터링 : 사이드바 영역 렌더링
export const RESOURCEMONITORING_LICENSECODE = "resourcemonitoring";
//추출로그 > Login
export const EXTRACTIONLOGIN_LICENSECODE = "extractionlogin";

//컴플라이언스 라이센스 코드
export const COMPLIANCE_LICENSECODE = "compliance";

// 1.보고서 메뉴
// 2.통계 메뉴
export const REPORT_LICENSECODE = "report";
//통계 > 박스플롯
export const BOXPLOT_LICENSECODE = "boxplot";

//타이머 뷰 라이센스 코드 (Enterprise(On-Prem), Premium 제공)
export const RELOADTIMER_LICENSECODE = "reloadTimer";

// 개인알림 > 기타 : 보고서 메일
export const REPORTMAIL_LICENSECODE = "reportmail";

// 시스템 > 시스템관리 > 포렌식
export const FORENSIC_LICENSECODE = "forensic";

//----------------------export default에 포함시키지 않음------------------------------------------
//라이센스 사용하는 서비스정의
export const USE_LICENSECODE_LIST = [
  WAF_LICENSECODE,
  MITRE_LICENSECODE,
  WEBEXTENDSDATABREACH_LICENSECODE,
  EXTORTACCOUNT_LICENSECODE,
  WEB_LICENSECODE,
  "custom",
];

export default {
  GROUPSUPPORTSERVICE_LICENSECODE,
  GROUPMEMBERADD_LICENSECODE,
  GROUPMEMBERINVITE_LICENSECODE,
  LOGINOTPMAIL_LICENSECODE,
  MEMBERADDITIONALINFO_LICENSECODE,
  CUSTOMAPI_LICENSECODE,
  CUSTOMERSUPPORT_LICENSECODE,
  SERVERLOSTSTATUS_LICENSECODE,
  TICKETCONFIG_LICENSECODE,
  QUESTIONANDANSWER_LICENSECODE,
  SUPPORTLANG_LICENSECODE,
  ALARM_LICENSECODE,
  GROUPALARM_LICENSECODE,
  WEBUPLOAD_LICENSECODE,
  SYSTEMUPLOAD_LICENSECODE,
  DASHBOARDCONFIG_LICENSECODE,
  LIMITLOGUPLOAD_LICENSECODE,
  LOGFORENSIC_LICENSECODE,
  SECURITYDETECT_LICENSECODE,
  MITRE_LICENSECODE,
  CORRELATION_LICENSECODE,
  WEBEXTENDSDATABREACH_LICENSECODE,
  EXTORTACCOUNT_LICENSECODE,
  WEBSITEFORGERY_LICENSECODE,
  WEB_LICENSECODE,
  FILTERDETECTNET_LICENSECODE,
  WAF_LICENSECODE,
  FILTERDETECTML_LICENSECODE,
  TOTALLOG_LICENSECODE,
  FILTER_LICENSECODE,
  DEFENCE_LICENSECODE,
  SERVERMANAGEMENTDETAIL_LICENSECODE,
  SERVERTOTALLOG_LICENSECODE,
  RESOURCEMONITORING_LICENSECODE,
  EXTRACTIONLOGIN_LICENSECODE,
  COMPLIANCE_LICENSECODE,
  REPORT_LICENSECODE,
  BOXPLOT_LICENSECODE,
  RELOADTIMER_LICENSECODE,
};
