import { observable, computed } from "mobx";

import { Common_Utils, languageSet, dateUtil, formatConstants, SetterModel } from "@qubit-utill/src";

class ApplicationFilterDefaultModel extends SetterModel {
  constructor(params, CodeStore) {
    super(params);
    this.CodeStore = CodeStore;
    if (params) {
      this.riskLevel = params.filterRiskLevel;
      this.filterEventType = params.filterEventName
        ? `${
            params.filterEventName.length > 50
              ? Common_Utils.textOverflow(params.filterEventName.replace(/\n/gi, ""), 50)
              : params.filterEventName
          }(${params.filterEventKey})`
        : "-";
      this.detectStartTime = dateUtil.newDate(params.detectStartTime, null);
      this.detectEndTime = dateUtil.newDate(params.detectEndTime, null);
    }
  }
  @observable
  CodeStore;

  @computed
  get isUseFlag() {
    return Common_Utils.isCheckBool(this.isUse);
  }

  @computed
  get isLockFlag() {
    return Common_Utils.isCheckBool(this.isLock);
  }

  @computed
  get isDetectDisplayFlag() {
    return Common_Utils.isCheckBool(this.isDetectDisplay);
  }

  @computed
  get isUseUserDescriptionFlag() {
    return Common_Utils.isCheckBool(this.isUseUserDescription);
  }

  @computed
  get isCustomFilterName() {
    return this.isCustomFilter === "0" ? languageSet("추천") : languageSet("사용자");
  }

  @computed
  get riskName() {
    return this.CodeStore.getCodeName("filterRiskLevel", this.filterRiskLevel);
  }

  @computed
  get isUseCustomFlag() {
    return Common_Utils.isCheckBool(this.isUseCustom);
  }

  @computed
  get isDetectTimeRangeUseFlag() {
    return Common_Utils.isCheckBool(this.isDetectTimeRangeUse);
  }

  @computed
  get detectStartTimeFormat() {
    return this.detectStartTime ? dateUtil.format(this.detectStartTime, formatConstants.TIMEFORMAT) : null;
  }
  @computed
  get detectEndTimeFormat() {
    return this.detectEndTime ? dateUtil.format(this.detectEndTime, formatConstants.TIMEFORMAT) : null;
  }

  @computed
  get isPossibleAddDataType() {
    return Boolean(this.filterEventId);
  }

  @computed
  get getServerGroup() {
    return Common_Utils.isDataWithDashBar(this.serverGroup);
  }

  @observable
  filterCategoryId: number = 1;

  @observable
  filterCategoryName: string = "";

  filterCategoryType: string = "";

  @observable
  filterChannelId: number = 0;

  @observable
  filterChannelName: string = "";

  @observable
  filterChannelOrderNo: number = 1;

  @observable
  filterCount: number = 0;

  @observable
  filterCustomConfigId: number = 0;

  @observable
  filterDescription: string = "";

  @observable
  filterElement: string = "";

  @observable
  filterElementJson = [];

  @observable
  filterElementRoot: string = "";

  @observable
  filterEventId: number = 0; //응용프로그램 필터이벤트 아이디

  @observable
  filterEventKey: string = "";

  @observable
  filterEventName: string = "";

  @observable
  filterId: string = "";

  @observable
  filterName: string = "";

  @observable
  filterNo: number = 0;

  @observable
  filterRiskLevel: string = "";

  @observable
  filterType: string = ""; //20 = 보안 10 = 관리

  @observable
  filterUserDescription: string = "";

  @observable
  guid: string = "";

  @observable
  isCustomFilter: string = "1";

  @observable
  isDetectDisplay: string = "";

  @observable
  isDetectTimeRangeUse: string = "";

  @observable
  isDisplay: string = "";

  @observable
  isGlobalFilter: string = "";

  @observable
  isLock: string = "";

  @observable
  isSingleEvent: string = "";

  @observable
  isUse: string = "1";

  @observable
  isUseCustom: string = "0";

  @observable
  isUseUserDescription: string = "";

  @observable
  logTag: string = "";

  @observable
  registerGuid: string = "";

  @observable
  serverGroupId: number = 0;

  @observable
  typeos: string = "";

  @observable
  filterOs: string = "";

  @observable
  serverGroup: string = "";

  @observable
  coverage: string = "";

  @observable
  riskLevel: string = "";

  @observable
  filterEventType: string = "-";

  @observable
  securityFilterType: string = "";

  @observable
  isSelected: boolean = false;

  @observable
  detectStartTime: string = "";

  @observable
  detectEndTime: string = "";
}

export default ApplicationFilterDefaultModel;
