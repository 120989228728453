import { axiosModules } from "@qubit-utill/src";

export default {
  getBlockedLogList: (params) => {
    return axiosModules.get("/plogdetect/detectblocked/list", params);
  },
  getBlockedLog: (documentId) => {
    return axiosModules.get(`/plogdetect/detectblocked/${documentId}`);
  },
  getWafBlockedLogList: (params) => {
    return axiosModules.get("/plogdetect/wafblocked/list", params);
  },
};
