export default function getTrFilters(filters, CodeStore) {
  return filters
    ? filters.map(({ filterRiskLevel, filterCategoryName, filterName, filterId, filterType, filterDetectType }) => ({
        filterId,
        attack: filterName,
        attackStage: filterRiskLevel,
        classfication: filterCategoryName,
        step: CodeStore.getCodeName("webFilterRiskCode", filterRiskLevel),
        filterType: filterType === "20",
        filterDetectType,
      }))
    : [];
}
