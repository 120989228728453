import { axiosModules } from "@qubit-utill/src";

export default {
  // paccount > gnbuser
  getGnbUserInfo: () => {
    return axiosModules.get("/paccount/gnbuser");
  },

  // paccount > 유저게스트 > 유저게스트 삭제
  deleteUserGuest: (userGuestId) => {
    return axiosModules.del(`/paccount/userguest/${userGuestId}`);
  },

  // paccount > 유저게스트 > 멤버초대
  createUserGuest: (data) => {
    return axiosModules.post(`/paccount/userguest`, data);
  },

  // paccount > 유저게스트 > 유저게스트 조회
  getUserGuest: (params) => {
    return axiosModules.get("/paccount/userguest", params);
  },

  // paccount > Company > 회사이름 수정
  updateUser: (guid, data) => {
    return axiosModules.put(`/paccount/company/${guid}`, data);
  },

  // paccount > 회원가입 > (OP)서브유저 회원가입 > 사용자 추가
  createSubUser: (data) => {
    return axiosModules.post(`/paccount/user/sub`, data);
  },

  // paccount > 회원가입 > 서브유저 회원가입 > Step1 - 회원초대 인증키 유효성 확인
  userValidActivateKey: (inviteKEY) => {
    return axiosModules.get(`/paccount/user/invitecetificate/${inviteKEY}`);
  },

  // paccount > 회원가입 > 서브유저 회원가입 > Step2 - 회원초대 가입
  createSubUserGuest: (data) => {
    return axiosModules.post(`/paccount/user/register/subuser`, data);
  },

  // paccount > 회원가입 > 신규 회원가입 기능 > Step1 - 회원 신규가입
  signup: (data) => {
    return axiosModules.post(`/paccount/user/register`, data);
  },

  // paccount > 회원가입 > 신규 회원가입 기능 > Step3 - 신규 회원가입 인증
  userCertification: (authkey) => {
    return axiosModules.post(`/paccount/user/registercetificate/${authkey}`);
  },

  // paccount > 회원가입 > 비밀번호 찾기 > Step1 - 비밀번호 찾기
  findUserPassword: (email) => {
    return axiosModules.post(`/paccount/user/findpassword/${email}`);
  },

  // paccount > 회원가입 > 비밀번호 찾기 > Step2 - 임시 비밀번호 생성 인증
  createTemporaryPassword: (authkey) => {
    return axiosModules.post(`/paccount/user/temppassword/${authkey}`);
  },

  // paccount > 유저 > 내정보
  getUserInfo: () => {
    return axiosModules.get("/paccount/user/uuid");
  },

  // paccount > 유저 > 유저정보조회
  getMemberInfo: (uuid) => {
    return axiosModules.get(`/paccount/user/uuid/${uuid}`);
  },

  // paccount > 유저 > 멤버관리 회원 목록
  getUserList: (params) => {
    return axiosModules.get("/paccount/user", params);
  },

  // paccount > 유저 > 단순 회원 조회 목록
  getUserSimpleInfo: (data) => {
    return axiosModules.get("/paccount/user/simple", data);
  },

  // paccount > 유저 > 내정보 수정
  putUserInfo: (data) => {
    return axiosModules.put("/paccount/user/uuid", data);
  },

  // paccount > 유저 > 비밀번호 나중에 변경하기
  updateSkipPasswordChange: () => {
    return axiosModules.put("/paccount/user/passwordchangeskipdate", {});
  },

  // paccount > 유저 > 이메일 존재 여부 확인
  getUserEmailCount: (email) => {
    return axiosModules.get(`/paccount/useremail/${email}`);
  },

  // paccount > 유저 > 유저 삭제
  deleteUser: (uuid, data) => {
    return axiosModules.patch(`/paccount/user/sub/${uuid}`, data);
  },

  // paccount > 유저 그룹 > 유저그룹 정보
  getUserGroup: () => {
    return axiosModules.get("/paccount/usergroup");
  },

  // paccount > 유저 그룹 > 유저그룹 타임존 변경
  updateUserTimezone: (data) => {
    return axiosModules.patch("/paccount/usergroup/timezone", data);
  },

  // paccount > 유저 그룹 > 유저그룹 언어변경
  updateLang: (data) => {
    return axiosModules.patch("/paccount/usergroup/lang", data);
  },

  // paccount > 유저 그룹 > 유저그룹 국가변경
  updateNation: (data) => {
    return axiosModules.patch("/paccount/usergroup/lang", data);
  },

  // paccount > 유저 그룹 > 로그인허용IP주소 사용변경
  updateLoginAllowIp: (param) => {
    return axiosModules.put("/paccount/usergroup/isuseipblock", param);
  },

  // paccount > 유저 > 로그인 제한
  updateUserBlocked: (uuid, data) => {
    return axiosModules.patch(`/paccount/user/${uuid}/isblocked`, data);
  },
};
