import { observable } from "mobx";

import { SetterModel } from "@qubit-utill/src";

export default class GroupWafNoticeExceptIpModel extends SetterModel {
  @observable
  wafConfigExceptIpId: number = 0;

  @observable
  wafConfigId: number = 0;

  @observable
  exceptType: string = "";

  @observable
  ipAddress: string = "";

  @observable
  registerDate: string = "";

  @observable
  updateDate: string = "";

  @observable
  registerUuid: string = "";

  @observable
  updateUuid: string = "";
}
