import { observable, computed } from "mobx";
import { Common_Utils } from "@qubit-utill/src";
import { CSVINFO } from "@src/constants";
import { NetworkLogModel } from "@src/common/models";

export default class NetworkTotalLogModel extends NetworkLogModel {
  constructor(params) {
    super(params);
    if (params) {
      this.withMicroSecondsLogCreateDate = params.logCreateDate + params.logCreateMicroSeconds;
    }
  }

  @computed
  get totalLogIp() {
    return this.hostIp || this.serverIp;
  }

  @computed
  get csvData() {
    return Common_Utils.makeCsvRowData(this, CSVINFO.TOTALLOG_NET.infoKey);
  }

  @computed
  get getOsFamily() {
    return Common_Utils.isDataWithDashBar(this.osFamily);
  }

  @observable
  documentId = "";
  @observable
  serverGroup = "";
  @observable
  serverIp = "";
  @observable
  hostIp = "";
  @observable
  hostName = "";
  @observable
  logSeverity = "";
  @observable
  filterChannelName = "";
  @observable
  logCreateDate = "";
  @observable
  osFamily = "";
  @observable
  serverId = "";
  //로그 상세
  //공통
  @observable
  formatLogOrigin = "";

  @observable
  logHash = "";
  @observable
  logOrigin = "";

  @observable
  logCreateMicroSeconds = "";

  @observable
  withMicroSecondsLogCreateDate = "";
}
