import React, { Fragment } from "react"

const OsAndIp = ({ os, ip }) => {
  return (
    <Fragment>
      <i className={`icon_os_${os}_s`} /> {ip}
    </Fragment>
  )
}

export default OsAndIp
