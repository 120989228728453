import React from "react";
import Chart from "react-apexcharts";
import { observer } from "mobx-react";
import { toJS } from "mobx";

import { Common_Utils } from "@qubit-utill/src";

import NullLogData from "@src/common/components/organisms/Graphs/NullLogData";

interface propsType {
  data: Array<{ name: string; data: Array<number | null> }>;
  category: string[];
  onClick?: Function;
  height?: number;
}

const HeatMap: React.FC<propsType> = ({ data, category, onClick, height = 400 }) => {
  let _seriesIndex = 0;
  const series = data && data.length > 0 ? toJS(data) : [];

  const checkNullValue = (config) => {
    const dataPointIndex: number = config.dataPointIndex;
    const seriesIndex: number = config.seriesIndex;
    const value: number | null = series[seriesIndex].data[dataPointIndex];
    const isNull = (value && value < 0) || value === null;
    return { isNull, dataPointIndex, seriesIndex };
  };

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
      events: {
        dataPointSelection: function(event, chartContext, config) {
          const { isNull, dataPointIndex, seriesIndex } = checkNullValue(config);
          if (!isNull) {
            onClick && onClick(seriesIndex, dataPointIndex);
          }
        },
        dataPointMouseEnter: function(event, chartContext, config) {
          const { isNull } = checkNullValue(config);
          if (!isNull) {
            event.target.style.cursor = "pointer";
          }
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function(value) {
        return value < 0 || value === null ? "" : value;
      },
    },
    colors: ["#008FFB"],
    stroke: {
      show: true,
      width: 0.5,
      colors: ["#d2d2d2"],
    },
    plotOptions: {
      heatmap: {
        reverseNegativeShade: false,
        colorScale: {
          ranges: [
            {
              from: -2,
              to: -2,
              color: "#ffffff",
            },
            {
              from: -1,
              to: -1,
              color: "#f0f0f0",
            },
            {
              from: 0,
              to: 0,
              color: "#f0f0f0",
            },
          ],
        },
      },
    },
    xaxis: {
      type: "category" as const,
      categories: category,
      tooltip: {
        enabled: false,
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: true,
      y: {
        show: false,
        formatter: function(value, { seriesIndex }) {
          return (_seriesIndex = seriesIndex);
        },
      },
      custom: function({ series, dataPointIndex, w }) {
        let tooltipDataArr: Array<number> = [];
        const tooltipTitle = w.globals.labels[dataPointIndex];
        series.forEach((seriesItem, seriesEachIndex) => {
          const yValue = w.globals.yLabelFormatters[0](series[seriesEachIndex][dataPointIndex]);
          tooltipDataArr.push(yValue);
        });
        const tooltip = `<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">${tooltipTitle}</div>
        <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
          <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
            <div class="apexcharts-tooltip-y-group">
              <span class="apexcharts-tooltip-text-y-label">${w.globals.seriesNames[_seriesIndex]}: </span>
              <span class="apexcharts-tooltip-text-y-value">${tooltipDataArr[_seriesIndex]}</span>
            </div>
          </div>
        </div>`;
        const nullData = tooltipDataArr[_seriesIndex] === null || tooltipDataArr[_seriesIndex] < 0;
        return nullData ? null : tooltip;
      },
    },
  };

  const isNullData =
    !data ||
    data.length === 0 ||
    (data.length > 0 &&
      data.every(({ data }) => !data || data.every((item) => !Common_Utils.isNull(item) || (item && item < 0))));

  return isNullData ? (
    <NullLogData height={400} />
  ) : (
    <Chart options={options} series={series} type="heatmap" height={`${height}px`} />
  );
};

export default observer(HeatMap);
