/**
 * 필터관리 > 데이터 유출
 */

import { observable, action, computed } from "mobx";

import { ListCondition, withLoading, arrayForSelectBox, languageSet, isRunning } from "@qubit-utill/src";

import DataBreachFilterModel from "../models/DataBreach/DataBreachFilterModel";
import DataBreachElementModel from "../models/DataBreach/DataBreachElementModel";
import { webExtendsFiltersApis, filterCategoryApis } from "@src/apis/pFilter";
import { FilterCategoryModel } from "../../RegisterFilter/model/Filter";

class DataBreachFilterStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }
  root;

  @observable
  isRunning = false;

  @observable
  isSetLoading = true;

  @observable
  isCopy = false;

  @observable
  isCopyCheck = false;

  @observable
  tableList = [];

  @observable
  dataBreachFilterInfo: DataBreachFilterModel = new DataBreachFilterModel(null, this.root.CodeStore);

  @observable
  dataBreachElementList: DataBreachElementModel[] = [];

  @observable
  filterFieldList: { name: string; value: DataBreachElementModel }[] = [];

  @action
  setIsCopyCheck = (flag) => {
    this.isCopyCheck = flag;
  };

  //등록시
  @withLoading("isSetLoading")
  @action
  initDataBreachFilterInfo = async () => {
    await this.getCodes();
    if (this.isCopyCheck) {
      this.dataBreachFilterInfo.webExtendsFilterName = "";
      this.isCopyCheck = false;
      this.isCopy = true;
    } else {
      this.dataBreachFilterInfo = new DataBreachFilterModel(null, this.root.CodeStore);
      this.dataBreachElementList = [];
    }
  };

  @action
  clearInfo = () => {
    if (this.isCopy) {
      this.isCopy = false;
    }
    if (!this.isCopyCheck) {
      this.dataBreachFilterInfo = new DataBreachFilterModel(null, this.root.CodeStore);
      this.dataBreachElementList = [];
    }
  };

  @withLoading("isLoading")
  @action
  getList = async () => {
    try {
      const { list, totalCount } = await webExtendsFiltersApis.getWebExtendsFilterList(this.params);
      this.tableList = list.map((info) => new DataBreachFilterModel(info, this.root.CodeStore));
      this.totalCount = totalCount;
    } catch (e) {
      this.tableList = [];
      this.totalCount = 0;
    }
  };

  //수정시
  @action
  getInfo = async (webExtendsFilterId) => {
    try {
      const { info } = await webExtendsFiltersApis.getWebExtendsFilter(webExtendsFilterId);
      this.dataBreachFilterInfo = new DataBreachFilterModel(info, this.root.CodeStore);
      this.dataBreachElementList = info.webExtendsFilterElements.map(
        (info) => new DataBreachElementModel(info, this.root.CodeStore, this.filterFieldList)
      );
    } catch (error) {}
  };

  //수정시
  @withLoading("isSetLoading")
  @action
  getTotalInfo = async (filterId) => {
    await this.getCodes();
    this.getInfo(filterId);
  };

  @action
  getCodes = async () => {
    await Promise.all([
      this.getFilterCategoryList("webextendsdatabreach").catch(() => null),
      this.getFieldList().catch(() => null),
    ]);
  };

  @action
  getFieldList = async () => {
    try {
      const { list } = await webExtendsFiltersApis.getWebExtendsFilterFieldList();
      this.filterFieldList = list.map((info) => ({
        name: info.webExtendsFilterFieldDisplayName,
        value: new DataBreachElementModel(info, this.root.CodeStore),
      }));
    } catch (error) {
      console.log(error);
    }
  };

  @observable
  filterCategoryList: FilterCategoryModel[] = [];

  @observable
  filterCategoryListForSelect: { name: string; value: FilterCategoryModel }[] = [];

  @action
  getFilterCategoryList = async (filterType) => {
    const { list } = await filterCategoryApis.getFilterCategoryList(filterType);
    this.filterCategoryList = list.map((element) => new FilterCategoryModel(element));
    this.filterCategoryListForSelect = arrayForSelectBox.parseValueForSelectBoxDefault(this.filterCategoryList);
  };

  getFilterClassificationName = (filterCategoryId) => {
    if (this.filterCategoryListForSelect.length > 0) {
      const classification = this.filterCategoryListForSelect.find(({ value }) => value === filterCategoryId);
      return classification ? classification.name : "";
    }
    return "";
  };

  @action
  addNewDataType = () => {
    this.dataBreachElementList.push(new DataBreachElementModel(null, this.root.CodeStore));
  };

  @action
  deleteDataElement = (index) => {
    this.dataBreachElementList.splice(index, 1);
  };

  elementValidation = () => {
    if (this.dataBreachElementList.length === 0) {
      throw languageSet("에러_정보를입력해라");
    }
    this.dataBreachElementList.forEach((info) => {
      if (info.webExtendsFilterFieldName === "") {
        throw languageSet("메세지_요소선택");
      }
      info.webExtendsFilterElementSelectors.forEach((info) => {
        info.validation();
      });
    });
  };

  @action
  setIsUse = async (info) => {
    try {
      const { isUseFlag, webExtendsFilterId } = info;
      const willIsUse = !isUseFlag ? "1" : "0";
      const { rowCount } = await webExtendsFiltersApis.updateIsUseWebExtends(webExtendsFilterId, willIsUse, {
        webExtendsFilterType: this.dataBreachFilterInfo.webExtendsFilterType,
      });
      if (rowCount >= 1) info.isUse = willIsUse;
    } catch ({ data }) {
      return data;
    }
  };

  //=============API 호출부
  @computed
  get apiData() {
    let obj: any = this.dataBreachFilterInfo.apiData;
    obj.webExtendsFilterElements = this.dataBreachElementList.map(({ apiData }) => apiData);
    return obj;
  }

  @isRunning("isRunning")
  @action
  createFilter = async () => {
    try {
      await webExtendsFiltersApis.createWebExtendsFilter(this.apiData);
    } catch ({ data }) {
      console.log(data);
      return data;
    }
  };

  @isRunning("isRunning")
  @action
  updateFilter = async () => {
    try {
      await webExtendsFiltersApis.updateWebExtendsFilter(this.apiData);
    } catch ({ data }) {
      return data;
    }
  };

  @action
  deleteFilter = async () => {
    try {
      await webExtendsFiltersApis.deleteWebExtendsFilter(this.dataBreachFilterInfo.webExtendsFilterId, {
        webExtendsFilterType: this.dataBreachFilterInfo.webExtendsFilterType,
      });
    } catch ({ data }) {
      return data;
    }
  };
}

export default DataBreachFilterStore;
