import { axiosModules } from "@qubit-utill/src";

export default {
  // plogdetect > 응용프로그램 탐지 로그 > 탐지 응용프로그램 로그 조회
  getAppLogDetect: (documentId) => {
    return axiosModules.get(`/plogdetect/applogdetect/${documentId}`);
  },

  // plogdetect > 응용프로그램 탐지 로그 > 탐지 응용프로그램 로그 리스트 조회
  getAppLogDetectList: (params) => {
    return axiosModules.get("/plogdetect/applogdetect/list", params);
  },

  // plogdetect > 응용프로그램 탐지 로그 > 탐지 응용프로그램 로그 리스트 CSV 다운로드
  getAppLogDetectCsvList: (params) => {
    return axiosModules.getCsv("/plogdetect/applogdetect/list/csv", params);
  },

  // plogdetect > 사용자 응용프로그램 탐지 로그 > 탐지 사용자 응용프로그램 로그 조회
  getResourceLogDetect: (documentId) => {
    return axiosModules.get(`/plogdetect/resourcelogdetect/${documentId}`);
  },

  // plogdetect > 사용자 응용프로그램 탐지 로그 > 탐지 사용자 응용프로그램 로그 리스트 조회
  getResourceLogDetectList: (params) => {
    return axiosModules.get("/plogdetect/resourcelogdetect/list", params);
  },

  // plogdetect > 사용자 응용프로그램 탐지 로그 > 탐지 사용자 응용프로그램 로그 리스트 조회
  getResourceLogDetectCsvList: (params) => {
    return axiosModules.getCsv("/plogdetect/resourcelogdetect/list/csv", params);
  },
};
