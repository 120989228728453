import { languageSet } from "@qubit-utill/src";
import getURI from "@src/constants/uriset";

import { ServerManagementContainer, ServerGroupManagementContainer, ServerGroupSetContainer } from "@src/pages";

export default {
  //서버
  SERVER_MANAGEMENT: {
    title: languageSet("시스템관리"),
    text: languageSet("시스템관리"),
    link: getURI("SERVER_MANAGEMENT"),
    withPageLink: `${getURI("SERVER_MANAGEMENT")}/page/:page`,
    component: ServerManagementContainer,
  },
  SERVERGROUP_MANAGEMENT: {
    title: languageSet("그룹관리"),
    text: languageSet("그룹관리"),
    link: getURI("SERVERGROUP_MANAGEMENT"),
    withPageLink: `${getURI("SERVERGROUP_MANAGEMENT")}/page/:page`,
    component: ServerGroupManagementContainer,
  },
  SERVERGROUP_REGIST: {
    link: getURI("SERVERGROUP_REGIST"),
    title: languageSet("그룹_등록"),
    isEdit: false,
    component: ServerGroupSetContainer,
  },
  SERVERGROUP_EDIT: {
    link: `${getURI("SERVERGROUP_EDIT")}/:serverGroupId`,
    title: languageSet("그룹_수정"),
    isEdit: true,
    component: ServerGroupSetContainer,
  },
};
