import React from "react";
import { path } from "@qubit-utill/src/common_constants";
import { languageSet } from "@qubit-utill/src";

const OsImage = ({ os, count }) => {
  return (
    <>
      <img src={`${path.CDNURL}/icon_${os}.png`} alt={os} />
      {count && <span className="p-l-5 p-r-5">{languageSet("n개", count)}</span>}
    </>
  );
};

export default OsImage;
