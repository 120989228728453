import React, { useEffect } from "react";
import { Table } from "reactstrap";
import { Observer, observer, useLocalStore } from "mobx-react";

import {
  arrayUtils,
  CustomModal,
  Input,
  languageSet,
  PartialLoadingWrapper,
  RadioButton,
  useInjectStore,
  SwitchBox,
  Common_Utils,
} from "@qubit-utill/src";

import { IpInputForm } from "../../../components/organisms/Input";
import { IpForm } from "../../../components/organisms/Input/IpInputForm";

interface PropsTypes {
  closeModal: any;
  tagReferenceTypeList: any;
  tagValueTypeList: any;
  tagServiceDataList: any;
  updateTagServiceDatas: any;
  isUpdate: boolean;
  params: any;
  typeInfo: any;
}

const IpTagModal: React.FC<PropsTypes> = ({
  closeModal,
  tagReferenceTypeList,
  tagValueTypeList,
  tagServiceDataList,
  updateTagServiceDatas,
  isUpdate,
  params,
  typeInfo,
}) => {
  const { ModalStore } = useInjectStore();
  const { openAlertModal } = ModalStore;

  const ipForm = useLocalStore(() => new IpForm());
  const state = useLocalStore(() => ({
    isLoading: true,

    generalInfo: tagServiceDataList.find((info: any) => info.tagReferenceType === "general"),
    dbInfo: tagServiceDataList.find((info: any) => info.tagReferenceType === "db"),
    macInfo: tagServiceDataList.find((info: any) => info.tagReferenceType === "mac"),
    selectedTab: "",
    selectedTagValueType: "ipv4",

    generalTagReferenceType: "general", //일반
    generalTagValueType: "ipv4", //ipv4, ipv6
    generalTagValue: "", //주소
    generalTagContent: "", //태그
    generalTagServiceDataId: 0,
    generalTagServiceId: "ip",
    generalTagIsUse: "0",

    dbTagReferenceType: "db", //평판DB
    dbTagValueType: "ipv4", //ipv4
    dbTagValue: "", //주소
    dbTagContent: "", //태그
    dbTagServiceDataId: 0,
    dbTagServiceId: "ip",
    dbTagIsUse: "0",

    macTagReferenceType: "general",
    macTagValueType: "mac", //ipv4, ipv6
    macTagValue: "mac", //주소
    macTagContent: "", //태그
    macTagServiceDataId: 0,
    macTagServiceId: "mac",
    macTagIsUse: "0",

    tagValue: "",

    tagServiceId: "ip",

    isIp: true,

    setInfo(key: string, value: any) {
      this[key] = value;
    },

    isErr: false,
    errMsg: "",

    _toggleIsFlag(key: string) {
      this[key] = this[key] === "1" ? "0" : "1";
    },
  }));

  const isSelected = React.useCallback(
    tabName => (state.selectedTab === tabName ? "active bold" : ""),
    []
  );

  const selectTab = (value: string) => {
    state.setInfo("selectedTab", value);
  };

  const _renderTabs = () => {
    //평판DB는 제외한다.
    const _tagReferenceTypeList = arrayUtils.sortByKey(
      tagReferenceTypeList.filter((info: any) => info.value !== "db"),
      "tagReferenceTypeOrderNo"
    );

    const _tagValueTypeList = arrayUtils.sortByKey(
      tagValueTypeList.filter((info: any) => state.tagServiceId === info.tagServiceId),
      "tagValueTypeOrderNo"
    );

    return _tagReferenceTypeList.map((info: any, index: number) => {
      return (
        <li className={isSelected(info.value)} key={index}>
          <a onClick={() => selectTab(info.value)}>
            <span className="dis-inline-b p-r-5 h-25">{info.name}</span>

            {info.value === "general" && (
              <span className="dis-inline-b h-25">
                <span className="p-lr-3">(</span>
                {_tagValueTypeList.map((info: any, index: number) => {
                  const _isChecked = state.generalTagValueType === info.value;
                  return (
                    <span className="dis-inline-b" key={index}>
                      <RadioButton
                        isChecked={_isChecked}
                        onSelect={() => state.setInfo("generalTagValueType", info.value)}
                        disabled
                      />
                      <span className={index === 0 ? "p-r-10" : ""}>{info.name}</span>
                    </span>
                  );
                })}
                <span className="p-lr-3">)</span>
              </span>
            )}
          </a>
        </li>
      );
    });
  };

  const selectTagValue = tagValueTypeList.find(
    (info: any) => info.value === state.generalTagValueType || info.isDefault
  );

  const _renderAddressInput = () => {
    return state.isIp && state.selectedTab === "general" && selectTagValue.value === "ipv4" ? (
      <IpInputForm
        ipForm={ipForm}
        info={state.generalTagValue}
        color="grey"
        useOptionInputForm={false}
        inputClass="w_70px"
        disabled
      />
    ) : (
      <div className="dis-inline-b p-tb-3">
        <Input.Text
          value={state[`${state.selectedTab}TagValue`]}
          onChange={(value: any) => state.setInfo(`${state.selectedTab}TagValue`, value)}
          color="grey"
          className="w_302px"
          disabled
        />
      </div>
    );
  };

  const initSetInfo = async () => {
    //1.태그 타입 결정 : IP or MAC
    if (typeInfo) {
      await state.setInfo("isIp", typeInfo.tagServiceId === "ip");

      //2.태그 타입에 따라 상태값 셋팅
      if (state.isIp) {
        await state.setInfo(
          "generalInfo",
          tagServiceDataList.find((info: any) => info.tagReferenceType === "general")
        );
        await state.setInfo(
          "dbInfo",
          tagServiceDataList.find((info: any) => info.tagReferenceType === "db")
        );
        state.setInfo("selectedTab", "general");

        //일반
        state.setInfo(
          "generalTagReferenceType",
          state.generalInfo ? state.generalInfo.tagReferenceType : "general"
        );
        state.setInfo(
          "generalTagValueType",
          state.generalInfo ? state.generalInfo.tagValueType : typeInfo.value
        );
        state.setInfo(
          "generalTagValue",
          state.generalInfo ? state.generalInfo.tagValue : params.tagValue
        );
        state.setInfo("generalTagContent", state.generalInfo ? state.generalInfo.tagContent : "");
        state.setInfo(
          "generalTagServiceDataId",
          state.generalInfo ? state.generalInfo.tagServiceDataId : 0
        );
        state.setInfo("generalTagIsUse", state.generalInfo ? state.generalInfo.isUse : "0");

        //평판DB
        state.setInfo("dbTagReferenceType", state.dbInfo ? state.dbInfo.tagReferenceType : "db");
        // state.setInfo("dbTagValueType", state.dbInfo ? state.dbInfo.tagValueType : "");
        state.setInfo("dbTagValue", state.dbInfo ? state.dbInfo.tagValue : params.tagValue);
        state.setInfo("dbTagContent", state.dbInfo ? state.dbInfo.tagContent : "");
        state.setInfo("dbTagServiceDataId", state.dbInfo ? state.dbInfo.tagServiceDataId : 0);
        state.setInfo("dbTagIsUse", state.dbInfo ? state.dbInfo.isUse : "0");
      } else {
        //MAC
        await state.setInfo(
          "macInfo",
          tagServiceDataList.find((info: any) => info.tagReferenceType === "mac")
        );

        state.setInfo(
          "macTagReferenceType",
          state.macInfo ? state.macInfo.tagReferenceType : "general"
        );
        // state.setInfo("macTagValueType", state.macInfo ? state.macInfo.tagValueType : "");
        state.setInfo("macTagValue", state.macInfo ? state.macInfo.tagValue : params.tagValue);
        state.setInfo("macTagContent", state.macInfo ? state.macInfo.tagContent : "");
        state.setInfo("macTagServiceDataId", state.macInfo ? state.macInfo.tagServiceDataId : 0);
        state.setInfo("macTagIsUse", state.macInfo ? state.macInfo.isUse : "0");
      }
    }
  };

  useEffect(() => {
    initSetInfo();
  }, []);

  const generalInfoParams = [
    {
      tagServiceDataId: state.generalTagServiceDataId,
      tagServiceId: state.generalTagServiceId,
      tagReferenceType: state.generalTagReferenceType,
      tagValueType: state.generalTagValueType,
      tagValue: state.generalTagValue,
      tagContent: state.generalTagContent,
      isUse: state.generalTagIsUse,
    },
    // {
    //   tagServiceDataId: state.dbTagServiceDataId,
    //   tagServiceId: state.dbTagServiceId,
    //   tagReferenceType: state.dbTagReferenceType,
    //   tagValueType: state.dbTagValueType,
    //   tagValue: state.dbTagValue,
    //   tagContent: state.dbTagContent,
    //   isUse: state.dbTagIsUse,
    // },
  ];

  const macInfoParams = [
    {
      tagServiceDataId: state.macTagServiceDataId,
      tagServiceId: state.macTagServiceId,
      tagReferenceType: state.macTagReferenceType,
      tagValueType: state.macTagValueType,
      tagValue: state.macTagValue,
      tagContent: state.macTagContent,
      isUse: state.macTagIsUse,
    },
  ];

  const _updateTagServiceDatas = async () => {
    if (state.generalTagContent) {
      const err = await updateTagServiceDatas(state.isIp ? generalInfoParams : macInfoParams);
      if (err) {
        state.setInfo("isErr", true);
        state.setInfo("errMsg", err.returnCodeMessage);
      } else {
        closeModal();
        openAlertModal({
          message: languageSet(isUpdate ? "메세지_수정완료" : "메세지_등록완료"),
        });
      }
    } else {
      openAlertModal({
        message: languageSet("에러_정보를입력해라"),
      });
    }
  };

  const title = `${languageSet(
    state.isIp ? "IP주소_사용자태그" : "MAC주소_사용자태그"
  )}(${languageSet(isUpdate ? "수정" : "등록")})`;

  return (
    <CustomModal
      closeBlock
      closeModal={closeModal}
      customAttribute="modal-dialog"
      title={title}
      isUseOkBtn
      isUseCancelBtn
      clickOk={typeInfo ? _updateTagServiceDatas : closeModal}
    >
      <PartialLoadingWrapper isLoading={state.isLoading}>
        {typeInfo ? (
          <div className="p-l-10 p-r-10">
            <div className="nav-tabs2">
              {state.isIp && (
                <ul className="nav nav-tabs nav-red">
                  <Observer render={_renderTabs} />
                </ul>
              )}
              <div className="tab-content">
                <div className="tab-pane active" id="tab5_1">
                  <div className="row">
                    <div className="col-md-12">
                      <Table className="table-bordered f-12 m-b-0">
                        <tbody>
                          <tr>
                            <th className="bg-gray-light p-10 w_100px">
                              <div>{languageSet("주소")}</div>
                            </th>
                            <td className="bg-white">
                              <Observer render={_renderAddressInput} />
                              {state.isErr && (
                                <span className="dis-block c-orange p-t-5">* {state.errMsg}</span>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th className="bg-gray-light p-10">
                              <div>{languageSet("태그")}</div>
                            </th>
                            <td className="bg-white">
                              {state.selectedTab === "general" ? (
                                <Input.TextArea
                                  row={3}
                                  maxLength={100}
                                  value={state.generalTagContent}
                                  onChange={(value: any) =>
                                    state.setInfo("generalTagContent", value)
                                  }
                                />
                              ) : (
                                <Input.TextArea
                                  row={3}
                                  maxLength={100}
                                  value={state.dbTagContent}
                                  onChange={(value: any) => state.setInfo("dbTagContent", value)}
                                />
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th className="bg-gray-light p-10">
                              <div>{languageSet("동작")}</div>
                            </th>
                            <td className="bg-white">
                              {state.isIp ? (
                                <SwitchBox
                                  isChecked={Common_Utils.isCheckBool(state.generalTagIsUse)}
                                  onToggle={() => state._toggleIsFlag("generalTagIsUse")}
                                />
                              ) : (
                                <SwitchBox
                                  isChecked={Common_Utils.isCheckBool(state.macTagIsUse)}
                                  onToggle={() => state._toggleIsFlag("macTagIsUse")}
                                />
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="m-l-20 m-r-20 border-top border-bottom p-5 bg-gray-light p-15">
            <div className="p-tb-3 t-center">
              <span className="dis-inline-b c-dark bold f-16">
                {languageSet("유효하지 않은 정보입니다.")}
              </span>
            </div>
          </div>
        )}
      </PartialLoadingWrapper>
    </CustomModal>
  );
};

export default observer(IpTagModal);
