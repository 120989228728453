import { observable, computed } from "mobx";

import { SetterModel, Common_Utils, languageSet } from "@qubit-utill/src";

export default class IpMacAddressModel extends SetterModel {
  constructor(params?, tagReferenceTypeList?) {
    super(params);
    if (tagReferenceTypeList) {
      this.tagReferenceTypeList = tagReferenceTypeList;
    }
  }

  @observable
  tagReferenceTypeList: Array<{ name: string; value: string }> = [];

  @computed
  get isGlobalFlag() {
    return Common_Utils.isCheckBool(this.isGlobal);
  }

  @computed
  get isUseFlag() {
    return Common_Utils.isCheckBool(this.isUse);
  }

  @computed
  get isManagerFlag() {
    return Common_Utils.isCheckBool(this.isManager);
  }

  @computed
  get getTagReferenceType() {
    const referenceInfo = this.tagReferenceTypeList.find((info) => info.value === this.tagReferenceType);
    return referenceInfo && referenceInfo.name;
  }

  @computed
  get getTagReference() {
    return languageSet(this.isGlobal === "0" ? "사용자" : "기본");
  }

  @observable
  tagServiceDataId: number = 0;

  @observable
  isGlobal: string = "0";

  @observable
  guid: string = "";

  @observable
  tagValue: string = "";

  @observable
  tagValueType: string = "";

  @observable
  tagReferenceType: string = "";

  @observable
  tagContent: string = "";

  @observable
  registerUuid: string = "";

  @observable
  updateUuid: string = "";

  @observable
  isUse: string = "0";

  @observable
  tagServiceId: string = "";

  @observable
  isManager: string = "0";

  @observable
  isSelected: boolean = false;
}
