import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Observer } from "mobx-react"

const ServerOsCount = ({ serverGroupOsCount, totalCount }) => {
  const _renderOsServerList = () =>
    serverGroupOsCount.map(({ typeos, count }) => (
      <span className="label label-default m-l-5 f-12" key={typeos}>
        <span className={`icon_os_${typeos}_s`} />
        <span>{count}</span>
      </span>
    ))

  return (
    <Fragment>
      <a>{totalCount}</a>
      {serverGroupOsCount && <Observer render={_renderOsServerList} />}
    </Fragment>
  )
}

ServerOsCount.propTypes = {
  serverTotalCount: PropTypes.number,
  typeosCount: PropTypes.array
}

export default ServerOsCount
