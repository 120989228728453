import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { observable } from "mobx";
import { observer, PropTypes as MobxPropTypes, Observer } from "mobx-react";
import { languageSet } from "@qubit-utill/src";
import { Input } from "@qubit-utill/src/components/atoms";

@observer
class SelectEditType extends Component {
  @observable
  isOpen = false;

  @observable
  isSelected = false;

  @observable
  selectedValue = {};

  @observable
  selectedTypeOs = "";

  toggleIsOpen = e => {
    e.stopPropagation();
    if (!this.props.disabled) {
      this.isOpen = true;
    }
  };

  toggleIsClose = e => {
    e && e.stopPropagation();
    if (!this.props.disabled) {
      this.isOpen = false;
    }
  };

  selectOption = (data, e) => {
    const { onSelect } = this.props;
    this.isOpen = false;
    this.isSelected = true;
    this.selectedValue = data.name;
    this.selectedTypeOs = data.typeos && data.typeos;
    onSelect && onSelect(data);
    this.toggleIsClose(e);
  };

  _renderOptions = () => {
    const {
      options,
      selectedValue,
      totalTitle,
      isUseTotal,
      useOsIcon,
      style,
      selectedValueClassName,
    } = this.props;

    let list = [];
    list =
      options &&
      Boolean(options.length) &&
      options.map(data => (
        <li
          key={data.uiId || data.name}
          className={`select2-results-dept-0 select2-result select2-result-selectable f-12 ${
            data.name === selectedValue ? "select2-highlighted" : ""
          }`}
        >
          <div
            className={`select2-result-label ${selectedValueClassName}`}
            style={style}
            onClick={e => this.selectOption(data, e)}
          >
            {useOsIcon && (
              <i className={`icon_os_${data.typeos || data.value.code || data.value}_s`} />
            )}
            <span>{data.name}</span>
          </div>
        </li>
      ));
    if (isUseTotal) {
      let title = totalTitle || languageSet("전체");
      list.unshift(
        <li
          className={`select2-results-dept-0 select2-result select2-result-selectable f-12 ${
            title === this.selectedValue || selectedValue === "" ? "select2-highlighted" : ""
          }`}
        >
          <div
            className="select2-result-label"
            onClick={e => this.selectOption({ value: "", name: title }, e)}
          >
            <span>{title}</span>
          </div>
        </li>
      );
    }

    return list;
  };
  render() {
    const {
      title,
      totalTitle,
      isUseTotal,
      color,
      selectedValue,
      disabled,
      className,
      optionsColor,
      searchInput,
      onChange,
      searchValue,
      style,
      useOsIcon,
      typeos,
      isInitTitle,
      onFocus,
      searchPlaceholder,
      isUseTitleSelect,
    } = this.props;

    //선택 됐거나 props로 받았을때 선택하는 값 노출 그 그 이외에는 title 노출

    let _title = isUseTotal ? totalTitle : title;

    const render_selectedValue =
      selectedValue !== "" && selectedValue !== undefined && selectedValue !== null
        ? selectedValue
        : _title !== ""
        ? _title
        : this.selectedValue;

    return (
      <div
        className={`select2-container form-${color} form-control p-l-0 p-r-0 f-12 ${
          disabled ? "cursor_d" : "cursor_p"
        } ${className}  ${this.isOpen && "select2-dropdown-open select2-container-active"}`}
        onClick={this.toggleIsOpen}
      >
        <a className="select2-choice" tabIndex="-1">
          <span className="select2-chosen" style={style}>
            {useOsIcon && <i className={`icon_os_${this.selectedTypeOs || typeos}_s`}></i>}
            {render_selectedValue}
          </span>
          {!disabled && (
            <Fragment>
              <abbr className="select2-search-choice-close" />
              <span className="select2-arrow">
                <b role="presentation" />
              </span>
            </Fragment>
          )}
        </a>
        {this.isOpen && !disabled && (
          <Fragment>
            <div
              className="select2-drop-mask"
              style={{ display: "block" }}
              onClick={this.toggleIsClose}
            />
            <div className={`select2-drop select2-drop-active ${optionsColor}`}>
              {searchInput && (
                <a className="p-tb-3">
                  <Input.Text
                    placeholder={searchPlaceholder}
                    value={searchValue}
                    onChange={onChange}
                    className="form-white"
                    onFocus={onFocus}
                  />
                </a>
              )}

              <ul className="select2-results">
                {title && !searchInput && (
                  <li
                    className={`select2-results-dept-0 select2-result select2-result-selectable f-12 ${
                      isInitTitle || !selectedValue ? "select2-highlighted" : ""
                    }`}
                  >
                    <div
                      className="select2-result-label"
                      onClick={e =>
                        isUseTitleSelect ? this.selectOption({ value: "", name: "" }, e) : {}
                      }
                    >
                      {title}
                    </div>
                  </li>
                )}
                <Observer render={this._renderOptions} />
              </ul>
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

SelectEditType.propTypes = {
  options: PropTypes.oneOfType([PropTypes.array, MobxPropTypes.observableArray]),
  color: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  selectedValue: PropTypes.string,
  onSelect: PropTypes.func,
  searchInput: PropTypes.bool,
  useOsIcon: PropTypes.bool,
  isInitTitle: PropTypes.bool,
  onFocus: PropTypes.func,
  useTitleClick: PropTypes.bool,
};

SelectEditType.defaultProps = {
  options: [],
  color: "",
  disabled: false,
  searchInput: false,
  useOsIcon: false,
  isInitTitle: false,
  searchPlaceholder: "ID",
  useTitleClick: false,
};

export default SelectEditType;
