import { observable, computed, set } from "mobx";

import { Common_Utils } from "@qubit-utill/src";

import { ApplicationLogModel } from "@src/common/models";

export default class HostTotalResourceMonitoringModel extends ApplicationLogModel {
  constructor(params) {
    super(params);
    if (params) {
      if (params.resourceMetaList) {
        this.resourceArray = params.resourceMetaList.map((data) => {
          const logMetaInfo = params.logMetaList.find((el) => el.resourceMetaId === data.resourceMetaId);
          set(this, {
            [data.metaName]: Boolean(logMetaInfo) ? logMetaInfo.logMetaValue : "-",
          });

          return {
            [data.metaName]: Boolean(logMetaInfo) ? logMetaInfo.logMetaValue : "-",
            metaName: data.metaName,
            metaUnitValue: Boolean(logMetaInfo) ? logMetaInfo.resourceMetaUnitValue : "", //데이터 유형
            metaRiskTag: Boolean(logMetaInfo) ? logMetaInfo.metaRiskTag : "", //위험도 태그(dataUnit이 gauge가 아니면 값 없음)
          };
        });
      }
    }
  }

  @computed
  get totalLogIp() {
    return this.hostIp || this.serverIp;
  }

  @computed
  get getServerGroup() {
    return Common_Utils.isDataWithDashBar(this.serverGroup);
  }

  @observable
  resourceArray = [];

  @observable
  documentId: string = "";

  @observable
  serverGroup: string = "";

  @observable
  serverIp: string = "";

  @observable
  hostIp: string = "";

  @observable
  hostName: string = "";

  @observable
  logCreateDate: string = "";

  @observable
  logOrigin: string = "";

  @observable
  logCreateMicroSeconds: string = "";

  @observable
  serverName: string = "";

  @observable
  agentType: string = "";

  @observable
  agentVersion: null | string = null;

  @observable
  ipKey: string = "";

  @observable
  isLogOriginForgery: string = "0";

  @observable
  logCreateNanoTime: string = "";

  @observable
  logExpireDate: string = "";

  @observable
  logHash: string = "";

  @observable
  logMetaList = [];

  @observable
  logOriginBytes: number = 0;

  @observable
  logOriginDocument: null | string = null;

  @observable
  logOriginJson: null | string = null;

  @observable
  logRegisterDate: string = "";

  @observable
  logTtl: string = "";

  @observable
  osFamily: string = "";

  @observable
  osReleaseType: string = "";

  @observable
  resourceId: string = "";

  @observable
  resourceServiceCode: string = "";

  @observable
  serverGroupId: null | number = null;

  @observable
  serverId: string = "";

  @observable
  shardId: string = "";
}
