import React from "react";
import { toJS } from "mobx";
import { Observer, observer } from "mobx-react";

import { useInjectStore } from "@qubit-utill/src";

import { StackBar, Bar, Line } from "@src/common/components/organisms/Graphs/ApexCharts";

import { GraphButtons } from "@src/pages/Report/templates/menus";
import { IPReportMapGraph } from "@src/pages/Report/templates/ReportContent";

interface PropsTypes {
  customOption?: React.ElementType;
  isUseGraphButton?: boolean;
  iPReportMapGraphFileName: string;
  isIP?: boolean;
  heightClass?: string;
  graphComponent?: React.ElementType;
  graphComponentProps?: Function;
  valueFormater?: Function;
}

const MainChart: React.FC<PropsTypes> = ({
  customOption: CustomOption,
  isUseGraphButton,
  iPReportMapGraphFileName,
  isIP,
  graphComponent: GraphComponent,
  valueFormater,
  graphComponentProps,
}) => {
  const { ReportStore } = useInjectStore();
  const {
    mainChartData,
    termsTab,
    getSubChartData,
    minDate,
    graphTitle,
    errorMessage,
    mainGraphTitle,
    getGraphFileName,
    dateInfo,
    scale,
    params,
    setListParams,
    selectedIndex,
    csvInfoKey,
  } = ReportStore;
  const _renderTermsGraph = () => {
    const obj = {
      id: csvInfoKey,
      title: mainGraphTitle,
      data: mainChartData.chartData,
      category: mainChartData.category,
      onClick: getSubChartData,
      showInLegend: true,
      selectedIndex: new Date(dateInfo.detectDate).getDay(),
      fileName: getGraphFileName(mainGraphTitle),
      valueFormater,
      useOnClick: csvInfoKey !== "REPORT_APP_RESOURCE", //현재(응용프로그램2차)는 응용프로그램 분석 > 사용자정의 : 주/월별 그래프의 getUploadSubChart 를 막기위함
    };

    const lineGraphOption = {
      stroke: {
        width: 2.5,
        curve: "smooth",
      },
      tooltip: {
        enabled: false,
      },
      dataLabels: {
        offsetY: -5,
      },
    };

    const isLineGraphData = obj?.data && obj?.data[0]?.type === "line";
    const customSeries = isLineGraphData && obj.data && obj.data.map(({ name, data }) => ({ name, data: toJS(data) }));

    if (isIP) {
      return (
        <IPReportMapGraph
          chartData={mainChartData.chartData}
          chartList={mainChartData.chartList}
          fileName={iPReportMapGraphFileName}
        />
      );
    } else {
      switch (termsTab) {
        case "day":
          return <StackBar {...obj} />;
        case "week":
          return <Bar {...obj} />;
        default:
          return <Line {...obj} graphOption={lineGraphOption} isUseCustomSeries={true} customSeries={customSeries} />;
      }
    }
  };

  const _renderGraph = () => {
    if (GraphComponent) {
      return (
        <GraphComponent
          title={graphTitle}
          termsTab={termsTab}
          errorMessage={errorMessage}
          minDate={minDate}
          data={mainChartData.chartData}
          {...toJS(mainChartData)}
          onClick={getSubChartData}
          scale={scale}
          fileName={getGraphFileName(graphTitle)}
          valueFormater={valueFormater}
          selectedIndex={selectedIndex}
          {...toJS(graphComponentProps && graphComponentProps(mainChartData))}
        />
      );
    } else {
      return (
        <div className="h-260">
          <Observer render={_renderTermsGraph} />
        </div>
      );
    }
  };

  return (
    <>
      {CustomOption && <CustomOption />}
      {isUseGraphButton && <GraphButtons selectedButton={params.actionDetectStatsType} setListParams={setListParams} />}
      <Observer render={_renderGraph} />
    </>
  );
};

export default observer(MainChart);
