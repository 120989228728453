import { observable, computed } from "mobx";

import { Common_Utils, logUtils } from "@qubit-utill/src";

import { WebLogModel } from "@src/common/models";
import { CSVINFO } from "@src/constants";

export default class WebTotalLogModel extends WebLogModel {
  constructor({ info, CodeStore }) {
    super(info);
    this.CodeStore = CodeStore;
    if (info) {
      if (info.isWaf) {
        this.isWaf = info.isWaf;
      }

      if (info.logBlocked) {
        const [logBlockedTextToRender, logBlockedTextForCSV] = logUtils.setLogBlockedInfo(
          info.logBlocked,
          info.webExtendsFilterTypes,
          this.CodeStore
        );
        this.logBlockedTextToRender = logBlockedTextToRender;
        this.logBlockedTextForCSV = logBlockedTextForCSV;
      }
    }
  }

  CodeStore;
  @computed
  get totalLogIp() {
    return this.hostIp || this.serverIp;
  }

  @computed
  get csvData() {
    const csvInfo = this.isWaf ? CSVINFO.TOTALLOG_WEBFIREWALL : CSVINFO.TOTALLOG_WEB;
    return Common_Utils.makeCsvRowData(this, csvInfo.infoKey);
  }

  @observable
  logBlockedText: Array<string> = [];

  @observable
  logBlockedTextToRender: any = [];

  @observable
  logBlockedTextForCSV: any = "";

  @computed
  get isDefenceFlag() {
    return Common_Utils.isCheckBool(this.isDefence);
  }

  @computed
  get attackIp() {
    return this.logClientIp;
  }

  @computed
  get filterIds() {
    return this.filters ? this.filters.map(({ filterId }) => filterId) : [this.filterId];
  }

  @computed
  get detectDefenseIp() {
    return this.logClientIp;
  }

  @computed
  get isMime() {
    return this.mime || "-";
  }

  @computed
  get getServerGroup() {
    return Common_Utils.isDataWithDashBar(this.serverGroup);
  }

  @computed
  get isSquid() {
    return !this.isWaf;
  }

  @observable
  filters = [];

  @observable
  filterId: string = "";

  @observable
  documentId = "";
  @observable
  serverGroup = "";
  @observable
  typeos = "";
  @observable
  serverIp = "";
  @observable
  isPrivateIp = "";
  @observable
  logClientIp = "";
  @observable
  logStatus = "";
  @observable
  logUri = "";
  @observable
  logCreateDate = "";
  @observable
  serverId = "";
  //로그 상세
  //공통
  @observable
  formatLogOrigin = "";

  @observable
  logRequestDate = "";
  @observable
  logServer = "";
  @observable
  logMethod = "";
  @observable
  logRequestBody = "";
  @observable
  logRequest = "";
  @observable
  logResponseBody = "";
  @observable
  logReferer = "";
  @observable
  logUserAgent = "";

  @observable
  logBlocked = "0";

  @observable
  detectDefenseId = 0;

  @observable
  isDefence = "";

  @observable
  isActive = "0";

  @observable
  defenseDescription = "";

  defenseConfigFilterType = "webfilter";
  logCookie = "";

  @observable
  isLog = true;

  @observable
  webExtendsFilterTypes = [];

  @observable
  mime = "";

  @observable
  isWaf = false;

  @observable
  logBlockedTypeName = "";

  @observable
  logProxyStatus = "";

  @observable
  logProxyStatusDescription = "";

  @observable
  agentType = "";
}
