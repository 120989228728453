import { observable } from "mobx";
import { SetterModel } from "@qubit-utill/src";

class LoginHistoryModel extends SetterModel {
  @observable
  countryIsoCode = "";
  @observable
  ipAddress = "";
  @observable
  registerDate = "";
  @observable
  userName = "";
}

export default LoginHistoryModel;
