import { axiosModules } from "@qubit-utill/src";
import { COOKIELANG } from "../../constants";

export default {
  // 검색메뉴
  getSearchMenuList: (searchMenuKey, params) =>
    axiosModules.get(`/psearch/searchmenu/${searchMenuKey}`, {
      lang: COOKIELANG,
      ...params,
    }),
  getSearchMenuListFixReport: () =>
    axiosModules.get(`/psearch/searchmenu/unitservergroup`, {
      lang: COOKIELANG,
    }),
};
