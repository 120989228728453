import { observable, action, computed } from "mobx";
import { SetterModel, languageSet, Common_Utils } from "@qubit-utill/src";

import { DataTypeModel, DataTypeAttributeModel, DataTypeSelectorModel } from "@src/pages/Filter/RegisterFilter/model/DataType";

export default class DefenseDataTypeOrderModel extends SetterModel {
  constructor(param?) {
    super(param);

    if (param) {
      if (param.isEventDataFlag) {
        this.setEventFilterElementJson(param, param.edrFilterElementJson);
      } else {
        this.setFilterElementJson(param, param.edrFilterElementJson);
      }
    }
  }

  @action
  setFilterElementJson = (param, edrFilterElementJson) => {
    if (edrFilterElementJson[0].childArray.length > 0) {
      this.edrFilterElementJson = edrFilterElementJson[0].childArray.map((dataTypeSelect) => {
        const _dataTypeSelect = { ...edrFilterElementJson[0], childArray: [dataTypeSelect] };
        return new DataTypeSelectorModel(param, _dataTypeSelect);
      });
    }
  };

  @action
  setEventFilterElementJson = (param, edrFilterElementJson) => {
    return (this.edrFilterElementJson = edrFilterElementJson.map((dataTypeSelect) => {
      return new DataTypeSelectorModel(param, dataTypeSelect);
    }));
  };

  @action
  toggleIsAutoDefense = (value) => {
    this.isAutoDefense = value;
  };

  @action
  toggleIsUseRecover = () => {
    this.isUseRecover = this.isUseRecover === "1" ? "0" : "1";
  };

  @action
  setDefenseRecoverCommand = (text) => {
    this.edrRecoverCommand = text;
  };

  @computed
  get isUseRecoverFlag() {
    return Common_Utils.isCheckBool(this.isUseRecover);
  }

  @computed
  get isAutoDefenseFlag() {
    return Common_Utils.isCheckBool(this.isAutoDefense);
  }

  @computed
  get edrRecoverTypeName() {
    switch (this.edrRecoverType) {
      case "default":
        return languageSet("기본_실행");
      default:
        return languageSet("명령어_입력_실행");
    }
  }

  @observable
  filterEventElementsList: Array<any> = [];

  @observable
  eventRootElement: Array<any> = [];

  @observable
  edrDefenseType: string = "";

  @observable
  edrDefenseTypeOrder: string = "";

  @observable
  isSelected: boolean = false;

  @observable
  isAutoDefense: string = "0";

  @observable
  isUseRecover: string = "0";

  @observable
  parrentDepth: number = 0;

  @observable
  parrentFilterEventElementId: number = 0;

  @observable
  elementAttributes: string = "";

  @observable
  edrRecoverCommand: string = "";

  @observable
  edrRecoverType: string = "custom";

  @observable
  edrFilterElementId: string = "";

  @observable
  filterId: string = "";

  @observable
  filterEventElementId: number = 0;

  @observable
  edrFilterElementJson: Array<DataTypeSelectorModel> = [];

  @computed
  get param() {
    return {
      filterEventElementsList: this.filterEventElementsList,
      eventRootElement: this.filterEventElementsList[0],
      isDefense: true,
    };
  }

  @action
  setSelectDefenseType(value) {
    value.isSelected = true;
    this.isSelected = true;
    this.edrDefenseType = value.name;

    let params = {
      edrDefenseType: this.edrDefenseType,
      edrDefenseTypeOrder: this.edrDefenseTypeOrder,
      isSelected: this.isSelected,
      isAutoDefense: this.isAutoDefense,
      isUseRecover: this.isUseRecover,
      edrRecoverType: this.edrRecoverType,
      edrRecoverCommand: this.edrRecoverCommand,
      filterId: this.filterId,
      parrentDepth: 1,
      parrentFilterEventElementId: this.parrentFilterEventElementId,
      isDefense: true,
      filterElementName: this.filterEventElementsList[0].filterElementName,
      childArray: [],
    };

    if (this.edrFilterElementJson.length === 0) {
      this.edrFilterElementJson.push(new DataTypeSelectorModel(this.param));
    }

    if (this.edrFilterElementJson[0].dataValues.length < 1) {
      this.edrFilterElementJson[0].selectedTypes[1] = new DataTypeModel(params);
      this.edrFilterElementJson[0].dataValues.push(new DataTypeAttributeModel());
    }
  }

  @action
  addAttributeItem = () => {
    this.edrFilterElementJson.push(new DataTypeSelectorModel(this.param));

    this.edrFilterElementJson[this.edrFilterElementJson.length - 1].selectedTypes[1] = new DataTypeModel({
      parrentDepth: 1,
      isDefense: true,
    });
    this.edrFilterElementJson[this.edrFilterElementJson.length - 1].dataValues.push(new DataTypeAttributeModel({}));
  };

  @action
  deleteAttributeItem = (index) => this.edrFilterElementJson.splice(index, 1);
}
