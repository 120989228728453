import React from "react";
import { Col } from "reactstrap";
import { observer } from "mobx-react";

import { languageSet, useInjectStore, useLink, dateUtil, Common_Utils } from "@qubit-utill/src";

import { Pie, Bar } from "@src/common/components/organisms/Graphs/ApexCharts";
import { ReportBox } from "@src/pages/Report/templates/ReportContent";
import LinkParamsType from "@src/type/LinkParamsType";

interface PropsTypes {
  useSubPieChart?: boolean;
  linkParams?: LinkParamsType;
  customSubChart?: React.ElementType;
  valueFormater?: Function;
  subPieChart?: { title: string; fileName: string };
  customSubGraphOption?: any;
  pieGraphOption?: any;
  useOnClick?: boolean;
}

const SubChart: React.FC<PropsTypes> = ({
  useSubPieChart = false,
  linkParams,
  customSubChart,
  valueFormater,
  subPieChart = { title: "", fileName: "" },
  customSubGraphOption,
  pieGraphOption,
  useOnClick = true,
}) => {
  const { ReportStore } = useInjectStore();
  const { goToLinkWithPathParams } = useLink(ReportStore);
  const { subGraphTitle, barChartData, termsTab, pieChartData, pieChartTitle, methodChartData, params } = ReportStore;

  const goToLogPage = (hour: number, name: string, days: number = 0) => {
    if (linkParams && termsTab === "day") {
      const { link, startParamName, endParamName } = linkParams;

      const detectDate = new Date(params.detectDate);
      const day = name === languageSet("전일") ? 1 : days;
      const agoDays = new Date(params.detectDate).getDate() - day;
      detectDate.setDate(agoDays);
      const paramDate = dateUtil.getDateByForm(detectDate);

      const setParams = {
        [startParamName]: `${paramDate} ${Common_Utils.pad2(hour)}:00:00`,
        [endParamName]: `${paramDate} ${Common_Utils.pad2(hour)}:59:59`,
      };
      goToLinkWithPathParams(setParams, true, link);
    }
  };

  const CustomSubChart = customSubChart ? customSubChart : Bar;

  return (
    <>
      <Col md={useSubPieChart ? "6" : "8"} className="p-l-0 m-b-5">
        <ReportBox className="m-b-5">
          <div className="min-h250">
            <CustomSubChart
              id={`custom_chart_${subGraphTitle}`}
              title={subGraphTitle}
              data={barChartData.chartData}
              category={barChartData.category}
              valueFormater={valueFormater}
              graphOption={customSubGraphOption}
              useOnClick={useOnClick}
              onClick={linkParams ? goToLogPage : undefined}
            />
          </div>
        </ReportBox>
      </Col>
      <Col md={useSubPieChart ? "3" : "4"} className={`p-l-0 m-b-5 ${useSubPieChart ? "" : "p-r-0"}`}>
        <ReportBox className="m-b-5">
          <div className="min-h250">
            <Pie
              id={`pie_chart_${pieChartTitle}`}
              title={pieChartTitle}
              data={pieChartData.chartData}
              category={pieChartData.category}
              colors={pieChartData.colors}
              height={pieGraphOption && pieGraphOption.graphHeight ? pieGraphOption.graphHeight : 297}
              graphOption={pieGraphOption}
              valueFormater={valueFormater}
            />
          </div>
        </ReportBox>
      </Col>
      {useSubPieChart && (
        <Col md={useSubPieChart ? "3" : "4"} className="p-l-0 p-r-0 m-b-5">
          <ReportBox className="m-b-5">
            <div className="min-h250">
              <Pie
                id={`pie_chart_${subPieChart.title}`}
                title={subPieChart.title}
                data={methodChartData.chartData}
                category={methodChartData.category}
                colors={methodChartData.colors}
                height={pieGraphOption && pieGraphOption.graphHeight ? pieGraphOption.graphHeight : 297}
                graphOption={pieGraphOption}
              />
            </div>
          </ReportBox>
        </Col>
      )}
    </>
  );
};

export default observer(SubChart);
