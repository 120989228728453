import React from "react";
import { Button } from "reactstrap";
import { observer } from "mobx-react";

import { DefaultTable, CustomPagination, languageSet, useInjectStore, ThFieldsConfig } from "@qubit-utill/src";

import DownloadIcon from "@src/common/components/atoms/Button/DownloadIcon";
import { UserActionLogStore } from "@src/common/stores";
import { Top10ReportTab, LimitSelector } from "./Top10Tables";
import { ReportTabs } from "@src/pages/Report/templates/ReportContent";
import { ReportBox, GraphTitle } from "@src/pages/Report/templates/ReportContent";

interface Props {
  isIP?: boolean;
  TrComponent?: React.ElementType;
  isPagination?: boolean;
  isUseTitle?: boolean;
  title?: string;
  isWeb?: boolean;
  thFields: ThFieldsConfig;
  tableSelectBox?: React.ElementType;
  emptyMessage?: string;
  isUseTab?: boolean;
  defaultTab?: string;
  newTabComponent?: any;
  newTabComponentProps?: any;
  newTabTitle?: string;
  isUpload?: boolean;
  top10ReportTabs?: any[];
  customUserActionLogCode?: {
    categoryGroupType: string;
    categoryType: string;
  };
}

const Top10Table: React.FC<Props> = ({
  isUseTitle = true,
  TrComponent,
  isPagination = false,
  title,
  isIP,
  isWeb,
  thFields,
  tableSelectBox: SelectBox,
  emptyMessage,
  isUseTab,
  defaultTab = "TABLE",
  newTabComponent: NewTabComponent,
  newTabComponentProps,
  newTabTitle,
  isUpload,
  top10ReportTabs,
  customUserActionLogCode,
}) => {
  const { ReportStore, ModalStore } = useInjectStore();

  const _getCsvData = async () => {
    const title = `${languageSet("공격")} TOP ${ReportStore.params.limit}`;
    isUpload && ReportStore.setInfo("isCsv", true);
    const e = await ReportStore.getCsvData(isUpload ? ReportStore.unit : title, isUpload);
    if (e) {
      ModalStore.openAlertModal({ message: e });
    } else {
      if (customUserActionLogCode) {
        const { categoryGroupType, categoryType } = customUserActionLogCode;
        UserActionLogStore.createCustomUserActionLog(categoryGroupType, categoryType);
      }
    }
  };
  const { reportTab, tabInfo, graphTab, termsTab, selectedGraphTab } = ReportStore;

  const CsvButton = () => (
    <span className="dis-inline-b f-right m-t-5">
      <Button size="sm" color="dark" className="m-r-0 m-b-0" onClick={_getCsvData}>
        <DownloadIcon text="CSV" />
      </Button>
    </span>
  );
  const setSelectedRadioInfo = (graphTabName, title) => {
    ReportStore.setInfo("selectedGraphTab", graphTabName);
    ReportStore.setInfo("selectedGraphName", title);
    ReportStore.setGraphsInfo(graphTabName);
  };

  return (
    <>
      {isUseTitle && (
        <div className="h-37 m-t-10">
          <span className="bold dis-inline-b p-r-10 verti_top p-t-7">
            {title ? (
              title
            ) : (
              <>
                {!top10ReportTabs && <span className="p-r-5">{isIP ? languageSet("위험_IP주소") : languageSet("공격")}</span>}
                <span className="c-blue p-r-10">TOP</span>
                <LimitSelector ReportStore={ReportStore} />
                {top10ReportTabs && <Top10ReportTab top10ReportTabs={top10ReportTabs} />}
              </>
            )}
          </span>
          {SelectBox && <SelectBox />}
          {!isUseTab && <CsvButton />}
        </div>
      )}

      {isUseTab && (
        <ReportTabs
          tabBoxName={reportTab}
          ReportTabsInfo={tabInfo}
          defaultTab={defaultTab}
          selectedRadioTab={selectedGraphTab}
          radioTab={graphTab}
          termsTab={termsTab}
          setInfo={(graphTabName, title) => setSelectedRadioInfo(graphTabName, title)}
        >
          <CsvButton />
        </ReportTabs>
      )}

      {!reportTab || reportTab === "TABLE" ? (
        <DefaultTable
          thFields={thFields}
          colorTheme="bg-aero"
          useIndex
          trType={TrComponent}
          tableList={ReportStore.logList}
          offset={ReportStore.params.offset || 0}
          isUseSetting={false}
          isLoading={ReportStore.isTableLoading}
          emptyMessage={emptyMessage || languageSet("에러_데이터가없습니다")}
          responsive={false}
        />
      ) : (
        <ReportBox>
          <GraphTitle title={newTabTitle} />
          <NewTabComponent {...newTabComponentProps} />
        </ReportBox>
      )}

      {isPagination && (
        <CustomPagination
          changePage={ReportStore.changePage}
          LINECOUNT={ReportStore.params.limit}
          totalCount={ReportStore.totalCount}
          page={isUpload && ReportStore.params.offset === 0 ? ReportStore.page : null}
        />
      )}
    </>
  );
};

export default observer(Top10Table);
