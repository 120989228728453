import { action, observable } from "mobx";

import { Common_Utils, ListCondition, withLoading } from "@qubit-utill/src";

import { middlewareGroupApis, middlewareConfigApis } from "@src/apis/pServer";

import { GroupSquidModel, GroupSquidSectionModel, GroupSquidPropertyModel } from "@src/pages/Group/SQUID/models";

class GroupSquidStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }

  root = {};

  middlewareId = "SQUID";

  @observable
  isPropertyListLoading: boolean = true;

  @observable
  squidGroupList: Array<GroupSquidModel> = [];

  @observable
  info: GroupSquidModel = new GroupSquidModel();

  @observable
  previewInfo: Array<GroupSquidSectionModel> = [];

  @observable
  middlewareUserSectionList: Array<GroupSquidSectionModel> = [];

  @observable
  originMiddlewareUserSectionList: Array<GroupSquidSectionModel> = [];

  @observable
  firewalldInfo: object = {};

  @observable
  managerConsoleList: Array<object> = [];

  @observable
  serverGroupId: number = 0;

  @observable
  middlewareUserConfigId: number = 0;

  // 그룹 리스트 조회
  @withLoading("isLoading")
  @action
  getList = async () => {
    try {
      const { list } = await middlewareGroupApis.getMiddlewareGroupList(this.middlewareId);
      this.squidGroupList = list.map((squidInfo) => {
        return new GroupSquidModel(squidInfo);
      });
    } catch ({ data }) {
      this.squidGroupList = [];
    }
  };

  // 그룹 정보 조회
  @withLoading("isSubLoading")
  @action
  getInfo = async (middlewareUserConfigId) => {
    try {
      const { info } = await middlewareGroupApis.getMiddlewareGroupInfo(middlewareUserConfigId);

      let sectionName = "";

      const getSectionList = () => {
        const middlewareUserSectionList = info.middlewareUserSectionList.map((sectionInfo) => {
          const isUseDelBtn = Boolean(sectionName === sectionInfo.middlewareSectionName);
          sectionName = sectionInfo.middlewareSectionName;

          const middlewareUserPropertyList = sectionInfo.middlewareUserPropertyList.map((propertyInfo) => {
            return new GroupSquidPropertyModel(propertyInfo);
          });
          const _sectionInfo = { ...sectionInfo, middlewareUserPropertyList };
          return new GroupSquidSectionModel(_sectionInfo, isUseDelBtn);
        });
        return middlewareUserSectionList;
      };

      this.middlewareUserSectionList = getSectionList();
      this.originMiddlewareUserSectionList = getSectionList();

      const _info = { ...info, middlewareUserSectionList: this.middlewareUserSectionList };
      this.info = new GroupSquidModel(_info);

      this.middlewareUserConfigId = this.info.middlewareUserConfigId;
      this.serverGroupId = this.info.serverGroupId;
      this.firewalldInfo = this.info.middlewareFirewalldList[0];
      this.managerConsoleList = info.middlewareManagerConsoleList.map((stats) => {
        return {
          managerConsoleAddress: stats.managerConsoleAddress,
          middlewareManagerConsole: stats.middlewareManagerConsole,
        };
      });
    } catch ({ data }) {
      this.info = new GroupSquidModel();
      this.middlewareUserSectionList = [];
      this.originMiddlewareUserSectionList = [];
    }
  };

  @action
  getPreviewInfo = async (middlewareUserConfigId) => {
    const { info } = await middlewareGroupApis.getMiddlewareGroupInfo(middlewareUserConfigId);

    this.previewInfo = info.middlewareUserSectionList.map((sectionInfo) => {
      const middlewareUserPropertyList = sectionInfo.middlewareUserPropertyList.map((propertyInfo) => {
        return new GroupSquidPropertyModel(propertyInfo);
      });
      const _sectionInfo = { ...sectionInfo, middlewareUserPropertyList };
      return new GroupSquidSectionModel(_sectionInfo);
    });
  };

  // 그룹 추가
  @withLoading("isLoading")
  @action
  createGroup = async (serverGroupId) => {
    try {
      await middlewareGroupApis.createMiddlewareGroup(this.middlewareId, { serverGroupId });
      this.getList();
    } catch ({ data }) {
      throw data;
    }
  };

  // 그룹 삭제
  @action
  deleteGroup = async (middlewareUserConfigId) => {
    try {
      await middlewareGroupApis.deleteMiddlewareGroup(middlewareUserConfigId);
      this.getList();
    } catch ({ data }) {
      return data;
    }
  };

  // section ( config ) 추가
  @action
  createSection = async (middlewareUserConfigId, middlewareSectionId) => {
    try {
      await middlewareConfigApis.createMiddlewareConfig(this.middlewareId, middlewareUserConfigId, middlewareSectionId);
      this.getInfo(middlewareUserConfigId);
    } catch ({ data }) {
      return data;
    }
  };

  // section ( config ) 수정
  @action
  updateSection = async (middlewareUserSectionId, isUse, middlewareUserConfigId, isScheduler, propertyList) => {
    const _isUse = isUse ? "1" : "0";
    const _isScheduler = isScheduler ? "1" : "0";
    const params = propertyList.map((propertyInfo) => {
      return {
        middlewarePropertyKey: propertyInfo.middlewarePropertyKey,
        middlewarePropertyValue: propertyInfo.middlewarePropertyValue,
        isSelect: propertyInfo.isSelect,
      };
    });
    try {
      await middlewareConfigApis.updateMiddlewareConfig(
        middlewareUserSectionId,
        _isUse,
        middlewareUserConfigId,
        _isScheduler,
        params
      );
      this.getInfo(middlewareUserConfigId);
    } catch ({ data }) {
      return data;
    }
  };

  // section ( config ) 삭제
  @action
  deleteSection = async (middlewareUserSectionId, middlewareUserConfigId) => {
    try {
      await middlewareConfigApis.deleteMiddlewareConfig(middlewareUserSectionId, middlewareUserConfigId);
      this.getInfo(middlewareUserConfigId);
    } catch ({ data }) {
      return data;
    }
  };

  // property 추가
  @action
  addPropertyList = (propertyList, middlewarePropertyKey) => {
    return propertyList.push(new GroupSquidPropertyModel({ middlewarePropertyKey }));
  };

  // property 삭제
  @withLoading("isPropertyListLoading")
  @action
  deletePropertyList = (propertyList, index) => {
    propertyList.splice(index, 1);
  };

  // property SelectList 조회
  @action
  getPropertySelectList = async (middlewareSectionId) => {
    let optionList: Array<{
      name: string;
      value: string;
      isUsePropertyValue: boolean;
      middlewarePropertyValueList: Array<{ name: string; value: string }>;
    }> = [];
    try {
      const { list } = await middlewareConfigApis.getMiddlewarePropertySelectList(this.middlewareId, middlewareSectionId);
      list.map((optionInfo) => {
        const middlewarePropertyValueList =
          optionInfo.middlewarePropertyValueList.length > 0 &&
          optionInfo.middlewarePropertyValueList.map((valueInfo) => {
            return {
              name: valueInfo.middlewarePropertyValue,
              value: valueInfo.middlewarePropertyValue,
            };
          });
        return optionList.push({
          name: optionInfo.middlewarePropertyKey,
          value: optionInfo.middlewarePropertyKey,
          isUsePropertyValue: Common_Utils.isCheckBool(optionInfo.isUsePropertyValue),
          middlewarePropertyValueList: middlewarePropertyValueList,
        });
      });
      return optionList;
    } catch ({ data }) {
      console.log(data);
      optionList = [];
    }
  };

  // firewalld 수정
  @action
  updateMiddlewareFirewalld = async (param) => {
    try {
      await middlewareConfigApis.updateMiddlewareFirewalld(this.middlewareUserConfigId, param);
      await this.getInfo(this.middlewareUserConfigId);
    } catch ({ data }) {
      return data;
    }
  };

  // firewalld 실행
  @action
  executeMiddlewareFirewalld = async () => {
    try {
      await middlewareConfigApis.executeMiddlewareFirewalld(this.middlewareUserConfigId, this.serverGroupId, this.middlewareId);
    } catch ({ data }) {
      return data;
    }
  };

  // cache manager 추가
  @action
  createManagerConsole = async () => {
    try {
      await middlewareConfigApis.createMiddlewareManagerConsole(this.middlewareUserConfigId);
      await this.getInfo(this.middlewareUserConfigId);
    } catch ({ data }) {
      return data;
    }
  };

  // cache manager 수정
  @action
  updateManagerConsole = async (middlewareManagerConsole, param) => {
    try {
      await middlewareConfigApis.updateMiddlewareManagerConsole(middlewareManagerConsole, param);
      await this.getInfo(this.middlewareUserConfigId);
    } catch ({ data }) {
      return data;
    }
  };

  // cache manager 삭제
  @action
  deleteManagerConsole = async (middlewareManagerConsole) => {
    try {
      await middlewareConfigApis.deleteMiddlewareManagerConsole(middlewareManagerConsole);
      await this.getInfo(this.middlewareUserConfigId);
    } catch ({ data }) {
      return data;
    }
  };

  // 설정검사 실행
  @action
  validationConfigGroup = async (middlewareUserConfigId, validatorState, serverGroupId) => {
    const params = {
      middlewareUserConfigId,
      validatorState,
      serverGroupId,
    };
    try {
      await middlewareGroupApis.validationConfigMiddlewareGroup(this.middlewareId, params);
      await this.getList();
    } catch ({ data }) {
      return data;
    }
  };

  // 최종적용 실행
  @action
  applicationGroup = async (middlewareUserConfigId, validatorState, serverGroupId) => {
    const params = {
      middlewareUserConfigId,
      validatorState,
      serverGroupId,
    };
    try {
      await middlewareGroupApis.applicationMiddlewareGroup(this.middlewareId, params);
      await this.getList();
    } catch ({ data }) {
      return data;
    }
  };

  // 재설정 실행
  @action
  reloadGroup = async (serverGroupId, middlewareUserConfigId) => {
    try {
      await middlewareGroupApis.reloadMiddlewareGroup(serverGroupId, middlewareUserConfigId, this.middlewareId);
      await this.getList();
    } catch ({ data }) {
      return data;
    }
  };

  // 재시작 실행
  @action
  restartGroup = async (serverGroupId, middlewareUserConfigId) => {
    try {
      await middlewareGroupApis.restartMiddlewareGroup(serverGroupId, middlewareUserConfigId, this.middlewareId);
      await this.getList();
    } catch ({ data }) {
      return data;
    }
  };

  // file 수정
  @action
  updateFileData = async (middlewareUserConfigId, middlewareUserSectionId, fileData) => {
    try {
      await middlewareConfigApis.updateMiddlewareFile(middlewareUserConfigId, middlewareUserSectionId, fileData);
      await this.getInfo(middlewareUserConfigId);
    } catch ({ data }) {
      return data;
    }
  };
}

export default GroupSquidStore;
