import { axiosModules } from "@qubit-utill/src";

export default {
  //탭메뉴
  getFilterCategoryGroupDayList: (params) => {
    return axiosModules.get("/pstats/webdetect/day/categorygroup", params);
  },
  //공격 리스트
  getWebDayMainDetect: (params) => {
    return axiosModules.get("/pstats/webdetect/day/main", params);
  },
  //URL리스트
  getWebDayMainDetectURL: (params) => {
    return axiosModules.get("/pstats/webrequest/day", params);
  },
  //HOST리스트
  getWebDayMainDetectHost: (params) => {
    return axiosModules.get("/pstats/webhostdetect/day/main", params);
  },
  //차트
  getWebDayMainChart: (params) => {
    return axiosModules.get("/pstats/webdetect/day/mainchart", params);
  },
  //서브 차트
  getWebDaySubChart: (params) => {
    return axiosModules.get("/pstats/webdetect/day/subchart", params);
  },
  //리스트 주별
  getWebWeekMainDetect: (params) => {
    return axiosModules.get("/pstats/webdetect/week/main", params);
  },
  //URL리스트 주별
  getWebWeekMainDetectURL: (params) => {
    return axiosModules.get("/pstats/webrequest/week", params);
  },
  //HOST리스트 주별
  getWebWeekMainDetectHost: (params) => {
    return axiosModules.get("/pstats/webhostdetect/week/main", params);
  },
  //차트 주별
  getWebWeekMainChart: (params) => {
    return axiosModules.get("/pstats/webdetect/week/mainchart", params);
  },
  //차트 주별
  getWebWeekSubChart: (params) => {
    return axiosModules.get("/pstats/webdetect/week/subchart", params);
  },
  //리스트 월별
  getWebMonthMainDetect: (params) => {
    return axiosModules.get("/pstats/webdetect/month/main", params);
  },
  //URL리스트 월별
  getWebMonthMainDetectURL: (params) => {
    return axiosModules.get("/pstats/webrequest/month", params);
  },
  //HOST리스트 월별
  getWebMonthMainDetectHost: (params) => {
    return axiosModules.get("/pstats/webhostdetect/month/main", params);
  },
  //차트 월별
  getWebMonthMainChart: (params) => {
    return axiosModules.get("/pstats/webdetect/month/mainchart", params);
  },
};
