import { set, computed, observable } from "mobx";

class ReportCategoryTabModel {
  constructor(param) {
    set(this, param);
    if (param) {
      this.value = param.filterCategoryGroupId;
      this.name = param.filterCategoryGroupName;
    }
  }

  @computed
  get count() {
    return this.detectCount;
  }

  @computed
  get isUndefinedCount() {
    return this.detectCount === undefined;
  }

  @observable
  value = "";
  @observable
  name = "";
  @observable
  detectCount: number | undefined = undefined;
}

export default ReportCategoryTabModel;
