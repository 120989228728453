import { observable, computed } from "mobx";

import { SetterModel, Common_Utils } from "@qubit-utill/src";

class WebFirewallLogModel extends SetterModel {
  constructor(params) {
    super(params);
    if (params) {
      this.registerDate = params.logCreateDate;
      this.serverOs = params.serverIp && params.typeos;
      this.defenceIp = params.logClientIp;
      this.serverGroup = params.serverGroup;
      this.serverIp = params.serverIp;
    }
  }

  @computed
  get defenceNation() {
    return this.countryIsoCode && this.countryIsoCode.toLowerCase();
  }

  @computed
  get getServerGroup() {
    return Common_Utils.isDataWithDashBar(this.serverGroup);
  }

  @computed
  get getServerIp() {
    return Common_Utils.isDataWithDashBar(this.serverIp);
  }

  @observable
  logBlockedCategoryName: string = ""; //분류

  @observable
  countryIsoCode: string = ""; //국가코드

  @observable
  defenceIp: string = ""; //공격자IP

  @observable
  serverGroup: string = ""; //그룹

  @observable
  serverOs: string = ""; //운영체제

  @observable
  serverIp: string = ""; //시스템

  @observable
  logBlockedTypeName: string = ""; //공격유형

  @observable
  logUri: string = ""; //경로

  @observable
  logHost: string = "";

  @observable
  serverId: string = "";

  @observable
  isPrivateIp = false; //공격IP가 내부인지 아닌지

  @observable
  logBlockedCategoryDetailName: string = "";

  @observable
  logClientIp: string = "";
}

export default WebFirewallLogModel;
