import { action } from "mobx";

import { pAccountApis } from "@src/apis";

class ChangePasswordGuideStore {
  constructor(root) {
    this.root = root;
  }

  root;

  @action
  updateSkipPasswordChange = async () => {
    try {
      await pAccountApis.updateSkipPasswordChange();
    } catch ({ data }) {
      return data;
    }
  };
}

export default ChangePasswordGuideStore;
