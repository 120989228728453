import { action, observable } from "mobx";
import { endOfToday, startOfToday } from "date-fns";
import { pAuthApis } from "@src/apis";
import LoginHistoryModel from "../models/LoginHistoryModel";
import { ListCondition, formatConstants, dateUtil } from "@qubit-utill/src";

class LoginHistoryStore extends ListCondition {
  constructor() {
    super();
    this.params.limit = 10;
    this.dateReload();
  }
  root;
  @observable
  list = [];

  @action
  dateReload = () => {
    this.params.startDate = this.dateFormat(startOfToday());
    this.params.endDate = this.dateFormat(endOfToday());
  };

  @action
  dateFormat = (date) => dateUtil.format(date, formatConstants.DATEFORMATWITHTIME);

  @action
  getList = async () => {
    try {
      const { list, totalCount } = this.params.uuid
        ? await pAuthApis.getUserLoginLogList(this.params)
        : await pAuthApis.getUserLoginLogListByUuid(this.params);
      this.list = list.map((data) => new LoginHistoryModel(data));
      this.totalCount = totalCount;
    } catch ({ data }) {
      this.list = [];
      this.totalCount = 0;
    }
  };

  @action
  setListByParam = (value, uuid) => {
    let date = dateUtil.getDateByForm(value, "-");
    this.params = { ...this.params, offset: 0, uuid: uuid, startDate: date + " 00:00:00" };
    this.setListParams("endDate", date + " 23:59:59", true);
  };
}

export default new LoginHistoryStore();
