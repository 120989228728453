import { axiosModules } from "@qubit-utill/src";

export default {
  // agent resource alarm > Agent Resource 사용자 알람 설정 조회
  getAlarmConfigResourceInfoList: () => {
    return axiosModules.get("/palarm/agentresourcealarmconfig");
  },

  // agent resource alarm > Agent Resource 사용자 알람 설정 등록
  createAgentResourceAlarmConfig: (data) => {
    return axiosModules.post(`/palarm/agentresourcealarmconfig`, data);
  },

  // alarm > info > 기본 알림설정 정보
  getAlarmConfigDefaultInfo: () => {
    return axiosModules.get("/palarm/alarmconfig/default");
  },

  // alarm > info > 탐지/방어 알림설정 정보
  getAlarmConfigDetectInfo: () => {
    return axiosModules.get("/palarm/alarmconfig/detect");
  },

  // alarm > info > 보고서 알림설정 정보
  getAlarmConfigReportInfo: () => {
    return axiosModules.get("/palarm/alarmconfig/report");
  },

  // alarm > info > 웹훅 알림설정 정보
  getAlarmConfigWebhookInfo: (params) => {
    return axiosModules.get("/palarm/alarmconfig/webhook", params);
  },

  // alarm > setting > 알림 간격 설정 수정
  updateAlarmConfigSettingAlarmIntervalTime: (data) => {
    return axiosModules.put(`/palarm/alarmconfig/default/setting/alarmintervaltime`, data);
  },

  // alarm > setting > 알림 시간대 설정
  updateAlarmRangeTime: (data) => {
    return axiosModules.put(`/palarm/alarmconfig/default/setting/alarmrangetime`, data);
  },

  // alarm > setting > 서버그룹 설정 수정
  updateAlarmConfigSettingServerGroup: (data) => {
    return axiosModules.put(`/palarm/alarmconfig/default/setting/servergroup`, data);
  },

  // alarm > setting > 계정탈취공격 설정 수정
  updateAlarmConfigSettingDdos: (data) => {
    return axiosModules.put(`/palarm/alarmconfig/detect/setting/ddos`, data);
  },

  // alarm > setting > 시스템로그 설정 수정
  updateAlarmConfigSettingSystem: (data) => {
    return axiosModules.put(`/palarm/alarmconfig/detect/setting/system`, data);
  },

  // alarm > setting > 데이터유출 설정 수정
  updateAlarmConfigSettingDataBreach: (data) => {
    return axiosModules.put(`/palarm/alarmconfig/detect/setting/webextendsdatabreach`, data);
  },

  // alarm > setting > 마이터 설정 수정
  updateMitre: (data) => {
    return axiosModules.put(`/palarm/alarmconfig/detect/setting/mitre`, data);
  },

  // alarm > setting > 상관분석 설정 수정
  updatePacakge: (data) => {
    return axiosModules.put(`/palarm/alarmconfig/detect/setting/package`, data);
  },

  // alarm > setting > 웹로그 설정 수정
  updateAlarmConfigSettingWeb: (data) => {
    return axiosModules.put(`/palarm/alarmconfig/detect/setting/web`, data);
  },

  // alarm > setting > 웹방화벽 로그 설정 수정
  updateWaf: (data) => {
    return axiosModules.put(`/palarm/alarmconfig/detect/setting/waf`, data);
  },

  // alarm > setting > 네트워크 설정 수정
  updateAlarmConfigSettingNetwork: (data) => {
    return axiosModules.put(`/palarm/alarmconfig/detect/setting/network`, data);
  },

  // alarm > setting > 응용프로그램 설정 수정
  updateAlarmConfigSettingApplication: (data) => {
    return axiosModules.put(`/palarm/alarmconfig/detect/setting/application`, data);
  },

  // alarm > setting > 리소스모니터링 설정 수정
  updateAlarmConfigSettingResourceMonitor: (data) => {
    return axiosModules.put(`/palarm/alarmconfig/detect/setting/resourcemonitor`, data);
  },

  // alarm > setting > 보고서 설정 수정
  updateAlarmConfigSettingReport: (data) => {
    return axiosModules.put(`/palarm/alarmconfig/report/setting`, data);
  },

  // alarm > setting > 웹훅 설정 수정
  updateAlarmConfigSettingWebhook: (data) => {
    return axiosModules.put(`/palarm/alarmconfig/webhook/setting`, data);
  },

  // alarm > setting > EDR로그 설정 수정
  updateEdr: (data) => {
    return axiosModules.put(`/palarm/alarmconfig/detect/setting/edr`, data);
  },

  // alarm > setting > 보고서 메일 설정 수정
  updateReportMail: (data) => {
    return axiosModules.put("/palarm/alarmconfig/setting/reportmail", data);
  },
};
