import { observable, action, computed, toJS } from "mobx";

import {
  ListCondition,
  Common_Utils,
  withLoading,
  languageSet,
  dateUtil,
  UserException,
  stringUtils,
  arrayUtils,
} from "@qubit-utill/src";
import { SummaryListModel } from "@qubit-utill/src/model";

import LICENSECODE from "@src/constants/licenseCode";
import { pAuthApis, pCustomerApis } from "@src/apis";
import { serverApis, agentApis, serverManageApis } from "@src/apis/pServer";
import { resourceConfigUserApis } from "@src/apis/pMetrics";
import { tagApis } from "@src/apis/pFilter";

import { CSVINFO } from "@src/constants";
import { HOST_ISOLATION_CONFIG, FORENSIC_CONFIG } from "@src/constants/code";

import { ServerModel, AllowIpAddressModel } from "@src/pages/Server/ServerManagement/models";

class ServerStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }

  root;

  @observable
  isTableLoading: boolean = false;

  @observable
  isSubTableLoading: boolean = false;

  @observable
  serverInfo = new ServerModel({}, this.root.CodeStore, this.root.RoleLicenseStore);

  @observable
  tableList: ServerModel[] = [];

  @observable
  allowIpAddressList: Array<AllowIpAddressModel> = [];

  @observable
  allowIpAddressParam = {
    offset: 0,
    limit: "10",
    ascDesc: "DESC",
  };

  @observable
  allowIpAddressTotalCount: number = 0;

  @observable
  isCmdLoading = false;

  @observable
  cmdLogList = [];

  @observable
  cmdLogTotalCount = 0;

  @observable
  cmdParams = {
    limit: 10,
    offset: 0,
  };

  @observable
  summaryList: any = [];

  @observable
  resourceConfigList = [];

  @observable
  serverAnalysisHistoryList: Array<{
    serverEdrDetectModeLogId: number;
    edrDetectMode: string;
    registerUuid: string;
    registerDate: string;
    registerUuidName: string;
  }> = [];

  @observable
  serverAnalysisHistoryPageOffset = 0;

  @observable
  serverAnalysisHistoryTotalCount = 0;

  @action
  subChangePage = (page) => {
    this.cmdParams.offset = Common_Utils.getOffset(page, this.cmdParams.limit);
    this.getCmdLogList(this.serverInfo.serverId);
  };

  @observable
  osInfomation = [];

  @observable
  osTypes = [];

  @observable
  osMajors = {};

  @observable
  seletedTab = "";

  @observable
  licenseDateRange: object = {};

  @observable
  checkedLogCollectorInfo: {
    hasParent: boolean;
    logcollectorChildServerCount: number;
  } = {
    hasParent: false,
    logcollectorChildServerCount: 0,
  };

  @computed
  get isDisplayShutdownFlag() {
    return Common_Utils.isCheckBool(this.isUseHostShutdown);
  }

  @computed
  get isDisplayIsolationFlag() {
    return Common_Utils.isCheckBool(this.isUseHostIsolate);
  }

  @observable
  isUseHostShutdown: string = "0";

  @observable
  isUseHostIsolate: string = "0";

  @observable
  tagValueTypeList;

  @observable
  defaultTagValueType;

  @observable
  displayForensicList: string[] = [];

  @computed
  get isDisplayForensicFlag() {
    return this.displayForensicList && this.displayForensicList.length > 0 ? true : false;
  }

  @action
  initSelectedTab = () => {
    this.seletedTab = "";
  };

  @computed
  get checkList() {
    let list: string[] = [];
    this.tableList.forEach(({ serverId, isSelected }) => {
      isSelected && list.push(serverId);
    });
    return list;
  }

  @computed
  get checkedLogcollectorList() {
    let list: any = [];
    this.tableList.forEach(
      ({ isSelected, isLogCollectorFlag, logcollecterId, serverId }) =>
        isSelected && isLogCollectorFlag && list.push({ logcollecterId, serverId })
    );
    return list;
  }

  @action
  initList = () => {
    this.tableList = [];
  };

  @action
  setData = (key, value) => {
    this[key] = value;
  };

  @withLoading("isLoading")
  @action
  getList = async () => {
    try {
      const { list, totalCount } = await serverApis.getServerList(this.params);
      this.tableList = list.map((info) => new ServerModel(info, this.root.CodeStore, this.root.RoleLicenseStore));
      this.totalCount = totalCount;
    } catch ({ data }) {
      this.totalCount = 0;
      this.tableList = [];
      return data;
    }
    return false;
  };

  @withLoading("isSubLoading")
  @action
  getInfo = async (serverId) => {
    try {
      await this.getResourceConfigList();
      await this.getServerInfo(serverId, this.resourceConfigList);

      this.serverAnalysisHistoryPageOffset = 0;
      this.serverAnalysisHistoryList = [];

      if (this.serverInfo.logcollecterId) {
        this.getParentServerInfo(this.serverInfo.logcollecterId);
      } else {
        this.getChildList(serverId);
        this.getCmdLogList(serverId);
      }
    } catch (error) {
      console.log("error");
    }
  };

  @action
  getResourceConfigList = async () => {
    try {
      const { list } = await resourceConfigUserApis.getResourceConfigUserGroupList();
      this.resourceConfigList = list;
    } catch (error) {
      this.resourceConfigList = [];
    }
  };

  @action
  getServerInfo = async (serverId, resourceConfigList) => {
    try {
      const { info } = await serverApis.getServer(serverId);
      this.serverInfo = new ServerModel({ ...info, resourceConfigList }, this.root.CodeStore, this.root.RoleLicenseStore);
    } catch (err) {
      this.serverInfo = new ServerModel({}, this.root.CodeStore, this.root.RoleLicenseStore);
    }
  };

  //todo dprecated
  @action
  getServerAgentExcuteLogRecnet = async (serverId) => {
    try {
      const { info } = await agentApis.getAgentExcuteLogRecnet(serverId);
      this.serverInfo.agentLogInfo = info;
    } catch (err) {
      this.serverInfo.agentLogInfo = null;
      console.log(err);
    }
  };

  @action
  getServerMopluraState = async (serverId) => {
    try {
      const { info } = await serverApis.getServerMopluraState(serverId);
      this.serverInfo.setInfo("displayAgentExecuteState", info.displayAgentExecuteState);
    } catch (err) {
      this.serverInfo.agentLogInfo = null;
      console.log(err);
    }
  };

  @withLoading("isCmdLoading")
  @action
  getCmdLogList = async (serverId) => {
    try {
      const { list, totalCount } = await agentApis.getAgentCmdLogList({
        serverId,
        ...this.cmdParams,
      });
      this.cmdLogList = list;
      this.cmdLogTotalCount = totalCount;
    } catch (err) {
      this.cmdLogList = [];
      this.cmdLogTotalCount = 0;
    }
  };

  @action
  getParentServerInfo = async (serverId) => {
    try {
      const { info } = await serverApis.getServer(serverId);
      this.serverInfo.setInfo("parentServerInfo", new ServerModel(info, this.root.CodeStore, this.root.RoleLicenseStore));
    } catch (err) {
      this.serverInfo.setInfo("parentServerInfo", null);
    }
  };

  @action
  getChildList = async (logcollecterId) => {
    try {
      const { list } = await serverApis.getServerList({
        logcollecterId,
        limit: 9999,
      });
      this.serverInfo.setInfo(
        "childList",
        list.map((data) => new ServerModel(data, this.root.CodeStore, this.root.RoleLicenseStore))
      );
    } catch (err) {
      this.serverInfo.setInfo("childList", null);
    }
  };

  @action
  updateAgentStatus = async (serverId, isLogUpload) => {
    try {
      await serverApis.updateIsLogUpload(serverId, {
        isLogUpload,
      });
      this.getList();
    } catch ({ data }) {
      throw new UserException(data.returnCodeMessage || data.dataNotFoundCodeMessage);
    }
  };

  @action
  createServer = async (data) => {
    try {
      const { serverId } = await serverApis.createServerManual(data);
      serverId && this.getList();
      return serverId;
    } catch ({ data }) {
      if (data.returnCode === "PSERVER10011") {
        throw new UserException(data.returnCodeMessage);
      }
      throw new UserException(languageSet("에러_등록중에러발생했습니다"));
    }
  };

  @action
  deleteServerModPlura = async (serverId) => {
    try {
      await serverApis.deleteServerModPlura(serverId);
      this.getInfo(serverId);
      this.seletedTab = "logSetting";
    } catch ({ data }) {
      throw new UserException(data.returnCodeMessage || data.dataNotFoundCodeMessage);
    }
  };

  @action
  createServerManualDLC = async (data) => {
    const _data = { ...data, isUseNetworkLog: "1" };
    try {
      const { serverId } = await serverApis.createServerManualDLC(_data);
      serverId && this.getList();
      return await serverId;
    } catch ({ data }) {
      if (data.returnCode === "SVC00105" || data.returnCode === "SVC00104") {
        throw new UserException(languageSet("에러_시스템등록불가_관리자에게문의"));
      }
      throw new UserException(languageSet(data.returnCodeMessage || data.dataNotFoundCodeMessage));
    }
  };

  @action
  updateServer = async (serverId, data) => {
    try {
      await serverApis.updateServer(serverId, data);
      this.getList();
    } catch ({ data }) {
      throw new UserException(data.returnCodeMessage || data.dataNotFoundCodeMessage);
    }
  };

  @action
  getLogcollectorChildServerCount = async () => {
    try {
      const hasParent = this.checkedLogcollectorList.find(({ logcollecterId }) => {
        return logcollecterId === null;
      });
      if (hasParent) {
        this.checkedLogCollectorInfo.hasParent = true;
        const _logcollecterIds = this.checkedLogcollectorList.map(({ serverId }) => serverId);
        const { totalCount } = await serverApis.getServerCount({ logcollecterIds: _logcollecterIds });
        this.checkedLogCollectorInfo.logcollectorChildServerCount = totalCount;
        return this.checkedLogCollectorInfo;
      } else {
        this.checkedLogCollectorInfo.hasParent = false;
        this.checkedLogCollectorInfo.logcollectorChildServerCount = 0;
        return this.checkedLogCollectorInfo;
      }
    } catch (error) {
      console.log("error: ", error);
      return error.data;
    }
  };

  @action
  deleteServer = async () => {
    try {
      await serverApis.deleteServer({
        serverIds: toJS(this.checkList),
      });
      this.getList();
    } catch ({ data }) {
      throw new UserException(data.returnCodeMessage || data.dataNotFoundCodeMessage);
    }
  };

  @action
  updateServerName = async (serverId, serverName) => {
    try {
      await serverApis.updateServerName({
        serverId,
        serverName,
      });
    } catch ({ data }) {
      throw new UserException(data.returnCodeMessage || data.dataNotFoundCodeMessage);
    }
  };

  csvDownload = (logForensicLicense) => {
    if (this.tableList.length > 0) {
      let csv = [];
      if (logForensicLicense) {
        csv = Common_Utils.makeCSV(CSVINFO.SYSTEM_LOGFORENSIC.head, this.tableList);
      } else {
        csv = Common_Utils.makeCSV(CSVINFO.SYSTEM.head, this.tableList);
      }
      Common_Utils.csvDownload(csv, `${languageSet("시스템관리")}_${dateUtil.getDateByForm(new Date())}`);
    } else {
      throw new UserException(languageSet("에러_데이터가없습니다"));
    }
  };

  @action
  getSummaryList = async () => {
    try {
      const { list } = await serverApis.getSummaryList();
      const _list = list.reduce((acc, cur) => {
        //additionalValue 값이 있을 경우 child로 넣기
        if (cur.additionalValue) {
          const matchIndex = acc.findIndex(({ additionalValue }) => additionalValue === cur.additionalValue);

          if (matchIndex !== -1) {
            acc[matchIndex].child.push(cur);
          } else {
            cur.child = [{ ...cur }];
            acc.push(cur);
          }
        } else {
          acc.push({ ...cur, additionalValue: cur.code });
        }
        return acc;
      }, []);

      this.summaryList = _list.map((data, curIndex) => {
        data.orderNo = curIndex; //orderNo 제정렬
        return new SummaryListModel({ params: data, LICENSECODE });
      });
    } catch ({ data }) {
      this.summaryList = [];
      return data;
    }
  };

  @action
  getLicenseSearchDateRange = async () => {
    const { info } = await pAuthApis.getLicenseSearchDateRange();
    this.licenseDateRange = info;
  };

  @withLoading("isTableLoading")
  @action
  getServerAnalysisHistoryList = async (serverId) => {
    try {
      const param = {
        limit: 5,
        offset: this.serverAnalysisHistoryPageOffset,
      };
      const { list, totalCount } = await serverManageApis.getServerAnalysisHistoryList(serverId, param);
      this.serverAnalysisHistoryList = list;
      this.serverAnalysisHistoryTotalCount = totalCount;
    } catch ({ data }) {
      this.serverAnalysisHistoryList = [];
      this.serverAnalysisHistoryTotalCount = 0;
    }
  };

  @observable
  changeableEdrList: Array<number> = [];

  @observable
  unchangeableEdrList: Array<number> = [];

  @observable
  changeableEdrCount: number = 0;

  @observable
  unchangeableEdrCount: number = 0;

  @action
  getCheckIsChangeableEdr = async () => {
    try {
      const { edrSupportIds, noEdrSupportIds } = await serverManageApis.getCheckIsChangeableEdr({
        serverIds: toJS(this.checkList),
      });
      this.changeableEdrList = edrSupportIds || [];
      this.unchangeableEdrList = noEdrSupportIds || [];
      this.changeableEdrCount = this.changeableEdrList.length;
      this.unchangeableEdrCount = this.unchangeableEdrList.length;
    } catch ({ data }) {
      this.changeableEdrList = [];
      this.unchangeableEdrList = [];
      this.changeableEdrCount = 0;
      this.unchangeableEdrCount = 0;
    }
  };

  @action
  updateServerLogAnalysis = async (isServer, blockMode?) => {
    const edrDetectMode = isServer ? "server" : "agent";
    const param = {
      serverIds: toJS(this.changeableEdrList),
      edrBlockMode: isServer ? null : blockMode,
    };

    try {
      await serverManageApis.updateEdrMode(edrDetectMode, param);
    } catch ({ data }) {
      return data;
    }
  };

  @action
  changeServerAnalysisHistoryPage = (page: number) => {
    this.serverAnalysisHistoryPageOffset = Common_Utils.getOffset(page, 5);
    this.getServerAnalysisHistoryList(this.serverInfo.serverId);
  };

  @observable
  changeableServerStatusIds: Array<number> = [];

  @observable
  unchangeableServerStatusIds: Array<number> = [];

  @computed
  get changeableServerStatusIdsCount() {
    return this.changeableServerStatusIds.length;
  }

  @computed
  get unchangeableServerStatusIdsCount() {
    return this.unchangeableServerStatusIds.length;
  }

  @action
  getServerManagementStatusCheck = async (params) => {
    try {
      params = { ...params, serverIds: toJS(this.checkList) };
      const { successServerIds, failServerIds } = await serverManageApis.getServerManagementStatusCheck(params);

      this.changeableServerStatusIds = successServerIds || [];
      this.unchangeableServerStatusIds = failServerIds || [];
    } catch (err) {
      this.changeableServerStatusIds = [];
      this.unchangeableServerStatusIds = [];
    }
  };

  @action
  updateMultiServerManagementStatus = async (editType, params) => {
    try {
      params = { ...params, serverIds: toJS(this.changeableServerStatusIds) };
      await serverManageApis[`createServerManagementStatus${stringUtils.firstLetterUpper(editType)}List`](params);
      this.getList();
    } catch (err) {
      return err;
    }
  };

  @action
  updateSingleServerManagementStatus = async (editType, param) => {
    try {
      await serverManageApis[`createServerManagementStatus${editType}`](param);
    } catch (err) {
      return err;
    }
  };

  /**
   * 관리 > 사용 설정 정보
   */
  @action
  getDisplayGroupSettings = async () => {
    try {
      const { list } = await pCustomerApis.getUserPreferencesList({
        servicePreferencesTypeCodes: [HOST_ISOLATION_CONFIG, FORENSIC_CONFIG],
      }); // 노출여부

      // 격리
      list[HOST_ISOLATION_CONFIG].forEach((configItem) => {
        this[configItem.servicePreferencesId] = configItem.servicePreferencesItemValue;
      });

      // 포렌식 (사용중인 값만 넣기)
      list[FORENSIC_CONFIG].forEach((configItem) => {
        if (Common_Utils.isCheckBool(configItem.servicePreferencesItemValue)) {
          this.displayForensicList.push(configItem.servicePreferencesId);
        }
      });
    } catch (err) {
      this.displayGroupSettingsClear();
    }
  };

  @action
  displayGroupSettingsClear = () => {
    this.isUseHostShutdown = "0";
    this.isUseHostIsolate = "0";
    this.displayForensicList = [];
  };

  // 추후 ipv6 추가를 위한 로직
  // 태그 주소 유형 목록
  @action
  getTagValueTypeList = async () => {
    try {
      const { list } = await tagApis.getTagValueTypeList("ip");
      this.tagValueTypeList = arrayUtils.sortByKey(list, "tagValueTypeOrderNo").map((info) => {
        if (info.isDefault === "1") {
          this.defaultTagValueType = info.tagValueType;
        }
        return {
          name: info.tagValueTypeName,
          value: info.tagValueType,
          isDefault: info.isDefault === "1",
          token: info.tagValueTypeToken,
          size: info.tagValueTypeTokenSize,
        };
      });
    } catch ({ data }) {
      this.tagValueTypeList = [];
      return data;
    }
  };

  @withLoading("isSubTableLoading")
  @action
  getAllowIpAddressList = async () => {
    try {
      const { list, totalCount } = await serverManageApis.getHostIsolateAllowIpList({
        serverId: this.serverInfo.serverId,
        ...this.allowIpAddressParam,
      });
      this.allowIpAddressList = list.map((info) => new AllowIpAddressModel(info));
      this.allowIpAddressTotalCount = totalCount;
    } catch ({ data }) {
      this.allowIpAddressList = [];
      this.allowIpAddressTotalCount = 0;
      return data.err;
    }
  };

  @action
  createAllowIpAddress = async (param) => {
    try {
      const { allowIp, description } = param;
      await serverManageApis.createHostIsolateAllowIp({
        serverId: this.serverInfo.serverId,
        allowIp,
        description,
      });
    } catch (err) {
      return err.data;
    }
  };

  @action
  deleteAllowIpAddress = async (allowIpId) => {
    try {
      await serverManageApis.deleteHostIsolateAllowIp(allowIpId);
    } catch (err) {
      return err.data;
    }
  };

  @action
  allowIpAddressChangePage = async (page) => {
    this.allowIpAddressParam.offset = Common_Utils.getOffset(page, Number(this.allowIpAddressParam.limit));
    await this.getAllowIpAddressList();
  };
}

export default ServerStore;
