import { observable, computed } from "mobx";

import { SetterModel, Common_Utils } from "@qubit-utill/src";

class RefListModel extends SetterModel {
  constructor(params) {
    super(params);
    if (params) {
      this.ip = params.filterElementReferenceDataValue;
      this.hostname = Common_Utils.isDataWithDashBar(params.filterElementReferenceDataDescription);
      this.ipId = params.filterElementReferenceDataId;
      this.registerUserName = Common_Utils.isDataWithDashBar(params.registerUserName);
    }
  }

  @computed
  get getIsRegEx() {
    return Common_Utils.isDataWithDashBar(Common_Utils.isCheckBool(this.isRegEx) && "O");
  }

  @computed
  get getFilterElementReferenceDataDescription() {
    return Common_Utils.isDataWithDashBar(this.filterElementReferenceDataDescription);
  }

  @observable
  ipId: string = "";

  @observable
  ip: string = "";

  @observable
  hostname: string = "";

  @observable
  registerUserName: string = "";

  @observable
  isSelected: boolean = false;

  @observable
  isRegEx: string = "";

  @observable
  filterElementReferenceDataDescription: string = "";
}

export default RefListModel;
