import { observable, action, toJS } from "mobx";

import { ListCondition, Common_Utils, withLoading, languageSet } from "@qubit-utill/src";

import { networkTotalLogApis } from "@src/apis/pLog";
import { CSVINFO, UISET } from "@src/constants";

import NetworkTotalLogModel from "@src/pages/TotalLog/NetworkTotalLog/Models/NetworkTotalLogModel";

class NetworkTotalLogStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }

  root;

  @observable
  tableList: NetworkTotalLogModel[] = [];

  @observable
  networkTotalLogInfo = {};

  @observable
  totalCount = 0;

  @withLoading("isLoading")
  @action
  getList = async () => {
    try {
      const tagServicePropertyList = this.tagServicePropertyList;
      const tagValueTypeList = this.tagValueTypeList;
      const getTagServiceDataList = this.openIpTagModal;

      const { list, totalCount, nextCursorMark } = await networkTotalLogApis.getNetLogList(this.params);

      this.tableList = list.map((info) => {
        const _info = { ...info, tagServicePropertyList, tagValueTypeList, getTagServiceDataList };
        return new NetworkTotalLogModel(_info);
      });

      this.setCursorMarkInfo(nextCursorMark);
      this.totalCount = totalCount;
    } catch (e) {
      this.tableList = [];
      this.totalCount = 0;
    }
  };

  @withLoading("isSubLoading")
  @action
  getInfo = async (documentId) => {
    try {
      const tagServicePropertyList = this.tagServicePropertyList;
      const tagValueTypeList = this.tagValueTypeList;
      const getTagServiceDataList = this.openIpTagModal;
      const getTagServiceDataListFn = this.getTagServiceDataList;

      const { info, viewTemplate, programMessageTemplate } = await networkTotalLogApis.getNetLog(documentId);

      const _info = { ...info, tagServicePropertyList, tagValueTypeList, getTagServiceDataList, getTagServiceDataListFn };

      this.networkTotalLogInfo = new NetworkTotalLogModel({
        ..._info,
        viewTemplate,
        programMessageTemplate,
      });
      this.root.TicketStore.getPrintTicketInfo("networklog", documentId, info.logCreateDate); //get 티켓발행정보
    } catch (err) {
      this.networkTotalLogInfo = {};
    }
  };

  @action
  downloadCSV = async (isUseBulk?, no?) => {
    try {
      if (this.tableList.length > 0) {
        let csv;
        if (isUseBulk && no) {
          csv = await networkTotalLogApis.getNetLogCsvList({
            ...toJS(this.params),
            // serverIds: toJS(this.params.serverIds),
            // typeosList: toJS(this.params.typeosList),
            // serverGroupIds: toJS(this.params.serverGroupIds),
            offset: 0,
            limit: no,
          });
        } else {
          csv = Common_Utils.makeCSV(CSVINFO.TOTALLOG_NET.head, this.tableList);
        }

        Common_Utils.csvDownload(
          csv,
          Common_Utils.setCsvFileName({ title: UISET.TOTALLOG_NET.title, category: languageSet("전체로그"), ...this.params })
        );
      } else {
        return languageSet("에러_데이터가없습니다");
      }
    } catch ({ data }) {
      console.log(data);
    }
  };
}

export default NetworkTotalLogStore;
