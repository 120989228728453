import getURI from "@src/constants/uriset";
import { DEFENCE_LICENSECODE } from "@src/constants/licenseCode";
import { DefenceManagementContainer } from "@src/pages";

export default {
  //방어 > 설정 : 시스템
  DEFENSE_MANAGEMENT_SYSTEM: {
    link: getURI("DEFENSE_MANAGEMENT_SYSTEM"),
    licenseCode: DEFENCE_LICENSECODE,
    component: DefenceManagementContainer,
  },

  //방어 > 설정 > 시스템 > 상관분석
  DEFENSE_MANAGEMENT_CORRELATION: {
    link: getURI("DEFENSE_MANAGEMENT_CORRELATION"),
    licenseCode: DEFENCE_LICENSECODE,
    tabType: "SYSTEM",
    component: DefenceManagementContainer,
  },
  //방어 > 설정 > 시스템 > 시스템로그
  DEFENSE_MANAGEMENT_SYSTEMLOG: {
    link: getURI("DEFENSE_MANAGEMENT_SYSTEMLOG"),
    licenseCode: DEFENCE_LICENSECODE,
    tabType: "SYSTEM",
    component: DefenceManagementContainer,
  },

  //방어 > 설정 : 웹방화벽
  DEFENSE_MANAGEMENT_WEBFIREWALL: {
    link: getURI("DEFENSE_MANAGEMENT_WEBFIREWALL"),
    licenseCode: DEFENCE_LICENSECODE,
    component: DefenceManagementContainer,
  },

  //방어 > 설정 > 웹방화벽 > 계정탈취 차단
  DEFENSE_MANAGEMENT_EXTORTACCOUNTBLOCK: {
    link: getURI("DEFENSE_MANAGEMENT_EXTORTACCOUNTBLOCK"),
    licenseCode: DEFENCE_LICENSECODE,
    tabType: "WAF",
    component: DefenceManagementContainer,
  },

  //방어 > 설정 > 웹방화벽 > 계정탈취 제한
  DEFENSE_MANAGEMENT_EXTORTACCOUNTRESTRICTION: {
    link: getURI("DEFENSE_MANAGEMENT_EXTORTACCOUNTRESTRICTION"),
    licenseCode: DEFENCE_LICENSECODE,
    tabType: "WAF",
    component: DefenceManagementContainer,
  },

  //방어 > 설정 > 웹방화벽 > 데이터유출
  DEFENSE_MANAGEMENT_DATABREACH: {
    link: getURI("DEFENSE_MANAGEMENT_DATABREACH"),
    licenseCode: DEFENCE_LICENSECODE,
    tabType: "WAF",
    component: DefenceManagementContainer,
  },

  //방어 > 설정 > 웹방화벽 > OWASP 차단
  DEFENSE_MANAGEMENT_OWASP: {
    link: getURI("DEFENSE_MANAGEMENT_OWASP"),
    licenseCode: DEFENCE_LICENSECODE,
    tabType: "WAF",
    component: DefenceManagementContainer,
  },
  //방어 > 설정 > 웹방화벽 > 계정탈취
  // EXTORTACCOUNTDEFENCE: {
  //   link: getURI("EXTORTACCOUNTDEFENCE"),
  //   licenseCode: "defence",
  //   tabType: "WAF",
  //   component: DefenceManagementContainer,
  // },
  //방어 > 설정 > 웹로그
  // WEBLOGDEFENCE: {
  //   link: getURI("WEBLOGDEFENCE"),
  //   licenseCode: "defence",
  //   component: DefenceManagementContainer,
  // },
};
