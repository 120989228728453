import { observable, computed } from "mobx";

import { Common_Utils, SetterModel } from "@qubit-utill/src";

import { WAF_LICENSECODE } from "@src/constants/licenseCode";

export default class DetectInfoModel extends SetterModel {
  constructor(params, RoleLicenseStore) {
    super(params);
    this.useWaf = RoleLicenseStore && RoleLicenseStore.hasLicense(WAF_LICENSECODE);
  }

  // 웹/웹방화벽 > status > 정해진 inputBox 개수
  @observable
  inputLength: number = 5;

  // 웹방화벽
  @observable
  isWafDetect: string = "";

  @observable
  isWafDefense: string = "";

  @observable
  isWafStatusAll: string = "";

  @observable
  wafDetectStatusCodeList = [];

  @computed
  get isWafDetectFlag() {
    return Common_Utils.isCheckBool(this.isWafDetect);
  }

  @computed
  get isWafDefenseFlag() {
    return Common_Utils.isCheckBool(this.isWafDefense);
  }

  @computed
  get isWafStatusAllFlag() {
    return Common_Utils.isCheckBool(this.isWafStatusAll);
  }

  // 호스트보안
  @observable
  isEdrDetect: string = "";

  @observable
  isEdrDefense: string = "";

  @observable
  isEdrSeverityAll: string = "";

  @observable
  edrDetectSeverityList = [];

  @computed
  get isEdrDetectFlag() {
    return Common_Utils.isCheckBool(this.isEdrDetect);
  }

  @computed
  get isEdrDefenseFlag() {
    return Common_Utils.isCheckBool(this.isEdrDefense);
  }

  @computed
  get isEdrSeverityAllFlag() {
    return Common_Utils.isCheckBool(this.isEdrSeverityAll);
  }

  // 마이터 어택
  @observable
  isMitreDetect: string = "";

  //TODO 방어 추후적용
  @observable
  isMitreDefense = null;

  @computed
  get isMitreDetectFlag() {
    return Common_Utils.isCheckBool(this.isMitreDetect);
  }

  @computed
  get isMitreDefenseFlag() {
    //TODO 방어 추후적용
    // return Common_Utils.isCheckBool(this.isMitreDefense);
    return null;
  }

  // 상관분석
  @observable
  isPackageDetect: string = "";

  @observable
  isPackageDefense: string = "";

  @computed
  get isPackageDetectFlag() {
    return Common_Utils.isCheckBool(this.isPackageDetect);
  }

  @computed
  get isPackageDefenseFlag() {
    return Common_Utils.isCheckBool(this.isPackageDefense);
  }

  // 데이터유출
  @observable
  isWebExtendsDataBreachDetect: string = "";

  @observable
  isWebExtendsDataBreachDefense: string = "";

  @observable
  useWaf: boolean = false;

  @computed
  get isWebExtendsDataBreachDetectFlag() {
    return Common_Utils.isCheckBool(this.isWebExtendsDataBreachDetect);
  }

  @computed
  get isWebExtendsDataBreachDefenseFlag() {
    return this.useWaf ? Common_Utils.isCheckBool(this.isWebExtendsDataBreachDefense) : null;
  }

  @computed
  get isWebStatusAllFlag() {
    return Common_Utils.isCheckBool(this.isWebStatusAll);
  }

  // 계정탈취
  @observable
  isDdosDetect: string = "";

  @observable
  isDdosDefense: string = "";

  @computed
  get isDdosDetectFlag() {
    return Common_Utils.isCheckBool(this.isDdosDetect);
  }

  @computed
  get isDdosDefenseFlag() {
    return this.useWaf ? Common_Utils.isCheckBool(this.isDdosDefense) : null;
  }

  // 시스템
  @observable
  isSystemDetect: string = "";

  @observable
  isSystemDefense: string = "";

  @observable
  isSystemSeverityAll: string = "";

  @observable
  syslogDetectSeverityList = [];

  @computed
  get isSystemDetectFlag() {
    return Common_Utils.isCheckBool(this.isSystemDetect);
  }

  @computed
  get isSystemDefenseFlag() {
    return Common_Utils.isCheckBool(this.isSystemDefense);
  }

  @computed
  get isSystemSeverityAllFlag() {
    return Common_Utils.isCheckBool(this.isSystemSeverityAll);
  }

  // 응용프로그램
  @observable
  isApplicationDetect: string = "";

  @computed
  get isApplicationDetectFlag() {
    return Common_Utils.isCheckBool(this.isApplicationDetect);
  }

  // 웹
  @observable
  isWebDetect: string = "";

  @observable
  isWebDefense: string = "";

  @observable
  isWebStatusAll: string = "";

  @observable
  webDetectStatusCodeList = [];

  @computed
  get isWebDetectFlag() {
    return Common_Utils.isCheckBool(this.isWebDetect);
  }

  @computed
  get isWebDefenseFlag() {
    // return Common_Utils.isCheckBool(this.isWebDefense);
    return null;
  }

  // 네트워크
  @observable
  isNetworkDetect: string = "";

  @observable
  isNetworkDefense: string = "";

  @observable
  isNetworkSeverityAll: string = "";

  @observable
  netlogDetectSeverityList = [];

  @computed
  get isNetworkDetectFlag() {
    return Common_Utils.isCheckBool(this.isNetworkDetect);
  }

  @computed
  get isNetworkDefenseFlag() {
    // return Common_Utils.isCheckBool(this.isNetworkDefense);
    return null;
  }

  @computed
  get isNetworkSeverityAllFlag() {
    return Common_Utils.isCheckBool(this.isNetworkSeverityAll);
  }

  //기타 - 리소스모니터링
  @observable
  isResourceMonitorDetect: string = "";

  @computed
  get isResourceMonitorDetectFlag() {
    return Common_Utils.isCheckBool(this.isResourceMonitorDetect);
  }
}
