import { axiosModules } from "@qubit-utill/src";

export default {
  getDosFilterTypeList: () => axiosModules.get(`/pfilter/dosfiltertype`),
  getDosFilterList: (params) => axiosModules.get(`/pfilter/dosfilter`, params),
  getDosFilter: (dosFilterId) => axiosModules.get(`/pfilter/dosfilter/${dosFilterId}`),
  createDosFilter: (data) => axiosModules.post(`/pfilter/dosfilter`, data),
  deleteDosFilterInfo: (dosFilterId) => axiosModules.del(`/pfilter/dosfilter/${dosFilterId}`),
  updateDosFilter: (dosFilterId, data) => axiosModules.put(`/pfilter/dosfilter/${dosFilterId}`, data),
  updateIsUseDosFilter: (dosFilterId, data) => axiosModules.put(`/pfilter/dosfilterisuse/${dosFilterId}`, data),

  // pfilter > filters > 필터 자동완성 검색용 > DoS 필터 목록 - 자동완성용
  getDosFilterListForAutoComplete: (dosFilterType?) =>
    axiosModules.get("/pfilter/dosfilter/simple", { limit: 9999, dosFilterType }),
};
