import React from "react";
import { Col } from "reactstrap";
import { observer } from "mobx-react";

import { Worldmap } from "@src/common/components/organisms/Graphs/ECharts";
import { WorldmapGrapType } from "@src/type/DashboardType";

import IPAnalysisReportTable from "@src/pages/Report/templates/ReportContent/IPAnalysisReportTable";

interface Props {
  chartData: WorldmapGrapType[];
  chartList: any[];
  fileName: string;
}

const IPReportMapGraph: React.SFC<Props> = ({ chartData, chartList, fileName }) => {
  return (
    <>
      <Col md="7" className="min_h250 p-15">
        <Worldmap data={chartData} />
      </Col>
      <Col md="5" className="min_h250 p-15">
        <div
          className="y_auto line_duo"
          style={{
            maxHeight: "250px",
          }}
        >
          <IPAnalysisReportTable chartList={chartList} />
        </div>
      </Col>
    </>
  );
};

export default observer(IPReportMapGraph);
