import makeDecorator from "./makeDecorator";

function value(target, loadingProp, method) {
  return async (...args) => {
    if (!target[loadingProp]) {
      try {
        target[loadingProp] = true;
        return await method.apply(target, args);
      } finally {
        target[loadingProp] = false;
      }
    }
  };
}

export default makeDecorator(value);
