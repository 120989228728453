import { languageSet } from "@qubit-utill/src";
import getURI from "../uriset";
import { QnaContainer, QnaSetContainer, QnaDetailContainer } from "../../pages";

export default {
  //Q&A
  QNA: {
    link: getURI("QNA"),
    withPageLink: `${getURI("QNA")}/page/:page`,
    component: QnaContainer,
  },
  QNA_DETAIL: {
    link: `${getURI("QNA_DETAIL")}/:qnaId`,
    title: languageSet("QnA_상세"),
    isDetail: true,
    component: QnaDetailContainer,
  },
  QNA_REGIST: {
    link: getURI("QNA_REGIST"),
    title: languageSet("QnA_등록"),
    isEdit: false,
    component: QnaSetContainer,
  },
  QNA_EDIT: {
    link: `${getURI("QNA_EDIT")}/:qnaId`,
    title: languageSet("QnA_수정"),
    isEdit: true,
    component: QnaSetContainer,
  },
};
