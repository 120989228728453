import { action } from "mobx";
import { pCustomerApis } from "@src/apis";

class UserCustomStore {
  @action
  getUserCustomProtocol = async (webInfo) => {
    let params = {
      userCustomPreferencesType: "URLSCAN",
      userCustomPreferencesItem: webInfo.replayAttackIp,
    };

    try {
      const { info } = await pCustomerApis.getUserCustomPreferences(params);
      const { userCustomPreferencesValueList } = info;

      userCustomPreferencesValueList.forEach((data) => {
        if (data.userCustomPreferencesKey === "protocol") {
          webInfo.setInfo("protocol", data.userCustomPreferencesValue ? data.userCustomPreferencesValue : "https");
        }
        if (data.userCustomPreferencesKey === "port") {
          webInfo.setInfo("port", data.userCustomPreferencesValue ? data.userCustomPreferencesValue : "443");
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  @action
  createUserCustomProtocol = async (hostIp, protocol, port) => {
    const list = [
      {
        userCustomPreferencesKey: "protocol",
        userCustomPreferencesValue: protocol,
      },
      {
        userCustomPreferencesKey: "port",
        userCustomPreferencesValue: port,
      },
    ];

    try {
      await pCustomerApis.createUserCustomPreferences({
        userCustomPreferencesType: "URLSCAN",
        userCustomPreferencesItem: hostIp,
        userCustomPreferencesValueList: list,
      });
    } catch (error) {}
  };
}

export default new UserCustomStore();
