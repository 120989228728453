import { observable } from "mobx";

export default class EdrEventElementOptionModel {
  constructor(param, type?) {
    const filterElementName = param.edrDefenseType;
    this.name = filterElementName;

    if (type === "order") {
      this.value = param.edrDefenseTypeOrderList;
    } else {
      this.value = {
        filterElementName,
      };
    }
  }

  @observable
  name = "";

  @observable
  value = {
    filterElementName: "",
  };
}
