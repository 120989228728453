import { observable } from "mobx";

import { SetterModel } from "@qubit-utill/src";

export default class AllowIpAddressModel extends SetterModel {
  @observable
  allowIpId: number = 0;

  @observable
  serverId: number = 0;

  @observable
  allowIp: string = "";

  @observable
  description: string = "";

  @observable
  registerUserName: string = "";
}
