import { observable } from "mobx";

import { SetterModel } from "@qubit-utill/src";

export default class LogCountByChannelDayModel extends SetterModel {
  @observable
  logUploadDate: string = "";

  @observable
  logUploadCount: number = 0;

  @observable
  logUploadBytes: number = 0;

  @observable
  logUploadYear: number = 0;

  @observable
  logUploadWeek: number = 0;

  @observable
  logUploadDayOfWeek: number = 0;
}
