import React from "react";
import createContext from "./createContext";
import { useObserver, useLocalStore } from "mobx-react";
import { useMobxStore, useInjectStore } from "../hooks";

export default function<TStore>(
  storeConstructor,
  name: string
): [storeContext, storeProvider, useStore] {
  const storeContext = createContext<TStore | null>(null, `${name}Context`);

  const storeProvider = ({ children }: Element) => {
    const { RootStore } = useInjectStore();
    let root = RootStore ? RootStore : null;
    const store: TStore = useLocalStore(() => new storeConstructor(root));
    return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
  };

  // userStore의 값을 가져오는 hooks
  const useStore = () => {
    const store = useMobxStore(storeContext);

    if (!store) {
      throw `${name}Store가 없습니다`;
    }

    return useObserver<TStore>(() => store);
  };

  return [storeContext, storeProvider, useStore];
}
