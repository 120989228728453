const getURI = function(key) {
  const links = {
    INDEX: "/",
    LOGIN: "/login",
    TERMS_PERSONAL: "/terms/privacy",
    TERMS_USE: "/terms/ofservice",
    ERROR_FREETOPREMIUM: "/free"
  }

  if (links[key]) {
    return links[key]
  } else {
    return key
  }
}

export default getURI
