import { axiosModules } from "@qubit-utill/src";

export default {
  // ===================== 일 정보 =====================
  // 보고서 > 호스트보안 > 일 정보 > 일 분류 그룹 가져오기
  getFilterCategoryGroupDayList: (params) => {
    return axiosModules.get("/pstats/edrdetect/day/categorygroup", params);
  },

  // 보고서 > 호스트보안 > 일 정보 > 일 탐지 메인 차트 정보 가져오기
  getHostSecurityDayMainChart: (params) => {
    return axiosModules.get("/pstats/edrdetect/day/mainchart", params);
  },

  // 보고서 > 호스트보안 > 일 정보 > 일 탐지 선택 일자 시간대별 정보 가져오기
  getHostSecurityDaySubChart: (params) => {
    return axiosModules.get("/pstats/edrdetect/day/subchart", params);
  },

  // 보고서 > 호스트보안 > 일 정보 > 일 탐지 메인 목록 가져오기
  getHostSecurityDayMainDetect: (params) => {
    return axiosModules.get("/pstats/edrdetect/day/main", params);
  },

  // 보고서 > 호스트보안 > 일 정보 > 일 필터기준 탐지 랭크 목록 조회
  getHostSecurityDayFilterRankDetect: (params) => {
    return axiosModules.get("/pstats/edrdetect/day/filterrank", params);
  },

  // ===================== 주 정보 =====================
  // 보고서 > 호스트보안 > 주 정보 > 주 탐지 메인 차트 정보 가져오기
  getHostSecurityWeekMainChart: (params) => {
    return axiosModules.get("/pstats/edrdetect/week/mainchart", params);
  },

  // 보고서 > 호스트보안 > 주 정보 > 주 탐지 서브 차트 정보 가져오기
  getHostSecurityWeekSubChart: (params) => {
    return axiosModules.get("/pstats/edrdetect/week/subchart", params);
  },

  // 보고서 > 호스트보안 > 주 정보 > 주 탐지 메인 목록 가져오기
  getHostSecurityWeekMainDetect: (params) => {
    return axiosModules.get("/pstats/edrdetect/week/main", params);
  },

  // 보고서 > 호스트보안 > 주 정보 > 주 필터기준 탐지 랭크 목록 조회
  getHostSecurityWeekFilterRankDetect: (params) => {
    return axiosModules.get("/pstats/edrdetect/week/filterrank", params);
  },

  // ===================== 월 정보 =====================
  // 보고서 > 호스트보안 > 월 정보 > 월 탐지 메인 차트 정보 가져오기
  getHostSecurityMonthMainChart: (params) => {
    return axiosModules.get("/pstats/edrdetect/month/mainchart", params);
  },

  // 보고서 > 호스트보안 > 월 정보 > 월 탐지 메인 목록 가져오기
  getHostSecurityMonthMainDetect: (params) => {
    return axiosModules.get("/pstats/edrdetect/month/main", params);
  },

  // 보고서 > 호스트보안 > 월 정보 > 월 필터기준 탐지 랭크 목록 조회
  getHostSecurityMonthFilterRankDetect: (params) => {
    return axiosModules.get("/pstats/edrdetect/month/filterrank", params);
  },
};
