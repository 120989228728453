import { axiosModules } from "@qubit-utill/src";

export default {
  getWafConfigList: () => axiosModules.get(`/pserver/wafconfig/main`),
  getWafConfig: (wafConfigId) => axiosModules.get(`/pserver/wafconfig/${wafConfigId}`),
  createWafConfig: (serverGroupId) => axiosModules.post(`/pserver/wafconfig/init`, { serverGroupId }),
  updateWafConfig: (wafConfigId, data) => axiosModules.put(`/pserver/wafconfig/${wafConfigId}`, data),
  deleteWafConfig: (wafConfigId) => axiosModules.del(`/pserver/wafconfig/${wafConfigId}`),
  updateWafSync: (wafConfigId) => axiosModules.put(`/pserver/wafconfig/syncwaf/${wafConfigId}`),
  updateWafProxyConfig: (wafConfigId, data) => axiosModules.put(`/pserver/wafconfig/wafconfigproxy/${wafConfigId}`, data),
  updateWafHealthConfig: (wafConfigId, data) =>
    axiosModules.put(`/pserver/wafconfig/wafconfighealthchecker/${wafConfigId}`, data),
};
