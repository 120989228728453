import React from "react";

import { FilterDropdown } from "qubit-utill/src";

import { LIMIT_OPTION } from "@src/constants/code";

const LimitSelector = ({ ReportStore }) => {
  return (
    <FilterDropdown
      isUseTotal={false}
      titleClass="c-blue bold"
      rigthClass="verti_top"
      onSelect={(value) => {
        ReportStore.setListParams("limit", value);
        ReportStore.getTableList(ReportStore.params);
      }}
      defaultValue={{ name: ReportStore.params.limit, value: ReportStore.params.limit }}
      items={LIMIT_OPTION}
    />
  );
};

export default LimitSelector;
