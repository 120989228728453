import { observable, action } from "mobx";

import { pcustomerApis } from "@qubit-utill/src/apis";

//백업 설정관련 Store
class LogBackupStore {
  constructor(root) {
    this.root = root;
  }
  root;

  //license code: enterprise계정은 온프라미스로 간주하고 role 및 백업설정 체크 안함.
  static RESTOREPASS_LICENSECODE: string = "restorepass";
  //restore를 사용 할 수 있는 계정 권한(관리자, 복원사용자)
  static RESTORE_ROLECODE: string = "restore";

  @observable
  logBackupInfo: any = {};

  @observable
  isApiCall: boolean = false;

  @action
  checkRestoreAccess = async (roleCodeCheck: boolean = false) => {
    //온프라미스 사용자의 경우 무조건 통과
    const isRestorePass: boolean = this.root.RoleLicenseStore.hasLicense(
      LogBackupStore.RESTOREPASS_LICENSECODE
    );
    if (isRestorePass) return true;

    //roleCode값 있으면 권한 체크
    if (roleCodeCheck) {
      const userRoles = this.root.RoleLicenseStore.hasRole(LogBackupStore.RESTORE_ROLECODE);
      if (!userRoles.isAction) return false;
    }

    try {
      await this.getLogBackupInfo();
      if (this.logBackupInfo.isUse !== "1" || this.logBackupInfo.isBackupConfig !== "1") {
        return false;
      }
    } catch (e) {
      console.log("checkRestoreAccess Error: ", e);
      return false;
    }

    return true;
  };

  @action
  getLogBackupInfo = async () => {
    try {
      if (!this.isApiCall) {
        const { info } = await pcustomerApis.getLogBackupInfo();
        this.logBackupInfo = info;
        this.isApiCall = true;
      }
    } catch (e) {
      console.error("getLogBackupInfo Error: ", e);
    }
  };
}

export default LogBackupStore;
