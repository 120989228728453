import React, { useMemo } from "react";
import { Col } from "reactstrap";
import { observer } from "mobx-react";
import { Common_Utils } from "@qubit-utill/src";

interface Props {
  title?: string;
  isBlackBorder?: Boolean;
  totalCount?: number;
  heightClass?: string;
  isWeekBorder?: Boolean;
  className?: string;
}

const ReportBox: React.FC<Props> = ({
  title,
  children,
  isBlackBorder,
  totalCount,
  heightClass = "min-h250",
  isWeekBorder,
  className = "m-b-20",
}) => {
  const totalCountFlag = totalCount === 0 ? Boolean(String(totalCount)) : Boolean(totalCount);
  const borderClass = useMemo(() => {
    if (isWeekBorder) return "report_box_week";
    if (isBlackBorder) return "report_box";
    return "report_web_box";
  }, [isWeekBorder, isBlackBorder]);

  return (
    <Col md="12" className={`p-l-0 ${className} p-r-0 ${borderClass}`}>
      <Col md="12" className={`${heightClass} p-15`}>
        {Boolean(title) && (
          <p className="m-10 p-l-5 c-black bold">
            <span>{title}</span>
            {totalCountFlag && (
              <>
                <span className="p-lr-3">:</span>
                <span className="c-red">{Common_Utils.numberWithComma(totalCount)}</span>
              </>
            )}
          </p>
        )}

        {children}
      </Col>
    </Col>
  );
};

export default observer(ReportBox);
