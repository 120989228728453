import { axiosModules } from "@qubit-utill/src";

export default {
  getTicketOption: () => axiosModules.get("/pticket/ticketsystem"),
  getTicketParameterOption: (params) => axiosModules.get("/pticket/ticketsystemparameters", params),
  getTicketInfo: () => axiosModules.get("/pticket/userticketsystem"),
  updateTicket: (data) => {
    const { isUseFlag, isUse, ticketSystemId, version, ticketSystemParameterList, isAttachLog } = data;
    let parameterList = [];
    ticketSystemParameterList.forEach(({ ticketSystemParameterId, parameterValue, parameterName }) => {
      if (parameterValue) {
        parameterList.push({
          ticketSystemParameterId,
          parameterValue,
          parameterName,
        });
      }
    });
    const sendData = isUseFlag
      ? {
          isUse,
          ticketSystemId,
          version,
          ticketSystemParameterList: parameterList,
          isAttachLog,
        }
      : {
          isUse,
        };
    return axiosModules.put("/pticket/userticketsystem", sendData);
  },
  //티켓발행정보
  getPrintTicketInfo: (issueServiceType, issuePrimaryKey) =>
    axiosModules.get("/pticket/userticketpublish", {
      issueServiceType,
      issuePrimaryKey,
    }),
  //티켓발행
  createPrintTicketInfo: (data) => axiosModules.post("/pticket/userticketpublish", data),
  //티켓테스트 발행
  createTestPrintTicket: () => axiosModules.post("/pticket/userticketpublish/test"),
  getTicketPrintList: (params) => axiosModules.get("/pticket/userticketpublish/list", params),
};
