import { axiosModules } from "@qubit-utill/src";

export default {
  // pserver > pserver > 서버관리 > 로그 태그 > 로그 태그 조회
  getLogTagInfo: (logTag) => {
    return axiosModules.get(`/pserver/logtag/${logTag}`);
  },

  // pserver > pserver > 서버관리 > 로그 태그 > 로그 태그 목록 조회
  getLogTagList: (params) => {
    return axiosModules.get("/pserver/logtags", params);
  },

  // pserver > pserver > 서버관리 > 로그 태그 > 로그 태그 생성
  createLogTag: (data) => {
    return axiosModules.post("/pserver/logtag", data);
  },

  // pserver > pserver > 서버관리 > 로그 태그 > 로그 태그 삭제
  deleteLogTag: (logTag) => {
    return axiosModules.del("/pserver/logtag", logTag);
  },
};
