import React, { Component } from "react";
import { observable } from "mobx";
import { observer } from "mobx-react";
import propTypes from "prop-types";

@observer
class SwitchBox extends Component {
  @observable
  isChecked = false;

  toggle = e => {
    e.stopPropagation();
    e.preventDefault();
    const { onToggle, disabled } = this.props;
    if (!disabled) {
      this.isChecked = !this.isChecked;
      onToggle && onToggle();
    }
  };
  render() {
    const { disabled, labelClassName, labelSubClassName } = this.props;
    const _isChecked = this.props.isChecked !== undefined ? this.props.isChecked : this.isChecked;
    const cursorStyle = disabled ? { cursor: "initial" } : {};
    return (
      <label
        className={`switch ${labelClassName} ${labelSubClassName}`}
        onClick={this.toggle}
        style={cursorStyle}
      >
        <input type="checkbox" className="switch-input" checked={_isChecked} readOnly />
        <span className="switch-label" data-on="On" data-off="Off" />
        <span className="switch-handle" />
      </label>
    );
  }
}

SwitchBox.propTypes = {
  disabled: propTypes.bool,
  onToggle: propTypes.func,
  isChecked: propTypes.bool,
  labelClassName: propTypes.string,
  labelSubClassName: propTypes.string,
};

SwitchBox.defaultProps = {
  labelClassName: "m-t-0",
  labelSubClassName: "",
};

export default SwitchBox;
