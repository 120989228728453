import { axiosModules } from "@qubit-utill/src";

export default {
  //일별 분류 리스트
  getCategoryGroupDayList: (reportType, param) => {
    return axiosModules.get(`/pstats/${reportType}/day/categorygroup`, param);
  },
  //일별 메인 차트(처음 진입 통합API)
  getDayMainChart: (reportType, param) => {
    return axiosModules.get(`/pstats/${reportType}/day/mainchart`, param);
  },
  //일별 서브 차트
  getDaySubChart: (reportType, param) => {
    return axiosModules.get(`/pstats/${reportType}/day/subchart`, param);
  },
  //일별 분류 리스트
  getDayTopList: (reportType, param) => {
    return axiosModules.get(`/pstats/${reportType}/day/main`, param);
  },
  //주별 통합
  getWeeklyMainChart: (reportType, param) => axiosModules.get(`/pstats/${reportType}/week/mainchart`, param),
  //주별 서브
  getWeekSubChart: (reportType, param) => axiosModules.get(`/pstats/${reportType}/week/subchart`, param),
  //주별 TOP 리스트
  getWeekTopListApi: (reportType, param) => axiosModules.get(`/pstats/${reportType}/week/main`, param),
  //월별 통합
  getMonthMainChart: (reportType, param) => axiosModules.get(`/pstats/${reportType}/month/mainchart`, param),
  //월별 TOP 리스트
  getMonthTopListApi: (reportType, param) => axiosModules.get(`/pstats/${reportType}/month/main`, param),
};
