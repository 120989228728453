import React from "react"

const OnOff = ({ isOn, className }) => {
  if (isOn) {
    return <span className={`${className} c-blue`}>ON</span>
  } else {
    return <span className={`${className} c-red`}>OFF</span>
  }
}

export default OnOff
