import { observable, computed } from "mobx";

export default class FilterCategoryModel {
  constructor(params) {
    this.filterCategoryId = params.filterCategoryId || 1;
    this.filterCategoryType = params.filterCategoryType || "filter";
    this.filterCategoryName = params.filterCategoryName || "";
    this.filterCategoryOrderNo = params.filterCategoryOrderNo || 1;
  }

  @computed
  get name() {
    return this.filterCategoryName;
  }

  @computed
  get value() {
    return this.filterCategoryId;
  }

  @observable
  filterCategoryId: number;

  @observable
  filterCategoryType: string;

  @observable
  filterCategoryName: string;

  @observable
  filterCategoryOrderNo: number;
}
