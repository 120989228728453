import React from "react";

const DownloadIcon: React.FC<{ text: string }> = ({ text }) => {
  return (
    <>
      <i className="fa fa-download p-r-5" />
      <span className="bold">{text}</span>
    </>
  );
};

export default DownloadIcon;
