import { languageSet } from "@qubit-utill/src";

import getURI from "@src/constants/uriset";
import { WAF_LICENSECODE, TOTALLOG_LICENSECODE, WEB_LICENSECODE } from "@src/constants/licenseCode";

import {
  ApplicationDefaultTotalLogContainer,
  ApplicationResourceTotalLogContainer,
  SystemTotalLogContainer,
  WebTotalLogContainer,
  NetworkTotalLogContainer,
  WebFirewallTotalLogContainer,
  HostSecurityTotalLogContainer,
} from "@src/pages";

export default {
  //전체로그
  TOTALLOG_SYS: {
    title: languageSet("시스템"),
    text: languageSet("시스템"),
    link: getURI("TOTALLOG_SYS"),
    isUseCursorMark: true,
    licenseCode: TOTALLOG_LICENSECODE,
    component: SystemTotalLogContainer,
  },
  TOTALLOG_HOSTSECURITY: {
    title: languageSet("호스트보안"),
    text: languageSet("호스트보안"),
    link: getURI("TOTALLOG_HOSTSECURITY"),
    isUseCursorMark: true,
    licenseCode: TOTALLOG_LICENSECODE,
    component: HostSecurityTotalLogContainer,
  },
  TOTALLOG_APP: {
    text: languageSet("응용프로그램"),
    isUseCursorMark: true,
    childList: [
      {
        title: languageSet("원본"),
        text: languageSet("원본"),
        link: getURI("TOTALLOG_APP_DEFAULT"),
        isUseCursorMark: true,
        component: ApplicationDefaultTotalLogContainer,
      },
      {
        title: languageSet("사용자정의"),
        text: languageSet("사용자정의"),
        link: getURI("TOTALLOG_APP_RESOURCE"),
        isUseCursorMark: true,
        component: ApplicationResourceTotalLogContainer,
      },
    ],
  },
  TOTALLOG_WEB: {
    title: languageSet("웹"),
    text: languageSet("웹"),
    link: getURI("TOTALLOG_WEB"),
    isUseCursorMark: true,
    licenseCode: WEB_LICENSECODE,
    component: WebTotalLogContainer,
  },
  TOTALLOG_NET: {
    title: languageSet("네트워크"),
    text: languageSet("네트워크"),
    link: getURI("TOTALLOG_NET"),
    isUseCursorMark: true,
    licenseCode: TOTALLOG_LICENSECODE,
    component: NetworkTotalLogContainer,
  },
  TOTALLOG_WEBFIREWALL: {
    title: languageSet("웹방화벽"),
    text: languageSet("웹방화벽"),
    link: getURI("TOTALLOG_WEBFIREWALL"),
    isUseCursorMark: true,
    licenseCode: WAF_LICENSECODE,
    component: WebFirewallTotalLogContainer,
  },

  //응용프로그램 원본
  TOTALLOG_APP_DEFAULT: {
    title: languageSet("응용프로그램"),
    text: languageSet("응용프로그램"),
    link: getURI("TOTALLOG_APP_DEFAULT"),
    isUseCursorMark: true,
    component: ApplicationDefaultTotalLogContainer,
  },
  //응용프로그램 사용자정의
  TOTALLOG_APP_RESOURCE: {
    title: languageSet("응용프로그램"),
    text: languageSet("응용프로그램"),
    link: getURI("TOTALLOG_APP_RESOURCE"),
    isUseCursorMark: true,
    component: ApplicationResourceTotalLogContainer,
  },
};
