import { axiosModules } from "@qubit-utill/src";

export default {
  getUserFilterConfig: (serviceFilterType) => {
    return axiosModules.get(`/pfilter/userfilterconfig/${serviceFilterType}`);
  },

  updateUserFilterConfig: (data) => {
    return axiosModules.put("/pfilter/userfilterconfig", data);
  },
};
