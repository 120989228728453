import { observable, computed } from "mobx";
import { Common_Utils, SetterModel } from "@qubit-utill/src";

export default class WebDetectExceptEntityModel extends SetterModel {
  @computed
  get isRegexFlag() {
    return Common_Utils.isCheckBool(this.isRegex);
  }

  @observable
  webExceptCustomEntryId: number = 0;
  @observable
  webExceptCustomEntryType: string = "";
  @observable
  webExceptCustomEntryValue: string = "";
  @observable
  isRegex: string = "0";
}
