import { observable, computed } from "mobx";
import { SetterModel, Common_Utils } from "@qubit-utill/src";

class UserHostListModel extends SetterModel {
  constructor(params, CodeStore) {
    super(params);
    this.CodeStore = CodeStore;
    if (params) {
      this.isRegEx = Common_Utils.isCheckBool(params.isRegEx);
    }
  }
  @observable
  CodeStore;

  @computed
  get filterElementReferenceTypeText() {
    return this.CodeStore.getCodeName("filterElementReferenceType", this.filterElementReferenceType);
  }

  @computed
  get isProtectedGroup() {
    return Common_Utils.isCheckBool(this.isProtected);
  }

  @computed
  get isUseCustomGroup() {
    return Common_Utils.isCheckBool(this.isUseCustom);
  }

  @computed
  get filterElementReferenceKindText() {
    return this.CodeStore && this.CodeStore.getCodeName("filterElementReferenceKind", this.filterElementReferenceKind);
  }

  @computed
  get getRegisterUserName() {
    return Common_Utils.isDataWithDashBar(this.registerUserName);
  }

  @observable
  userHostId: number = 0;

  @observable
  host: string = "";

  @observable
  userHostReferenceCount: number = 0;

  @observable
  registerDate = ""; //등록일

  @observable
  registerUserName = ""; //등록자

  @observable
  ipList = []; //그룹내 IP리스트

  @observable
  filterElementReferenceOrderNo = 0;

  @observable
  isSelected = false;

  @observable
  filterElementReferenceType = "";

  @observable
  filterElementReferenceDataValue = "";

  @observable
  isRegEx = false;

  @observable
  isProtected = "0";

  @observable
  isUseCustom = "1";

  @observable
  filterElementReferenceKind = "";
}

export default UserHostListModel;
