import { axiosModules } from "@qubit-utill/src";

export default {
  getWebExceptFilterList: (params) => {
    return axiosModules.get(`/pfilter/webexceptfilter`, params);
  },
  createWebExceptFilter: (data) => {
    return axiosModules.post(`/pfilter/webexceptfilter`, data);
  },
  // filters > 웹탐지 예외 > 웹탐지 엔트리 > 웹탐지 예외 엔트리 목록(그룹관리용)
  getWebExceptCustomEntryInfoList: (params?) => {
    return axiosModules.get(`/pfilter/webexceptcustomentrylist?orderDesc=ASC`, params);
  },
  // filters > 웹탐지 예외 > 웹탐지 엔트리 > 웹 예외 필터 등록
  createWebExceptCustomEntry: (data) => {
    return axiosModules.post(`/pfilter/webexceptcustomentry`, data);
  },
  // filters > 웹탐지 예외 > 계정탈취 탐지 예외 > 계정탈취 예외 그룹 리스트
  getWebExtortAccountExceptList: () => {
    return axiosModules.get("/pfilter/dosexceptcustomgroup/list");
  },
  // filters > 웹탐지 예외 > 계정탈취 탐지 예외 > 계정탈취 예외 생성,수정,삭제
  createWebExtortAccountExcept: (data) => {
    return axiosModules.post("/pfilter/dosexceptcustomgroup", data);
  },
};
