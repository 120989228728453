import { languageSet } from "@qubit-utill/src";

import getURI from "@src/constants/uriset";
import { ALARM_LICENSECODE } from "@src/constants/licenseCode";
import TabMenusType from "@src/type/TabMenusType";

import { AlarmProvidorContainer } from "@src/pages";

export default {
  ALARM: {
    link: getURI("ALARM"),
    title: languageSet("개인알림"),
    tabMenus: [
      {
        to: getURI("ALARM"),
        name: "HOME",
        iconId: "icon-home",
      },
      {
        to: getURI("ALARM_BASIC"),
        name: languageSet("기본"),
        iconId: "icon-pencil",
      },
      {
        to: getURI("ALARM_DETECT"),
        name: languageSet("탐지_방어"),
        iconId: "glyphicon glyphicon-screenshot",
      },
      {
        to: getURI("ALARM_ETC"),
        name: languageSet("기타"),
        iconId: "fa fa-asterisk",
      },
      //레드마인1885: 잠시 히든처리 { to: getURI("ALARM_REPORT"), name: languageSet("보고서"), iconId: "icon-note" },
    ] as TabMenusType[],
    licenseCode: ALARM_LICENSECODE,
    component: AlarmProvidorContainer,
  },
};
