import * as React from "react";
import { observer } from "mobx-react";
import { Row, Col } from "reactstrap";

import { ContentLayout, PartialLoadingWrapper, useInjectStore, ThFieldsConfig, useGetData, Common_Utils } from "@qubit-utill/src";

import { pSearchApis } from "@src/apis";
import LinkParamsType from "@src/type/LinkParamsType";
import { MainChart, SubChart, WeekChart, SearchOption } from "@src/pages/Report/templates/wrapper";
import { ReportBox, Top10Table } from "@src/pages/Report/templates/ReportContent";

interface ComponentProps {
  title: string;
  TrComponent?: any;
  isUseTermTab?: boolean;
  isUseClassification?: boolean;
  isBlackBorder?: boolean;
  isUseSubGraph?: boolean;
  isUseList?: boolean;
  isWeb?: boolean;
  useSubPieChart?: boolean;
  heightClass?: string;
  graphComponent?: any;
  graphComponentProps?: Function;
  customDateComponent?: JSX.Element;
  classificationTitle?: string;
  isUseServiceButton?: boolean;
  listTitle?: string;
  customOption?: any;
  isUseGraphButton?: boolean;
  isIP?: boolean;
  OptionTemplate?: any;
  isUseListTitle?: boolean;
  linkParams?: LinkParamsType;
  isPagination?: boolean;
  customSubChart?: typeof React.Component | React.FC | React.ElementType;
  additionalInfo?: typeof React.Component | React.FC;
  thFields?: ThFieldsConfig;
  valueFormater?: Function;
  tableSelectBox?: typeof React.Component | React.FC;
  subPieChart?: {
    title: string;
    fileName: string;
  };
  isUseSearchListFilter?: boolean;
  paramName?: string;
  isUseCustomListFilter?: boolean;
  CustomListFilter?: any;
  emptyMessage?: string;
  isAlawaysUseClassification?: boolean;
  isUseTab?: boolean;
  defaultTab?: string;
  newTabComponent?: any;
  newTabComponentProps?: any;
  newTabTitle?: string;
  isUpload?: boolean;
  top10ReportTabs?: any[];
  customUserActionLogCode?: {
    categoryGroupType: string;
    categoryType: string;
  };
  borderClass?: string;
  pieGraphOption?: any;
  customSubGraphOption?: any;
  useSubChartOnClick?: boolean;
}

const ReportContainer: React.FC<ComponentProps> = ({
  thFields,
  title,
  isUseClassification,
  TrComponent,
  isIP,
  customDateComponent,
  isUseTermTab = true,
  isUseServiceButton,
  heightClass,
  graphComponent,
  graphComponentProps,
  isUseGraphButton,
  isUseList,
  isUseSubGraph,
  isBlackBorder,
  OptionTemplate,
  classificationTitle,
  listTitle,
  isUseListTitle,
  linkParams,
  isWeb,
  useSubPieChart,
  customOption,
  isPagination,
  customSubChart,
  additionalInfo: AdditionalInfo,
  valueFormater,
  tableSelectBox,
  subPieChart,
  isUseSearchListFilter = true,
  paramName,
  isUseCustomListFilter,
  CustomListFilter,
  emptyMessage,
  isAlawaysUseClassification,
  isUseTab,
  defaultTab,
  newTabComponent,
  newTabComponentProps,
  newTabTitle,
  isUpload = false,
  top10ReportTabs,
  customUserActionLogCode,
  borderClass,
  pieGraphOption,
  customSubGraphOption,
  useSubChartOnClick,
}) => {
  const { ReportStore, SearchListFilterStore } = useInjectStore();
  const { termsTab, getData, isLoading, isSubLoading, clear, csvInfoKey } = ReportStore;

  const _getData = async () => {
    ReportStore.isLoading = false;
    // isUseClassification && (await getCategoryTab());
    SearchListFilterStore.getSearchMenuList(pSearchApis.getSearchMenuListFixReport)();
    getData();
  };

  useGetData(_getData, clear);

  const isWeeklyChart = React.useMemo(() => termsTab === "week" && !isIP && !graphComponent, [termsTab, isIP, graphComponent]);
  const isResourceWeekMonthFlag = csvInfoKey === "REPORT_APP_RESOURCE" && (termsTab === "week" || termsTab === "month");

  const currentPath = Common_Utils.getCurrentPathUrl();

  window.addEventListener("beforeunload", () => sessionStorage.removeItem(currentPath));

  React.useEffect(() => {
    return () => {
      sessionStorage.removeItem(currentPath);
    };
  }, []);

  return (
    <ContentLayout title={title} isUseHelp>
      <Row>
        <Col lg="12" className="portlets">
          <div className="panel min_h650">
            <div className="panel-header" />
            <div className="panel-content">
              <SearchOption
                customDateComponent={customDateComponent}
                isUseServiceButton={isUseServiceButton}
                isUseTermTab={isUseTermTab}
                OptionTemplate={OptionTemplate}
                isUseClassification={isUseClassification}
                classificationTitle={classificationTitle}
                isUseSearchListFilter={isUseSearchListFilter}
                paramName={paramName}
                isUseCustomListFilter={isUseCustomListFilter}
                CustomListFilter={CustomListFilter}
                isAlawaysUseClassification={isAlawaysUseClassification}
              />
              <PartialLoadingWrapper isLoading={isLoading}>
                <div className="tab-content p-t-0 border-top0">
                  <Row className="tab-pane fade active in">
                    {isWeeklyChart && (
                      <ReportBox isWeekBorder>
                        <WeekChart />
                      </ReportBox>
                    )}

                    <ReportBox isBlackBorder={isBlackBorder} heightClass={heightClass} className={borderClass}>
                      <MainChart
                        customOption={customOption}
                        isUseGraphButton={isUseGraphButton}
                        iPReportMapGraphFileName={title}
                        isIP={isIP}
                        heightClass={heightClass}
                        graphComponent={graphComponent}
                        graphComponentProps={graphComponentProps}
                        valueFormater={valueFormater}
                      />
                    </ReportBox>

                    <PartialLoadingWrapper isLoading={isSubLoading}>
                      {AdditionalInfo && <AdditionalInfo />}
                      {isUseSubGraph && !isResourceWeekMonthFlag && (
                        <SubChart
                          valueFormater={valueFormater}
                          useSubPieChart={useSubPieChart}
                          linkParams={linkParams}
                          customSubChart={customSubChart}
                          subPieChart={subPieChart}
                          pieGraphOption={pieGraphOption}
                          customSubGraphOption={customSubGraphOption}
                          useOnClick={useSubChartOnClick}
                        />
                      )}
                      {isUseList && thFields && (
                        <Col md="12" className="p-l-0 p-r-0">
                          <Top10Table
                            isWeb={isWeb}
                            isIP={isIP}
                            thFields={thFields}
                            TrComponent={TrComponent}
                            isUseTitle={isUseListTitle}
                            title={listTitle}
                            isPagination={isPagination}
                            tableSelectBox={tableSelectBox}
                            emptyMessage={emptyMessage}
                            isUseTab={isUseTab}
                            defaultTab={defaultTab}
                            newTabComponent={newTabComponent}
                            newTabComponentProps={newTabComponentProps}
                            newTabTitle={newTabTitle}
                            isUpload={isUpload}
                            top10ReportTabs={top10ReportTabs}
                            customUserActionLogCode={customUserActionLogCode}
                          />
                        </Col>
                      )}
                    </PartialLoadingWrapper>
                  </Row>
                </div>
              </PartialLoadingWrapper>
            </div>
          </div>
        </Col>
      </Row>
    </ContentLayout>
  );
};

export default observer(ReportContainer);
