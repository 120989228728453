import { languageSet } from "@qubit-utill/src";

import { DEFENCE_LICENSECODE } from "@src/constants/licenseCode";
import getURI from "@src/constants/uriset";

import { DefenceIpContainer } from "@src/pages";

export default {
  //방어 > 차단IP주소 : 시스템
  DEFENSE_IP_SYSTEM: {
    title: languageSet("시스템"),
    withPageLink: `${getURI("DEFENSE_IP_SYSTEM")}/page/:page`,
    link: getURI("DEFENSE_IP_SYSTEM"),
    licenseCode: DEFENCE_LICENSECODE,
    component: DefenceIpContainer,
  },

  //방어 > 차단IP주소 : 웹방화벽
  DEFENSE_IP_WEBFIREWALL: {
    title: languageSet("웹방화벽"),
    withPageLink: `${getURI("DEFENSE_IP_WEBFIREWALL")}/page/:page`,
    link: getURI("DEFENSE_IP_WEBFIREWALL"),
    licenseCode: DEFENCE_LICENSECODE,
    component: DefenceIpContainer,
  },
};
