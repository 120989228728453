import { observable, computed } from "mobx";

import { SetterModel, Common_Utils } from "@qubit-utill/src";

import { CSVINFO } from "@src/constants";

import { LogCountByChannelDayModel } from "@src/pages/Statistic/LogUpLoad/models";

export default class LogCountByChannelModel extends SetterModel {
  constructor(param, serverIp) {
    super(param);
    if (param) {
      this.logUploadChannelDayList = param.logUploadChannelDayList.map((dayItem) => new LogCountByChannelDayModel(dayItem));
    }
    if (serverIp) {
      this.serverIp = serverIp;
    }
  }

  @computed
  get csvData() {
    return Common_Utils.makeCsvRowData(this, CSVINFO[this.csvInfoKey].infoKey);
  }

  @computed
  get getCountAverage() {
    return Common_Utils.numberWithComma(this.logUploadCountAverage);
  }

  csvInfoKey: string = "LOGCOUNT_BY_CHANNEL";

  @observable
  serverIp: string = "";

  @observable
  filterChannelId: number = 0;

  @observable
  filterChannelName: string = "";

  @observable
  typeos: string = "";

  @observable
  logUploadCountAverage: number = 0;

  @observable
  logUploadChannelDayList: Array<LogCountByChannelDayModel> = [];

  @observable
  dayOfWeekData_1: number | string = "-";

  @observable
  dayOfWeekData_2: number | string = "-";

  @observable
  dayOfWeekData_3: number | string = "-";

  @observable
  dayOfWeekData_4: number | string = "-";

  @observable
  dayOfWeekData_5: number | string = "-";

  @observable
  dayOfWeekData_6: number | string = "-";

  @observable
  dayOfWeekData_7: number | string = "-";
}
