import React from "react";
import { observer } from "mobx-react";
import { observable, computed, action } from "mobx";

import { Input, Common_Utils, languageSet } from "@qubit-utill/src";
import SetterModel from "../../../model/SetterModel";

import { BIT_OPTION } from "../../../constants/code";
import { OptionalInputForm } from ".";

export class IpForm extends SetterModel {
  @observable
  ip01 = "";
  @observable
  ip02 = "";
  @observable
  ip03 = "";
  @observable
  ip04 = "";
  @observable
  bit: string | number = 32;

  @computed
  get fullIp() {
    return `${this.ip01}.${this.ip02}.${this.ip03}.${this.ip04}/${this.bit || "32"}`;
  }

  @computed
  get withOutBitIp() {
    return `${this.ip01}.${this.ip02}.${this.ip03}.${this.ip04}`;
  }

  @computed
  get ipValidation() {
    return this.ip01 !== "" && this.ip02 !== "" && this.ip03 !== "" && this.ip04 !== "";
  }
  @action
  init = () => {
    this.ip01 = "";
    this.ip02 = "";
    this.ip03 = "";
    this.ip04 = "";
    this.bit = 32;
  };
}

/////////////////////////////////////////

interface PropsTypes {
  ipForm: IpForm;
  apis?: any;
  info?: string;
  disabled?: boolean;
  color?: string;
  useOptionInputForm?: boolean;
  useInit?: boolean;
  noSize?: boolean;
  noColor?: boolean;
  inputClass?: string;
}

const IpInputForm: React.FC<PropsTypes> = ({
  ipForm,
  apis,
  info = "0.0.0.0",
  disabled = false,
  color = "white",
  useOptionInputForm = true,
  useInit = true,
  noSize = false,
  noColor = false,
  inputClass = "width_45",
}) => {
  const getClientIp = async () => {
    if (apis) {
      try {
        const { info } = await apis();
        const clientIp = info.clientIp.split(".");
        clientIp.forEach((ip, index) => {
          ipForm.setInfo(`ip0${index + 1}`, ip);
        });
      } catch (error) {}
    } else {
      const ip = info ? info.split("/")[0].split(".") : "";
      const bit = info.split("/")[1] || "32";
      ip &&
        ip.forEach((ip, index) => {
          ipForm.setInfo(`ip0${index + 1}`, ip);
        });
      ipForm.setInfo("bit", bit);
    }
  };

  React.useEffect(() => {
    getClientIp();
  }, [info]);

  const setWithValid = elementName => value => {
    if (Common_Utils.onlyNumber(value) && value >= 0 && value <= 255) {
      ipForm.setInfo(elementName, value);
    }
  };

  const setWithBitValid = value => {
    if (Common_Utils.onlyNumber(value) && value > 0 && value <= 32) {
      ipForm.setInfo("bit", value);
    } else if (value === "") {
      ipForm.setInfo("bit", "");
    }
  };

  const onClickInit = () => {
    // state.setIsCustomPort(false);
    ipForm.init();
  };

  return (
    <>
      <span className="dis-inline-b">
        <Input.Text
          className={`${inputClass} form-${color}`}
          value={ipForm.ip01}
          onChange={setWithValid("ip01")}
          maxSize={3}
          disabled={disabled}
          color={color}
          noSize={noSize}
          noColor={noColor}
        />
        <span className="p-lr-3">.</span>
      </span>
      <span className="dis-inline-b">
        <Input.Text
          className={`${inputClass} form-${color}`}
          value={ipForm.ip02}
          onChange={setWithValid("ip02")}
          maxSize={3}
          disabled={disabled}
          color={color}
          noSize={noSize}
          noColor={noColor}
        />
        <span className="p-lr-3">.</span>
      </span>
      <span className="dis-inline-b">
        <Input.Text
          className={`${inputClass} form-${color}`}
          value={ipForm.ip03}
          onChange={setWithValid("ip03")}
          maxSize={3}
          disabled={disabled}
          color={color}
          noSize={noSize}
          noColor={noColor}
        />
        <span className="p-lr-3">.</span>
      </span>
      <span className={`dis-inline-b ${!useOptionInputForm ? "m-r-10" : ""}`}>
        <Input.Text
          className={`${inputClass} form-${color}`}
          value={ipForm.ip04}
          onChange={setWithValid("ip04")}
          maxSize={3}
          disabled={disabled}
          color={color}
          noSize={noSize}
          noColor={noColor}
        />
      </span>

      {useOptionInputForm && (
        <OptionalInputForm
          color={color}
          option={BIT_OPTION}
          info={ipForm}
          valueName="bit"
          onChange={setWithBitValid}
          disabled={disabled}
        />
      )}

      {!disabled && useInit && (
        <span className="dis-inline-b">
          <a onClick={onClickInit}>
            <span className="label bg-black f-11 cursor_p">{languageSet("초기화")}</span>
          </a>
        </span>
      )}
    </>
  );
};

export default observer(IpInputForm);
