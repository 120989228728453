import { observable, computed } from "mobx";
import { Common_Utils, SetterModel } from "@qubit-utill/src";

class SignMemberModel extends SetterModel {
  @computed
  get isEmailFlag() {
    return Common_Utils.validEmail(this.userEmail);
  }
  @computed
  get passwordSameFlag() {
    return this.password01 && this.password02 && this.password01 === this.password02;
  }

  @computed
  get pinPasswordSameFlag() {
    return this.pinPassword01 && this.pinPassword02 && this.pinPassword01 === this.pinPassword02;
  }

  @computed
  get signUpFlag() {
    return (
      !this.validText("company") &&
      !this.validText("userName") &&
      !this.validText("userEmail") &&
      this.isEmailFlag &&
      !this.overlapEmail &&
      !this.validText("password01") &&
      !this.validText("recaptcha") &&
      this.passwordSameFlag
    );
  }

  @computed
  get createFlag() {
    return (
      !this.validText("userName") &&
      !this.validText("userPhone") &&
      !this.validText("userEmail") &&
      this.isEmailFlag &&
      !this.overlapEmail &&
      !this.validText("password01") &&
      this.passwordSameFlag
    );
  }

  @observable
  company = "";
  @observable
  userName = "";
  @observable
  userEmail = "";
  @observable
  overlapEmail = false;
  @observable
  password01 = "";
  @observable
  password02 = "";
  @observable
  mailServiceFlag = true; //정보수신 동의
  @observable
  termsFlag = true; //이용약관 동의
  @observable
  recaptchaFlag = false;
  @observable
  signUpTryFlag = false;
  @observable
  recaptcha = "";
  @observable
  userPhone = "";
  @observable
  department = "";
  @observable
  position = "";
  @observable
  pinPassword01 = "";
  @observable
  pinPassword02 = "";
}

export default SignMemberModel;
