import { observable, computed } from "mobx";

import { Common_Utils, dateUtil, formatConstants, SetterModel } from "@qubit-utill/src";

class ApplicationFilterResourceModel extends SetterModel {
  constructor(params, CodeStore) {
    super(params);
    this.CodeStore = CodeStore;
    if (params) {
      this.detectStartTime = dateUtil.newDate(params.detectStartTime, null);
      this.detectEndTime = dateUtil.newDate(params.detectEndTime, null);
    }
  }
  @observable
  CodeStore;

  @computed
  get isUseFlag() {
    return Common_Utils.isCheckBool(this.isUse);
  }

  @computed
  get riskName() {
    return this.CodeStore.getCodeName("filterRiskLevel", this.resourceFilterRiskLevel);
  }

  @computed
  get isDetectTimeRangeUseFlag() {
    return Common_Utils.isCheckBool(this.isDetectTimeRangeUse);
  }

  @computed
  get detectStartTimeFormat() {
    return this.detectStartTime ? dateUtil.format(this.detectStartTime, formatConstants.TIMEFORMAT) : null;
  }
  @computed
  get detectEndTimeFormat() {
    return this.detectEndTime ? dateUtil.format(this.detectEndTime, formatConstants.TIMEFORMAT) : null;
  }

  @computed
  get getServerGroup() {
    return Common_Utils.isDataWithDashBar(this.serverGroup);
  }

  @computed
  get isPossibleAddDataType() {
    return Boolean(this.resourceId);
  }

  @observable
  resourceFilterId: string = ""; //필터 고유키

  @observable
  resourceFilterName: string = ""; //필터 이름

  @observable
  resourceId: string = ""; //서비스 아이디

  @observable
  resourceFilterRiskLevel: string = "1"; //서비스 위험도

  @observable
  isDetectTimeRangeUse: string = ""; //탐지 시간 설정 사용 여부

  @observable
  detectStartTime: string = ""; //탐지 시작 시간

  @observable
  detectEndTime: string = ""; //탐지 종료 시간

  @observable
  isUse: string = "0"; //사용 여부

  @observable
  registerDate: string = ""; //등록일

  @observable
  updateDate: string = ""; //수정일

  @observable
  resourceFilterElementList = []; //탐지 조건 설정 정보

  @observable
  serverGroupId: string = ""; // 서벗 그룹 고유키

  @observable
  serverGroup: string = ""; // 서버 그룹명

  @observable
  resourceFilterDescription: string = ""; // 설명

  @observable
  isSelected: boolean = false;

  @observable
  resourceName: string = "";
}

export default ApplicationFilterResourceModel;
