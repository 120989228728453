import { set, computed, observable } from "mobx";

class ResourceReportCategoryTabModel {
  constructor(param) {
    set(this, param);
    if (param) {
      this.value = param.resourceId;
      this.name = param.resourceName;
    }
  }

  @computed
  get count() {
    return this.detectCount;
  }

  @computed
  get isUndefinedCount() {
    return this.detectCount === undefined;
  }

  @observable
  value = "";
  @observable
  name = "";
  @observable
  detectCount: number | undefined = undefined;
}

export default ResourceReportCategoryTabModel;
