import { observable } from "mobx";

import { SetterModel } from "@qubit-utill/src";

export default class GroupFirewallModel extends SetterModel {
  @observable
  middlewareUserConfigId: number = 0;

  @observable
  middlewareId: string = "";

  @observable
  guid: string = "";

  @observable
  serverGroupId: number = 0;

  @observable
  validatorState: string = "";

  @observable
  isUseSsl: string = "";

  @observable
  registerDate: string = "";

  @observable
  updateDate: string = "";

  @observable
  registerUuid: string = "";

  @observable
  updateUuid: string = "";

  @observable
  serverHashKey: string = "";

  @observable
  serverGroup: string = "";

  @observable
  isSync: string = "0";

  @observable
  middlewareExecuteCmdList = [];
}
