import { observable, action, computed, toJS } from "mobx";

import {
  arrayForSelectBox,
  UserException,
  languageSet,
  withLoading,
  isRunning,
  ListCondition,
  Common_Utils,
} from "@qubit-utill/src";

import {
  filterApis,
  filterCategoryApis,
  filterCustomConfigApis,
  filterElementReferenceApis,
  eventTypeApis,
} from "@src/apis/pFilter";
import { serverGroupApis, logTagApis } from "@src/apis/pServer";
import { getURI } from "@src/constants";

import {
  DataTypeSelectorModel,
  DataTypeForApiModel,
  DataTypeAttributeModel,
} from "@src/pages/Filter/RegisterFilter/model/DataType";
import { ApplicationFilterDefaultModel } from "@src/pages/Filter/RegisterFilter/model";
import validFilterTimeRange from "@src/pages/Filter/valid/validFilterTimeRange";
import { LogTagListModel } from "@src/pages/Group/RefItem/models";

class ApplicationFilterDefaultStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }
  root;

  @observable
  isRunning = false;

  @observable
  filterElementReferenceListForSelect = [];

  @observable
  menusOrigin = [
    { title: languageSet("원본"), code: "default", pathName: getURI("REGISTERATIONFILTER_APPLICATION_DEFAULT") },
    { title: languageSet("사용자"), code: "custom", pathName: getURI("REGISTERATIONFILTER_APPLICATION_RESOURCE") },
  ];

  @observable
  menus = [
    { title: languageSet("원본"), code: "default", pathName: getURI("REGISTERATIONFILTER_APPLICATION_DEFAULT") },
    { title: languageSet("사용자"), code: "custom", pathName: getURI("REGISTERATIONFILTER_APPLICATION_RESOURCE") },
  ];

  @action
  getFilterElementList = async () => {
    try {
      const { list } = await filterElementReferenceApis.getFilterElementReferenceList({
        isUseManager: "0",
        limit: 9999,
      });
      this.filterElementReferenceListForSelect = arrayForSelectBox.parseValueObjectForSelectBox(
        list,
        "filterElementReferenceName"
      );
    } catch (e) {
      this.filterElementReferenceListForSelect = [];
    }
  };

  @observable
  isCopy = false;

  @observable
  isCopyCheck = false;

  @action
  setIsCopy = (flag) => {
    this.isCopy = flag;
  };
  @action
  setIsCopyCheck = (flag) => {
    this.isCopyCheck = flag;
  };

  @observable
  tableList: ApplicationFilterDefaultModel[] = [];

  @observable
  filterInfo = new ApplicationFilterDefaultModel(null, this.root.CodeStore);

  @observable
  filterCategoryList = [];

  //단일필터 등록등에서 사용하는 데이터 유형 (filterElement)
  @observable
  filterEventElementsList = [];

  @observable
  logTagList = [];

  //UI용 데이터 조작을 위한 프로퍼티
  //필터 데이터 유형
  @observable
  dataTypeSelects: DataTypeSelectorModel[] = [];

  @observable
  isTotalCheck = false;

  @action
  setIsTotalCheck = (flag: boolean) => {
    this.isTotalCheck = flag;
  };

  @computed
  get totalCountForUI() {
    return this.isTotalCheck ? this.totalCount : this.checkList.length;
  }

  @computed
  get checkList() {
    return this.tableList.filter((data) => data.isSelected);
  }

  @computed
  get totalCheckParams() {
    return this.isTotalCheck
      ? { filterCustomConfigIdsAll: "1" }
      : { filterCustomConfigIds: this.checkList.map(({ filterCustomConfigId }) => filterCustomConfigId) };
  }

  @withLoading("isLoading")
  @action
  getList = async () => {
    const params = {
      ...toJS(this.params),
      orderDesc: "DESC",
    };

    try {
      const { list, totalCount } = await filterCustomConfigApis.getFilterCustomConfigList("application", params);
      this.tableList = list.map((data) => new ApplicationFilterDefaultModel(data, this.root.CodeStore));
      this.totalCount = totalCount;
    } catch (e) {
      console.error("e", e);
      this.tableList = [];
      this.totalCount = 0;
    }
  };

  @action
  getDefaultList = async () => {
    const params = {
      ...toJS(this.params),
      orderDesc: "DESC",
    };

    try {
      const { list, totalCount } = await filterCustomConfigApis.getFilterCustomConfigList("application", params);
      this.tableList = list.map((data) => new ApplicationFilterDefaultModel(data, this.root.CodeStore));
      this.totalCount = totalCount;
    } catch (e) {
      console.error("e", e);
      this.tableList = [];
      this.totalCount = 0;
    }
  };

  @action
  setfilterEventElementsList = async () => {
    try {
      const { info, elements } = await eventTypeApis.getEventTypeChannelLogTypeInfo({
        channelLogType: "application",
        filterEventKey: "application",
      });
      this.filterInfo.setInfo("filterEventName", info.filterEventName);
      this.filterInfo.setInfo("filterEventKey", info.filterEventKey);
      this.filterInfo.setInfo("filterEventId", info.filterEventId);
      this.filterEventElementsList = elements;
    } catch (e) {
      this.clearEventElementsList();
    }
  };

  @action
  clearEventElementsList = () => {
    this.filterInfo.setInfo("filterEventName", "");
    this.filterInfo.setInfo("filterEventId", null);
    this.dataTypeSelects = [];
  };

  @withLoading("isLoading")
  @action
  initFilterInfo = async () => {
    if (this.isCopyCheck) {
      await this.getCodes();
      await this.setfilterEventElementsList();
      this.filterInfo.filterName = "";
      this.filterInfo.filterDescription = "";
      this.isCopyCheck = false;
      this.isCopy = true;
    } else {
      this.filterInfo = await new ApplicationFilterDefaultModel(null, this.root.CodeStore);
      await this.getCodes();
      await this.setfilterEventElementsList();
      this.dataTypeSelects = [];
    }
  };

  @action
  clearFilterInfo = () => {
    if (this.isCopy) {
      this.isCopy = false;
    }
    if (!this.isCopyCheck) {
      this.filterInfo = new ApplicationFilterDefaultModel(null, this.root.CodeStore);
      this.dataTypeSelects = [];
    }
  };

  @withLoading("isLoading")
  @action
  getFilterInfo = async (filterCustomConfigId) => {
    const { info } = await filterCustomConfigApis.getFilterCustomConfigInfo(filterCustomConfigId);
    this.filterInfo = new ApplicationFilterDefaultModel(info, this.root.CodeStore);
    await this.getCodes();
  };

  @action
  getServerGroupInfo = async (serverGroupId) => {
    try {
      const { result } = await serverGroupApis.getServerGroupInfo(serverGroupId);
      this.filterInfo.setInfo("serverGroup", result.serverGroupInfo.serverGroup);
    } catch ({ data }) {
      return data;
    }
  };

  @withLoading("isLoading")
  @action
  async getFilterWithTotalInfo(filterId) {
    try {
      await this.getFilterInfo(filterId);
      if (this.filterInfo.serverGroupId) {
        await this.getServerGroupInfo(this.filterInfo.serverGroupId);
      }

      await this.setfilterEventElementsList();

      if (this.filterInfo.filterElementJson) {
        this.parseFilterElementsForUI(this.filterInfo.filterElementJson);
      }
    } catch (e) {
      console.error("tag", e);
    }
  }

  @action
  parseFilterElementsForUI = (filterElementJson) => {
    this.dataTypeSelects = filterElementJson.map((element) => {
      return new DataTypeSelectorModel(
        {
          filterEventElementsList: this.filterEventElementsList,
        },
        element,
        this.filterElementReferenceListForSelect
      );
    });
  };

  @action
  getCodes = async () => {
    const { list } = await logTagApis.getLogTagList({ limit: 99999 });
    this.logTagList = list.map(
      (data) =>
        new LogTagListModel({
          ...data,
          value: data.logTag,
          name: data.logTag,
        })
    );
    await this.root.ServerGroupStore.getListForOptions();
    this.getFilterCategoryList("applicationfilter");
  };

  dataTypeValidationCheck = (dataTypeElement) => {
    if (!dataTypeElement.filterElementName) throw new UserException(languageSet("메세지_요소선택"));

    if (dataTypeElement.elementAttributes && !dataTypeElement.filterElementAttributeName)
      throw new UserException(languageSet("메세지_속성선택"));

    if (dataTypeElement.filterEventElementAttributeValues && !dataTypeElement.filterElementAttributeValue)
      throw new UserException(languageSet("메세지_속성값선택"));

    if (dataTypeElement.filterEventElementRepeatSize > 0 && !dataTypeElement.filterElementRepeatSequence)
      throw new UserException(languageSet("메세지_요소수선택"));
  };

  validData = () => {
    try {
      let newDataTypeList: DataTypeForApiModel[] = [];
      this.dataTypeSelects.forEach(({ selectedTypes, dataValues }) => {
        const dataTypeList: any = toJS(selectedTypes) as any;
        const dataTypeValues = toJS(dataValues) as DataTypeAttributeModel[];

        const slectedLastIndex: number = dataTypeList.length - 1;
        const selectedLastElement = dataTypeList[slectedLastIndex];
        //텍스트 밸류에 엘리먼트 네임 삽입

        this.dataTypeValidationCheck(selectedLastElement);

        if (dataTypeValues.length <= 0) throw new UserException(languageSet("메세지_요소선택2"));

        //마지막 요소에 텍스트 밸류 삽입
        const lastDataType = dataTypeValues.map((element) => {
          if (element.filterElementSearchText) {
            return new DataTypeForApiModel({
              filterElementName: selectedLastElement.filterElementName,
              filterElementAttributeValue: selectedLastElement.filterElementAttributeValue,
              filterElementAttributeName: selectedLastElement.filterElementAttributeName,
              filterElementRepeatSequence: selectedLastElement.filterElementRepeatSequence,
              filterElementSearchText: element.filterElementSearchText,
              filterElementSearchType: element.filterElementSearchType,
              filterElementSearchFlag: element.filterElementSearchFlag,
            });
          } else {
            throw new UserException(languageSet("메세지_데이터값입력"));
          }
        });

        dataTypeList[slectedLastIndex] = lastDataType;
        for (let i = slectedLastIndex; i >= 1; i--) {
          this.dataTypeValidationCheck(dataTypeList[i - 1]);

          if (i === slectedLastIndex) {
            dataTypeList[i - 1].childArray = dataTypeList[i];
          } else {
            dataTypeList[i - 1].childArray = [new DataTypeForApiModel(dataTypeList[i])];
          }
        }

        if (!dataTypeList[0].childArray) {
          throw new UserException(languageSet("메세지_요소선택"));
        }

        newDataTypeList.push(new DataTypeForApiModel(dataTypeList[0]));
      });

      if (this.filterInfo.isDetectTimeRangeUseFlag && !(this.filterInfo.detectStartTime && this.filterInfo.detectEndTime)) {
        throw new UserException(languageSet("메세지_시간선택"));
      }

      if (
        this.filterInfo.isDetectTimeRangeUseFlag &&
        this.filterInfo.detectStartTimeFormat === this.filterInfo.detectEndTimeFormat
      ) {
        throw new UserException(languageSet("메세지_시간설정다시"));
      }

      if (newDataTypeList.length === 0 || !this.filterInfo.filterName || !this.filterInfo.filterDescription) {
        throw new UserException(languageSet("에러_정보를입력해라"));
      }

      this.filterInfo.setInfo("filterElementJson", newDataTypeList);

      return {
        isValid: true,
      };
    } catch ({ message }) {
      return {
        isValid: false,
        message: message,
      };
    }
  };

  validTime = () => {
    const {
      isDetectTimeRangeUseFlag,
      detectStartTime,
      detectEndTime,
      detectStartTimeFormat,
      detectEndTimeFormat,
    } = this.filterInfo;
    return validFilterTimeRange(
      isDetectTimeRangeUseFlag,
      detectStartTime,
      detectEndTime,
      detectStartTimeFormat,
      detectEndTimeFormat
    );
  };

  @isRunning("isRunning")
  @action
  createFilter = async () => {
    const {
      filterType,
      isDetectDisplay,
      isUseUserDescription,
      isUse,
      isGlobalFilter,
      filterRiskLevel,
      isLock,
      filterName,
      filterDescription,
      filterUserDescription,
      filterNo,
      filterEventId,
      filterElementJson,
      isDisplay,
      filterCategoryId,
      isCustomFilter,
      isUseCustom,
      serverGroupId,
      isDetectTimeRangeUse,
      detectStartTimeFormat,
      detectEndTimeFormat,
      logTag,
    } = this.filterInfo;

    try {
      await filterApis.createFilter({
        filterType,
        isDetectDisplay,
        isUseUserDescription,
        isUse,
        isGlobalFilter,
        filterRiskLevel,
        isLock,
        filterName,
        filterDescription,
        filterUserDescription,
        filterNo,
        filterEventId,
        filterElementJson,
        isDisplay,
        filterCategoryId,
        isCustomFilter,
        isUseCustom,
        serverGroupId,
        isDetectTimeRangeUse,
        detectStartTime: detectStartTimeFormat,
        detectEndTime: detectEndTimeFormat,
        logTag,
      });
    } catch (error) {
      return (
        error.data || {
          returnCodeMessage: error,
        }
      );
    }
  };

  @action
  updateCustomConfig = async () => {
    try {
      await filterCustomConfigApis.updateFilterCustomConfig(
        [this.filterInfo.filterId],
        [this.filterInfo.serverGroupId],
        this.filterInfo.isUseCustom,
        this.filterInfo.isDetectTimeRangeUse,
        this.filterInfo.detectStartTimeFormat,
        this.filterInfo.detectEndTimeFormat,
        [this.filterInfo.filterCustomConfigId],
        this.filterInfo.logTag
      );
    } catch ({ data }) {
      return data;
    }
  };

  @action
  updateCustomConfigIsUse = async (onOff) => {
    try {
      await filterCustomConfigApis.updateFilterCustomConfigIsUse(
        Common_Utils.BoolToString(onOff),
        this.totalCheckParams,
        this.params
      );
      this.getList();
    } catch ({ data }) {
      return data;
    }
  };

  @action
  updateIsUseCustom = async (info) => {
    const isUseValue = Common_Utils.BoolToString(!info.isUseCustomFlag);
    try {
      const { rowCount } = await filterCustomConfigApis.updateFilterCustomConfigIsUse(isUseValue, {
        filterCustomConfigIds: [info.filterCustomConfigId],
      });
      if (rowCount >= 1) info.setInfo("isUseCustom", isUseValue);
    } catch ({ data }) {
      return data;
    }
  };

  @action
  deleteCustomFilter = async () => {
    try {
      await filterCustomConfigApis.deleteFilterCustomConfig(this.totalCheckParams, this.params);
    } catch ({ data }) {
      return data;
    }
  };

  @action
  deleteRecommendFilterCustomConfig = async (filterCustomConfigId) => {
    try {
      await filterCustomConfigApis.deleteFilterCustomConfig({ filterCustomConfigIds: [filterCustomConfigId] });
    } catch ({ data }) {
      return data;
    }
  };

  @isRunning("isRunning")
  @action
  updateFilter = async () => {
    const {
      filterId,
      filterType,
      isDetectDisplay,
      isUseUserDescription,
      isUse,
      isGlobalFilter,
      filterRiskLevel,
      isLock,
      filterName,
      filterDescription,
      filterUserDescription,
      filterNo,
      filterEventId,
      filterElementJson,
      isDisplay,
      filterCategoryId,
      isCustomFilter,
      serverGroupId,
      isUseCustom,
      filterCustomConfigId,
      isDetectTimeRangeUse,
      detectStartTimeFormat,
      detectEndTimeFormat,
      logTag,
    } = this.filterInfo;

    try {
      await filterApis.updateFilter({
        filterId,
        filterType,
        isDetectDisplay,
        isUseUserDescription,
        isUse,
        isGlobalFilter,
        filterRiskLevel,
        isLock,
        filterName,
        filterDescription,
        filterUserDescription,
        filterNo,
        filterEventId,
        filterElementJson,
        isDisplay,
        filterCategoryId,
        isCustomFilter,
        serverGroupId,
        isUseCustom,
        filterCustomConfigId,
        isDetectTimeRangeUse,
        detectStartTime: detectStartTimeFormat,
        detectEndTime: detectEndTimeFormat,
        logTag,
      });
    } catch ({ data }) {
      return data;
    }
  };

  @action
  addNewDataType = () => {
    const eventRootElement = this.filterEventElementsList[0];
    const param = {
      filterEventElementsList: this.filterEventElementsList,
      eventRootElement: eventRootElement,
    };
    this.dataTypeSelects.push(new DataTypeSelectorModel(param));
  };

  @action
  deleteDataElement = (index) => {
    this.dataTypeSelects.splice(index, 1);
  };

  @action
  getFilterCategoryList = async (filterType) => {
    const { list } = await filterCategoryApis.getFilterCategoryList(filterType);
    // this.filterCategoryList = list.map((element) => new FilterCategoryModel(element));
    this.filterInfo.filterCategoryId = list[0].filterCategoryId;
  };

  @action
  allCheck = (checked) => {
    this.tableList.forEach((data) => {
      data.setInfo("isSelected", checked);
    });
  };
}

export default ApplicationFilterDefaultStore;
