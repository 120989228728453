import { observable } from "mobx";

export default class ResourceDataTypeApiModel {
  constructor(params) {
    this.resourceFilterElementSelectorId = params.resourceFilterElementSelectorId;
    this.resourceFilterElementSelectorType = params.resourceFilterElementSelectorType;
    this.resourceFilterElementSelectorOperator = params.resourceFilterElementSelectorOperator;
    this.resourceFilterElementSelectorValue = params.resourceFilterElementSelectorValue;
    this.resourceFilterElementSelectorOrderNo = params.resourceFilterElementSelectorOrderNo;
    this.resourceFilterElementId = params.resourceFilterElementId;
  }

  @observable
  resourceFilterElementSelectorId: string = "";

  @observable
  resourceFilterElementSelectorType: string = "";

  @observable
  resourceFilterElementSelectorOperator: string = "";

  @observable
  resourceFilterElementSelectorValue: string = "";

  @observable
  resourceFilterElementSelectorOrderNo: number = 0;

  @observable
  resourceFilterElementId: string = "";
}
