import { action, observable, computed } from "mobx";
import { SetterModel, languageSet, Common_Utils } from "@qubit-utill/src";

export default class DataBreachElementSelectorModel extends SetterModel {
  constructor(info, CodeStore) {
    super(info);
    this.CodeStore = CodeStore;
    if (info.webExtendsFilterFieldValue) {
      this.webExtendsFilterFieldIpValue = info.webExtendsFilterFieldValue.split("/")[0];
      this.webExtendsFilterFieldBitValue = info.webExtendsFilterFieldValue.split("/")[1] || this.webExtendsFilterFieldBitValue;
    }
  }
  CodeStore;

  @computed
  get webExtendsFilterFieldSelectOperatorName() {
    return this.CodeStore.getCodeName("searchColumnAssign", this.webExtendsFilterFieldSelectOperator);
  }

  @observable
  webExtendsFilterFieldIpValue: string = "";

  @observable
  webExtendsFilterFieldBitValue: string = "32";

  @observable
  webExtendsFilterElementSelectorId = 0;
  @observable
  webExtendsFilterFieldSelectOperator: string = ""; //gt
  @observable
  webExtendsFilterFieldSelectValueType: string = ""; //input
  @observable
  webExtendsFilterFieldSelectValueTypeCode: string = ""; //KB
  @observable
  webExtendsFilterFieldValue = "";
  @observable
  webExtendsFilterFieldSelectValueTypeName: string = "";
  @observable
  webExtendsFilterFieldSelectDisplayText: string = "";

  validation = () => {
    if (
      !Common_Utils.getBlockFirstBlank(this.webExtendsFilterFieldValue) ||
      !Common_Utils.getBlockFirstBlank(this.webExtendsFilterFieldBitValue)
    ) {
      throw languageSet("메세지_요소값입력해주세요");
    }
  };

  @action
  setSelectFilterElementSearchType = ({ value, name }) => {
    this.webExtendsFilterFieldSelectValueType = value;
    this.webExtendsFilterFieldSelectValueTypeName = name;
    this.webExtendsFilterFieldSelectDisplayText = name;
  };
}
