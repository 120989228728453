import { asyncComponent } from "@qubit-utill/src";

const DashBoardMainContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'DashBoardMainContainer' */
    "@src/pages/Dashboard/DashBoardMainContainer"
  )
);

const DashBoardContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'DashBoardContainer' */
    "@src/pages/Dashboard/DashBoardContainer"
  )
);

const LogForensicDashBoardContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'LogForensicDashBoardContainer' */
    "@src/pages/Dashboard/LogForensicDashBoardContainer"
  )
);

const CorrelationContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'CorrelationContainer' */
    "@src/pages/SecurityDetect/Correlation/CorrelationContainer"
  )
);

const DataBreachContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'DataBreachContainer' */
    "@src/pages/SecurityDetect/DataBreach/DataBreachContainer"
  )
);

const ExtortAccountContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'ExtortAccountContainer' */
    "@src/pages/SecurityDetect/ExtortAccount/ExtortAccountContainer"
  )
);

const WebsiteforgeryContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'WebsiteforgeryContainer' */
    "@src/pages/SecurityDetect/Websiteforgery/WebsiteforgeryContainer"
  )
);

const RegisterFilterContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'RegisterFilterContainer' */
    "@src/pages/Filter/RegisterFilter/RegisterFilterList/RegisterFilterContainer"
  )
);

const SecurityFilterContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'SecurityFilterContainer' */
    "@src/pages/Filter/SecurityFilter/SecurityFilterList/SecurityFilterContainer"
  )
);

const WebsiteForgeryFilterEditContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'WebsiteForgeryFilterEditContainer' */
    "@src/pages/Filter/SecurityFilter/WebsiteForgeryFilterEdit/WebsiteForgeryFilterEditContainer"
  )
);

const ExtortAccountFilterEditContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'ExtortAccountFilterEditContainer' */
    "@src/pages/Filter/SecurityFilter/ExtortAccountFilterEdit/ExtortAccountFilterEditContainer"
  )
);

const RecommendFilterContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'RecommendFilterContainer' */
    "@src/pages/Filter/RecommendFilter/RecommendFilterList/RecommendFilterContainer"
  )
);

const RecommendFilterDetailContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'RecommendFilterDetailContainer' */
    "@src/pages/Filter/RecommendFilter/RecommendFilterDetail/RecommendFilterDetailContainer"
  )
);

const RecommendFilterEditContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'RecommendFilterEditContainer' */
    "@src/pages/Filter/RecommendFilter/RecomendFilterEdit/RecommendFilterEditContainer"
  )
);

const RecommendFilterSetContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'RecommendFilterSetContainer' */
    "@src/pages/Filter/RecommendFilter/RecommendFilterRegist/RecommendFilterSetContainer"
  )
);

const DataBreachFilterSetContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'DataBreachFilterSetContainer' */
    "@src/pages/Filter/SecurityFilter/DataBreachFilterSet/DataBreachFilterSetContainer"
  )
);

const WebFirewallFilterSetContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'WebFirewallFilterSetContainer' */
    "@src/pages/Filter/RegisterFilter/RegisterFilterSet/WebFirewallFilter/WebFirewallFilterSetContainer"
  )
);

const SystemFilterSetContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'SystemFilterSetContainer' */
    "@src/pages/Filter/RegisterFilter/RegisterFilterSet/SystemFilter/SystemFilterSetContainer"
  )
);

const ApplicationFilterDefaultSetContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'ApplicationFilterDefaultSetContainer' */
    "@src/pages/Filter/RegisterFilter/RegisterFilterSet/ApplicationFilter/ApplicationFilterDefaultSetContainer"
  )
);

const ApplicationFilterResourceSetContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'ApplicationFilterResourceSetContainer' */
    "@src/pages/Filter/RegisterFilter/RegisterFilterSet/ApplicationFilter/ApplicationFilterResourceSetContainer"
  )
);

const WebFilterSetContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'WebFilterSetContainer' */
    "@src/pages/Filter/RegisterFilter/RegisterFilterSet/WebFilter/WebFilterSetContainer"
  )
);

const NetworkFilterSetContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'NetworkFilterSetContainer' */
    "@src/pages/Filter/RegisterFilter/RegisterFilterSet/NetworkFilter/NetworkFilterSetContainer"
  )
);

const NetworkFilterDetectContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'NetworkFilterDetectContainer' */
    "@src/pages/FilterDetection/NetworkFilterDetection/NetworkFilterDetectContainer"
  )
);

const WebFilterDetectContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'WebFilterDetectContainer' */
    "@src/pages/FilterDetection/WebFilterDetection/WebFilterDetectContainer"
  )
);

const SystemFilterDetectContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'SystemFilterDetectContainer' */
    "@src/pages/FilterDetection/SystemFilterDetection/SystemFilterDetectContainer"
  )
);

const ApplicationDefaultFilterDetectContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'ApplicationDefaultFilterDetectContainer' */
    "@src/pages/FilterDetection/ApplicationFilterDetection/Default/ApplicationDefaultFilterDetectContainer"
  )
);

const ApplicationResourceFilterDetectContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'ApplicationResourceFilterDetectContainer' */
    "@src/pages/FilterDetection/ApplicationFilterDetection/Resource/ApplicationResourceFilterDetectContainer"
  )
);

const QnaContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'QnaContainer' */
    "@src/pages/QnA/QnaList/QnaContainer"
  )
);

const QnaSetContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'QnaSetContainer' */
    "@src/pages/QnA/QnaSet/QnaSetContainer"
  )
);

const QnaDetailContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'QnaDetailContainer' */
    "@src/pages/QnA/QnaDetail/QnaDetailContainer"
  )
);

const GroupManageMainContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'GroupManageMainContainer' */
    "@src/pages/Group/GroupManageMainContainer"
  )
);

const GroupManageContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'GroupManageContainer' */
    "@src/pages/Group/GroupManageContainer"
  )
);

const SystemTotalLogContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'SystemTotalLogContainer' */
    "@src/pages/TotalLog/SystemTotalLog/SystemTotalLogContainer"
  )
);

const ApplicationDefaultTotalLogContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'ApplicationDefaultTotalLogContainer' */
    "@src/pages/TotalLog/ApplicationTotalLog/Default/ApplicationDefaultTotalLogContainer"
  )
);

const ApplicationResourceTotalLogContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'ApplicationResourceTotalLogContainer' */
    "@src/pages/TotalLog/ApplicationTotalLog/Resource/ApplicationResourceTotalLogContainer"
  )
);

const WebTotalLogContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'WebTotalLogContainer' */
    "@src/pages/TotalLog/WebTotalLog/WebTotalLogContainer"
  )
);

const NetworkTotalLogContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'NetworkTotalLogContainer' */
    "@src/pages/TotalLog/NetworkTotalLog/NetworkTotalLogContainer"
  )
);

const WebFirewallTotalLogContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'WebFirewallTotalLogContainer' */
    "@src/pages/TotalLog/WebFirewallTotalLog/WebFirewallTotalLogContainer"
  )
);

const HostSecurityTotalLogContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'HostSecurityTotalLogContainer' */
    "@src/pages/TotalLog/HostSecurityTotalLog/HostSecurityTotalLogContainer"
  )
);

const ServerManagementContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'ServerManagementContainer' */
    "@src/pages/Server/ServerManagement/ServerManagementContainer"
  )
);

const ServerGroupManagementContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'ServerGroupManagementContainer' */
    "@src/pages/Server/ServerGroupManagement/ServerGroupManagementContainer"
  )
);

const ServerGroupSetContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'ServerGroupSetContainer' */
    "@src/pages/Server/ServerGroupManagement/ServerGroupSetContainer"
  )
);

// const DefenceExecuteContainer = asyncComponent(() =>
//   import(
//     /* webpackChunkName: 'DefenceExecuteContainer' */
//     "@src/pages/Defence/DefenceExecute/DefenceExecuteContainer"
//   )
// );
const DefenceIpContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'DefenceIpContainer' */
    "@src/pages/Defence/DefenceIp/DefenceIpList/DefenceIpContainer"
  )
);

const DefenseLogContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'DefenseLogContainer' */
    "@src/pages/Defence/DefenseLog/DefenseLogList/DefenseLogContainer"
  )
);

const DefenceManagementContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'DefenceManagementContainer' */
    "@src/pages/Defence/DefenceManagement/DefenceManagementContainer"
  )
);

const ComplianceContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'ComplianceContainer' */
    "@src/pages/Compliance/ComplianceContainer"
  )
);

const MyInfoContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'MyInfoContainer' */
    "@src/pages/MyInfo/MyInfoContainer"
  )
);

const MyInfoTabContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'MyInfoTabContainer' */
    "@src/pages/MyInfo/MyInfoTabContainer"
  )
);

const AlarmProvidorContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'AlarmProvidorContainer' */
    "@src/pages/Alarm/AlarmProvidorContainer"
  )
);

const IpExtractionContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'IpExtractionContainer' */
    "@src/pages/Extraction/IpExtraction/IpExtractionContainer"
  )
);

export const UrlExtractionContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'UrlExtractionContainer' */
    "@src/pages/Extraction/UrlExtraction/UrlExtractionContainer"
  )
);

export const LoginExtractionContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'LoginExtractionContainer' */
    "@src/pages/Extraction/Login/LoginExtractionContainer"
  )
);

const MLContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'MLContainer' */
    "@src/pages/ML/MLContainer"
  )
);

const SystemReportContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'SystemReportContainer' */
    "@src/pages/Report/System/SystemReportContainer"
  )
);
const WebReportContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'WebReportContainer' */
    "@src/pages/Report/Web/WebReportContainer"
  )
);

const NetworkReportContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'NetworkReportContainer' */
    "@src/pages/Report/Network/NetworkReportContainer"
  )
);

const CorrelationReportContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'CorrelationReportContainer' */
    "@src/pages/Report/Correlation/CorrelationReportContainer"
  )
);

const IPReportContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'IPReportContainer' */
    "@src/pages/Statistic/IpAddress/IPReportContainer"
  )
);

const CorrelationFilterSetContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'CorrelationFilterSetContainer' */
    "@src/pages/Filter/SecurityFilter/CorrelationFilterSet/CorrelationFilterSetContainer"
  )
);

const InstallContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'InstallContainer' */
    "@src/pages/Install/InstallContainer"
  )
);

const InstallAgentGuideContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'InstallContainer' */
    "@src/pages/Install/InstallAgentGuideContainer"
  )
);

const FreeToPremiumContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'FreeToPremiumContainer' */
    "@src/pages/Error/FreeToPremiumContainer"
  )
);

const BoxPlotReportContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'BoxPlotReportContainer' */
    "@src/pages/Statistic/BoxPlot/BoxPlotReportContainer"
  )
);

const WordCloudReportContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'WordCloudReportContainer' */
    "@src/pages/Statistic/WordCloud/WordCloudReportContainer"
  )
);

const HeatMapReportContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'HeatMapReportContainer' */
    "@src/pages/Statistic/HeatMap/HeatMapReportContainer"
  )
);

const WebFirewallReportContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'WebFirewallReportContainer' */
    "@src/pages/Report/WebFirewall/WebFirewallReportContainer"
  )
);

const WebFirewallContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'WebFirewallContainer' */
    "@src/pages/FilterDetection/WebFirewallFilterDetection/WebFirewallContainer"
  )
);

const HostSecurityFilterDetectContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'HostSecurityFilterDetectContainer' */
    "@src/pages/FilterDetection/HostSecurityFilterDetection/HostSecurityFilterDetectContainer"
  )
);

const HostSecurityReportContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'HostSecurityReportContainer' */
    "@src/pages/Report/HostSecurity/HostSecurityReportContainer"
  )
);

const TicketListContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'TicketListContainer' */
    "@src/pages/Ticket/TicketListContainer"
  )
);

const AgentExecuteLogList = asyncComponent(() =>
  import(
    /* webpackChunkName: 'AgentExecuteLogList' */
    "@src/pages/Agent/AgentExecuteLogList"
  )
);

const HostResourceMonitoringContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'HostResourceMonitoringContainer' */
    "@src/pages/ResourceMonitoring/HostResourceMonitoring/HostResourceMonitoringContainer"
  )
);

const HostTotalResourceMonitoringContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'HostTotalResourceMonitoringContainer' */
    "@src/pages/ResourceMonitoring/HostTotalResourceMonitoring/HostTotalResourceMonitoringContainer"
  )
);

const AlarmFilterResourceMonitoringContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'AlarmFilterResourceMonitoringContainer' */
    "@src/pages/ResourceMonitoring/AlarmFilterResourceMonitoring/AlarmFilterResourceMonitoringContainer"
  )
);

const AlarmFilterResourceMonitoringSetContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'AlarmFilterResourceMonitoringSetContainer' */
    "@src/pages/ResourceMonitoring/AlarmFilterResourceMonitoring/AlarmFilterResourceMonitoringSetContainer"
  )
);

const LogUploadReportContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'LogUploadReportContainer' */
    "@src/pages/Statistic/LogUpLoad/LogUploadReportContainer"
  )
);

const ThemeContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'ThemeContainer' */
    "@src/pages/Theme/ThemeContainer"
  )
);

const DataBreachReportContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'DataBreachReportContainer' */
    "@src/pages/Report/DataBreach/DataBreachReportContainer"
  )
);

const MitreReportContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'MitreReportContainer' */
    "@src/pages/Report/Mitre/MitreReportContainer"
  )
);

const LoginReportContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'LoginReportContainer' */
    "@src/pages/Statistic/Login/LoginReportContainer"
  )
);

const MitreContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'MitreContainer' */
    "@src/pages/SecurityDetect/Mitre/MitreContainer"
  )
);

const ExtortAccountReportContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'ExtortAccountReportContainer' */
    "@src/pages/Report/ExtortAccount/ExtortAccountReportContainer"
  )
);

const MainReportContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'MainReportContainer' */
    "@src/pages/Report/Main/MainReportContainer"
  )
);
const ApplicationReportContainer = asyncComponent(() =>
  import(
    /* webpackChunkName: 'ApplicationReportContainer' */
    "@src/pages/Report/Application/ApplicationReportContainer"
  )
);

//대시보드
export { DashBoardMainContainer };
export { DashBoardContainer };
export { LogForensicDashBoardContainer };

//웹방화벽 탐지
export { WebFirewallContainer };

//호스트보안 탐지
export { HostSecurityFilterDetectContainer };

//보안탐지
export { MitreContainer };
export { CorrelationContainer };
export { DataBreachContainer };
export { ExtortAccountContainer };
export { WebsiteforgeryContainer };

//필터탐지
export { SystemFilterDetectContainer };
export { ApplicationDefaultFilterDetectContainer };
export { ApplicationResourceFilterDetectContainer };
export { WebFilterDetectContainer };
export { NetworkFilterDetectContainer };

//ML탐지
export { MLContainer };

//전체로그
export { WebFirewallTotalLogContainer };
export { SystemTotalLogContainer };
export { ApplicationDefaultTotalLogContainer };
export { ApplicationResourceTotalLogContainer };
export { WebTotalLogContainer };
export { NetworkTotalLogContainer };
export { HostSecurityTotalLogContainer };

//필터
export { SecurityFilterContainer };
export { DataBreachFilterSetContainer };
export { WebsiteForgeryFilterEditContainer };
export { ExtortAccountFilterEditContainer };
export { CorrelationFilterSetContainer };
export { RecommendFilterContainer };
export { RecommendFilterDetailContainer };
export { RecommendFilterEditContainer };
export { RecommendFilterSetContainer };
export { RegisterFilterContainer };
export { WebFirewallFilterSetContainer };
export { SystemFilterSetContainer };
export { ApplicationFilterDefaultSetContainer };
export { ApplicationFilterResourceSetContainer };
export { WebFilterSetContainer };
export { NetworkFilterSetContainer };

//방어
export { DefenceIpContainer };
export { DefenseLogContainer };
export { DefenceManagementContainer };
// export { DefenceExecuteContainer };

//시스템
export { ServerManagementContainer };
export { ServerGroupManagementContainer };
export { ServerGroupSetContainer };

// 리소스모니터링
export { HostResourceMonitoringContainer };
export { HostTotalResourceMonitoringContainer };
export { AlarmFilterResourceMonitoringContainer };
export { AlarmFilterResourceMonitoringSetContainer };

//추출로그
export { IpExtractionContainer };

//보고서
export { MainReportContainer };
export { WebFirewallReportContainer };
export { MitreReportContainer };
export { CorrelationReportContainer };
export { DataBreachReportContainer };
export { ExtortAccountReportContainer };
export { SystemReportContainer };
export { ApplicationReportContainer };
export { WebReportContainer };
export { NetworkReportContainer };
export { AgentExecuteLogList };
export { HostSecurityReportContainer };

//통계
export { IPReportContainer };
export { LoginReportContainer };
export { LogUploadReportContainer };
export { WordCloudReportContainer };
export { HeatMapReportContainer };
export { BoxPlotReportContainer };

//컴플라이언스
export { ComplianceContainer };

//관리
export { GroupManageMainContainer };
export { GroupManageContainer };

//Q&A
export { QnaContainer };
export { QnaDetailContainer };
export { QnaSetContainer };

//나의정보
export { MyInfoContainer };
export { MyInfoTabContainer };

//개인알림
export { AlarmProvidorContainer };

//테마설정
export { ThemeContainer };

//InstallAgent
export { InstallContainer };
export { InstallAgentGuideContainer };

//Ticket
export { TicketListContainer };

//Error
export { FreeToPremiumContainer };
