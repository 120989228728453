import React from "react";
import { languageSet } from "@qubit-utill/src";
import { path, getURI } from "../../../common_constants";

const Footer = () => {
  return (
    <div className="footer">
      <div className="copyright">
        <p className="pull-left sm-pull-reset">
          <span>© 2014. Qubit Security Inc. All rights reserved.</span>
        </p>
        <p className="pull-right sm-pull-reset">
          <span>
            <a href={path.QUBITCORPURL} target="_blank" className="m-r-10">
              {languageSet("회사소개")}
            </a>
            |
            <a href={path.QUBITTOS} target="_blank" className="m-l-10 m-r-10">
              {languageSet("이용약관")}
            </a>
            |
            <a href={path.QUBITPRIVACY} target="_blank" className="m-l-10">
              {languageSet("개인정보_처리방침")}
            </a>
          </span>
        </p>
      </div>
    </div>
  );
};

export default Footer;
