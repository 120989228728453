import { observable, computed } from "mobx";

import { Common_Utils } from "@qubit-utill/src";
import { SystemLogModel } from "@src/common/models";
import { CSVINFO } from "@src/constants";

export default class SystemTotalLogModel extends SystemLogModel {
  constructor(params) {
    super(params);
    if (params) {
      this.logHash = params.logHash || "-";
      this.withMicroSecondsLogCreateDate = params.logCreateDate + params.logCreateMicroSeconds;
    }
  }

  @computed
  get totalLogIp() {
    return this.hostIp || this.serverIp;
  }

  @computed
  get csvData() {
    return Common_Utils.makeCsvRowData(this, CSVINFO.TOTALLOG_SYS.infoKey);
  }

  @computed
  get attackIp() {
    return this.logClientIp;
  }

  @computed
  get filterIds() {
    return this.filters ? this.filters.map(({ filterId }) => filterId) : [this.filterId];
  }

  @computed
  get isDefenceFlag() {
    return Common_Utils.isCheckBool(this.isDefence);
  }

  @computed
  get getServerGroup() {
    return Common_Utils.isDataWithDashBar(this.serverGroup);
  }

  @observable
  filters = [];

  @observable
  filterId: string = "";

  @observable
  documentId = "";

  @observable
  serverGroup = "";

  @observable
  typeos = "";
  @observable
  serverIp = "";
  @observable
  hostIp = "";
  @observable
  hostName = "";
  @observable
  logSeverity = "";
  @observable
  filterChannelName = "";
  @observable
  logCreateDate = "";
  @observable
  serverId = "";
  //로그 상세
  @observable
  logPriorityId = "";
  @observable
  logHash = "";
  @observable
  logOrigin = "";
  @observable
  filterEventKey = "";
  @observable
  logMessage = "";
  @observable
  filterEventName = "";

  @observable
  logClientIp = "";

  @observable
  detectDefenseId = 0;

  @observable
  isActive = "0";

  @observable
  isDefence = "";

  defenseConfigFilterType = "filter";

  @observable
  logCreateMicroSeconds = "";

  @observable
  withMicroSecondsLogCreateDate = "";
}
