import React, { useEffect } from "react";
import { Col } from "reactstrap";
import { observer, Observer } from "mobx-react";

import { RadioButton, languageSet, useInjectStore, CheckBox } from "@qubit-utill/src";

const WebStatusOption = () => {
  const { CodeStore, ReportStore } = useInjectStore();
  const { codeListForSelect } = CodeStore;

  const {
    webStatus,
    setInfo,
    getData,
    blockModeCheckBoxes,
    setBlockModeCheckBoxes,
    isWaf,
    isHostSecurity,
    getChagneBlockMode,
  } = ReportStore;

  const _renderStatus = () => {
    if (codeListForSelect.detectStatusCodeRange) {
      return codeListForSelect.detectStatusCodeRange.map(({ name }) => (
        <RadioButton
          key={name}
          isChecked={webStatus === name}
          onSelect={() => {
            setInfo("webStatus", name);
            getData();
          }}
        >
          <span className="dis-inline-b p-r-10">{name}</span>
        </RadioButton>
      ));
    }
    return null;
  };

  const _renderBlockMode = () => {
    if ((isWaf || isHostSecurity) && blockModeCheckBoxes && blockModeCheckBoxes.length > 0) {
      return blockModeCheckBoxes.map(({ isSelected, name, isToggle, code }) => (
        <span className="dis-inline-b f-right">
          <span className="dis-inline-b">
            <span className="p-r-10">
              <CheckBox
                type="flat"
                color="blue"
                isChecked={isSelected}
                onToggle={() => {
                  blockModeCheckBoxes.forEach((info) => info.code !== code && info.isSelected === true && isToggle());
                  getChagneBlockMode();
                }}
              />
              <span>{name}</span>
            </span>
          </span>
        </span>
      ));
    }
    return null;
  };

  useEffect(() => {
    if ((isWaf || isHostSecurity) && codeListForSelect?.logBlocked) {
      setBlockModeCheckBoxes(codeListForSelect.logBlocked);
    }
  }, [codeListForSelect?.logBlocked]);

  return (
    <Col md="12" className="m-b-5">
      {!isHostSecurity && (
        <>
          <span className="dis-inline-b p-r-10 c-black">
            Status
            <span className="p-lr-3">:</span>
          </span>
          <RadioButton
            isChecked={webStatus === ""}
            onSelect={() => {
              setInfo("webStatus", "");
              getData();
            }}
          >
            <span className="dis-inline-b p-r-10">
              <span>{languageSet("전체")}</span>
            </span>
          </RadioButton>

          <Observer render={_renderStatus} />
        </>
      )}
      <Observer render={_renderBlockMode} />
    </Col>
  );
};

export default observer(WebStatusOption);
