import { observable, action, toJS } from "mobx";

import { ListCondition, withLoading, Common_Utils, languageSet } from "@qubit-utill/src";

import { complianceSettingApis } from "@src/apis/pFilter";
import { webTotalLogApis } from "@src/apis/pLog";
import DefenceStore from "@src/common/stores/DefenceStore";
import { UserCustomStore } from "@src/common/stores";
import { CSVINFO, UISET } from "@src/constants";

import WebTotalLogModel from "@src/pages/TotalLog/WebTotalLog/Models/WebTotalLogModel";

class WebFirewallTotalLogStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }
  root;

  @observable
  tableList: WebTotalLogModel[] = [];

  @observable
  webTotalLogInfo: WebTotalLogModel = new WebTotalLogModel({
    info: null,
    CodeStore: this.root.CodeStore,
  });

  @observable
  ipDefenceType = "DEFENSE_IP_WEBFIREWALL";

  @withLoading("isLoading")
  @action
  getList = async () => {
    this.params.isWaf = true;
    try {
      const tagServicePropertyList = this.tagServicePropertyList;
      const tagValueTypeList = this.tagValueTypeList;
      const getTagServiceDataList = this.openIpTagModal;

      const { list, totalCount, nextCursorMark } = await webTotalLogApis.getWebLogList(this.params);
      this.tableList = list.map((info) => {
        const _info = { ...info, tagServicePropertyList, tagValueTypeList, getTagServiceDataList };
        return new WebTotalLogModel({ info: _info, CodeStore: this.root.CodeStore });
      });

      this.setCursorMarkInfo(nextCursorMark);
      this.totalCount = totalCount;
    } catch (e) {
      this.tableList = [];
      this.totalCount = 0;
      console.log("WebTotalLogStore getList", e);
    }
  };

  getComplianceEntityCountByFilterKeys = async (filterIds) => {
    try {
      const { totalCount } = await complianceSettingApis.getComplianceEntityCountByFilterKeys(filterIds);
      return totalCount;
    } catch (e) {
      return 0;
    }
  };

  @withLoading("isSubLoading")
  @action
  getInfo = async (documentId) => {
    try {
      const tagServicePropertyList = this.tagServicePropertyList;
      const tagValueTypeList = this.tagValueTypeList;
      const getTagServiceDataList = this.openIpTagModal;
      const getTagServiceDataListFn = this.getTagServiceDataList;

      const { info } = await webTotalLogApis.getWebLog(documentId);

      const _info = { ...info, tagServicePropertyList, tagValueTypeList, getTagServiceDataList, getTagServiceDataListFn };

      this.webTotalLogInfo = new WebTotalLogModel({
        info: _info,
        CodeStore: this.root.CodeStore,
      });
      this.root.TicketStore.getPrintTicketInfo("wafweblog", documentId, info.logCreateDate); //get 티켓발행정보
      info.logClientIp && (await DefenceStore.getWafDefenceInfo(this.webTotalLogInfo));
      this.webTotalLogInfo.isReplayAttack && UserCustomStore.getUserCustomProtocol(this.webTotalLogInfo);
    } catch (err) {
      this.webTotalLogInfo = new WebTotalLogModel({
        info: null,
        CodeStore: this.root.CodeStore,
      });
    }
  };

  @action
  downloadCSV = async (isUseBulk?, no?) => {
    try {
      if (this.tableList.length > 0) {
        let csv;
        if (isUseBulk && no) {
          csv = await webTotalLogApis.getWebLogCsvList({
            ...toJS(this.params),
            // serverIds: toJS(this.params.serverIds),
            // typeosList: toJS(this.params.typeosList),
            // serverGroupIds: toJS(this.params.serverGroupIds),
            offset: 0,
            limit: no,
          });
        } else {
          csv = Common_Utils.makeCSV(CSVINFO.TOTALLOG_WEBFIREWALL.head, this.tableList);
        }

        Common_Utils.csvDownload(
          csv,
          Common_Utils.setCsvFileName({
            title: UISET.TOTALLOG_WEBFIREWALL.title,
            category: languageSet("전체로그"),
            ...this.params,
          })
        );
      } else {
        return languageSet("에러_데이터가없습니다");
      }
    } catch ({ data }) {
      console.log(data);
    }
  };
}

export default WebFirewallTotalLogStore;
