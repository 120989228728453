import axiosModules from "../axiosSetting";

let pauthApis = {
  //로그인
  createLoginWeb: ({ userEmail, password, recaptchaToken, isOnPremise }) => {
    return axiosModules.post("/pauth/login/web", {
      userEmail,
      password,
      recaptchaToken,
      isOnPremise,
    });
  },

  //pin인증
  createLoginPin: ({ userEmail, pinPassword, loginAuthToken }) => {
    return axiosModules.post("/pauth/loginauth/loginpin", {
      userEmail,
      pinPassword,
      loginAuthToken,
    });
  },

  //otpMail인증
  createLoginOtpMail: ({ userEmail, otpMailToken, loginAuthToken }) => {
    return axiosModules.post("/pauth/loginauth/loginotpmail", {
      userEmail,
      otpMailToken,
      loginAuthToken,
    });
  },

  //otpMail 재발송
  resendOtpMail: ({ userEmail, loginAuthToken }) => {
    return axiosModules.post("/pauth/loginauth/send/otpmail", { userEmail, loginAuthToken });
  },

  //로그아웃
  setLogoutWeb: () => {
    return axiosModules.get("/pauth/logout");
  },

  //권한추가
  createRoleUserPublic: ({ uuid, password }) => {
    return axiosModules.post("/pauth/roleuser/public/U0001", {
      uuid,
      password,
    });
  },

  //권한삭제
  deleteRoleUserPublic: ({ uuid, password }) => {
    return axiosModules.patch("/pauth/roleuser/public/U0001", {
      uuid,
      password,
    });
  },

  //Public 권한(role) 목록
  getRoleListPublic: params => {
    return axiosModules.get("/pauth/role/public", params);
  },

  //고객지원요청 : 지원 계정 생성 및 로그인
  createSupportLoginWeb: (customerSupportId, param) => {
    return axiosModules.put(`/pauth/customersupportsystemuuid/${customerSupportId}`, param);
  },

  //이용 라이센스 수정
  updateUseLicense: (guid, param) => {
    return axiosModules.put(`/pauth/uselicense/${guid}`, param);
  },

  getLangCookie: () => axiosModules.get("/pauth/lang/cookielang"),
  setLangCookie: paramLang => axiosModules.post("/pauth/lang/cookielang", { paramLang }),
  getGlobalDNS: email => axiosModules.get(`/pauth/login/region/${email}/`),

  getUserPasswordRule: () => axiosModules.get("/pauth/userpasswordrule", { isUse: "1" }),

  //pin번호변경
  updateChangePin: params => axiosModules.put("/pauth/loginauth/changepin", params),

  //비밀번호변경(onPremise)
  updateChangePassword: params => axiosModules.put("/pauth/loginauth/changepassword", params),
};

export default pauthApis;
