import { axiosModules } from "@qubit-utill/src";

export default {
  //대시보드 설정 조회
  getDashBoardConfigInfo: () => {
    return axiosModules.get("/pcustomer/dashboardconfig");
  },

  //대시보드 설정 등록/수정
  createUpdateDashBoardConfig: (data) => {
    return axiosModules.post("/pcustomer/dashboardconfig", data);
  },

  //사용자 대시보드 필터 리스트
  getPersonalDashboardFilterList: (data) => {
    return axiosModules.get("/pcustomer/dashboardfilter", data);
  },

  //사용자 대시보드 필터 리스트
  createPersonalDashboardFilterList: (data) => {
    return axiosModules.post("/pcustomer/dashboardfilter", data);
  },

  //고객 지원 요청 등록
  createCustomerSupport: (data) => {
    return axiosModules.post("/pcustomer/customersupport", data);
  },

  //QnA목록 조회
  getQnaList: (data) => {
    return axiosModules.get("/pcustomer/qnas", data);
  },

  //QnA조회
  getQnaInfo: (qnaId) => {
    return axiosModules.get(`/pcustomer/qna/${qnaId}`);
  },

  //QnA등록
  createQna: (data) => {
    return axiosModules.post("/pcustomer/qna", data);
  },

  //QnA수정
  updateQna: (data) => {
    return axiosModules.put("/pcustomer/qna", data);
  },

  //QnA삭제
  deleteQna: (qnaId) => {
    return axiosModules.del(`/pcustomer/qna/${qnaId}`);
  },

  // 사용자 행동 로그
  // 사용자 행동 로그 > 사용자 행동 카테고리 상위 그룹 목록
  getActionCategoryParentGroupList: (param) => {
    return axiosModules.get("/pcustomer/useractioncategoryparentgroup", param);
  },

  // 사용자 행동 로그 > 사용자 행동 카테고리 그룹 목록
  getActionCategoryGroupList: (userActionCategoryParentGroup) => {
    return axiosModules.get("/pcustomer/useractioncategorygroup", {
      orderDesc: "asc",
      userActionCategoryParentGroup,
    });
  },

  // 사용자 행동 로그 > 사용자 행동 카테고리 목록
  getActionCategoryList: (userActionCategoryGroupType) => {
    return axiosModules.get("/pcustomer/useractioncategory", {
      orderDesc: "asc",
      userActionCategoryGroupType,
    });
  },

  // 사용자 행동 로그 > 사용자 행동 로그 목록
  getUserActionLogList: (param) => {
    return axiosModules.get("/pcustomer/useractionlog", param);
  },

  // 사용자 행동 로그 > 사용자 행동 로그 커스텀 등록
  createCustomUserActionLog: (param) => {
    return axiosModules.post("/pcustomer/useractionlog", param);
  },

  getThemeOptionList: () =>
    axiosModules.get(`/pcustomer/servicepreferencesitemlist`, { servicePreferencesTypeCode: "UI_COLOR_SCHEME" }),
  getThemeUserInfo: () => axiosModules.get(`/pcustomer/userpreferences`, { servicePreferencesTypeCode: "UI_COLOR_SCHEME" }),
  setThemeUserInfo: (info) => axiosModules.post(`/pcustomer/userpreferences`, info),

  // 서비스 이용 환경 설정 > 서비스 환경 설정 목록 조회
  getServicePreferenceList: (info) => {
    return axiosModules.get(`/pcustomer/servicepreferencesitemlist`, info);
  },

  // 서비스 이용 환경 설정 > 사용자 이용 환경 설정 > 사용자 환경 등록 설정 목록 조회
  getUserPreferences: (param) => {
    return axiosModules.get("/pcustomer/userpreferences", param);
  },

  // 서비스 이용 환경 설정 > 사용자 이용 환경 설정 > 사용자 환경 등록 설정 다중 목록 조회
  getUserPreferencesList: (param) => {
    return axiosModules.get("/pcustomer/userpreferencegroups", param);
  },

  // 서비스 이용 환경 설정 > 사용자 이용 환경 설정 > 사용자 환경 등록
  updateUserPreference: (info) => axiosModules.post(`/pcustomer/userpreferences`, info),

  //사용자 정의 환경 설정
  getUserCustomPreferences: (data) => {
    return axiosModules.get("/pcustomer/usercustompreferences", data);
  },

  //사용자 정의 항목 생성
  createUserCustomPreferences: (data) => {
    return axiosModules.post("/pcustomer/usercustompreferences", data);
  },
};
