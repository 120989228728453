/**
 * 포렌식 실행 타입
 * codeGroup: forensicExecuteType
 */
export const FORENSIC_EXECUTE_TYPE_KEY = {
  AUTO: "auto", //자동
  MANUAL: "manual", //수동
} as const;
export type FORENSIC_EXECUTE_TYPE = typeof FORENSIC_EXECUTE_TYPE_KEY[keyof typeof FORENSIC_EXECUTE_TYPE_KEY];

/**
 * 포렌식 수동 타입
 * codeGroup: forensicManualType
 */
export const FORENSIC_MANUAL_TYPE_KEY = {
  SELECT: "select", //선택
  INPUT: "input", //입력
} as const;
export type FORENSIC_MANUAL_TYPE = typeof FORENSIC_MANUAL_TYPE_KEY[keyof typeof FORENSIC_MANUAL_TYPE_KEY];

/**
 * 포렌식 데이터 타입
 * codeGroup: forensicDataType
 */
export const FORENSIC_DATA_TYPE_KEY = {
  ORIGINAL: "original", //이전 데이터
  CURRENT: "current", //현재 데이터
} as const;
export type FORENSIC_DATA_TYPE = typeof FORENSIC_DATA_TYPE_KEY[keyof typeof FORENSIC_DATA_TYPE_KEY];
