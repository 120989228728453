import { axiosModules } from "@qubit-utill/src";

export default {
  getEventTypeList: (param) => {
    return axiosModules.get("/pfilter/filterevent", param);
  },

  getEventTypeInfo: (filterEventId) => {
    return axiosModules.get(`/pfilter/filterevent/${filterEventId}`);
  },

  createEventType: (param) => {
    return axiosModules.post("/pfilter/filterevent", param);
  },

  updateEventType: (param) => {
    return axiosModules.put(`/pfilter/filterevent/${param.filterEventId}`, param);
  },

  getEventTypeChannelLogTypeInfo: (param) => {
    return axiosModules.get("/pfilter/filterevent/channellogtype", param);
  },
};
