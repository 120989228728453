/**
 * 필터 > 등록필터 : 웹 방화벽
 */

import { action, computed, observable, toJS } from "mobx";

import { UserException, languageSet, withLoading, isRunning, ListCondition } from "@qubit-utill/src";

import { webExtendsFiltersApis } from "@src/apis/pFilter";
import { serverGroupApis } from "@src/apis/pServer";

import { DataBreachElementModel } from "@src/pages/Filter/SecurityFilter/models/DataBreach";
import { WebFirewallFilterModel } from "@src/pages/Filter/RegisterFilter/model";
import { FilterCategoryModel } from "@src/pages/Filter/RegisterFilter/model/Filter";

class WebFirewallFilterStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }
  root;

  @observable
  isRunning = false;

  @observable
  isSetLoading = true;

  @observable
  isCopy = false;

  @observable
  isCopyCheck = false;

  @observable
  filterElementReferenceListForSelect = [];

  @observable
  tableList: WebFirewallFilterModel[] = [];

  @observable
  webFirewallFilterInfo = new WebFirewallFilterModel(null, this.root.CodeStore);

  @observable
  webFirewallFilterElementList: DataBreachElementModel[] = [];

  @observable
  filterFieldList: { name: string; value: DataBreachElementModel }[] = [];

  @observable
  filterCategoryList: FilterCategoryModel[] = [];

  @observable
  filterCategoryListForSelect: { name: string; value: FilterCategoryModel }[] = [];

  @observable
  isTotalCheck = false;

  @computed
  get checkList() {
    return this.tableList.filter((data) => data.isSelected);
  }

  @computed
  get totalCheckParams() {
    return this.isTotalCheck
      ? { webExtendsFilterIdsAll: "1" }
      : { webExtendsFilterIds: this.checkList.map(({ webExtendsFilterId }) => webExtendsFilterId) };
  }

  @computed
  get totalCountForUI() {
    return this.isTotalCheck ? this.totalCount : this.checkList.length;
  }

  @computed
  get apiData() {
    let obj: any = this.webFirewallFilterInfo.apiData;
    obj.webExtendsFilterElements = this.webFirewallFilterElementList.map(({ apiData }) => apiData);
    return obj;
  }

  @action
  setIsCopy = (flag) => {
    this.isCopy = flag;
  };
  @action
  setIsCopyCheck = (flag) => {
    this.isCopyCheck = flag;
  };

  @action
  setIsTotalCheck = (flag: boolean) => {
    this.isTotalCheck = flag;
  };

  @action
  allCheck = (checked) => {
    this.tableList.forEach((data) => {
      data.setInfo("isSelected", checked);
    });
  };

  @action
  getCodes = async () => {
    await Promise.all([this.getFieldList().catch(() => null), this.root.ServerGroupStore.getListForOptions().catch(() => null)]);
  };

  @action
  getServerGroupInfo = async (serverGroupId) => {
    try {
      const { result } = await serverGroupApis.getServerGroupInfo(serverGroupId);
      this.webFirewallFilterInfo.setInfo("serverGroup", result.serverGroupInfo.serverGroup);
    } catch ({ data }) {
      return data;
    }
  };

  @withLoading("isSetLoading")
  @action
  getTotalInfo = async (webExtendsFilterId) => {
    await this.getCodes();
    this.getFilterInfo(webExtendsFilterId);
    if (this.webFirewallFilterInfo.serverGroupId) {
      await this.getServerGroupInfo(this.webFirewallFilterInfo.serverGroupId);
    }
  };

  @action
  clearFilterInfo = () => {
    if (this.isCopy) {
      this.isCopy = false;
    }
    if (!this.isCopyCheck) {
      this.webFirewallFilterInfo = new WebFirewallFilterModel(null, this.root.CodeStore);
      this.webFirewallFilterElementList = [];
    }
  };

  @withLoading("isLoading")
  @action
  initFilterInfo = async () => {
    await this.getCodes();
    if (this.isCopyCheck) {
      this.webFirewallFilterInfo.webExtendsFilterName = "";
      this.webFirewallFilterInfo.webExtendsFilterDescription = "";
      this.isCopyCheck = false;
      this.isCopy = true;
    } else {
      this.webFirewallFilterInfo = new WebFirewallFilterModel(null, this.root.CodeStore);
      this.webFirewallFilterElementList = [];
    }
  };

  @isRunning("isRunning")
  @action
  createFilter = async () => {
    try {
      await webExtendsFiltersApis.createWebExtendsFilter(this.apiData);
    } catch ({ data }) {
      console.log(data);
      return data;
    }
  };

  @isRunning("isRunning")
  @action
  updateFilter = async () => {
    try {
      await webExtendsFiltersApis.updateWebExtendsFilter(this.apiData);
    } catch ({ data }) {
      return data;
    }
  };

  @action
  updateCustomConfigIsUse = async (onOff) => {
    const data = { ...this.totalCheckParams, webExtendsFilterType: this.webFirewallFilterInfo.webExtendsFilterType };
    try {
      await webExtendsFiltersApis.updateWebExtendsFilters(onOff ? "1" : "0", data);
    } catch ({ data }) {
      return data;
    }
  };

  @action
  deleteFilter = async () => {
    try {
      await webExtendsFiltersApis.deleteWebExtendsFilter(this.webFirewallFilterInfo.webExtendsFilterId, {
        webExtendsFilterType: this.webFirewallFilterInfo.webExtendsFilterType,
      });
    } catch ({ data }) {
      return data;
    }
  };

  @action
  deleteCustomFilter = async () => {
    try {
      const data = { ...this.totalCheckParams, webExtendsFilterType: "waffilter" };
      await webExtendsFiltersApis.deleteWebExtendsFilters(data);
    } catch ({ data }) {
      return data;
    }
  };

  @withLoading("isLoading")
  @action
  getList = async () => {
    const _params = toJS(this.params);
    const params = {
      ..._params,
      webExtendsFilterType: this.webFirewallFilterInfo.webExtendsFilterType,
      orderDesc: "DESC",
    };

    try {
      const { list, totalCount } = await webExtendsFiltersApis.getWebExtendsFilterList(params);
      this.tableList = list.map((data) => new WebFirewallFilterModel(data, this.root.CodeStore));
      this.totalCount = totalCount;
    } catch (e) {
      console.error("e", e);
      this.tableList = [];
      this.totalCount = 0;
    }
  };

  @withLoading("isLoading")
  @action
  getFilterInfo = async (webExtendsFilterId) => {
    try {
      const { info } = await webExtendsFiltersApis.getWebExtendsFilter(webExtendsFilterId);
      this.webFirewallFilterInfo = new WebFirewallFilterModel(info, this.root.CodeStore);
      this.webFirewallFilterElementList = info.webExtendsFilterElements.map(
        (info) => new DataBreachElementModel(info, this.root.CodeStore, this.filterFieldList)
      );
    } catch (error) {}
  };

  @action
  getFieldList = async () => {
    try {
      const { list } = await webExtendsFiltersApis.getWebExtendsFilterFieldList({
        webExtendsFilterType: this.webFirewallFilterInfo.webExtendsFilterType,
      });
      this.filterFieldList = list.map((info) => ({
        name: info.webExtendsFilterFieldDisplayName,
        value: new DataBreachElementModel(info, this.root.CodeStore),
      }));
    } catch (error) {
      console.log(error);
    }
  };

  @action
  addNewDataType = () => {
    this.webFirewallFilterElementList.push(new DataBreachElementModel(null, this.root.CodeStore));
  };

  @action
  deleteDataElement = (index) => {
    this.webFirewallFilterElementList.splice(index, 1);
  };

  elementValidation = () => {
    if (this.webFirewallFilterElementList.length === 0) {
      throw languageSet("에러_정보를입력해라");
    }
    this.webFirewallFilterElementList.forEach((info) => {
      if (info.webExtendsFilterFieldName === "") {
        throw languageSet("메세지_요소선택");
      }
      info.webExtendsFilterElementSelectors.forEach((info) => {
        info.validation();
      });
    });
  };

  dataTypeValidationCheck = (dataTypeElement) => {
    if (!dataTypeElement.filterElementName) throw new UserException(languageSet("메세지_요소선택"));

    if (dataTypeElement.elementAttributes && !dataTypeElement.filterElementAttributeName)
      throw new UserException(languageSet("메세지_속성선택"));

    if (dataTypeElement.filterEventElementAttributeValues && !dataTypeElement.filterElementAttributeValue)
      throw new UserException(languageSet("메세지_속성값선택"));

    if (dataTypeElement.filterEventElementRepeatSize > 0 && !dataTypeElement.filterElementRepeatSequence)
      throw new UserException(languageSet("메세지_요소수선택"));
  };

  @action
  setIsUse = async (info) => {
    try {
      const { isUseFlag, webExtendsFilterId } = info;
      const willIsUse = !isUseFlag ? "1" : "0";
      const { rowCount } = await webExtendsFiltersApis.updateIsUseWebExtends(webExtendsFilterId, willIsUse, {
        webExtendsFilterType: this.webFirewallFilterInfo.webExtendsFilterType,
      });
      if (rowCount >= 1) info.isUse = willIsUse;
    } catch ({ data }) {
      return data;
    }
  };
}

export default WebFirewallFilterStore;
