import React, { useState, useMemo } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, Button } from "reactstrap";
import { Observer, observer } from "mobx-react";
import { languageSet, FilterDropdown, useInjectStore, useIsToggle, MultiDateSelect } from "@qubit-utill/src";

const BoxPlotCustomOption = () => {
  const { ReportStore } = useInjectStore();
  const { errorMessage, exceptDays, setExceptDays, minDate, getData, scaleOptions, scale, setScale } = ReportStore;
  const { isToggle, setIsToggle } = useIsToggle();
  const [isSetting, setIsSetting] = useState(false);
  const [days, setDays] = useState(exceptDays);
  const disabled = useMemo(() => errorMessage !== "", [errorMessage]);

  const _renderSettingBtn = () =>
    isSetting ? (
      <>
        <p className="label bg-black f-11 m-r-5">
          <a
            onClick={() => {
              setExceptDays(days);
              setIsSetting(!isSetting);
              getData();
            }}
          >
            <span className="c-white">{languageSet("확인")}</span>
          </a>
        </p>
        <p className="label bg-gray f-11">
          <a
            onClick={() => {
              setDays(exceptDays);
              setIsSetting(!isSetting);
            }}
          >
            <span className="c-white">{languageSet("취소")}</span>
          </a>
        </p>
      </>
    ) : (
      <p className="label bg-black f-11">
        <a
          onClick={() => {
            setIsSetting(!isSetting);
          }}
        >
          <span className="c-white">{languageSet("제외설정")}</span>
        </a>
      </p>
    );

  return (
    <>
      <Dropdown
        tag="div"
        isOpen={isToggle}
        toggle={!disabled ? setIsToggle : () => {}}
        className="btn-group f-right p-t-10 p-r-20 cursor_p"
        style={{ position: "relative", zIndex: 999 }}
      >
        <DropdownToggle tag="div">
          <Button color={disabled ? "default" : "dark"} size="sm" className="m-b-0">
            {languageSet("통계제외")}
          </Button>
        </DropdownToggle>
        <DropdownMenu tag="ul" className="f-12">
          <li className="dropdown-item">
            <span>{languageSet("통계제외일_n일", exceptDays.length)}</span>
          </li>
          <li className="divider" />
          <div className="form-group m-b-0 text-center">
            <label className="form-label">
              <i className="fa fa-angle-left"></i>
              {languageSet("제외기간_설정")}
              <i className="fa fa-angle-right p-l-5"></i>
            </label>
            <MultiDateSelect
              disabled={!isSetting}
              propsDays={days}
              minDate={minDate ? new Date(minDate) : null}
              maxDate={new Date()}
              onChange={setDays}
            />
            <span className="z-in3 p-b-5">
              <Observer render={_renderSettingBtn} />
            </span>
          </div>
        </DropdownMenu>
      </Dropdown>
      <FilterDropdown
        rigthClass="f-right f-12 m-t-12"
        items={scaleOptions}
        onSelect={(value: object) => setScale(value)}
        defaultValue={scale}
        style={{ position: "relative", zIndex: 999 }}
      />
    </>
  );
};

export default observer(BoxPlotCustomOption);
