import { axiosModules } from "@qubit-utill/src";

export default {
  //탭메뉴
  getFilterCategoryGroupDayList: (params) => {
    return axiosModules.get("/pstats/networkdetect/day/categorygroup", params);
  },
  //리스트
  getNetworkDayMainDetect: (params) => {
    return axiosModules.get("/pstats/networkdetect/day/main", params);
  },
  //네트워크 > 일 정보 > 일 필터기준 탐지 랭크 목록 조회
  getNetworkDayFilterRankDetect: (params) => {
    return axiosModules.get("/pstats/networkdetect/day/filterrank", params);
  },
  //차트
  getNetworkDayMainChart: (params) => {
    return axiosModules.get("/pstats/networkdetect/day/mainchart", params);
  },
  //서브 차트
  getNetworkDaySubChart: (params) => {
    return axiosModules.get("/pstats/networkdetect/day/subchart", params);
  },
  //리스트 주별
  getNetworkWeekMainDetect: (params) => {
    return axiosModules.get("/pstats/networkdetect/week/main", params);
  },
  //네트워크 > 주 정보 > 주 필터기준 탐지 랭크 목록 조회
  getNetworkWeekFilterRankDetect: (params) => {
    return axiosModules.get("/pstats/networkdetect/week/filterrank", params);
  },
  //차트 주별
  getNetworkWeekMainChart: (params) => {
    return axiosModules.get("/pstats/networkdetect/week/mainchart", params);
  },
  //차트 주별
  getNetworkWeekSubChart: (params) => {
    return axiosModules.get("/pstats/networkdetect/week/subchart", params);
  },
  //리스트 월별
  getNetworkMonthMainDetect: (params) => {
    return axiosModules.get("/pstats/networkdetect/month/main", params);
  },
  //네트워크 > 월 정보 > 월 필터기준 탐지 랭크 목록 조회
  getNetworkMonthFilterRankDetect: (params) => {
    return axiosModules.get("/pstats/networkdetect/month/filterrank", params);
  },
  //차트 월별
  getNetworkMonthMainChart: (params) => {
    return axiosModules.get("/pstats/networkdetect/month/mainchart", params);
  },
  //박스플롯 minDate
  getPlotChartMinDate: () => axiosModules.get("/pstats/networkdetect/plotchart/mindate"),
  //카테고리 그룹 리스트 (카운트 x)
  getFilterCategoryGroupList: () => axiosModules.get("/pstats/networkdetect/categorygroup"),
  //박스플롯 Data
  getPlotChart: (params) => axiosModules.get(`/pstats/networkdetect/plotchart`, params),
};
