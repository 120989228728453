import { observable } from "mobx";

import { SetterModel } from "@qubit-utill/src";

export default class HAProxyPropertyModel extends SetterModel {
  constructor(params?) {
    super(params);
    if (params) {
      if (params.isSelect === null) {
        this.isSelect = "0";
      }
    }
  }

  @observable
  middlewareUserPropertyId: number = 0;

  @observable
  middlewarePropertyKey: string = "";

  @observable
  middlewarePropertyValue: string = "";

  @observable
  registerDate: string = "";

  @observable
  updateDate: string = "";

  @observable
  registerUuid: string = "";

  @observable
  updateUuid: string = "";

  @observable
  middlewareUserSectionId: number = 0;

  @observable
  isSelect: string = "0";
}
