import { axiosModules } from "@qubit-utill/src";

export default {
  // plog > 응용프로그램로그 > appLog 조회
  getAppLog: (documentId) => {
    return axiosModules.get(`/plog/applog/${documentId}`);
  },

  // plog > 응용프로그램로그 > appLog List CSV 다운로드
  getAppLogCsvList: (params) => {
    return axiosModules.getCsv("/plog/applog/list/csv", params);
  },

  // plog > 응용프로그램로그 > appLog List 조회
  getAppLogList: (params) => {
    return axiosModules.get("/plog/applog/list", params);
  },

  // plog > 사용자응용프로그램로그 > resourceLog 조회
  getResourceLog: (documentId) => {
    return axiosModules.get(`/plog/resourcelog/${documentId}`);
  },

  // plog > 사용자응용프로그램로그 > resourceLog List CSV 다운로드
  getResourceLogCsvList: (params) => {
    return axiosModules.getCsv("/plog/resourcelog/list/csv", params);
  },

  // plog > 사용자응용프로그램로그 > resourceLog List 조회
  getResourceLogList: (params) => {
    return axiosModules.get("/plog/resourcelog/list", params);
  },

  // plog > 사용자응용프로그램로그 > 리소스모니터링 시계열 그래프 조회
  getResourceMonitoringGraphInfo: (param) => {
    return axiosModules.get("/plog/resourcelog/timeseries/list", param);
  },
};
