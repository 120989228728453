import ReportStore from "@src/pages/Report/stores/extends/ReportStore";

class APIClass extends ReportStore {
  isSystem = false;

  getDayTop10ListApi = (params?) => ({
    list: [],
    totalCount: 0,
  });

  getDayChartApi = (params?) => ({
    categoryGroupList: [],
    dailySumList: [],
    hourSumList: [],
    riskSumList: [],
    riskLevelCodeSource: "",
    startDetectDate: "",
    endDetectDate: "",
    statusSumList: [],
    methodSumList: [],
    yesterdayHourSumList: [],
    statusCountSumList: {},
    weekHourSumList: [],
  });

  getDaySubChartApi = (params?) => ({
    hourSumList: [],
    riskSumList: [],
    riskLevelCodeSource: "",
    statusSumList: [],
    methodSumList: [],
    yesterdayHourSumList: [],
    statusCountSumList: {},
    weekHourSumList: [],
  });

  getWeekTop10ListApi = (params?) => ({
    list: [],
    totalCount: 0,
  });

  getWeekChartApi = (params?) => ({
    riskSumList: [],
    categoryGroupNames: [],
    categoryDataList: [],
    weeklySumList: [],
    riskLevelCodeSource: "",
    statusSumList: [],
    searchWeeklyList: [],
    weeklyFilterCategorySumList: [],
    methodSumList: [],
    statusCountSumList: {},
    weekSumList: [],
    detectDate: "",
  });

  getWeekSubChartApi = (params?) => ({
    categoryGroupNames: [],
    categoryDataList: [],
    riskSumList: [],
    riskLevelCodeSource: "",
    statusSumList: [],
    methodSumList: [],
    statusCountSumList: {},
  });

  getMonthTop10ListApi = (params?) => ({
    list: [],
    totalCount: 0,
  });

  getMonthChartApi = (params?) => ({
    categoryGroupNames: [],
    categoryDataList: [
      {
        detectCount: 0,
        filterCategoryGroupId: 0,
        filterCategoryGroupName: "",
      },
    ],
    categoryDataListBefore: [
      {
        detectCount: 0,
        filterCategoryGroupId: 0,
        filterCategoryGroupName: "",
      },
    ],
    monthlySumList: [],
    monthlySumListBefore: [],
    riskLevelCodeSource: "",
    riskSumList: [],
    statusSumList: [],
    methodSumList: [],
    statusCountSumList: {},
  });
}

export default APIClass;
