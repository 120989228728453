import { observable, computed } from "mobx";

import { SetterModel, Common_Utils } from "@qubit-utill/src";

class LogTagListModel extends SetterModel {
  @computed
  get getRegisterUserName() {
    return Common_Utils.isDataWithDashBar(this.registerUserName);
  }

  @observable
  logTag: string = "";

  @observable
  registerDate: string = "";

  @observable
  registerUserName: string = "";

  @observable
  totalReferenceCount: number = 0;

  @observable
  logTagReferenceList: Array<object> = [];
}

export default LogTagListModel;
