import { observable, computed } from "mobx";

import { Common_Utils, languageSet } from "@qubit-utill/src";
import { SystemLogModel } from "@src/common/models";

export default class HostSecurityTotalLogModel extends SystemLogModel {
  constructor(params) {
    super(params);
    if (params) {
      this.logHash = params.logHash || "-";
      this.withMicroSecondsLogCreateDate = params.logCreateDate + params.logCreateMicroSeconds;
    }
  }

  @computed
  get totalLogIp() {
    return this.hostIp || this.serverIp;
  }

  @computed
  get attackIp() {
    return this.logClientIp;
  }

  @computed
  get filterIds() {
    return this.filters ? this.filters.map(({ filterId }) => filterId) : [this.filterId];
  }

  @computed
  get isDefenceFlag() {
    return Common_Utils.isCheckBool(this.isDefence);
  }

  @computed
  get getServerGroup() {
    return Common_Utils.isDataWithDashBar(this.serverGroup);
  }

  @computed
  get edrDetectTypeText() {
    return this.edrDetectType === "detect"
      ? languageSet("탐지")
      : this.edrDetectType === "defense"
      ? languageSet("차단")
      : Common_Utils.isDataWithDashBar(this.edrDetectType);
  }

  @observable
  filters = [];

  @observable
  filterId: string = "";

  @observable
  documentId: string = "";

  @observable
  serverGroup: string = "";

  @observable
  typeos: string = "";

  @observable
  serverIp: string = "";

  @observable
  hostIp: string = "";

  @observable
  hostName: string = "";

  @observable
  logSeverity: string = "";

  @observable
  filterChannelName: string = "";

  @observable
  logCreateDate: string = "";

  @observable
  serverId: string = "";
  //로그 상세
  @observable
  logPriorityId: string = "";

  @observable
  logHash: string = "";

  @observable
  logOrigin: string = "";

  @observable
  filterEventKey: string = "";

  @observable
  logMessage: string = "";

  @observable
  filterEventName: string = "";

  @observable
  logClientIp: string = "";

  @observable
  detectDefenseId: number = 0;

  @observable
  isActive: string = "0";

  @observable
  isDefence: string = "";

  defenseConfigFilterType: string = "filter";

  @observable
  logCreateMicroSeconds: string = "";

  @observable
  withMicroSecondsLogCreateDate: string = "";

  @observable
  edrDetectType: string | null = "";
}
