import { axiosModules } from "@qubit-utill/src";

export default {
  getLogMaskingFilterList: (params) => {
    return axiosModules.get(`/pfilter/logmaskingfilter`, params);
  },
  createUpdateLogMaskingFilter: (data) => {
    return axiosModules.post(`/pfilter/logmaskingfilter`, data);
  },
};
