import {
  CodeStore,
  CookieStore,
  RoleLicenseStore,
  ModalStore,
  SearchWordStore,
  SearchListFilterStore,
  LoadingStore,
  LogBackupStore,
} from "@qubit-utill/src";
import { observable, action } from "mobx";
import TicketListStore from "@src/pages/Ticket/stores/TicketListStore";
import MyInfoStore from "@src/pages/MyInfo/stores/MyInfoStore";

import SystemRecommendFilterStore from "@src/pages/Filter/RecommendFilter/stores/SystemRecommendFilterStore";
import ApplicationRecommendFilterStore from "@src/pages/Filter/RecommendFilter/stores/ApplicationRecommendFilterStore";
import NetworkRecommendFilterStore from "@src/pages/Filter/RecommendFilter/stores/NetworkRecommendFilterStore";

import SystemFilterStore from "@src/pages/Filter/RegisterFilter/stores/SystemFilterStore";
import ApplicationFilterDefaultStore from "@src/pages/Filter/RegisterFilter/stores/ApplicationFilterDefaultStore";
import ApplicationFilterResourceStore from "@src/pages/Filter/RegisterFilter/stores/ApplicationFilterResourceStore";
import WebFilterStore from "@src/pages/Filter/RegisterFilter/stores/WebFilterStore";
import NetworkFilterStore from "@src/pages/Filter/RegisterFilter/stores/NetworkFilterStore";

import GroupStore from "@src/pages/Group/stores/GroupStore";
import MemberStore from "@src/pages/Group/Member/stores/MemberStore";
import SystemDefenceStore from "@src/pages/Defence/DefenceIp/stores/SystemDefenceStore";
import WebFirewallDefenceStore from "@src/pages/Defence/DefenceIp/stores/WebFirewallDefenceStore";
import SystemLogStore from "@src/pages/Defence/DefenseLog/stores/SystemLogStore";
import WebFirewallLogStore from "@src/pages/Defence/DefenseLog/stores/WebFirewallLogStore";
import IpExtractionStore from "@src/pages/Extraction/IpExtraction/stores/IpExtractionStore";
import TicketStore from "@src/pages/Group/Interlock/Ticket/stores/TicketStore";
import WebFirewallTotalLogStore from "@src/pages/TotalLog/WebFirewallTotalLog/stores/WebFirewallTotalLogStore";

import WebFirewallFilterStore from "@src/pages/Filter/RegisterFilter/stores/WebFirewallFilterStore";
import HostSecurityFilterDetectStore from "@src/pages/FilterDetection/HostSecurityFilterDetection/stores/HostSecurityFilterDetectStore";
import SystemFilterDetectStore from "@src/pages/FilterDetection/SystemFilterDetection/stores/SystemFilterDetectStore";
import ApplicationDefaultFilterDetectStore from "@src/pages/FilterDetection/ApplicationFilterDetection/Default/stores/ApplicationDefaultFilterDetectStore";
import ApplicationResourceFilterDetectStore from "@src/pages/FilterDetection/ApplicationFilterDetection/Resource/stores/ApplicationResourceFilterDetectStore";
import WebFilterDetectStore from "@src/pages/FilterDetection/WebFilterDetection/stores/WebFilterDetectStore";
import NetworkFilterDetectStore from "@src/pages/FilterDetection/NetworkFilterDetection/stores/NetworkFilterDetectStore";
import WebFirewallFilterDetectStore from "@src/pages/FilterDetection/WebFirewallFilterDetection/stores/WebFirewallFilterDetectStore";

import SystemTotalLogStore from "@src/pages/TotalLog/SystemTotalLog/stores/SystemTotalLogStore";
import ApplicationDefaultTotalLogStore from "@src/pages/TotalLog/ApplicationTotalLog/Default/stores/ApplicationDefaultTotalLogStore";
import ApplicationResourceTotalLogStore from "@src/pages/TotalLog/ApplicationTotalLog/Resource/stores/ApplicationResourceTotalLogStore";
import WebTotalLogStore from "@src/pages/TotalLog/WebTotalLog/stores/WebTotalLogStore";
import NetworkTotalLogStore from "@src/pages/TotalLog/NetworkTotalLog/stores/NetworkTotalLogStore";
import HostSecurityTotalLogStore from "@src/pages/TotalLog/HostSecurityTotalLog/stores/HostSecurityTotalLogStore";

import ServerStore from "@src/pages/Server/ServerManagement/stores/ServerStore";
import LogConfigPathModalStore from "@src/pages/Server/ServerManagement/stores/LogConfigPathModalStore";
import ServerGroupStore from "@src/pages/Server/ServerGroupManagement/stores/ServerGroupStore";
import SettingDetectStore from "@src/pages/Group/SettingDetect/stores/SettingDetectStore";
import GroupSystemStore from "@src/pages/Group/System/store/GroupSystemStore";
import GroupWafStore from "@src/pages/Group/WAF/stores/GroupWafStore";
import GroupFirewallStore from "@src/pages/Group/Firewall/stores/GroupFirewallStore";
import GroupHAProxyStore from "@src/pages/Group/HAProxy/stores/GroupHAProxyStore";
import GroupSquidStore from "@src/pages/Group/SQUID/stores/GroupSquidStore";
import ThemeStore from "@src/pages/Theme/stores/ThemeStore";
import DataBreachFilterStore from "@src/pages/Filter/SecurityFilter/stores/DataBreachFilterStore";
import ExtortAccountFilterStore from "@src/pages/Filter/SecurityFilter/stores/ExtortAccountFilterStore";
import InstallStore from "@src/pages/Install/stores/InstallStore";
import ChangePasswordGuideStore from "@src/common/stores/ChangePasswordGuideStore";
import IpMacAddressStore from "@src/pages/Group/RefItem/stores/IpMacAddressStore";
import LogCountByChannelStore from "@src/pages/Statistic/LogUpLoad/stores/LogCountByChannelStore";
import HostResourceMonitoringStore from "@src/pages/ResourceMonitoring/HostResourceMonitoring/stores/HostResourceMonitoringStore";
import HostTotalResourceMonitoringStore from "@src/pages/ResourceMonitoring/HostTotalResourceMonitoring/store/HostTotalResourceMonitoringStore";
import { SystemIpFileUploadStore, WebFirewallIpFileUploadStore } from "@src/pages/Defence/DefenceIp/stores";

class RootStore {
  constructor() {
    this.ServerStore = new ServerStore(this);
    this.LogConfigPathModalStore = new LogConfigPathModalStore(this);
    this.CodeStore = new CodeStore();
    this.TicketListStore = new TicketListStore(this);
    this.MyInfoStore = new MyInfoStore(this);
    this.CookieStore = new CookieStore(this);

    //필터탐지
    this.WebFirewallFilterDetectStore = new WebFirewallFilterDetectStore(this);
    this.HostSecurityFilterDetectStore = new HostSecurityFilterDetectStore(this);
    this.SystemFilterDetectStore = new SystemFilterDetectStore(this);
    this.ApplicationDefaultFilterDetectStore = new ApplicationDefaultFilterDetectStore(this);
    this.ApplicationResourceFilterDetectStore = new ApplicationResourceFilterDetectStore(this);
    this.WebFilterDetectStore = new WebFilterDetectStore(this);
    this.NetworkFilterDetectStore = new NetworkFilterDetectStore(this);

    //전체로그
    this.SystemTotalLogStore = new SystemTotalLogStore(this);
    this.ApplicationDefaultTotalLogStore = new ApplicationDefaultTotalLogStore(this);
    this.ApplicationResourceTotalLogStore = new ApplicationResourceTotalLogStore(this);
    this.WebTotalLogStore = new WebTotalLogStore(this);
    this.NetworkTotalLogStore = new NetworkTotalLogStore(this);
    this.WebFirewallTotalLogStore = new WebFirewallTotalLogStore(this);
    this.HostSecurityTotalLogStore = new HostSecurityTotalLogStore(this);

    //필터 > 추천
    this.SystemRecommendFilterStore = new SystemRecommendFilterStore(this);
    this.ApplicationRecommendFilterStore = new ApplicationRecommendFilterStore(this);
    this.NetworkRecommendFilterStore = new NetworkRecommendFilterStore(this);

    //필터 > 등록
    this.WebFirewallFilterStore = new WebFirewallFilterStore(this);
    this.SystemFilterStore = new SystemFilterStore(this);
    this.ApplicationFilterDefaultStore = new ApplicationFilterDefaultStore(this);
    this.ApplicationFilterResourceStore = new ApplicationFilterResourceStore(this);
    this.WebFilterStore = new WebFilterStore(this);
    this.NetworkFilterStore = new NetworkFilterStore(this);

    this.RoleLicenseStore = new RoleLicenseStore(this);
    this.GroupStore = new GroupStore(this);
    this.MemberStore = new MemberStore(this);
    this.SystemDefenceStore = new SystemDefenceStore(this);
    this.WebFirewallDefenceStore = new WebFirewallDefenceStore(this);
    this.SystemLogStore = new SystemLogStore(this);
    this.WebFirewallLogStore = new WebFirewallLogStore(this);
    this.IpExtractionStore = new IpExtractionStore(this);
    this.TicketStore = new TicketStore(this);

    this.ServerGroupStore = new ServerGroupStore(this);
    this.ModalStore = ModalStore;
    this.SettingDetectStore = new SettingDetectStore(this);
    this.SearchWordStore = new SearchWordStore(this);
    this.SearchListFilterStore = new SearchListFilterStore(this);
    this.ThemeStore = new ThemeStore(this);
    this.LoadingStore = LoadingStore;
    this.DataBreachFilterStore = new DataBreachFilterStore(this);
    this.ExtortAccountFilterStore = new ExtortAccountFilterStore(this);
    this.GroupSystemStore = new GroupSystemStore(this);
    this.GroupWafStore = new GroupWafStore(this);
    this.GroupFirewallStore = new GroupFirewallStore(this);
    this.GroupHAProxyStore = new GroupHAProxyStore(this);
    this.GroupSquidStore = new GroupSquidStore(this);
    this.InstallStore = new InstallStore(this);
    this.ChangePasswordGuideStore = new ChangePasswordGuideStore(this);
    this.IpMacAddressStore = new IpMacAddressStore(this);
    this.LogBackupStore = new LogBackupStore(this);
    this.LogCountByChannelStore = new LogCountByChannelStore(this, this.CodeStore);
    this.HostResourceMonitoringStore = new HostResourceMonitoringStore(this);
    this.HostTotalResourceMonitoringStore = new HostTotalResourceMonitoringStore(this);
    this.SystemIpFileUploadStore = new SystemIpFileUploadStore();
    this.WebFirewallIpFileUploadStore = new WebFirewallIpFileUploadStore();
  }

  @action
  getInitUserInfo = () => {
    this.RoleLicenseStore.getUserLicense();
    this.MyInfoStore.getUserInfo();
    this.GroupStore.getIsUseResourceFlag();
  };

  @observable
  ModalStore;

  @observable
  ServerStore;

  @observable
  LogConfigPathModalStore;

  @observable
  ServerGroupStore;

  @observable
  SystemTotalLogStore;

  @observable
  ApplicationDefaultTotalLogStore;

  @observable
  ApplicationResourceTotalLogStore;

  @observable
  WebTotalLogStore;

  @observable
  NetworkTotalLogStore;

  @observable
  WebFirewallTotalLogStore;

  @observable
  TicketStore;

  @observable
  MemberStore;

  @observable
  GroupStore;

  @observable
  TicketListStore;

  @observable
  WebFilterDetectStore;

  @observable
  CookieStore;

  @observable
  MyInfoStore;

  @observable
  CodeStore;

  @observable
  RecommendFilterStore;

  @observable
  SystemFilterDetectStore;

  @observable
  ApplicationDefaultFilterDetectStore;

  @observable
  ApplicationResourceFilterDetectStore;

  @observable
  NetworkFilterDetectStore;

  @observable
  SystemRecommendFilterStore;

  @observable
  ApplicationRecommendFilterStore;

  @observable
  NetworkRecommendFilterStore;

  @observable
  WebFirewallFilterStore;

  @observable
  SystemFilterStore;

  @observable
  ApplicationFilterDefaultStore;

  @observable
  ApplicationFilterResourceStore;

  @observable
  WebFilterStore;

  @observable
  NetworkFilterStore;

  @observable
  RoleLicenseStore;

  @observable
  SystemDefenceStore;

  @observable
  WebFirewallDefenceStore;

  @observable
  SystemLogStore;

  @observable
  WebFirewallLogStore;

  @observable
  IpExtractionStore;

  @observable
  WebFirewallFilterDetectStore;

  @observable
  SettingDetectStore;

  @observable
  SearchWordStore;

  @observable
  SearchListFilterStore;

  @observable
  ThemeStore;

  @observable
  LoadingStore;

  @observable
  DataBreachFilterStore;

  @observable
  ExtortAccountFilterStore;

  @observable
  GroupSystemStore;

  @observable
  GroupWafStore;

  @observable
  GroupFirewallStore;

  @observable
  GroupHAProxyStore;

  @observable
  GroupSquidStore;

  @observable
  InstallStore;

  @observable
  ChangePasswordGuideStore;

  @observable
  IpMacAddressStore;

  @observable
  LogBackupStore;

  @observable
  LogCountByChannelStore;

  @observable
  HostSecurityFilterDetectStore;

  @observable
  HostSecurityTotalLogStore;

  @observable
  HostResourceMonitoringStore;

  @observable
  HostTotalResourceMonitoringStore;

  @observable
  SystemIpFileUploadStore;

  @observable
  WebFirewallIpFileUploadStore;
}

export default new RootStore();
