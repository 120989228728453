import { axiosModules } from "@qubit-utill/src";

export default {
  // pfilter > 방어 > 방어 사용자 설정 > 방어 사용자 설정 탭 목록
  getDefenseCustomConfigListByTab: (params) => {
    return axiosModules.get("/pfilter/defensecustomconfig/tab", params);
  },

  // pfilter > 방어 > 방어 사용자 설정 > 방어 사용자 설정 탭 1개 정보
  getDefenseCustomConfigInfoByTab: (defenseConfigCode) => {
    return axiosModules.get(`/pfilter/defensecustomconfig/tab/${defenseConfigCode}`);
  },

  // pfilter > 방어 > 방어 사용자 설정 > 방어 사용자 설정 탭 1개 정보 등록
  createDefenseCustomConfigInfoByTab: (defenseConfigCode, datas) => {
    return axiosModules.post(`/pfilter/defensecustomconfig/tab/${defenseConfigCode}`, {
      ...datas,
    });
  },

  // pfilter > 방어 > 방어 설정 필터 > 방어 설정 필터 정보 리스트
  getDefenseConfigFilterList: (params) => axiosModules.get("/pfilter/defenseconfigfilter/list", params),
};
