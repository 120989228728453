import { observable } from "mobx";
import { SetterModel } from "@qubit-utill/src";

export default class ResourceConfigModel extends SetterModel {
  constructor(params, isFirst?, useList?) {
    super(params);

    if (isFirst && isFirst === "1") {
      this.isSelected = true;
    }
    if (useList) {
      this.isSelected = useList.some((data) => data.resourceId === params.resourceId);
    }
  }

  @observable
  isSelected: boolean = false;

  @observable
  resourceId: string = "";

  @observable
  resourceName: string = "";
}
