import { action, computed, observable } from "mobx";

import { Common_Utils, dateUtil, languageSet, SetterModel } from "@qubit-utill/src";

import { GroupWafProxyModel, GroupWafHealthModel, GroupWafNoticeExceptIpModel } from "@src/pages/Group/WAF/models";
import validFilterTimeRange from "@src/pages/Filter/valid/validFilterTimeRange";

export default class GroupWafModel extends SetterModel {
  constructor(root, params?) {
    super(params);
    this.root = root;
    if (params) {
      const {
        serverGroupId,
        serverGroup,
        trafficRate,
        trafficBurst,
        scheduleDowCustom,
        scheduleHourCustom,
        wafConfigProxyList,
        wafConfigHealthCheckerList,
        scheduleDowType,
        scheduleHourType,
        wafConfigExceptIpList,
      } = params;
      this.wafUploadModeName = root.CodeStore.getCodeName("wafUploadMode", params.wafUploadMode);

      this.groupId = serverGroupId;
      this.groupName = serverGroup;
      this.rate = trafficRate === null ? 30 : trafficRate;
      this.burst = trafficBurst === null ? 10 : trafficBurst;
      this.week = scheduleDowCustom ? scheduleDowCustom.split(",") : [];
      if (scheduleHourCustom) {
        const [start, end] = scheduleHourCustom.split(",");
        this.startTime = new Date();
        this.startTime.setHours(+start);
        this.startTime.setMinutes(0);
        this.endTime = new Date();
        this.endTime.setHours(+end);
        this.endTime.setMinutes(0);
      }
      if (wafConfigProxyList && wafConfigProxyList.length > 0) {
        this.proxyList = wafConfigProxyList.map((info) => new GroupWafProxyModel(info, ++this.indexNumber));
      }
      if (wafConfigHealthCheckerList && wafConfigHealthCheckerList.length > 0) {
        this.healthList = wafConfigHealthCheckerList.map((info) => new GroupWafHealthModel(info));
      }
      if (!scheduleDowType) {
        this.scheduleDowType = "everyday";
      }
      if (!scheduleHourType) {
        this.scheduleHourType = "all";
      }
      if (wafConfigExceptIpList && wafConfigExceptIpList.length > 0) {
        this.wafConfigExceptIpList = wafConfigExceptIpList.map((exceptIpInfo) => new GroupWafNoticeExceptIpModel(exceptIpInfo));
      }
    }
  }
  root;

  @observable
  indexNumber = 0;

  @observable
  isSyncWaf: "0" | "1" = "0";
  @computed
  get isSyncWafFlag() {
    return Common_Utils.isCheckBool(this.isSyncWaf);
  }
  @observable
  wafConfigId = 0;
  @observable
  groupId = 0;
  @observable
  groupName = "";
  @observable
  isWafBlockMode: "0" | "1" = "0";
  @computed
  get isWafBlockModeFlag() {
    return Common_Utils.isCheckBool(this.isWafBlockMode);
  }

  @observable
  isInXForwardedFor: "0" | "1" = "0";

  @computed
  get isInXForwardedForFlag() {
    return Common_Utils.isCheckBool(this.isInXForwardedFor);
  }

  @observable
  outXForwardedForType = "";

  @computed
  get outXForwardedForText() {
    return this.root.CodeStore.getCodeName("outXForwardedForType", this.outXForwardedForType);
  }

  @observable
  isUseProxy: "0" | "1" = "0";
  @computed
  get isUseProxyFlag() {
    return Common_Utils.isCheckBool(this.isUseProxy);
  }

  @observable
  isUseHealthChecker: "0" | "1" = "0";

  @computed
  get isUseHealthCheckerFlag() {
    return Common_Utils.isCheckBool(this.isUseHealthChecker);
  }

  @observable
  isMaintenance: string = "0";

  @computed
  get isMaintenanceFlag() {
    return Common_Utils.isCheckBool(this.isMaintenance);
  }

  @observable
  isUseMaintenanceExceptIp: string = "0";

  @computed
  get isUseMaintenanceExceptIpFlag() {
    return Common_Utils.isCheckBool(this.isUseMaintenanceExceptIp);
  }

  @observable
  proxyList: GroupWafProxyModel[] = [];

  @observable
  healthList: GroupWafHealthModel[] = [];

  @action
  pushProxy = () => {
    this.proxyList.push(new GroupWafProxyModel(null, ++this.indexNumber));
  };

  @action
  pushHealth = () => {
    this.healthList.push(new GroupWafHealthModel(null));
  };

  @action
  deleteProxy = (index) => {
    this.proxyList.splice(index, 1);
    --this.indexNumber;
  };

  @action
  deleteHealth = (index) => {
    this.healthList.splice(index, 1);
  };

  @observable
  isUseSecurity: "0" | "1" = "0";
  @computed
  get isUseSecurityFlag() {
    return Common_Utils.isCheckBool(this.isUseSecurity);
  }

  @observable
  isUseTraffic: "0" | "1" = "0";
  @computed
  get isUseTrafficFlag() {
    return Common_Utils.isCheckBool(this.isUseTraffic);
  }
  @observable
  rate: number | string = 30;
  @observable
  burst: number | string = 10;
  @observable
  isUseCra: "0" | "1" = "0";
  @computed
  get isUseCraFlag() {
    return Common_Utils.isCheckBool(this.isUseCra);
  }
  @computed
  get isUseTimeRange() {
    return this.isUseTrafficFlag || this.isUseCraFlag;
  }
  @observable
  scheduleDowType = "everyday";
  @computed
  get scheduleDowTypeName() {
    return this.root.CodeStore.getCodeName("scheduleDowType", this.scheduleDowType);
  }
  @computed
  get scheduleDowTypeDisplayName() {
    if (this.scheduleDowType === "custom") {
      return this.week.map((index) => dateUtil.getWeekDayStartMonday(index - 1)).toString();
    } else {
      return this.root.CodeStore.getCodeName("scheduleDowType", this.scheduleDowType);
    }
  }
  @observable
  scheduleHourType = "all";
  @computed
  get scheduleHourTypeName() {
    return this.root.CodeStore.getCodeName("scheduleHourType", this.scheduleHourType);
  }
  @computed
  get scheduleHourTypeDisplayName() {
    if (this.scheduleHourType === "custom") {
      return `${this.startTimeFormat} ~ ${this.endTimeFormat}`;
    } else {
      return this.root.CodeStore.getCodeName("scheduleHourType", this.scheduleHourType);
    }
  }
  @computed
  get isCustomHour() {
    return this.scheduleHourType === "custom";
  }
  @observable
  startTime: Date | null = null;
  @observable
  endTime: Date | null = null;
  @computed
  get startTimeFormat() {
    return dateUtil.format(this.startTime, "HH:mm");
  }
  @computed
  get endTimeFormat() {
    return dateUtil.format(this.endTime, "HH:mm");
  }
  @observable
  week: number[] = [];

  @observable
  wafUploadModeName = "";

  @observable
  wafUploadMode = "10";

  @observable
  noticeText: string = "";

  @observable
  wafConfigExceptIpList: Array<GroupWafNoticeExceptIpModel> = [];

  @observable
  blockNoticeText: string = "";

  validTime = () => {
    const { isCustomHour, startTime, endTime, startTimeFormat, endTimeFormat } = this;
    return validFilterTimeRange(isCustomHour, startTime, endTime, startTimeFormat, endTimeFormat);
  };

  limitValidation = () => {
    if (!this.isUseTrafficFlag && !this.isUseCraFlag) {
      throw languageSet("메세지_설정체크");
    }

    if (this.isUseTrafficFlag && (this.rate < 1 || this.rate === "")) {
      throw languageSet("메세지_rate를입력해주세요");
    }
    if (this.isUseTrafficFlag && (this.rate < 1 || this.rate > 60 || this.burst < 0 || this.burst > 20)) {
      throw languageSet("입력된_정보를_확인해주세요");
    }
    if (this.isUseTrafficFlag && this.burst === "") {
      throw languageSet("메세지_burst를입력해주세요");
    }
    if (this.scheduleDowType === "custom" && this.week.length === 0) {
      throw languageSet("메세지_요일선택해주세요");
    }
    if (this.isUseTimeRange) {
      const { isValid, message } = this.validTime();
      if (!isValid) {
        throw message;
      }
    }
  };

  @computed
  get limitApiData() {
    const {
      isUseSecurity,
      isUseTraffic,
      rate,
      burst,
      isUseCra,
      startTime,
      endTime,
      week,
      scheduleDowType,
      scheduleHourType,
    } = this;

    return {
      isUseSecurity,
      isUseTraffic,
      trafficRate: rate,
      trafficBurst: burst,
      isUseCra,
      scheduleDowType,
      scheduleDowCustom: week.toString(),
      scheduleHourType,
      scheduleHourCustom: `${startTime && startTime.getHours()},${endTime && endTime.getHours()}`,
    };
  }
}
