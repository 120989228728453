import { observable } from "mobx"
import SetterModel from "./SetterModel"

class OsOptionModel extends SetterModel {
  constructor(param, keyName) {
    super(param)
    this.name = param[keyName]
    this.value = param
  }

  @observable
  name = ""

  @observable
  value = {}

  @observable
  isChecked = false
}

export default OsOptionModel
