export { default as applicationReportApis } from "./applicationReportApis";
export { default as correlationReportApis } from "./correlationReportApis";
export { default as hostSecurityReportApis } from "./hostSecurityReportApis";
export { default as ipReportApis } from "./ipReportApis";
export { default as logUploadReportApis } from "./logUploadReportApis";
export { default as mainReportApis } from "./mainReportApis";
export { default as networkReportApis } from "./networkReportApis";
export { default as systemReportApis } from "./systemReportApis";
export { default as webExtendsReportApis } from "./webExtendsReportApis";
export { default as webFirewallReportApis } from "./webFirewallReportApis";
export { default as webReportApis } from "./webReportApis";
