import { observable, action, toJS } from "mobx";

import { Common_Utils } from "@qubit-utill/src/utils";

import { codeApis, channelApis } from "@qubit-utill/src/apis";

import { SearchWordModel, SearchColumnModel } from "../model";

class SearchWordStore {
  constructor(root) {
    this.root = root;
  }
  root;

  @observable
  codeList = [];

  @observable
  searchWordList = {}; //검색어리스트

  @observable
  searchOptions = []; //검색옵션

  @observable
  _resourceId = "";

  @observable
  filedIndex: number = 0;

  @observable
  allFilterNameList: Array<{ key: string; value: Array<{ name: string; value: string }> }> = [];

  @observable
  filterNameList: Array<{ name: string; value: string; typeos: string }> = [];

  @observable
  allChannelList: Array<{
    key: string;
    value: Array<{ name: string; value: string; typeos: string }>;
  }> = [];

  @observable
  channelList: Array<{ name: string; value: string; typeos: string }> = [];

  @action
  setFieldIndex = async filedIndex => {
    if (filedIndex) {
      this.filedIndex = await filedIndex;
    }
    this.filedIndex++;
  };

  @action
  setResourceId = (value: string) => {
    this._resourceId = value;
  };

  @action
  getPathName = (resourceId: string) => {
    const pathName = Common_Utils.getCurrentPathName();
    return resourceId ? `${pathName}${resourceId}` : pathName;
  };

  @action
  deleteSearchWord = (currentPageName: string) => {
    currentPageName &&
      this.searchWordList[currentPageName] &&
      this.searchWordList[currentPageName].forEach((info: any, index: number) => {
        if (!info.isFix) {
          this.searchWordList[currentPageName].splice(index, 1);
        }
      });
  };

  @action
  createSearchWord = (resourceId: string) => {
    const pathName = this.getPathName(resourceId);
    const localList = window.localStorage.getItem(pathName);

    const resourceList = ["totallogapplicationresource", "filterdetectapplicationresource"];

    const check = !resourceList.some(info => info === pathName);

    if (check) {
      if (!this.searchWordList[pathName]) {
        this.searchWordList[pathName] = localList
          ? observable(
              JSON.parse(localList).map(
                (data: SearchWordModel) => new SearchWordModel(data, true, this.setFieldIndex)
              )
            )
          : observable([]);
      }
    }
  };

  @action
  pushData = (data: any, resourceId: string) => {
    const pathName = this.getPathName(resourceId);
    if (!this.searchWordList[pathName]) {
      this.searchWordList[pathName] = observable([]);
    }
    this.searchWordList[pathName].push(new SearchWordModel(data));
  };

  @action
  removeData = (index: number, resourceId: string) => {
    const pathName = this.getPathName(resourceId);
    this.searchWordList[pathName].splice(index, 1);
  };

  @action
  initData = (resourceId: string) => {
    const pathName = this.getPathName(resourceId);
    this.searchWordList[pathName] && this.searchWordList[pathName].replace([]);
  };

  @action
  createSearchWordBykey = async (searchServiceCode: string) => {
    const localList = window.localStorage.getItem(searchServiceCode);

    if (!this.searchWordList[searchServiceCode]) {
      this.searchWordList[searchServiceCode] = localList
        ? observable(
            JSON.parse(localList).map((data: SearchWordModel) => new SearchWordModel(data, true))
          )
        : observable([]);
    }
  };

  @action
  storeSetParamsByKey = (
    setListParams: Function,
    searchOption: string,
    searchText: string,
    searchServiceCode: string
  ) => {
    let array = this.searchWordList[searchServiceCode].filter(
      (data: any) => data.searchOption === searchOption
    );
    setListParams(
      searchOption,
      array.length > 1 ? array.map(({ searchTextParam }) => searchTextParam) : searchText
    );
  };

  @action
  getSearchColumnByKey = async (searchServiceCode: string) => {
    try {
      const { list } = await codeApis.getSearchMenus({ searchServiceCode, isUse: "1" });
      this.searchOptions = list.map(
        (info: any) => new SearchColumnModel(info, this.root.CodeStore.codeList.searchColumnAssign)
      );

      if (this.searchWordList[searchServiceCode]) {
        this.searchWordList[searchServiceCode].forEach((data: any) => {
          const searchColumn: any = this.searchOptions.find(
            (element: { searchColumn: string }) => element.searchColumn === data.searchOption
          );
          searchColumn && data.setInfo("searchOptionName", searchColumn.name);
        });
      }
    } catch (e) {
      console.log(e);
      this.searchOptions = [];
    }
  };

  @action
  getSearchColumn = async (searchServiceCode: string, resourceId: string) => {
    try {
      const { list } = await codeApis.getSearchMenus({ searchServiceCode, isUse: "1", resourceId });
      this.searchOptions = list.reduce((acc, cur) => {
        if (cur.isHidden === "0") {
          acc.push(new SearchColumnModel(cur, this.root.CodeStore.codeList.searchColumnAssign));
        }
        return acc;
      }, []);

      const pathName = this.getPathName(resourceId);

      if (this.searchWordList[pathName]) {
        this.searchWordList[pathName].forEach((data: any) => {
          const searchOption = data.searchOption.toLowerCase();

          const searchColumn: any = this.searchOptions.find((element: SearchColumnModel) => {
            if (searchOption.includes("filtername")) {
              return element.schemaName && element.schemaName.toLowerCase().includes("filtername");
            } else {
              return element["searchColumn"] === data.searchOption;
            }
          });

          searchColumn && data.setInfo("searchOptionName", searchColumn.name);
        });
      }
    } catch (e) {
      console.log(e);
      this.searchOptions = [];
    }
  };

  @action
  setFixedDataToLocal = async (resourceId: string) => {
    const pathName = await this.getPathName(resourceId);

    const arr =
      this.searchWordList[pathName] &&
      this.searchWordList[pathName].filter((data: any) => data.isFix);

    arr && arr.length > 0
      ? localStorage.setItem(pathName, JSON.stringify(toJS(arr)))
      : localStorage.removeItem(pathName);
  };

  @action
  storeSetParams = (
    setListParams: Function,
    searchOption: string,
    searchText: string,
    resourceId: string
  ) => {
    const pathName = this.getPathName(resourceId);

    let array = this.searchWordList[pathName].filter(
      (data: any) => data.searchOption === searchOption
    );
    setListParams(
      searchOption,
      array.length > 1 ? array.map(({ searchTextParam }: any) => searchTextParam) : searchText
    );
  };

  //hoc WithGetList에서 사용중
  @action
  setNewData = (searchOption: string, searchText: string, assign?: string) => {
    this.pushData(
      {
        searchOption,
        searchText,
        assign,
      },
      ""
    );
  };

  @action
  getChannelList = async (filterType: string) => {
    this.channelList = [];
    const key = `${filterType}ChannelList`;

    const localStorageChannelList = localStorage.getItem("channelList");
    const allChannelList = localStorageChannelList && JSON.parse(localStorageChannelList);
    this.allChannelList = allChannelList || [];

    const channelListInfo =
      allChannelList && allChannelList.find((channelInfo: object) => channelInfo["key"] === key);

    try {
      if (channelListInfo) {
        this.channelList = channelListInfo.value;
      } else {
        const { list } = await channelApis.getChannelListForAutoComplete(filterType);
        this.channelList = list.map(
          (
            channelInfo: { filterChannelName: string; filterChannelId: string; typeos: string },
            index: number
          ) => ({
            name: `${channelInfo.filterChannelName}+${index}`,
            value: channelInfo.filterChannelId,
            typeos: channelInfo.typeos,
          })
        );

        this.allChannelList.push({ key, value: this.channelList });
        localStorage.setItem("channelList", JSON.stringify(this.allChannelList));
      }
      return this.channelList;
    } catch ({ data }) {
      console.log(data);
      return (this.channelList = []);
    }
  };

  @action
  getFilterNameList = async (filterType: string, getApi: Function) => {
    this.filterNameList = [];
    const key = `${filterType}FilterNameList`;

    const localStorageFilterNameList = localStorage.getItem("filterNameList");
    const allFilterNameList = localStorageFilterNameList && JSON.parse(localStorageFilterNameList);
    this.allFilterNameList = allFilterNameList || [];

    const filterNameListInfo =
      allFilterNameList &&
      allFilterNameList.find((filterInfo: object) => filterInfo["key"] === key);

    try {
      if (filterNameListInfo) {
        this.filterNameList = filterNameListInfo.value;
      } else {
        const { list } = await getApi();

        const keys = Object.keys(list[0]);
        let propertyName = "";
        let propertyKey = "";
        let channelName = "";

        keys.forEach(key => {
          const _key = key.toLowerCase();
          if (_key.includes("filtername")) {
            propertyName = key;
          }
          if (_key.includes("filterid")) {
            propertyKey = key;
          }
          if (_key.includes("channelname")) {
            channelName = key;
          }
        });

        const deleteOverlapList =
          list &&
          list.reduce((acc, cur) => {
            if (acc.findIndex((info: object) => info[propertyKey] === cur[propertyKey]) === -1) {
              acc.push(cur);
            }
            return acc;
          }, []);

        await deleteOverlapList.forEach((filterInfo: object, index: number) => {
          this.filterNameList.push({
            name: `${filterInfo[propertyName]}${channelName &&
              " / " + filterInfo[channelName]}`,
            value: filterInfo[propertyKey],
            typeos: filterInfo["typeos"],
          });
        });

        this.allFilterNameList.push({ key, value: this.filterNameList });

        localStorage.setItem("filterNameList", JSON.stringify(this.allFilterNameList));
      }
      return this.filterNameList;
    } catch ({ data }) {
      console.log(data);
      return (this.filterNameList = []);
    }
  };
}

export default SearchWordStore;
