import { computed, observable } from "mobx";
import { Common_Utils, SetterModel } from "@qubit-utill/src";

class LogConfigPathModel extends SetterModel {
  @computed
  get isUseFlag() {
    return Common_Utils.isCheckBool(this.isUse);
  }

  @observable
  isSelected: boolean = false;

  @observable
  isDel: boolean = false;

  @observable
  configPathId: string = "";

  @observable
  logType: string = "";

  @observable
  configPath: string = "";

  @observable
  logTag: string = "";

  @observable
  isUse: string = "1";

  @observable
  registerDate: string = "";

  @observable
  updateDate: string = "";

  @observable
  registerUuid: string = "";

  @observable
  updateUuid: string = "";

  @observable
  serverId: number = 0;
}

export default LogConfigPathModel;
