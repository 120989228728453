import { observable, action } from "mobx";

import { dateUtil, formatConstants, languageSet, SetterModel } from "@qubit-utill/src";

interface LineType {
  type: string;
  name: string;
  data: Array<number | null>;
}

interface MonthlyListType {
  detectDate: string;
  detectCount: number;
  detectYear: number;
  detectMonth: number;
}

class LineModel extends SetterModel {
  constructor(param: any) {
    super(param);

    let monthlySumListBefore = {
      detectDate: "",
      detectCount: 0,
      detectYear: 0,
      detectWeek: 0,
      detectDayOfWeek: 0,
      detectMonth: 0,
    };

    let monthlySumList = {
      detectDate: "",
      detectCount: 0,
      detectYear: 0,
      detectWeek: 0,
      detectDayOfWeek: 0,
      detectMonth: 0,
    };

    if (param.monthlySumList === null && param.monthlySumListBefore !== null) {
      param.monthlySumList = [monthlySumList];
      // param.monthlySumList[0].detectDate = this.setDetectDate(param.monthlySumListBefore[0].detectDate, true);
    } else if (param.monthlySumListBefore === null && param.monthlySumList !== null) {
      param.monthlySumListBefore = [monthlySumListBefore];
      // param.monthlySumListBefore[0].detectDate = this.setDetectDate(param.monthlySumList[0].detectDate, false);
    } else if (param.monthlySumListBefore === null && param.monthlySumList === null) {
      param.monthlySumList = [monthlySumList];
      param.monthlySumListBefore = [monthlySumListBefore];
    }

    this.category = param.category;
    this.chartData.push(this.getChartDate(param.monthlySumListBefore, param.beforeMonth, false));
    this.chartData.push(this.getChartDate(param.monthlySumList, param.month, true));
  }

  @action
  setDetectDate = (detectDate, current) => {
    const date = new Date(detectDate);
    const getMonth = new Date(date.setMonth(current ? date.getMonth() + 1 : date.getMonth() - 1));
    const formatDate = dateUtil.format(getMonth, formatConstants.DATEFORMAT);
    return formatDate;
  };

  @action
  getChartDate = (dataList: MonthlyListType[], month: number, current: boolean) => {
    const isNullList = dataList.length === 1 && dataList[0].detectYear === 0;
    let chart: Array<number | null> = isNullList ? [] : this.category.map(() => 0);
    dataList &&
      dataList.forEach((data) => {
        chart[Number(data.detectDate.substring(8, 10)) - 1] = data.detectCount;
      });

    const thisMonth = new Date().getMonth() + 1;

    if (current && month === thisMonth) {
      const date = new Date();

      for (let i = date.getDate(); i < this.category.length; i++) {
        chart[i] = null;
      }
    }

    if (isNullList) {
      for (let i = 0; i < this.category.length; i++) {
        chart[i] = null;
      }
    }

    return {
      type: "line",
      name: languageSet("n월", month),
      data: chart,
    };
  };

  @observable
  chartData: LineType[] = [];
  @observable
  category: string[] = [];
}

export default LineModel;
