import { axiosModules } from "@qubit-utill/src";

export default {
  // plog > 시스템로그 > sysLog 조회
  getSysLog: (documentId) => {
    return axiosModules.get(`/plog/syslog/${documentId}`);
  },

  // plog > 시스템로그 > sysLog List 조회
  getSysLogList: (params) => {
    return axiosModules.get("/plog/syslog/list", params);
  },

  // plog > 시스템로그 > sysLog List CSV 다운로드
  getSysLogCsvList: (params) => {
    return axiosModules.getCsv("/plog/syslog/list/csv", params);
  },

  //시스템로그 > sysLog 방어/복구 조회
  getSysLogEdrDefense: (documentId) => {
    return axiosModules.get(`/plog/syslog/edrdefense/${documentId}`);
  },
};
