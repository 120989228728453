import { observable, action, computed, toJS } from "mobx";

import {
  arrayForSelectBox,
  UserException,
  languageSet,
  withLoading,
  isRunning,
  ListCondition,
  Common_Utils,
} from "@qubit-utill/src";

import { codeApis } from "@src/apis";
import {
  filterApis,
  filterCategoryApis,
  filterCustomConfigApis,
  filterElementReferenceApis,
  eventTypeApis,
} from "@src/apis/pFilter";
import { serverGroupApis } from "@src/apis/pServer";

import {
  DataTypeSelectorModel,
  DataTypeForApiModel,
  DataTypeAttributeModel,
  DefenseDataTypeOrderModel,
  DefenseEventElementOptionModel,
} from "@src/pages/Filter/RegisterFilter/model/DataType";
import { FilterChannelModel, FilterCategoryModel } from "@src/pages/Filter/RegisterFilter/model/Filter";
import { SystemFilterModel } from "@src/pages/Filter/RegisterFilter/model";

class SystemFilterStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }
  root;

  @observable
  isRunning = false;

  @observable
  selectedSubTab: string = "detect";

  @action
  setSelectSubTab = (tab) => {
    this.selectedSubTab = tab;
  };

  @observable
  filterElementReferenceListForSelect = [];

  @action
  getFilterElementList = async () => {
    try {
      const { list } = await filterElementReferenceApis.getFilterElementReferenceList({
        isUseManager: "0",
        limit: 9999,
      });
      this.filterElementReferenceListForSelect = arrayForSelectBox.parseValueObjectForSelectBox(
        list,
        "filterElementReferenceName"
      );
    } catch (e) {
      this.filterElementReferenceListForSelect = [];
    }
  };

  @observable
  isCopy = false;

  @observable
  isCopyCheck = false;

  @action
  setIsCopy = (flag) => {
    this.isCopy = flag;
  };
  @action
  setIsCopyCheck = (flag) => {
    this.isCopyCheck = flag;
  };

  @observable
  tableList: SystemFilterModel[] = [];

  @observable
  filterInfo = new SystemFilterModel(null, this.root.CodeStore);

  @observable
  selectedChannelInfo = {};

  @observable
  filterCategoryList = [];

  @observable
  filterCategoryListForSelect = [];

  @observable
  osFamilyList = {};

  //단일필터 등록등에서 사용하는 데이터 유형 (filterElement)
  @observable
  filterEventElementsList = [];

  @observable
  eventElementOptionList = {};

  @observable
  detectDataTypeSelects: DataTypeSelectorModel[] = [];

  @observable
  defenseDataTypeSelects: DefenseDataTypeOrderModel[] = [];

  @observable
  filterDetectTypeList = [];

  @observable
  isTotalCheck = false;

  @observable
  defenseTypeOrderList: Array<DefenseEventElementOptionModel> = [];

  @action
  setIsTotalCheck = (flag: boolean) => {
    this.isTotalCheck = flag;
  };

  @computed
  get totalCountForUI() {
    return this.isTotalCheck ? this.totalCount : this.checkList.length;
  }

  @computed
  get typeosListForSelect() {
    return arrayForSelectBox.parsePrimitiveValueForSelectBox(this.osFamilyList[this.filterInfo.osFamily]);
  }

  @computed
  get checkList() {
    return this.tableList.filter((data) => data.isSelected);
  }

  @computed
  get totalCheckParams() {
    return this.isTotalCheck
      ? { filterCustomConfigIdsAll: "1" }
      : { filterCustomConfigIds: this.checkList.map(({ filterCustomConfigId }) => filterCustomConfigId) };
  }

  @action
  getFilterDetectTypeList = async () => {
    const { list } = await codeApis.getCodeList("filterDetectType");
    this.filterDetectTypeList = list;
  };

  @withLoading("isLoading")
  @action
  getList = async () => {
    const _params = toJS(this.params);
    const params = {
      ..._params,
      orderDesc: "DESC",
    };

    try {
      const { list, totalCount } = await filterCustomConfigApis.getFilterCustomConfigList("system", params);
      this.tableList = list.map((data) => new SystemFilterModel(data, this.root.CodeStore));
      this.totalCount = totalCount;
    } catch (e) {
      console.error("e", e);
      this.tableList = [];
      this.totalCount = 0;
    }
  };

  @observable
  filter;

  @action
  setfilterEventElementsList = async (eventInfo) => {
    if (eventInfo) {
      try {
        const { info, elements } = await eventTypeApis.getEventTypeInfo(eventInfo.filterEventId);
        this.filterInfo.setInfo("filterEventName", info.filterEventName);
        this.filterInfo.setInfo("filterEventKey", info.filterEventKey);
        this.filterInfo.setInfo("filterEventId", info.filterEventId);
        this.filterInfo.setInfo("filterElementRoot", info.filterElementRoot);
        return (this.filterEventElementsList = elements);
      } catch (e) {
        this.clearEventElementsList();
      }
    } else {
      this.clearEventElementsList();
    }
  };

  @action
  clearEventElementsList = () => {
    this.filterInfo.setInfo("filterEventName", "");
    this.filterInfo.setInfo("filterEventKey", "");
    this.filterInfo.setInfo("filterEventId", null);
    this.filterInfo.setInfo("filterElementRoot", "");
    this.detectDataTypeSelects = [];
  };

  @withLoading("isLoading")
  @action
  initFilterInfo = async () => {
    await this.getCodes();
    if (this.isCopyCheck) {
      await this.getChannelByTypeos(this.filterInfo.typeos);
      this.filterInfo.filterName = "";
      this.filterInfo.filterDescription = "";
      this.isCopyCheck = false;
      this.isCopy = true;
    } else {
      this.filterInfo = new SystemFilterModel(null, this.root.CodeStore);
      this.detectDataTypeSelects = [];
      this.defenseDataTypeSelects = [];
    }
  };

  @action
  clearFilterInfo = () => {
    if (this.isCopy) {
      this.isCopy = false;
    }
    if (!this.isCopyCheck) {
      this.filterInfo = new SystemFilterModel(null, this.root.CodeStore);
      this.detectDataTypeSelects = [];
      this.defenseDataTypeSelects = [];
    }
  };

  @withLoading("isLoading")
  @action
  getFilterInfo = async (filterCustomConfigId) => {
    const { info } = await filterCustomConfigApis.getFilterCustomConfigInfo(filterCustomConfigId);
    this.filterInfo = new SystemFilterModel(info, this.root.CodeStore);
  };

  @action
  getChannelByTypeos = async (typeos) => {
    await this.root.CodeStore.getChannelByTypeos(typeos);
  };

  @action
  getServerGroupInfo = async (serverGroupId) => {
    try {
      const { result } = await serverGroupApis.getServerGroupInfo(serverGroupId);
      this.filterInfo.setInfo("serverGroup", result.serverGroupInfo.serverGroup);
    } catch ({ data }) {
      return data;
    }
  };

  @withLoading("isLoading")
  @action
  async getFilterWithTotalInfo(filterId) {
    try {
      await this.getCodes();
      await this.getFilterInfo(filterId);
      if (this.filterInfo.serverGroupId) {
        await this.getServerGroupInfo(this.filterInfo.serverGroupId);
      }
      //osFamily를 set 해준다
      const osList = this.root.CodeStore.osList.systemfilter;
      if (osList) {
        osList.osFamily.forEach(({ typeosList, osFamily }) => {
          if (typeosList.indexOf(this.filterInfo.typeos) > -1 && osFamily) {
            this.filterInfo.setInfo("osFamily", osFamily);
          }
        });
      }
      await this.setfilterEventElementsList({
        filterEventId: this.filterInfo.filterEventId,
      });

      const isUseFilterElementAdditionalFlag = this.filterInfo.isUseFilterElementAdditionalFlag;
      const isDefenseType = this.filterInfo.isDefenseTypeFlag;

      this.parseFilterElements(
        isUseFilterElementAdditionalFlag
          ? this.filterInfo.filterElementAdditionalJson
          : isDefenseType
          ? { defenseFilterElement: this.filterInfo.edrFilterElementList, detectFilterElement: this.filterInfo.filterElementJson }
          : this.filterInfo.filterElementJson,
        isDefenseType
      );
    } catch (e) {
      console.error("tag", e);
    }
  }

  @action
  defenseParseFilterElements = (filterElementJson) => {
    const param = {
      filterEventElementsList: this.filterEventElementsList,
      isEventDataFlag: this.filterInfo.isEventDataFlag,
    };

    this.defenseDataTypeSelects = filterElementJson.map((element) => {
      return new DefenseDataTypeOrderModel({ ...element, ...param });
    });
  };

  @action
  detectParseFilterElements = (filterElementJson) => {
    if (!this.filterInfo.isUseFilterElementAdditionalFlag) {
      this.detectDataTypeSelects = filterElementJson.map((element) => {
        return new DataTypeSelectorModel(
          { filterEventElementsList: this.filterEventElementsList },
          element,
          this.filterElementReferenceListForSelect
        );
      });
    } else {
      this.detectDataTypeSelects = [
        new DataTypeSelectorModel(
          {},
          filterElementJson,
          this.filterElementReferenceListForSelect,
          this.filterInfo.isUseFilterElementAdditionalFlag
        ),
      ];
    }
  };

  @action
  parseFilterElements = (filterElementJson, isDefenseTypeFlag) => {
    this.detectParseFilterElements(isDefenseTypeFlag ? filterElementJson.detectFilterElement : filterElementJson);
    isDefenseTypeFlag && this.defenseParseFilterElements(filterElementJson.defenseFilterElement);
  };

  @action
  getCodes = async () => {
    await this.root.CodeStore.getOsListByType("systemfilter");
    await this.root.ServerGroupStore.getListForOptions();
    this.getFilterCategoryList("filter");
  };

  @action
  setFilterChannel = async (element) => {
    if (element) {
      this.filterInfo.setInfo("filterChannelName", element.filterChannelName);
      this.filterInfo.setInfo("filterChannelId", element.filterChannelId);
      this.selectedChannelInfo = new FilterChannelModel(element);
      if (element.isSingleEvent === "1") {
        try {
          const { list } = await eventTypeApis.getEventTypeList({
            filterChannelId: element.filterChannelId,
          });
          this.setfilterEventElementsList(list[0]);
        } catch (error) {
          this.filterInfo.setInfo("filterEventId", null);
          this.detectDataTypeSelects = [];
        }
      }
    } else {
      this.filterInfo.setInfo("filterChannelName", "");
      this.filterInfo.setInfo("filterChannelId", "");
      this.selectedChannelInfo = {};
    }
  };

  dataTypeValidationCheck = (dataTypeElement) => {
    const err =
      !dataTypeElement.filterElementName ||
      (dataTypeElement.elementAttributes && !dataTypeElement.filterElementAttributeName) ||
      (dataTypeElement.filterEventElementAttributeValues && !dataTypeElement.filterElementAttributeValue) ||
      (dataTypeElement.filterEventElementRepeatSize > 0 && !dataTypeElement.filterElementRepeatSequence);

    if (err) {
      throw new UserException(languageSet("에러_빈값을입력하세요"));
    }
  };

  @action
  detectValidData = () => {
    let detectValidDataList: DataTypeForApiModel[] | any = [];

    if (this.detectDataTypeSelects.length < 1) throw new UserException(languageSet("에러_빈값을입력하세요"));

    this.detectDataTypeSelects.forEach(({ selectedTypes, dataValues }) => {
      const dataTypeList: any = toJS(selectedTypes) as any;
      const dataTypeValues = toJS(dataValues) as DataTypeAttributeModel[];

      const slectedLastIndex: number = dataTypeList.length - 1;
      const selectedLastElement = dataTypeList[slectedLastIndex];

      !this.filterInfo.isUseFilterElementAdditionalFlag && this.dataTypeValidationCheck(selectedLastElement);

      //마지막 요소에 텍스트 밸류 삽입
      const lastDataType = dataTypeValues.map((element) => {
        if (element.filterElementSearchText) {
          return new DataTypeForApiModel({
            filterElementName: selectedLastElement.filterElementName,
            filterElementAttributeValue: selectedLastElement.filterElementAttributeValue,
            filterElementAttributeName: selectedLastElement.filterElementAttributeName,
            filterElementRepeatSequence: selectedLastElement.filterElementRepeatSequence,
            filterElementSearchText: element.filterElementSearchText,
            filterElementSearchType: element.filterElementSearchType,
            filterElementSearchFlag: element.filterElementSearchFlag,
          });
        } else {
          throw new UserException(languageSet("에러_빈값을입력하세요"));
        }
      });

      dataTypeList[slectedLastIndex] = lastDataType;

      if (!this.filterInfo.isUseFilterElementAdditionalFlag) {
        for (let i = slectedLastIndex; i >= 1; i--) {
          this.dataTypeValidationCheck(dataTypeList[i - 1]);

          if (i === slectedLastIndex) {
            dataTypeList[i - 1].childArray = dataTypeList[i];
          } else {
            dataTypeList[i - 1].childArray = [new DataTypeForApiModel(dataTypeList[i])];
          }
        }

        detectValidDataList.push(new DataTypeForApiModel(dataTypeList[0]));
      } else {
        dataTypeList[0] = lastDataType;

        dataTypeList[0].map((data) => {
          return detectValidDataList.push(new DataTypeForApiModel(data));
        });
      }

      if (
        detectValidDataList.length < 1 ||
        dataTypeValues.length <= 0 ||
        (!this.filterInfo.isUseFilterElementAdditionalFlag && !dataTypeList[0].childArray)
      ) {
        throw new UserException(languageSet("에러_빈값을입력하세요"));
      }
    });
    return detectValidDataList;
  };

  @action
  defenseValidData = () => {
    let defenseValidDataList: any = [];
    let detectValidDataList: DataTypeForApiModel[] = [];

    if (this.detectDataTypeSelects.length <= 0) {
      throw new UserException(languageSet("에러_탐지필터_등록해_주세요"));
    } else {
      detectValidDataList = this.detectValidData();
    }

    if (this.defenseDataTypeSelects.length > 0) {
      this.defenseDataTypeSelects.forEach((defenseItem: DefenseDataTypeOrderModel) => {
        const defenseParam = {
          filterId: defenseItem.filterId,
          edrDefenseType: defenseItem.edrDefenseType,
          edrDefenseTypeOrder: defenseItem.edrDefenseTypeOrder,
          isAutoDefense: defenseItem.isAutoDefense,
          isUseRecover: defenseItem.isUseRecover,
          edrRecoverType: defenseItem.edrRecoverType,
          edrRecoverCommand: defenseItem.edrRecoverCommand,
          edrFilterElementJson: [] as any,
        };

        if (!defenseItem.edrFilterElementJson[0].selectedTypes) defenseItem.edrFilterElementJson.shift();

        defenseItem.edrFilterElementJson.forEach(({ selectedTypes, dataValues }, index) => {
          const selectedTypeList: any = toJS(selectedTypes) as any;
          const dataTypeValues = toJS(dataValues) as DataTypeAttributeModel[];

          const selectedLastIndex: number = selectedTypeList.length - 1;
          const selectedLastElement = selectedTypeList[selectedLastIndex];

          !this.filterInfo.isUseFilterElementAdditionalFlag && this.dataTypeValidationCheck(selectedLastElement);

          const lastDataType = dataTypeValues.map((element) => {
            if (element.filterElementSearchText) {
              return new DataTypeForApiModel({
                filterElementName: selectedLastElement.filterElementName,
                filterElementAttributeValue: selectedLastElement.filterElementAttributeValue,
                filterElementAttributeName: selectedLastElement.filterElementAttributeName,
                filterElementRepeatSequence: selectedLastElement.filterElementRepeatSequence,
                filterElementSearchText: element.filterElementSearchText,
                filterElementSearchType: element.filterElementSearchType,
                filterElementSearchFlag: element.filterElementSearchFlag,
              });
            } else {
              throw new UserException(languageSet("에러_빈값을입력하세요"));
            }
          });

          selectedTypeList[selectedLastIndex] = lastDataType[0];

          if (!this.filterInfo.isUseFilterElementAdditionalFlag) {
            const rootIndex = index > 0 && !this.filterInfo.isEventDataFlag ? 1 : 0;
            for (let i = selectedLastIndex; i > rootIndex; i--) {
              this.dataTypeValidationCheck(selectedTypeList[i - 1]);

              if (i === selectedLastIndex) {
                selectedTypeList[i - 1].childArray = [selectedTypeList[i]];
              } else {
                selectedTypeList[i - 1].childArray = [new DataTypeForApiModel(selectedTypeList[i])];
              }
            }

            if (this.filterInfo.isEventDataFlag) {
              defenseParam.edrFilterElementJson.push(new DataTypeForApiModel(selectedTypeList[0]));
            } else {
              if (index > 0) {
                if (defenseParam.edrFilterElementJson[0].childArray === null) {
                  defenseParam.edrFilterElementJson[0].childArray = [];
                }
                defenseParam.edrFilterElementJson[0].childArray.push(new DataTypeForApiModel(selectedTypeList[rootIndex]));
              } else {
                defenseParam.edrFilterElementJson.push(new DataTypeForApiModel(selectedTypeList[rootIndex]));
              }
            }
          } else {
            selectedTypeList[0] = lastDataType[0];
            selectedTypeList[0].map((data) => {
              return defenseParam.edrFilterElementJson.push(new DataTypeForApiModel(data));
            });
          }

          if (
            dataTypeValues.length <= 0 ||
            (!this.filterInfo.isUseFilterElementAdditionalFlag && index === 0 && !selectedTypeList[0].childArray)
          ) {
            throw new UserException(languageSet("에러_빈값을입력하세요"));
          }
        });

        defenseValidDataList.push(defenseParam);
      });
    }
    return { detectValidDataList, defenseValidDataList };
  };

  validData = (isDetectType) => {
    try {
      if (!this.filterInfo.filterName || !this.filterInfo.filterDescription) {
        throw new UserException(languageSet("에러_빈값을입력하세요"));
      }

      this.validTime();

      let detectValidDataList: DataTypeForApiModel[] = [];
      let defenseValidDataList: any[] = [];

      if (isDetectType) {
        detectValidDataList = this.detectValidData();
      } else {
        const result = this.defenseValidData();
        detectValidDataList = result.detectValidDataList;
        defenseValidDataList = result.defenseValidDataList;
      }

      if (!this.filterInfo.isUseFilterElementAdditionalFlag) {
        this.filterInfo.setInfo("filterElementJson", detectValidDataList);
        !isDetectType && this.filterInfo.setInfo("edrFilterElementList", defenseValidDataList);
      } else {
        this.filterInfo.setInfo("filterElementAdditionalJson", isDetectType ? detectValidDataList : defenseValidDataList);
      }

      return {
        isValid: true,
      };
    } catch ({ message }) {
      return {
        isValid: false,
        message: message,
      };
    }
  };

  validTime = () => {
    if (this.filterInfo.isDetectTimeRangeUseFlag && !(this.filterInfo.detectStartTime && this.filterInfo.detectEndTime)) {
      throw new UserException(languageSet("메세지_시간선택"));
    }

    if (
      this.filterInfo.isDetectTimeRangeUseFlag &&
      this.filterInfo.detectStartTimeFormat === this.filterInfo.detectEndTimeFormat
    ) {
      throw new UserException(languageSet("메세지_시간설정다시"));
    }
  };

  @isRunning("isRunning")
  @action
  createFilter = async () => {
    const {
      filterType,
      filterDetectType,
      isDefenseTypeFlag,
      isDetectDisplay,
      isUseUserDescription,
      isUse,
      isGlobalFilter,
      filterRiskLevel,
      isLock,
      filterName,
      filterDescription,
      filterUserDescription,
      filterNo,
      filterEventId,
      filterElementJson,
      filterElementAdditionalJson,
      isDisplay,
      filterCategoryId,
      isCustomFilter,
      isUseCustom,
      serverGroupId,
      isDetectTimeRangeUse,
      detectStartTimeFormat,
      detectEndTimeFormat,
      refFilterId,
      edrFilterElementList,
    } = this.filterInfo;

    try {
      await filterApis.createFilter({
        filterType,
        filterDetectType,
        isDetectDisplay,
        isUseUserDescription,
        isUse,
        isGlobalFilter,
        filterRiskLevel,
        isLock,
        filterName,
        filterDescription,
        filterUserDescription,
        filterNo,
        filterEventId,
        filterElementJson,
        filterElementAdditionalJson,
        isDisplay,
        filterCategoryId,
        isCustomFilter,
        isUseCustom,
        serverGroupId,
        isDetectTimeRangeUse,
        detectStartTime: detectStartTimeFormat,
        detectEndTime: detectEndTimeFormat,
        refFilterId,
        edrFilterElementList: isDefenseTypeFlag ? edrFilterElementList : [],
      });
    } catch (error) {
      return (
        error.data || {
          returnCodeMessage: error,
        }
      );
    }
  };

  @action
  updateCustomConfig = async () => {
    try {
      await filterCustomConfigApis.updateFilterCustomConfig(
        [this.filterInfo.filterId],
        [this.filterInfo.serverGroupId],
        this.filterInfo.isUseCustom,
        this.filterInfo.isDetectTimeRangeUse,
        this.filterInfo.detectStartTimeFormat,
        this.filterInfo.detectEndTimeFormat,
        [this.filterInfo.filterCustomConfigId],
        this.filterInfo.logTag
      );
    } catch ({ data }) {
      return data;
    }
  };

  @action
  updateCustomConfigIsUse = async (onOff) => {
    try {
      await filterCustomConfigApis.updateFilterCustomConfigIsUse(onOff ? "1" : "0", this.totalCheckParams, this.params);
    } catch ({ data }) {
      return data;
    }
  };

  @action
  updateIsUseCustom = async (info) => {
    const isUseValue = Common_Utils.BoolToString(!info.isUseCustomFlag);
    try {
      const { rowCount } = await filterCustomConfigApis.updateFilterCustomConfigIsUse(isUseValue, {
        filterCustomConfigIds: [info.filterCustomConfigId],
      });
      if (rowCount >= 1) info.setInfo("isUseCustom", isUseValue);
    } catch ({ data }) {
      return data;
    }
  };

  @action
  deleteCustomFilter = async () => {
    try {
      await filterCustomConfigApis.deleteFilterCustomConfig(this.totalCheckParams, this.params);
    } catch ({ data }) {
      return data;
    }
  };

  @action
  deleteRecommendFilterCustomConfig = async (filterCustomConfigId) => {
    try {
      await filterCustomConfigApis.deleteFilterCustomConfig({ filterCustomConfigIds: [filterCustomConfigId] });
    } catch ({ data }) {
      return data;
    }
  };

  @isRunning("isRunning")
  @action
  updateFilter = async () => {
    const {
      filterId,
      filterType,
      filterDetectType,
      isDetectDisplay,
      isUseUserDescription,
      isUse,
      isGlobalFilter,
      filterRiskLevel,
      isLock,
      filterName,
      filterDescription,
      filterUserDescription,
      filterNo,
      filterEventId,
      filterElementJson,
      filterElementAdditionalJson,
      isDisplay,
      filterCategoryId,
      isCustomFilter,
      serverGroupId,
      isUseCustom,
      filterCustomConfigId,
      isDetectTimeRangeUse,
      detectStartTimeFormat,
      detectEndTimeFormat,
      refFilterId,
      edrFilterElementList,
      isDefenseTypeFlag,
    } = this.filterInfo;

    try {
      await filterApis.updateFilter({
        filterId,
        filterType,
        filterDetectType,
        isDetectDisplay,
        isUseUserDescription,
        isUse,
        isGlobalFilter,
        filterRiskLevel,
        isLock,
        filterName,
        filterDescription,
        filterUserDescription,
        filterNo,
        filterEventId,
        filterElementJson,
        filterElementAdditionalJson,
        isDisplay,
        filterCategoryId,
        isCustomFilter,
        serverGroupId,
        isUseCustom,
        filterCustomConfigId,
        isDetectTimeRangeUse,
        detectStartTime: detectStartTimeFormat,
        detectEndTime: detectEndTimeFormat,
        refFilterId,
        edrFilterElementList: isDefenseTypeFlag ? edrFilterElementList : [],
      });
    } catch ({ data }) {
      return data;
    }
  };

  @action
  deleteDataElement = (index, isDefense?) => {
    if (isDefense) {
      this.defenseDataTypeSelects.splice(index, 1);
    } else {
      this.detectDataTypeSelects.splice(index, 1);
    }
  };

  @action
  getFilterCategoryList = async (filterType) => {
    const { list } = await filterCategoryApis.getFilterCategoryList(filterType);
    this.filterCategoryList = list.map((element) => new FilterCategoryModel(element));
    this.filterCategoryListForSelect = arrayForSelectBox.parseValueForSelectBoxDefault(this.filterCategoryList);
  };

  @action
  allCheck = (checked) => {
    this.tableList.forEach((data) => {
      data.setInfo("isSelected", checked);
    });
  };

  @action
  addNewDataType = (isDetectTab) => {
    if (isDetectTab) {
      const eventRootElement = this.filterEventElementsList[0];
      const param = {
        filterEventElementsList: this.filterEventElementsList,
        eventRootElement: eventRootElement,
      };
      this.detectDataTypeSelects.push(new DataTypeSelectorModel(param));
    } else {
      const param = {
        filterEventElementsList: this.filterEventElementsList,
        eventRootElement: this.filterEventElementsList[0],
        isDefense: true,
      };

      const { filterId, isAutoDefense, isUseRecover } = this.filterInfo;

      this.defenseDataTypeSelects.push(
        new DefenseDataTypeOrderModel({
          filterId,
          isAutoDefense,
          isUseRecover,
          ...param,
          edrFilterElementJson: [new DataTypeSelectorModel(param)],
        })
      );
    }
  };

  @action
  getDefenseOrderTypeList = async () => {
    if (this.defenseTypeOrderList.length <= 0) {
      const { list } = await filterApis.getDefenseOrderTypeList();
      this.defenseTypeOrderList = list.map((typeItem) => new DefenseEventElementOptionModel(typeItem));
    }
  };
}

export default SystemFilterStore;
