import { observable, computed } from "mobx";

import { Common_Utils, SetterModel } from "@qubit-utill/src";

import { HAProxyPropertyModel } from "@src/pages/Group/HAProxy/models";

export default class HAProxySectionModel extends SetterModel {
  constructor(params, useDeleteBtn?, isLast?) {
    super(params);

    if (params) {
      this.isBackend = params.middlewareSectionName.toLowerCase().includes("backend");
      this.isFrontend = params.middlewareSectionName.toLowerCase().includes("frontend");
    }

    if (useDeleteBtn) {
      this.useDeleteBtn = useDeleteBtn;
    }

    if (isLast) {
      this.isLast = isLast;
    }
  }

  @computed
  get isUseFlag() {
    return Common_Utils.isCheckBool(this.isUse);
  }

  @computed
  get isSingleSectionFlag() {
    return Common_Utils.isCheckBool(this.isSingleSection);
  }

  @computed
  get isSectionLabelPropertyFlag() {
    return Common_Utils.isCheckBool(this.isSectionLabelProperty);
  }

  @observable
  isBackend: boolean = false;

  @observable
  isFrontend: boolean = false;

  @observable
  useDeleteBtn: boolean = false;

  @observable
  middlewareUserSectionId: number = 0;

  @observable
  middlewareSectionId: number = 0;

  @observable
  isUse: string = "1";

  @observable
  registerDate: string = "";

  @observable
  updateDate: string = "";

  @observable
  registerUuid: string = "";

  @observable
  updateUuid: string = "";

  @observable
  middlewareUserConfigId: number = 0;

  @observable
  middlewareSectionName: string = "";

  @observable
  middlewareSectionLabel: null = null;

  @observable
  isSingleSection: string = "0";

  @observable
  isSectionLabelProperty: string = "0";

  @observable
  middlewareUserPropertyList: Array<HAProxyPropertyModel> = [];

  @observable
  isLast: boolean = false;
}
