import { observable, computed } from "mobx";

import { Common_Utils, dateUtil, SetterModel, formatConstants } from "@qubit-utill/src";

class NetworkRecommendFilterModel extends SetterModel {
  constructor(params, CodeStore) {
    super(params);
    this.CodeStore = CodeStore;
    if (params) {
      this.recommendFilterOs = params.typeos;
      this.recommendFilterType = params.filterCategoryName;
      this.recommendFilterChannel = params.filterChannelName;
      this.recommendFilterName = params.filterName;
      this.recommendFilterRiskLevel = params.filterRiskLevel;
      this.recommendFilterEventType = `${params.filterEventName}(${params.filterEventKey})`;
      this.recommendFilterDescription = params.filterDescription;
      this.recommendFilterUserDescription = params.filterUserDescription;
      this.detectStartTime = dateUtil.newDate(params.detectStartTime, null);
      this.detectEndTime = dateUtil.newDate(params.detectEndTime, null);
    }
  }

  @observable
  CodeStore;

  @computed
  get recommendFilterNameType() {
    return this.CodeStore.getCodeName("filterRiskLevel", this.recommendFilterRiskLevel);
  }

  @computed
  get isUseUserDescriptionFlag() {
    return Common_Utils.isCheckBool(this.isUseUserDescription);
  }

  @computed
  get isNew() {
    return dateUtil.isCompareDateFlag(30, this.registerDate);
  }

  @computed
  get filterDetectTypeName() {
    return Common_Utils.isDataWithDashBar(this.CodeStore.getCodeName("filterDetectType", this.filterDetectType));
  }

  @computed
  get isUseCustomFlag() {
    return Common_Utils.isCheckBool(this.isUseCustom);
  }

  @computed
  get isDetectTimeRangeUseFlag() {
    return Common_Utils.isCheckBool(this.isDetectTimeRangeUse);
  }

  @computed
  get isPossibleAddDataType() {
    return Boolean(this.filterEventId);
  }

  @computed
  get detectStartTimeFormat() {
    return this.detectStartTime ? dateUtil.format(this.detectStartTime, formatConstants.TIMEFORMAT) : null;
  }

  @computed
  get detectEndTimeFormat() {
    return this.detectEndTime ? dateUtil.format(this.detectEndTime, formatConstants.TIMEFORMAT) : null;
  }

  @computed
  get isUseFilterElementAdditionalFlag() {
    return Boolean(this.refFilterId);
  }

  @observable
  filterId: string = "";

  @observable
  registerDate: string = "";

  @observable
  recommendFilterType: string = "";

  @observable
  recommendFilterOs: string = "";

  @observable
  recommendFilterChannel: string = "";

  @observable
  recommendFilterName: string = "";

  @observable
  recommendFilterEventType: string = "";

  @observable
  recommendFilterDescription: string = "";

  @observable
  recommendFilterUserDescription: string = "";

  @observable
  recommendFilterRiskLevel: string = "";

  @observable
  isUseUserDescription: string = "0";

  @observable
  userGuid: string = "";

  @observable
  isSelected: boolean = false;

  @observable
  refFilterId: string = "";

  @observable
  filterType: string = "";

  @observable
  filterDetectType: string = "";

  @observable
  isDetectDisplay: string = "";

  @observable
  isUse: string = "1";

  @observable
  isGlobalFilter: string = "";

  @observable
  isCustomFilter: string = "1";

  @observable
  isLock: string = "";

  @observable
  filterNo: number = 0;

  @observable
  filterEventId: number = 0;

  @observable
  filterEventName: string = "";

  @observable
  filterElement: string = "";

  @observable
  filterElementJson: Array<any> = [];

  @observable
  filterElementAdditional: string = "";

  @observable
  filterElementAdditionalJson: Array<any> = [];

  @observable
  filterCustomConfigId: number = 0;

  @observable
  serverGroupId: number | null = null;

  @observable
  serverGroup: string = "";

  @observable
  isUseCustom: string = "0";

  @observable
  filterEventKey: string = "";

  @observable
  isDisplay: string = "";

  @observable
  filterElementRoot: string = "";

  @observable
  filterChannelId: number = 0;

  @observable
  isSingleEvent: string = "";

  @observable
  filterChannelOrderNo: string = "";

  @observable
  filterCategoryId: number = 1;

  @observable
  filterCategoryType: string = "";

  @observable
  isDetectTimeRangeUse: string = "";

  @observable
  detectStartTime: string | null = null;

  @observable
  detectEndTime: string | null = null;

  @observable
  isUseFilterElementAdditional: string = "0";

  @observable
  filterCount: number = 0;

  @observable
  osFamily: string = "";
}

export default NetworkRecommendFilterModel;
