import { axiosModules } from "@qubit-utill/src";

export default {
  // 계정탈취(Dos) > 계정탈취 탐지 리스트
  getDetectDosList: (params) => {
    return axiosModules.get("/pdetectfilter/detectdos", params);
  },

  // 상관분석 > 상관필터 - 탐지정보 리스트
  getDetectPackageList: (params) => {
    return axiosModules.get("/pdetectfilter/detectpackages", params);
  },

  // 상관분석 > 상관필터 - 탐지정보
  getDetectPackage: (detectPackageId) => {
    return axiosModules.get(`/pdetectfilter/detectpackage/${detectPackageId}`);
  },

  //즉시방어처리
  createDetectDefense: (defenseConfigFilterType, data) => {
    return axiosModules.post(`/pdetectfilter/detectdefense/manual/${defenseConfigFilterType}`, data);
  },

  // 방어 > 방어 실행 중 확인
  getDetectPackageIsActive: (params) => {
    return axiosModules.get(`/pdetectfilter/detectdefense/check/detectdefenseip`, params);
  },

  // 방어 > 방어 수정
  updateDetectDefenseByDefenseId: (detectDefenseId, params) => {
    return axiosModules.put(`/pdetectfilter/detectdefense/${detectDefenseId}`, params);
  },

  // 방어 삭제
  deleteDetectDefense: (detectDefenseId) => {
    return axiosModules.del(`/pdetectfilter/detectdefense/${detectDefenseId}`);
  },

  //////// 차단IP주소 > 웹방화벽 > IP주소 등록(WAF-차단 아이피 등록) ////////

  // 방어 > 방어 정보 조회
  // 차단IP주소 > 시스템 조회
  getDetectDefenseManual: (detectDefenseId) => {
    return axiosModules.get(`/pdetectfilter/detectdefense/${detectDefenseId}`);
  },

  // 방어 > 방어 실행 리스트
  // 차단IP주소 > 시스템 리스트
  getDetectDefenseList: (params) => {
    return axiosModules.get("/pdetectfilter/detectdefense", params);
  },

  // 방어 > 방어 등록
  // 차단IP주소 > 시스템 > IP주소 등록
  createDetectDefenseManual: (data) => {
    return axiosModules.post("/pdetectfilter/detectdefense/manual/register", data);
  },

  // 방어 > 방어 수정
  //차단IP주소 > 시스템 > 수정
  updateDetectDefense: (detectDefenseId, data) => {
    return axiosModules.put(`/pdetectfilter/detectdefense/${detectDefenseId}`, data);
  },

  // 방어 > 방어 아이피 상태 수정
  // 차단IP주소 > 시스템 > 동작수정
  updateDetectDefenseByIsActive: (data) => {
    return axiosModules.put("/pdetectfilter/detectdefense/isactive", data);
  },

  // 방어 > 방어 아이피 상태 단일 삭제
  // 차단IP주소 > 시스템 단일 삭제
  deleteDetectDefenseId: (detectDefenseId) => {
    return axiosModules.del(`/pdetectfilter/detectdefense/${detectDefenseId}`);
  },

  // 방어 > 방어 아이피 상태 일괄 삭제
  //차단IP주소 > 시스템 목록 삭제
  deleteDetectDefenseIds: (params) => {
    return axiosModules.del("/pdetectfilter/detectdefense", params);
  },

  // 방어 > 웹방화벽 차단아이피 관리 > WAF-차단 아이피 등록
  // 차단IP주소 > 웹방화벽 > IP주소 등록
  createCustomDefenseWaf: (data) => {
    return axiosModules.post("/pdetectfilter/customdefensewaf", data);
  },

  // 방어 > 웹방화벽 차단아이피 관리 > WAF-차단 아이피 수정
  // 차단IP주소 > 웹방화벽 > 수정
  updateCustomDefenseWaf: (customDefenseWafId, data) => {
    return axiosModules.put(`/pdetectfilter/customdefensewaf/${customDefenseWafId}`, data);
  },

  // 방어 > 웹방화벽 차단아이피 관리 > WAF-차단 아이피 상태 수정
  // 차단IP주소 > 웹방화벽 > 동작수정
  updateCustomDefenseWafIsActive: (data) => {
    return axiosModules.put(`/pdetectfilter/customdefensewaf/isactive`, data);
  },

  // 방어 > 웹방화벽 차단아이피 관리 > WAF-차단 아이피 확인
  // 차단IP주소 >  웹방화벽 아이피 확인
  searchDetectDefenseIp: (detectDefenseIp) => {
    return axiosModules.get(`/pdetectfilter/customdefensewaf/check/detectdefenseip`, detectDefenseIp);
  },

  // 방어 > 웹방화벽 차단아이피 관리 > WAF-차단 아이피 정보 조회
  // 차단IP주소 > 웹방화벽 조회
  getCustomDefencseWaf: (customDefenseWafId) => {
    return axiosModules.get(`/pdetectfilter/customdefensewaf/${customDefenseWafId}`);
  },

  // 방어 > 웹방화벽 차단아이피 관리 > WAF-차단 아이피 리스트
  // 차단IP주소 > 웹방화벽 리스트
  getCustomDefencseWafList: (params) => {
    return axiosModules.get("/pdetectfilter/customdefensewaf", params);
  },

  // 방어 > 웹방화벽 차단아이피 관리 > WAF-차단 아이피 단일 삭제
  // 차단IP주소 > 웹방화벽 단일 삭제
  deleteCustomDefenseWaf: (customDefenseWafId) => {
    return axiosModules.del(`/pdetectfilter/customdefensewaf/${customDefenseWafId}`);
  },

  // 방어 > 웹방화벽 차단아이피 관리 > WAF-차단 아이피 목록 삭제
  // 차단IP주소 > 웹방화벽 목록 삭제
  deleteCustomDefenseWafs: (params) => {
    return axiosModules.del("/pdetectfilter/customdefensewaf", params);
  },
  //방어 > 방어 대량 등록 체크
  createDetectDefenseListCheck: (data) => {
    return axiosModules.post("/pdetectfilter/detectdefense/list/check", data);
  },
  //방어 > 방어 대량 등록
  createDetectDefenseList: (data) => {
    return axiosModules.post("/pdetectfilter/detectdefense/list", data);
  },
  //방어 > 웹방화벽 차단아이피 관리 > WAF-차단 아이피 다중 등록 체크
  createWafDetectDefenseListCheck: (data) => {
    return axiosModules.post("/pdetectfilter/customdefensewafs/check", data);
  },
  //방어 > 웹방화벽 차단아이피 관리 > WAF-차단 아이피 다중 등록
  createWafDetectDefenseList: (data) => {
    return axiosModules.post("/pdetectfilter/customdefensewafs", data);
  },
};
