import { observable } from "mobx"
import SetterModel from "./SetterModel"

class OsFamilyModel extends SetterModel {
  @observable
  osFamily = [
    {
      osFamily: "",
      typeosList: [""]
    }
  ]

  @observable
  osReleaseType = [
    {
      osReleaseType: "",
      osMajorList: []
    }
  ]

  @observable
  typeos = [
    {
      typeos: "",
      osReleaseTypeList: [""]
    }
  ]
}

export default OsFamilyModel
