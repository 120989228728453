import React from "react";
import { observer, useLocalStore } from "mobx-react";

import { SelectEditType, languageSet, Input, Common_Utils } from "@qubit-utill/src";

interface PropsType {
  color?: string;
  option: any[];
  info: any;
  valueName: string;
  onChange: Function;
  inputClass?: string;
  selectClass?: string;
  disabled?: boolean;
  noInit?: boolean;
  defaultValue?: number;
}

const OptionalInputForm: React.FC<PropsType> = ({
  selectClass = "min_w100",
  inputClass = "width-40",
  color = "white",
  option,
  info,
  valueName,
  onChange,
  disabled = false,
  noInit,
  defaultValue,
}) => {
  const state = useLocalStore(() => ({
    isCustom: false,
    setIsCustom(flag) {
      this.isCustom = flag;
    },
  }));

  const infoValue = info[valueName];
  const value = infoValue ? infoValue.toString() : infoValue;
  const initValue = `${valueName}InitValue`;

  React.useEffect(() => {
    if (!infoValue) {
      if (Common_Utils.isEmpty(info[initValue]) && defaultValue) {
        info.setInfo(valueName, defaultValue);
      } else if (noInit) {
        info.setInfo(valueName, info[initValue]);
      }
    } else {
      const found = option.find((el) => {
        return el.value === infoValue;
      });
      found ? state.setIsCustom(false) : state.setIsCustom(true);
    }
  }, []);

  return (
    <>
      <span className={`dis-inline-b ${selectClass} verti_top`}>
        <SelectEditType
          color={color}
          options={option}
          onSelect={({ value }) => {
            if (value === "custom") {
              info.setInfo(initValue, infoValue);
              info.setInfo(valueName, "");
              state.setIsCustom(true);
            } else {
              info.setInfo(valueName, value);
              state.setIsCustom(false);
            }
          }}
          selectedValue={state.isCustom ? languageSet("직접입력") : value === 0 ? languageSet("포트번호_유지") : value}
          disabled={disabled}
        />
      </span>
      <span className="dis-inline-b m-r-3"></span>
      {state.isCustom && (
        <span className="dis-inline-b m-r-3">
          <Input.Text
            color={color}
            className={`${inputClass} form-${color}`}
            value={infoValue}
            onChange={onChange}
            disabled={disabled}
          />
        </span>
      )}
    </>
  );
};

export default observer(OptionalInputForm);
