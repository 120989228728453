import { observable } from "mobx";
import uuid from "uuid/v1";
import { SetterModel } from "@qubit-utill/src";

class EntryFilterModel extends SetterModel {
  constructor(params, filterType) {
    super(params);
    if (params) {
      if (filterType === "filter") {
        this.filterName = params.filterName;
        this.entryFilterId = params.filterId;
      } else if (filterType === "webfilter") {
        this.filterName = params.webFilterName;
        this.entryFilterId = params.webFilterId;
        this.displayName = `${params.webFilterAttackType} > ${params.webFilterPattern ? params.webFilterPattern + " > " : ""}${
          this.filterName
        } (${params.webFilterId})`;
      } else if (filterType === "applicationfilter") {
        this.filterName = params.filterName || params.applicationFilterName;
        this.entryFilterId = params.filterId || params.applicationFilterId;
      } else if (filterType === "networkfilter") {
        this.filterName = params.filterName || params.networkFilterName;
        this.entryFilterId = params.filterId || params.networkFilterId;
      } else if (filterType === "defense") {
        this.filterName = params.defenseConfigFilterName;
        this.entryFilterId = params.defenseConfigFilterId;
        this.typeos = params.defenseConfigFilterTypeos;
      }

      filterType !== "webfilter" && (this.displayName = `${this.filterName}(${this.entryFilterId})`);
      this.packageFilterEntryType = filterType;
    }
  }
  @observable
  uiId = uuid();
  @observable
  displayName = "";
  @observable
  entryFilterId = ""; //ui등록시 사용
  @observable
  packageFilterEntryType = ""; //ui등록시 사용
  //필터인지 웹필터인지
  @observable
  packageFilterGroupId = 0;
  // packageFilterId = ""
  @observable
  filterId = "";
  @observable
  filterName = "";
  @observable
  typeos = "";
  //웹필터인 경우
  @observable
  webFilterId = null;
  @observable
  webFilterName = null;
  @observable
  isSelected = false;
}

export default EntryFilterModel;
