import { axiosModules } from "@qubit-utill/src";

export default {
  // plogdetect > 네트워크 탐지 로그 > 탐지 네트워크 로그 조회
  getNetLogDetect: (documentId) => {
    return axiosModules.get(`/plogdetect/netlogdetect/${documentId}`);
  },

  // plogdetect > 네트워크 탐지 로그 > 탐지 네트워크 로그 리스트 조회
  getNetLogDetectList: (params) => {
    return axiosModules.get("/plogdetect/netlogdetect/list", params);
  },

  // plogdetect > 네트워크 탐지 로그 > 탐지 네트워크 로그 리스트 조회
  getNetLogDetectCsvList: (params) => {
    return axiosModules.getCsv("/plogdetect/netlogdetect/list/csv", params);
  },
};
