import { axiosModules } from "@qubit-utill/src";

export default {
  //메타 목록 조회
  getResourceMetaList: (resourceId) => {
    return axiosModules.get(`/pmetrics/resourcemeta/${resourceId}/list?isDisplay=1`);
  },

  //메타 정보 조회
  getResourceMetaInfo: (resourceMetaId) => {
    return axiosModules.get(`/pmetrics/resourcemeta/${resourceMetaId}`);
  },

  //메타 등록/수정(다중)
  createResourceMeta: (resourceId, params) => {
    return axiosModules.post(`/pmetrics/resourcemeta/${resourceId}/list`, params);
  },

  //메타 노출여부 수정
  updateIsDisplayResourceMeta: (resourceId, resourceMetaId, params) => {
    return axiosModules.put(`/pmetrics/resourcemeta/isdisplay/${resourceId}/${resourceMetaId}`, params);
  },

  //메타 정렬순서 수정
  updateOrderNoResourceMeta: (resourceId, params) => {
    return axiosModules.put(`/pmetrics/resourcemeta/orderno/${resourceId}`, params);
  },

  //메타 삭제(다중)
  deleteResourceMeta: (resourceId, params) => {
    return axiosModules.del(`/pmetrics/resourcemeta/${resourceId}/list`, params);
  },
};
