import React from "react";
import { observer, Observer } from "mobx-react";

import { languageSet, TableInfos } from "@qubit-utill/src";

const IPAnalysisReportTable = ({ chartList }) => {
  const _renderItems = () => {
    if (chartList && chartList.length > 0) {
      return chartList.map((data, index) => (
        <tr key={data.nationName}>
          <td>{index + 1}</td>
          <td>
            <TableInfos.NationAndIp nation={data.nation} ip={data.nationName} ipClass={"c-red bold"} />
          </td>
          <td>{data.attackPercent}</td>
        </tr>
      ));
    } else {
      return (
        <tr>
          <td colSpan={100}>
            <span className="c-red">{languageSet("에러_발생로그없습니다")}</span>
          </td>
        </tr>
      );
    }
  };

  return (
    <table className="table f-12 m-b-0">
      <thead>
        <tr className=" bg-red01">
          <th className="width_60">NO</th>
          <th>{languageSet("국가")}</th>
          <th>{languageSet("공격수")}</th>
        </tr>
      </thead>
      <tbody>
        <Observer render={_renderItems} />
      </tbody>
    </table>
  );
};

export default observer(IPAnalysisReportTable);
