import React, { Component, Fragment } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { Provider, observer } from "mobx-react";
import stores, { RootStore } from "@src/stores";
import { getURI } from "@src/constants";
import routes from "@src/routes";

import {
  customHistory,
  PopoverStore,
  DefaultLayout,
  CustomPopover,
  ModalContainer,
  Loading,
  asyncComponent,
} from "@qubit-utill/src";

const TopBar = asyncComponent(() => import(/* TopBar */ "@src/pages/TopBar/TopBar"));
const SideBar = asyncComponent(() => import(/* SideBar */ "@src/pages/SideBar/SideBar"));
const LoginContainer = asyncComponent(() => import("@src/pages/Login/LoginContainer"));
const SignUpContainer = asyncComponent(() => import("@src/pages/SignUp/SignUpContainer"));
const TermsContainer = asyncComponent(() => import("@src/pages/Terms/TermsContainer"));
const MailCertificationContainer = asyncComponent(() => import("@src/pages/MailCertification/MailCertificationContainer"));
const ChangePasswordGuideContainer = asyncComponent(() =>
  import("@src/common/components/organisms/Template/ChangePasswordGuideContainer")
);
const NewHostResourceMonitoringContainer = asyncComponent(() =>
  import("@src/pages/ResourceMonitoring/HostResourceMonitoring/templates/NewHostResourceMonitoringContainer")
);

const MitreMatrixRouter = asyncComponent(() => import(/* MitreMatrixRouter */ "@src/pages/MitreMatrix/MitreMatrixRouter"));
const DecorderModal = asyncComponent(() => import(/* DecorderModal: */ "@src/common/components/organisms/Modal/DecorderModal"));
const DiffCheckerModal = asyncComponent(() =>
  import(/* DiffCheckerModal */ "@src/common/components/organisms/Modal/DiffCheckerModal")
);

const MITRE_URI = getURI("MITRE_MATRIX");
const NEW_MITRE_URI = getURI("NEW_MITRE_MATRIX");

const useTheme = () => {
  return window.location.pathname.indexOf(NEW_MITRE_URI) < 0 ? true : window.location.pathname === MITRE_URI;
};

@observer
class App extends Component {
  constructor(props) {
    super(props);

    RootStore.CookieStore.checkIsLogin();
    RootStore.CookieStore.checkLang();
    RootStore.LoadingStore.Loading();

    if (RootStore.CookieStore.isLogin) {
      if (useTheme()) {
        !RootStore.ThemeStore.isAPICalling && RootStore.ThemeStore.getInfo();
      }
      RootStore.getInitUserInfo();
    }
  }

  render() {
    const { isUsePasswordChangeFlag, isNeedPasswordChangeFlag, isUse } = RootStore.MyInfoStore.userPasswordChangeInfo;
    const { isRenderPasswordChangePage } = RootStore.CookieStore;

    return (
      <Provider {...stores} {...RootStore} RootStore={RootStore}>
        <Fragment>
          <Router history={customHistory}>
            <Switch>
              <Route path={getURI("TERMS")} key={getURI("TERMS")} render={() => <TermsContainer />} />

              {[getURI("PASSWORD"), getURI("FINDPWD"), getURI("SIGNUPCERT"), getURI("ERROR_CERTIFICATION")].map((path) => (
                <Route
                  path={path}
                  key={path}
                  render={() =>
                    RootStore.CookieStore.isLogin ? <Redirect to={getURI("INDEX")} /> : <MailCertificationContainer />
                  }
                />
              ))}
              <Route
                path={getURI("SIGNUP")}
                render={() => (RootStore.CookieStore.isLogin ? <Redirect to={getURI("INDEX")} /> : <SignUpContainer />)}
              />
              <Route
                path={getURI("LOGIN")}
                render={() => (!RootStore.CookieStore.isLogin ? <LoginContainer /> : <Redirect to={getURI("INDEX")} />)}
              />
              <Route
                path={getURI("NEW_MITRE_MATRIX")}
                render={() => (RootStore.CookieStore.isLogin ? <MitreMatrixRouter /> : <Redirect to={getURI("LOGIN")} />)}
              />
              <Route
                path={getURI("RESOURCEMONITORING_HOST_DETAIL_NEW")}
                render={() =>
                  RootStore.CookieStore.isLogin ? <NewHostResourceMonitoringContainer /> : <Redirect to={getURI("LOGIN")} />
                }
              />
              <Route
                path={getURI("DECODEENCODE")}
                render={() => (RootStore.CookieStore.isLogin ? <DecorderModal /> : <Redirect to={getURI("LOGIN")} />)}
              />

              <Route
                path={getURI("DIFFCHECKER")}
                render={() => (RootStore.CookieStore.isLogin ? <DiffCheckerModal /> : <Redirect to={getURI("LOGIN")} />)}
              />

              <Route
                path={getURI("INDEX")}
                render={() =>
                  RootStore.CookieStore.isLogin ? (
                    isUse !== null &&
                    (isUsePasswordChangeFlag && isNeedPasswordChangeFlag && isRenderPasswordChangePage ? (
                      <ChangePasswordGuideContainer />
                    ) : (
                      <DefaultLayout
                        licenseCheck
                        routes={routes}
                        SideBar={SideBar}
                        TopBar={TopBar}
                        defaultLink={getURI("DASHBOARD")}
                      />
                    ))
                  ) : (
                    <Redirect to={getURI("LOGIN")} />
                  )
                }
              />
            </Switch>
          </Router>
          <Loading />
          <ModalContainer />
          {PopoverStore.isFade && <CustomPopover />}
        </Fragment>
      </Provider>
    );
  }
}

export default App;
