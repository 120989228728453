import React from "react";
import { observer } from "mobx-react";

import { useInjectStore } from "@qubit-utill/src";

interface Props {
  top10ReportTabs: Top10ReportTabInfo[];
}
//tabInfo상세
interface Top10ReportTabInfo {
  tableTabTitle: string;
  selectedTableTab: string;
}

const Top10ReportTab: React.FC<Props> = ({ top10ReportTabs }) => {
  const { ReportStore } = useInjectStore();

  const _setTableTab = React.useCallback(
    (tab: string) => () => {
      ReportStore.setTableTab(tab);
    },
    []
  );

  const _isSelected = React.useCallback(
    (tab: string) => {
      return ReportStore.tableTab === tab ? "c-red" : "c-gray";
    },
    [ReportStore.tableTab]
  );

  const TableTab = ({ tabInfo, index }) => (
    <>
      {index !== 0 && <span className="p-lr-3 f-11 c-black">|</span>}
      <a className="cursor_p" onClick={_setTableTab(`${tabInfo.selectedTableTab}`)}>
        <span className={`f-13 ${_isSelected(tabInfo.selectedTableTab)}`}>{tabInfo.tableTabTitle}</span>
      </a>
    </>
  );

  return (
    <>
      <span className="dis-inline-b border p-r-10 p-l-10">
        {top10ReportTabs.length > 0 &&
          top10ReportTabs.map((tabInfo: Top10ReportTabInfo, index: number) => (
            <TableTab key={index} tabInfo={tabInfo} index={index} />
          ))}
      </span>
    </>
  );
};

export default observer(Top10ReportTab);
