import React, { useEffect } from "react";
import { ButtonGroup } from "reactstrap";
import { observer, Observer } from "mobx-react";
import { languageSet, Buttons, useInjectStore, Common_Utils } from "@qubit-utill/src";

const TermTab: React.FC = () => {
  const { ReportStore } = useInjectStore();
  const onClickHandler = (key: string, isActive: boolean) => () => {
    if (!isActive) {
      ReportStore.setInfo("termsTab", key);
      ReportStore.setInfo("classificationTab", 0);
      ReportStore.getData();
    }
  };

  const _renderButton = (text: string, key: string) => () => {
    const isActive = ReportStore.termsTab === key;

    return (
      <Buttons.Rounded
        onClick={onClickHandler(key, isActive)}
        className={isActive ? "bg-orange" : ""}
        color={!isActive ? "default" : ""}
      >
        {text}
      </Buttons.Rounded>
    );
  };

  const pathUrl = Common_Utils.getCurrentPathUrl();

  useEffect(() => {
    const _termsTab = sessionStorage.getItem("termsTab");
    _termsTab && ReportStore.setInfo("termsTab", _termsTab);
    return () => sessionStorage.removeItem("termsTab");
  }, [pathUrl]);

  return (
    <ButtonGroup className="m-r-0 f-right">
      <Observer render={_renderButton(languageSet("일별"), "day")} />
      <Observer render={_renderButton(languageSet("주별"), "week")} />
      <Observer render={_renderButton(languageSet("월별"), "month")} />
    </ButtonGroup>
  );
};

export default observer(TermTab);
