import { computed, observable } from "mobx";
import { SetterModel, Common_Utils, dateUtil } from "@qubit-utill/src";

import {
  FORENSIC_EXECUTE_TYPE_KEY,
  FORENSIC_MANUAL_TYPE_KEY,
} from "@src/pages/Server/ServerManagement/types/ServerForensicTypes";

class ServerForensicModel extends SetterModel {
  constructor(param, CodeStore) {
    super(param);
    this.CodeStore = CodeStore;
  }

  CodeStore;

  @observable
  isSelected: boolean = false;

  @observable
  forensicId: number = 0;

  @observable
  parentId: number = 0;

  @observable
  forensicType: string = "";

  @observable
  forensicExecuteType: string = "";

  @observable
  forensicManualType: string = "";

  @observable
  forensicCategory: string = "";

  @observable
  forensicCategoryName: string = "";

  @observable
  forensicTarget: string = "";

  @observable
  isOriginalValueExist: string = "0";

  @observable
  isCurrentValueExist: string = "0";

  @observable
  originalCheckDate: string = "";

  @observable
  currentCheckDate: string = "";

  @observable
  optionValue: string = "";

  @observable
  isDiff: string = "0";

  @observable
  selectCount: number = 0; //선택목록 카운트

  @computed
  get isOriginalValueExistFlag() {
    return Common_Utils.isCheckBool(this.isOriginalValueExist);
  }

  @computed
  get isCurrentValueExistFlag() {
    return Common_Utils.isCheckBool(this.isCurrentValueExist);
  }

  @computed
  get isDiffFlag() {
    return Common_Utils.isCheckBool(this.isDiff);
  }

  /**
   * 하위 목록 구분
   */
  @computed
  get isChildFlag() {
    return this.parentId > 0;
  }

  /**
   * 포렌식 실행 타입 (자동/수동)
   */
  @computed
  get isForensicExecuteTypeByAutoFlag() {
    return this.forensicExecuteType === FORENSIC_EXECUTE_TYPE_KEY.AUTO;
  }

  /**
   * 포렌식 수동 실행 타입 (입력/선택)
   */
  @computed
  get isForensicManualTypeBySelectFlag() {
    return this.forensicManualType === FORENSIC_MANUAL_TYPE_KEY.SELECT;
  }

  @computed
  get displayForensicType() {
    return this.CodeStore.getCodeName("forensicType", this.forensicType);
  }

  @computed
  get displayForensicExecuteType() {
    return this.CodeStore.getCodeName("forensicExecuteType", this.forensicExecuteType);
  }

  @computed
  get displayForensicManualType() {
    return this.CodeStore.getCodeName("forensicManualType", this.forensicManualType);
  }

  @computed
  get displayOriginalCheckDate() {
    return this.originalCheckDate || "-";
  }

  @computed
  get displayCurrentCheckDate() {
    return this.currentCheckDate || "-";
  }

  @computed
  get displayNewOriginalIcon() {
    return this.dateDiffIcon(this.originalCheckDate);
  }

  @computed
  get displayNewCurrentIcon() {
    return this.dateDiffIcon(this.currentCheckDate);
  }

  /**
   * 최근 등록값인지 확인 (1시간이내)
   * @param date
   * @returns
   */
  dateDiffIcon(date) {
    if (Common_Utils.isEmpty(date)) return "";

    const nowDate = new Date();
    const checkDate = new Date(date);
    const diffTime = Math.floor((nowDate.getTime() - checkDate.getTime()) / 1000);

    if (diffTime <= 3600) return "c-orange";
    return "";
  }
}

export default ServerForensicModel;
