import { observable } from "mobx";

import { SetterModel } from "@qubit-utill/src";

export default class TicketSystemModel extends SetterModel {
  constructor(params) {
    super(params);
    if (params) {
      this.value = params.version;
      this.name = params.version;
    }
  }

  @observable
  ticketSystemId: string = "";

  @observable
  version: string = "";

  @observable
  value: string = "";

  @observable
  name: string = "";
}
