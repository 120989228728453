const SECURITYDETECT_MITRE = "/sdetect/mitre";

const getURI = function(key) {
  const links = {
    INDEX: "/",
    LOGIN: "/login",
    DASHBOARD: "/dashboard",
    SIGNUP: "/signup",
    FINDPWD: "/findpassword",
    PASSWORD: "/password",
    SIGNUPCERT: "/signupcert",

    //보안탐지
    SECURITYDETECT_CORRELATION: "/sdetect/correlation",
    SECURITYDETECT_DATABREACH: "/sdetect/databreach",
    SECURITYDETECT_EXTORTACCOUNT: "/sdetect/takeover",
    SECURITYDETECT_WEBSITEFORGERY: "/sdetect/forgery",

    //마이터
    MITRE: SECURITYDETECT_MITRE,
    MITRE_MATRIX: SECURITYDETECT_MITRE + "/matrix",
    MITRE_LIST: SECURITYDETECT_MITRE + "/list",
    MITRE_HACK: SECURITYDETECT_MITRE + "/hack",
    MITRE_SOFT: SECURITYDETECT_MITRE + "/sw",

    //새창매트릭스
    NEW_MITRE_MATRIX: "/mitre/matrix",
    NEW_MITRE_SOFT_MATRIX: "/mitre/matrix/sw",
    NEW_MITRE_HACK_MATRIX: "/mitre/matrix/hack",

    //필터탐지
    FILTERDETECT_SYS: "/fdetect/host",
    // FILTERDETECT_APP: "/filterdetect/application/default",
    FILTERDETECT_APP_DEFAULT: "/fdetect/app/default",
    FILTERDETECT_APP_RESOURCE: "/fdetect/app/custom",
    FILTERDETECT_WEB: "/fdetect/web",
    FILTERDETECT_NET: "/fdetect/net",
    //웹방화벽 탐지 (1depth)
    FILTERDETECTWEBFIREWALL: "/waf",
    //호스트보안 탐지 (1depth)
    FILTERDETECTHOSTSECURITY: "/edr",

    //ML
    ML: "/ml",

    //전체로그
    TOTALLOG_SYS: "/flog/host",
    TOTALLOG_HOSTSECURITY: "/flog/edr",
    // TOTALLOG_APP: "/totallog/application",
    TOTALLOG_APP_DEFAULT: "/flog/app/default",
    TOTALLOG_APP_RESOURCE: "/flog/app/custom",
    TOTALLOG_WEB: "/flog/web",
    TOTALLOG_NET: "/flog/net",
    TOTALLOG_WEBFIREWALL: "/flog/waf",

    //보안필터
    SECURITYFILTER_CORRELATION: "/filter/security/correlation",
    SECURITYFILTER_CORRELATION_REGIST: "/filter/security/correlation/regist",
    SECURITYFILTER_CORRELATION_EDIT: "/filter/security/correlation/edit",
    SECURITYFILTER_DATABREACH: "/filter/security/databreach",
    SECURITYFILTER_DATABREACH_REGIST: "/filter/security/databreach/regist",
    SECURITYFILTER_DATABREACH_EDIT: "/filter/security/databreach/edit",
    SECURITYFILTER_EXTORTACCOUNT: "/filter/security/takeover",
    SECURITYFILTER_EXTORTACCOUNT_REGIST: "/filter/security/takeover/regist",
    SECURITYFILTER_EXTORTACCOUNT_EDIT: "/filter/security/takeover/edit",
    SECURITYFILTER_WEBSITEFORERY: "/filter/security/forgery",
    SECURITYFILTER_WEBSITEFORERY_EDIT: "/filter/security/forgery/edit",

    //추천필터
    RECOMMENDFILTER_DETAIL: "/filter/recommend/detail",
    RECOMMENDFILTER_REGIST: "/filter/recommend/regist",
    RECOMMENDFILTER_EDIT: "/filter/recommend/edit",
    //추천필터 > 시스템
    RECOMMENDFILTER_SYSTEM: "/filter/recommend/edr",
    RECOMMENDFILTER_SYSTEM_DETAIL: "/filter/recommend/edr/detail",
    RECOMMENDFILTER_SYSTEM_EDIT: "/filter/recommend/edr/edit",
    //추천필터 > 응용프로그램
    RECOMMENDFILTER_APPLICATION: "/filter/recommend/app",
    RECOMMENDFILTER_APPLICATION_DETAIL: "/filter/recommend/app/detail",
    RECOMMENDFILTER_APPLICATION_EDIT: "/filter/recommend/app/edit",
    //추천필터 > 네트워크
    RECOMMENDFILTER_NETWORK: "/filter/recommend/net",
    RECOMMENDFILTER_NETWORK_DETAIL: "/filter/recommend/net/detail",
    RECOMMENDFILTER_NETWORK_EDIT: "/filter/recommend/net/edit",

    //등록필터
    //등록필터 > 웹방화벽
    REGISTERATIONFILTER_WEBFIREWALL: "/filter/registration/waf",
    REGISTERATIONFILTER_WEBFIREWALL_REGIST: "/filter/registration/waf/regist",
    REGISTERATIONFILTER_WEBFIREWALL_EDIT: "/filter/registration/waf/edit",
    //등록필터 > 시스템
    REGISTERATIONFILTER_SYSTEM: "/filter/registration/edr",
    REGISTERATIONFILTER_SYSTEM_REGIST: "/filter/registration/edr/regist",
    REGISTERATIONFILTER_SYSTEM_EDIT: "/filter/registration/edr/edit",
    //등록필터 > 응용프로그램
    REGISTERATIONFILTER_APPLICATION_DEFAULT: "/filter/registration/app/default",
    REGISTERATIONFILTER_APPLICATION_DEFAULT_REGIST: "/filter/registration/app/default/regist",
    REGISTERATIONFILTER_APPLICATION_DEFAULT_EDIT: "/filter/registration/app/default/edit",
    REGISTERATIONFILTER_APPLICATION_RESOURCE: "/filter/registration/app/custom",
    REGISTERATIONFILTER_APPLICATION_RESOURCE_REGIST: "/filter/registration/app/custom/regist",
    REGISTERATIONFILTER_APPLICATION_RESOURCE_EDIT: "/filter/registration/app/custom/edit",
    //등록필터 > 웹
    REGISTERATIONFILTER_WEB: "/filter/registration/web",
    REGISTERATIONFILTER_WEB_REGIST: "/filter/registration/web/regist",
    REGISTERATIONFILTER_WEB_EDIT: "/filter/registration/web/edit",
    //등록필터 > 네트워크
    REGISTERATIONFILTER_NETWORK: "/filter/registration/net",
    REGISTERATIONFILTER_NETWORK_REGIST: "/filter/registration/net/regist",
    REGISTERATIONFILTER_NETWORK_EDIT: "/filter/registration/net/edit",

    //방어
    // EXECUTEDEFENCE: "/defence/execute",
    DEFENSE_IP_SYSTEM: "/defense/ip/host",
    DEFENSE_IP_WEBFIREWALL: "/defense/ip/waf",
    // DEFENCELOG: "/defence/log",
    DEFENSE_LOG_SYSTEM: "/defense/log/host",
    DEFENSE_LOG_WEBFIREWALL: "/defense/log/waf",
    // DEFENCEMANAGEMENT: "/defense/management",
    DEFENSE_MANAGEMENT_SYSTEM: "/defense/mgmt/host/correlation",
    DEFENSE_MANAGEMENT_CORRELATION: "/defense/mgmt/host/correlation",
    DEFENSE_MANAGEMENT_SYSTEMLOG: "/defense/mgmt/host/log",
    DEFENSE_MANAGEMENT_WEBFIREWALL: "/defense/mgmt/waf/takeover",
    DEFENSE_MANAGEMENT_EXTORTACCOUNTBLOCK: "/defense/mgmt/waf/takeover",
    DEFENSE_MANAGEMENT_EXTORTACCOUNTRESTRICTION: "/defense/mgmt/waf/takeover_restriction",
    DEFENSE_MANAGEMENT_DATABREACH: "/defense/mgmt/waf/databreach",
    DEFENSE_MANAGEMENT_OWASP: "/defense/mgmt/waf/owasp",
    // EXTORTACCOUNTDEFENCE: "/defence/management/extortaccount",
    // WEBLOGDEFENCE: "/defence/management/weblog",

    //시스템
    SERVER_MANAGEMENT: "/system/mgmt",
    SERVERGROUP_MANAGEMENT: "/system/groupmgmt",
    SERVERGROUP_REGIST: "/system/groupmgmt/regist",
    SERVERGROUP_EDIT: "/system/groupmgmt/edit",

    // 리소스모니터링
    RESOURCEMONITORING_HOST: "/resmon/host",
    RESOURCEMONITORING_HOST_DETAIL_NEW: "/resmon/host/detail",
    RESOURCEMONITORING_HOST_TOTAL: "/resmon/fhost",
    RESOURCEMONITORING_ALARMFILTER: "/resmon/alarm",
    RESOURCEMONITORING_ALARMFILTER_REGIST: "/resmon/alarm/regist",
    RESOURCEMONITORING_ALARMFILTER_EDIT: "/resmon/alarm/edit",

    //추출로그
    EXTRACTION_IP: "/extraction/ip",
    EXTRACTION_LOGIN: "/extraction/login",
    EXTRACTION_URL: "/extraction/url",

    //보고서
    REPORT_MAIN: "/report/main",
    REPORT_WEBFIREWALL: "/report/waf",
    REPORT_HOSTSECURITY: "/report/edr",
    REPORT_MITRE: "/report/mitre",
    REPORT_CORRELATION: "/report/correlation",
    REPORT_DATABREACH: "/report/databreach",
    REPORT_EXTORTACCOUNT: "/report/takeover",
    REPORT_SYS: "/report/host",
    REPORT_APP: "/report/app",
    REPORT_WEB: "/report/web",
    REPORT_NET: "/report/net",

    //통계
    STATISTIC_IP: "/stats/ip",
    STATISTIC_BOXPLOT: "/stats/boxplot",
    STATISTIC_WORDCLOUD: "/stats/wordcloud",
    STATISTIC_HEATMAP: "/stats/heatmap",
    STATISTIC_UPLOAD: "/stats/upload",
    STATISTIC_LOGIN: "/stats/login",

    // 컴플라이언스
    COMPLIANCE: "/compliance",

    //관리
    GROUP: "/group",
    GROUP_MEMBER: "/group/member",
    GROUP_SERVICE: "/group/service",
    GROUP_IP: "/group/ip",
    GROUP_SECURITY: "/group/security",
    GROUP_TICKET: "/group/linking",
    GROUP_SETTINGDETECT: "/group/setting",
    GROUP_USERLOG: "/group/history",
    GROUP_ALARM: "/group/alarm",
    GROUP_SYSTEM: "/group/system",
    GROUP_WAF: "/group/waf",
    GROUP_FIREWALL: "/group/firewall",
    GROUP_HAPROXY: "/group/haproxy",
    GROUP_SQUID: "/group/squid",

    //Q&A
    QNA: "/qna",
    QNA_REGIST: "/qna/regist",
    QNA_DETAIL: "/qna/detail",
    QNA_EDIT: "/qna/edit",

    //나의 정보
    MYINFO: "/myinfo",
    MYINFO_SECURITY: "/myinfo/security",
    MYINFO_USERINFO: "/myinfo/userinfo",
    MYINFO_ETCINFO: "/myinfo/etcinfo",
    MYINFO_EDITPWD: "/myinfo/editpassword",

    //개인알림
    ALARM: "/alarm",
    ALARM_BASIC: "/alarm/basic",
    ALARM_DETECT: "/alarm/detect",
    // ALARM_REPORT: "/alarm/report",
    ALARM_ETC: "/alarm/etc",

    //테마설정
    THEME: "/theme",

    //InstallAgent
    INSTALL: "/install",
    INSTALL_WINDOWS: "/install/windows",
    INSTALL_LINUX: "/install/linux",
    INSTALL_UBUNTU: "/install/ubuntu",
    INSTALL_UNIX: "/install/unix",
    INSTALL_COLLECTOR: "/install/collector",
    INSTALL_WEBFIREWALL: "/install/waf",
    INSTALL_LOGFORENSIC: "/install/forensic",

    //InstallAgent 가이드
    INSTALL_AGENT_GUIDE: "/installagentguide",

    //티켓
    TICKETLIST: "/ticket",
    AGENTLOG: "/serverupdatelog",

    //에러
    ERROR_FREETOPREMIUM: "/free",
    ERROR_CERTIFICATION: "/error/cert",

    //약관
    TERMS: "/terms",
    TERMS_PERSONAL: "/terms/privacy",
    TERMS_USE: "/terms/ofservice",

    //디코딩팝업
    DECODEENCODE: "/tools/encoder",
    //diff viewer
    DIFFCHECKER: "/tools/diffchecker",
  };

  if (links[key]) {
    return links[key];
  } else {
    return key;
  }
};

export default getURI;
