import { axiosModules } from "@qubit-utill/src";

export default {
  getFilterCategoryGroupDayList: (params) => {
    return axiosModules.get("/pstats/appdetect/day/categorygroup", params);
  },

  //리소스 일별 분류 그룹
  getResourceConfigUserGroupDayList: (params) => {
    return axiosModules.get("/pstats/resourcedetect/day/resourceconfigusergroup", params);
  },

  //리소스 주별 분류 그룹
  getResourceConfigUserGroupWeekList: (params) => {
    return axiosModules.get("/pstats/resourcedetect/week/resourceconfigusergroup", params);
  },

  //리소스 월별 분류 그룹
  getResourceConfigUserGroupMonthList: (params) => {
    return axiosModules.get("/pstats/resourcedetect/month/resourceconfigusergroup", params);
  },

  /////////////////// 일별 ///////////////////
  //원본 메인 차트
  getApplicationDayMainChart: (params) => {
    return axiosModules.get("/pstats/appdetect/day/mainchart", params);
  },
  //원본 서브 차트
  getApplicationDaySubChart: (params) => {
    return axiosModules.get("/pstats/appdetect/day/subchart", params);
  },
  //원본 리스트
  getApplicationDayMainDetect: (params) => {
    return axiosModules.get("/pstats/appdetect/day/main", params);
  },

  //사용자정의 메인 차트
  getResourceDayMainChart: (params) => {
    return axiosModules.get("/pstats/resourcedetect/day/mainchart", params);
  },
  //사용자정의 서브 차트
  getResourceDaySubChart: (params) => {
    return axiosModules.get("/pstats/resourcedetect/day/subchart", params);
  },
  //사용자정의 리스트
  getResourceDayMainDetect: (params) => {
    return axiosModules.get("/pstats/resourcedetect/day/main", params);
  },

  /////////////////// 주별 ///////////////////
  //원본 메인 차트
  getApplicationWeekMainChart: (params) => {
    return axiosModules.get("/pstats/appdetect/week/mainchart", params);
  },
  //원본 서브 차트
  getApplicationWeekSubChart: (params) => {
    return axiosModules.get("/pstats/appdetect/week/subchart", params);
  },
  //원본 리스트
  getApplicationWeekMainDetect: (params) => {
    return axiosModules.get("/pstats/appdetect/week/main", params);
  },

  //사용자정의 메인 차트
  getResourceWeekMainChart: (params) => {
    return axiosModules.get("/pstats/resourcedetect/week/mainchart", params);
  },

  //사용자정의 서브 차트
  getResourceWeekSubChart: (params) => {
    return axiosModules.get("/pstats/resourcedetect/week/subchart", params);
  },

  //사용자정의 리스트
  getResourceWeekMainDetect: (params) => {
    return axiosModules.get("/pstats/resourcedetect/week/main", params);
  },

  /////////////////// 월별 ///////////////////
  //원본 메인 차트
  getApplicationMonthMainChart: (params) => {
    return axiosModules.get("/pstats/appdetect/month/mainchart", params);
  },

  //원본 리스트
  getApplicationMonthMainDetect: (params) => {
    return axiosModules.get("/pstats/appdetect/month/main", params);
  },

  //원본 메인 차트
  getResourceMonthMainChart: (params) => {
    return axiosModules.get("/pstats/resourcedetect/month/mainchart", params);
  },

  //원본 리스트
  getResourceMonthMainDetect: (params) => {
    return axiosModules.get("/pstats/resourcedetect/month/main", params);
  },
};
