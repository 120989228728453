import { axiosModules } from "@qubit-utill/src";

export default {
  getFilterCustomConfigList: (filter, params) => {
    return axiosModules.get(`/pfilter/filtercustomconfigs/${filter}`, {
      ...params,
    });
  },
  getFilterCustomConfigInfo: (filterCustomConfigId) => {
    return axiosModules.get(`/pfilter/filtercustomconfig/${filterCustomConfigId}`);
  },
  createFilterCustomConfig: (data, params) => {
    return axiosModules.post("/pfilter/filtercustomconfig", data, params);
  },
  updateFilterCustomConfig: (
    filterIds,
    serverGroupIds,
    isUseCustom,
    isDetectTimeRangeUse,
    detectStartTime,
    detectEndTime,
    filterCustomConfigIds,
    logTag?
  ) => {
    return axiosModules.put("/pfilter/filtercustomconfig", {
      filterIds,
      serverGroupIds: serverGroupIds ? serverGroupIds : null,
      isUseCustom,
      isDetectTimeRangeUse,
      detectStartTime,
      detectEndTime,
      filterCustomConfigIds,
      logTag,
    });
  },
  updateFilterCustomConfigIsUse: (isUseCustom, data, params?) => {
    return axiosModules.put("/pfilter/filtercustomconfigisuse", { isUseCustom, ...data }, params);
  },
  deleteFilterCustomConfig: (data, params?) => {
    return axiosModules.patch("/pfilter/filtercustomconfig", data, params);
  },

  // pfilter > filters > 필터 자동완성 검색용 > 필터 사용자 설정 목록 - 자동완성용
  getFilterCustomListForAutoComplete: (channelLogType) => {
    return axiosModules.get(`/pfilter/filtercustomconfigs/simple/${channelLogType}`, { limit: 9999 });
  },
};
