import { observable, action, toJS } from "mobx";

import { ListCondition, Common_Utils, withLoading, languageSet, SearchWordModel } from "@qubit-utill/src";

import { applicationLogApis } from "@src/apis/pLog";
import { resourceConfigUserApis, resourceMetaApis } from "@src/apis/pMetrics";

import { DefenceStore } from "@src/common/stores";
import { SelectOptionModel } from "@src/common/models";
import { RESOURCELOGISSUETYPE } from "@src/constants/code";
import { CSVINFO, UISET } from "@src/constants";

import ApplicationResourceTotalLogModel from "@src/pages/TotalLog/ApplicationTotalLog/Resource/Models/ApplicationResourceTotalLogModel";

class ApplicationResourceTotalLogStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }

  root;

  @observable
  tableList: ApplicationResourceTotalLogModel[] = [];

  @observable
  applicationTotalLogInfo = new ApplicationResourceTotalLogModel({});

  @observable
  resourceThList = [];

  @observable
  resourceMetaList = [];

  @observable
  optionList: SelectOptionModel[] = [];

  @observable
  resourceMetaSearchParams = [];

  @action
  setResourceParams = (resourceId: string, isGetData: boolean, isClearMetaParmas: boolean) => {
    isClearMetaParmas && this.clearMetaParams();
    this.resourceMetaSearchParams = [];
    const searchKey = this.root.SearchWordStore.getPathName(resourceId);
    const localList = window.localStorage.getItem(searchKey);

    const params = localList && observable(JSON.parse(localList).map((data) => new SearchWordModel(data, true)));

    params &&
      params.map((info) => {
        if (info.isApply) {
          if (info.isMetaProperty === "1") {
            info.resourceMetaSearchField.forEach((value) => {
              if (value.includes("resourceMetaId")) {
                this.setListParams(value, info.searchOption);
              } else {
                this.setListParams(value, info.assign !== "" ? `${info.assign}:${info.searchText}` : info.searchText);
              }
            });
          } else {
            this.setListParams(info.searchOption, info.assign !== "" ? `${info.assign}:${info.searchText}` : info.searchText);
          }
        }
      });

    this.setListParams("resourceId", resourceId);
    isGetData && this.getList(false);
  };

  //서비스 리스트
  @action
  getServiceListForOption = async () => {
    try {
      const { list } = await resourceConfigUserApis.getResourceConfigUserGroupList();
      this.optionList = list.map((info) => new SelectOptionModel({ name: info.resourceName, code: info.resourceId }));

      if (this.optionList.length > 0) {
        this.params = this.params.resourceId ? this.params : { ...this.params, resourceId: this.optionList[0].code };
      }

      //보고서에서 넘어올 경우 전달받은 리소스 아이디가 있으면 파라미터에 새로 셋팅
      if (sessionStorage.getItem("resourceId")) {
        this.params = { ...this.params, resourceId: sessionStorage.getItem("resourceId") };
      }
    } catch ({ data }) {
      this.optionList = [];
      return data;
    }
  };

  @action
  getResourceMetaList = async () => {
    try {
      const { list } = await resourceMetaApis.getResourceMetaList(this.params.resourceId);
      return list;
    } catch (e) {
      return [];
    }
  };

  @withLoading("isLoading")
  @action
  getList = async (isSetResourceParams: boolean = true) => {
    await this.getServiceListForOption();
    isSetResourceParams && (await this.setResourceParams(this.params.resourceId, false, false));
    try {
      const tagServicePropertyList = this.tagServicePropertyList;
      const tagValueTypeList = this.tagValueTypeList;
      const getTagServiceDataList = this.openIpTagModal;

      const resourceMetaList = await this.getResourceMetaList();
      this.resourceMetaList = resourceMetaList;

      this.resourceThList = resourceMetaList
        ? resourceMetaList.map((data) => {
            return {
              displayName: data.metaName,
              columnName: data.metaName,
              useSort: true,
              useCustom: true,
              className: "w_110px",
            };
          })
        : [];

      const { list, totalCount, nextCursorMark } = await applicationLogApis.getResourceLogList(toJS(this.params));
      this.tableList = list.map((info) => {
        const _info = { ...info, resourceMetaList, tagServicePropertyList, tagValueTypeList, getTagServiceDataList };
        return new ApplicationResourceTotalLogModel(_info);
      });

      this.setCursorMarkInfo(nextCursorMark);
      this.totalCount = totalCount;

      sessionStorage.removeItem("resourceId");
    } catch (e) {
      console.log("ApplicationResourceTotalLogStore", e);
      this.tableList = [];
      this.totalCount = 0;
    }
  };

  @withLoading("isSubLoading")
  @action
  getInfo = async (documentId) => {
    try {
      const tagServicePropertyList = this.tagServicePropertyList;
      const tagValueTypeList = this.tagValueTypeList;
      const getTagServiceDataList = this.openIpTagModal;
      const getTagServiceDataListFn = this.getTagServiceDataList;

      const { info, viewTemplate, programMessageTemplate } = await applicationLogApis.getResourceLog(documentId);
      const resourceMetaList = this.resourceMetaList;

      const _info = { ...info, tagServicePropertyList, tagValueTypeList, getTagServiceDataList, getTagServiceDataListFn };

      this.applicationTotalLogInfo = new ApplicationResourceTotalLogModel({
        ..._info,
        viewTemplate,
        programMessageTemplate,
        resourceMetaList,
      });
      info.logClientIp && (await DefenceStore.getDefenceInfo(this.applicationTotalLogInfo));
      this.root.TicketStore.getPrintTicketInfo(RESOURCELOGISSUETYPE, documentId, info.logCreateDate); //get 티켓발행정보
    } catch (err) {
      console.log(err);
      this.applicationTotalLogInfo = new ApplicationResourceTotalLogModel({});
    }
  };

  @action
  downloadCSV = async (isUseBulk?, no?) => {
    let appResource = JSON.stringify(CSVINFO.TOTALLOG_APP_RESOURCE_BASIC);
    CSVINFO.TOTALLOG_APP_RESOURCE = JSON.parse(appResource);

    this.resourceMetaList.map((info, index) => CSVINFO.TOTALLOG_APP_RESOURCE.head.splice(5 + index, 0, info["metaName"]));
    this.resourceMetaList.map((info, index) => CSVINFO.TOTALLOG_APP_RESOURCE.infoKey.splice(5 + index, 0, info["metaName"]));
    const resourceName = this.resourceMetaList[0]["resourceName"];

    try {
      if (this.tableList.length > 0) {
        let csv;
        if (isUseBulk && no) {
          csv = await applicationLogApis.getResourceLogCsvList({
            ...toJS(this.params),
            // serverIds: toJS(this.params.serverIds),
            // typeosList: toJS(this.params.typeosList),
            // serverGroupIds: toJS(this.params.serverGroupIds),
            offset: 0,
            limit: no,
          });
        } else {
          csv = Common_Utils.makeCSV(CSVINFO.TOTALLOG_APP_RESOURCE.head, this.tableList);
        }

        Common_Utils.csvDownload(
          csv,
          Common_Utils.setCsvFileName({
            title: UISET.TOTALLOG_APP_RESOURCE.title,
            category: `${languageSet("사용자정의")}_${resourceName}_${languageSet("전체로그")}`,
            ...this.params,
          })
        );
      } else {
        return languageSet("에러_데이터가없습니다");
      }
    } catch ({ data }) {
      console.log(data);
    }
  };
}

export default ApplicationResourceTotalLogStore;
