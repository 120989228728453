import { observable, action } from "mobx";

import { ListCondition, Common_Utils, withLoading, languageSet } from "@qubit-utill/src";

import extractionApis from "@src/apis/pStats/extractionApis";
import { LINECOUNT, CSVINFO } from "@src/constants";
import { IpListModel } from "@src/pages/Extraction/IpExtraction/models";

class IpExtractionStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }
  root;

  @observable
  tableList = [];

  @observable
  ipExtractionInfo = [];

  @observable
  ipExtractionSubInfo = [];

  @observable
  subTotalCount = 0;

  @observable
  subParams = {
    limit: LINECOUNT,
    offset: 0,
    clientIp: "",
    logCreateDateStart: "",
    logCreateDateEnd: "",
  };

  @withLoading("isLoading")
  @action
  getList = async () => {
    try {
      const { list, totalCount } = await extractionApis.getIpExtractionList(this.params);
      this.tableList = list.map((data) => new IpListModel(data, this.root.CodeStore));
      this.totalCount = totalCount;
    } catch (response) {
      this.tableList = [];
      this.totalCount = 0;
      return response;
    }
  };

  @action
  getListForDown = async () => {
    try {
      let { list } = await extractionApis.getIpExtractionList({
        ...this.params,
        limit: 3000,
      });
      list = list.map((data) => new IpListModel(data, this.root.CodeStore));

      const csv = Common_Utils.makeCSV(CSVINFO.EXTRACTION_IP.head, list);
      Common_Utils.csvDownload(
        csv,
        `${languageSet("IP주소_추출로그")}_${this.params.startDetectDate.split(" ")[0]}~${
          this.params.endDetectDate.split(" ")[0]
        }`
      );
    } catch ({ data }) {
      return data;
    }
  };
}

export default IpExtractionStore;
