import { axiosModules } from "@qubit-utill/src";

const DATABREACH_PARAM = "?webExtendsFilterType=webextendsdatabreach&detectFilterType=webextendsdatabreach";

export default {
  //탭메뉴
  getFilterCategoryGroupDayList: (params) => {
    return axiosModules.get("/pstats/webextendsdetect/day/categorygroup" + DATABREACH_PARAM, params);
  },
  //리스트
  getWebExtendsDayMainDetect: (params) => {
    return axiosModules.get("/pstats/webextendsdetect/day/main" + DATABREACH_PARAM, params);
  },
  //차트
  getWebExtendsDayMainChart: (params) => {
    return axiosModules.get("/pstats/webextendsdetect/day/mainchart" + DATABREACH_PARAM, params);
  },
  //서브 차트
  getWebExtendsDaySubChart: (params) => {
    return axiosModules.get("/pstats/webextendsdetect/day/subchart" + DATABREACH_PARAM, params);
  },
  //리스트 주별
  getWebExtendsWeekMainDetect: (params) => {
    return axiosModules.get("/pstats/webextendsdetect/week/main" + DATABREACH_PARAM, params);
  },
  //차트 주별
  getWebExtendsWeekMainChart: (params) => {
    return axiosModules.get("/pstats/webextendsdetect/week/mainchart" + DATABREACH_PARAM, params);
  },
  //차트 주별
  getWebExtendsWeekSubChart: (params) => {
    return axiosModules.get("/pstats/webextendsdetect/week/subchart" + DATABREACH_PARAM, params);
  },
  //리스트 월별
  getWebExtendsMonthMainDetect: (params) => {
    return axiosModules.get("/pstats/webextendsdetect/month/main" + DATABREACH_PARAM, params);
  },
  //차트 월별
  getWebExtendsMonthMainChart: (params) => {
    return axiosModules.get("/pstats/webextendsdetect/month/mainchart" + DATABREACH_PARAM, params);
  },
};
