import { observable, action, computed } from "mobx";
import { startOfYesterday, startOfMonth, endOfMonth, startOfWeek, endOfWeek } from "date-fns";

import { ListCondition, dateUtil, languageSet, withLoading, Common_Utils } from "@qubit-utill/src";

import { pAuthApis } from "@src/apis";
import { CSVINFO } from "@src/constants";

import { ReportCategoryTabModel, LogListModel } from "@src/pages/Report/models";

class FunctionClass extends ListCondition {
  getDay = (day?: string) => {};
  getWeek = (date?: Date) => {};
  getMonth = () => {};
  getCategoryTabApi = (param: object): { list: Array<any> } => ({
    list: [],
  });
  getGraphFileName = (param): string => "";
  downloadCSV = (list: Array<object>, etcText?: string) => {};
}

class ReportStore extends FunctionClass {
  constructor(root?) {
    super();
    this.root = root;
    const currentPath = Common_Utils.getCurrentPathUrl();
    const session = sessionStorage.getItem(currentPath);
    const params = session ? JSON.parse(session) : null;
    this.dateInfo = dateUtil.getDateInfo(params ? new Date(params.detectDate) : startOfYesterday());

    this.params.detectFilterType = "";
    this.params.limit = 10;
    this.params.selectedService = "system";
    this.params.filterCategoryGroupId = 0;
    if (params) {
      this.params.serverIds = params.serverIds && params.serverIds.length > 0 && params.serverIds;
      this.params.serverGroupList = params.serverGroupIds && params.serverGroupIds.length > 0 && params.serverGroupIds;
      this.params.serverGroupIds = params.serverGroupIds && params.serverGroupIds.length > 0 && params.serverGroupIds;
    }
  }
  root;

  @observable
  startDate = "";
  @observable
  endDate = "";
  @observable
  csvInfoKey = "";

  @observable
  graphTitle = "";
  @observable
  mainGraphTitle = languageSet("공격유형_분포");
  @observable
  subGraphTitle = "";
  @observable
  weeklyGraphTitle = languageSet("공격유형_분포_주별");

  @observable
  isLoading = false;
  @observable
  isSubLoading = true;
  @observable
  isTableLoading = true;

  @observable
  limitDate = "61";

  @observable
  dateInfo: any = {
    detectDate: "",
    detectMonth: "",
    detectYear: "",
    detectWeek: "",
    detectWeekYear: "",
  }; //getDetectData API호출로 date정보 가져옴 (주, 월별 api호출시 필요한 정보) > param date

  //일일, 주별, 월별 탭 선택정보
  @observable
  termsTab = "day";

  @computed
  get termsText() {
    if (this.termsTab === "day") {
      return `${languageSet("일별")}_${this.dateInfo.detectDate}`;
    } else if (this.termsTab === "week") {
      const { year, month, weekNo } = dateUtil.getWeekNumberByMonth(this.dateInfo.detectDate);
      return `${languageSet("주별")}_${dateUtil.setYearWeekWeekNo(year, month, weekNo)}`;
    } else {
      return `${languageSet("월별")}_${this.dateInfo.detectYear}_${languageSet("n월", this.dateInfo.detectMonth)}`;
    }
  }

  //보고서 분류탭 선택(daliy)
  @observable
  tabMenus: ReportCategoryTabModel[] = []; //분류탭 리스트

  @observable
  selectedTab: any = null;

  @action
  setSelectedTab = (value: string | number) => {
    this.selectedTab = value;
  };

  @action
  setSelectedClear = () => {
    this.selectedTab = 0;
  };

  @observable
  mainChartData: any = {
    category: [],
    data: [],
  };

  @observable
  barChartData: any = {
    category: [],
    data: [],
  };

  @observable
  pieChartData: any = {
    data: [],
  };

  @observable
  methodChartData = {
    data: [],
  };

  @observable
  weekChartData = {
    category: [],
    data: [],
  };

  @observable
  logList: LogListModel[] = []; // 그래프 밑 리스트 정보들을 위한 데이터

  @observable
  webStatus = ""; //param이름 변경됨

  @observable
  selectedGraphTab = "total";

  @observable
  selectedGraphName = languageSet("종합");

  @observable
  resourceId: string = "";

  @action
  initSelectedGraphTab = () => {
    this.setInfo("selectedGraphTab", "total");
    this.setInfo("selectedGraphName", languageSet("종합"));
  };

  @action
  clear = () => {
    //페이지 이동시, 메뉴 선택시 다를수있음
    this.defaultClear();
  };

  @action
  defaultClear = () => {
    this.dateInfo = dateUtil.getDateInfo(startOfYesterday());
    this.params.detectFilterType = "";
    this.params.filterCategoryGroupId = 0;
    this.params.blocked = ""; //웹방화벽 탐지/차단
    this.deleteListParams("serverIds");
    this.termsTab = "day";
    this.webStatus = "";
    this.dataInit();
  };

  @action
  dataInit = () => {
    this.mainChartData = {
      category: [],
      data: [],
    };

    this.weekChartData = {
      category: [],
      data: [],
    };

    this.barChartData = {
      category: [],
      data: [],
    };

    this.pieChartData = {
      data: [],
    };

    this.methodChartData = {
      data: [],
    };
  };

  @action
  setInfo = (key: string, value: any) => {
    this[key] = value;
  };

  @action
  getCategoryTab = async () => {
    let ArrayServerIds: Array<number> = [];
    let serverIds = this.params.serverIds;

    if (serverIds) {
      ArrayServerIds.push(serverIds.map((serverId) => serverId));
    }

    try {
      const { list } = await this.getCategoryTabApi({
        detectDate: this.dateInfo.detectDate,
        detectStatusCodeRange: this.webStatus,
        blocked: this.params.blocked,
        serverIds: ArrayServerIds,
      });
      this.tabMenus = list.map((data: any) => new ReportCategoryTabModel(data));
    } catch ({ data }) {
      this.tabMenus = [];
    }
  };

  subTitles = {
    day: languageSet("시간별_탐지로그발생_현황"),
    week: languageSet("로그발생_현황"),
    month: languageSet("로그발생_현황"),
  };

  @action
  setSubTitle = (date?: string) => {
    switch (this.termsTab) {
      case "day":
        this.subGraphTitle = `${date ? date : this.dateInfo.detectDate} ${this.subTitles.day}`;
        break;

      case "week":
        this.subGraphTitle = `${date} ${this.subTitles.week}`;
        break;

      case "month":
        this.subGraphTitle = `${this.dateInfo.detectYear} ${languageSet("n월", this.dateInfo.detectMonth)} ${
          this.subTitles.month
        }`;
        break;
    }
  };

  //초기페이지 진입시 데이터 호출
  @withLoading("isLoading")
  getData = async (param?: any) => {
    const { info } = await pAuthApis.getLicenseActivityInfo({
      serviceId: "psearch",
      licenseActivityType: "searchDateRange",
    });

    const resourceId = this.params.resourceId;

    this.limitDate = info.limitCount;
    if (this.termsTab === "day") {
      this.setSubTitle();
      await this.getCategoryTab();
      await this.getDay();
    } else if (this.termsTab === "week") {
      const { year, month, weekNo } = dateUtil.getWeekNumberByMonth(this.dateInfo.detectDate);
      this.setSubTitle(dateUtil.setYearWeekWeekNo(year, month, weekNo));
      resourceId && (await this.getCategoryTab());
      await this.getWeek();
    } else {
      this.setSubTitle();
      resourceId && (await this.getCategoryTab());
      await this.getMonth();
    }
    this.initSelectedGraphTab();
  };

  //searchListFilter사용
  getList = () => {
    this.getData();
  };

  @action
  @withLoading("isSubLoading")
  getSubChartData = (dateOrIndex: any, _xName: any, xName?) => {
    this.isSubLoading = false;
    if (this.termsTab === "day") {
      this.setSubTitle(dateOrIndex);
      this.getDay(dateOrIndex);
    } else if (this.termsTab === "week") {
      _xName = _xName ? _xName : dateOrIndex;
      const year = _xName.year;
      const week = _xName.week;
      const _month = xName.split(" ")[1];
      const month = _month && _month.substring(0, _month.length - 1);
      const weekName = dateUtil.getWeekDay(dateOrIndex);
      this.setSubTitle(`${xName} ${weekName}`);
      const date = dateUtil.getDateByWeekNumber(year, month || this.dateInfo.detectMonth, week, dateOrIndex);
      this.getWeek(new Date(date));
    }
  };

  @action
  getDateRange = (start: string, end: string) => dateUtil.getDateByInterval(start, end);

  downloadCSV = (list, etcText = "", notUseNo = false) => {
    const head = CSVINFO[this.csvInfoKey].head;
    const csv = Common_Utils.makeCSV(head, list, etcText, notUseNo);
    Common_Utils.csvDownload(csv, this.getGraphFileName(etcText));
  };

  @action
  setStartEndDate = (date?) => {
    date = date ? date : this.dateInfo.detectDate;
    switch (this.termsTab) {
      case "week":
        this.startDate = dateUtil.getDateByForm(startOfWeek(new Date(date)));
        this.endDate = dateUtil.getDateByForm(endOfWeek(new Date(date)));
        break;
      case "month":
        this.startDate = dateUtil.getDateByForm(startOfMonth(new Date(date)));
        this.endDate = dateUtil.getDateByForm(endOfMonth(new Date(date)));
        break;
      default:
        this.startDate = date;
        this.endDate = date;
        break;
    }
  };
}

export default ReportStore;
