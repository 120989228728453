import { observable, computed, action } from "mobx";

import { Common_Utils, languageSet, stringUtils, SetterModel } from "@qubit-utill/src";

import { CSVINFO } from "@src/constants";
import { LOGFORENSIC_LICENSECODE } from "@src/constants/licenseCode";

import { ResourceConfigPathModel } from "@src/pages/Server/ServerManagement/models";

export default class ServerModel extends SetterModel {
  constructor(params, CodeStore, RoleLicenseStore?) {
    super(params);
    this.CodeStore = CodeStore;

    if (params) {
      if (params.resourceConfigList) {
        this.resourceConfigList = params.resourceConfigList;
        this.serverResourceConfigPathList = params.resourceConfigList.map(
          (info) => new ResourceConfigPathModel(info, params.serverResourceConfigPathList)
        );
      }

      if (params.logTypeSummary) {
        const logTypeSummary = params.logTypeSummary.split(",");
        this.logTypeSummaryList.forEach((info) => {
          if (logTypeSummary.some((logType) => logType === info.logType)) {
            info.isUse = true;
            info.cursor = "cursor_p";
            info.labelColor = "blue";
          }
        });
      }

      params.registerDate && (this.registerDate = params.registerDate.split(".")[0]);
      const logForensicLicense = (() => {
        return RoleLicenseStore && (this.isLogForensicLicense = RoleLicenseStore.hasLicense(LOGFORENSIC_LICENSECODE));
      })();

      if (!logForensicLicense) {
        if (params.isUseAgent === "1") {
          this.registerWay = languageSet("라이선스");
        } else {
          this.registerWay = languageSet("직접");
          this.serverManagerName = `[${Common_Utils.isDataWithDashBar(params.serverManagerName)}]`;
        }
      } else {
        this.isLogForensicLicense = true;
        if (params.isUseAgent === "1") {
          this.registerWay = languageSet("라이선스");
        } else {
          this.registerWay = languageSet("업로드");
        }
      }

      this.serverOs = params.typeos;
      this.agentActiveLogDate = this.registerDate;
      this.osVersion = params.osReleaseName;
      this.serverGroupName = params.serverGroup;
      // this.webLogPath = params.webLogPath ? params.webLogPath : "";
      this.osLatestVersion = params.osLatestVersion || "-";
      if (params.typeos === "windows") {
        this.osLatestInfo = stringUtils.replaceAll(params.osLatestInfo, ",", "<br/>");
      }

      if (params.osReleaseType === "windows") {
        this.osReleaseTypeName = params.osReleaseType.charAt(0).toUpperCase() + params.osReleaseType.slice(1);
      } else {
        this.osReleaseTypeName = params.osReleaseType;
      }

      if (params.serverLogConfigPathOnOffCountModel) {
        const obj = params.serverLogConfigPathOnOffCountModel;
        for (let key in obj) {
          const value = obj[key];
          this[key] = value;
        }
      }

      if (
        params.serverLogConfigPathList === null ||
        (params.serverLogConfigPathList && params.serverLogConfigPathList.length === 0)
      ) {
        this.serverLogConfigPathList = [{ configPath: "", isUse: "0", logType: "" }];
      }
    }
  }

  CodeStore;

  @action
  setConfigPath(value, logType) {
    this.serverLogConfigPathList[0].logType = logType;
    this.serverLogConfigPathList[0].configPath = value;
  }

  @computed
  get uselessDefaultPathFlag() {
    return this.serverOs === "windows" && this.logcollecterId === null;
  }

  @computed
  get agentStatus() {
    return Common_Utils.isCheckBool(this.isLogUpload);
  }

  @computed
  get isUseSysLogFlag() {
    return Common_Utils.isCheckBool(this.isUseSysLog);
  }

  @computed
  get isUseApplicationLogFlag() {
    return Common_Utils.isCheckBool(this.isUseApplicationLog);
  }

  @computed
  get isUseWebLogFlag() {
    return Common_Utils.isCheckBool(this.isUseWebLog);
  }

  @computed
  get isUseNetworkLogFlag() {
    return Common_Utils.isCheckBool(this.isUseNetworkLog);
  }

  @computed
  get isUseAgentFlag() {
    return Common_Utils.isCheckBool(this.isUseAgent);
  }

  @computed
  get isSaveFullLogFlag() {
    return Common_Utils.isCheckBool(this.isSaveFullLog);
  }

  @computed
  get isSaveFullLogName() {
    return Common_Utils.isCheckBool(this.isSaveFullLog) ? languageSet("저장") : languageSet("미저장");
  }

  @computed
  get getSaveFullLogClassName() {
    return Common_Utils.isCheckBool(this.isSaveFullLog) ? "icon_circle_save" : "icon_circle_n_save";
  }

  @computed
  get isOceanFlag() {
    return this.agentType === "DLC" ? true : false;
  }

  @computed
  get agentVersionText() {
    const agentTypes = ["LOGCOLLECTOR", "HAPROXY", "SQUID", "FIREWALL"];

    if (this.isUseAgentFlag) {
      if (agentTypes.includes(this.agentType)) {
        return `${this.agentType.split("")[0]}${this.agentVersion}`;
      } else {
        return `${this.isWafFlag ? "W" : "P"}${this.agentVersion}`;
      }
    } else {
      if (this.isOceanFlag) {
        return this.agentVersion;
      } else {
        return "-";
      }
    }
  }

  @computed
  get isLogCollectorFlag() {
    return Boolean(this.agentType === "LOGCOLLECTOR");
  }

  @computed
  get isHAProxyFlag() {
    return Boolean(this.agentType === "HAPROXY");
  }

  @computed
  get isSquidFlag() {
    return Boolean(this.agentType === "SQUID");
  }

  @computed
  get isWafFlag() {
    return Common_Utils.isCheckBool(this.isWaf);
  }

  @computed
  get isWindowAgentFlag() {
    return Boolean(this.serverOs === "windows");
  }

  @computed
  get isViewApplicationLogFlag() {
    return this.isWafFlag || this.isWindowAgentFlag;
  }

  @computed
  get isWafBlockModeFlag() {
    return Common_Utils.isCheckBool(this.isWafBlockMode);
  }

  @computed
  get isAgentActiveFlag() {
    return Common_Utils.isCheckBool(this.isAgentActive);
  }

  @computed
  get isResourceUploadFlag() {
    return Common_Utils.isCheckBool(this.isResourceUpload);
  }

  @computed
  get wafUploadModeText() {
    return this.CodeStore.getCodeName("wafUploadMode", this.wafUploadMode);
  }

  @computed
  get displayModPluraType() {
    if (this.modPluraType && this.modPluraVersion) {
      return `${this.modPluraType} / ${this.modPluraVersion}`;
    } else {
      return "-";
    }
  }

  @computed
  get displayAgentExecuteStateIsActive() {
    if (this.modPluraType === null || this.displayAgentExecuteState === "20") {
      return false;
    } else if (this.displayAgentExecuteState === "30" || this.displayAgentExecuteState === "40" || this.agentLogInfo === null) {
      return true;
    }
    return false;
  }

  @computed
  get logCollectForCSV() {
    if (this.logTypeSummary !== null) {
      const logTypeSummary = this.logTypeSummary.split(",");

      const logTypeName = {
        system: languageSet("호스트보안"),
        application: languageSet("응용프로그램"),
        web: languageSet("웹"),
        network: languageSet("네트워크"),
      };

      const logCollect = logTypeSummary.reduce((acc, cur, index, src) => {
        acc += logTypeName[cur] + (src.length - 1 > index ? "/" : "");
        return acc;
      }, "");

      return logCollect;
    } else {
      return "-";
    }
  }

  @computed
  get agentStatusText() {
    return this.isUseAgentFlag ? (this.agentStatus ? "ON" : "OFF") : "-";
  }

  @computed
  get isAgentActiveText() {
    return languageSet(this.isAgentActiveFlag ? "정상" : "중지");
  }

  @computed
  get isAgentActiveForSort() {
    if (this.isUseAgentFlag) return `${this.isAgentActiveText}${this.agentVersionText}`;
    return null;
  }

  @computed
  get csvData() {
    return Common_Utils.makeCsvRowData(
      this,
      this.isLogForensicLicense ? CSVINFO.SYSTEM_LOGFORENSIC.infoKey : CSVINFO.SYSTEM.infoKey
    );
  }

  @computed
  get isAgentDelFlag() {
    return Common_Utils.isCheckBool(this.isAgentDel);
  }

  @computed
  get logUploadStatusMessage() {
    if (this.logUploadStatus === "2") {
      return `${languageSet("웹")} OFF`;
    } else if (this.logUploadStatus === "3") {
      return `${languageSet("에이전트")} OFF`;
    } else {
      return null;
    }
  }

  @computed
  get isUseOceanLogOrderFlag() {
    return Common_Utils.isCheckBool(this.isUseOceanLogOrder);
  }

  @computed
  get isVisibleLogCountByChannelFlag() {
    return (this.osFamily === "windows" || this.osFamily === "linux") && !this.isLogCollectorFlag;
  }

  @computed
  get isLinuxFlag() {
    return this.osFamily === "linux";
  }

  @computed
  get isEdrSysFullUploadFlag() {
    return Common_Utils.isCheckBool(this.isEdrSysFullUpload);
  }

  @computed
  get isEdrSupportFlag() {
    return Common_Utils.isCheckBool(this.isEdrSupport);
  }

  @computed
  get blockModeText() {
    return this.isEdrSupportFlag ? languageSet(this.isEdrBlockFlag ? "차단" : "탐지") : "-";
  }

  @computed
  get isShutdownFlag() {
    return Common_Utils.isCheckBool(this.isShutdown);
  }

  @computed
  get isIsolationFlag() {
    return Common_Utils.isCheckBool(this.isIsolation);
  }

  @computed
  get isServerFlag() {
    return Boolean(this.edrDetectMode === "server");
  }

  @computed
  get isEdrBlockFlag() {
    return Boolean(this.edrBlockMode === "block");
  }

  @computed
  get isServerStatusCheckFlag() {
    return Common_Utils.isCheckBool(this.serverStatusCheck);
  }

  @observable
  logTypeSummaryList: Array<{
    logType: string;
    logTypeName: string;
    bgColor: string;
    name: string;
    cursor: string;
    labelColor: string;
    isMultiPath: boolean;
    isUse: boolean;
    isUseLog: string;
  }> = [
    {
      logType: "system",
      logTypeName: languageSet("호스트보안로그_수집"),
      name: languageSet("호스트보안"),
      cursor: "cursor_d",
      labelColor: "default",
      bgColor: "bg-blue-dark01",
      isMultiPath: false,
      isUse: false,
      isUseLog: "isUseSysLog",
    },
    {
      logType: "application",
      logTypeName: languageSet("응용프로그램_원본로그"),
      name: languageSet("응용프로그램"),
      cursor: "cursor_d",
      labelColor: "default",
      bgColor: "bg-green",
      isMultiPath: true,
      isUse: false,
      isUseLog: "isUseApplicationLog",
    },
    {
      logType: "web",
      logTypeName: languageSet("웹로그_수집"),
      name: languageSet("웹"),
      cursor: "cursor_d",
      labelColor: "default",
      bgColor: "bg-blue",
      isMultiPath: true,
      isUse: false,
      isUseLog: "isUseWebLog",
    },
    {
      logType: "network",
      logTypeName: languageSet("네트워크로그_수집"),
      name: languageSet("네트워크"),
      cursor: "cursor_d",
      labelColor: "default",
      bgColor: "bg-purple3",
      isMultiPath: false,
      isUse: false,
      isUseLog: "isUseNetworkLog",
    },
  ];

  @observable
  isAgentActive: string = "";

  @observable
  modPluraVersion: string = "";

  @observable
  modPluraType: string = "";

  @observable
  isUseSysLog: string = "0";

  @observable
  isUseApplicationLog: string = "0";

  @observable
  isUseWebLog: string = "0";

  @observable
  isUseNetworkLog: string = "0";

  @observable
  isUseAgent: string = "";

  @observable
  serverId: string = "";

  @observable
  serverIp: string = "";

  @observable
  macAddress: string = "";

  @observable
  hostname: string = ""; //호스트이름

  @observable
  isLogUpload: string = ""; //에이전트 상태 (on/off)

  @observable
  serverGroupName: string = ""; //서버그룹명

  @observable
  agentVersion: string = ""; //에이전트 버전

  @observable
  serverOs: string = ""; //서버 OS

  @observable
  registerWay: string = ""; //등록방법

  @observable
  registerDate: string = ""; //등록일

  @observable
  agentActiveLogDate: string = ""; //에이전트 설치시간

  @observable
  osVersion: string = ""; //운영체제버전

  @observable
  isSaveFullLog: string = "1"; //전체로그

  @observable
  childList = null;

  @observable
  parentServerInfo = null;

  @observable
  logcollecterId: number | null = null;

  @observable
  webLogPath: string = "";

  @observable
  isSelected: boolean = false;

  @observable
  isWaf: string = "";

  @observable
  isWafBlockMode: string = "";

  @observable
  wafUploadMode: string = "";

  @observable
  agentType: string = "";

  @observable
  displayAgentExecuteState: string = "";

  @observable
  agentLogInfo = null;

  @observable
  serverName: string = "";

  @observable
  osReleaseName: string = "";

  @observable
  osLatestVersion: string = "";

  @observable
  osLatestInfo: string = "";

  @observable
  isResourceUpload: string = "";

  @observable
  serverGroupId: string = "";

  @observable
  osBulidNo: string = "";

  @observable
  osMajor: string = "";

  @observable
  osReleaseType: string = "";

  @observable
  sysLogPath: string = "";

  @observable
  modPluraStatus: string = "";

  @observable
  isAgentDel: string = "";

  @observable
  logUploadStatus: string = "";

  @observable
  osReleaseTypeName: string = "";

  @observable
  logTypeSummary: string = "";

  @observable
  serverLogConfigPathList = [{ configPath: "", isUse: "0", logType: "" }];

  @observable
  publicLogProxyModel: object | null = null;

  @observable
  serverLogConfigPathOnOffCountModel: {
    sysPathOnCount: number;
    sysPathOffCount: number;
    appPathOnCount: number;
    appPathOffCount: number;
    webPathOnCount: number;
    webPathOffCount: number;
    netPathOnCount: number;
    netPathOffCount: number;
  } = {
    sysPathOnCount: 0,
    sysPathOffCount: 0,
    appPathOnCount: 0,
    appPathOffCount: 0,
    webPathOnCount: 0,
    webPathOffCount: 0,
    netPathOnCount: 0,
    netPathOffCount: 0,
  };

  @observable
  sysPathOnCount: number = 0;

  @observable
  sysPathOffCount: number = 0;

  @observable
  appPathOnCount: number = 0;

  @observable
  appPathOffCount: number = 0;

  @observable
  webPathOnCount: number = 0;

  @observable
  webPathOffCount: number = 0;

  @observable
  netPathOnCount: number = 0;

  @observable
  netPathOffCount: number = 0;

  @observable
  registerType: string = "";

  @observable
  serverResourceConfigPathList: ResourceConfigPathModel[] = [];

  @observable
  resourceConfigList: Array<any> = [];

  @observable
  serverManagerName: string = "";

  @observable
  isLogForensicLicense = false;

  @observable
  isUseOceanLogOrder: string = "0"; //정렬코드

  @observable
  osFamily: string = "";

  @observable
  edrDetectMode: string | null = "agent";

  @observable
  edrBlockMode: string | null = "detect";

  @observable
  isEdrSysFullUpload: string | null = "";

  @observable
  isEdrSupport: string = "";

  @observable
  isShutdown: string = "0"; // 호스트 종료

  @observable
  isIsolation: string = "0"; // 네트워크 격리

  @observable
  serverStatusCheck: string = ""; // 호스트 종료, 네트워크 격리 가능 에이전트 여부
}
