import { observable, action, toJS } from "mobx";

import { ListCondition, withLoading, languageSet, Common_Utils } from "@qubit-utill/src";

import { systemDetectApis } from "@src/apis/pLogDetect";
import { complianceSettingApis } from "@src/apis/pFilter";
import { UISET } from "@src/constants";

import { DefenceStore, EdrDefenseStore } from "@src/common/stores";
import HostSecurityFilterDetectModel from "@src/pages/FilterDetection/HostSecurityFilterDetection/models/HostSecurityFilterDetectModel";

export default class HostSecurityFilterDetectStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }

  root;

  @observable
  tableList = [];

  @observable
  hostSecurityFilterDetectInfo = new HostSecurityFilterDetectModel(null, this.root.CodeStore);

  @observable
  ipDefenceType = "DEFENSE_IP_SYSTEM";

  @observable
  autoDefenseInfo = {};

  @observable
  manualDefenseInfo = {};

  @action
  clear = () => {
    this.tableList = [];
    this.hostSecurityFilterDetectInfo = new HostSecurityFilterDetectModel(null, this.root.CodeStore);
  };

  @withLoading("isLoading")
  @action
  getList = async () => {
    try {
      const tagServicePropertyList = this.tagServicePropertyList;
      const tagValueTypeList = this.tagValueTypeList;
      const getTagServiceDataList = this.openIpTagModal;

      this.params = {
        ...this.params,
        isEdrLog: true,
      };
      const { list, totalCount, nextCursorMark } = await systemDetectApis.getSysLogDetectList(this.params);

      this.tableList = list.map((info) => {
        const _info = { ...info, tagServicePropertyList, tagValueTypeList, getTagServiceDataList };
        return new HostSecurityFilterDetectModel(_info, this.root.CodeStore);
      });

      this.setCursorMarkInfo(nextCursorMark);
      this.totalCount = totalCount;
    } catch (e) {
      this.tableList = [];
      this.totalCount = 0;
    }
  };

  getComplianceEntityCountByFilterKeys = async (filterIds) => {
    try {
      const { totalCount } = await complianceSettingApis.getComplianceEntityCountByFilterKeys(filterIds);

      return totalCount;
    } catch (e) {
      return 0;
    }
  };

  @withLoading("isSubLoading")
  @action
  getInfo = async (documentId) => {
    try {
      const tagServicePropertyList = this.tagServicePropertyList;
      const tagValueTypeList = this.tagValueTypeList;
      const getTagServiceDataList = this.openIpTagModal;
      const getTagServiceDataListFn = this.getTagServiceDataList;

      const { info, viewTemplate, filterInfoList, programMessageTemplate } = await systemDetectApis.getSysLogDetect(documentId);

      const _info = { ...info, tagServicePropertyList, tagValueTypeList, getTagServiceDataList, getTagServiceDataListFn };

      this.hostSecurityFilterDetectInfo = await new HostSecurityFilterDetectModel(
        {
          ..._info,
          viewTemplate,
          filterInfoList,
          programMessageTemplate,
        },
        this.root.CodeStore
      );

      await this.getEdrDefense(documentId, this.hostSecurityFilterDetectInfo.serverId);

      const totalCount = await this.getComplianceEntityCountByFilterKeys(this.hostSecurityFilterDetectInfo.filterIds);
      this.hostSecurityFilterDetectInfo.setInfo("complianceEntityCount", totalCount);

      this.hostSecurityFilterDetectInfo.attackIp && (await DefenceStore.getDefenceInfo(this.hostSecurityFilterDetectInfo)); //get 방어정보
    } catch (err) {
      console.log(err);
      this.hostSecurityFilterDetectInfo = new HostSecurityFilterDetectModel(null, this.root.CodeStore);
    }
  };

  /**
   * 호스트보안 정보 가져오기
   *
   * @param documentId
   * @param serverId
   */
  @action
  getEdrDefense = async (documentId, serverId) => {
    const { autoDefenseInfo, manualDefenseInfo } = await EdrDefenseStore.getEdrDefense(documentId, serverId);

    this.autoDefenseInfo = await autoDefenseInfo;
    this.manualDefenseInfo = await manualDefenseInfo;
  };

  /**
   * 호스트보안 차단 실행
   *
   * @param documentId
   * @param serverId
   * @param edrFilterElementId
   * @param data
   * @returns
   */
  @action
  createEdrDefenseCmd = async (documentId, serverId, edrFilterElementId, data) => {
    try {
      await EdrDefenseStore.createEdrDefenseCmd(documentId, edrFilterElementId, data);
    } catch (error) {
      return error;
    }

    //정보 갱신
    await this.getEdrDefense(documentId, serverId);
  };

  @action
  downloadCSV = async (isUseBulk?, no?) => {
    try {
      if (this.tableList.length <= 0) {
        return languageSet("에러_데이터가없습니다");
      }

      this.params = {
        ...toJS(this.params),
        isEdrLog: true,
      };

      let csv = await systemDetectApis.getSysLogDetectCsvList({
        ...toJS(this.params),
        // serverIds: toJS(this.params.serverIds),
        // typeosList: toJS(this.params.typeosList),
        // serverGroupIds: toJS(this.params.serverGroupIds),
        // edrDetectTypes: toJS(this.params.edrDetectTypes),
        offset: 0,
        limit: no,
      });

      Common_Utils.csvDownload(
        csv,
        Common_Utils.setCsvFileName({
          title: UISET.FILTERDETECTHOSTSECURITY.title,
          category: languageSet("필터탐지"),
          ...this.params,
        })
      );
    } catch ({ data }) {
      console.log(data);
    }
  };
}
