import { Common_Utils, stringUtils, languageSet } from "@qubit-utill/src";

export default {
  /**
   * 웹/웹방화벽 로그: 유형
   * 
   * @param logBlocked 
   * @param webExtendsFilterTypes 
   * @param CodeStore 
   * @returns 
   */
  setLogBlockedInfo: (logBlocked: string, webExtendsFilterTypes: any, CodeStore: any) => {
    const logBlockedText: Array<string> = [];
    let logBlockedTextToRender: Array<string> = [];
    let logBlockedTextForCSV: string = "";
    const isLogBlocked: boolean = Common_Utils.isCheckBool(logBlocked);

    if (isLogBlocked && !Common_Utils.isEmpty(webExtendsFilterTypes)) {
      //차단 유형 세분화
      webExtendsFilterTypes.forEach((element: string) => {
        let _text = languageSet("차단");
        switch (element) {
          case "modsecurityfilter":
            _text = _text.concat("(", languageSet("OWASP"), ")");
            break;
          case "webfilter":
          case "waffilter":
            _text = _text.concat("(", languageSet("사용자정의"), ")");
            break;
          default:
            _text = _text.concat("(", languageSet("IP주소"), ")");
        }

        logBlockedText.push(_text);
      });
    } else if (Common_Utils.isEmpty(webExtendsFilterTypes)) {
      logBlockedText.push("-");
    } else {
      webExtendsFilterTypes.map((element: string) => {
        return logBlockedText.push(CodeStore.getCodeName("webExtendsFilterType", element));
      });
    }

    logBlockedText.forEach((element) => {
      const _separateText = stringUtils.separateText(element, "(");
      _separateText.forEach((_text: string) => logBlockedTextToRender.push(_text));
    });

    logBlockedTextToRender = [...new Set(logBlockedTextToRender)];
    logBlockedTextToRender.forEach((data, index, arr) => {
      logBlockedTextForCSV += `${data || "-"}${index + 1 !== arr.length ? " / " : ""}`;
    });

    return [logBlockedTextToRender, logBlockedTextForCSV];
  },
};
