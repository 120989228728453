import { languageSet } from "@qubit-utill/src";

interface MenuItemType {
  onClick: (e: MouseEvent, data: any) => void;
  data?: any;
  text: string;
}

export const CONTEXT = {
  TEXT: {
    TICKET: languageSet("티켓"),
    TOTALLOG: languageSet("전체로그"),
    TOTALLOG_EQUALS: `${languageSet("전체로그")} (=)`,
    TOTALLOG_1S: `${languageSet("전체로그")} (±1s)`,
    TOTALLOG_2S: `${languageSet("전체로그")} (±2s)`,
    TOTALLOG_3S: `${languageSet("전체로그")} (±3s)`,
    TOTALLOG_5S: `${languageSet("전체로그")} (±5s)`,
    TOTALLOG_10S: `${languageSet("전체로그")} (±10s)`,
    TOTALLOG_30S: `${languageSet("전체로그")} (±30s)`,
    TOTALLOG_60S: `${languageSet("전체로그")} (±60s)`,
    DETECTLOG: languageSet("탐지로그"),
    EXTRACTLOG: languageSet("추출로그"),
    LOGCLIENTIP: `${languageSet("전체로그")} (=) ${languageSet("공격자")}`,
    DATABREACHLOG: languageSet("데이터유출"),
  },
  CODE: {
    TOTALLOG: "totalLog",
    DETECTLOG: "detectLog",
    WEBEXTENDSDATABREACH: "webextendsdatabreach",
    DOS: "dos",
    PACKAGE: "package",
    TOTALLOG_WEB: "TOTALLOG_WEB",
    EXTORTACCOUNT: "SECURITYDETECT_EXTORTACCOUNT",
  },
};
