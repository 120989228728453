import AttackTypeStep3 from "./AttackTypeStep3";
import GeoIpFLag from "./GeoIpFLag";
import NationAndIp from "./NationAndIp";
import OnOff from "./OnOff";
import OsAndIp from "./OsAndIp";
import OsImage from "./OsImage";
import ServerOsCount from "./ServerOsCount";
import TotalCount from "./TotalCount";
import Hipen from "./Hipen";
import TD from "./TD";

export default {
  AttackTypeStep3,
  GeoIpFLag,
  NationAndIp,
  OnOff,
  OsAndIp,
  OsImage,
  ServerOsCount,
  TotalCount,
  Hipen,
  TD,
};
