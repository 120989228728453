import React from "react";
import { subDays } from "date-fns";
import { Input, useInjectStore, dateUtil, useInterval } from "@qubit-utill/src";

interface PropsTypes {
  termsTab: string;
  selectedDate: string;
  CustomDate?: React.ReactElement;
  limitDate?: number;
}

const ReportDatePicker: React.FC<PropsTypes> = ({ termsTab, selectedDate, CustomDate, limitDate = 61 }) => {
  const [currentDate, setCurrentDate] = React.useState(new Date());
  const { ReportStore } = useInjectStore();
  const { setInfo, getData } = ReportStore;

  useInterval(() => setCurrentDate(new Date()), 24 * 60 * 60 * 1000);

  const onChangeDate = (date: any) => {
    setInfo("dateInfo", dateUtil.getDateInfo(Array.isArray(date) ? date[0] : date));
    getData();
  };

  const DATEPICKER_OPTION = {
    size: "sm",
    termDays: limitDate,
    minDate: subDays(currentDate, limitDate),
    maxDate: currentDate,
  };

  if (CustomDate) {
    return CustomDate;
  } else if (termsTab === "week") {
    return <Input.WeekPicker {...DATEPICKER_OPTION} value={selectedDate} onChange={onChangeDate} />;
  } else if (termsTab === "month") {
    return <Input.MonthPicker {...DATEPICKER_OPTION} value={selectedDate} onChange={onChangeDate} />;
  } else {
    return <Input.DatePicker {...DATEPICKER_OPTION} value={selectedDate} onChange={onChangeDate} />;
  }
};

export default ReportDatePicker;
