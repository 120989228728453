import React from "react";
import { Route } from "react-router-dom";
import { observer } from "mobx-react";
import WithLicenseChecker from "../../../hoc/license/WithLicenseChecker";

const LicenseCheckRoute = ({
  isRedirect,
  goToRediret,
  exact,
  path,
  component: Component,
  ...rest
}) => {
  return (
    <Route
      exact={exact}
      path={path}
      render={routeProps => {
        if (isRedirect) {
          goToRediret();
          return null;
        }
        return <Component {...rest} {...routeProps} />;
      }}
    />
  );
};

LicenseCheckRoute.propTypes = {};

export default WithLicenseChecker()(observer(LicenseCheckRoute));
