import { axiosModules } from "@qubit-utill/src";

export default {
  //------------------------------------------------------------------
  //차트 : 일별
  getExtortAccountDayMainChart: (params) => {
    return axiosModules.get("/pstats/dosdetect/day/mainchart", params);
  },
  //서브 차트 : 일별
  getExtortAccountDaySubChart: (params) => {
    return axiosModules.get("/pstats/dosdetect/day/subchart", params);
  },
  //리스트 : 일별
  getExtortAccountDayMainDetect: (params) => {
    return axiosModules.get("/pstats/dosdetect/day/detect", params);
  },
  //------------------------------------------------------------------
  //차트 : 주별
  getExtortAccountWeekMainChart: (params) => {
    return axiosModules.get("/pstats/dosdetect/week/mainchart", params);
  },
  //서브 차트 : 주별
  getExtortAccountWeekSubChart: (params) => {
    return axiosModules.get("/pstats/dosdetect/week/subchart", params);
  },
  //리스트 : 주별
  getExtortAccountWeekDetect: (params) => {
    return axiosModules.get("/pstats/dosdetect/week/detect", params);
  },
  //------------------------------------------------------------------
  //차트 월별
  getExtortAccountMonthMainChart: (params) => {
    return axiosModules.get("/pstats/dosdetect/month/mainchart", params);
  },
  //리스트 월별
  getExtortAccountMonthMainDetect: (params) => {
    return axiosModules.get("/pstats/dosdetect/month/detect", params);
  },
  //------------------------------------------------------------------
  //박스플롯 minDate
  getPlotChartMinDate: () => axiosModules.get("/pstats/dosdetect/plotchart/mindate"),
  //카테고리 그룹 리스트 (카운트 x)
  getFilterCategoryGroupList: () => axiosModules.get("/pstats/dosdetect/categorygroup"),
  //박스플롯 Data
  getPlotChart: (params) => axiosModules.get(`/pstats/dosdetect/plotchart`, params),
};
