import React from "react";

import { Common_Utils } from "@qubit-utill/src";

interface PropTypes {
  value: any;
  className?: string;
  type?: string;
}

const TD: React.FC<PropTypes> = ({ value, className, type = "div", children }) => {
  const validCheck = !Common_Utils.isEmpty(value);
  return (
    <td>
      {(validCheck && children) ||
        React.createElement(
          type,
          { className },
          Common_Utils.isDataWithDashBar(validCheck && value)
        )}
    </td>
  );
};

// 단순한 TD 를 생성하는 Atom Component
//  <td>
//    <div className={className}>{value}</div>
//  </td>
// 의 형태를 html 태그를 적용시키기 위해 createElement 로 호출하여 적용함
// type에 따라 html 태그 값이 결정된다

export default TD;
