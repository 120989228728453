import { observable, computed } from "mobx";

import { SetterModel, Common_Utils } from "@qubit-utill/src";

import { GroupSquidPropertyModel } from "@src/pages/Group/SQUID/models";

export default class GroupSquidSectionModel extends SetterModel {
  constructor(param?, isUseDelBtn?) {
    super(param);

    if (isUseDelBtn) {
      this.isUseDelBtn = isUseDelBtn;
    }
  }

  @computed
  get isSquidUseFlag() {
    return Common_Utils.isCheckBool(this.isUse);
  }

  @computed
  get isSingleSectionFlag() {
    return Common_Utils.isCheckBool(this.isSingleSection);
  }

  @computed
  get isSectionLabelPropertyFlag() {
    return Common_Utils.isCheckBool(this.isSectionLabelProperty);
  }

  @computed
  get isUseSchedulerFlag() {
    return Common_Utils.isCheckBool(this.isUseScheduler);
  }

  @computed
  get isSchedulerFlag() {
    return Common_Utils.isCheckBool(this.middlewareUserSectionFile?.isScheduler);
  }

  @computed
  get isStaticPropertyFlag() {
    return Common_Utils.isCheckBool(this.isStaticProperty);
  }

  @computed
  get isStaticUsableFlag() {
    return Common_Utils.isCheckBool(this.isStaticUsable);
  }

  @observable
  isUseDelBtn: boolean = false;

  @observable
  middlewareUserSectionId: number = 0;

  @observable
  middlewareSectionId: number = 0;

  @observable
  isUse: string = "0";

  @observable
  registerUuid: string = "";

  @observable
  updateUuid: string = "";

  @observable
  middlewareUserConfigId: number = 0;

  @observable
  middlewareSectionName: string = "";

  @observable
  middlewareSectionLabel: string | null = "" || null;

  @observable
  isSingleSection: string = "0";

  @observable
  isSectionLabelProperty: string = "0";

  @observable
  middlewareSectionType: string | null = "" || null;

  @observable
  isUseScheduler: string = "0";

  @observable
  isStaticProperty: string = "0";

  @observable
  isStaticUsable: string = "0";

  @observable
  middlewareUserPropertyList: Array<GroupSquidPropertyModel> = [];

  @observable
  middlewareUserSectionFile: {
    middlewareUserSectionId: number;
    fileUrl: string | null;
    fileData: string | null;
    isScheduler: string;
  } | null =
    {
      middlewareUserSectionId: 0,
      fileUrl: "" || null,
      fileData: "" || null,
      isScheduler: "0",
    } || null;
}
