import { observable, action, computed } from "mobx";

import { withLoading, ListCondition, arrayUtils } from "@qubit-utill/src";

import { tagApis } from "@src/apis/pFilter";

import { IpMacAddressModel } from "@src/pages/Group/RefItem/models";

class IpMacAddressStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }
  root;

  @observable
  tagServiceId: string = "";

  @observable
  tagServiceName: string = "";

  @observable
  tableList: Array<IpMacAddressModel> = [];

  @observable
  multiRegistParams: object = {};

  @observable
  successListCount: number = 0;

  @observable
  failListCount: number = 0;

  @observable
  checkFailList: Array<object> = [];

  @observable
  info: IpMacAddressModel = new IpMacAddressModel();

  @observable
  selectedTab: string = "";

  @observable
  defaultTagValueType: string = "";

  @observable
  isSubLoading: boolean = true;

  @observable
  isTotalCheck: boolean = false;

  @computed
  get getResultTotalCount() {
    return this.successListCount + this.failListCount;
  }

  @computed
  get checkList() {
    return this.tableList.filter(({ isGlobalFlag, isSelected }) => !isGlobalFlag && isSelected);
  }

  @action
  setSelectedTab = (tab) => {
    return (this.selectedTab = tab);
  };

  @withLoading("isLoading")
  @action
  getList = async () => {
    try {
      const { list, totalCount } = await tagApis.getTagServiceDataList(this.tagServiceId, this.params);
      this.tableList = list.map((ipInfo) => new IpMacAddressModel(ipInfo, this.tagReferenceTypeList));
      this.totalCount = totalCount;
    } catch ({ data }) {
      this.tableList = [];
      this.totalCount = 0;
      return data;
    }
  };

  @action
  getInfo = async (tagServiceDataId) => {
    try {
      const { info } = await tagApis.getTagServiceDataInfo(tagServiceDataId);
      this.info = new IpMacAddressModel(info);
    } catch ({ data }) {
      this.info = new IpMacAddressModel();
      return data;
    }
  };

  // 태그 서비스 목록
  @action
  getTagServiceList = async () => {
    try {
      const tabInfo = this.selectedTab === "RefItemIpAddress" ? "ip" : "mac";
      const { list } = await tagApis.getTagServiceList();
      const tagServiceInfo = list.find((tag) => tag.tagServiceId === tabInfo);
      this.tagServiceId = tagServiceInfo.tagServiceId;
      this.tagServiceName = tagServiceInfo.tagServiceName;
    } catch ({ data }) {
      return data;
    }
  };

  // 태그 참조 유형 목록
  @action
  getTagReferenceTypeList = async () => {
    try {
      const { list } = await tagApis.getTagReferenceTypeList(this.tagServiceId);

      const _tagReferenceTypeList = list.filter(({ referenceRegisterType }) => referenceRegisterType !== "manager");
      this.tagReferenceTypeList = arrayUtils
        .sortByKey(_tagReferenceTypeList, "tagReferenceTypeOrderNo")
        .map((info) => ({ name: info.tagReferenceTypeName, value: info.tagReferenceType, isDefault: info.isDefault === "1" }));
    } catch ({ data }) {
      return data;
    }
  };

  // 태그 주소 유형 목록
  @action
  getTagValueTypeList = async () => {
    try {
      const { list } = await tagApis.getTagValueTypeList(this.tagServiceId);
      this.tagValueTypeList = arrayUtils.sortByKey(list, "tagValueTypeOrderNo").map((info) => {
        if (info.isDefault === "1") {
          this.defaultTagValueType = info.tagValueType;
        }
        return {
          name: info.tagValueTypeName,
          value: info.tagValueType,
          isDefault: info.isDefault === "1",
          token: info.tagValueTypeToken,
          size: info.tagValueTypeTokenSize,
        };
      });
    } catch ({ data }) {
      this.tagValueTypeList = [];
      return data;
    }
  };

  // 태그 데이터 등록 ( 단일 )
  @action
  createSingleTagServiceData = async (params) => {
    try {
      await tagApis.createSingleTagServiceData(this.tagServiceId, params);
      this.getList();
    } catch ({ data }) {
      return data;
    }
  };

  // 태그 데이터 다중 등록 체크
  @action
  checkCreateTagServiceData = async (params) => {
    try {
      const { tagReferenceType, tagValueType, successList, failList } = await tagApis.checkCreateTagServiceData(
        this.tagServiceId,
        params
      );

      this.multiRegistParams = {
        tagReferenceType,
        tagValueType,
        tagList: successList,
      };

      this.successListCount = successList ? successList.length : 0;
      this.failListCount = failList ? failList.length : 0;
      this.checkFailList = failList;
      this.setIsSubLoading(true);
    } catch ({ data }) {}
  };

  // 태그 데이터 다중 등록
  @action
  createMultiTagServiceData = async () => {
    try {
      await tagApis.createMultiTagServiceData(this.tagServiceId, this.multiRegistParams);
      this.setIsSubLoading(true);
      this.getList();
    } catch ({ data }) {
      this.setIsSubLoading(true);
      return data;
    }
  };

  // 사용여부 수정
  @action
  updateIsUseTagServiceData = async (tagServiceDataId, isUse) => {
    try {
      await tagApis.updateIsUseTagServiceData(tagServiceDataId, { isUse: isUse === "1" ? "0" : "1" });
    } catch ({ data }) {
      return data;
    }
  };

  // 태그 정보 수정
  @action
  updateTagServiceData = async (tagServiceDataId, params) => {
    try {
      await tagApis.updateTagServiceData(tagServiceDataId, params);
      this.getList();
    } catch ({ data }) {
      return data;
    }
  };

  // 태그 데이터 다중 삭제
  @action
  deleteTagServiceData = async (tagServiceDataId?) => {
    let tagServiceDataIds;

    if (tagServiceDataId) {
      tagServiceDataIds = tagServiceDataId;
    } else {
      tagServiceDataIds = this.tableList.filter(({ isSelected }) => isSelected).map(({ tagServiceDataId }) => tagServiceDataId);
    }

    try {
      await tagApis.deleteTagServiceData({ tagServiceDataIds, isTagServiceDataIdsAll: "0" });
      this.getList();
    } catch ({ data }) {
      return data;
    }
  };

  // 태그 데이터 다중 삭제 (조건) : pSearch의 조건에 맞는 전체삭제
  @action
  deleteTagServiceDataAll = async () => {
    const _param = { isUse: this.params.isUse, tagReferenceType: this.params.tagReferenceType, isTagServiceDataIdsAll: "1" };
    try {
      await tagApis.deleteTagServiceDataAll(this.tagServiceId, _param);
      this.getList();
    } catch ({ data }) {
      return data;
    }
  };

  @action
  allCheck = (checked) => {
    this.tableList.forEach((data) => {
      data._setInfo("isSelected")(checked);
    });
  };

  @action
  setIsSubLoading = (flag: boolean) => {
    this.isSubLoading = flag;
  };

  @action
  setIsTotalCheck = (flag) => {
    return (this.isTotalCheck = flag);
  };
}

export default IpMacAddressStore;
