import axios from "axios";
import qs from "qs";
import { toJS } from "mobx";

import {
  getURI,
  path,
  ISUSECSRF,
  CSRFALLOWSERVERNAMES,
  CSRFALLOWMETHODS,
  APICOOKIENAME,
} from "../common_constants";

import LoadingStore from "../store/LoadingStore";
import ModalStore from "../store/ModalStore";
import languageSet from "../lang/languageSet";
import { objectUtil, Common_Utils } from "../utils";

const API_ROOT = path.APIURL;

const axiosModules = {
  paramsSerializer: params => qs.stringify(params, { arrayFormat: "repeat" }),

  get(url, params, cancelToken) {
    const validParams = objectUtil.deleteNullPropety(toJS(params));
    return this.axiosSetting(url, {
      method: "get",
      params: validParams,
      paramsSerializer: this.paramsSerializer,
      cancelToken,
    });
  },

  post(url, data, params) {
    const validParams = objectUtil.deleteNullPropety(toJS(params));
    return this.axiosSetting(url, {
      method: "post",
      data,
      params: validParams,
      paramsSerializer: this.paramsSerializer,
    });
  },

  del(url, params) {
    return this.axiosSetting(url, {
      method: "delete",
      params: params,
      paramsSerializer: this.paramsSerializer,
    });
  },

  patch(url, data, params) {
    const validParams = objectUtil.deleteNullPropety(toJS(params));
    return this.axiosSetting(url, {
      method: "patch",
      data,
      params: validParams,
      paramsSerializer: this.paramsSerializer,
    });
  },

  put(url, data, params) {
    const validParams = objectUtil.deleteNullPropety(toJS(params));
    return this.axiosSetting(url, {
      method: "put",
      data,
      params: validParams,
      paramsSerializer: this.paramsSerializer,
    });
  },

  getCsv(url, params, cancelToken) {
    const validParams = objectUtil.deleteNullPropety(toJS(params));
    return this.axiosSetting(
      url,
      {
        method: "get",
        params: validParams,
        paramsSerializer: this.paramsSerializer,
        cancelToken,
      },
      false
    );
  },

  getBlob(url, params) {
    const validParams = objectUtil.deleteNullPropety(toJS(params));
    return this.axiosSetting(
      url,
      {
        method: "get",
        params: validParams,
        paramsSerializer: this.paramsSerializer,
        responseType: "blob",
      },
      false
    );
  },

  checkDomain() {
    const cookie = Common_Utils.checkCookie(APICOOKIENAME);
    return Boolean(cookie) && cookie[2]
      ? `${process.env.NODE_ENV !== "production" ? "http" : "https"}://${cookie[2]}.${path.PLURAIO}`
      : API_ROOT;
  },

  async axiosSetting(url, axiosOptions, isCheckValid) {
    // axiosOptions.url = `${this.checkDomain()}${url}`;
    axiosOptions.url = `${API_ROOT}${url}`;
    axiosOptions.headers = {
      "X-Frame-Options": "deny",
      "X-Content-Type-Options": "nosniff",
    };

    //이부분에서 csrfTokenKey를 넣어주자
    if (ISUSECSRF === "true") {
      const serverName = url.split("/")[1];
      const isMatchMethod = CSRFALLOWMETHODS.toUpperCase().indexOf(
        axiosOptions.method.toUpperCase()
      );
      const isMatchServer = CSRFALLOWSERVERNAMES.toUpperCase().indexOf(serverName.toUpperCase());
      if (isMatchMethod >= 0 && isMatchServer >= 0) {
        const csrfUrl = `${this.checkDomain()}/${serverName}/csrftoken`;
        try {
          const { csrfTokenKey } = await this.getCsrfTokenKeyAPI(csrfUrl);
          axiosOptions.data = axiosOptions.data || {};
          axiosOptions.data.csrfTokenKey = csrfTokenKey;
          return this.callAxios(axiosOptions, isCheckValid);
        } catch (e) {
          console.log("getCsrfTokenKeyAPI", e);
          return this.callAxios(axiosOptions, isCheckValid);
        }
      }
    }
    return this.callAxios(axiosOptions, isCheckValid);
  },

  //csrfTokenKey 발급
  getCsrfTokenKeyAPI(csrfUrl) {
    const axiosOptions = {
      url: csrfUrl,
      method: "get",
    };

    return this.callAxios(axiosOptions);
  },

  checkReturnValid: ({
    returnCode,
    dataNotFoundCode,
    dataNotFoundCodeMessage,
    returnCodeMessage,
  }) => {
    if (returnCode === "0" && dataNotFoundCode === "0") {
      return true;
    } else if (returnCode.substring(0, 2) === "99") {
      ModalStore.openAlertModal({
        message: returnCodeMessage || languageSet("에러_다시로그인해주세요"),
        okCallBack: () => (window.location.href = getURI("LOGIN")),
        closeCallBack: () => (window.location.href = getURI("LOGIN")),
      });
      LoadingStore.completed();
    } else if (returnCode === "97010") {
      ModalStore.openAlertModal({
        message: returnCodeMessage,
      });
      LoadingStore.completed();
    } else if (returnCode === "97011") {
      ModalStore.openAlertModal({
        message: returnCodeMessage,
      });
      LoadingStore.completed();
    } else if (returnCode.substring(0, 2) === "97") {
      ModalStore.openAlertModal({
        message: returnCodeMessage,
        okCallBack: () => (window.location.href = path.SIEMURL),
        closeCallBack: () => (window.location.href = path.SIEMURL),
      });
      LoadingStore.completed();
    } else {
      return false;
    }
  },

  handleAPIError: response => {
    if (response instanceof Error) {
      ModalStore.openAlertModal({
        message: languageSet("에러_네트워크"),
      });
      LoadingStore.completed();
    }
  },

  callAxios(options, isCheckValid = true) {
    const _this = this;
    options.withCredentials = true;
    options.headers = { ...options.headers, "Cache-Control": "no-store" };
    return new Promise(function(resolve, reject) {
      axios(options)
        .then(response => {
          if (response.status === 403 || response.status === 406) {
            ModalStore.openAlertModal({
              title: languageSet("경고"),
              message: languageSet("메세지_해킹의심경고"),
            });
            reject(response);
            return;
          }

          if (isCheckValid) {
            if (!_this.checkReturnValid(response.data)) {
              reject(response);
              return;
            }
          } else if (!response.data) {
            reject(response);
            return;
          }
          resolve(response.data);
        })
        .catch(error => {
          _this.handleAPIError(error);
          reject(error);
          setTimeout(() => {
            ModalStore.modals = {};
          }, 30000);
        });
    });
  },
};

export default axiosModules;
