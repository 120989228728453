import { observable, action } from "mobx";

import { Common_Utils, arrayForSelectBox, withLoading, ListCondition } from "@qubit-utill/src";

import { packageFilterApis, filterCategoryApis } from "@src/apis/pFilter";

import CorrelationFilterModel from "@src/pages/Filter/SecurityFilter/models/Correlation/CorrelationFilterModel";

class CorrelationFilterStore extends ListCondition {
  @observable
  isLoading = true;

  @observable
  tableList = [];

  @observable
  info: CorrelationFilterModel = new CorrelationFilterModel();

  @observable
  filterClassificationListForSelect = [];

  @observable
  isCopy = false;

  @action
  setIsCopy = (flag) => {
    this.isCopy = flag;
  };

  @action
  @withLoading("isLoading")
  getList = async () => {
    try {
      const { list, totalCount } = await packageFilterApis.getUserPackageFilterList(this.params);
      this.tableList = Common_Utils.constructClassList(list, CorrelationFilterModel);
      this.totalCount = totalCount;
    } catch ({ data }) {
      this.tableList = [];
      this.totalCount = 0;
    }
  };

  @action
  getInfo = async (packageFilterId) => {
    const { info } = await packageFilterApis.getUserPackageFilterInfo(packageFilterId);
    this.info = new CorrelationFilterModel(info);
  };

  @action
  getFilterCategoryListForSelect = async () => {
    const { list } = await filterCategoryApis.getFilterCategoryList("package");
    this.filterClassificationListForSelect = arrayForSelectBox.parseValueObjectForSelectBox(list, "filterCategoryName");
  };

  @action
  initInfo = () => {
    if (!this.isCopy) {
      this.info = new CorrelationFilterModel();
    } else {
      this.info.packageFilterName = "";
      this.info.packageFilterDescription = "";
      this.setIsCopy(false);
    }

    this.getFilterCategoryListForSelect();
  };

  @withLoading("isLoading")
  @action
  getTotalInfo = async (packageFilterId) => {
    try {
      await Promise.all([this.getInfo(packageFilterId), this.getFilterCategoryListForSelect()]);
    } catch (error) {
      console.log(error);
    }
  };

  @action
  clear = () => {
    if (!this.isCopy) {
      this.info = new CorrelationFilterModel();
    }
  };

  @action
  deletePackageFilterInfo = async () => {
    const { packageFilterId } = this.info;
    try {
      await packageFilterApis.deleteUserPackageFilter(packageFilterId);
    } catch ({ data }) {
      return data;
    }
  };

  @action
  setUserPackageIsUse = async (info) => {
    try {
      const willIsUse = !info.isUseFlag ? "1" : "0";
      const { rowCount } = await packageFilterApis.setUserPackageIsUse(info.packageFilterId, {
        isUse: willIsUse,
      });
      if (rowCount >= 1) info.isUse = willIsUse;
    } catch ({ data }) {
      return data;
    }
  };

  @action
  createPackageFilterInfo = async () => {
    const {
      packageFilterName,
      filterCategoryId,
      isUse,
      isSequenceCheck,
      packageFilterTimeRange,
      packageFilterDescription,
      packageFilterGroupList,
      packageFilterCheckPointRatio,
    } = this.info;
    try {
      await packageFilterApis.createUserPackageFilter({
        packageFilterName,
        packageFilterTimeRange,
        isUse,
        isSequenceCheck,
        isCustomPackage: 1,
        nonSequenceCheckPointRatio: 0,
        packageFilterDescription,
        filterCategoryId,
        packageFilterGroupList,
        packageFilterCheckPointRatio,
      });
    } catch ({ data }) {
      return data;
    }
  };

  @action
  updatePackageFilterInfo = async () => {
    const {
      packageFilterId,
      packageFilterName,
      filterCategoryId,
      isUse,
      isSequenceCheck,
      packageFilterTimeRange,
      packageFilterDescription,
      packageFilterGroupList,
      packageFilterCheckPointRatio,
    } = this.info;

    try {
      await packageFilterApis.updateUserPackageFilter(packageFilterId, {
        packageFilterId,
        packageFilterName,
        packageFilterTimeRange,
        isUse,
        isSequenceCheck,
        nonSequenceCheckPointRatio: 0,
        packageFilterDescription,
        filterCategoryId,
        packageFilterGroupList,
        packageFilterCheckPointRatio,
      });
    } catch ({ data }) {
      return data;
    }
  };
}

export default CorrelationFilterStore;
