import React, { useState } from "react";
import Chart from "react-apexcharts";
import { observer } from "mobx-react";
import { toJS } from "mobx";

import { Common_Utils, languageSet } from "qubit-utill/src";

import UISET from "@src/constants/uiset";
import NullLogData from "@src/common/components/organisms/Graphs/NullLogData";

interface GraphOptionPropsTypes {
  chart?: {
    animations?: {
      enabled?: boolean;
    };
  };
  tickAmount?: number;
  stroke?: object;
  markers?: object;
  dataLabels?: { enabled: boolean; style: object; enabledOnSeries: any; offsetY: number; background: object };
  plotOptions?: {
    bar?: {
      columnWidth?: string;
      dataLabels?: {
        positions?: string;
        total?: {
          enabled?: boolean;
          formatter?: () => string;
          style?: {};
        };
      };
    };
  };
}

interface PropsTypes {
  id: string;
  title?: string;
  data: { type?: string; name: string; data: number[] }[];
  category: string[];
  selectedIndex?: number;
  onClick?: Function;
  valueFormater?: Function;
  graphOption?: GraphOptionPropsTypes;
  height?: number;
  nullNotice?: string;
  useOnClick?: boolean;
}

const BarLineMixed: React.FC<PropsTypes> = ({
  id,
  title,
  data,
  category,
  selectedIndex = -1,
  onClick,
  valueFormater,
  graphOption,
  height = 250,
  nullNotice,
  useOnClick,
}) => {
  const [chartIndex, setChartIndex] = useState<number>(selectedIndex); //현재 x축 index
  const [rotateAlways, setRotateAlways] = useState(false);

  const sumMaxColor = "#ff0000";
  const defaultColor = "#000000";

  //onClick event 있을 경우만 category 색상 변경.
  let categoriesColors: string[] = [];
  if (onClick && category && category.length > 0) {
    categoriesColors = new Array<string>(category.length).fill("");
    categoriesColors[chartIndex] = sumMaxColor;
  }

  const series = data && data.length > 0 ? toJS(data) : [];
  const strokeWidth = series.map(({ type }) => (type === "line" ? 2 : 0));
  //line series의 index 구하기
  const lineIndexes: number[] = [];
  series.map((data, index) => {
    if (data.type === "line") {
      lineIndexes.push(index);
    }
  });

  const _valueFormatter = (value) =>
    Common_Utils.isNull(value) ? (valueFormater ? valueFormater(value) : Common_Utils.numberWithComma(value)?.toString()) : "";

  const dataPointOnClick = (config) => {
    if (useOnClick) {
      const dataPointIndex: number = config.dataPointIndex; //선택한 x축 data index
      const seriesIndex: number = config.seriesIndex;

      const seriesName = config.w.globals.seriesNames[seriesIndex];
      const _xAxisData = config.w.globals.labels[dataPointIndex];
      const hourTextIndex = _xAxisData.lastIndexOf(languageSet("n시", ""));

      let xAxisData = "";
      let seriesDate = 0;

      [...seriesName].forEach((str) => {
        const strToNum = Number(str);
        const isInteger = Number.isInteger(strToNum);
        if (str !== " " && isInteger) seriesDate = strToNum;
      });

      if (hourTextIndex > -1) {
        xAxisData = _xAxisData.substring(0, hourTextIndex);
      } else {
        xAxisData = _xAxisData;
      }

      onClick && onClick(xAxisData, seriesName, seriesDate, dataPointIndex);
      onClick && setChartIndex(dataPointIndex);
    }
  };

  const isMaxValue = (data, value) => {
    // const sumMaxValue = Math.max(...data.flat());
    //line은 max에서 제외
    const sumMaxValue = Math.max(...data.filter((item, index) => !lineIndexes.includes(index)).flat());

    return value > 0 && value === sumMaxValue;
  };

  const options = {
    title: title ? { text: title, align: "center" as const } : {},
    chart: {
      id: id,
      type: "line" as const,
      stacked: false,
      toolbar: {
        show: false,
      },
      redrawOnParentResize: true,
      events: {
        dataPointSelection: function(event, chartContext, config) {
          dataPointOnClick(config);
        },
        dataPointMouseEnter: function(event) {
          if (useOnClick) {
            event.target.style.cursor = "pointer";
          }
        },
        beforeMount: function(chartContext, config) {
          config.config.xaxis.categories.forEach((categoryItem) => categoryItem.length > 8 && setRotateAlways(true));
        },
      },
      animations: {
        enabled: graphOption?.chart?.animations?.enabled || false,
      },
      zoom: {
        enabled: false,
      },
    },
    xaxis: {
      categories: category && category.length > 0 ? toJS(category) : [],
      labels: {
        maxHeight: 80,
        rotateAlways: rotateAlways,
        style: {
          colors: categoriesColors,
        },
        formatter: function(value) {
          if (value.length > 8) {
            return value.substring(0, 8) + "...";
          } else return value;
        },
      },
      tooltip: {
        enabled: false,
      },
      tickPlacement: "between",
    },
    yaxis: {
      min: 0,
      tickAmount: graphOption?.tickAmount || undefined,
      stepSize: 1,
      forceNiceScale: true,
      labels: {
        formatter: function(value) {
          return _valueFormatter(value) as string;
        },
      },
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      x: {
        formatter: function(value, opts) {
          const nullValue = opts.series.every((seriesItem) => {
            const data = seriesItem[opts.dataPointIndex];
            return !Common_Utils.isNull(data);
          });
          const label = category[opts.dataPointIndex];
          return nullValue ? null : (label as any);
        },
      },
      y: {
        formatter: function(value, opts) {
          return value === null ? null : _valueFormatter(value);
        },
      },
    },
    stroke: graphOption?.stroke || {
      width: strokeWidth,
      curve: "smooth" as const,
    },
    markers: graphOption?.markers || {
      size: 3,
      strokeWidth: 0,
      hover: {
        sizeOffset: 3,
      },
    },
    dataLabels: {
      enabled: graphOption?.dataLabels?.enabled ?? false,
      enabledOnSeries: graphOption?.dataLabels?.enabledOnSeries || null,
      offsetY: graphOption?.dataLabels?.offsetY || 0,
      background: graphOption?.dataLabels?.background || {},
      style: graphOption?.dataLabels?.style || {
        colors: [
          function(opts) {
            const { w, dataPointIndex, seriesIndex } = opts;
            //line일 경우 skip
            if (lineIndexes.includes(seriesIndex)) return defaultColor;

            const series = w.globals.series;
            const value = series[seriesIndex][dataPointIndex];
            const isMax = isMaxValue(series, value);

            return isMax ? sumMaxColor : defaultColor;
          },
        ],
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      position: "bottom" as const,
      horizontalAlign: "center" as const,
      offsetY: 8,
    },
    colors: [
      function(opts) {
        const { w, seriesIndex, value } = opts;
        //line일 경우 skip
        if (lineIndexes.includes(seriesIndex)) return UISET.CHART_COLOR[seriesIndex];

        const series = w.globals.series;
        const isMax = isMaxValue(series, value);

        return isMax ? sumMaxColor : UISET.CHART_COLOR[seriesIndex];
      },
    ],
    plotOptions: {
      bar: {
        columnWidth: graphOption?.plotOptions?.bar?.columnWidth || "60%",
        dataLabels: {
          position: graphOption?.plotOptions?.bar?.dataLabels?.positions || "top",
          total: {
            enabled: graphOption?.plotOptions?.bar?.dataLabels?.total?.enabled || false,
            formatter:
              graphOption?.plotOptions?.bar?.dataLabels?.total?.formatter ||
              function(value, { dataPointIndex }) {
                const nullValue = data.every(({ data }) => data[dataPointIndex] === null);
                return nullValue ? "-" : (_valueFormatter(value) as string);
              },
            style: graphOption?.plotOptions?.bar?.dataLabels?.total?.style || {},
          },
        },
      },
    },
  };

  return data && data.length > 0 ? (
    <Chart options={options} series={series} height={`${height}px`} />
  ) : (
    <NullLogData height={265} nullNotice={nullNotice} />
  );
};

export default observer(BarLineMixed);
