import { observable, computed } from "mobx";

import { Common_Utils, languageSet, SetterModel } from "@qubit-utill/src";

class WebFirewallFilterModel extends SetterModel {
  constructor(params, CodeStore) {
    super(params);
    this.CodeStore = CodeStore;
    this.responseBodySizeCode = params ? params.responseBodySizeCode : CodeStore.getDefaultCode("responseBodySizeCode");
    this.keywordSizeCode = params ? params.keywordSizeCode : CodeStore.getDefaultCode("keywordSizeCode");
  }
  @observable
  CodeStore;

  @computed
  get isLockFlag() {
    return Common_Utils.isCheckBool(this.isLock);
  }

  @computed
  get riskName() {
    return this.CodeStore.getCodeName("filterRiskLevel", this.webExtendsFilterRiskCode);
  }

  @computed
  get isUseFlag() {
    return Common_Utils.isCheckBool(this.isUse);
  }

  @computed
  get isBlockFlag() {
    return Common_Utils.isCheckBool(this.isBlock);
  }

  @computed
  get isBlockText() {
    return Common_Utils.isCheckBool(this.isBlock) ? languageSet("차단") : languageSet("탐지");
  }

  validation = () => {
    if (!this.webExtendsFilterAttackType) {
      throw languageSet("메세지_분류를_선택하세요");
    }

    if (!this.serverGroupId) {
      throw languageSet("메세지_시스템그룹을_선택");
    }

    if (!this.webExtendsFilterName) {
      throw languageSet("메세지_필터명입력필요");
    }

    if (!this.webExtendsFilterRiskCode) {
      throw languageSet("메세지_위험도별설정체크");
    }
  };

  isPossibleAddDataType: boolean = true;

  @observable
  webExtendsFilterId: string = "";

  @observable
  webExtendsFilterIds = null;

  @observable
  webExtendsFilterName: string = "";

  @observable
  webExtendsFilterAttackType: string = "";

  @observable
  webExtendsFilterRiskCode: string = "";

  @observable
  webExtendsFilterType = "waffilter";

  @observable
  webExtendsFilterDescription: string = "";

  @observable
  webFilterOwaspCode;

  @observable
  isGlobalFilter: string = "0";

  @observable
  isCustomFilter: string = "0";

  @observable
  isUse: string = "0";

  @observable
  isLock: string = "0";

  @observable
  isBlock: string = "0";

  @observable
  isAgentFilter: string = "1";

  @observable
  keywordSizeCode: string = "";

  @observable
  responseBodyStartPosition: number = 0;

  @observable
  responseBodySizeCode: string = "";

  @observable
  registerDate: string = "";

  @observable
  updateDate: string = "";

  @observable
  registerGuid: string = "";

  @observable
  registerUuid: string = "";

  @observable
  updateUuid: string = "";

  @observable
  filterCategoryId: number = 0;

  @observable
  serverGroupId = null;

  @observable
  filterCategoryName: string = "";

  @observable
  isDel: string = "0";

  @observable
  keywordSize = null;

  @observable
  responseBodySize = null;

  @observable
  registerUserName: string = "";

  @observable
  updateUserName: string = "";

  @observable
  registerGuidName: string = "";

  @observable
  isSelected: boolean = false;

  @observable
  filterElementJson = [];

  @observable
  serverGroup: string = "";

  @computed
  get apiData() {
    const {
      webExtendsFilterId,
      webExtendsFilterIds,
      webExtendsFilterName,
      webExtendsFilterAttackType,
      webExtendsFilterRiskCode,
      webExtendsFilterType,
      webExtendsFilterDescription,
      webFilterOwaspCode,
      isGlobalFilter,
      isCustomFilter,
      isUse,
      isLock,
      isBlock,
      isAgentFilter,
      keywordSizeCode,
      responseBodyStartPosition,
      responseBodySizeCode,
      filterCategoryId,
      serverGroupId,
      serverGroup,
    } = this;
    let obj: any = {
      webExtendsFilterId,
      webExtendsFilterIds,
      webExtendsFilterName,
      webExtendsFilterAttackType,
      webExtendsFilterRiskCode,
      webExtendsFilterType,
      webExtendsFilterDescription,
      webFilterOwaspCode,
      isGlobalFilter,
      isCustomFilter,
      isUse,
      isLock,
      isBlock,
      isAgentFilter,
      keywordSizeCode,
      responseBodyStartPosition,
      responseBodySizeCode,
      filterCategoryId,
      serverGroupId,
      serverGroup,
      webExtendsFilterElements: [],
    };

    return obj;
  }
}

export default WebFirewallFilterModel;
