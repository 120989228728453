import { languageSet } from "@qubit-utill/src";

export const EXTRA_TAB = ["waf"];
export const SECURITY_TAB = ["mitre", "package", "webExtendsDataBreach", "ddos"];
export const FILTER_TAB = ["system", "web", "network", "application"];

export const DASHBOARDCUTOM = "custom";
export const EXTORTACCOUNT = "EXTORTACCOUNT";
export const IP_EXTRACTION = "IPEXTRACTION";
export const LOGIN_EXTRACTION = "LOGINEXTRACTION";
export const URL_EXTRACTION = "URLEXTRACTION";
export const DASHBOARDTYPE = {
  waf: languageSet("웹방화벽"),
  mitre: languageSet("마이터"),
  package: languageSet("상관분석"),
  webExtendsDataBreach: languageSet("데이터유출"),
  ddos: languageSet("계정탈취"),
  system: languageSet("시스템"),
  application: languageSet("응용프로그램"),
  applicationTab: languageSet("원본"),
  resourceTab: languageSet("사용자정의"),
  web: languageSet("웹"),
  network: languageSet("네트워크"),
};

//보안탐지 > 상관분석
export const PACKAGEISSUETYPE = "packagefilter";
//보안탐지 > 데이터유출
export const WEBEXTENDSDATABREACHTYPE = "webextendsdatabreach";

//보안탐지 > 계정탈취공격
export const DETECTDOSISSUETYPE = "detectdos";
//보안탐지 > 홈페이지 위변조
export const DETECTFORGERYISSUETYPE = "detectforgery";

//필터탐지 > 시스템
export const SYSISSUETYPE = "syslogdetect";
//필터탐지 > 응용프로그램 > 원본
export const APPISSUETYPE = "applogdetect";
//필터탐지 > 응용프로그램 > 사용자정의
export const RESOURCEISSUETYPE = "resourcelogdetect";
//필터탐지 > 웹
export const WEBISSUETYPE = "weblogdetect";
//필터탐지 > 네트워크
export const NETISSUETYPE = "networklogdetect";
//필터탐지 > 웹방화벽
export const WAFWEBISSUETYPE = "wafweblogdetect";

//ML탐지
export const WEBLOGMLISSUETYPE = "weblogmldetect";

//전체로그 > 시스템
export const SYSLOGISSUETYPE = "syslog";
//전체로그 > 응용프로그램 > 원본
export const APPLOGISSUETYPE = "applog";
//전체로그 > 응용프로그램 > 사용자정의
export const RESOURCELOGISSUETYPE = "resourcelog";
//전체로그 > 웹
export const WEBLOGISSUETYPE = "weblog";
//전체로그 > 네트워크
export const NETWORKLOGISSUETYPE = "networklog";
//전체로그 > 웹방화벽
export const WAFLOGISSUETYPE = "wafweblog";

export const METHOD = languageSet("메소드");
export const URLPATH = languageSet("경로");
export const QUERYWORD = "Query";
export const MSGEVENTDATA = "msg || eventdata";

//마이터 관련

export const MITRE_SOFT = "MITRE_SOFT";
export const MITRE_HACK = "MITRE_HACK";
export const MITRE_LIST_CODE = "MITRE_LIST";
export const MITRE_DETECT_FILTER_SEARCHKEY = "mitredetectfilterlist";
//마이터에서 구분용도 키
export const SYSLOGDETECT = "syslogDetect";
export const PACKAGEDETECT = "packageDetect";
//마이터에서 구분용도 키
export const SYSTEM = "system";
export const PACKAGE = "package";
//마이터 티켓
export const MITREISSUETYPE = "mitredetect";
export const ENTERPRISE = "enterprise";
export const DETECTFILTER = "detectfilter";
export const MITREDETECTLISTSEARCHKEY = "securitydetectmitrelist";

export const ISOPEN_MITRE_DETECT_TR = "isOpenMitreDetectTr";

export const CUSTOM_INPUT_OPTION = [
  {
    value: "select",
    name: languageSet("선택입력"),
  },
  {
    value: "input",
    name: languageSet("직접입력"),
  },
];

export const PROTOCOL_OPTION = [{ name: "http", value: "http" }, { name: "https", value: "https" }];

export const BIT_OPTION = [
  { name: "32", value: 32 },
  { name: "31", value: 31 },
  { name: "30", value: 30 },
  { name: "29", value: 29 },
  { name: "28", value: 28 },
  { name: "27", value: 27 },
  { name: "26", value: 26 },
  { name: "25", value: 25 },
  { name: "24", value: 24 },
  { name: "23", value: 23 },
  { name: "16", value: 16 },
  { name: languageSet("직접입력"), value: "custom" },
];

export const HTTP_PORT_OPTION = [
  { name: "80", value: 80 },
  { name: "443", value: 443 },
  { name: languageSet("직접입력"), value: "custom" },
];

export const PROXY_PORT_OPTION = [
  { name: "80", value: 80 },
  { name: "443", value: 443 },
  { name: languageSet("포트번호_유지"), value: 0 },
  { name: languageSet("직접입력"), value: "custom" },
];

export const LIMIT_OPTION = [
  { name: 10, value: 10 },
  { name: 20, value: 20 },
  { name: 30, value: 30 },
  { name: 40, value: 40 },
  { name: 50, value: 50 },
  { name: 100, value: 100 },
  { name: 200, value: 200 },
];

export const COOKIEWORD = "Cookie";
export const REFERERWORD = "Referer";
export const UAWORD = "UA";
export const SUCCESSWORD = "Success";
export const FAILWORD = "Fail";
export const DATEWORD = "Date";
export const TICKETWORD = "Ticket";
export const OWASP = "OWASP";

export const SECONDS_OPTION = [
  { name: "120s", value: 120 },
  { name: "90s", value: 90 },
  { name: "60s", value: 60 },
  { name: "30s", value: 30 },
];

export const RISK_COLOR_PREFIX = { BACKGROUND: "bg-", TEXT: "c-" }; //risk className 접두어
