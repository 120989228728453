import { observable, computed, action } from "mobx";

import { ListCondition } from "@qubit-utill/src";

/**
 * IP 파일 대량 등록
 * - 사용할 땐 IpFileUploadStore 상속받아서 method 구현해서 사용
 */
class IpFileUploadStore extends ListCondition {
  constructor() {
    super();
  }

  @observable
  successList: any[] | null = null; //등록가능 리스트

  @observable
  successCount: number = 0; //등록가능 카운트

  @observable
  failList: any[] | null = null; //등록실패 리스트

  @observable
  failCount: number = 0; //등록실패 카운트

  @computed
  get listTotalCount() {
    return this.successCount + this.failCount;
  }

  /**
   * 등록 시 file list valid check
   *
   * @param fileList upload list
   */
  @action
  getIpFileUploadValidCheck = async (fileList: string[] | undefined): Promise<void> => {
    throw new Error();
  };

  /**
   * 등록
   */
  @action
  createIpFileUpload = async (): Promise<void> => {
    throw new Error();
  };

  /**
   * 초기화
   */
  @action
  clear = () => {
    this.successList = null;
    this.successCount = 0;
    this.failList = null;
    this.failCount = 0;
  };
}

export default IpFileUploadStore;
