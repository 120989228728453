import { observable, action, computed } from "mobx";

import { Common_Utils, languageSet, withLoading, UserException, ListCondition, isRunning } from "@qubit-utill/src";

import pAccountApis from "@src/apis/pAccount/pAccountApis";
import { serverGroupApis, serverApis } from "@src/apis/pServer";

import ServerGroupModel from "@src/pages/Server/ServerGroupManagement/models/ServerGroupModel";
import { ServerModel } from "@src/pages/Server/ServerManagement/models";

class ServerGroupStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }

  root;

  @observable
  isRunning = false;

  @observable
  serverGroupInfo = new ServerGroupModel();

  @observable
  tableList: ServerGroupModel[] = [];

  @observable
  paramServerGroupList: any[] = [];

  @observable
  serverList: ServerModel[] = [];
  @action
  setServerList = (serverList) => (this.serverList = serverList);
  @computed
  get checkServerList() {
    let list: string[] = [];
    this.serverList.forEach(({ serverId, isSelected }) => {
      isSelected && list.push(serverId);
    });
    return list;
  }

  @computed
  get checkList() {
    let list: number[] = [];
    this.tableList.forEach(({ serverGroupId, isSelected }) => {
      isSelected && list.push(serverGroupId);
    });
    return list;
  }

  @computed
  get paramsCheckList() {
    let list: number[] = [];
    this.paramServerGroupList.forEach(({ serverGroupId, checked }) => {
      checked && list.push(serverGroupId);
    });
    return list;
  }

  @action
  initInfo = () => {
    this.serverGroupInfo = new ServerGroupModel();
    this.serverList = [];
    this.isLoading = true; // server그룹 페이지에서 넘어올때 필요함
  };

  @action
  clearInfo = () => {
    this.serverGroupInfo = new ServerGroupModel();
    this.serverList = [];
  };

  @withLoading("isLoading")
  @action
  getList = async () => {
    try {
      const { result } = await serverGroupApis.getServerGroupByUserServerGroupList(this.params);
      this.tableList = Common_Utils.constructClassList(result.serverGroupList, ServerGroupModel);

      this.totalCount = result.totalCount;
    } catch ({ data }) {
      this.tableList = [];
      this.totalCount = 0;
      return data;
    }
  };

  @withLoading("isLoading")
  @action
  getInfo = async (serverGroupId) => {
    try {
      const { result } = await serverGroupApis.getServerGroupInfo(serverGroupId);
      let params = result.serverGroupInfo;
      params.serverGroupOsCount = result.serverGroupOsCount;
      this.serverGroupInfo = new ServerGroupModel(params);
    } catch ({ data }) {
      this.serverGroupInfo = new ServerGroupModel();
      return data;
    }
    return false;
  };

  @action
  getInfoWithServerList = (serverGroupId) => {
    try {
      this.getInfo(serverGroupId);
      this.getServerList(serverGroupId);
    } catch (e) {
      console.log(e);
    }
  };

  @action
  getServerList = async (serverGroupId = "") => {
    try {
      const { list } = await serverApis.getServerList({
        offset: 0,
        limit: 99999,
        serverGroupId,
      });
      this.serverList = list.map((info) => new ServerModel(info, this.root.CodeStore));
    } catch ({ data }) {
      this.serverList = [];
    }
  };

  @action
  getListForOptions = async (isUseTotal) => {
    try {
      if (this.paramServerGroupList.length === 0 || this.paramServerGroupList.length !== this.tableList.length) {
        const { list } = await serverGroupApis.getSimpleServerGroupList({ limit: 99999 });
        this.paramServerGroupList = list.map(
          (data) =>
            new ServerGroupModel({
              ...data,
              value: data.serverGroupId,
              name: data.serverGroup,
            })
        );
        if (isUseTotal) {
          this.paramServerGroupList.unshift({
            name: languageSet("서버그룹_전체"),
            value: -1,
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  @action
  update = async () => {
    const { serverGroupId, serverGroupName: serverGroup } = this.serverGroupInfo;
    try {
      await serverGroupApis.updateServerGroup(serverGroupId, {
        serverGroup,
      });
    } catch ({ data }) {
      throw new UserException(data.returnCodeMessage || data.dataNotFoundCodeMessage);
    }
  };

  @action
  updateServerMove = async (serverIds, serverGroupId) => {
    try {
      await serverApis.updateServerMoveByServerGroupId({
        serverGroupId,
        serverIds,
      });
      this.getInfoWithServerList(this.serverGroupInfo.serverGroupId);
    } catch ({ data }) {
      throw new UserException(data.returnCodeMessage || data.dataNotFoundCodeMessage);
    }
  };

  @isRunning("isRunning")
  @action
  create = async () => {
    if (!this.serverGroupInfo.serverGroupName) throw new UserException(languageSet("메세지_시스템그룹명_입력하세요"));
    try {
      const { serverGroupId } = await serverGroupApis.createServerGroup({
        serverGroup: this.serverGroupInfo.serverGroupName,
      });
      this.serverList.length > 0 &&
        this.updateServerMove(
          this.serverList.map(({ serverId }) => serverId),
          serverGroupId
        );
      return serverGroupId;
    } catch ({ data }) {
      throw new UserException(data.returnCodeMessage || data.dataNotFoundCodeMessage);
    }
  };

  @action
  deleteServerGroup = async () => {
    try {
      await serverGroupApis.deleteServerGroups({
        serverGroupIds: this.checkList,
      });
      await this.getList();
    } catch ({ data }) {
      return data;
    }
  };

  @action
  createUserServerGroupByServerGroupId = async (serverGroupId, data) => {
    try {
      await serverApis.createUserServerGroupByServerGroupId(serverGroupId, data);
      this.getList();
    } catch ({ data }) {
      return data;
    }
  };

  @action
  serverGroupHasList = (list) => {
    const t = new Set();
    list.forEach(({ uuid }) => t.add(uuid));
    return t;
  };

  @action
  getServerGroupUserList = async (serverGroupId) => {
    let serverGroupUserList: any = [];
    let userList: Array<object> = [];

    try {
      const { list: checkUserList } = await serverApis.getUserServerGroupByServerGroupIdList(serverGroupId);
      serverGroupUserList = this.serverGroupHasList(checkUserList);

      const { list } = await pAccountApis.getUserList({ limit: 99999 });

      userList = list.map((element: ServerGroupModel) => {
        if (serverGroupUserList.has(element.uuid)) {
          element.isSelected = true;
        }
        return new ServerGroupModel(element);
      });

      return { serverGroupUserList, userList };
    } catch (error) {
      return { serverGroupUserList: [], userList: [] };
    }
  };
}

export default ServerGroupStore;
