import React, { useState } from "react";
import Chart from "react-apexcharts";
import { observer } from "mobx-react";
import { toJS } from "mobx";

import { Common_Utils } from "qubit-utill/src";

import UISET from "@src/constants/uiset";
import NullLogData from "@src/common/components/organisms/Graphs/NullLogData";

interface GraphOptionPropsTypes {
  tooltip?: { marker?: { show?: boolean }; followCursor?: boolean };
  legend?: { show?: boolean; showForSingleSeries?: boolean };
  plotOptions?: { columnWidth?: string };
  xaxis?: { labels?: { style?: { fontSize?: string } } };
  dataLabels?: { enabled?: boolean; style: { colors: Array<string> | Array<Function> } };
}

interface PropsTypes {
  id: string;
  title: string;
  data: {
    name: string;
    data:
      | number[]
      | {
          x: any;
          y: any;
          fillColor?: string;
        }[]
      | any[];
  }[];
  category: string[];
  valueFormater?: Function;
  height?: number;
  onClick?: Function;
  selectedIndex?: number;
  graphOption?: GraphOptionPropsTypes;
  useOnClick?: boolean;
  nullNotice?: string;
}

const Bar: React.FC<PropsTypes> = ({
  id,
  title,
  data,
  category,
  valueFormater,
  height = 250,
  onClick,
  selectedIndex = -1,
  graphOption = {},
  useOnClick,
  nullNotice,
}) => {
  const [chartIndex, setChartIndex] = useState<number>(selectedIndex);

  const sumMaxColor = "#ff0000";
  const defaultColor = "#000000";

  const series = data && data.length > 0 ? toJS(data) : [];

  let categoriesColors: string[] = [];
  if (useOnClick && onClick && category && category.length > 0) {
    categoriesColors = new Array<string>(category.length).fill("");
    categoriesColors[chartIndex] = sumMaxColor;
  }

  const _valueFormatter = (value) =>
    Common_Utils.isNull(value) ? (valueFormater ? valueFormater(value) : Common_Utils.numberWithComma(value)?.toString()) : "";

  const isMaxValue = (data, value) => {
    const sumMaxValue = Math.max(...data.flat());

    return value > 0 && value === sumMaxValue;
  };

  const options = {
    title: { text: title, align: "center" as const },
    labels: category && category.length > 0 ? toJS(category) : [],

    chart: {
      id: id,
      toolbar: {
        show: false,
      },
      redrawOnParentResize: true,
      events: {
        dataPointSelection: function(event, chartContext, config) {
          if (useOnClick) {
            const dataPointIndex: number = config.dataPointIndex;
            const seriesIndex: number = config.seriesIndex;
            const xName = data[seriesIndex].name;
            const _xName = chartContext.opts.series[seriesIndex]._name;
            const dataPointValue = category && category.length > 0 ? category[dataPointIndex] : "";

            onClick && onClick(dataPointIndex, _xName, xName, dataPointValue);
            onClick && setChartIndex(dataPointIndex);
          }
        },
        dataPointMouseEnter: function(event) {
          if (useOnClick) {
            event.target.style.cursor = "pointer";
          }
        },
      },
    },
    xaxis: {
      labels: {
        style: {
          colors: categoriesColors,
          fontSize: graphOption?.xaxis?.labels?.style?.fontSize || "12px",
        },
        trim: true,
        hideOverlappingLabels: false,
        maxHeight: 70,
      },
    },
    yaxis: {
      labels: {
        formatter: function(val, index) {
          return _valueFormatter(val) as string;
        },
      },
    },
    dataLabels: {
      enabled: graphOption?.dataLabels?.enabled ?? false,
      style: graphOption?.dataLabels?.style || {
        colors: [
          function(opts) {
            const { w, dataPointIndex, seriesIndex } = opts;
            const series = w.globals.series;
            const value = series[seriesIndex][dataPointIndex];
            const isMax = isMaxValue(series, value);

            return isMax ? sumMaxColor : defaultColor;
          },
        ],
      },
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      followCursor: graphOption?.tooltip?.followCursor || false,
      marker: {
        show: graphOption?.tooltip?.marker?.show ?? true,
      },
      x: {
        formatter: function(value, opts) {
          const nullValue = opts.series.every((seriesItem) => {
            const data = seriesItem[opts.dataPointIndex];
            return !Common_Utils.isNull(data);
          });
          return nullValue ? null : value;
        },
      },
      y: {
        formatter: function(value, opts) {
          return (Common_Utils.isNull(value) ? _valueFormatter(value) : null) as string;
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: graphOption?.plotOptions?.columnWidth || "60%",
      },
    },
    stroke: {
      show: true,
      width: 7,
      colors: ["transparent"],
    },
    legend: {
      show: graphOption?.legend?.show ?? true,
      showForSingleSeries: graphOption?.legend?.showForSingleSeries ?? false,
      position: "bottom" as const,
      horizontalAlign: "center" as const,
      offsetY: 8,
      markers: {
        radius: 12,
      },
    },
    colors: [
      function(opts) {
        const { w, seriesIndex, value } = opts;
        const series = w.globals.series;
        const isMax = isMaxValue(series, value);

        return isMax ? sumMaxColor : UISET.CHART_COLOR[seriesIndex];
      },
    ],
  };

  const isNullData =
    !data ||
    data.length === 0 ||
    (data.length > 0 && data.every(({ data }) => !data || data.every((item) => !Common_Utils.isNull(item))));

  return isNullData ? (
    <NullLogData height={265} nullNotice={nullNotice} />
  ) : (
    <Chart options={options} series={series} type="bar" height={`${height}px`} />
  );
};

export default observer(Bar);
