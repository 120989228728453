import { SetterModel } from "@qubit-utill/src";

export default class BarGraphModel extends SetterModel {
  getIndex = (list: Array<any>, key: any, name: string) => {
    if (name === "index") {
      return list.indexOf(key);
    } else {
      return list.findIndex((subData) => key === subData[name]);
    }
  };
}
