import { axiosModules } from "@qubit-utill/src";

export default {
  // plog > 웹로그 > 웹 로그 조회
  getWebLog: (documentId) => {
    return axiosModules.get(`/plog/weblog/${documentId}`);
  },

  // plog > 웹로그 > 웹 로그 리스트 CSV 다운로드
  getWebLogCsvList: (params) => {
    return axiosModules.getCsv("/plog/weblog/list/csv", params);
  },

  // plog > 웹로그 > 웹 로그 리스트 조회
  getWebLogList: (params) => {
    return axiosModules.get("/plog/weblog/list", params);
  },
};
