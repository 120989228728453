import { observable, action, toJS } from "mobx";

import { ListCondition, Common_Utils, withLoading, languageSet } from "@qubit-utill/src";

import { applicationLogApis } from "@src/apis/pLog";
import DefenceStore from "@src/common/stores/DefenceStore";
import { APPLOGISSUETYPE } from "@src/constants/code";
import { CSVINFO, UISET } from "@src/constants";

import ApplicationDefaultTotalLogModel from "@src/pages/TotalLog/ApplicationTotalLog/Default/Models/ApplicationDefaultTotalLogModel";

class ApplicationDefaultTotalLogStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }

  root;

  @observable
  tableList: ApplicationDefaultTotalLogModel[] = [];

  @observable
  applicationTotalLogInfo = new ApplicationDefaultTotalLogModel({});

  @observable
  ipDefenceType = "DEFENCEIP_APPLICATION";

  @withLoading("isLoading")
  @action
  getList = async () => {
    try {
      const tagServicePropertyList = this.tagServicePropertyList;
      const tagValueTypeList = this.tagValueTypeList;
      const getTagServiceDataList = this.openIpTagModal;

      const { list, totalCount, nextCursorMark } = await applicationLogApis.getAppLogList(toJS(this.params));

      this.tableList = list.map((info) => {
        const _info = { ...info, tagServicePropertyList, tagValueTypeList, getTagServiceDataList };
        return new ApplicationDefaultTotalLogModel(_info);
      });

      this.setCursorMarkInfo(nextCursorMark);
      this.totalCount = totalCount;
    } catch (e) {
      console.log("ApplicationDefaultTotalLogStore", e);
      this.tableList = [];
      this.totalCount = 0;
    }
  };

  @withLoading("isSubLoading")
  @action
  getInfo = async (documentId) => {
    try {
      const tagServicePropertyList = this.tagServicePropertyList;
      const tagValueTypeList = this.tagValueTypeList;
      const getTagServiceDataList = this.openIpTagModal;
      const getTagServiceDataListFn = this.getTagServiceDataList;

      const { info, viewTemplate, programMessageTemplate } = await applicationLogApis.getAppLog(documentId);

      const _info = { ...info, tagServicePropertyList, tagValueTypeList, getTagServiceDataList, getTagServiceDataListFn };

      this.applicationTotalLogInfo = new ApplicationDefaultTotalLogModel({
        ..._info,
        viewTemplate,
        programMessageTemplate,
      });
      info.logClientIp && (await DefenceStore.getDefenceInfo(this.applicationTotalLogInfo));
      this.root.TicketStore.getPrintTicketInfo(APPLOGISSUETYPE, documentId, info.logCreateDate); //get 티켓발행정보
    } catch (err) {
      console.log(err);
      this.applicationTotalLogInfo = new ApplicationDefaultTotalLogModel({});
    }
  };

  @action
  downloadCSV = async (isUseBulk?, no?) => {
    try {
      if (this.tableList.length > 0) {
        let csv;
        if (isUseBulk && no) {
          csv = await applicationLogApis.getAppLogCsvList({
            ...toJS(this.params),
            // serverIds: toJS(this.params.serverIds),
            // typeosList: toJS(this.params.typeosList),
            // serverGroupIds: toJS(this.params.serverGroupIds),
            offset: 0,
            limit: no,
          });
        } else {
          csv = Common_Utils.makeCSV(CSVINFO.TOTALLOG_APP_DEFAULT.head, this.tableList);
        }
        Common_Utils.csvDownload(
          csv,
          Common_Utils.setCsvFileName({
            title: UISET.TOTALLOG_APP_DEFAULT.title,
            category: `${languageSet("원본")}_${languageSet("전체로그")}`,
            ...this.params,
          })
        );
      } else {
        return languageSet("에러_데이터가없습니다");
      }
    } catch ({ data }) {
      console.log(data);
    }
  };
}

export default ApplicationDefaultTotalLogStore;
