import { observable, action } from "mobx";
import { ListCondition, withLoading } from "@qubit-utill/src";
import TicketListModel from "@src/pages/Ticket/models/TicketListModel";
import pTicketApis from "@src/apis/pTicket/pTicketApis";

class TicketListStore extends ListCondition {
  constructor(root) {
    super();
    this.root = root;
  }
  @observable
  root;

  @observable
  tableList = [];

  @withLoading("isLoading")
  @action
  getList = async () => {
    try {
      const { list, totalCount } = await pTicketApis.getTicketPrintList(this.params);
      this.tableList = list.map((data) => new TicketListModel(data, this.root.CodeStore));
      this.totalCount = totalCount;
    } catch (response) {
      this.tableList = [];
      this.totalCount = 0;
    }
  };

  @action
  clear = () => {
    this.tableList = [];
  };
}

export default TicketListStore;
