import React from "react";
import { observer } from "mobx-react";

import { Common_Utils } from "@qubit-utill/src";

interface PropTypes {
  value: any;
}

const Hipen: React.FC<PropTypes> = ({ value, children }) => {
  return (value && children) || Common_Utils.isDataWithDashBar(value);
};

export default observer(Hipen);
