import { observable, computed } from "mobx";
import { format } from "date-fns";

import { Common_Utils, formatConstants, dateUtil, SetterModel } from "@qubit-utill/src";

import GroupAlarmModel from "@src/pages/Group/Alarm/models/GroupAlarmModel";

export default class AlarmModel extends SetterModel {
  constructor(params, dataType, dataList, CodeStore) {
    super(params);
    this.CodeStore = CodeStore;
    if (params) {
      this.isServerGroupAll = params.isServerGoupAll;
      this.originAlarmIntervalTime = params.alarmIntervalTime;
      if (dataType === "webhook" || dataType === "syslog") {
        //관리 > 연동 > Syslog
        this.webhookConfig = dataList
          ? dataList.map(
              (data, index) =>
                new GroupAlarmModel(
                  {
                    ...data,
                    index,
                    webDetectStatusEntryList: params.webDetectStatusEntryList,
                    wafDetectStatusEntryList: params.wafDetectStatusEntryList,
                  },
                  CodeStore
                )
            )
          : [];

        if (this.webhookConfig.filter(({ isDefaultFlag }) => isDefaultFlag).length === 0) {
          this.webhookConfig.push(new GroupAlarmModel({ alarmWebhookType: dataType, isDefault: "1" }, CodeStore));
        }

        if (this.webhookConfig.filter(({ isDefaultFlag }) => !isDefaultFlag).length === 0) {
          this.webhookConfig.push(new GroupAlarmModel({ alarmWebhookType: dataType }, CodeStore));
        }
      } else if (dataType === "default") {
        this.serverGroupList = dataList
          ? dataList.map(({ serverGroup, serverGroupId }) => ({
              name: serverGroup,
              value: serverGroupId,
            }))
          : [];
      }

      this.alarmStartTime = dateUtil.newDate(params.alarmStartTime, null);
      this.alarmEndTime = dateUtil.newDate(params.alarmEndTime, null);
    }
  }

  @observable
  CodeStore;

  @computed
  get isServerGroupAllFlag() {
    return Common_Utils.isCheckBool(this.isServerGroupAll);
  }

  @computed
  get isReportFlag() {
    return Common_Utils.isCheckBool(this.isReport);
  }

  @computed
  get isUseDefaultWebhookFlag() {
    return Common_Utils.isCheckBool(this.isUseDefaultWebhook);
  }

  @computed
  get isWebhookFlag() {
    return Common_Utils.isCheckBool(this.isWebhook);
  }

  @computed
  get isAlarmTimeRangeUseFlag() {
    return Common_Utils.isCheckBool(this.isAlarmTimeRangeUse);
  }

  @computed
  get isSingleSendFlag() {
    return Common_Utils.isCheckBool(this.isSingleSend);
  }

  @computed
  get alarmStartTimeFormat() {
    return this.alarmStartTime ? format(new Date(this.alarmStartTime), formatConstants.TIMEFORMAT) : null;
  }

  @computed
  get alarmEndTimeFormat() {
    return this.alarmEndTime ? format(new Date(this.alarmEndTime), formatConstants.TIMEFORMAT) : null;
  }

  originAlarmIntervalTime: number = 0;

  @observable
  alarmIntervalTime: number = 0;

  @observable
  alarmLastSendDate: string = "";

  @observable
  isServerGroupAll: string = ""; //전체서버그룹

  @observable
  isWebStatusAll: string = ""; //웹 status

  @observable
  isReport: string = ""; //보고서

  @observable
  isUseDefaultWebhook: string = ""; //대표웹훅설정

  @observable
  isWebhook: string = ""; //웹훅사용여부

  @observable
  reportTypes = [];

  @observable
  serverGroupList = [];

  @observable
  webhookConfig: GroupAlarmModel[] = [];

  @observable
  isAlarmTimeRangeUse: string = "";

  @observable
  alarmStartTime: Date | null = null;

  @observable
  alarmEndTime: Date | null = null;

  @observable
  agentResourceTypeCode: string = "";

  @observable
  isUseAlarm: string = "";

  @observable
  criticalValue: string = "";

  @observable
  codeName: string = "";

  @observable
  codeDescription: string = "";

  @observable
  isSingleSend: string = "0";

  @observable
  webDetectStatusEntryList = [];

  @observable
  wafDetectStatusEntryList = [];

  @observable
  alarmWebhookConfigEntryOptionValues = [];

  //기타 - 보고서 메일
  @observable
  isReportMail: string = "";

  @computed
  get isReportMailFlag() {
    return Common_Utils.isCheckBool(this.isReportMail);
  }
}
