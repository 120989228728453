import { axiosModules } from "@qubit-utill/src";

export default {
  getComplianceDetectList: (params) => {
    return axiosModules.get("/pstats/detectcompliance/list", params);
  },
  getComplianceDetectCount: (params) => {
    return axiosModules.get("/pstats/detectcompliance/list/count", params);
  },
};
