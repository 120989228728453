import React, { useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { observer, PropTypes as Mobx_PropTypes, Observer } from "mobx-react";
import DropdownWithChilren from "./DropdownWithChilren";

const FilterDropdown = ({
  items,
  onSelect,
  defaultValue,
  rigthClass,
  isUseTotal,
  selectedValue: propsSelectedValue,
  titleClass = "c-dark f-12",
  arrowClass = "",
  dropdownSize = "drop-menu-lg",
  titleStyle = {},
  useClockIcon = false,
  useDownArrow = true,
  style = {},
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const _renderItems = useCallback(() => {
    return items.map(({ value, name }) => {
      const checkFlag =
        propsSelectedValue !== undefined
          ? propsSelectedValue === value
          : selectedValue.value === value;
      return (
        <DropdownItem
          tag="li"
          onClick={() => {
            setSelectedValue({ value, name });
            onSelect(value, name);
          }}
          key={value}
        >
          <a className="p-tb-3">
            {checkFlag && (
              <span className="check_w">
                <i className="fa fa-check" />
              </span>
            )}
            {name}
          </a>
        </DropdownItem>
      );
    });
  }, [selectedValue, items]);

  const _optionClear = useCallback(() => {
    setSelectedValue(defaultValue);
    onSelect(defaultValue.value);
  }, []);

  const displayName = useMemo(() => {
    propsSelectedValue === defaultValue.value && setSelectedValue(defaultValue);
    return propsSelectedValue
      ? items.find(info => info.value === propsSelectedValue).name
      : selectedValue.name;
  }, [propsSelectedValue, selectedValue]);

  const checkFlag =
    propsSelectedValue !== undefined
      ? propsSelectedValue === defaultValue.value
      : selectedValue.value === defaultValue.value;

  return (
    <div className={`dis-inline-b m-r-10 ${rigthClass} cursor_p`} style={style}>
      <DropdownWithChilren>
        <DropdownToggle tag="div">
          <a className="c-dark">
            {useClockIcon && <i className="icon-clock c-red" />}
            <span className={titleClass} style={titleStyle}>
              {displayName}
            </span>
            {useDownArrow && <i className={`fa fa-angle-down p-l-10 ${arrowClass}`} />}
          </a>
        </DropdownToggle>
        <DropdownMenu tag="ul" className={`f-12 ${dropdownSize}`}>
          {isUseTotal && (
            <DropdownItem tag="li" className="cursor_p" onClick={_optionClear}>
              <a className="p-tb-3">
                {checkFlag && (
                  <span className="check_w">
                    <i className="fa fa-check" />
                  </span>
                )}
                {defaultValue.name}
              </a>
            </DropdownItem>
          )}
          <Observer render={_renderItems} />
        </DropdownMenu>
      </DropdownWithChilren>
    </div>
  );
};

FilterDropdown.propTypes = {
  defaultValue: PropTypes.object,
  _renderItems: PropTypes.func,
  selectedValue: PropTypes.object,
  items: Mobx_PropTypes.arrayOrObservableArray,
  rigthClass: PropTypes.string,
  titleClass: PropTypes.string,
  arrowClass: PropTypes.string,
  dropdownSize: PropTypes.string,
  titleStyle: PropTypes.object,
};

FilterDropdown.defualtProps = {
  items: [],
  rigthClass: "",
  defaultValue: {},
  selectedValue: {},
  titleClass: "c-dark f-12",
  arrowClass: "",
  dropdownSize: "drop-menu-lg",
  titleStyle: {},
};

export default observer(FilterDropdown);
