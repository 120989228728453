import { observable } from "mobx";
import { SetterModel } from "@qubit-utill/src";

class SignUpModel extends SetterModel {
  @observable
  termsFlag = false; //이용약관 동의
  @observable
  personalDataFlag = false; //개인정보 처리방침 동의
}

export default SignUpModel;
