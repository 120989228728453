import React from "react";
import { ButtonGroup, Button } from "reactstrap";
import { languageSet } from "@qubit-utill/src";

interface Props {
  setListParams: Function;
  selectedButton: String;
  paramName: string;
}

const ServiceButtons: React.SFC<Props> = ({ setListParams, selectedButton, paramName }) => {
  const activeColor = (service: String) => (selectedButton === service ? "bg-orange" : "btn-default");
  return (
    <ButtonGroup className="dis-inline-b">
      <Button
        size="sm"
        className={`${activeColor("system")} m-b-0`}
        onClick={() => {
          setListParams(paramName, 0, false);
          setListParams("selectedService", "system", true);
        }}
      >
        {languageSet("시스템")}
      </Button>
      <Button
        size="sm"
        className={`${activeColor("network")} m-b-0`}
        onClick={() => {
          setListParams(paramName, 0, false);
          setListParams("selectedService", "network", true);
        }}
      >
        {languageSet("네트워크")}
      </Button>
    </ButtonGroup>
  );
};

export default ServiceButtons;
