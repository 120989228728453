import { action } from "mobx";

import { systemTotalLogApis } from "@src/apis/pLog";
import { edrDefenseCmdApis } from "@src/apis/pServer";

/**
 * 호스트보안 차단관련 Store
 */
class EdrDefenseStore {
  @action
  getEdrDefense = async (documentId, serverId) => {
    let autoDefenseInfo, manualDefenseInfo;

    try {
      const {
        autoDefenseList,
        manualDefenseList,
        autoExecuteCount,
        manualExecuteCount,
      } = await systemTotalLogApis.getSysLogEdrDefense(documentId);

      autoDefenseInfo = await this.setEdrDefenseObject(documentId, serverId, autoDefenseList, autoExecuteCount);
      manualDefenseInfo = await this.setEdrDefenseObject(documentId, serverId, manualDefenseList, manualExecuteCount);
    } catch (error) {
      const initDefenseInfo = {
        excuteCount: "",
        defenseListCount: "",
        optionsList: [],
      };
      autoDefenseInfo = initDefenseInfo;
      manualDefenseInfo = initDefenseInfo;
    }

    return { autoDefenseInfo, manualDefenseInfo };
  };

  @action
  createEdrDefenseCmd = async (documentId, edrFilterElementId, data) => {
    try {
      await edrDefenseCmdApis.createEdrDefenseCmd(documentId, edrFilterElementId, data);
    } catch ({ data }) {
      return data;
    }

    return null;
  };

  @action
  setEdrDefenseObject = async (documentId, serverId, defenseList, excuteCount) => {
    const defenseListCount = defenseList.length;
    let defenseInfo = {
      excuteCount: "",
      defenseListCount: "",
      optionsList: [],
    };
    const optionsList = await defenseList.map((info) => {
      let edrCmdObj = {
        icon: "",
        color: "",
        tooltipText: "",
        edrDefenseCmdType: "",
      };

      let edrAgentCommand = {};

      switch (info.edrCmd) {
        case "execute":
          edrCmdObj.icon = "play";
          edrCmdObj.color = "blue";
          edrCmdObj.tooltipText = "실행";
          edrCmdObj.edrDefenseCmdType = "defense";
          edrAgentCommand = { edrAgentDefenseCommand: info.edrAgentDefense.edrAgentDefenseCommand };
          break;
        case "recover":
          edrCmdObj.icon = "reply";
          edrCmdObj.color = "red";
          edrCmdObj.tooltipText = "복구";
          edrCmdObj.edrDefenseCmdType = "recover";
          edrAgentCommand = { edrAgentRecoverCommand: info.edrAgentDefense.edrAgentRecoverCommand };
          break;
        case null:
          edrCmdObj.icon = "";
          edrCmdObj.color = "";
          edrCmdObj.tooltipText = "";
          edrCmdObj.edrDefenseCmdType = "";
          break;
      }

      return {
        edrCmd: info.edrCmd,
        name: info.edrAgentDefense.edrDefenseType,
        icon: edrCmdObj.icon,
        color: edrCmdObj.color,
        tooltipText: edrCmdObj.tooltipText,
        value: {
          documentId,
          edrFilterElementId: info.edrAgentDefense.edrFilterElementId,
        },
        defenseParams: {
          serverId,
          filterId: info.edrAgentDefense.filterId,
          edrDefenseCmdType: edrCmdObj.edrDefenseCmdType,
          ...edrAgentCommand,
        },
      };
    });

    defenseInfo.optionsList = optionsList;
    defenseInfo.excuteCount = ` (${excuteCount}/${defenseListCount})`;
    defenseInfo.defenseListCount = defenseListCount;

    return defenseInfo;
  };
}

export default new EdrDefenseStore();
