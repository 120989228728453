import { axiosModules } from "@qubit-utill/src";

export default {
  // 관리 > 보안 > 비밀번호 변경
  // pauth > 비밀번호 변경 주기 > 비밀번호 변경 주기 정보
  getPasswordChangeInfo: () => {
    return axiosModules.get("/pauth/userpasswordchangeconfig/info");
  },

  // pauth > 비밀번호 변경 주기 > 비밀번호 변경 주기 체크
  getPasswordChangeInfoCheck: () => {
    return axiosModules.get("/pauth/userpasswordchangeconfig/check");
  },

  // pauth > 비밀번호 변경 주기 > 비밀번호 변경 주기 등록/수정
  updatePasswordChange: (params) => {
    return axiosModules.post("/pauth/userpasswordchangeconfig", params);
  },

  // pauth > 고객API세션 > 고객 API 세션 생성
  createApiSession: () => axiosModules.post("/pauth/customerapisession"),

  // pauth > 고객API세션 > 만료된 고객 API 세션 연장
  extensionApiSession: () => axiosModules.put("/pauth/customerapisession"),

  // pauth > 고객API세션 > 고객 API 세션 만료 설정
  expiredApiSession: () => axiosModules.patch("/pauth/customerapisession"),

  // pauth > 고객API세션 > 고객 API 세션 조회
  getCustomApiSession: () => axiosModules.get("/pauth/customerapisession"),

  // pauth > 쿠키언어 > 쿠키언어 가져오기
  getLangCookie: () => axiosModules.get("/pauth/lang/cookielang"),

  // pauth > 쿠키언어 > 쿠키언어 설정하기
  setLangCookie: (paramLang) =>
    axiosModules.post("/pauth/lang/cookielang", {
      paramLang,
    }),

  // pauth > 로그인허용 > 로그인 허용IP 목록(그룹관리 메뉴 로그인허용IP IP리스트)
  getLoginAllowIpList: (params) => {
    return axiosModules.get("/pauth/allowip", params);
  },

  // pauth > 로그인허용 > 로그인 허용IP 등록(로그인 허용 IP 추가)
  createAllowIp: (data) => {
    return axiosModules.post("/pauth/allowip", data);
  },

  // pauth > 로그인허용 > 로그인 허용IP 삭제
  deleteAllowIp: (allowIpAddressId) => {
    return axiosModules.del(`/pauth/allowip/${allowIpAddressId}`);
  },

  // pauth > 로그인허용 > 로그인 허용IP 국가목록
  getLoginAllowIpCountryList: () => {
    return axiosModules.get("/pauth/allownation?limit=9999");
  },

  // pauth > 이용라이센스 > 이용 라이센스 정보 조회
  getUserLicenseInfo: () => axiosModules.get("/pauth/userlicenseinfo"),

  // pauth > 로그인내역 > 접속자의 로그인 내역 목록
  getUserLoginLogListByUuid: (params) => {
    return axiosModules.get("/pauth/userloginlogs/uuid", params);
  },

  // pauth > 로그인내역 > 멤버관리 로그인 내역 목록
  getUserLoginLogList: (params) => {
    return axiosModules.get("/pauth/userloginlogs", params);
  },

  // pauth > 그룹메인 > 그룹메인 정보
  getGroupManager: () => {
    return axiosModules.get("/pauth/groupmain");
  },

  // pauth > 라이센스키 > 사용 가능한 라이센스키 조회
  getLicenseKey: () => {
    return axiosModules.get("/pauth/licensekey/useable");
  },

  // pauth > Role > Public 권한(role)목록
  getRoleListPublic: () => {
    return axiosModules.get("/pauth/role/public");
  },

  // pauth > Role > 권한 목록으로 수정
  updateRoleUserPublic: (data) => {
    return axiosModules.put("/pauth/roleuser/public", data);
  },

  // pauth > 라이센스 행동유형 > 라이센스 행동유형 조회
  getLicenseActivityInfo: (params) => axiosModules.get("/pauth/licenseacitivy", params),

  // pauth > 라이센스 행동유형 > 라이센스 검색 가능일자 조회
  getLicenseSearchDateRange: () => axiosModules.get("/pauth/accessuser/licenseactivity/searchdaterange"),

  // pauth > 라이센스 행동유형 > 리소스모니터링 라이센스 검색 가능일자 조회
  getLicenseResourceMonitorSearchDateRange: () =>
    axiosModules.get("/pauth/accessuser/licenseactivity/resourcemonitor/searchdaterange"),

  // pauth > 비밀번호 생성 규칙 관리 > 비밀번호 생성 규칙 리스트
  getUserPasswordRule: () => axiosModules.get("/pauth/userpasswordrule", { isUse: "1" }),

  // pauth > 로그인 인증 설정관리 > 통합 로그인 인증 설정 정보
  getUserGroupLoginAuth: () => {
    return axiosModules.get("/pauth/usergrouploginauth/total/info");
  },

  // pauth > 로그인 인증 설정관리 > 세션 관리 > 웹 세션 코드 리스트
  getSessionCodeList: () => axiosModules.get("/pauth/sessioncode/usergroup/web"),

  // pauth > 로그인 인증 설정관리 > 세션 관리 > 세션 설정 수정
  setSessionTime: (sessionCode) => axiosModules.put(`/pauth/sessioncodeusergroup/${sessionCode}`),

  // pauth > 로그인 인증 설정관리 > 인증 관리 > 로그인 인증 잠금 해제
  deleteUserFailLoginAuth: (uuid, loginAuthType) => {
    return axiosModules.del(`/pauth/userfailloginauth/${uuid}/${loginAuthType}`);
  },

  // pauth > 로그인 인증 설정관리 > 인증 관리 > 그룹 로그인 인증 설정 등록/수정
  updateUserGroupLoginAuth: (params, loginAuthType) => axiosModules.post(`/pauth/usergrouploginauth/${loginAuthType}`, params),

  // pauth > 약관동의 > 사용자 약관 동의 조회
  getAgreementRegistration: (params) => axiosModules.get("/pauth/agreementregistration", params),

  // pauth > 약관동의 > 사용자 약관 동의 생성
  setAgreementRegistration: (params) => axiosModules.post("/pauth/agreementregistration", params),

  setLogoutWeb: () => {
    return axiosModules.get("/pauth/logout");
  },
};
