import { axiosModules } from "@qubit-utill/src";

export default {
  getFilterList: (params, channelLogType) => axiosModules.get(`/pfilter/siem/filters/${channelLogType}`, params),
  getFilterInfo: (filterId) => axiosModules.get(`/pfilter/siem/filter/${filterId}`),
  //필터관리 > 사용자필터 등록
  createFilter: (params) => axiosModules.post(`/pfilter/siem/filter`, params),
  //필터관리 > 사용자필터 수정
  updateFilter: (params) => axiosModules.put(`/pfilter/siem/filter/${params.filterId}`, params),
  getFilterOsCount: () => axiosModules.get(`/pfilter/siem/filtertypeos`),
  getDosFilterCount: async () => {
    try {
      const { limitFilterCount, registerFilterCount } = await axiosModules.get(`/pfilter/dosfiltercount`);
      return { canRegistFilter: limitFilterCount - registerFilterCount > 0, hasFilter: registerFilterCount > 0 };
    } catch (error) {
      return { canRegistFilter: false, hasFilter: false };
    }
  },
  getUserHostList: (params) => axiosModules.get(`/pfilter/userhost`, params),
  setUserHostList: (params) => axiosModules.post(`/pfilter/userhost`, params),

  // EDR > 방어 명령 관리 > 방어 타입 목록
  getDefenseOrderTypeList: () => axiosModules.get("/pfilter/edrdefensetype"),
};
