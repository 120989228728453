import { observable, action, computed } from "mobx";
import { languageSet, SetterModel, Common_Utils } from "@qubit-utill/src";

export interface resourceDataTypeAttributeModelType {
  filterElementSearchDisplayText: string;
  filterElementSearchText: string;
  resourceFilterElementId: number;
  resourceFilterElementSelectorType: string;
  resourceFilterElementSelectorValue: string;
  resourceFilterElementSelectorTypeName: Function;
  resourceFilterElementSelectorIntegerName: string;
  resourceFilterElementSelectorOperator: string;
  setResourceFilterElementSelectorOperator: Function;
  setResourceFilterElementSelectorValue: (text) => void;
  setResourceFilterElementSeletorType: (value) => void;
}

export default class ResourceDataTypeAttributeModel extends SetterModel {
  constructor(param?) {
    super(param);

    if (param && param.filterElementReferenceList && param.filterElementSearchType !== "input") {
      const item = param.filterElementReferenceList.find(
        ({ value }) => value.filterElementReferenceId === param.filterElementSearchText
      );
      if (item) {
        this.filterElementSearchDisplayText = item.name;
      } else {
        this.filterElementSearchDisplayText = param.filterElementSearchText;
      }
    }

    if (param && param.dataType) {
      if (param.dataType === "Integer") {
        this.resourceFilterElementSelectorOperator = "ge";
      } else {
        this.resourceFilterElementSelectorOperator = "el";
      }
    }
  }

  @action
  setResourceFilterElementSelectorValue = (text) => {
    this.resourceFilterElementSelectorValue = Common_Utils.getBlockFirstBlank(text);
  };

  @computed
  get resourceFilterElementSelectorIntegerName() {
    return this.resourceFilterElementSelectorOperator === "ge" ? languageSet("이상") : languageSet("이하");
  }

  @action
  setResourceFilterElementSelectorOperator = ({ value }) => {
    this.resourceFilterElementSelectorOperator = value;
  };

  @action
  setResourceFilterElementSeletorType = ({ value }) => {
    this.resourceFilterElementSelectorType = value;
    this.resourceFilterElementSelectorValue = "";
  };

  @computed
  get resourceFilterElementSelectorTypeName() {
    return this.resourceFilterElementSelectorType === "input" ? languageSet("직접입력") : languageSet("정규식");
  }

  // @action
  // setSelectFilterElementSearchType = ({ value, name }) => {
  //   this.filterElementSearchType = value.filterElementReferenceType;
  //   this.filterElementSearchText = value.filterElementReferenceId;
  //   this.filterElementSearchDisplayText = name;
  // };

  ////////// 응용프로그램 사용자 구간 ////////////
  @observable
  resourceFilterElementSelectorId: number = 0;

  @observable
  resourceFilterElementSelectorType: string = "input"; //입력방식 (input: 직접입력, regex : 정규식)

  @observable
  resourceFilterElementSelectorOperator: string = ""; //탐지 비교 구분

  @observable
  resourceFilterElementSelectorValue: string = ""; //탐지 값

  @observable
  resourceFilterElementSelectorOrderNo: number = 0; //순번

  @observable
  resourceFilterElementId: number = 0;

  ////////// 응용프로그램 사용자 구간 ////////////

  @observable
  filterElementSearchText: string = "";
  @observable
  filterElementRepeatSequence: number = 1;
  // @observable
  // filterElementSearchType: string = "input";
  @observable
  filterElementSearchFlag: boolean = true;
  @observable
  filterElementSearchDisplayText: string = "";
  @observable
  parrentDepth: number = 0;
}
