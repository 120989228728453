/**
 * stores 모음
 */
import { PopoverStore, RoleLicenseStore, ModalStore } from "@qubit-utill/src";

import DefenceStore from "@src/common/stores/DefenceStore"; //로그 즉시방어 버튼에서 사용, 로그store들 사용
import NetworkFilterDetectStore from "@src/pages/FilterDetection/NetworkFilterDetection/stores/NetworkFilterDetectStore"; //보고서 param
import WebFilterDetectStore from "@src/pages/FilterDetection/WebFilterDetection/stores/WebFilterDetectStore"; //보고서 param
import ServerGroupStore from "@src/pages/Server/ServerGroupManagement/stores/ServerGroupStore"; //서버에서 사용
import LoginHistoryStore from "@src/pages/SideBar/stores/LoginHistoryStore";
import SystemFilterStore from "@src/pages/Filter/RegisterFilter/stores/SystemFilterStore";
import CorrelationFilterStore from "@src/pages/Filter/SecurityFilter/stores/CorrelationFilterStore";
import IpExtractionStore from "@src/pages/Extraction/IpExtraction/stores/IpExtractionStore";
import { CorrelationStore } from "@src/pages/SecurityDetect";
import UserCustomStore from "@src/common/stores/UserCustomStore";
export { default as RootStore } from "@src/stores/RootStore";

export default {
  RoleLicenseStore,
  CorrelationFilterStore: new CorrelationFilterStore(),
  LoginHistoryStore,
  PopoverStore,
  SystemFilterStore,
  NetworkFilterDetectStore,
  WebFilterDetectStore,
  CorrelationStore,
  ServerGroupStore,
  IpExtractionStore,
  DefenceStore,
  ModalStore,
  UserCustomStore,
};
