import { observable, action } from "mobx";

import { dateUtil } from "@qubit-utill/src";

import BarGraphModel from "@src/pages/Report/models/extends/BarGraphModel";

interface WeeklySumType {
  detectCount: number;
  detectDate: string;
  detectDayOfWeek: number;
  detectWeek: number;
  detectYear: number;
}
interface SerarchWeeklyType {
  detectWeek: number;
  detectYear: number;
}
interface ParamType {
  weeklySumList: WeeklySumType[];
  category: string[];
  searchWeeklyList: SerarchWeeklyType[];
  detectDate?: string;
}

class MultiBarModel extends BarGraphModel {
  constructor(param?: ParamType) {
    super(param);
    if (param) {
      const { weeklySumList, category, searchWeeklyList, detectDate } = param || {};

      if (weeklySumList) {
        if (category) {
          this.chartData = searchWeeklyList.map((data, index) => ({
            name: dateUtil.setWeekWithMonthForReport(detectDate)[index],
            weekNumber: data.detectWeek,
            data: weeklySumList ? category.map(() => null) : [],
            // name의 전신 ('2021년 22주'의 형식)
            _name: { year: data.detectYear, week: data.detectWeek },
          }));
        }

        weeklySumList.forEach((data) => {
          let index = this.getIndex(this.chartData, data.detectWeek, "weekNumber");
          this.chartData[index].data[data.detectDayOfWeek - 1] = data.detectCount;
        });
      }
    }
  }

  @action
  getIndex = (list, key, name) => {
    let returnIndex = 0;
    list.some((subData, index) => {
      const flag = key === (name === "index" ? list[index] : subData[name]);
      if (flag) {
        returnIndex = index;
      }
      return flag;
    });

    return returnIndex;
  };

  @observable
  category = [];
  @observable
  chartData: { data: number | null[]; name: string; weekNumber: number }[] = [];
}

export default MultiBarModel;
