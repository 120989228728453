import { observable, computed } from "mobx";
import { SetterModel } from "@qubit-utill/src";

class TicketListModel extends SetterModel {
  constructor(param, CodeStore) {
    super(param);
    this.CodeStore = CodeStore;
  }

  @observable
  CodeStore;

  @computed
  get issueServiceTypeText() {
    return this.CodeStore.getCodeName("ticketIssueType", this.issueServiceType);
  }
  @computed
  get filterNames() {
    return this.filterList && this.filterList.length > 0 ? this.filterList.map(({ filterName }) => filterName) : "-";
  }

  @observable
  userTicketPublishId: number = 0;

  @observable
  issueServiceType: string = "";

  @observable
  filterList: Array<{
    filterName: string;
  }> = [];

  @observable
  registerDate: string = "";

  @observable
  logCreateDate: string = "";

  @observable
  registerUserName: string = "";

  @observable
  issuePrimaryKey: string = "";

  @observable
  issueResourceType: string = "";
}

export default TicketListModel;
