import { axiosModules } from "@qubit-utill/src";

export default {
  // pserver > 서버관리 > 서버목록(SIEM)
  getServerList: async (param) => await axiosModules.get("/pserver/server", param),

  // pserver > 서버관리 > 서버 정보 조회
  getServerInfo: async (serverId) => await axiosModules.get(`/pserver/server/${serverId}`),

  // pserver > 서버관리 > 시스템 분석모드 변경 히스토리 조회
  getServerAnalysisHistoryList: async (serverId, param?) =>
    await axiosModules.get(`/pserver/serveredrdetectmodelog/${serverId}`, param),

  // pserver > 서버관리 > EDR 모드 변경 가능 에이전트 체크
  getCheckIsChangeableEdr: async (serverIds) => await axiosModules.get("/pserver/server/edr/supportcheck", serverIds),

  // pserver > 서버관리 > EDR 모드 일괄 업데이트
  updateEdrMode: async (edrDetectMode, param) => await axiosModules.put(`/pserver/server/edr/${edrDetectMode}`, param),

  // pserver > ServerManagementStatus > 호스트 종료 명령 실행
  createServerManagementStatusShutdown: async (data) =>
    await axiosModules.post("/pserver/servermanagementstatus/hostshutdown", data),

  // pserver > ServerManagementStatus > 호스트 종료 명령 다중 실행
  createServerManagementStatusShutdownList: async (data) =>
    await axiosModules.post("/pserver/servermanagementstatus/hostshutdown/list", data),

  // pserver > ServerManagementStatus > 호스트 격리 명령 실행
  createServerManagementStatusIsolation: async (data) =>
    await axiosModules.post("/pserver/servermanagementstatus/hostisolate", data),

  // pserver > ServerManagementStatus > 호스트 격리 명령 다중 실행
  createServerManagementStatusIsolationList: async (data) =>
    await axiosModules.post("/pserver/servermanagementstatus/hostisolate/list", data),

  // pserver > ServerManagementStatus > 호스트 격리 해제 명령 실행
  createServerManagementStatusIsolationClear: async (data) =>
    await axiosModules.put("/pserver/servermanagementstatus/hostisolateclear", data),

  // pserver > ServerManagementStatus > 호스트 격리 다중 해제 명령 실행
  createServerManagementStatusIsolationClearList: async (data) =>
    await axiosModules.put("/pserver/servermanagementstatus/hostisolateclear/list", data),

  // pserver > ServerManagementStatus > 종료/격리/격리해제 가능 호스트 수 조회
  getServerManagementStatusCheck: async (params) =>
    await axiosModules.get("/pserver/servermanagementstatus/servermanagementstatuscount", params),

  // pserver > ServerManagementStatus > 호스트 격리/종료 리스트 조회
  getServerManagementStatusList: async (serverIds) => await axiosModules.get("/pserver/servermanagementstatus/list", serverIds),

  // pserver > ServerManagementStatus > 호스트 격리 허용 IP 주소 등록
  createHostIsolateAllowIp: async (param) =>
    await axiosModules.post("/pserver/servermanagementstatus/hostisolate/allowip", param),

  // pserver > ServerManagementStatus > 호스트 격리 허용 IP 주소 삭제
  deleteHostIsolateAllowIp: async (allowIpId) =>
    await axiosModules.del(`/pserver/servermanagementstatus/hostisolate/allowip/${allowIpId}`),

  // pserver > ServerManagementStatus > 호스트 격리 허용 IP 주소 리스트 조회
  getHostIsolateAllowIpList: async (param) =>
    await axiosModules.get("/pserver/servermanagementstatus/hostisolate/allowip/list", param),
};
